/**
 * AddressSocialDetail Component
 * 
 * Description: This component renders detailed profile information including contact details, gender, important dates,
 * and social media links.
 * 
 * File Name: AddressSocialDetail.tsx
 * Date: 12-08-2024
 */

import React from "react";
import {
    faClockRotateLeft,
    faEnvelope,
    faLocationDot,
    faPhone,
    faEnvelopesBulk,
    faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookSquare,
    faInstagram,
    faLinkedin,
    faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { IProfileData } from "src/types/GlobalInterfaces";
import moment from "moment";
import { truncateString } from "src/helpers/helperFunctions";
interface IProfileProps {
    data: IProfileData;
}
const AddressSocialDetail: React.FC<IProfileProps> = ({
    data = {}
}) => {
    return (
        <div className="">
            <div className="shadow-lg p-4 rounded-lg bg-white">
                {data?.mobile ?
                    <button className="border-[#202020] text-[#202020] border w-full profile-right-side-btn flex items-center font-size-16px rounded-lg font-Poppins-Medium mb-2 bg-white">
                        <FontAwesomeIcon className="w-8" icon={faPhone} />
                        {data?.mobile || ""}
                    </button>
                    : ""
                }
                {data?.email ?
                    <button className="border-[#202020] text-[#202020] border w-full profile-right-side-btn flex items-center font-size-16px rounded-lg font-Poppins-Medium mb-2 bg-white">
                        <FontAwesomeIcon className="w-8" icon={faEnvelope} />
                        {data?.email || ""}
                    </button>
                    : ""
                }
                {data?.address ?
                    <button className="border-[#202020] text-[#202020] border w-full profile-right-side-btn flex items-center font-size-16px rounded-lg font-Poppins-Medium mb-2 bg-white">
                        <FontAwesomeIcon className="w-8" icon={faLocationDot} />
                        <span title={data?.address} className="text-left">{truncateString(data?.address || "", 25)}</span>
                    </button>
                    : ""
                }
                <button className="border-[#202020] text-[#202020] border w-full profile-right-side-btn flex items-center font-size-16px rounded-lg font-Poppins-Medium mb-2 bg-white capitalize">
                    <FontAwesomeIcon className="w-8" icon={faVenusMars} />
                    {data?.gender || ""}
                </button>
                <button className="border-[#202020] text-[#202020] border w-full profile-right-side-btn flex items-center font-size-16px rounded-lg font-Poppins-Medium mb-2 bg-white">
                    <FontAwesomeIcon className="w-8" icon={faEnvelopesBulk} />
                    {moment(data?.date).format("DD-MM-YYYY")}
                </button>
                <button className="border-[#202020] text-[#202020] border w-full profile-right-side-btn flex items-center font-size-16px rounded-lg font-Poppins-Medium mb-2 bg-white">
                    <FontAwesomeIcon className="w-8" icon={faClockRotateLeft} />
                    User Since {moment(data?.createdAt).format("DD MMM, YYYY")}
                </button>
            </div>
            {(data?.linkedinLink || data?.facebookLink || data?.instagramLink || data?.whatsappLink) &&
                <div className="flex flex-col gap-1 mt-2">
                    <div
                        className="flex shadow-lg rounded-lg  py-2 bg-white"
                        style={{ borderRadius: "7px", justifyContent: "space-evenly" }}
                    >
                        {
                            data?.linkedinLink
                            &&
                            <a
                                href={data?.linkedinLink}
                                target="_blank"
                                className="theme-color-green w-10"
                            >
                                <FontAwesomeIcon size="xl" icon={faLinkedin} />
                            </a>
                        }
                        {
                            data?.facebookLink
                            &&
                            <a
                                href={data?.facebookLink}
                                target="_blank"
                                className="theme-color-green w-10"
                            >
                                <FontAwesomeIcon size="xl" icon={faFacebookSquare} />
                            </a>
                        }
                        {
                            data?.instagramLink
                            &&
                            <a
                                href={data?.instagramLink}
                                target="_blank"
                                className="theme-color-green w-10"
                            >
                                <FontAwesomeIcon size="xl" icon={faInstagram} />
                            </a>
                        }
                        {
                            data?.whatsappLink
                            &&
                            <a
                                href={data?.whatsappLink}
                                target="_blank"
                                className="theme-color-green w-10"
                            >
                                <FontAwesomeIcon size="xl" icon={faWhatsapp} />
                            </a>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default AddressSocialDetail;