/**
 * ProfileView Component
 * 
* Description: This component displays the profile view, including user details, health system information,
 * recent tests, and social details. It fetches the profile data from an API and displays it using various child components such as
 * ProfileUserDetailDashboard, HealthSystem, RecentTest, AboutMe, WorkHistory, Education, Services, Diseases, Specialization,
 * OnlineConsultant, Hospitals, and Reviews. The view is conditionally rendered based on user role.
 * 
 * File Name: ProfileView.tsx
 * Date: 02-09-2024; Customer profile & doctor profile components worked on 09-02-2024
 */

import { AppDispatch, RootState } from '@store/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AddressSocialDetail from 'src/Components/AddressSocialDetail';
import HealthSystem from 'src/Components/Customer/HealthSystem';
import ProfileUserDetailDashboard from 'src/Components/Customer/ProfileUserDetailDashboard';
import RecentTest from 'src/Components/Customer/RecentTest';
import AboutMe from 'src/Components/DoctorProfile/View/AboutMe';
import Diseases from 'src/Components/DoctorProfile/View/Diseases';
import Education from 'src/Components/DoctorProfile/View/Education';
import Hospitals from 'src/Components/DoctorProfile/View/Hospitals';
import OnlineConsultant from 'src/Components/DoctorProfile/View/OnlineConsultant';
import Reviews from 'src/Components/DoctorProfile/View/Reviews';
import Services from 'src/Components/DoctorProfile/View/Services';
import Specialization from 'src/Components/DoctorProfile/View/Specialization';
import WorkHistory from 'src/Components/DoctorProfile/View/WorkHistory';
import httpRequest from 'src/helpers/httpRequest';
import { doctor } from 'src/lib/constans';
import { toggleLoader } from 'src/Store/reducer';
import { IProfileData } from 'src/types/GlobalInterfaces';

const ProfileView: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const { profile } = useSelector((store: RootState) => store.doctor);
    const [profileData, setProfileData] = useState<IProfileData>({});

    /**
    * Fetch profile data from the API.
    * 
    * @param _id - The user ID to fetch the profile data.
    */

    // API_CALL: Fetch profile data from the server based on user ID
    const fetchProfileData = async (_id: string) => {
        dispatch(toggleLoader(true));
        const { res, err } = await httpRequest<IProfileData>({ path: `/customer/get/profile/${_id}` });
        if (res) {
            setProfileData(res);
        } else {
            toast(err, { type: 'error' });
        }
        dispatch(toggleLoader(false));
    };

    useEffect(() => {
        if (userDetails?._id) {
            fetchProfileData(userDetails._id); // Fetch profile data when component mounts
        }
    }, []);

    return (
        <div className="px-5" style={{ minHeight: "800px" }}>
            <div className="container-1480 block justify-between h-3vw md:flex lg:flex">
                <div className="w-full md:w-3/4 lg:w-3/4">
                    <ProfileUserDetailDashboard
                        data={profileData}
                        setData={setProfileData}
                        profile={profile}

                    />
                    {profile && userDetails && userDetails?.userRole === doctor ?
                        <>
                            <AboutMe
                                isMyProfile={true}
                                aboutMe={profile.aboutMe}
                            />
                            <WorkHistory
                                isMyProfile={true}
                                experiences={profile.experiences}
                            />
                            <Education
                                isMyProfile={true}
                                education={profile.education}
                            />
                            <Services
                                isMyProfile={true}
                                servicesDetails={profile.servicesDetails}
                            />
                            <Diseases
                                isMyProfile={true}
                                diseasesDetails={profile.diseasesDetails}
                            />
                            <Specialization
                                isMyProfile={true}
                                specailizedDetails={profile.specailizedDetails}
                            />
                            <OnlineConsultant
                                isMyProfile={true}
                                availability={profile.availability}
                            />
                            <Hospitals
                                isMyProfile={true}
                                timings={profile.timings}
                            />
                        </>
                        : null
                    }
                    <HealthSystem
                        id={userDetails?._id}
                    />
                    <RecentTest
                        id={userDetails?._id}
                    />
                    {(profile && userDetails && userDetails?.userRole === doctor) &&
                        <Reviews
                            id={userDetails?._id}
                        />
                    }
                </div>
                <div className="w-1/4 pl-2 show-location-detail-mob">
                    <AddressSocialDetail
                        data={profileData}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProfileView;