/**
 * SingleBioMarkerHistory Component
 * 
 * Description: This component is responsible for displaying the historical results of a single biomarker. 
 * It fetches the biomarker history, displays the result history, and allows users to add new biomarker results.
 * 
 * File Name: SingleBioMarkerHistory.js
 * Date: 2024-08-23
 */

import { CircularProgress } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import SingleBioMarkerResult from './SingleBioMarkerResult';
import { IAddSingleBio } from 'src/types/GlobalInterfaces';

interface IHistory {
    date: string;
    value: string;
    isShown: boolean;
    measurement: string;
    barCategrory: string;
    [key: string]: any;
};

const SingleBioMarkerHistory: React.FC<{ bioId: string; onSuccess: () => void; uid: string; _gender: string; _date: string }> = ({ bioId, onSuccess, uid, _gender, _date }) => {
    const [historyLoader, setHistoryLoader] = useState<boolean>(false);
    const [historyArr, setHistoryArr] = useState<IHistory[]>([]);
    const [item, setItem] = useState<null | IAddSingleBio>(null);

    // API_CALL: Fetch the biomarker history data from the server
    const fetchHistoryMarkers = async (bioId: string, uid: string) => {
        setHistoryLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: { resultHistory: IHistory[], biomarkerDetails: IAddSingleBio[] } }>({ path: `/healthprofile/get/history/${bioId}/${uid}` });
        if (res) {
            setHistoryArr(res?.data?.resultHistory || []);
            setItem(res?.data?.biomarkerDetails[0] || null);
        } else {
            toast(err, { type: 'error' });
        }
        setHistoryLoader(false);
    };

    useEffect(() => {
        if (bioId && uid) {
            fetchHistoryMarkers(bioId, uid);
        }
    }, [bioId, uid]);

    return (
        <>
            {
                historyLoader
                    ?
                    <div className="text-center mt-3">
                        <CircularProgress size={24} className='loader__extra__class' />
                    </div>
                    :
                    <>
                        {
                            item
                            &&
                            <SingleBioMarkerResult
                                _date={_date}
                                _gender={_gender}
                                item={item}
                                onSuccess={onSuccess}
                                uid={uid}
                            />
                        }
                        <div className='health-vitals-dropdown-detail mx-auto'>
                            <h4 className='text-[#004D48] text-center font-Poppins-Medium font-size-18px'>Result History</h4>
                            {
                                historyArr.length > 0
                                    ?
                                    historyArr.map((v: IHistory, i: number) => (
                                        <div key={i} className='flex justify-between result-history-data'>
                                            <span className='font-Poppins-Medium text-[#444444] text-gray font-size-14px'>{moment(v.date).format("DD-MM-YYYY")}</span>
                                            <span className='font-Poppins-Medium text-[#444444] text-gray font-size-14px'>
                                                {((v.barCategrory === "twoBarDNDAsc" || v.barCategrory === "twoBarDNDDesc") && v.value === "1") ?
                                                    "Detected"
                                                    : ((v.barCategrory === "twoBarDNDAsc" || v.barCategrory === "twoBarDNDDesc") && v.value === "0") ?
                                                        "Not Detected"
                                                        : ((v.barCategrory === "twoBarPNAsc" || v.barCategrory === "twoBarPNDesc") && v.value === "1") ?
                                                            "Positive"
                                                            : ((v.barCategrory === "twoBarPNAsc" || v.barCategrory === "twoBarPNDesc") && v.value === "0") ?
                                                                "Negative"
                                                                :
                                                                v?.value
                                                }
                                                {v?.isShown ? v?.measurement : ""}
                                            </span>
                                        </div>
                                    ))
                                    :
                                    <p>No History</p>
                            }
                        </div>
                    </>
            }
        </>
    );
};

export default SingleBioMarkerHistory;