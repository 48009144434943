/**
 * DetailView Component
 * 
 * Description: Component to display a detailed view of an order including order information, items, and history.
 * It now includes an optional `changeHistory` prop that triggers the re-fetching of order history when updated.
 * 
 * Props:
 * - item (IOrderDetails): The main order data object to be displayed.
 * - id (string): The unique identifier for the order.
 * - changeHistory (boolean, optional): If true, triggers a re-fetch of the order history when it changes. Default is false.
 * 
 * File Name: DetailView.tsx
 * Date: 15-08-24
 */

import React, { useEffect, useState } from 'react';
import { displayStatus, getLastSegment } from 'src/helpers/helperFunctions';
import { IOrderDetails } from 'src/types/GlobalInterfaces';
import orderCircleImg from "src/assets/images/circle-icon-order-detail.png";
import moment from 'moment';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { printCity } from 'src/Config/CityList';

// Interface for order history
interface IHistory {
    dateAdded: string;
    comment: string;
    status: string;
    [key: string]: any;
};

// Component to display detailed view of an order
const DetailView: React.FC<{ item: IOrderDetails, id: string, changeHistory?: boolean }> = ({ item, id, changeHistory = false }) => {
    const [history, setHistory] = useState<IHistory[]>([]); // State to store the order history

    //API_CALL: Fetch order history from the server
    const fetchOrderHistory = async (_id: string) => {
        const { res, err } = await httpRequest<IHistory[]>({ path: `/order/history/status/${_id}` });
        if (res) {
            setHistory(res); // Update state with fetched history
        } else {
            toast(err, { type: 'error' }); // Show error toast if the request fails
        }
    };

    useEffect(() => {
        if (id) {
            fetchOrderHistory(id);
        };
    }, [id, changeHistory]); // Fetch order history when the component mounts or the id changes

    return (
        <>
            <div className="flex gap-2 items-center">
                <img className="order-detail-img" src={orderCircleImg} alt="" />
                <p className="order-no font-size-20px font-Poppins-Regular">
                    {`Order (#${getLastSegment(item.serialOrderId)})`}
                </p>
            </div>
            <div className="order-detail-main">
                <div className="order-detail-child">
                    <div className="order-detail-head">
                        <h3 className="font-size-15px font-Poppins-SemiBold">
                            Invoice
                        </h3>
                        <h4 className="font-size-15px font-Poppins-Regular order-no">
                            {item?.serialOrderId}
                        </h4>
                    </div>
                </div>
                <div className="order-detail-child">
                    <div className="order-detail-head">
                        <h3 className="font-size-15px font-Poppins-SemiBold">
                            Customer
                        </h3>
                        <h4 className="font-size-15px font-Poppins-Regular order-no capitalize">
                            {`${item.firstName} ${item.lastName}`}
                        </h4>
                    </div>
                </div>
                <div className="order-detail-child">
                    <div className="order-detail-head">
                        <h3 className="font-size-15px font-Poppins-SemiBold">
                            Date Added
                        </h3>
                        <h4 className="font-size-15px font-Poppins-Regular order-no">
                            {moment(item.orderDate).format("DD-MM-YYYY")}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="order-add-table text-white font-size-15px font-Poppins-SemiBold">
                {/* <p>Image</p> */}
                <p>Product Name</p>
                <p>Test Type</p>
                <p>Quantity</p>
                <p>Total</p>
            </div>
            {/* map items */}
            {item.orderDetails.map((v, i: number) => (
                <div key={i} style={{ border: "1px solid #949494" }} className="sub-order-add-table font-size-15px font-Poppins-Regular items-center">
                    {/* <p>
                        <img
                            className='order-invoice-img-pro'
                            src={`${process.env.REACT_APP_API_BASEURL}${v.orderItemPicture}`} alt="product image"
                        />
                    </p> */}
                    <p className="Order-product-name-bdl">{v.productName}</p>
                    <p>{v.testType}</p>
                    <p>{v.quantity}</p>
                    <p>AED {v.productPrice}</p>
                </div>
            ))}
            {/* map items */}
            <div className="mt-8">
                <div className="order-detail-child">
                    <div className="order-detail-head">
                        <h3 className="font-size-15px text-black font-Poppins-Medium">
                            Payment Address
                        </h3>
                        <h4 className="font-size-15px font-Poppins-Regular order-no">
                            {`${item.firstName} ${item.lastName}`}
                        </h4>
                        <h4 className="font-size-15px font-Poppins-Regular order-no">
                            {item.email}
                        </h4>
                        <h4 className="font-size-15px font-Poppins-Regular order-no">
                            {item.mobile}
                        </h4>
                        <h4 className="font-size-15px font-Poppins-Regular order-no">
                            {item.serialOrderId}
                        </h4>
                        <h4 className="font-size-15px w-[80%] font-Poppins-Regular order-no">
                            {item.address}
                        </h4>
                        <h4 className="font-size-15px font-Poppins-Regular order-no">
                            {printCity(item.city)}
                        </h4>
                        <h4 className="font-size-15px font-Poppins-Regular order-no">
                            {item.postCode}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="bg-white mt-4">
                <div className="total-table">
                    <p className="flex justify-end font-size-15px font-Poppins-SemiBold text-black">
                        Total
                    </p>
                    <p className="text-center font-size-15px font-Poppins-Regular ">AED {item.totalAmount}</p>
                </div>
            </div>
            <div className="flex gap-2 items-center order-no-cust mt-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.44"
                    height="19.44"
                    viewBox="0 0 19.44 19.44"
                >
                    <path
                        id="Path_20131"
                        data-name="Path 20131"
                        d="M645.354-224.951a4.39,4.39,0,0,0-.1-.984,4.476,4.476,0,0,0-4.454-3.547h-5.142q-2.571,0-5.142,0a4.632,4.632,0,0,0-.786.055,4.5,4.5,0,0,0-3.8,4.455c-.016,2.105-.01,4.211-.007,6.316a4.075,4.075,0,0,0,.429,1.836,4.413,4.413,0,0,0,3.524,2.529c.177.025.226.083.223.26-.011.675,0,1.35,0,2.024,0,.533,0,1.066,0,1.6a.329.329,0,0,0,.4.354.566.566,0,0,0,.242-.176q1.909-1.913,3.815-3.83a.538.538,0,0,1,.424-.172q2.885.008,5.77,0a5.073,5.073,0,0,0,.687-.038,4.494,4.494,0,0,0,3.92-4.488C645.359-220.821,645.354-222.886,645.354-224.951Z"
                        transform="translate(-625.916 229.483)"
                        fill="#4f6367"
                    />
                </svg>

                <p className="order-no font-size-20px font-Poppins-Regular">
                    History
                </p>
            </div>
            <div className="order-add-table order-add-table-second text-white font-size-15px font-Poppins-SemiBold">
                <p>Date Added</p>
                <p>Comments</p>
                <p>Status</p>
            </div>
            {history.map((v: IHistory, i: number) => (
                <div key={i} className="sub-order-add-table sub-order-add-table-second font-size-15px font-Poppins-Regular items-center">
                    <p>{moment(v.dateAdded).format("DD-MM-YYYY")}</p>
                    <p>{v.comment}</p>
                    <p>{displayStatus(v.status)}</p>
                </div>
            ))}
        </>
    );
};

export default DetailView;