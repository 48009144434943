import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import defaultProfile from "src/assets/images/default_profile_image.png";
import { useDispatch, useSelector } from "react-redux";
import { removeToken, truncateString } from 'src/helpers/helperFunctions';
import { removeAuth } from 'src/Store/reducer';
import { AppDispatch, RootState } from '@store/store';
import { customer, doctor, doctorMode } from 'src/lib/constans';
import Cookies from 'js-cookie';


const ProfilePopup: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const mode = Cookies.get(doctorMode) || doctor;
    const { profile } = useSelector((store: RootState) => store.doctor);

    const profileOptions = [
        { option: "Account Settings", url: "/account-settings", isDisplay: true },
        { option: "Privacy", url: "/privacy", isDisplay: true },
        { option: "Activity Logs", url: "/activity", isDisplay: true },
        { option: "Payout & Withdrawal", url: "/payouts-withdrawal", isDisplay: mode === doctor && userDetails?.userRole === doctor }, // display only dostor site
        { option: "Support Hub", url: "/support-hub", isDisplay: true },
    ];

    const handleSwitchMode = () => {
        const cookieOptionsCross = {
            path: "/",
            domain: process.env.REACT_APP_DOMAIN, // Add domain here
        };
        Cookies.set(doctorMode, mode === doctor ? customer : doctor, cookieOptionsCross);
        navigate("/dashboard");
    };

    const handleLogOut = () => {
        Cookies.remove(doctorMode);
        removeToken();
        dispatch(removeAuth());
        const baseUrl = process.env.REACT_APP_WEBSITE_URL;
        const targetUrl = `${baseUrl}/login`;
        window.location.href = targetUrl;
    };

    return (
        <div className='profile-popup-container absolute'>
            <div className="profile-popup-top">
                <div className="profile-popup-details">
                    <div className="profile-img-container">
                        <img
                            title={`${userDetails?.firstName || ""} ${userDetails?.lastName || ""}`}
                            alt='profile-image'
                            width={100}
                            height={100}
                            className='rounded-full'
                            style={{ border: "2px solid #fff" }}
                            src={`${process.env.REACT_APP_API_BASEURL}${userDetails?.coverPhoto}?t=${Date.now()}`}
                            onError={(e) => {
                                const target = e.target as HTMLImageElement; // Assert the type
                                if (target) {
                                    target.src = defaultProfile;
                                }
                            }}
                        />
                    </div>
                    <div className="profile-popup-des">
                        <h1 className="title-heading font-Poppins-SemiBold font-size-18px capitalize">{`${userDetails?.firstName || ""} ${userDetails?.lastName || ""}`}</h1>
                        {userDetails?.userRole === doctor
                            ?
                            <>
                                <p className='font-size-12px font-Poppins-Regular theme-color-green flex items-center gap-1 flex-wrap'>
                                    {profile?.specailizedDetails?.map((v, i) => (
                                        <>
                                            <span key={i}>{v.name}</span>
                                            {i < profile?.specailizedDetails?.length - 1 && <span>|</span>}
                                        </>
                                    ))}
                                </p>
                                <p className='font-size-12px font-Poppins-Regular mt-1 flex gap-1 flex-wrap'>
                                    {profile?.educatuonHistory?.map((v, i) => (
                                        <>
                                            <span key={v}>{v}</span>
                                            {i < profile?.educatuonHistory?.length - 1 && <span>, </span>}
                                        </>
                                    ))}
                                </p>
                            </>
                            :
                            <p title={userDetails?.headLine || ""} className='font-size-12px font-Poppins-Regular'>{truncateString(userDetails?.headLine || "", 80)}</p>
                        }
                    </div>
                </div>
                <Link to={"/profile-view"} className='view-profile-btn font-Poppins-Medium text-center font-size-14px'>
                    View Profile
                </Link>
            </div>
            <div className="profile-popup-links">
                {/* <span className='font-Poppins-SemiBold mb-[2px] font-size-14px theme-color-green'>Account</span> */}
                {profileOptions.filter(item => item.isDisplay).map(item => (<Link className='text-[#464646] font-Poppins-Medium account-setting-hover font-size-14px' to={item.url}>{item.option}</Link>))
                }
                {userDetails?.userRole === doctor
                    &&
                    <button onClick={handleSwitchMode} className='text-[#464646] font-Poppins-Medium account-setting-hover font-size-14px text-left'>{mode === doctor ? "Switch to Health Profile" : "Switch to Doctor Profile"}</button>
                }
            </div>
            <div className="profile-popup-signout">
                <button className='text-[#707070] font-Poppins-Medium account-setting-hover font-size-14px w-full' onClick={handleLogOut}>Sign out</button>
            </div>
        </div>
    );
};

export default ProfilePopup;