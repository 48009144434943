/**
 * RevenueManagement
 * 
 * Description: This component serves as a container for various revenue-related 
 * statistics and graphs. It imports and displays components such as 
 * RevenueStatistics, EarningsGraph, PlatformRevenueGraph, ConsultationRevenue, 
 * and RevenuePercentages in a responsive layout.
 * 
 * File Name: RevenueManagement.tsx
 * Date: 01-10-2024
 */

import React from 'react';
import ConsultationRevenue from 'src/Components/AdminComponents/RevenueGraphs/ConsultationRevenue';
import EarningsGraph from 'src/Components/AdminComponents/RevenueGraphs/EarningsGraph';
import PlatformRevenueGraph from 'src/Components/AdminComponents/RevenueGraphs/PlatformRevenueGraph';
import RevenuePercentages from 'src/Components/AdminComponents/RevenueGraphs/RevenuePercentages';
import RevenueStatistics from 'src/Components/AdminComponents/RevenueStatistics';

const RevenueManagement: React.FC = () => {
    return (
        <div className="gap-marging-admin">
            <RevenueStatistics />

            <div className="parent-box">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <EarningsGraph />
                    <PlatformRevenueGraph />
                    <ConsultationRevenue />
                    <RevenuePercentages />
                </div>
            </div>
        </div>
    );
};

export default RevenueManagement;