/**
 * AddResult Component
 * 
 * Description: A form for adding test results with input fields for customer details, test information, 
 * and biomarker data. It supports image uploads, date selection, and dynamically handles various biomarker 
 * inputs based on the selected test.
 * 
 * File Name: AddResult.tsx
 * Date: 19-08-2024
 */

import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import BreadCrumb from '../../Components/GlobalComponents/BreadCrumb';
import SimpleInput from 'src/Components/SimpleInput';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import SplashScreen from 'src/Components/SplashScreen';
import { IDropDownArray, IMaleFemaleThresholds } from 'src/types/GlobalInterfaces';
import moment from 'moment';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import ResultInputs from 'src/Components/AdminComponents/ResultInputs';

// Interfaces for component state and props
interface iTest {
    productId: string;
    productName: string;
    _id: string;
};

interface IPendingTestResponse {
    customerName: {
        firstName: string;
        lastName: string;
        gender: string;
        age: string;
        [key: string]: any;
    }[];
    testArray: iTest[];
};

interface IBioMarker {
    name: string;
    shortDescription: string;
    barCategoryId: string;
    _id: string;
    maleThresholds?: IMaleFemaleThresholds;
    femaleThresholds?: IMaleFemaleThresholds;
    isShown: boolean;
    [key: string]: any;
};

interface ITestData {
    barId: string;
    biomarkerId: string;
    result: string;
};

const AddResult: React.FC = () => {
    const navigate = useNavigate();
    const { uid = "" } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const isDoctor = queryParams.get('isdoctor') === 'true';

    // State management for loading and data
    const [testLoader, setTestLoader] = useState<boolean>(false);
    const [detailLoader, setDetailLoader] = useState<boolean>(false);
    const [options, setOptions] = useState<IDropDownArray[]>([]);
    const [allBioMarkers, setAllBioMarkers] = useState<IBioMarker[]>([]);
    const [gender, setGender] = useState<string>("");
    const [dob, setDOB] = useState<string>("");
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [documentImageName, setDocumentImageName] = useState<string>('');

    // ****************** ADD TEST STATE ******************
    const [orderitemId, setOrderitemId] = useState<string>("");
    const [customerName, setCustomerName] = useState<string>("");
    const [testId, setTestId] = useState<string>("");
    const [summary, setSummary] = useState<string>("");
    const [collectionDate, setCollectionDate] = useState<string>("");
    const [testData, setTestData] = useState<ITestData[]>([]);
    const [documentImage, setDocumentImage] = useState<string | ArrayBuffer | null>(null);
    const [pracitionerNote, setPracitionerNote] = useState<string>("");
    // ****************** ADD TEST STATE ******************

    // Create a ref for the file input
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    // Function to handle image change
    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
        if (file) {
            if (file.size > maxSize) {
                toast("File size exceeds the maximum allowed size (5 MB). Please choose a smaller file.", { type: "error" });
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setDocumentImage(reader.result);
            };
            // Store the file name
            setDocumentImageName(file.name);
            reader.readAsDataURL(file);
        }
    };

    // Handle test selection
    const handleTestChange = (arr: IDropDownArray[]) => {
        setOrderitemId(arr[0]?.value)
        setTestId(arr[0]?._id);
    }; // test change

    // API_CALL: Fetch pending test data
    const fetchPendingTest = async (_id: string) => {
        setTestLoader(true);
        const { res, err } = await httpRequest<IPendingTestResponse>({ path: `/test/pending/${_id}` });
        if (res) {
            const { customerName, testArray } = res;
            setGender(customerName[0]?.gender || "");
            setDOB(customerName[0]?.age || "");
            setCustomerName(`${customerName[0]?.firstName} ${customerName[0]?.lastName}`);
            const arr = testArray.map(v => ({
                value: v?._id,
                label: v?.productName,
                _id: v?.productId,
            }));
            setOptions(arr);
        } else {
            toast(err, { type: 'error' });
        }
        setTestLoader(false);
    };

    // API_CALL: Fetch biomarker details for selected test
    const fetchTestDetail = async (id: string) => {
        setDetailLoader(true);
        const { res, err } = await httpRequest<IBioMarker[]>({ path: `/test/details/${id}` });
        if (res) {
            setAllBioMarkers(res);
            const arr = res.map(v => ({
                barId: v?.barCategoryId,
                biomarkerId: v?._id,
                result: "",
            }));
            setTestData(arr);
        } else {
            toast(err, { type: 'error' });
        }
        setDetailLoader(false);
    };

    // Handle changes to biomarker result inputs
    const handleResultChange = (id: string, value: string) => {
        setTestData(
            testData.map(v => {
                if (v?.biomarkerId === id) {
                    return {
                        ...v,
                        result: value,
                    }
                }
                return v;
            })
        );
    };

    // API_CALL: Handle form submission create result
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (!documentImage) {
            toast("Please attach reference document", { type: 'error' });
            return;
        }
        setIsLoader(true);
        const formData = new FormData();
        formData.append("orderitemId", orderitemId);
        formData.append("customerName", customerName);
        formData.append("testId", testId);
        formData.append("summary", summary);
        formData.append("collectionDate", collectionDate);
        formData.append("testData", JSON.stringify(testData));
        formData.append("pracitionerNote", pracitionerNote);
        // Convert base64 string to Blob if necessary
        if (typeof documentImage === 'string') {
            const base64String = documentImage.split(',')[1]; // Remove the data URL part
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/png' }); // Adjust MIME type as needed
            formData.append("documentImage", blob, documentImageName); // Use the file name from state
        } else if (documentImage instanceof ArrayBuffer) {
            const blob = new Blob([documentImage], { type: 'image/png' }); // Adjust MIME type as needed
            formData.append("documentImage", blob, documentImageName); // Use the file name from state
        }
        const { res, err } = await httpRequest<{ message: string;[key: string]: any }>({ method: "post", path: `/test/${uid}`, params: formData, header: { 'Content-Type': 'multipart/form-data' } });
        if (res) {
            toast(res.message, { type: 'success' });
            navigate(isDoctor ? "/doctors" : "/customers");
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Effect to fetch biomarker details when testId changes
    useEffect(() => {
        if (testId) {
            fetchTestDetail(testId);
        }
    }, [testId]);

    // Effect to fetch pending test data when uid changes
    useEffect(() => {
        if (uid) {
            fetchPendingTest(uid);
        }
    }, [uid]);

    return (
        <>
            {
                testLoader || detailLoader
                &&
                <SplashScreen />
            }
            <BreadCrumb
                current="Add Test"
                previous="dashboard"
                navigateUrl="/admin"
                btnText={"Add Test"}
                btnShow={true}
                isUpsert={true}
                onPress={() => navigate(isDoctor ? "/doctors" : "/customers")}
            />
            <div className="table-container">
                <div className="w-[80%] parent-box-scroll-bar-full mx-auto">
                    <form onSubmit={handleSubmit}>
                        <div className="admin-add-test">
                            <div className="admin-add-test-inner">
                                {/* customer name read only field */}
                                <SimpleInput
                                    labelText='Customer name'
                                    isLabel={true}
                                    value={customerName}
                                    onChange={() => { }}
                                    readOnly
                                />
                                {/* <SelectDropdown
                                loader={false}
                                options={[]}
                                isMulti={false}
                                data={[]}
                                setData={() => { }}
                                checkboxShow={false}
                                isLabel={true}
                                labelText="Doctor name"
                                isRequired={false}
                                extraClass=""
                            /> */}
                                {/* select test dropdown */}
                                <SelectDropdown
                                    loader={testLoader}
                                    options={options}
                                    isMulti={false}
                                    data={options.filter(v => v?.value === orderitemId)}
                                    setData={handleTestChange}
                                    checkboxShow={false}
                                    isLabel={true}
                                    labelText="Test name"
                                    isRequired={true}
                                    extraClass=""
                                />
                                {/* summary */}
                                <SimpleInput
                                    isRequired={true}
                                    rows={4}
                                    type='textArea'
                                    labelText='Summary'
                                    isLabel={true}
                                    value={summary}
                                    onChange={(val) => setSummary(val)}
                                />
                                {/* collection date */}
                                <SimpleInput
                                    isRequired={true}
                                    type='date'
                                    labelText='Collection date'
                                    isLabel={true}
                                    value={moment(collectionDate).format("YYYY-MM-DD")}
                                    onChange={(val) => setCollectionDate(val + "T00:00:00Z")}
                                />
                            </div>
                        </div>
                        {
                            allBioMarkers.length > 0
                            &&
                            <>
                                <div className="admin-add-test">
                                    {
                                        allBioMarkers.map((v: IBioMarker, i: number) => (
                                            <>
                                                <div key={i} className="admin-add-test-inner">
                                                    <h3 className='font-size-20px text-center my-3 font-Poppins-Medium'>{v?.name || ""}</h3>
                                                    <p className='text-center my-3 font-size-14px font-Poppins-Regular text-[#797F82]'>{v?.shortDescription || ""}</p>
                                                    <ResultInputs
                                                        key={i}
                                                        uniqueKey={Math.random()}
                                                        gender={gender}
                                                        dob={dob}
                                                        data={v}
                                                        result={testData.find(item => item.biomarkerId === v._id)?.result || ""}
                                                        onChange={handleResultChange}
                                                    />
                                                </div>
                                                {
                                                    allBioMarkers.length !== i + 1
                                                    &&
                                                    <hr className='my-3' style={{ border: '1px solid #C8C8C8' }} />
                                                }
                                            </>
                                        ))
                                    }
                                </div>
                                <div className="admin-add-test">
                                    <div className="add-test-last-textarea">
                                        <SimpleInput
                                            rows={6}
                                            type='textArea'
                                            labelText='Practitioner note'
                                            isLabel={true}
                                            value={pracitionerNote}
                                            isRequired={true}
                                            onChange={(val) => setPracitionerNote(val)}
                                        />
                                    </div>
                                </div>
                                <div className='flex justify-center my-4 items-center flex-col gap-4'>
                                    <h3 className='text-[#00443f] font-size-20px font-Poppins-Medium'>Upload reference document</h3>
                                    <input
                                        ref={fileInputRef}
                                        type='file'
                                        className='uplaod-btn font-size-14px font-Poppins-Medium'
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>
                                {/* submit button */}
                                <div className="flex justify-center">
                                    <PrimaryBtn
                                        loader={isLoader}
                                        type="submit"
                                        ButtonText="Submit"
                                        btnExtraStyle={{ backgroundColor: "#004540", borderRadius: "1.5rem" }}
                                    />
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddResult;