import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import iconRevenue from "src/assets/images/admin-dashboard/revenue-icon.png";
import httpRequest from 'src/helpers/httpRequest';

interface IItems {
    id: string;
    icon: string;
    title: string;
    value: number | null;
};

interface IResponseCount {
    monthlyRevenue: number;
    totalRevenue: number;
    totalTestRevenue: number;
    totalAppoitmentRevenue: number;
};

const RevenueStatistics: React.FC = () => {
    const [data, setData] = useState<IItems[]>([
        {
            id: "totalRevenue",
            icon: iconRevenue,
            title: "Total revenue",
            value: null,
        },
        {
            id: "monthlyRevenue",
            icon: iconRevenue,
            title: "This Months Revenue",
            value: null,
        },
        {
            id: "totalTestRevenue",
            icon: iconRevenue,
            title: "Test Revenue",
            value: null,
        },
        {
            id: "totalAppoitmentRevenue",
            icon: iconRevenue,
            title: "Appointment Revenue",
            value: null,
        }
    ]);

    const [isLoader, setIsLoader] = useState<boolean>(false);

    const fetchRevenuedStats = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ data: IResponseCount; message: string; }>({ path: "/order/statistics/revenue-monthly" });
        if (res) {
            Object.entries(res?.data).map(([key, value]) => {
                setData(prev => prev.map(v => v.id === key ? { ...v, value } : v));
            });
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchRevenuedStats();
    }, []);
    return (
        <div className="parent-box parent-box-dashboard">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
                {
                    data.map((item, index) => (
                        <div key={index} className="dashboard-sm-box">
                            <div className="dashboard-inner-box w-[80%] text-center">
                                <img className='admin-dashboard-img' src={item.icon} alt="biomarker-icon" />
                                <p className="font-Poppins-Medium whitespace-nowrap font-size-20px">
                                    {item.title}
                                </p>
                                {
                                    isLoader
                                        ?
                                        <CircularProgress size={20} className="theme-color-green" />
                                        :
                                        <p className="font-Poppins-SemiBold font-size-32px theme-color-green">
                                            {`AED ${item.value || "-"}`}
                                        </p>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default RevenueStatistics;