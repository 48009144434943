/**
 * MyTestResult Component
 * 
 * Description: This component fetches and displays the test results for a customer. It retrieves the user data, product data, and biomarker data based on a provided test ID, and renders the results using the DisplayResult component.
 * 
 * File Name: MyTestResult.tsx
 * Date: 19-08-2024
 */

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'src/Components/Customer/PageHeader';
import SplashScreen from 'src/Components/SplashScreen';
import BackIcon from "src/assets/images/back-icon.png";
import DisplayResult from 'src/Components/GlobalComponents/DisplayResult';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { IResultBiomarker, IResultDetails, IResultProduct, IResultUser } from 'src/types/GlobalInterfaces';

const MyTestResult: React.FC = () => {
    const { id = "" } = useParams(); // Extract user ID from URL parameters
    const navigate = useNavigate();

    const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage loading status

    const [userData, setUserData] = useState<IResultUser | null>(null); // State to store user data
    const [productData, setProductData] = useState<IResultProduct | null>(null); // State to store product data
    const [biomarkerData, setBiomarkerData] = useState<IResultBiomarker[]>([]); // State to store biomarker data

    // Component to display a back button in the page header
    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    // API_CALL: Function to fetch test result details
    const fetchResult = async (_id: string) => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IResultDetails>({ path: `/test/single-details/${_id}` });
        if (res) {
            const { data } = res;
            // Update states with fetched data
            setUserData(data.userData[0]);
            setProductData(data.productData[0]);
            setBiomarkerData(data.biomarkerData);
        } else {
            // Display error toast notification
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Effect to fetch result when the component mounts or when the ID changes
    useEffect(() => {
        if (id) {
            fetchResult(id);
        }
    }, [id]);

    return (
        <>
            {
                isLoader
                &&
                <SplashScreen />
            }
            <PageHeader
                title="Results"
                isButton={true}
                ButtonComponent={BackButton}
            />
            {(userData && productData) &&
                <DisplayResult
                    userData={userData}
                    productData={productData}
                    biomarkerData={biomarkerData}
                />
            }
        </>
    );
};

export default MyTestResult;