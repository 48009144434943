import axios, { Method } from 'axios';
import { getToken } from './helperFunctions';

const _baseUrl = process.env.REACT_APP_API_BASEURL;

interface IhttpRequestParams {
    method?: Method;
    path: string;
    params?: Record<string, any> | null;
    baseUrl?: string;
    header?: Record<string, string>;
    token?: string;
};

interface IHttpResponse<T> {
    res?: T;
    err?: any;
}

export default async function httpRequest<T = any>({
    header = { 'Content-Type': 'application/json' },
    method = 'get',
    path = '',
    params = null,
    baseUrl = _baseUrl,
    token = getToken(),
}: IhttpRequestParams): Promise<IHttpResponse<T>> {
    const headers: Record<string, string> = {
        ...header,
        ...(token && { Authorization: `Bearer ${token}` }),
    };

    try {
        let response;
        if (['post', 'put', 'patch', 'delete'].includes(method)) {
            response = await axios({
                method,
                url: baseUrl + path,
                headers,
                data: params, // Use params as request body
            });
        } else {
            response = await axios({
                method,
                url: baseUrl + path,
                headers,
            });
        }
        return { res: response?.data };
    } catch (error: any) {
        return { err: error?.response?.data?.message || error?.response?.data?.err || "Error" };
    }
};