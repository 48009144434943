/**
 * DoctorPublicProfile
 * 
 * Description: This component displays a doctor's public profile, including their personal information, contact details, services, and more. It also fetches the doctor's profile data from the server.
 * 
 * File Name: DoctorPublicProfile.tsx
 * Date: 14-09-2024
 */

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import AboutMe from 'src/Components/DoctorProfile/View/AboutMe';
import { IAvailability, IDoctorProfile } from 'src/types/GlobalInterfaces';
import WorkHistory from 'src/Components/DoctorProfile/View/WorkHistory';
import Education from 'src/Components/DoctorProfile/View/Education';
import Services from 'src/Components/DoctorProfile/View/Services';
import Specialization from 'src/Components/DoctorProfile/View/Specialization';
import Diseases from 'src/Components/DoctorProfile/View/Diseases';
import OnlineConsultant from 'src/Components/DoctorProfile/View/OnlineConsultant';
import Hospitals from 'src/Components/DoctorProfile/View/Hospitals';
import Reviews from 'src/Components/DoctorProfile/View/Reviews';
import DefaultBackgroundImage from "src/assets/images/welcome-hudson.png";
import defaultProfile from "src/assets/images/default_profile_image.png";
import { convertMonthsToReadableFormat } from 'src/helpers/helperFunctions';
import moment from 'moment';
import { weekDays } from 'src/lib/constans';
import SplashScreen from 'src/Components/SplashScreen';

import phoneIcon from 'src/assets/images/doctor-profile/mydoctor-phone-icon.png';
import mailIcon from 'src/assets/images/doctor-profile/mydoctor-mail-icon.png';
import locationIcon from 'src/assets/images/doctor-profile/mydoctor-location-icon.png';
import gendersIcon from 'src/assets/images/doctor-profile/mydoctor-genders-icon.png';
import birthdayIcon from 'src/assets/images/doctor-profile/mydoctor-birthday-icon.png';
import recentIcon from 'src/assets/images/doctor-profile/mydoctor-recent-icon.png';
import videoIcon from 'src/assets/images/doctor-profile/mydoctor-video-iconcamera.png';
import hospitalIcon from 'src/assets/images/doctor-profile/mydoctor-hospital-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { printCity } from 'src/Config/CityList';
import BackIcon from "../../assets/images/back-icon.png";

const booKBtn = {
    borderRadius: '100rem',
    backgroundColor: "#004540",
    fontSize: "12px",
    padding: "0.5rem 1rem",
    width: '100%',
    color: "#fff",
}; // Styles for the Book Button

const DoctorPublicProfile: React.FC = () => {
    const { id = "" } = useParams();  // Extract doctor ID from URL params

    const [isLoader, setIsLoader] = useState<boolean>(false);  // State for loader visibility
    const [data, setData] = useState<IDoctorProfile | null>(null);  // State for storing doctor profile data

    // API_CALL: Fetch profile data from the server based on the doctor ID
    const fetchDoctorProfile = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IDoctorProfile[]>({ path: `/accessdoctorside/profile/privacy/${id}` });
        if (res) {
            setData(res[0]); // Set the profile data to state
        } else {
            toast(err, { type: 'error' });  // Show error notification if the request fails
        }
        setIsLoader(false);
    };

    // useEffect: Fetch profile data when component mounts
    useEffect(() => {
        fetchDoctorProfile();
    }, [id]);

    return (
        <div style={{ minHeight: "800px" }}>
            {isLoader && <SplashScreen />}
            <Link to={'/my-doctors'}>
                <img style={{ position: "absolute" }} src={BackIcon} className="back-icon-docter-pro" />
            </Link>
            <div className="container-1480 block gap-4 h-3vw md:flex lg:flex">
                <div className="w-[80%] md:w-3/4 lg:w-3/4">
                    <div className="shadow-lg bg-white rounded-xl mb-2 pb-3 ">
                        <div className="cover-img relative">
                            <img
                                src={`${process.env.REACT_APP_API_BASEURL}${data?.backgroundPicture}`}
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement; // Assert the type
                                    if (target) {
                                        target.src = DefaultBackgroundImage;
                                    }
                                }}
                                alt="cover"
                                width="100%"
                            />
                        </div>
                        <div className="px-6 flex justify-between">
                            <div className="profile-img-uploader relative">
                                <img
                                    src={`${process.env.REACT_APP_API_BASEURL}${data?.coverPhoto}`}
                                    onError={(e) => {
                                        const target = e.target as HTMLImageElement; // Assert the type
                                        if (target) {
                                            target.src = defaultProfile;
                                        }
                                    }}
                                    alt="profile image"
                                    className="rounded-full"
                                />
                            </div>
                        </div>
                        <div className='flex gap-docter-profile'>
                            <div className="user-name w-[45%]">
                                <h4 className="font-size-32px font-Poppins-SemiBold theme-color-green capitalize">
                                    {`${data?.firstName || ""} ${data?.lastName || ""}`}
                                </h4>
                                <div>
                                    <p className="font-size-20px font-Poppins-Regular theme-color-green flex flex-wrap items-center gap-2">
                                        {data?.specailizedDetails?.map((v, i) => (
                                            <>
                                                <span key={i}>{v.name}</span>
                                                {i < data?.specailizedDetails?.length - 1 && <span>|</span>}
                                            </>
                                        ))}
                                    </p>{" "}
                                    <span className="font-size-18px font-Poppins-Regular text-[#393939]">
                                        {data?.educatuonHistory?.map((v, i) => (
                                            <span key={i}>
                                                <span key={v}>{v}</span>
                                                {i < data?.educatuonHistory?.length - 1 && <span>, </span>}
                                            </span>
                                        ))}
                                    </span>{" "}
                                    <div className="stars flex gap-1 items-center mt-2">
                                        {[1, 2, 3, 4, 5].map((v) => (
                                            <FontAwesomeIcon
                                                className={(data?.reviewStar || 0) >= v ? "text-[#facc15]" : `text-[#b7b6b1]`}
                                                key={v}
                                                fontSize="16px"
                                                icon={faStar}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="mt-6 flex gap-[40px] items-center">
                                    <div className="doctor-experience">
                                        <p className="font-size-18px font-Poppins-Regular text-[#707070] mb-2">
                                            Experience
                                        </p>
                                        <h1 className="font-size-20px font-Poppins-Medium">
                                            {convertMonthsToReadableFormat(data?.workExperienceMonthly || 0)}
                                        </h1>
                                    </div>
                                    <div className="doctor-experience">
                                        <p className="font-size-18px font-Poppins-Regular text-[#707070] mb-2">
                                            Reviews
                                        </p>
                                        <h1 className="font-size-20px font-Poppins-Medium">{data?.reviews?.length || 0}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 w-[50%] self-end gap-x-4 gap-y-2 grid-rows-3 mr-[1rem]">
                                {data?.contact &&
                                    <div className='flex gap-4 items-center'>
                                        <img src={phoneIcon} alt="phone-icon" width={15} height={15} />
                                        <p className="font-Poppins-Regular font-size-15px">{data.contact}</p>
                                    </div>
                                }
                                {data?.email &&
                                    <div className='flex gap-4 items-center'>
                                        <img src={mailIcon} alt="mail-icon" width={15} height={15} />
                                        <p className="font-Poppins-Regular font-size-15px">{data.email}</p>
                                    </div>
                                }
                                {data?.address &&
                                    <div className='flex gap-4 items-center'>
                                        <img src={locationIcon} alt="location-icon" width={15} height={15} />

                                        <p className="font-Poppins-Regular font-size-15px">
                                            {`
                                        ${printCity(data.address?.city) || ""} 
                                        ${data.address?.state || ""} 
                                        ${data.address?.address || ""}
                                        `}
                                        </p>
                                    </div>
                                }
                                {data?.gender &&
                                    <div className='flex gap-4 items-center'>
                                        <img src={gendersIcon} alt="genders-icon" width={15} height={15} />
                                        <p className="font-Poppins-Regular font-size-15px capitalize">{data.gender}</p>
                                    </div>
                                }
                                {data?.dateofBirth &&
                                    <div className='flex gap-4 items-center'>
                                        <img src={birthdayIcon} alt="birthday-icon" width={15} height={15} />
                                        <p className="font-Poppins-Regular font-size-15px">{moment(data.dateofBirth).format("DD-MM-YYYY")}</p>
                                    </div>
                                }
                                {data?.createdAt &&
                                    <div className='flex gap-4 items-center'>
                                        <img src={recentIcon} alt="recent-icon" width={15} height={15} />
                                        <p className="font-Poppins-Regular font-size-15px">{`User Since ${moment(data.createdAt).format('DD MMM, YYYY')}`}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <>
                        {data?.aboutMe &&
                            <AboutMe
                                isMyProfile={false}
                                aboutMe={data.aboutMe}
                            />
                        }
                        {data?.experiences &&
                            <WorkHistory
                                isMyProfile={false}
                                experiences={data.experiences}
                            />
                        }
                        {data?.experiences &&
                            <Education
                                isMyProfile={false}
                                education={data.education}
                            />
                        }
                        {data?.experiences &&
                            <Services
                                isMyProfile={false}
                                servicesDetails={data.servicesDetails}
                            />
                        }
                        {data?.diseasesDetails &&
                            <Diseases
                                isMyProfile={false}
                                diseasesDetails={data.diseasesDetails}
                            />
                        }
                        {data?.specailizedDetails &&
                            <Specialization
                                isMyProfile={false}
                                specailizedDetails={data.specailizedDetails}
                            />
                        }
                        {data?.availability &&
                            <OnlineConsultant
                                isMyProfile={false}
                                availability={data.availability}
                            />
                        }
                        {data?.timings &&
                            <Hospitals
                                isMyProfile={false}
                                timings={data.timings}
                            />
                        }
                        <Reviews
                            id={id}
                        />
                    </>
                </div>
                <div className="w-[25%]">
                    {data?.availability &&
                        <div className="parent-box">
                            <div className="flex gap-3 items-center">
                                <img src={videoIcon} alt="video-icon" width={18} height={18} />
                                <h1 className="font-Poppins-SemiBold font-size-20px text-[#004540]">
                                    Online Consultation
                                </h1>
                            </div>
                            <div className="my-4">
                                <div className="flex py-3 px-1 items-center justify-between border-b border-[#D9D9D9]">
                                    <p className='font-Poppins-Regular font-size-16px text-[#646464]'>Fee:</p>
                                    <p className='font-Poppins-Medium font-size-16px text-[#202020]'>AED {data.availability.fees}</p>
                                </div>
                                {weekDays.map((day) => { // Iterate over ordered days
                                    const slots = data.availability?.[day as keyof IAvailability];
                                    if (!slots) return null;
                                    return (
                                        <div className="flex py-3 px-1 items-center justify-between border-b border-[#D9D9D9]">
                                            <p className='font-Poppins-Regular font-size-16px text-[#646464] capitalize'>{day}</p>
                                            <div className="text-end">
                                                {Array.isArray(slots) && (
                                                    slots.map(([startTime, endTime], index) => (
                                                        <p key={index} className='font-Poppins-Medium font-size-16px text-[#202020]'>
                                                            <React.Fragment key={index}>
                                                                <span>{moment(startTime, "HH:mm").format("h:mm A")}</span>
                                                                <span className="mx-1">to</span>
                                                                <span>{moment(endTime, "HH:mm").format("h:mm A")}</span>
                                                                {/* {index < slots.length - 1 && <span>,</span>} */}
                                                            </React.Fragment>
                                                        </p>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='flex mt-8'>
                                <Link
                                    className="text-center"
                                    style={booKBtn}
                                    to={`/book-appointment/${id}/${data.availability._id}/view`}
                                >Book Video Consultation</Link>
                            </div>
                        </div>
                    }
                    {(data?.timings && data.timings.length) ?
                        data.timings.map((item: IAvailability, index: number) => (
                            <div key={index} className="parent-box">
                                <div className="flex gap-3 items-center">
                                    <img src={hospitalIcon} alt="video-icon" width={18} height={18} />
                                    <h1 className="font-Poppins-SemiBold font-size-20px text-[#004540] capitalize">
                                        {item.hospitalName}
                                    </h1>
                                </div>
                                <div className="my-4">
                                    <div className="flex py-3 px-1 items-center justify-between border-b border-[#D9D9D9]">
                                        <p className='font-Poppins-Regular font-size-16px text-[#646464]'>Fee:</p>
                                        <p className='font-Poppins-Medium font-size-16px text-[#202020]'>AED {item.fees}</p>
                                    </div>
                                    {weekDays.map((day) => { // Iterate over ordered days
                                        const slots = data.timings[index]?.[day as keyof IAvailability];
                                        if (!slots) return null;
                                        return (
                                            <div className="flex py-3 px-1 items-center justify-between border-b border-[#D9D9D9]">
                                                <p className='font-Poppins-Regular font-size-16px text-[#646464] capitalize'>{day}</p>
                                                <div className="text-end">
                                                    {Array.isArray(slots) && (
                                                        slots.map(([startTime, endTime], index) => (
                                                            <p key={index} className='font-Poppins-Medium font-size-16px text-[#202020]'>
                                                                <React.Fragment key={index}>
                                                                    <span>{moment(startTime, "HH:mm").format("h:mm A")}</span>
                                                                    <span className="mx-1">to</span>
                                                                    <span>{moment(endTime, "HH:mm").format("h:mm A")}</span>
                                                                    {/* {index < slots.length - 1 && <span>,</span>} */}
                                                                </React.Fragment>
                                                            </p>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className='flex mt-8'>
                                    <Link
                                        className="text-center"
                                        style={booKBtn}
                                        to={`/book-appointment/${id}/${item._id}/view`}
                                    >Book Appointment</Link>
                                </div>
                            </div>
                        ))
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );
};

export default DoctorPublicProfile;