/**
 * SpecializationsList Component
 * 
 * Description: This component renders a list of specializations that can be filtered, paginated, and managed 
 * (create, edit, delete). It allows for both viewing and upserting (adding/editing) of specializations. 
 * The component fetches data from the backend, handles search and pagination, and provides a confirmation 
 * modal for deleting items.
 * 
 * Features:
 * - Displays a list of specializations with an image, name, creation date, and actions (edit/delete).
 * - Allows adding new specializations or editing existing ones through the `UpsertSpecialization` component.
 * - Supports search and pagination to navigate through the list of specializations.
 * - Implements a confirmation modal before deleting a specialization.
 * 
 * File Name: SpecializationsList.tsx
 * Date: 05-09-2024
 */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import httpRequest from 'src/helpers/httpRequest';
import emtyImg from 'src/assets/images/emty-img.png';
import editIcon from 'src/assets/images/edit-icon.png';
import deleteIcon from 'src/assets/images/delete-icon.png';
import { IqueryParams, ISpecializationItem } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import ConfirmationModal from 'src/Components/GlobalComponents/ConfirmationModal';
import UpsertSpecialization from 'src/Components/AdminComponents/UpsertSpecialization';

interface ISpecializationResponse {
    documents: ISpecializationItem[];
    paginated: IPaginated;
};

const SpecializationsList: React.FC = () => {
    const [isUpsert, setIsUpsert] = useState<boolean>(false); // State to manage the upsert mode (create/edit specialization)
    const [isEdit, setIsEdit] = useState<ISpecializationItem | boolean>(false); // State to determine if the component is in edit mode

    // States for loading status, pagination, and customer data
    const [deleteLoader, setDeleteLoader] = useState<boolean>(false); // State to manage the loader during specialization deletion
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [allSpecializations, setAllSpecializations] = useState<ISpecializationItem[]>([]);
    const [deleteModal, setDeleteModal] = useState<boolean>(false); // State to control the visibility of the delete confirmation modal
    const [deleteId, setDeleteId] = useState<string>("");  // State to store the ID of the specialization to be deleted

    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        search: "",
        counter: 1
    });

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            search: txt,
            page: 1
        }));
    };

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Function to set the component to edit mode and populate it with specialization data
    const handleEdit = (obj: ISpecializationItem) => {
        setIsUpsert(true);
        setIsEdit(obj);
    };

    // Function to show the delete confirmation modal and set the ID of the specialization to be deleted
    const handleDelete = (id: string) => {
        setDeleteModal(true);
        setDeleteId(id);
    };

    // Function to close the delete confirmation modal
    const deleteModalClose = () => {
        setDeleteModal(false);
        setDeleteId("");
    };

    // Function to toggle between create and edit mode
    const handleClose = () => {
        setIsEdit(false);
        setIsUpsert(prev => !prev);
    };

    // Function to update the list of categories after creating or editing a category
    const handleUpdateList = () => {
        setQueryParams(prev => ({
            ...prev,
            counter: (prev.counter ?? 0) + 1,
        }));
        setIsUpsert(false);
    };

    // API_CALL: Function to delete a specialization by its ID
    const deleteSpecialization = async () => {
        setDeleteLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({ method: "delete", path: `/provider/special/${deleteId}`, params: {} });
        if (res) {
            toast(res.message, { type: 'success' });
            setQueryParams(prev => ({
                ...prev,
                counter: (prev.counter ?? 0) + 1,
                page: allSpecializations?.length > 1 ? prev?.page : prev?.page > 1 ? prev?.page - 1 : prev?.page
            }));
            setDeleteModal(false);
            setDeleteId("");
        } else {
            toast(err, { type: 'error' });
        }
        setDeleteLoader(false);
    };

    // API_CALL: Fetch the list of specializations when the component mounts and change queryparams
    useEffect(() => {
        (async () => {
            setIsLoader(true);
            let { counter, ...remainingParams } = queryParams;
            const { res, err } = await httpRequest<ISpecializationResponse>({ path: `/provider/special/get/all?${qs.stringify(remainingParams)}` });
            if (res) {
                let { documents = [], paginated } = res;
                setAllSpecializations(documents);
                setPaginated(paginated);
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        })();
    }, [queryParams]);

    return (
        <>
            <ConfirmationModal
                show={deleteModal}
                onClose={deleteModalClose}
                title="Are you sure you want to delete?"
                leftBtnText="No"
                rightBtnText="Yes"
                onConfirm={deleteSpecialization}
                loader={deleteLoader}
            />
            <BreadCrumb
                current="specialization"
                previous="dashboard"
                navigateUrl="/admin"
                btnText={"Add Specialization"}
                onPress={handleClose}
                btnShow={true}
                isUpsert={isUpsert}
            />
            {isUpsert
                ?
                <UpsertSpecialization
                    onSuccess={handleUpdateList}
                    isEdit={isEdit}
                />
                :
                <div className="table-container">
                    <SearchFilterInput
                        onChangeText={handleChangeText}
                    />
                    <TableWrapper
                        isLoading={isLoader}
                        page={paginated?.currentPage || 1}
                        total={paginated?.totalPages || 0}
                        thead={["S.No", "Icon", "Specialization", "Date", "Action"]}
                        onChange={handlePageChange}
                        columnWidth={"6% 22% 35% 22% 15%"}
                    >
                        <div className="tbody-content-height">
                            {allSpecializations?.map((item: ISpecializationItem, index: number) => (
                                <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 22% 35% 22% 15%" }}>
                                    <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                    <li className='action-last-colum-img'>
                                        <img
                                            src={`${process.env.REACT_APP_API_BASEURL}${item.SpecailImage}`}
                                            alt="category"
                                            onError={(e) => {
                                                const target = e.target as HTMLImageElement; // Type assertion
                                                target.src = emtyImg; // Handle error by setting default image
                                            }}
                                        />
                                    </li>
                                    <li>{item.name}</li>
                                    <li>{moment(item.createdAt).format("DD-MM-YYYY")}</li>
                                    <li className='action-last-colum'>
                                        <img onClick={() => handleEdit(item)} src={editIcon} alt="" />
                                        <img onClick={() => handleDelete(item?._id)} src={deleteIcon} alt="" />
                                    </li>
                                </ul>
                            ))}
                        </div>
                    </TableWrapper>
                </div>
            }
        </>
    );
};

export default SpecializationsList;