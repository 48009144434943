/**
 * Hospitals Component
 * 
 * Description: Displays hospital schedules including name, fees, and available timings. If `isMyProfile` 
 * is true, an edit icon allows modifications to the hospital details.
 * 
 * Props:
 * - isMyProfile (boolean): Shows edit icon if true.
 * - timings (IAvailability[]): Array of hospital timings.
 * 
 * File Name: Hospitals.tsx
 * Date: 02-09-2024
 */

import { RootState } from '@store/store';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import edit from "../../../assets/images/awesome-edit.svg";
import { weekDays } from 'src/lib/constans';
import moment from 'moment';
import { IAvailability } from 'src/types/GlobalInterfaces';

const Hospitals: React.FC<{ isMyProfile: boolean; timings: IAvailability[] }> = ({ isMyProfile = false, timings = [] }) => {
    const { profile } = useSelector((store: RootState) => store.doctor);
    return (
        <div className="parent-box parent-box-margin-issue relative">
            <h2 className='font-size-20px font-Poppins-SemiBold theme-color-green mb-1'>Hospitals</h2>
            {timings?.map((item, index) => (
                <div key={index} className="hospitals-schedule">
                    <div className='w-[90%]'>
                        <div className='flex items-center justify-between'>
                            <h2 className='font-size-20px font-Poppins-SemiBold theme-color-green mb-1'>{item.hospitalName}</h2>
                            <p className="font-size-20px font-Poppins-SemiBold theme-color-green">AED {item.fees}</p>
                        </div>
                        <div className="mt-6 grid grid-cols-3 xl:grid-cols-4 gap-y-8 gap-x-6">
                            {weekDays.map((day) => { // Iterate over ordered days
                                const slots = timings[index]?.[day as keyof IAvailability];
                                if (!slots) return null;
                                return (
                                    <div key={day} className="schedule">
                                        <p className='font-Poppins-Regular font-size-20px capitalize'>{day}</p>
                                        {Array.isArray(slots) && (
                                            slots.map(([startTime, endTime], index) => (
                                                <p key={index} className='font-Poppins-Regular font-size-16px flex items-center gap-2'>
                                                    <React.Fragment key={index}>
                                                        <span>{moment(startTime, "HH:mm").format("h:mm A")}</span>
                                                        <span>to</span>
                                                        <span>{moment(endTime, "HH:mm").format("h:mm A")}</span>
                                                        {/* {index < slots.length - 1 && <span>,</span>} */}
                                                    </React.Fragment>
                                                </p>
                                            ))
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            ))}
            {isMyProfile
                &&
                <Link to="/physical-availability" className="edit-pro-icon absolute top-2 right-4">
                    <img src={edit} alt="edit-icon" />
                </Link>
            }
        </div>
    );
};

export default Hospitals;