/**
 * App Component
 * 
 * Description: This component serves as the main entry point for the application, handling socket connections and fetching unread notifications. 
 * It initializes the router and manages the application's global state through Redux.
 * 
 * File Name: App.tsx
 * Date: [insert current date here]
 * Marker: 28-07-2024
 */

import React, { useEffect } from 'react';

// Import styles
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

// Import the router
import Router from './router';
import { getToken } from './helpers/helperFunctions';
import { initializeSocket, turnOffSocket, turnOnSocket } from './Sockets/socket';
import { useDispatch } from 'react-redux';
import httpRequest from './helpers/httpRequest';
import { setCounter } from 'src/Store/reducer';

const App: React.FC = () => {
  const dispatch = useDispatch();
  // Function to handle socket initialization
  const initializeSockets = async () => {
    await initializeSocket(dispatch);
    await turnOnSocket();
  };

  // Function to handle socket disconnection
  const disconnectSockets = async () => {
    await turnOffSocket();
  };

  const fetchunReadCount = async () => {
    const { res, err } = await httpRequest<{ messageUnreadCount: number; unreadNotificationCount: number; }>({ path: "/notification-setting/un-read/count" });
    if (res) {
      dispatch(setCounter({ key: 'messageUnreadCount', val: res.messageUnreadCount }));
      dispatch(setCounter({ key: 'unreadNotificationCount', val: res.unreadNotificationCount }));
    } else {
      console.log("fetchunReadCount", err);
    }
  };

  useEffect(() => {
    const token = getToken();
    const manageSocketConnections = async () => {
      if (token) {
        fetchunReadCount();
        await disconnectSockets();
        await initializeSockets();
      } else {
        await turnOffSocket();
      }
    };

    manageSocketConnections();

    return () => {
      turnOffSocket(); // Clean up socket connections when component unmounts (if needed)
    };
  }, [getToken()]);
  return (
    <Router />
  );
};

export default App;