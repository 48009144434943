/**
 * WelComeChat
 * 
 * Description: This component displays a welcome message with an image, providing an introduction to the chat feature.
 * 
 * File Name: WelComeChat.tsx
 * Date: 23-09-2024
 */

import React from 'react';
import WelcomeImage from "src/assets/images/welcome-chat.png";

const WelComeChat: React.FC = () => {
    return (
        <div className="Chat-message flex flex-1 items-center">
            <div className="w-full flex flex-col gap-10">
                <img
                    src={WelcomeImage}
                    alt="welcome"
                    className="mx-auto"
                />
                <p className="font-Poppins-Regular text-[#464646] w-[50%] mx-auto font-size-20px text-center">Welcome to messages, where you can effortlessly send and receive messages across your connections, and share files seamlessly.</p>
            </div>
        </div>
    );
};

export default WelComeChat;