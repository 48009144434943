import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { IqueryParams, ISubCategory } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import UpsertSubCategory from 'src/Components/PharmacyComponents/UpsertSubCategory';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';

interface IResponce {
    documents: ISubCategory[];
    paginated: IPaginated;
};

const PSubCategories: React.FC = () => {
    const [isUpsert, setIsUpsert] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<ISubCategory | false>(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [displayData, setDisplayData] = useState<ISubCategory[]>([]);
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        search: "",
        bool: false,
    });
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    });

    // Function to update the list of categories after creating or editing a category
    const handleUpdateList = () => {
        setQueryParams(prev => ({
            ...prev,
            bool: !prev.bool,
        }));
        setIsUpsert(false);
    };

    // Function change search text for api query params
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            search: txt
        }));
    };

    // API_CALL: Function to fetch all sub categories of pharmacy based on query parameters
    const fetch = async () => {
        setIsLoader(true);
        const { bool, ...actualParams } = queryParams;
        // const { res, err } = await httpRequest<IResponce>({ path: `/product/all?${qs.stringify(actualParams)}` });
        // if (res) {
        //     const { documents = [], paginated = { currentPage: 1, totalPages: 1 } } = res;
        //     setDisplayData(documents);
        //     setPaginated(paginated);
        // } else {
        //     toast(err, { type: 'error' });
        // }
        setIsLoader(false);
    };

    useEffect(() => {
        fetch();
    }, [queryParams]);
    return (
        <>
            <BreadCrumb
                current="Sub Categories"
                previous="dashboard"
                navigateUrl="/admin"
                btnText={"Add Category"}
                btnShow={true}
                onPress={() => setIsUpsert(prev => !prev)}
                isUpsert={isUpsert}
            />
            <div className="table-container">
                {
                    isUpsert
                        ?
                        <UpsertSubCategory
                            onSuccess={handleUpdateList}
                            isEdit={isEdit}
                        />
                        :
                        <>
                            <SearchFilterInput
                                onChangeText={handleChangeText}
                            />
                        </>
                }
            </div>
        </>
    );
};

export default PSubCategories;