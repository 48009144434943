/**
 * ReviewLoader Component
 * 
 * This component displays a loading skeleton for review items,
 * with a specified number of loader instances based on the `length` prop.
 * It visually indicates that review data is being fetched.
 * 
 * Props:
 * - length (optional): Number of review loaders to display (default is 1).
 */

import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from '@mui/material';
import React from 'react';

const ReviewLoader: React.FC<{ length?: number }> = ({ length = 1 }) => {
    return (
        <>
            {Array.from({ length: length }, (_, index) => (
                <div key={index} className="reviews rounded-[25px] bg-[#FFFCF1] shadow-[3px_3px_10px_rgba(0,0,0,0.12)] w-full grid grid-cols-[1.5fr_3fr_1fr] gap-2 items-center mb-4 px-4 py-6">
                    <div className="flex items-center justify-center flex-col gap-2 grow">
                        <Skeleton variant="circular" width={45} height={45} />
                        <Skeleton variant="text" width={100} className="font-size-18px" />
                        <Skeleton variant="text" width={80} className="font-size-14px" />
                    </div>
                    <div>
                        <div className="flex gap-2 items-center justify-start mb-3">
                            {[1, 2, 3, 4, 5].map((v) => (
                                <FontAwesomeIcon
                                    fade={true}
                                    className="text-[#b7b6b1]"
                                    key={v}
                                    fontSize="16px"
                                    icon={faStar}
                                />
                            ))}
                        </div>
                        <Skeleton variant="text" className="font-size-15px" />
                        <Skeleton width={150} variant="text" className="font-size-15px" />
                    </div>
                    <div className="self-start ml-auto pr-2">
                        <Skeleton width={60} variant="text" className="font-size-13px" />
                    </div>
                </div>
            ))
            }
        </>
    );
};

export default ReviewLoader;