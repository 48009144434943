/**
 * FaqsTab Component
 *
 * A component for managing FAQs within a tab. It allows users to add, edit, and delete FAQ entries. It includes a form for submitting new FAQs and utilizes modals for editing and deleting entries.
 *
 * File Name: FaqsTab.tsx
 * Date: 31-07-24
 */

import React, { FormEvent, useState } from 'react';
import editIcon from '../../../assets/images/edit-icon.png';
import deleteIcon from '../../../assets/images/delete-icon.png';
import TableWrapper from '../../GlobalComponents/TableWrapper';
import { IProductItems } from 'src/types/GlobalInterfaces';
import SimpleInput from 'src/Components/SimpleInput';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import { generateRandomKey } from 'src/helpers/helperFunctions';
import ConfirmationModal from 'src/Components/GlobalComponents/ConfirmationModal';
import InputsModal from 'src/Components/GlobalComponents/InputsModal';

interface IQna { question: string; answer: string; _id: string }

const FaqsTab: React.FC<{
    state: IProductItems;
    setState: React.Dispatch<React.SetStateAction<IProductItems>>;
    tabChange: () => void;
}> = ({
    state,
    setState,
    tabChange,
}) => {

        // State hooks for managing form input, modal visibility, and FAQ data
        const [question, setQuestion] = useState<string>("");
        const [answer, setAnswer] = useState<string>("");
        const [faqEdit, setFaqEdit] = useState<boolean>(false);
        const [isDelete, setIsDelete] = useState<boolean>(false);
        const [faqId, setFaqId] = useState<string>("");
        const [editQuestion, setEditQuestion] = useState<string>("");
        const [editAnswer, setEditAnswer] = useState<string>("");

        // Handles form submission to add a new FAQ
        const handleSubmit = (event: FormEvent) => {
            event.preventDefault();
            // Create new FAQ object with a unique ID
            let obj: IQna = {
                question: question,
                answer: answer,
                _id: generateRandomKey()
            };
            // Update the state with the new FAQ
            setState(prev => ({
                ...prev,
                qna: [...prev.qna, obj]
            }));
            // Reset input fields
            setQuestion("");
            setAnswer("");
        };
        // Opens edit modal with pre-filled data for the selected FAQ
        const handleEdit = (obj: IQna): void => {
            setFaqEdit(true);
            setFaqId(obj?._id);
            setEditQuestion(obj?.question);
            setEditAnswer(obj?.answer);
        };
        // Opens delete confirmation modal for the selected FAQ
        const handleDelete = (id: string): void => {
            setIsDelete(true);
            setFaqId(id);
        };
        // Closes modals and resets edit/delete state
        const modalClose = (): void => {
            setFaqEdit(false);
            setIsDelete(false);
            setEditQuestion("");
            setEditAnswer("");
        };
        // Deletes the selected FAQ from state
        const deleteFaq = (): void => {
            setState(prev => ({
                ...prev,
                qna: prev?.qna?.filter(v => v?._id !== faqId),
            }));
            modalClose();
        };
        // Updates the selected FAQ with new data
        const updateFaq = () => {
            let Arr = state?.qna?.map(v => {
                if (v?._id === faqId) {
                    return {
                        ...v,
                        question: editQuestion,
                        answer: editAnswer
                    };
                }
                return v;
            });
            setState(prev => ({
                ...prev,
                qna: Arr,
            }));
            modalClose();
        };

        return (
            <>
                {/* Modal for editing FAQ */}
                <InputsModal
                    btnText='Update'
                    show={faqEdit}
                    onClose={modalClose}
                    onSubmit={updateFaq}
                    arr={[
                        { value: editQuestion, setValue: (val) => setEditQuestion(val), title: "Question", type: "textArea", isRequired: true },
                        { value: editAnswer, setValue: (val) => setEditAnswer(val), title: "Answer", type: "textArea", isRequired: true }
                    ]}
                />
                {/* Modal for confirming FAQ deletion */}
                <ConfirmationModal
                    show={isDelete}
                    onClose={modalClose}
                    title="Are you sure you want to delete?"
                    leftBtnText="No"
                    rightBtnText="Yes"
                    onConfirm={deleteFaq}
                    loader={false}
                />
                {/* Table displaying existing FAQs */}
                <TableWrapper
                    thead={["S.No", "Question", "Answer", "Action",]}
                    total={state?.qna?.length < 1 ? 0 : 1}
                    columnWidth={"10% 40% 40% 10%"}
                >
                    {state?.qna?.map((v: IQna, i: number) => (
                        <ul key={i} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "10% 40% 40% 10%" }}>
                            <li>{i + 1}</li>
                            <li className="break-all">{v?.question || ""}</li>
                            <li className="break-all">{v?.answer || ""}</li>
                            <li className='action-last-colum'>
                                <img onClick={() => handleEdit(v)} src={editIcon} alt="" />
                                <img onClick={() => handleDelete(v?._id)} src={deleteIcon} alt="" />
                            </li>
                        </ul>
                    ))}
                </TableWrapper>
                {/* Form for adding new FAQs */}
                <div className='products-tabs-content-main'>
                    <form onSubmit={handleSubmit}>
                        <div className='products-tabs-content'>
                            <SimpleInput
                                value={question}
                                onChange={(val) => setQuestion(val)}
                                isLabel={true}
                                labelText={"Question"}
                                isRequired={true}
                                type={"textArea"}
                                rows={3}
                            />
                            <SimpleInput
                                value={answer}
                                onChange={(val) => setAnswer(val)}
                                isLabel={true}
                                labelText={"Answer"}
                                isRequired={true}
                                type={"textArea"}
                                rows={5}
                            />
                        </div>
                        <div className='flex justify-between my-6'>
                            <PrimaryBtn
                                ButtonText="Add New Faq's"
                                btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                                type='submit'
                            />
                            <PrimaryBtn
                                ButtonText="Next"
                                btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                                onPress={tabChange}
                                type='button'
                            />
                        </div>
                    </form>
                </div>
            </>
        );
    };

export default FaqsTab;