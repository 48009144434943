import { io, Socket } from "socket.io-client";
import { getToken } from "src/helpers/helperFunctions";
import { Dispatch } from "@reduxjs/toolkit";
import SimplePeer from 'simple-peer'; // WebRTC peer connection library
import { setCallNotifications } from "src/Store/VideoCall";
import { pushNewMessage, useStatusToogle } from "src/Store/chat";
import { IChatItem } from "src/types/GlobalInterfaces";
import { counterIncreament } from "src/Store/reducer";

const SOCKET_URL = process.env.REACT_APP_API_BASEURL || ""; // server url
let socket: Socket | null = null;
let peer: SimplePeer.Instance | null = null; // WebRTC peer instance

// Function to initialize the socket connection
const initializeSocket = (dispatch: Dispatch) => {
    return new Promise<void>((resolve, reject) => {
        if (socket) {
            return resolve(); // Socket is already initialized
        }
        socket = io(SOCKET_URL, {
            autoConnect: false,
            auth: {
                token: getToken()
            },
            transports: ['websocket'],
            pingInterval: 25000, // how often to ping (in milliseconds).
            pingTimeout: 60000 // time after which the connection is considered timed-out (in milliseconds).

        } as any); // socket dashboard

        // Add event listeners for socket connection
        socket.on("connect", () => {
            console.log("SOCKET_URL", SOCKET_URL);
            resolve();
        });

        // Add event listeners for customerCall
        socket.on("customerCall", (data: any) => {
            console.log("Received customer call", data);
            dispatch(setCallNotifications(data));
        });

        // user status online/offline
        socket.on("userStatus", (data: { statusOnline: boolean; userId: string }) => {
            console.log("socket____useStatusToogle", data);
            dispatch(useStatusToogle(data));
        });

        // user receive new message
        socket.on("receiveMessage", (data: { document: IChatItem }) => {
            dispatch(pushNewMessage(data.document));
            dispatch(counterIncreament("messageUnreadCount"));
            console.log("socket____receiveMessage", data);
        });

        // receive new notification
        socket.on("notification", (data: any) => {
            dispatch(counterIncreament("unreadNotificationCount"));
            console.log("notification", data);
        });

        socket.on("disconnect", () => {
            console.log("disconnect socket");
        });

        // Connect the socket
        socket.connect();
    });
};

// Function to turn on the socket connection
const turnOnSocket = () => {
    if (socket) {
        console.log("CONNECT SERVER");
        return Promise.all([socket.connect()]);
    } else {
        return Promise.reject(new Error("Socket is not initialized"));
    }
};

// Function to turn off the socket connection
const turnOffSocket = () => {
    console.log("DISCONNECT SERVER");
    if (socket) {
        socket.disconnect();
    }
    if (peer) {
        peer.destroy(); // Close the WebRTC peer connection
        peer = null;
    }
};

export { initializeSocket, turnOnSocket, turnOffSocket };