/**
 * PopularTestsAndSpecializations
 * 
 * Description: A React component that displays popular tests and specializations in two sections, 
 * including a bar graph for test counts and a pie chart for specializations. 
 * 
 * File Name: PopularTestsAndSpecializations.tsx
 * Date: 30-09-2024
 */

import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { IAnalytics } from 'src/types/GlobalInterfaces';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Text } from 'recharts';

const colors: string[] = [
    '#F6B667', '#80E7DA', '#FF8E84', '#FF4C61', '#FFA4C4',
    '#46C173', '#4E95D2', '#A66F37', '#A383FF', '#FFAA7D',
    '#6CC6A1', '#FFBD44', '#FF57BB', '#3DB9FF', '#FF7676',
    '#B877FF', '#FFD076', '#72EAD4', '#6B8EF1', '#FF98A1',
    '#FFA867', '#8CFF95', '#FFEB67', '#FF6E6E', '#B2FF6E',
    '#6EBFFF', '#FF94D6', '#FF8C4C', '#95FF8C', '#FF4C88'
];

interface IPopularTestsAndSpecializations {
    loader?: boolean;
    allCount: number;
    graphTitle: string;
    data: IAnalytics[];
}

const PopularTestsAndSpecializations: React.FC<IPopularTestsAndSpecializations> = ({
    loader = false,
    allCount = 0,
    graphTitle = "",
    data = [],
}) => {

    const [totalCount, setTotalCount] = useState<number>(0);
    const [sortedData, setSortedData] = useState<IAnalytics[]>([]);

    useEffect(() => {
        if (data.length) {
            const _total = data.reduce((accumulator, item) => accumulator + item.count, 0);
            setTotalCount(_total);
            const sortedData = data.sort((a, b) => b.count - a.count);
            setSortedData(sortedData);
        }
    }, [data]);
    // Custom label rendering function
    const renderCustomLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        value,
    }: {
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        value: number;
    }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <Text x={x} y={y} fill="white" verticalAnchor="middle" textAnchor='middle' dominantBaseline="center">
                {value}
            </Text>
        );
    };
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
                {/* <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">{title1}</h2> */}
                {loader &&
                    <div className="text-center">
                        <CircularProgress style={{ color: "#004540" }} size={20} />
                    </div>
                }
                <div className="flex flex-col justify-between h-full">
                    {sortedData.slice(0, 5).map((item: IAnalytics) => (
                        <div key={item._id} className="border border-[#004641] p-2 rounded-md">
                            <div className="flex justify-between mb-2">
                                <span className="font-Poppins-Medium font-size-16px mb-0">{item.name}</span>
                                <span className="font-Poppins-Medium font-size-16px mb-0">{item.count}</span>
                            </div>

                            <div className="relative w-full h-1 bg-gray-200 rounded">
                                <div className="absolute top-0 left-0 h-1 bg-[#004641] rounded" style={{ width: `${(item.count / totalCount) * 100 || 0}%` }}></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                {/* <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">{title2}</h2> */}
                {loader &&
                    <div className="text-center">
                        <CircularProgress style={{ color: "#004540" }} size={20} />
                    </div>
                }
                <div className='user-graph-inner'>
                    <div
                        className='Biomarkers-graph'
                    >
                        <div className='bio-graph-bio-legend-main'>
                            {data.filter(v => v.count > 0).map((v: IAnalytics, i: number) => (
                                <div
                                    className='bio-graph-bio-legend'
                                    key={`legend-${i}`}>
                                    <div
                                        className='bio-graph-bio-color'
                                        style={{
                                            backgroundColor: colors[i],
                                            borderRadius: '50%',
                                        }}
                                    ></div>
                                    <span>{v.name}</span>
                                </div>
                            ))}
                        </div>
                        <div className='relative h-[100%] w-[100%]'>
                            <div className='relative pie-chart-container w-[100%]'>
                                <ResponsiveContainer >
                                    <PieChart >
                                        <Pie
                                            data={data.filter(v => v.count > 0)}
                                            dataKey="count"
                                            nameKey="name"
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={120}
                                            innerRadius={70}
                                            startAngle={200}
                                            endAngle={1000}
                                            paddingAngle={0.5}
                                            label={renderCustomLabel}
                                            labelLine={false}
                                        >
                                            {data.filter(v => v.count > 0).map((_: any, i: number) => (
                                                <Cell key={`cell-${i}`} fill={colors[i]} />
                                            ))}
                                        </Pie>

                                        <Tooltip position={{ x: -30, y: -10 }} />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>


                            <div
                                className='bio-graph-bio-count'
                            >
                                <span>{allCount}</span>
                                <p>{graphTitle}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopularTestsAndSpecializations;