import React, { useState } from 'react';
import { IPharmaBrand } from 'src/types/GlobalInterfaces';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import ImageUpload from '../GlobalComponents/ImageUplaod';
import SimpleInput from '../SimpleInput';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';

interface IProps {
    onSuccess: () => void;
    isEdit: IPharmaBrand | false;
};

const UpsertPharmaBrand: React.FC<IProps> = ({
    onSuccess = () => { },
    isEdit = false,
}) => {
    const [name, setName] = useState<string>("");
    const [brandLogo, setBrandLogo] = useState<File | null | string>(null);
    const [isLoader, setIsLoader] = useState<boolean>(false);

    const handleSubmit = async () => {
        if (!name) {
            toast("Brand name is mandatory!", { type: 'error' });
        } else if (!brandLogo) {
            toast("Brand image is mandatory!", { type: 'error' });
        } else {
            setIsLoader(true);
            const formData = new FormData();
            formData.append("name", name);
            formData.append("brandLogo", brandLogo);
            const path = isEdit && typeof isEdit !== 'boolean' ? `/brand/edit/${isEdit._id}` : "/brand/add";

            const { res, err } = await httpRequest<IPharmaBrand>({ method: isEdit ? "put" : "post", path, params: formData, header: { "Content-Type": "multipart/form-data" } });
            if (res) {
                toast(`Brand has been ${isEdit ? "updated" : "added"} successfully.`, { type: 'success' });
                onSuccess();
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        }
    };

    return (
        <div className="table-container">
            <div className='admin-create-role-main'>
                <div className='admin-create-role'>
                    <div className='create-operators-res '>
                        <h3 className='font-size-18px text-[#2F2F31] mb-1 font-Poppins-Regular'>Brand Logo</h3>
                        <ImageUpload
                            image={brandLogo}
                            onImageChange={setBrandLogo}
                        />
                        <SimpleInput
                            value={name}
                            onChange={setName}
                            isLabel={true}
                            labelText="Brand Name"
                            isRequired={true}
                        />
                    </div>
                </div>
                <div className='flex justify-end my-6'>
                    <PrimaryBtn
                        loader={isLoader}
                        ButtonText="Save"
                        btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        onPress={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

export default UpsertPharmaBrand;