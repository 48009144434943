import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Control, Controller, UseFormSetValue, FieldErrors } from 'react-hook-form';
import { ICreateOrder } from 'src/types/GlobalInterfaces';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import SelectDropdown from '../GlobalComponents/SelectDropdown';
import { cityList } from 'src/Config/CityList';

interface CheckOutProps {
    control: Control<ICreateOrder>;
    setValue: UseFormSetValue<ICreateOrder>; // Proper type for setValue
    errors: FieldErrors<ICreateOrder>;
    submit: () => void;
}

const CheckOut: React.FC<CheckOutProps> = ({
    control, setValue, errors, submit
}) => {
    const navigate = useNavigate();
    return (
        <div>
            <h2 className='font-size-22px theme-color-green font-Poppins-Regular'>Express Checkout</h2>
            <p className='font-size-16px text-[#3B3B3B] font-Poppins-Regular'>Please enter your details below to complete your purchase</p>
            <div className='checkout-box-left'>
                {/* <h3 className='font-size-18px text-[#959595] font-Poppins-Regular'>Details for pathology referral</h3> */}
                {/* <div className='relative checkbox-checkout'>
                    <img src={checkBox} alt="check box" />
                    <input className='font-size-16px font-Poppins-Regular text-[#959595]' placeholder='The referring practitioner may view my result' type="text" />
                </div> */}
                <div>
                    <Controller
                        name="firstName"
                        control={control}
                        rules={{ required: 'First Name is required' }}
                        render={({ field }) => (
                            <input
                                className='font-size-16px font-Poppins-Regular text-[#959595]'
                                placeholder='First Name'
                                type="text"
                                {...field}
                            />
                        )}
                    />
                    {/* Handle validation errors */}
                    {errors.firstName && <p className="font-size-16px text-[#ff0000] mb-1">{errors.firstName.message}</p>}
                </div>
                <div>
                    <Controller
                        name="lastName"
                        control={control}
                        rules={{ required: 'Last Name is required' }}
                        render={({ field }) => (
                            <input
                                className='font-size-16px font-Poppins-Regular text-[#959595]'
                                placeholder='Last Name'
                                type="text"
                                {...field}
                            />
                        )}
                    />
                    {/* Handle validation errors */}
                    {errors.lastName && <p className="font-size-16px text-[#ff0000] mb-1">{errors.lastName.message}</p>}
                </div>
                <div>
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: 'Email is required' }}
                        render={({ field }) => (
                            <input
                                className='font-size-16px font-Poppins-Regular text-[#959595]'
                                placeholder='Email Address'
                                type="text"
                                {...field}
                            />
                        )}
                    />
                    {/* Handle validation errors */}
                    {errors.email && <p className="font-size-16px text-[#ff0000] mb-1">{errors.email.message}</p>}
                </div>
                <div className='flex gap-4'>
                    <div className="checkout-date-sex">
                        <Controller
                            name="date"
                            control={control}
                            rules={{ required: 'Date of Birth is required' }}
                            render={({ field }) => (
                                <>
                                    <label className='font-size-16px text-[#959595] font-Poppins-Regular' htmlFor="">MM/DD/YYYY</label>
                                    <input
                                        type="date"
                                        className='font-size-16px font-Poppins-Regular text-[#959595]'
                                        placeholder='Date of birth'
                                        {...field}
                                    />
                                </>
                            )}
                        />
                        {/* Handle validation errors */}
                        {errors.date && <p className="font-size-16px text-[#ff0000] mb-1">{errors.date.message}</p>}
                    </div>
                    <div className="checkout-date-sex">
                        <Controller
                            name="gender"
                            control={control}
                            rules={{ required: 'Date if Birth is required' }}
                            render={({ field }) => (
                                <>
                                    <label className='font-size-16px text-[#959595] font-Poppins-Regular' htmlFor="">Sex</label>
                                    <div className='checkout-date-sex flex gap-4'>
                                        <span
                                            className={`font-size-16px text-[#959595] font-Poppins-Regular ${field.value === 'male' ? 'male-female' : ''}`}
                                            onClick={() => setValue('gender', "male")} // Ensure consistent value
                                        >
                                            M
                                        </span>
                                        <span
                                            className={`font-size-16px text-[#959595] font-Poppins-Regular ${field.value === 'female' ? 'male-female' : ''}`}
                                            onClick={() => setValue('gender', "female")} // Ensure consistent value
                                        >
                                            F
                                        </span>
                                    </div>
                                </>
                            )}
                        />
                        {/* Handle validation errors */}
                        {errors.gender && <p className="font-size-16px text-[#ff0000] mb-1">{errors.gender.message}</p>}
                    </div>
                </div>
                <div className='checkout-city'>
                    <Controller
                        name="city"
                        control={control}
                        rules={{ required: 'City is required' }}
                        render={({ field }) => (
                            <SelectDropdown
                                    options={cityList}
                                    data={cityList.filter(v => v?.value === field.value)}
                                    setData={(e) => field.onChange(e[0]?.value || '')}
                                    isMulti={false}
                                />
                        )}
                    />
                    {/* Handle validation errors */}
                    {errors.city && <p className="font-size-16px text-[#ff0000] mb-1">{errors.city.message}</p>}
                </div>
                <div>
                    <Controller
                        name="postCode"
                        control={control}
                        rules={{
                            required: 'PostCode is required',
                            pattern: {
                                value: /^[0-9]{4,7}$/, // Ensures the value is exactly 4 digits
                                message: 'Post Code must be between 4 and 7 digits'
                            }
                        }}
                        render={({ field }) => (
                            <input
                                className='font-size-16px font-Poppins-Regular text-[#959595]'
                                inputMode="numeric"
                                minLength={4}
                                maxLength={7}
                                placeholder='PostCode'
                                type="text"
                                {...field}
                                onInput={(e) => {
                                    // Ensures the input value matches the pattern for length constraints
                                    const value = e.currentTarget.value;
                                    if (!/^[0-9]{0,7}$/.test(value)) {
                                        e.currentTarget.value = value.slice(0, -1);
                                    }
                                }}
                            />
                        )}
                    />
                    {/* Handle validation errors */}
                    {errors.postCode && <p className="font-size-16px text-[#ff0000] mb-1">{errors.postCode.message}</p>}
                </div>
                <div>
                    <Controller
                        name="address"
                        control={control}
                        rules={{ required: 'Address is required' }}
                        render={({ field }) => (
                            <input
                                className='font-size-16px font-Poppins-Regular text-[#959595]'
                                placeholder='Address'
                                type="text"
                                {...field}
                            />
                        )}
                    />
                    {/* Handle validation errors */}
                    {errors.address && <p className="font-size-16px text-[#ff0000] mb-1">{errors.address.message}</p>}
                </div>
                <div>
                    <Controller
                        name="mobile"
                        control={control}
                        rules={{ required: 'Mobile is required' }}
                        render={({ field }) => (
                            <input
                                className='font-size-16px font-Poppins-Regular text-[#959595]'
                                inputMode="numeric"
                                minLength={10}
                                maxLength={15}
                                placeholder='Phone Number'
                                type="text"
                                {...field}
                                onInput={(e) => {
                                    // Ensures the input value matches the pattern for length constraints
                                    const value = e.currentTarget.value;
                                    if (!/^[0-9]{0,15}$/.test(value)) {
                                        e.currentTarget.value = value.slice(0, -1);
                                    }
                                }}
                            />
                        )}
                    />
                    {/* Handle validation errors */}
                    {errors.mobile && <p className="font-size-16px text-[#ff0000] mb-1">{errors.mobile.message}</p>}
                </div>
            </div>
            <div className="flex justify-between mb-5">
                <PrimaryBtn
                    ButtonText="Back"
                    onPress={() => navigate(-1)}
                    btnExtraStyle={{ borderRadius: "1.5rem" }}
                />
                <PrimaryBtn
                    ButtonText="Order"
                    onPress={() => submit()}
                    btnExtraStyle={{ borderRadius: "1.5rem", backgroundColor: "#004540" }}
                />
            </div>
        </div>
    );
};

export default CheckOut;