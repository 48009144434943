import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';
import Cart from './Cart';
import doctor from './doctor';
import VideoCall from './VideoCall';
import chatSlice from './chat';

// Create a type for the store's dispatch function
export const store = configureStore({
    reducer: {
        appReducer: reducer,
        cart: Cart,
        doctor,
        VideoCall,
        chat: chatSlice,
    },
});

// Define types for RootState and AppDispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;