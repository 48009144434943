/**
 * UserVitals Component
 * 
 * Description: This component manages the display of completed and pending biomarker vitals for a user.
 * It includes functionality to fetch, display, and update vitals data with dropdowns for detailed information.
 * The component handles expanding and collapsing vitals details for both completed and to-be-completed vitals.
 * Additionally, the component fetches the necessary data from the server and updates the vitals upon user interactions.
 * 
 * File Name: UserVitals.tsx
 * Date: 24-08-2024
 */

import { faChevronDown, faChevronUp, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { ICompletedVitals, IAddSingleBio } from 'src/types/GlobalInterfaces';
import SingleBioMarkerResult from './SingleBioMarkerResult';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import SingleBioMarkerHistory from './SingleBioMarkerHistory';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { returnColor } from 'src/helpers/helperFunctions';

const UserVitals: React.FC = () => { // id is user id
    const { userDetails } = useSelector((store: RootState) => store.appReducer); // Extracting user details from the Redux store

    // State for managing loading indicators
    const [completedLoader, setCompletedLoader] = useState<boolean>(false);
    const [pendingLoader, setPendingLoader] = useState<boolean>(false);

    const [completedList, setCompletedList] = useState<ICompletedVitals[]>([]); // State for storing the list of completed vitals
    const [completedDropDown, setCompletedDropDown] = useState<string | false>(false); // State to manage the dropdown visibility for completed vitals

    const [toBeCompletedList, setToBeCompletedList] = useState<IAddSingleBio[]>([]); // State for storing the list of to-be-completed vitals
    const [toBeCompletedDropDown, setToBeCompletedDropDown] = useState<string | false>(false); // State to manage the dropdown visibility for to-be-completed vitals

    /**
     * Toggles the dropdown for completed vitals.
     * @param id - The id of the vitals item to toggle.
     */
    const toogleCompleted = (id: string) => {
        setCompletedDropDown(completedDropDown === id ? false : id);
    };

    /**
      * Toggles the dropdown for to-be-completed vitals.
      * @param id - The id of the vitals item to toggle.
      */
    const toogleToBeCompleted = (id: string) => {
        setToBeCompletedDropDown(toBeCompletedDropDown === id ? false : id);
    };

    /**
     * API_CALL: Fetch the completed vitals data from the server. Triggered when the component mounts.
     */
    const fetchCompletedVitals = async () => {
        setCompletedLoader(true);
        const { res, err } = await httpRequest<ICompletedVitals[]>({ path: `/healthprofile/biomarker/details/${userDetails?._id}/completed?vital=${"true"}` });
        if (res) {
            setCompletedList(res);
        } else {
            toast(err, { type: 'error' });
        }
        setCompletedLoader(false);
    };

    /**
   * API_CALL: Fetch the to-be-completed vitals data from the server. Triggered when the component mounts.
   */
    const fetchToBeCompletedVitals = async () => {
        setPendingLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: { biomarkerData: IAddSingleBio[];[key: string]: any }; }>({ path: `/healthprofile/biomarker/details/${userDetails?._id}?vital=${"true"}` });
        if (res) {
            setToBeCompletedList(res?.data?.biomarkerData || []);
        } else {
            toast(err, { type: 'error' });
        }
        setPendingLoader(false);
    };

    /**
     * Update vitals history after successfully editing an item.
     * Resets the dropdown and fetches the latest completed vitals data.
     */
    const updateVitalsHistory = () => {
        setCompletedDropDown(false); // hide dropdown
        fetchCompletedVitals(); // Re-fetch the completed vitals list
    };

    /**
     * Handles moving a vitals item from the "to be completed" list to the "completed" list.
     * Updates the state and triggers a refetch of completed vitals.
     */
    const addToVitals = () => {
        setToBeCompletedList(prev => prev?.filter(v => v?._id !== toBeCompletedDropDown)); //remove from toBeCompletedList;
        setToBeCompletedDropDown(false); // hide dropdown
        fetchCompletedVitals(); // completed List api again cal;
    };

    /**
     * Fetch the vitals data when the component mounts.
     */
    useEffect(() => {
        fetchCompletedVitals();
        fetchToBeCompletedVitals();
    }, []);

    return (
        <>
            <div className='health-vitals-content'>
                <h2 className='font-size-20px theme-color-green font-Poppins-SemiBold'>Completed Vitals</h2>
                {completedLoader &&
                    <div className="text-center mt-3">
                        <CircularProgress style={{ width: 24, height: 24, color: "#00443f" }} />
                    </div>
                }
                <div className='health-vitals-row-main'>
                    {
                        completedList.length > 0
                            ?
                            completedList.map((item: ICompletedVitals, index: number) => (
                                <div key={index} className='health-vitals-row-grid relative'>
                                    <span style={{ borderLeftColor: returnColor(item.biomarkerColour) }} className='font-size-16px health-vitals-border-left text-white font-Poppins-Regular'>{item.name}</span>
                                    <span className='font-size-16px flex justify-center text-white font-Poppins-Regular'>{`${item.value} ${item.isShown ? item.measurement : ""}`}</span>
                                    <span className='font-size-16px flex justify-center  text-white font-Poppins-Regular'>{moment(item.date).format("DD-MM-YYYY")}</span>
                                    <span className='flex justify-center '><FontAwesomeIcon onClick={() => toogleCompleted(item._id)} icon={faPencil} /></span>
                                    <div style={{ backgroundColor: returnColor(item.biomarkerColour) }} className='health-vitals-row-circle-dot'></div>
                                    {completedDropDown === item._id && (
                                        <div className='health-vitals-dropdown'>
                                            <SingleBioMarkerHistory
                                                uid={userDetails?._id}
                                                _date={userDetails?.date}
                                                _gender={userDetails?.gender}
                                                bioId={completedDropDown}
                                                onSuccess={updateVitalsHistory}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))
                            :
                            <p className="font-Poppins-Regular font-size-15px text-center">No Items</p>
                    }
                </div>
            </div>
            <hr className='my-4' />
            <div className='health-vitals-content'>
                <h2 className='font-size-20px theme-color-green font-Poppins-SemiBold'>To be Completed</h2>
                {
                    pendingLoader
                    &&
                    <div className="text-center mt-3">
                        <CircularProgress style={{ width: 24, height: 24, color: "#00443f" }} />
                    </div>
                }
                <div className='health-vitals-row-main'>
                    {
                        toBeCompletedList.length > 0
                            ?
                            toBeCompletedList.map((item: IAddSingleBio, index: number) => (
                                <div key={index} className='health-vitals-row-grid-comp relative'>
                                    <span className='font-size-16px text-white font-Poppins-Regular'>{item.name}</span>
                                    <span className='font-size-16px flex justify-center  text-white font-Poppins-Regular'>{item.isShown ? item.measurement : ""}</span>
                                    <span className='flex justify-end'><FontAwesomeIcon onClick={() => toogleToBeCompleted(item._id)} icon={toBeCompletedDropDown === item._id ? faChevronUp : faChevronDown} /></span>
                                    {toBeCompletedDropDown === item._id && (
                                        <div className='health-vitals-dropdown'>
                                            <SingleBioMarkerResult
                                                uid={userDetails?._id}
                                                _date={userDetails?.date}
                                                _gender={userDetails?.gender}
                                                item={item}
                                                onSuccess={addToVitals}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))
                            :
                            <p className="font-Poppins-Regular font-size-15px text-center">No Items</p>
                    }
                </div>
            </div>
        </>
    );
};

export default UserVitals;