/**
 * ChangeEmail Component
 * 
 * Description:
 * This component provides a form for users to change their email address. It includes fields for the current email, new email,
 * and confirm email. It performs validation to ensure that the new and confirm emails match. On submission, it makes an API call
 * to update the email and displays appropriate success or error messages based on the response.
 * 
 * Props: None
 * 
 * File Name: ChangeEmail.tsx
 * Date: 10-08-2024
 * 
 * Marker: DOC_START
 */

import React, { useState } from 'react';
import SimpleInput from '../SimpleInput';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { useForm, Controller } from "react-hook-form";
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { twoFactorKey } from 'src/lib/constans';
import { removeAuth } from 'src/Store/reducer';
import { removeToken } from 'src/helpers/helperFunctions';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { AppDispatch } from '@store/store';
import moment from 'moment';

interface FormValues {
  currentEmail: string;
  newEmail: string;
  confirmEmail: string;
}; // Define the shape of the form values

interface iAPIResponse {
  message: string;
  [key: string]: any
}; // Define the shape of the API response

const ChangeEmail: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoader, setIsLoader] = useState<boolean>(false); // loader state

  // State for email, new email & confirm email
  const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm<FormValues>({
    defaultValues: {
      currentEmail: "",
      newEmail: "",
      confirmEmail: "",
    }
  });

  // Custom validation function to ensure new and confirm emails match
  const validateEmail = (value: string) => {
    const { newEmail } = getValues();
    return value === newEmail || 'Email do not match';
  };

  const redirectToOtpPage = () => {
    const baseUrl = process.env.REACT_APP_WEBSITE_URL;
    const targetUrl = `${baseUrl}/otp`;
    window.location.href = targetUrl;
  };

  // API_CALL: API call for customer change email
  const onSubmit = async (data: FormValues) => {
    setIsLoader(true);
    const { confirmEmail, ...payload } = data;
    const { res, err } = await httpRequest<iAPIResponse>({ method: "put", path: "/customer/change-email", params: payload });
    if (res) {
      toast(res?.message || "Success!", { type: 'success' });

      // Store relevant information in localStorage
      let obj = { email: payload.newEmail, otpExpire: moment().add(2, 'minutes').toISOString() };
      const cookieOptionsCross = {
        path: "/",
        domain: process.env.NEXT_APP_DOMAIN, // Add domain here
      };
      Cookies.set(twoFactorKey as string, JSON.stringify(obj), cookieOptionsCross);

      redirectToOtpPage(); // navigate to otp page
      dispatch(removeAuth()); // logout
      removeToken();
      // Reset form fields
      setValue("currentEmail", "");
      setValue("newEmail", "");
      setValue("confirmEmail", "");
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };

  return (
    <div className='parent-box parent-box-docter-margin'>
      <h2 className='title-heading font-Poppins-SemiBold font-size-20px pb-5'>Change Email</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="wellcome-login-admin flex flex-col">
        <div>
          <div className="mb-2">
            <Controller
              name="currentEmail"
              control={control}
              rules={{ required: 'Email is required' }}
              render={({ field }) => (
                <SimpleInput
                  isLabel={true}
                  labelText='Current Email'
                  type="email"
                  extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                  {...field}
                />
              )}
            />
            {errors.currentEmail && <p className="font-size-14 text-[#ff0000]">{errors.currentEmail.message}</p>}
          </div>
          <div className="mb-2">
            <Controller
              name="newEmail"
              control={control}
              rules={{ required: 'New Email is required' }}
              render={({ field }) => (
                <SimpleInput
                  isLabel={true}
                  labelText='New Email'
                  type="Email"
                  extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                  {...field}
                />
              )}
            />
            {errors.newEmail && <p className="font-size-14 text-[#ff0000]">{errors.newEmail.message}</p>}
          </div>
          <div className="mb-2">
            <Controller
              name="confirmEmail"
              control={control}
              rules={{
                required: 'Confirm Email is required',
                validate: validateEmail
              }}
              render={({ field }) => (
                <SimpleInput
                  isLabel={true}
                  labelText='Confirm Email'
                  type="email"
                  extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                  {...field}
                />
              )}
            />
            {errors.confirmEmail && <p className="font-size-14 text-[#ff0000]">{errors.confirmEmail.message}</p>}
          </div>
        </div>
        <div>
          <PrimaryBtn
            loader={isLoader}
            type="submit"
            ButtonText="Update"
            btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem", padding: "8px 32px" }}
          />
        </div>
      </form>
    </div>
  );
};

export default ChangeEmail;