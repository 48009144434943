/**
 * PlatformSetting Component
 * 
 * Description: This component manages platform settings, allowing users to view, edit, and delete commission settings.
 * It also handles pagination and displays a confirmation modal for deletions.
 * 
 * File Name: PlatformSetting.tsx
 * Date: 27-09-2024
 */

import React, { useEffect, useState } from 'react';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import { ICommisionItems } from 'src/types/GlobalInterfaces';
import editIcon from 'src/assets/images/edit-icon.png';
import deleteIcon from 'src/assets/images/delete-icon.png';
import UpsertPlatformSetting from 'src/Components/AdminComponents/UpsertPlatformSetting';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import ConfirmationModal from 'src/Components/GlobalComponents/ConfirmationModal';

const PlatformSetting: React.FC = () => {
    const [isUpsert, setIsUpsert] = useState<boolean>(false); // State to manage the upsert mode (create/edit role)
    const [isEdit, setIsEdit] = useState<ICommisionItems | boolean>(false); // State to determine if the component is in edit mode

    // Function to toggle between create and edit mode
    const handleClose = () => {
        setIsEdit(false);
        setIsUpsert(prev => !prev);
    };

    // Function to set the component to edit mode and populate it with platform-setting data
    const handleEdit = (obj: ICommisionItems) => {
        setIsUpsert(true);
        setIsEdit(obj);
    };

    // *************** DELETE STATES & FUNCTIONS *************** //
    // Function to show the delete confirmation modal and set the ID of the platform-setting to be deleted
    const [deleteModal, setDeleteModal] = useState<boolean>(false); // State to control the visibility of the delete confirmation modal
    const [deleteId, setDeleteId] = useState<string>("");  // State to store the ID of the platform-setting to be deleted
    const [deleteLoader, setDeleteLoader] = useState<boolean>(false); // State to control the visibility of the delete confirmation modal
    const handleDelete = (id: string) => {
        setDeleteModal(true);
        setDeleteId(id);
    };

    // Function to close the delete confirmation modal
    const deleteModalClose = () => {
        setDeleteModal(false);
        setDeleteId("");
    };

    // API_CALL: Function to delete a commission by its ID
    const deleteCommission = async () => {
        setDeleteLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({ method: "delete", path: `/commission/${deleteId}`, params: {} });
        if (res) {
            toast(res.message || "Success", { type: 'success' });
            setData(prev => prev.filter(v => v._id !== deleteId));
            setDeleteModal(false);
            setDeleteId("");
        } else {
            toast(err, { type: 'error' });
        }
        setDeleteLoader(false);
    };
    // *************** DELETE STATES & FUNCTIONS *************** //

    // States for loading status, pagination, and customer data
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [data, setData] = useState<ICommisionItems[]>([]);

    // Function to update the list of categories after creating or editing a biomarker
    const handleUpdateList = (item: ICommisionItems) => {
        if (isEdit) {
            setData(prev =>
                prev.map(v =>
                    v?._id === item?._id ? item : v
                )
            );
        } else {
            setData(prev => [item, ...prev]);
        }
        setIsUpsert(false);
    };

    // API_CALL: Fetch all commissions from the server
    const fetch = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ICommisionItems[]>({
            path: "/commission/all"
        });
        if (res) {
            setData(res || []);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetch();
    }, []);

    return (
        <>
            <ConfirmationModal
                show={deleteModal}
                onClose={deleteModalClose}
                title="Are you sure you want to delete?"
                leftBtnText="No"
                rightBtnText="Yes"
                onConfirm={deleteCommission}
                loader={deleteLoader}
            />
            <BreadCrumb
                current="Commission"
                previous="dashboard"
                navigateUrl="/admin"
                btnText={"Add New Commission"}
                onPress={handleClose}
                btnShow={true}
                isUpsert={isUpsert}
            />
            {isUpsert
                ?
                <UpsertPlatformSetting
                    onSuccess={handleUpdateList}
                    isEdit={isEdit}
                />
                :
                <div className="table-container">
                    <TableWrapper
                        isLoading={isLoader}
                        page={1}
                        total={data.length ? 1 : 0}
                        thead={["S.No", "Specialization Name", "Commission", "Action"]}
                        columnWidth={"6% 59% 20% 15%"}
                    >
                        <div className='tbody-content-height'>
                            {data?.map((item: ICommisionItems, index: number) => (
                                <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 59% 20% 15%" }}>
                                    <li>{index + 1}</li>
                                    <li className="capitalize">{item?.type}</li>
                                    <li>{item.commission}%</li>
                                    <li className="action-last-colum">
                                        <img
                                            onClick={() => handleEdit(item)}
                                            src={editIcon}
                                            alt=""
                                        />
                                        <img
                                            onClick={() => handleDelete(item._id)}
                                            src={deleteIcon}
                                            alt=""
                                        />
                                    </li>
                                </ul>
                            ))}
                        </div>
                    </TableWrapper>
                </div>
            }
        </>
    );
};

export default PlatformSetting;