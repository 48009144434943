/**
 * AppointmentHistory
 * 
 * Description: This component fetches and displays the appointment history, including handling requests for rescheduling or completing appointments. It shows appointment details and allows users to approve or decline requests.
 * 
 * File Name: AppointmentHistory.tsx
 * Date: 09-09-2024
 */

import React, { useEffect, useState } from 'react';
import icon from 'src/assets/images/appointment-view-calander-icon.png';
import PrimaryBtn from './primaryBtn';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { admin, operator } from 'src/lib/constans';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

interface IHistory {
    _id: string;
    userId: string;
    appoinmentDate: string;
    startTime: string;
    endTime: string;
    appoinmentId: string;
    isOnlineAppoinment: true;
    comment: string;
    title: string;
    logCategory?: string;
    star?: number;
    requestType: "reschedule" | "completed";
    status: "requested" | "approved" | "declined";
    createdAt: string;
    firstName: string;
    lastName: string;
};

const AppointmentHistory: React.FC<{ id: string; reCall?: boolean; isEditModal: (obj: { star: number, comment: string }) => void }> = ({ id = "", reCall = false, isEditModal = () => { } }) => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);

    const [status, setStatus] = useState<"" | "history">("");
    const [bool, setBool] = useState<boolean>(false); // for recall the history
    const [isLoader, setIsLoader] = useState<boolean>(false); // fetch history loader
    const [approvedLoader, setApprovedLoader] = useState<boolean>(false); // approved loader
    const [declinedLoader, setDeclinedLoader] = useState<boolean>(false); // declined loader
    const [history, setHistory] = useState<IHistory[]>([]);

    const handleEditReview = (item: IHistory) => {
        const { star = 0, comment = "" } = item;
        isEditModal({ star, comment });
    };

    // delete review
    const [deleteReviewLoader, setDeleteReviewLoader] = useState<boolean>(false);
    // API_CALL: delete review
    const handleDeleteReview = async (historyId: string) => {
        setDeleteReviewLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({ method: "delete", path: `/review/${id}`, params: {} });
        if (res) {
            toast(res?.message || "Success", { type: 'success' });
            setHistory(prev => prev.filter(v => v._id !== historyId));
        } else {
            toast(err, { type: 'error' });
        }
        setDeleteReviewLoader(false);
    };
    // delete review

    // API_CALL: Approve the request
    const handleApprovedRequest = async (appoinmentId: string, userId: string, type: string) => {
        setApprovedLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({ path: `/reshedule/approved/${appoinmentId}/${userId}?type=${type}` });
        if (res) {
            toast(res?.message || "", { type: 'success' });
            setBool(prev => !prev);
        } else {
            toast(err, { type: 'error' });
        }
        setApprovedLoader(false);
    };

    // API_CALL: Decline the request
    const handleDeclinedRequest = async (appoinmentId: string, userId: string, type: string) => {
        setDeclinedLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({ path: `/reshedule/declined/${appoinmentId}/${userId}?type=${type}` });
        if (res) {
            toast(res?.message || "", { type: 'success' });
            setBool(prev => !prev);
        } else {
            toast(err, { type: 'error' });
        }
        setDeclinedLoader(false);
    };

    // API_CALL: Fetch appointment history
    const fetchHistory = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IHistory[]>({ path: `/reshedule/history/${id}?status=${status}` });
        if (res) {
            setHistory(res);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (id) fetchHistory();
    }, [id, reCall, bool, status]);

    return (
        <>
            <div className='border-b border-[#707070] pb-6 w-[90%]'>
                <div className='flex gap-2'>
                    <button onClick={() => setStatus("")} className={`font-Poppins-SemiBold font-size-20px py-1 px-3 text-center ${status === "" ? "theme-color-green border-b-[5px] border-[#00443F]" : ""}`}>
                        Request
                    </button>
                    <p className='text-3xl text-[#aeaeae]'>|</p>
                    <button onClick={() => setStatus("history")} className={`font-Poppins-SemiBold font-size-20px py-1 px-3 text-center ${status === "history" ? "theme-color-green border-b-[5px] border-[#00443F]" : ""}`}>
                        History
                    </button>
                </div>
            </div>
            {isLoader &&
                <div className="text-center mt-5">
                    <CircularProgress size={20} style={{ color: "#004540" }} />
                </div>
            }
            {history.map((item: IHistory, index: number) => (
                <div key={index} className="pt-6 w-[90%]">
                    <div className="flex items-center justify-center">
                        <p className="font-Poppins-SemiBold font-size-15px theme-color-green">
                            {moment(item?.createdAt).format("ddd D MMM")}
                        </p>
                    </div>
                    <div className="grid grid-cols-[.5fr_9.5fr] gap-[20px] mt-2">
                        <div className='relative z-10 flex items-center justify-center'>
                            {index < history.length - 1 && (
                                <div className="absolute top-[23%] left-[50%] translate-x-[-50%] w-[5px] h-[140%] bg-[#00443F]"></div>
                            )}
                            <div className="flex items-center justify-center rounded-full bg-[#00443F] self-start w-[40px] h-[40px] mt-3 relative z-20">
                                <img src={icon} alt="calander-icon" />
                            </div>
                        </div>
                        <div className='p-4 border-[2px] border-[#00443F] rounded-[13px] '>
                            <div className="flex gap-2 items-center justify-between">
                                <div>
                                    <div className='flex gap-2 items-center'>
                                        <p className="font-Poppins-SemiBold font-size-15px capitalize">
                                            {`${item?.firstName || ""} ${item?.lastName || ""}`}
                                        </p>
                                        {item?.logCategory === "review" &&
                                            <div className="stars flex gap-1 items-center mt-2">
                                                {[1, 2, 3, 4, 5].map((v) => (
                                                    <FontAwesomeIcon
                                                        className={(item.star || 0) >= v ? "text-[#facc15]" : `text-[#b7b6b1]`}
                                                        key={v}
                                                        fontSize="12px"
                                                        icon={faStar}
                                                    />
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <p className="font-Poppins-Regular font-size-15px">
                                        {item?.title}
                                    </p>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    {(item?.logCategory === "review" && (item.userId === userDetails?._id || userDetails?.userRole === admin || userDetails?.userRole === operator)) &&
                                        <>
                                            <PrimaryBtn
                                                type="button"
                                                ButtonText='Edit'
                                                onPress={() => handleEditReview(item)}
                                                btnExtraStyle={{
                                                    borderRadius: "100rem",
                                                    padding: '5px 25px',
                                                    backgroundColor: "#004540",
                                                    fontSize: '10px'
                                                }}
                                            />
                                            <PrimaryBtn
                                                loader={deleteReviewLoader}
                                                type="button"
                                                ButtonText='Delete'
                                                onPress={() => handleDeleteReview(item._id)}
                                                btnExtraStyle={{
                                                    borderRadius: "100rem",
                                                    padding: '5px 25px',
                                                    backgroundColor: "#DF0000",
                                                    fontSize: '10px'
                                                }}
                                            />
                                        </>
                                    }
                                    {(item?.status === "requested" && item?.userId !== userDetails?._id) &&
                                        <>
                                            <PrimaryBtn
                                                loader={approvedLoader}
                                                type="button"
                                                ButtonText='Approve'
                                                onPress={() => handleApprovedRequest(item?.appoinmentId, item?.userId, item?.requestType)}
                                                btnExtraStyle={{
                                                    borderRadius: "100rem",
                                                    padding: '5px 25px',
                                                    backgroundColor: "#004540",
                                                    fontSize: '10px'
                                                }}
                                            />
                                            <PrimaryBtn
                                                loader={declinedLoader}
                                                type="button"
                                                ButtonText='Decline'
                                                onPress={() => handleDeclinedRequest(item?.appoinmentId, item?.userId, item?.requestType)}
                                                btnExtraStyle={{
                                                    borderRadius: "100rem",
                                                    padding: '5px 25px',
                                                    backgroundColor: "#DF0000",
                                                    fontSize: '10px'
                                                }}
                                            />
                                        </>
                                    }
                                    <p className="font-Poppins-Regular font-size-15px ml-1">
                                        {moment(item?.createdAt, "HH:mm").format("h:mm A")}
                                    </p>
                                </div>
                            </div>
                            <div className="my-3">
                                <p className="font-Poppins-Regular font-size-15px">
                                    {item?.comment || ""}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default AppointmentHistory;