/**
 * Component Name: AddToCartModal
 * Description: This component displays a modal to inform the user that a test has been added to the cart. It provides options to either proceed to checkout or continue shopping.
 * File Name: AddToCartModal.tsx
 * Date: 13-08-2024
 */

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

// Define the props type for the ConfirmationModal component
interface IModalProps {
    show?: boolean; // Boolean to control the visibility of the modal
    onClose?: () => void; // Function to handle closing the modal
};

const AddToCartModal: React.FC<IModalProps> = ({
    show = false, // Boolean to control the visibility of the modal
    onClose = () => { }, // Function to handle closing the modal
}) => {
    // Style for the modal container
    const customeStyle: React.CSSProperties = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgb(255, 255, 255)",
        maxWidth: 500,
        width: "100%",
        padding: "30px 12px",
        borderRadius: 8
    };
    // Style for the close button container
    const backBtnMaain: React.CSSProperties = {
        position: "absolute",
        top: -10,
        right: -10,
        backgroundColor: "red",
        borderRadius: 100,
        width: 20,
        height: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };
    // Style for the close button text
    const backBtn: React.CSSProperties = {
        color: "white",
    };
    // Style for the confirm button
    const greenBtn: React.CSSProperties = {
        padding: "5px 24px",
        backgroundColor: "#00443F",
        border: "1px solid #00443F",
        color: "white",
        borderRadius: 5,
    };
    // Style for the outline button
    const greenOutlineBtn: React.CSSProperties = {
        padding: "5px 24px",
        border: "1px solid #00443F",
        color: "#00443F",
        borderRadius: 5,
    };
    return (
        <Modal
            open={show}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={customeStyle}>
                <button onClick={onClose} style={backBtnMaain}>
                    <FontAwesomeIcon className='font-size-16px' icon={faXmark} size="2x" style={backBtn} />
                </button>
                <div className="text-center">
                    <h1 className="title-heading font-Poppins-Regular font-size-25px mb-3">Test Added to Cart!</h1>
                    <p className="mb-3 font-size-20px">Your test has been added to the cart.</p>
                    <div className="flex items-center justify-center gap-4">
                        <Link to="/checkout-payment" className="font-size-20px" style={greenBtn}>Checkout</Link>
                        <button onClick={onClose} className="font-size-20px" style={greenOutlineBtn}>Continue Shopping</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AddToCartModal;