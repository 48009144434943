import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMicrophone,
    faPaperPlane,
    faSpinner,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { IChatItem } from 'src/types/GlobalInterfaces';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/Store/store';
import { sendNewMessage } from 'src/Store/chat';

const Audiocustom: React.FC<{ receiverId: string; }> = ({ receiverId = "" }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [isStart, setIsStart] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [elapsedTime, setElapsedTime] = useState<string>('00:00');
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [isBlinking, setIsBlinking] = useState<boolean>(false);
    const [chunks, setChunks] = useState<Blob[]>([]);

    const calculateElapsedTime = (startTime: Date): string => {
        const currentTime = new Date();
        const elapsedMilliseconds = currentTime.getTime() - startTime.getTime();
        const minutes = Math.floor(elapsedMilliseconds / 60000);
        const seconds = Math.floor((elapsedMilliseconds % 60000) / 1000);
        return `${padZero(minutes)}:${padZero(seconds)}`;
    };

    const padZero = (num: number): string => {
        return num < 10 ? `0${num}` : num.toString();
    };

    const handleRecordingComplete = () => {
        try {
            if (mediaRecorder && mediaRecorder.state === 'recording') {
                mediaRecorder.onstop = () => handleRecorderStop(true);
                mediaRecorder.stop();
            }
        } catch (error) {
            console.error('Error converting to MP3:', error);
        }
    };

    const sendAudioFile = async (file: File) => {
        setIsLoader(true);
        const formData = new FormData();
        formData.append('image', file);
        formData.append('receiverId', receiverId);
        const { res, err } = await httpRequest<{ message: string; document: IChatItem[] }>({
            method: "post",
            path: `/chat/send/file`,
            params: formData,
            header: { 'Content-Type': 'multipart/form-data' }
        });
        if (res) {
            for (let i = 0; i < res.document.length; i++) {
                dispatch(sendNewMessage({ data: res.document[i], receiverId }));
            }
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    const resetRecordingState = () => {
        setIsStart(false);
        setStartTime(null);
        setElapsedTime('00:00');
        setChunks([]);
        if (mediaRecorder) {
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }
        setMediaRecorder(null);
    };

    const handleRecordingCancel = async () => {
        try {
            if (mediaRecorder && mediaRecorder.state === 'recording') {
                mediaRecorder.onstop = () => handleRecorderStop(false);
                mediaRecorder.stop();
            }
        } catch (error) {
            console.error('Error cancelling recording:', error);
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
        if (isStart && startTime) {
            timer = setInterval(() => {
                const elapsed = calculateElapsedTime(startTime);
                setElapsedTime(elapsed);
            }, 1000);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [isStart, startTime]);

    // Declare handleRecorderStop at a higher scope
    const handleRecorderStop = async (shouldSend: boolean) => {
        if (shouldSend) {
            const mimeType = MediaRecorder.isTypeSupported('audio/webm') ? 'audio/webm' : 'audio/wav';
            const audioBlob = new Blob(chunks, { type: mimeType });
            const fileExtension = mimeType === 'audio/webm' ? 'webm' : 'wav';
            const audioFile = new File([audioBlob], `record.${fileExtension}`, { type: mimeType });
            await sendAudioFile(audioFile);
        } else {
            console.log('Recording canceled; API call prevented.');
        }
        resetRecordingState();
    };

    const handleCheckMicrophoneAccess = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setIsStart(true);
            const newStartTime = new Date();
            setStartTime(newStartTime);
            const chunksArray: Blob[] = [];
            const mimeType = MediaRecorder.isTypeSupported('audio/webm') ? 'audio/webm' : 'audio/wav';
            const recorder = new MediaRecorder(stream, { mimeType, audioBitsPerSecond: 192000 });
            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunksArray.push(event.data);
                }
            };

            recorder.onstop = () => handleRecorderStop(true); // Default to sending
            setChunks(chunksArray);
            setMediaRecorder(recorder);
            recorder.start();
        } catch (error) {
            console.log('Mic access denied:', error);
            alert('Microphone access is not supported in this browser or has been denied.');
        }
    };

    const handleRecordingStart = async () => {
        await handleCheckMicrophoneAccess();
    };

    return (
        <div className='relative'>
            <label htmlFor="microphone" onClick={handleRecordingStart} className='flex justify-center items-center cursor-pointer'>
                <FontAwesomeIcon className='font-size-26px text-[#707070]' icon={faMicrophone} />
            </label>
            {isStart ? (
                <div>
                    <div className="audio-recorder recording" data-testid="audio_recorder">
                        <div className='flex items-center gap-2'>
                            <FontAwesomeIcon className='Regular' icon={faMicrophone} />
                            <span className="audio-recorder-timer font-Poppins-Regular font-size-16px" data-testid="ar_timer">
                                {elapsedTime}
                            </span>
                            <span className="audio-recorder-status flex items-center font-Poppins-Regular font-size-16px">
                                <span className={`audio-recorder-status-dot  ${!isBlinking ? 'blinking-dot-recording' : ''}`} />
                                Recording
                            </span>
                        </div>
                        <div className='flex items-center gap-3'>
                            <FontAwesomeIcon
                                spin={isLoader}
                                icon={isLoader ? faSpinner : faPaperPlane}
                                className="audio-recorder-options font-size-16px"
                                title="Send Recording"
                                onClick={handleRecordingComplete}
                            />
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="audio-recorder-options font-size-16px"
                                title="Cancel Recording"
                                onClick={handleRecordingCancel}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Audiocustom;