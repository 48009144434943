/**
 * Services Component
 * 
 * Description: Displays a list of services provided by a doctor. If `isMyProfile` is true, an edit icon 
 * is shown to allow modifications to the services list.
 * 
 * Props:
 * - isMyProfile (boolean): If true, displays an edit icon.
 * - servicesDetails ({ _id: string; name: string }[]): List of services to display.
 * 
 * File Name: Services.tsx
 * Date: 02-09-2024
 */

import React from 'react';
import { Link } from 'react-router-dom';
import edit from "../../../assets/images/awesome-edit.svg";

const Services: React.FC<{ isMyProfile: boolean; servicesDetails: { _id: string; name: string }[] }> = ({ isMyProfile = false, servicesDetails = [] }) => {
    return (
        <div className="parent-box parent-box-margin-issue relative">
            <h2 className='font-size-20px font-Poppins-SemiBold theme-color-green mb-1'>Services</h2>
            <div className="services mt-6 flex items-center gap-4 flex-wrap">
                {servicesDetails?.map(item => (
                    <div key={item._id} className='py-2 px-6 rounded-md border border-solid border-[#707070] bg-[#F3F3F3]'>
                        <p className="font-Poppins-Regular font-size-18px">{item.name}</p>
                    </div>
                ))}
            </div>
            {isMyProfile
                &&
                <Link to="/my-services" className="edit-pro-icon absolute top-2 right-4">
                    <img src={edit} alt="edit-icon" />
                </Link>
            }
        </div>
    );
};

export default Services;