/**
 * SimpleLineGraph Component
 * 
 * Description:
 * This component renders a simple line graph with background bands that visually represent reference and optimal ranges for a given metric. 
 * It displays a dynamic marker whose position is determined by the provided result value. The graph adjusts for various categories like 5-bar, 
 * 4-bar, 3-bar, and 2-bar configurations, handling both ascending and descending ranges with customizable colors and thresholds.
 * 
 * The background bands and marker colors are dynamically generated based on the category provided through props, ensuring appropriate 
 * visual distinctions between thresholds such as optimal, reference, and outlier values.
 * 
 * Key Features:
 * - Dynamic band colors and marker positioning based on the selected category.
 * - Handles a variety of configurations for different bar types (e.g., DND, PN).
 * - Responsive and scalable within the provided SVG container.
 * 
 * File Name: SimpleLineGraph.tsx
 * Date: 19-08-2024
 */

import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { barColors, fiveBarMarker, fourBarMarkerAsc, fourBarMarkerDesc, threeBarMarkerAsc, threeBarMarkerCentralized, threeBarMarkerDesc, twoBarDNDAsc, twoBarDNDDesc, twoBarMarkerAsc, twoBarMarkerDesc, twoBarPNAsc, twoBarPNDesc } from 'src/lib/constans';

// Define interfaces for the component props
interface Band {
    x1: number;
    x2: number;
    color: string;
}

interface SimpleLineGraphProps {
    result: number; // Position of the marker, either as a percentage (0-100) or an absolute value depending on the category.
    refMin: number; // Reference minimum value
    refMax: number; // Reference maximum value
    optMin: number; // Optimal minimum value
    optMax: number; // Optimal maximum value
    category: string; // The category type that defines the graph configuration (e.g., 2-bar, 3-bar, 5-bar)
    size: "md" | "lg" | "sm";
}

const SimpleLineGraph: React.FC<SimpleLineGraphProps> = ({ result, refMin, refMax, optMin, optMax, category, size = "md" }) => {
    const svgRef = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const margin = { top: 20, right: 0, bottom: 20, left: 0 };
        const width = size === "lg" ? 600 : size === "md" ? 350 : 300;
        const height = size === "lg" ? 10 : 6;

        let minValue;
        let maxValue;

        // Determine the value range based on category
        if (category === twoBarDNDAsc || category === twoBarDNDDesc || category === twoBarPNAsc || category === twoBarPNDesc) {
            minValue = 0;
            maxValue = 100;
        } else {
            minValue = result < refMin ? 0 : refMin - 10;
            maxValue = result > refMax ? result : refMax + 10;
        };

        // Define band configurations for different categories

        const fiveBarBands = [
            { x1: minValue, x2: refMin - 1e-10, color: barColors.red },
            { x1: refMin, x2: optMin - 1e-10, color: barColors.orange },
            { x1: optMin, x2: optMax, color: barColors.green },
            { x1: optMax + 1e-10, x2: refMax, color: barColors.orange },
            { x1: refMax + 1e-10, x2: maxValue, color: barColors.red },
        ];

        const fourBarAscBands = [
            { x1: minValue, x2: refMin - 1e-10, color: barColors.red },
            { x1: refMin, x2: optMin - 1e-10, color: barColors.orange },
            { x1: optMin, x2: optMax, color: barColors.yellow },
            { x1: optMax + 1e-10, x2: maxValue, color: barColors.green },
        ];

        const fourBarDescBands = [
            { x1: minValue, x2: optMin - 1e-10, color: barColors.green },
            { x1: optMin, x2: optMax, color: barColors.yellow },
            { x1: optMax + 1e-10, x2: refMax, color: barColors.orange },
            { x1: refMax + 1e-10, x2: maxValue, color: barColors.red },
        ];

        const threeBarCenter = [
            { x1: minValue, x2: optMin - 1e-10, color: barColors.red },
            { x1: optMin, x2: optMax, color: barColors.green },
            { x1: optMax + 1e-10, x2: maxValue, color: barColors.red },
        ];

        const threeBarAsc = [
            { x1: minValue, x2: refMin, color: barColors.red },
            { x1: refMin, x2: refMax, color: barColors.orange },
            { x1: refMax, x2: maxValue, color: barColors.green },
        ];

        const threeBarDesc = [
            { x1: minValue, x2: optMax, color: barColors.green },
            { x1: optMax, x2: refMax, color: barColors.orange },
            { x1: refMax, x2: maxValue, color: barColors.red },
        ];

        const twoBarAsc = [
            { x1: minValue, x2: optMin - 1e-10, color: barColors.red },
            { x1: optMin, x2: maxValue, color: barColors.green },
        ];

        const twoBarDesc = [
            { x1: minValue, x2: optMax, color: barColors.green },
            { x1: optMax, x2: maxValue, color: barColors.red },
        ];

        const twoBarRedGreen = [
            { x1: 0, x2: 50, color: barColors.red },
            { x1: 50, x2: 100, color: barColors.green },
        ];

        const twoBarGreenRed = [
            { x1: 0, x2: 50, color: barColors.green },
            { x1: 50, x2: 100, color: barColors.red },
        ];


        svg.selectAll('*').remove(); // Clear previous SVG content

        const chart = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

        // Create an x-scale based on the value range
        const xScale = d3.scaleLinear()
            .domain([minValue, maxValue]) // Domain based on minValue and maxValue
            .range([0, width]); // Full width for scaling

        // Choose the correct bands configuration based on the category
        const bands =
            category === fiveBarMarker ? fiveBarBands
                : category === fourBarMarkerAsc ? fourBarAscBands
                    : category === fourBarMarkerDesc ? fourBarDescBands
                        : category === threeBarMarkerCentralized ? threeBarCenter
                            : category === threeBarMarkerAsc ? threeBarAsc
                                : category === threeBarMarkerDesc ? threeBarDesc
                                    : category === twoBarMarkerAsc ? twoBarAsc
                                        : category === twoBarMarkerDesc ? twoBarDesc
                                            : category === twoBarDNDAsc ? twoBarRedGreen
                                                : category === twoBarDNDDesc ? twoBarGreenRed
                                                    : category === twoBarPNAsc ? twoBarRedGreen
                                                        : category === twoBarPNDesc ? twoBarGreenRed
                                                            : [];

        // Render the bands dynamically based on the provided configuration
        bands.forEach((band) => {
            chart.append('rect')
                .attr('x', xScale(band.x1)) // Set the x position
                .attr('y', 0)
                .attr('width', xScale(band.x2) - xScale(band.x1)) // Calculate width based on x1 and x2
                .attr('height', height)
                .attr('fill', band.color);
        });

        // Determine the circle color based on the marker's position within the bands
        let circleColor = '#000'; // Default marker color
        for (const band of bands) {
            if ((category === twoBarDNDAsc || category === twoBarPNAsc)) {
                circleColor = result === 1 ? barColors.green : barColors.red;
                break;
            }
            if ((category === twoBarDNDDesc || category === twoBarPNDesc)) {
                circleColor = result === 1 ? barColors.red : barColors.green;
                break;
            }
            if (result >= band.x1 && result <= band.x2) {
                circleColor = band.color;
                break;
            }
        }

        let _result;
        if ((category === twoBarDNDAsc || category === twoBarPNAsc || category === twoBarDNDDesc || category === twoBarPNDesc)) {
            _result = result === 1 ? 75 : 25;
        } else {
            _result = result;
        }

        // Render the marker (circle) based on the computed result and color
        chart.append('circle')
            .attr('cx', `${xScale(_result)}px`)
            .attr('cy', height / 2)
            .attr('r', size === "lg" ? 10 : 8)
            .attr('fill', circleColor) // Dynamic marker color based on range
            .attr('stroke', 'white')
            .attr('stroke-width', 2);

    }, [result, refMin, refMax, optMin, optMax]);

    return (
        <div className="flex justify-center">
            <svg ref={svgRef} width={size === "lg" ? 600 : size === "md" ? 350 : 300} height={size === "lg" ? 80 : 50}></svg>
        </div>
    );
};

export default SimpleLineGraph;