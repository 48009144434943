/**
 * MyCustomerHealthProfile
 * 
 * Description: Displays the health profile of a customer including personal details and biomarkers. 
 *              Users can switch between viewing personal details and biomarker categories.
 * 
 * File Name: MyCustomerHealthProfile.tsx
 * Date: 12-09-2024
 */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeader from 'src/Components/Customer/PageHeader';
import SplashScreen from 'src/Components/SplashScreen';
import BackIcon from "src/assets/images/back-icon.png";
import { returnColor } from 'src/helpers/helperFunctions';
import httpRequest from 'src/helpers/httpRequest';

type tabType = "personal_details" | "biomarkers"; // Define tab types for toggling between tabs

export interface ISingleBios {
    date: string;
    measurement: boolean;
    name: string;
    value: string;
    _id: string;
    isShown: boolean;
    isFavourite: boolean;
    biomarkerColour: string;
    [key: string]: any;
}; // Interface for a single biomarker entry

interface ICategory {
    _id: string,
    biomarkerCategoryIds: string,
    date: string,
    createdAt: string,
    biomarkerName: string,
    status: boolean
}; // Interface for biomarker category details

interface ICustomer {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    gender: string;
    date: string;
}; // Interface for customer details

const MyCustomerHealthProfile: React.FC = () => {
    const navigate = useNavigate(); // Hook to navigate between routes
    const { id = "" } = useParams(); // Get customer ID from route params

    // Define tabs to toggle between personal details and biomarkers
    const tabList: { title: string; key: tabType }[] = [
        { title: "Personal details", key: "personal_details" },
        { title: "Biomarkers", key: "biomarkers" },
    ];

    const [activeTab, setActiveTab] = useState<tabType>("personal_details");
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [biomarkerLoader, setBiomarkerLoader] = useState<boolean>(false);
    const [biomarkerList, setBiomarkerList] = useState<ISingleBios[]>([]);
    const [customer, setCustomer] = useState<ICustomer | null>(null);
    const [category, setCategory] = useState<ICategory[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);

    // Function to change the selected biomarker category
    const changeCategories = (_id: string) => {
        setSelectedCategoryId(_id);
    };

    // API_CALL: Fetch customer details and categories
    const fetch = async () => {
        setIsLoader(true); // Show loader
        const { res, err } = await httpRequest<{ biomarkerCategory: ICategory[]; userDetails: ICustomer[] }>({
            path: `/accessdoctorside/for-biocategorys/${id}`
        });
        if (res) {
            setCustomer(res.userDetails[0]); // Set customer data
            setCategory(res.biomarkerCategory); // Set biomarker categories
            setSelectedCategoryId(res.biomarkerCategory[0]?._id || null); // Set first category as default
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setIsLoader(false); // Hide loader
    };

    // API_CALL: Fetch biomarkers based on selected category
    const fetchBioMarkers = async (categId: string) => {
        setBiomarkerLoader(true);
        const { res, err } = await httpRequest<ISingleBios[]>({
            path: `/healthprofile/biomarker/details/${id}/completed?categoryId=${categId}`
        });
        if (res) {
            setBiomarkerList(res || []);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setBiomarkerLoader(false);
    };

    // Fetch biomarkers when category changes
    useEffect(() => {
        if (selectedCategoryId) {
            fetchBioMarkers(selectedCategoryId);
        }
    }, [selectedCategoryId]);

    // Fetch customer and category data on component mount and when the customer ID changes
    useEffect(() => {
        fetch();
    }, [id]); // Fetch appointments when queryParams & customer id change & components mount

    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate("/my-customers")}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    return (
        <>
            {isLoader && <SplashScreen />}
            <PageHeader
                title="Health Profile"
                isButton={true}
                ButtonComponent={BackButton}
            />
            <div className="parent-box parent-box-height">
                <div className="flex gap-4 items-center">
                    {
                        tabList.map((item, index: number) => (
                            <button
                                onClick={() => setActiveTab(item.key)}
                                key={index}
                                className={`border font-size-16px font-Poppins-SemiBold health-tabs-main-btn rounded-full ${activeTab === item.key ? "text-[#fff] theme-bg-green" : "theme-color-green border-[#004540]"}`}>
                                {item.title}
                            </button>
                        ))
                    }
                </div>
                <hr className='my-4' />
                {activeTab === "personal_details"
                    ?
                    <div className='persnal-details-vitals'>
                        <div className='user-persnal-detail'>
                            <div className='flex justify-between'>
                                <span className='font-Poppins-Medium text-[#444444] font-size-18px'>Name:</span>
                                <span className='font-Poppins-Regular text-[#444444] font-size-18px capitalize'>
                                    {`${customer?.firstName} ${customer?.lastName}`}
                                </span>
                            </div>
                            <div className='flex justify-between'>
                                <span className='font-Poppins-Medium text-[#444444] font-size-18px'>Email:</span>
                                <span className='font-Poppins-Regular text-[#444444] font-size-18px'>
                                    {customer?.email}
                                </span>
                            </div>
                            <div className='flex justify-between'>
                                <span className='font-Poppins-Medium text-[#444444] font-size-18px'>Mobile:</span>
                                <span className='font-Poppins-Regular text-[#444444] font-size-18px'>
                                    {customer?.mobile}
                                </span>
                            </div>
                            <div className='flex justify-between'>
                                <span className='font-Poppins-Medium text-[#444444] font-size-18px'>Date of birth:</span>
                                <span className='font-Poppins-Regular text-[#444444] font-size-18px'>
                                    {moment(customer?.date).format("DD-MM-YYYY")}
                                </span>
                            </div>
                            <div className='flex justify-between'>
                                <span className='font-Poppins-Medium text-[#444444] font-size-18px'>Gender:</span>
                                <span className='font-Poppins-Regular text-[#444444] font-size-18px capitalize'>
                                    {customer?.gender}
                                </span>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className='flex flex-wrap gap-3'>
                            {category.map((v: ICategory) => (
                                <button
                                    key={v._id}
                                    className={`font-size-14px font-Poppins-Medium theme-color-green health-bimarkers-tabs-btn rounded-full ${selectedCategoryId === v._id && "active-tab"}`}
                                    onClick={() => changeCategories(v._id)}
                                >{v.biomarkerName}</button>
                            ))}
                        </div>
                        <hr className='my-4' />
                        <div className='health-biomarkers-tab-1'>
                            <div className='health-vitals-content'>
                                <h2 className='font-Poppins-Regular text-[#313131] font-size-18px'><span className='theme-color-green font-Poppins-SemiBold'>Tested Biomarkers</span></h2>
                                {biomarkerLoader
                                    &&
                                    <div className="text-center mt-3">
                                        <CircularProgress size={24} style={{ color: "#00443f" }} />
                                    </div>
                                }
                                <div className='health-vitals-row-main'>
                                    {biomarkerList.length > 0
                                        ?
                                        biomarkerList.map((v: ISingleBios, i: number) => (
                                            <div key={i} className='health-vitals-row-grid relative'>
                                                <Link to={`/bio-details/${id}/${v._id}/view`}
                                                    style={{ borderLeftColor: returnColor(v?.biomarkerColour) }}
                                                    className='font-size-16px health-vitals-border-left text-white font-Poppins-Regular'
                                                >
                                                    {v?.name}
                                                </Link>
                                                <span className='font-size-16px flex justify-center text-white font-Poppins-Regular'>
                                                    {`${v?.value} ${v?.isShown ? v?.measurement : ""}`}
                                                </span>
                                                <span className='font-size-16px flex justify-center text-white font-Poppins-Regular'>
                                                    {moment(v?.date).format("DD-MM-YYYY")}
                                                </span>
                                                <div style={{ backgroundColor: returnColor(v?.biomarkerColour) }} className='health-vitals-row-circle-dot'></div>
                                            </div>
                                        ))
                                        :
                                        <p className="font-Poppins-Regular font-size-15px text-center">No Items</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default MyCustomerHealthProfile;