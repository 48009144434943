/**
 * NotificationsComponent
 * 
 * Description: This component fetches and displays notifications with infinite scroll functionality.
 * It utilizes the `httpRequest` helper for API calls and manages loading state, pagination, 
 * and notification rendering, including handling default profile images for users.
 * 
 * File Name: NotificationsComponent.tsx
 * Date: 30-09-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { INotifications, IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import moment from 'moment';
import defaultProfile from "src/assets/images/default_profile_image.png";
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { resetCounter } from 'src/Store/reducer';
import { Link } from 'react-router-dom';

interface INotificationResponse {
    documents: INotifications[];
    paginated: IPaginated;
};

const NotificationsComponent: React.FC = () => {
    const dispatch = useDispatch();
    // States for loading status, pagination, and customer data
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [allNotifications, setAllNotifications] = useState<INotifications[]>([]);

    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
    });

    // API_CALL to fetch notifications from the server
    const fetchNotifications = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<INotificationResponse>({ path: `/notification-setting/notification?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setAllNotifications(prev => [...prev, ...documents]);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    const handleNavigate = (item: INotifications): string => {
        const { notificationRoute, routeId } = item;
        switch (notificationRoute) {
            case 'addTest':
                return `/test-result/${routeId}/view`;
            case 'suggestTest':
                return `/product-detail/${routeId}/view`;
            case 'appoinment':
                return `/appointment/${routeId}/view`;
            case 'chat':
                return `/message/${routeId}/view`;
            case 'discussion':
                return `/discussion/${routeId}/detail`;
            case 'testAcess':
                return `/test-result/${routeId}/view`;
            case 'healthProfile':
                return `/customer-healthprofile/${routeId}/view`;
            default:
                return "/notifications";
        };
    };

    useEffect(() => {
        fetchNotifications();
    }, [queryParams]);

    useEffect(() => {
        (async () => {
            const { res, err } = await httpRequest<any>({ path: "/notification-setting/read/notification" });
            if (res) {
                dispatch(resetCounter("unreadNotificationCount"));
            } else {
                console.log("fetchunReadCount", err);
            }
        })();
    }, []);

    return (
        <div className="parent-box parent-box-padding-issue-resolve">
            <InfiniteScroll
                dataLength={allNotifications.length} // This is important field to render the next data
                next={() =>
                    setQueryParams((prev) => ({ ...prev, page: prev.page + 1 }))
                }
                hasMore={queryParams.page < paginated.totalPages}
                loader={isLoader && <div className="text-center">
                    <CircularProgress
                        size={20}
                        style={{ color: "#004540" }}
                    />
                </div>}
            // endMessage={
            //     <p className="font-size-16px font-Poppins-Regular" style={{ textAlign: "center" }}>No more Notification to show</p>
            // }
            >
                {allNotifications.map((item: INotifications, index: number) => (
                    <Link to={handleNavigate(item)} key={index} className="parent-box parent-box-shadow-issue p-3 mb-3 mt-3 m-4 block">
                        <div className="flex justify-between gap-3">
                            <div className="flex items-center gap-3">
                                <div className="profile-img-container">
                                    <img
                                        alt="User"
                                        className="rounded-full"
                                        src={`${process.env.REACT_APP_API_BASEURL}${item?.imageUrl}`}
                                        onError={(e) => {
                                            const target = e.target as HTMLImageElement; // Assert the type
                                            if (target) {
                                                target.src = defaultProfile;
                                            }
                                        }}
                                    />
                                </div>
                                <div>
                                    <p className="font-size-20px font-Poppins-SemiBold">{item?.title || ""}</p>
                                    <p className="font-size-16px font-Poppins-Regular">{item?.message || ""}</p>
                                </div>
                            </div>
                            <div>
                                <p className="font-size-14px font-Poppins-Regular">{moment(item.createdAt).format("hh:mm A")}</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default NotificationsComponent;