/**
 * UpsertSpecialization Component
 * 
 * Description: This component allows users to add a new specialization or edit an existing one. It includes 
 * an image upload feature for the specialization icon, a text input for the specialization name, and a save button. 
 * Upon submission, it sends a request to the server to either create a new specialization or update an existing one, 
 * depending on whether the component is in add or edit mode. It handles form validation for both the name and image fields, 
 * and displays appropriate success or error messages based on the server response.
 * 
 * Props:
 * - isEdit (ISpecializationItem | boolean): Optional. If provided as an object, it represents the specialization 
 *   to be edited. If `false`, the component operates in add mode.
 * - onSuccess (function): Callback function invoked upon successful form submission.
 * 
 * File Name: UpsertSpecialization.tsx
 * Date: 05-09-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import ImageUpload from '../GlobalComponents/ImageUplaod'; // Corrected typo
import SimpleInput from '../SimpleInput';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { ISpecializationItem } from 'src/types/GlobalInterfaces';

interface IUpsertSpecializationProps {
    isEdit?: ISpecializationItem | boolean;
    onSuccess: () => void;
};

const UpsertSpecialization: React.FC<IUpsertSpecializationProps> = ({ isEdit = false, onSuccess = () => { } }) => {
    // img upload
    const [name, setName] = useState<string>("");
    const [SpecailImage, setSpecailImage] = useState<File | null | string>(null);

    const [isLoader, setIsLoader] = useState<boolean>(false);

    const handleSubmit = async () => {
        if (!name) {
            toast("Specialization name is mandatory!", { type: 'error' });
        } else if (!SpecailImage) {
            toast("Specialization image is mandatory!", { type: 'error' });
        } else {
            setIsLoader(true);
            const formData = new FormData();
            formData.append("name", name);
            formData.append("SpecailImage", SpecailImage);
            const path = isEdit && typeof isEdit !== 'boolean' ? `/provider/special/${isEdit._id}` : "/provider/special";

            const { res, err } = await httpRequest<any>({ method: isEdit ? "put" : "post", path, params: formData, header: { "Content-Type": "multipart/form-data" } });
            if (res) {
                toast(`Specialization has been ${isEdit ? "updated" : "added"} successfully.`, { type: 'success' });
                onSuccess();
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        }
    };

    useEffect(() => {
        if (isEdit && typeof isEdit !== 'boolean') {
            let { name = "", SpecailImage = "" } = isEdit;
            setName(name);
            setSpecailImage(SpecailImage);
        }
    }, [isEdit]); // Execute this useEffect hook if in edit mode to initialize form with existing role data
    return (
        <div className="table-container">
            <div className='admin-create-role-main'>
                <div className='admin-create-role'>
                    <div className='create-operators-res '>
                        <h3 className='font-size-18px text-[#2F2F31] mb-1 font-Poppins-Regular'>Specialization Icon</h3>
                        <ImageUpload
                            image={SpecailImage}
                            onImageChange={setSpecailImage}
                        />
                        <SimpleInput
                            value={name}
                            onChange={setName}
                            isLabel={true}
                            labelText="Specialization Name"
                            isRequired={true}
                        />
                    </div>
                </div>
                <div className='flex justify-end my-6'>
                    <PrimaryBtn
                        loader={isLoader}
                        ButtonText="Save"
                        btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        onPress={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

export default UpsertSpecialization;