/**
 * Scheduler
 * 
 * Description: This component dynamically switches between the doctor and customer 
 * schedulers based on the user's role and the mode stored in cookies. It checks for 
 * cookie changes and updates the mode accordingly.
 * 
 * File Name: Scheduler.tsx
 * Date: 04-10-2024
 */

import React, { useEffect, useState } from 'react';
import "../../assets/css/Scheduler.css";
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { customer, doctor, doctorMode } from 'src/lib/constans';
import Cookies from 'js-cookie';
import DoctorSchedular from 'src/Components/Customer/DoctorSchedular';
import CustomerScheduler from 'src/Components/Customer/CustomerScheduler';

const Scheduler: React.FC = () => {
  const { userDetails } = useSelector((store: RootState) => store.appReducer);
  const [mode, setMode] = useState(Cookies.get(doctorMode) || customer);

  useEffect(() => {
    // Function to check for cookie changes
    const checkCookieChange = () => {
      const currentMode = Cookies.get(doctorMode) || customer;
      if (currentMode !== mode) {
        setMode(currentMode);
      }
    };

    // Set an interval to check for changes every second (1000ms)
    const intervalId = setInterval(checkCookieChange, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [mode]);

  return (
    <div className='container-1480'>
      <div className='schedular-parent-top-padding'>
        <div className='calendar-container'>
          {(mode === doctor && userDetails?.userRole === doctor)
            ?
            <DoctorSchedular />
            :
            <CustomerScheduler />
          }
        </div>
      </div>
    </div>
  );
};

export default Scheduler;