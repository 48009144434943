/**
 * HealthBiomarkers Component
 * 
 * Description: This component serves as the main view for displaying the user's health biomarkers within their health profile.
 * It fetches the user ID from the Redux store and passes it to the UserBiomarkers component.
 * 
 * File Name: HealthBiomarkers.js
 * Date: 2024-08-23
 */

import React from 'react';
import HealthProfileTabs from 'src/Components/Customer/HealthProfileTabs';
import PageHeader from 'src/Components/Customer/PageHeader';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import UserBiomarkers from 'src/Components/GlobalComponents/HealthProfiles/UserBiomarkers';

const HealthBiomarkers: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer); // Extract user details from the Redux store
    const uid = userDetails?._id || ""; // Extract user ID or set to an empty string if not available
    return (
        <>
            {/* Render the page header with the title "Health Profile" */}
            <PageHeader title="Health Profile" />
            <div className="parent-box parent-box-height">
                {/* Render tabs for navigating the health profile sections */}
                <HealthProfileTabs />
                {/* Render the user's Biomarkers information component and pass the user ID */}
                <UserBiomarkers
                    id={uid}
                    isMyProfile={true}
                    role={"customer"}
                />
            </div>
        </>
    );
};

export default HealthBiomarkers;