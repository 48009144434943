import React, { useEffect, useState } from 'react';
import { useNavigate, useParams,Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddressSocialDetail from 'src/Components/AddressSocialDetail';
import HealthSystem from 'src/Components/Customer/HealthSystem';
import ProfileUserDetailDashboard from 'src/Components/Customer/ProfileUserDetailDashboard';
import RecentTest from 'src/Components/Customer/RecentTest';
import SplashScreen from 'src/Components/SplashScreen';
import httpRequest from 'src/helpers/httpRequest';
import { IProfileData } from 'src/types/GlobalInterfaces';
import BackIcon from "../../assets/images/back-icon.png";

const PatientPublicProfile: React.FC = () => {
    const navigate = useNavigate();
    const { id = "" } = useParams();

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [profileData, setProfileData] = useState<IProfileData>({});
    const fetchPatientProfile = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IProfileData>({ path: `/provider/get/patient-profile/${id}` });
        if (res) {
            setProfileData(res);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (id) {
            fetchPatientProfile();
        } else {
            navigate(-1);
        }
    }, [id]);
    return (
        <div className="px-5" style={{ minHeight: "800px" }}>
            {isLoader && <SplashScreen />}
            <Link to={'/my-customers'}>
                <img style={{ position: "absolute" }} src={BackIcon} className="back-icon-docter-pro" />
            </Link>
            <div className="container-1480 block justify-between h-3vw md:flex lg:flex">
                <div className="w-full md:w-3/4 lg:w-3/4">
                    <ProfileUserDetailDashboard
                        data={profileData}
                    />
                    <HealthSystem
                        id={id}
                    />
                    <RecentTest
                        id={id}
                    />
                </div>
                <div className="w-1/4 pl-2 show-location-detail-mob">
                    <AddressSocialDetail
                        data={profileData}
                    />
                </div>
            </div>
        </div>
    );
};

export default PatientPublicProfile;