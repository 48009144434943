/**
 * CustomerScheduler Component
 * 
 * Description: This component handles the scheduling UI for customers. It displays a weekly calendar view
 * with available appointment slots and booked appointments for each day of the selected week.
 * 
 * The user can navigate between weeks, and the component fetches appointment data based on the selected week.
 * It also handles the display of individual appointments with patient details, status, and timing.
 * 
 * Props: None
 * 
 * File Name: CustomerScheduler.tsx
 * Date: 04-10-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import qs from "qs";
import SplashScreen from '../SplashScreen';
import moment, { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IAppointmentItem {
    _id: string;
    doctorId: string;
    userId: string;
    hospitalId: string;
    patientName: string;
    phone: string;
    appoinmentDate: string; // Use Date if you want to store it as a Date object
    slotId: string;
    isOnlineAppointment: boolean;
    isBooked: boolean;
    isReschedule: boolean;
    totalAmount: number;
    startTime: string;
    endTime: string;
    status: "pending" | "completed" | "cancelled";
    createdAt: string; // Use Date if you want to store it as a Date object
    updatedAt: string; // Use Date if you want to store it as a Date object
    __v: number;
};

const CustomerScheduler: React.FC = () => {
    const [weekDays, setWeekDays] = useState<moment.Moment[]>([]);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [allAppoiment, setAllAppoiment] = useState<IAppointmentItem[]>([]);
    const [totalSlots, setTotalSlots] = useState<{ startTime: string; endTime: string }[]>([]);

    const [queryParams, setQueryParams] = useState<{ startDate: string; endDate: string; }>({
        startDate: "",
        endDate: "",
    });

    // Navigate to the next week
    const nextWeek = () => {
        const nextStartDate = moment(queryParams.startDate).add(1, 'week');
        setWeek(nextStartDate);
    };

    // Navigate to the previous week
    const prevWeek = () => {
        const prevStartDate = moment(queryParams.startDate).subtract(1, 'week');
        setWeek(prevStartDate);
    };

    // Set the week and generate dates for the week
    const setWeek = (date: moment.Moment) => {
        const start = moment(date).utc().startOf('day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        const end = moment(start).add(6, 'days').utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // End date at midnight UTC after 6 days

        setQueryParams(prev => ({
            ...prev,
            startDate: start,
            endDate: end,
        }));

        // Create a moment object from the start date
        const START = moment(start);

        // Generate an array of dates for the entire week
        const arr = Array.from({ length: 7 }, (_, index) => {
            return START.clone().add(index, 'days'); // Add days to the start date
        });

        setWeekDays(arr);
    };

    const isDataExist = (day: Moment, time: string): string => {
        const _find = allAppoiment.find(v => moment(v.appoinmentDate).format("dd") === moment(day).format("dd") && v.startTime === time);

        if (_find) {
            return "z-[2] hovered-cell"
        }
        return "";
    };

    // Generate an array of dates between startDate and endDate
    const generateHighlightedDates = (startDate: string, endDate: string): Date[] => {
        const start = moment(startDate);
        const end = moment(endDate);
        const dates: Date[] = [];

        for (let date = start; date.isBefore(end) || date.isSame(end, 'day'); date.add(1, 'day')) {
            dates.push(date.toDate());
        }

        return dates;
    };

    //API_CALL: Fetch appointments
    const fetchAppointments = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IAppointmentItem[]>({
            path: `/appoiment/customer/slots/schedular?${qs.stringify(queryParams)}`
        });
        if (res) {
            setAllAppoiment(res || []);
            const newSlots = res.reduce((acc: { startTime: string; endTime: string }[], item: IAppointmentItem) => {
                // Check if the startTime is already in the accumulator
                if (!acc.some(v => v.startTime === item.startTime)) {
                    acc.push({ startTime: item.startTime, endTime: item.endTime });
                }
                return acc;
            }, []);
            setTotalSlots(newSlots);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Initialize week and fetch data when component mounts
    useEffect(() => {
        setWeek(moment());
    }, []);

    useEffect(() => {
        if (queryParams.startDate && queryParams.endDate) {
            fetchAppointments();
        }
    }, [queryParams]);

    // Render appointment details
    const printAppointment = (day: moment.Moment, startTime: string) => {
        const item = allAppoiment.find(v => (
            v.appoinmentDate ?
                moment(v.appoinmentDate).format("DD") === moment(day).format("DD") && v.startTime === startTime
                :
                null
        ));
        if (item) {
            return (
                <div className={`event hovered-class ${item?.status === "completed" ? "Complete" : item?.status === "pending" ? "Pending" : "cancelled"}-Box min-w-[132px]`}>
                    <div className="Shedular-main">
                        <div className="UserName-calen">
                            <h6 className="capitalize font-Poppins-SemiBold font-size-10px">{item?.patientName || ""}</h6><span
                                className="job__status capitalize font-Poppins-Medium">{item?.status || ""}</span>
                        </div>
                        <p className="job__time__start__end font-Poppins-Regular">{`${moment(item.startTime, "HH:mm").format("hh:mm A")} - ${moment(item.endTime, "HH:mm").format("hh:mm A")}`}</p>
                        <div className="UserName-calen2">
                            <p className="font-Poppins-Regular">{item?.phone || ""}</p>
                            <p className="font-Poppins-Medium">AED {item?.totalAmount || ""}</p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <>-</>
        }
    };
    return (
        <>
            {isLoader && <SplashScreen />}
            <div className='calendar-header flex flex-wrap items-center justify-between'>
                <div className="">
                    <div className='calender-header-weeks'>
                        <button onClick={prevWeek} className='calender-header-week-arrow'>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <span className='text-[#FFFFFF] font-Poppins-Medium font-size-16px'>Week</span>
                        <button onClick={nextWeek} className='calender-header-week-arrow'>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                </div>
                <div className='clander-handler-head clander-handler-head-docter'>
                    <span className='font-Poppins-SemiBold font-size-20px theme-color-green'>
                        <span className="flex gap-2 items-center justify-center">
                            <span>
                                {moment(queryParams.startDate).format("DD-MM-YYYY")}
                            </span>
                            <span>
                                -
                            </span>
                            <span>
                                {moment(queryParams.endDate).format("DD-MM-YYYY")}
                            </span>
                        </span>
                    </span>
                </div>
                <div>
                    <DatePicker
                        className="cursor-pointer"
                        keepInput={false}  // Keep input field but don't allow typing
                        placeholderText="Start Date"
                        dateFormat="dd-MM-YYYY"
                        closeOnScroll={true}
                        startOpen={false}
                        onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                        selected={queryParams.startDate ? moment(queryParams.startDate).toDate() : null}
                        onChange={(date: Date | null) => date && setWeek(moment(date))}
                        highlightDates={generateHighlightedDates(queryParams.startDate, queryParams.endDate)} // Highlight dates between startDate and endDate
                    />
                </div>
            </div>
            <div className='calendar'>
                <table className='weekly-calender'>
                    <thead>
                        <tr>
                            <th className="px-2">
                                <span className='font-Poppins-SemiBold font-size-15px theme-color-green'>Timings</span>
                            </th>
                            {weekDays.map((day, index) => (
                                <th key={index} className={`px-2 font-Poppins-SemiBold font-size-15px text-[#4B4B4B]`}>
                                    <span className="flex flex-col">
                                        <span>{day.format('ddd')}</span>
                                        <span>{day.format('DD')}</span>
                                    </span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {totalSlots.map((v, rowId: number) => (
                            <tr key={v.startTime}>
                                <td className="px-2">
                                    <span className="font-size-15px font-Poppins-SemiBold text-[#464646] capitalize text-left whitespace-nowrap">
                                        {`${moment(v.startTime, "HH:mm").format("hh:mm A")} to ${moment(v.endTime, "HH:mm").format("hh:mm A")}`}
                                    </span>
                                </td>
                                {weekDays.map((day, columnId) => (
                                    <td
                                        key={columnId}
                                        className={`${isDataExist(day, v.startTime)}`}
                                    >
                                        {printAppointment(day, v.startTime)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {Array.from({ length: Math.max(0, 10 - totalSlots.length) }).map(
                            (_, index) => (
                                <tr key={totalSlots.length + index}>
                                    <td></td>
                                    {weekDays.map((day, i) => (
                                        <td key={i}></td>
                                    ))}
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default CustomerScheduler;