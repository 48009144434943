import React, { useState } from "react";

function FullScreenLoader({ show = false }) {
    const [loader, setLoader] = useState(true);
    const [zIndex, setZindex] = useState(false);
    const loaderStyles = {
        opacity: loader ? 1 : 0,
        transition: "opacity 0.8s ease-in-out",
        zIndex: 9999,
    };


    if (!show) {
        return <></>
    }
    return (
        <div
            className={`loader vh-100 w-100 d-flex align-items-center justify-content-center position-fixed top-0 z-20 theme-body-bg particles-loader ${zIndex ? "-z-index-1" : ""
                }`}
            style={loaderStyles}
        >

            <img className="spinGolden" src="https://generalwebdevelopment.s3.ap-southeast-2.amazonaws.com/images/bannerhomeleftimg.png" style={{ maxWidth: '7%' }} />
        </div>
    );
}

export default FullScreenLoader;