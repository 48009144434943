import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICustomJwtPayload, setToken } from '../helpers/helperFunctions';

export interface AppState {
    userDetails: null | ICustomJwtPayload;
    isLogged: boolean;
    showLoader: boolean;
    messageUnreadCount: number;
    unreadNotificationCount: number;
};

const initialState: AppState = {
    userDetails: null,
    isLogged: false, // change after api integrate
    showLoader: false,
    messageUnreadCount: 0,
    unreadNotificationCount: 0,
};

export const reducer = createSlice({
    name: 'APP_REDUCER',
    initialState,
    reducers: {
        setCounter: (
            state,
            action: PayloadAction<{ key: "messageUnreadCount" | "unreadNotificationCount"; val: number }>
        ) => {
            state[action.payload.key] = action.payload.val;
        },
        counterIncreament: (state, action: PayloadAction<"messageUnreadCount" | "unreadNotificationCount">) => {
            state[action.payload] = state[action.payload] + 1;
        },
        resetCounter: (state, action: PayloadAction<"messageUnreadCount" | "unreadNotificationCount">) => {
            state[action.payload] = 0;
        },
        setAuth: (state, action: PayloadAction<string>) => {
            setToken(action.payload);
            state.isLogged = true;
        },
        setUserDetails: (state, action: PayloadAction<ICustomJwtPayload>) => {
            state.userDetails = action.payload;
        },
        removeAuth: (state) => {
            state.userDetails = null;
            state.isLogged = false;
        },
        toggleLoader: (state, action: PayloadAction<boolean>) => {
            state.showLoader = action.payload;
        },
        setUniqueData: (state, action: PayloadAction<{ key: string; value: string }>) => {
            const { key, value } = action.payload;
            // Ensure userDetails is not null before attempting to update it
            if (state.userDetails) {
                state.userDetails = {
                    ...state.userDetails,
                    [key]: value,
                };
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCounter, counterIncreament, resetCounter, setAuth, setUserDetails, removeAuth, toggleLoader, setUniqueData } = reducer.actions;

export default reducer.reducer;