/**
 * Discussions Component
 * 
 * Description: This component renders a list of discussions, allowing users to filter, sort, and create new discussions. 
 * It fetches the discussion data from an API, provides pagination, and offers options to filter between public and personal discussions.
 * 
 * File Name: Discussions.tsx
 * Date: 24-09-2024
 */

import React, { useEffect, useRef, useState } from 'react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from 'src/Components/Customer/PageHeader';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import { IqueryParams } from 'src/types/GlobalInterfaces';
import defaultProfile from "src/assets/images/default_profile_image.png";
import PaginationComponet from 'src/Components/GlobalComponents/PaginationComponet';
import CreateDiscussionModal from 'src/Components/GlobalComponents/CreateDiscussionModal';
import httpRequest from 'src/helpers/httpRequest';
import qs from "qs";
import { toast } from 'react-toastify';
import { IPaginated } from 'src/types/paginated';
import { CircularProgress } from '@mui/material';
import { convertToTimeAgo, truncateString } from 'src/helpers/helperFunctions';
import { Link } from 'react-router-dom';

interface IDiscussionItem {
    _id: string;
    title: string;
    firstName: string;
    lastName: string;
    photo: string;
    createdAt: string;
    commentCount: number;
};

interface IDiscussionResponse {
    documents: IDiscussionItem[];
    paginated: IPaginated;
};

const Discussions: React.FC = () => {
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [sortByDropDown, setSortByDropDown] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [allDiscussions, setAllDiscussions] = useState<IDiscussionItem[]>([]);
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        search: "",
        type: "public"
    });

    const typeChange = (new_type: "my" | "public") => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            type: new_type,
            search: "",
        }));
    };

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            search: txt,
            page: 1
        }));
    };

    //API_CALL: Fetch discussions based on current queryParams
    const fetchDiscussions = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IDiscussionResponse>({
            path: `/disscussion/?${qs.stringify(queryParams)}`,
        });
        if (res) {
            let { documents = [], paginated } = res;
            setAllDiscussions(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchDiscussions(); // Fetch discussions whenever queryParams change
    }, [queryParams]);

    // Handle click outside the dropdown to close it
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setSortByDropDown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <CreateDiscussionModal
                isOpen={modal}
                onClose={() => setModal(false)}
            />
            <PageHeader title="Discussions" />
            <div className="parent-box min-h-[75vh]">
                <p className="theme-color-green font-size-15px font-Poppins-Regular mb-3">
                    Ask questions, find support, and connect with the community.
                </p>
                <PrimaryBtn
                    onPress={() => setModal(true)}
                    ButtonText="New Discussion"
                    btnExtraStyle={{
                        backgroundColor: "#004540",
                        borderRadius: "1.5rem",
                        padding: "0.7rem 2.5rem",
                    }}
                />
                <div className="my-3 flex items-center justify-between">
                    <div className='flex gap-4 items-center'>
                        <p className='text-[#707070] font-size-15px font-Poppins-Regular'>Sort by:</p>
                        <div ref={dropdownRef} onClick={() => setSortByDropDown(prev => !prev)} className='flex cursor-pointer gap-4 items-center relative'>
                            <p className='text-[#606060] font-size-18px font-Poppins-Medium'>{queryParams.type === "my" ? "My Discussion" : "Public Discussion"}</p>
                            <div>
                                <FontAwesomeIcon icon={faCaretDown} />
                            </div>
                            {sortByDropDown && (
                                <div className='dropdownBox'>
                                    <p onClick={() => typeChange("public")} className={`text-[#707070] font-size-16px font-Poppins-SemiBold ${queryParams.type === "public" ? "dropdownBox-active" : ""}`}>Public Discussion</p>
                                    <p onClick={() => typeChange("my")} className={`text-[#707070] font-size-16px font-Poppins-SemiBold ${queryParams.type === "my" ? "dropdownBox-active" : ""}`}>My Discussion</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <SearchFilterInput
                        containerExtraStyle={{ minWidth: 250 }}
                        inputExtraStyle={{ width: "100%", backgroundColor: "#E5E5E5", marginBottom: 0 }}
                        onChangeText={handleChangeText}
                    />
                </div>
                <hr style={{ border: '1px solid #D4D4D4', }} />
                {isLoader &&
                    <div className="text-center mt-3">
                        <CircularProgress size={20} style={{ color: "#004540" }} />
                    </div>
                }
                {allDiscussions.map((item: IDiscussionItem) => (
                    <div key={item._id} className="discussion-list-map pb-3 border-b border-[#E1E1E1] flex items-center gap-3 mt-3">
                        <div className="w-16 h-16">
                            <img
                                alt="profile"
                                src={`${process.env.REACT_APP_API_BASEURL}${item?.photo}`}
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement; // Assert the type
                                    if (target) {
                                        target.src = defaultProfile;
                                    }
                                }}
                                className="rounded-full w-16 h-16"
                            />
                        </div>
                        {/* <div className="flex-1 border-b border-[#E1E1E1]"> */}
                        <div className="flex-1">
                            <div className="flex flex-col gap-1">
                                <Link to={`/discussion/${item._id}/detail`} className="theme-color-green font-size-20px font-Poppins-Medium hover:underline">{truncateString(item?.title || "", 70)}</Link>
                                <div className="flex items-center gap-2">
                                    <p className="text-[#707070] font-size-16px font-Poppins-Regular">{item.commentCount || 0} {`Repl${item.commentCount > 1 ? "ies" : "y"}`}</p>
                                    <span className="w-1 h-1 rounded-full bg-[#707070]"></span>
                                    <p className="text-[#707070] font-size-16px font-Poppins-Regular">{convertToTimeAgo(item.createdAt)}</p>
                                </div>
                                <p className="theme-color-green font-size-16px font-Poppins-Medium">Posted by: <span className="capitalize">{`${item?.firstName || ""} ${item?.lastName || ""}`}</span></p>
                            </div>
                        </div>
                    </div>
                ))}
                {paginated?.totalPages > 1 &&
                    <PaginationComponet
                        total={paginated.totalPages}
                        currentPage={paginated.currentPage}
                        setCurrentPage={(_page) => setQueryParams(prev => ({ ...prev, page: _page }))}
                    />
                }
            </div>
        </>
    );
};

export default Discussions;