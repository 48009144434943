// src/components/FullPageLoader.tsx
import React from 'react';
import '../assets/css/loader.css'; // Adjust the path as necessary

const SplashScreen: React.FC = () => {
    return (
        <div className="full_page">
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default SplashScreen;
