import React, { useState, useEffect, FormEvent } from 'react';
import PageHeader from 'src/Components/Customer/PageHeader';
import PreviewProfileButton from 'src/Components/Customer/PreviewProfileButton';
import SimpleInput from 'src/Components/SimpleInput';
import ToggleRadioBtn from 'src/Components/ToggleRadioBtn';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';

import {
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IAvailability } from 'src/types/GlobalInterfaces';
import { weekDays } from 'src/lib/constans';
import { capitalize } from '@mui/material';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { updateDoctorField } from '../../../Store/doctor';
import { setUniqueData } from "src/Store/reducer";

const HospitalAvailabilityEdit: React.FC = () => {
    const { profile } = useSelector((store: RootState) => store.doctor);
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [timings, setTimings] = useState<IAvailability[]>([{
        hospitalName: "",
        fees: "",
    }]);

    const addHospitals = () => {
        setTimings(prev => [...prev, {
            hospitalName: "",
            fees: "",
        }])
    };

    const handleChangeItem = (key: keyof IAvailability, val: string, index: number) => {
        setTimings(prev => prev.map((v, i) => i === index ? { ...v, [key]: val } : v));
    };

    const handleToggle = (day: keyof IAvailability, index: number) => {
        setTimings(prev => prev.map((timing, idx) => {
            if (idx === index) {
                const isExist = Object.keys(timing).includes(day);
                if (isExist) {
                    // Remove the day from the timing object
                    const updatedTiming = { ...timing };
                    delete updatedTiming[day];
                    return updatedTiming;
                } else {
                    // Add the day with an empty time slot array
                    return {
                        ...timing,
                        [day]: [["", ""]],
                    };
                }
            }
            return timing;
        }));
    };

    const handleChange = (day: keyof IAvailability, index: number, type: number, value: string, location: number) => {
        setTimings(prev => prev.map((timing, idx) => {
            if (idx === location) {
                // Ensure `timing[day]` is an array of tuples or default to an empty array
                const currentSchedule = Array.isArray(timing[day])
                    ? timing[day] as [string, string][]
                    : [];

                // Clone the current schedule to avoid direct mutation
                const updatedSlots = currentSchedule.map((slot, slotIdx) => slotIdx === index ? [...slot] : slot);

                // Update the slot with the new value
                updatedSlots[index][type] = value;

                // Function to convert time string to number of minutes
                const timeToMinutes = (time: string) => {
                    const [hours, minutes] = time.split(':').map(Number);
                    return hours * 60 + minutes;
                };

                // Check if 'To' time is greater than 'From' time
                const fromTime = updatedSlots[index][0];
                const toTime = updatedSlots[index][1];

                if (fromTime && toTime && timeToMinutes(fromTime) >= timeToMinutes(toTime)) {
                    toast(`${day}: End time must be greater than Start time.`, {
                        type: "error",
                    });

                    // Revert the change if validation fails
                    const prevState = currentSchedule.map((slot, slotIdx) => slotIdx === index ? [...slot] : slot);
                    prevState[index][type] = ""; // Clear the changed value
                    return {
                        ...timing,
                        [day]: prevState,
                    };
                }

                return {
                    ...timing,
                    [day]: updatedSlots,
                };
            }
            return timing;
        }));
    };


    const addRow = (day: keyof IAvailability, index: number) => {
        setTimings(prev => prev.map((timing, idx) => {
            if (idx === index) {
                // Ensure `timing[day]` is an array or default to an empty array
                const currentSchedule = Array.isArray(timing[day])
                    ? timing[day] as [string, string][]
                    : [];
                return {
                    ...timing,
                    [day]: [...currentSchedule, ["", ""]],
                };
            }
            return timing;
        }));
    };

    const deleteRow = (day: keyof IAvailability, i: number, index: number) => {
        setTimings(prev => prev.map((timing, timingIndex) => {
            if (timingIndex === index) {
                // Ensure `timing[day]` is an array or default to an empty array
                const currentSchedule = Array.isArray(timing[day])
                    ? timing[day] as [string, string][]
                    : [];

                // Filter out the item at the specified index
                const updatedSchedule = currentSchedule.filter((_, slotIndex) => slotIndex !== i);

                return {
                    ...timing,
                    [day]: updatedSchedule,
                };
            }
            return timing;
        }));
    };


    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; availability: { timings: IAvailability[] }; percent: number }>({
            method: "post", path: "/provider/update-timing", params: { timings }
        });
        if (res) {
            const { message, availability, percent } = res;
            toast(message, { type: "success" }); // api success message
            dispatch(updateDoctorField({ timings: availability.timings }));
            dispatch(setUniqueData({ key: 'profileCompletion', value: String(percent) }));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (profile) setTimings((profile.timings && profile.timings.length) ? profile.timings : [{ hospitalName: "", fees: "" }]);
    }, [profile]);

    return (
        <>
            <PageHeader
                title="Availability"
                isButton={true}
                ButtonComponent={PreviewProfileButton}
            />

            <div className="parent-box">
                <div className="border-b border-[#707070] pt-2 pb-4">
                    <p className="font-Poppins-SemiBold font-size-20px theme-color-green">Hospital</p>
                    <p className="font-Poppins-Regular font-size-14px theme-color-gren">Keep this up to date so you get enquiries that suit you.</p>
                </div>
                <form onSubmit={handleSubmit} className="py-4">
                    {timings.map((item, index) => (
                        <div className=" mt-4" key={index}>
                            <div>
                                <SimpleInput
                                    type='text'
                                    value={item.hospitalName || ""}
                                    onChange={(val) => handleChangeItem("hospitalName", val, index)}
                                    isLabel={true}
                                    labelText={"Hospital Name"}
                                    isRequired={true}
                                    extraStyle={{ borderColor: "#009d91" }}
                                />
                            </div>
                            <div>
                                <SimpleInput
                                    type='text'
                                    value={item.fees || ""}
                                    onChange={(val) => {
                                        if (/^\d+$/.test(val) || val === "") {
                                            handleChangeItem("fees", val, index)
                                        }
                                    }}
                                    isLabel={true}
                                    isRequired={true}
                                    labelText={"Fee"}
                                    extraStyle={{ borderColor: "#009d91" }}
                                />
                            </div>
                            {weekDays.map(day => (
                                <div key={day} className="availability mt-4">
                                    <div className='avail pb-4'>
                                        <div className='flex'>
                                            <ToggleRadioBtn
                                                toggleText={capitalize(day)}
                                                isToggled={Object.keys(timings[index]).includes(day)}
                                                handleToggle={() => handleToggle(day as keyof IAvailability, index)}
                                            />
                                        </div>
                                        {Object.keys(timings[index]).includes(day) &&
                                            <>
                                                {Array.isArray(timings[index][day as keyof IAvailability])
                                                    ? (timings[index][day as keyof IAvailability] as [string, string][]).map((item, i) => (
                                                        <div className="flex w-full gap-4 mt-4 items-center" key={i}>
                                                            <div className='w-[30%]'>
                                                                <SimpleInput
                                                                    value={item[0]}
                                                                    onChange={(val) =>
                                                                        handleChange(day as keyof IAvailability, i, 0, val, index)
                                                                    }
                                                                    isLabel={true}
                                                                    labelText={"Start Time"}
                                                                    extraStyle={{ borderColor: "#009d91" }}
                                                                    type='time'
                                                                    isRequired={true}
                                                                />
                                                            </div>

                                                            <div className='w-[30%]'>
                                                                <SimpleInput
                                                                    value={item[1]}
                                                                    onChange={(val) =>
                                                                        handleChange(day as keyof IAvailability, i, 1, val, index)
                                                                    }
                                                                    isLabel={true}
                                                                    labelText={"End Time"}
                                                                    extraStyle={{ borderColor: "#009d91" }}
                                                                    type='time'
                                                                    isRequired={true}
                                                                />
                                                            </div>
                                                            {i !== 0 &&
                                                                <button
                                                                    onClick={() => deleteRow(day as keyof IAvailability, i, index)}
                                                                    type="button"
                                                                    className="ml-4 mt-[35px]"
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                            }
                                                        </div>
                                                    ))
                                                    : null
                                                }
                                                <div className="mt-4">
                                                    <button
                                                        onClick={() => addRow(day as keyof IAvailability, index)}
                                                        type="button"
                                                        className="font-Poppins-semiBold font-size-18px theme-color-green">
                                                        + Add another time
                                                    </button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className='my-5'>
                        <PrimaryBtn
                            type='submit'
                            loader={isLoader}
                            ButtonText='Save'
                            btnExtraStyle={{
                                backgroundColor: "#004540",
                                borderRadius: "50rem",
                                marginLeft: "auto"
                            }}
                        />
                    </div>
                </form>
                <div className="pb-4 border-b border-[#707070]">
                    <button
                        onClick={addHospitals}
                        type="button"
                        className="font-Poppins-semiBold font-size-18px theme-color-green">
                        + Add hospital
                    </button>
                </div>
            </div>
        </>
    );
};

export default HospitalAvailabilityEdit;