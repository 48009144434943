/**
 * BookDoctorAppointment
 * 
 * Description: This component handles both the payment process for booking a new appointment and rescheduling an existing appointment using Stripe. It includes form elements for card details, handles form submission, validates payment information, and manages the booking or rescheduling process. Upon successful payment or rescheduling, it displays a confirmation modal with updated appointment details.
 * 
 * File Name: BookDoctorAppointment.tsx
 * Date: 09-09-2024 // edit 16-09-2024
 */

import React, { useRef, useState, useEffect, FormEvent } from 'react';
import PageHeader from 'src/Components/Customer/PageHeader';
import BackIcon from "src/assets/images/back-icon.png";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import SimpleInput from 'src/Components/SimpleInput';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import timeIcon from "../../assets/images/time-icon.png"
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import SplashScreen from 'src/Components/SplashScreen';
import defaultProfile from "src/assets/images/default_profile_image.png";
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { loadStripe } from "@stripe/stripe-js";
import visaCard from 'src/assets/images/visa-card.png';
import {
    Elements,
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { CURRENCY } from 'src/lib/constans';

const stripePromise = loadStripe(process.env.REACT_APP_VITE_STRIPE_PK as string);

interface IDoctoInfo {
    days: string[];
    firstName: string;
    lastName: string;
    gender: string;
    hospitalName: string;
    image: string;
    fees: string;
};

interface ISlots {
    id: string;
    from: string;
    to: string;
    isBooked: boolean;
    isOnline: boolean;
};

interface IBooking {
    doctorId: string;
    hospitalId: string;
    slotId: string;
    appoinmentDate: string;
    patientName: string;
    phone: string;
    isOnlineAppoinment: boolean;
    totalAmount: number;
    startTime: string;
    endTime: string;
};

const AppointmentPaymentForm: React.FC<{ data: IBooking; allSlots: ISlots[] }> = ({ data, allSlots }) => {
    const stripe = useStripe(); // Stripe instance for handling payments
    const elements = useElements(); // Elements instance for Stripe components

    const [payLoader, setPayLoader] = useState<boolean>(false); // Loader state for payment processing
    const [isModal, setIsModal] = useState<boolean>(false); // Modal state for displaying confirmation

    // API_CALL: Function to handle form submission
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault(); // Prevent default form submission
        if (!stripe || !elements) {
            console.error("Stripe or Elements not loaded");
            return;
        }

        // Get card elements
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);
        if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
            console.error("Card Element(s) not found");
            return;
        }

        // Validate card details
        setPayLoader(true);
        const { error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement, // Use cardNumberElement for full validation
        });

        if (error) {
            toast(error.message, { type: 'error' });
            setPayLoader(false);
            return;
        }
        try {
            const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement, // card number for validation
            }); // Create a PaymentMethod

            if (paymentMethodError) {
                toast(paymentMethodError.message, { type: 'error' });
                setPayLoader(false);
                return;
            }

            const paymentIntentResponse = await httpRequest<{ clientSecret: string; paymentIntentId: string }>({
                method: "post",
                path: "/stripe/create-payment-intent",
                params: { currency: CURRENCY, amount: data.totalAmount }
            }); // Create payment intent

            if (paymentIntentResponse.err) {
                toast(paymentIntentResponse.err, { type: 'error' });
                throw new Error(paymentIntentResponse.err);
            }

            const clientSecret = paymentIntentResponse?.res?.clientSecret || "";

            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod.id,
            }); // Confirm the payment using clientSecret and card element

            if (confirmError) {
                toast(confirmError.message, { type: 'error' });
                setPayLoader(false);
                return;
            }

            if (paymentIntent?.status === "succeeded") {
                const { res, err } = await httpRequest<any>({
                    method: "post",
                    path: "/appoiment/booking",
                    params: data
                });
                if (res) {
                    setIsModal(true);
                } else {
                    toast(err, { type: 'error' });
                }
            } else {
                toast("Payment failed. Please try again.", { type: 'error' });
            }

        } catch (err) {
            toast('An error occurred during processing. Please try again.', { type: 'error' });
        } finally {
            setPayLoader(false);
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-5">
                    <div>
                        <label className='font-size-16px text-[#000] font-Poppins-Regular flex items-center gap-2' htmlFor="">Card Number
                        </label>
                        <div className='visa-card-number relative'>
                            <img src={visaCard} alt="visa card" />
                            <CardNumberElement
                                className="stripe-des font-size-16px font-Poppins-Regular paymnet-form-input"
                                id="cardNumber"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <div>
                            <label className='font-size-16px text-[#000] font-Poppins-Regular' htmlFor="">Expiry Date</label>
                            <CardExpiryElement
                                className="stripe-des font-size-16px font-Poppins-Regular paymnet-form-input"
                                id="CardExpiryElement"
                            />
                        </div>
                        <div>
                            <label className='font-size-16px text-[#000] font-Poppins-Regular' htmlFor="">CVV
                            </label>
                            <CardCvcElement
                                className="stripe-des font-size-16px font-Poppins-Regular paymnet-form-input"
                                id="CardCvcElement"
                            />
                        </div>
                    </div>
                </div>
                <div className='mt-4'>
                    <PrimaryBtn
                        loader={payLoader}
                        type="submit"
                        ButtonText="Pay now"
                        btnExtraStyle={{
                            borderRadius: "1.5rem",
                            backgroundColor: "#004540",
                            marginLeft: "auto"
                        }}
                    />
                </div>
            </form>
            {isModal &&
                <div className='modal-stripe-overlay'>
                    <div className='modal-stripe p-10'>
                        <h2 className='text-[#2F2F31] leading-tight font-size-40px font-Poppins-SemiBold text-center'>Your appointment has been booked for</h2>
                        <p className='font-Poppins-Regular font-size-18px text-[#474747]'>{moment(data.appoinmentDate).format("MMM DD")}, {moment(allSlots.find(v => v.id === data.slotId)?.from, "HH:mm").format("h:mm A")}</p>
                        <Link to="/dashboard" className='back-to-home-btn text-white font-size-18px font-Poppins-SemiBold'>Back To Home</Link>
                    </div>
                </div>
            }
        </>
    );
};

const BookDoctorAppointment = () => {
    const navigate = useNavigate();
    const { hid = "", did = "" } = useParams();
    const location = useLocation();
    const { isEdit = false } = location.state || false;
    const { userDetails } = useSelector((store: RootState) => store.appReducer);

    const [scrollPosition, setScrollPosition] = useState(0);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const scrollAmount = 225; // Adjust this value based on the width of your date items

    const getNextAvailableDays = (numDays: number, availableDays: string[]) => {
        const result = [];
        let currentDate = moment().startOf('day'); // Start from today
        for (let i = 0; i < numDays; i++) {
            const dayOfWeek = currentDate.format('dddd').toLowerCase(); // Get day of the week in lowercase
            const formattedDate = currentDate.format('YYYY-MM-DD'); // Format the date
            if (availableDays.includes(dayOfWeek)) {
                result.push({
                    date: formattedDate
                });
            }
            currentDate = currentDate.add(1, 'days'); // Move to the next day
        }
        return result;
    };

    const [comment, setComment] = useState<string>(""); // only for reschedule
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [rescheduleLoader, setRescheduleLoader] = useState<boolean>(false);
    const [slotsLoader, setSlotsLoader] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1);
    const [data, setData] = useState<IDoctoInfo | null>(null);
    const [datesRange, setDatesRange] = useState<{ date: string }[]>([]);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [allSlots, setAllSlots] = useState<ISlots[]>([]);
    const [mySelf, setMySelf] = useState<boolean>(true);

    const [nameError, setNameError] = useState<string>("");
    const [phoneError, setPhoneError] = useState<string>("");

    const [bookSlots, setBookSlots] = useState<IBooking>({
        doctorId: "",
        hospitalId: "",
        slotId: "",
        appoinmentDate: "",
        isOnlineAppoinment: false,
        totalAmount: 0,
        patientName: "",
        phone: "",
        startTime: "",
        endTime: "",
    });

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            const newPosition = Math.max(scrollPosition - scrollAmount, 0);
            setScrollPosition(newPosition);
        }
        const findIndex = datesRange.findIndex(v => v.date === selectedDate);
        setSelectedDate(datesRange[findIndex - 1].date);
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            const maxScroll = scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth;
            const newPosition = Math.min(scrollPosition + scrollAmount, maxScroll);
            setScrollPosition(newPosition);
        }
        const findIndex = datesRange.findIndex(v => v.date === selectedDate);
        setSelectedDate(datesRange[findIndex + 1].date);
    };

    const handleChange = (key: keyof IBooking, val: string | boolean | number) => {
        setBookSlots(prev => ({
            ...prev,
            [key]: val,
        }));
    };

    const handleSelectSlot = (item: ISlots) => {
        const { id, isOnline, from, to } = item;
        handleChange("slotId", id);
        handleChange("isOnlineAppoinment", isOnline);
        handleChange("startTime", from);
        handleChange("endTime", to);
        if (!isEdit) {
            setStep(2);
        }
    };

    const moveToConfirm = () => {
        if (!bookSlots.patientName) {
            setNameError("Patient Name is required!");
            return;
        }
        if (!bookSlots.phone) {
            setPhoneError("Patient Phone Number is required!");
            return;
        }
        setNameError("");
        setPhoneError("");
        setStep(3);
    };

    const fetchDoctorInfo = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IDoctoInfo[]>({
            path: `/appoiment/doctor/slots/${did}/${hid}`,
        });
        if (res) {
            setData(res[0]);
            const arr = getNextAvailableDays(30, res[0].days);
            setDatesRange(arr);
            if (!isEdit) {
                setSelectedDate(arr[0] ? arr[0].date : null);
            }
            handleChange("totalAmount", Number(res[0].fees) * 100);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    const fetchSlots = async (_date: string) => {
        setSlotsLoader(true);
        const { res, err } = await httpRequest<ISlots[]>({
            method: "post",
            path: "/appoiment/doctor/slots",
            params: {
                dateAvalible: moment(new Date(_date)).toISOString(), // ISOFormat
                doctotId: did,
                hospitalId: hid
            },
        });
        if (res) {
            setAllSlots(res);
        } else {
            toast(err, { type: 'error' });
        }
        setSlotsLoader(false);
    };

    const reScheduleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setRescheduleLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: "/reshedule/slots",
            params: {
                ...bookSlots,
                comment,
                appoinmentId: isEdit?._id,
            },
        });
        if (res) {
            toast(res?.message || "", { type: 'success' });
            navigate("/appointments");
        } else {
            toast(err, { type: 'error' });
        }
        setRescheduleLoader(false);
    };

    useEffect(() => {
        if (did && hid) {
            fetchDoctorInfo();
            handleChange("doctorId", did);
            handleChange("hospitalId", hid);
        }
    }, [did, hid]);

    useEffect(() => {
        if (isEdit) {
            setSelectedDate(moment(isEdit.appoinmentDate).format("YYYY-MM-DD"));
            handleChange("patientName", isEdit?.patientName || "");
            handleChange("phone", isEdit?.phone || "");
            handleChange("startTime", isEdit?.startTime || "");
            handleChange("endTime", isEdit?.endTime || "");
            handleChange("isOnlineAppoinment", isEdit?.isOnlineAppoinment || false);
        }
    }, [isEdit]);

    useEffect(() => {
        if (selectedDate && did && hid) {
            fetchSlots(selectedDate);
            handleChange("appoinmentDate", moment(new Date(selectedDate)).toISOString());
        }
    }, [selectedDate]);

    useEffect(() => {
        if (!isEdit) {
            if (mySelf) {
                if (userDetails) {
                    handleChange("patientName", userDetails.firstName + " " + userDetails.lastName);
                    handleChange("phone", userDetails.mobile);
                }
            } else {
                handleChange("patientName", "");
                handleChange("phone", "");
            }
        }
    }, [mySelf]);

    // Update the container transform based on the scroll position
    const containerStyle = {
        transform: `translateX(-${scrollPosition}px)`
    };

    const BackButton: React.FC = () => {
        return <button className='right-bar-icon'
            onClick={() => {
                if (step === 3 || step === 2) {
                    setStep(prev => prev - 1);
                } else {
                    navigate(-1)
                }
            }}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    return (
        <>
            <PageHeader title="Find Doctor" isButton={true} ButtonComponent={BackButton} />
            {isLoader && <SplashScreen />}
            {step === 1 ? (
                <div className='parent-box'>
                    <div className="parent-box">
                        {data ?
                            <div className='flex gap-4 align-start'>
                                <div>
                                    <img
                                        alt="profile image"
                                        width={80}
                                        height={80}
                                        className="rounded-full profile-image-find-docter"
                                        src={`${process.env.REACT_APP_API_BASEURL}${data.image}`}
                                        onError={(e) => {
                                            const target = e.target as HTMLImageElement; // Assert the type
                                            if (target) {
                                                target.src = defaultProfile;
                                            }
                                        }}
                                    />
                                </div>
                                <div className='pb-3'>
                                    <h4 className="font-size-28px font-Poppins-SemiBold theme-color-green capitalize">
                                        {`${data.firstName} ${data.lastName}`}
                                    </h4>
                                    <p className="font-size-18px font-Poppins-Regular theme-color-green">
                                        {data.hospitalName}
                                    </p> {/* specialization */}
                                    <span className="font-size-18px font-Poppins-Medium text-[#393939]">
                                        Fee: AED {data.fees}
                                    </span> {/* educations & degree */}
                                </div>
                            </div>
                            :
                            <div className='flex gap-4 align-start'>
                                <Skeleton variant="circular" width={80} height={80} />
                                <div className='pb-8' style={{ minWidth: 200 }}>
                                    <Skeleton variant="text" sx={{ fontSize: '28px' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '20px' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '20px' }} />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="parent-box">
                        <div className="border-b">
                            <div className='relative'>
                                <button disabled={selectedDate === datesRange[0]?.date} className='absolute left-0 left-arrow-top-side' onClick={scrollLeft}>
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </button>
                                <button disabled={selectedDate === datesRange[(datesRange?.length - 1)]?.date} className='absolute right-0 left-arrow-top-side' onClick={scrollRight}>
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </button>
                                <div className='overflow-x-hidden mx-8'>
                                    <div
                                        ref={scrollContainerRef}
                                        className="gap-28 mr-[7rem] flex items-start scroll-container"
                                        style={containerStyle}
                                    >
                                        {datesRange.map(item => (
                                            <button onClick={() => setSelectedDate(item.date)} key={item.date} className='flex-shrink-0'>
                                                <p className={`appointment-date font-Poppins-Regular font-size-20px px-3 pt-1 pb-2 cursor-pointer ${item.date === selectedDate && "active"}`}>
                                                    {moment(item.date).format('DD MMMM')}
                                                </p>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-8 pb-6 time-container-find-doctor items-center gap-4 border-b">
                            {slotsLoader ?
                                Array.from({ length: 4 }).map((_, i) => (
                                    <Skeleton key={i} variant="rounded" height={40} />
                                ))
                                :
                                allSlots.map((item: ISlots, index: number) => (
                                    <button onClick={() => handleSelectSlot(item)} key={index} disabled={item.isBooked} className={`${item.isBooked ? 'disabled' : ""} ${bookSlots?.slotId === item.id && "active"} appointment-time border border-[#878787] px-6 py-2 rounded-md flex items-center justify-center disabled:bg-gray-300 disabled:cursor-not-allowed`}>
                                        <p className="font-Poppins-Regular font-size-18px">
                                            {`${moment(item.from, "HH:mm").format("h:mm A")} to ${moment(item.to, "HH:mm").format("h:mm A")}`}
                                        </p>
                                    </button>
                                ))
                            }
                        </div>
                        {isEdit &&
                            <div className="py-5">
                                <p className="font-Poppins-Regular font-size-20px">
                                    Reason
                                </p>
                                <form onSubmit={reScheduleSubmit}>
                                    <SimpleInput
                                        isRequired={true}
                                        value={comment}
                                        onChange={(val) => setComment(val)}
                                        type="textArea"
                                        rows={10}
                                        isLimit={1200}
                                        maxLength={1200}
                                        extraStyle={{ borderColor: "#009d91" }}
                                    />
                                    <div className="flex justify-end mt-10">
                                        <PrimaryBtn
                                            loader={rescheduleLoader}
                                            type="submit"
                                            ButtonText="Save"
                                            btnExtraStyle={{
                                                borderRadius: "1.5rem",
                                                backgroundColor: "#004540"
                                            }}
                                        />
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            )
                : step === 2 ? (
                    <div className="grid grid-cols-[7fr_3fr] gap-4">
                        <div className="parent-box">
                            <h1 className="font-Poppins-Medium font-size-20px mb-6">
                                Appointment for
                            </h1>
                            <div className="flex gap-4 items-center">
                                <button onClick={() => setMySelf(true)} className={`border font-size-16px font-Poppins-SemiBold health-tabs-main-btn rounded-full ${mySelf ? "text-[#fff] theme-bg-green" : "theme-color-green border-[#004540]"}`}>My Self</button>
                                <button onClick={() => setMySelf(false)} className={`border font-size-16px font-Poppins-SemiBold health-tabs-main-btn rounded-full ${!mySelf ? "text-[#fff] theme-bg-green" : "theme-color-green border-[#004540]"}`}>Someone Else</button>
                            </div>
                            <hr className="my-4" />
                            <div className="mb-3">
                                <SimpleInput
                                    readOnly={mySelf ? true : false}
                                    value={bookSlots.patientName}
                                    onChange={(val) => handleChange("patientName", val)}
                                    isLabel={true}
                                    labelText="Patient Name"
                                    isRequired={false}
                                    extraStyle={{ borderColor: "#00A297" }}
                                />
                                <p className="font-size-14 text-[#ff0000]">{nameError}</p>
                            </div>
                            <div className="mb-3">
                                <SimpleInput
                                    readOnly={mySelf ? true : false}
                                    value={bookSlots.phone}
                                    onChange={(val) => handleChange("phone", val)}
                                    type='number'
                                    isLabel={true}
                                    labelText="Phone Number"
                                    isRequired={false}
                                    extraStyle={{ borderColor: "#00A297" }}
                                />
                                <p className="font-size-14 text-[#ff0000]">{phoneError}</p>
                            </div>
                            <h1 className="font-Poppins-Medium font-size-20px mb-2">
                                Payment method
                            </h1>
                            <div className="bg-[#ECFFFE] border border-[#00A297] inline-block rounded-md px-4 py-2 text-[#646464] text-center">
                                <span className="font-Poppins-Regular font-size-15px mr-6">
                                    Online payment
                                </span>
                                <span className="font-Poppins-Regular font-size-18px">
                                AED {data?.fees}
                                </span>
                            </div>
                        </div>
                        <div className="parent-box h-fit">
                            <div className='flex items-start gap-3'>
                                <img
                                    alt="profile image"
                                   className="rounded-full profile-image-find-docter-confirm-booking"
                                    src={`${process.env.REACT_APP_API_BASEURL}${data?.image}`}
                                    onError={(e) => {
                                        const target = e.target as HTMLImageElement; // Assert the type
                                        if (target) {
                                            target.src = defaultProfile;
                                        }
                                    }}
                                />
                                <div className='flex flex-col gap-3'>
                                    <div>
                                        <p className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize">
                                            {`${data?.firstName} ${data?.lastName}`}
                                        </p>
                                        <p className="font-size-13px font-Poppins-Regular theme-color-green flex items-center gap-1">
                                            {data?.hospitalName}
                                        </p>
                                    </div>
                                    <div className="rounded-full bg-[#F1F1F1] px-4 py-2 flex items-center gap-1">
                                        <span className='mr-2'>
                                            <img style={{width:'1vw',height:'1vw'}} src={timeIcon} alt="time icon" />
                                        </span>
                                        <span className='font-Poppins-Regular font-size-13px text-[#474747]'>{moment(bookSlots.appoinmentDate).format("MMM DD")}, {moment(allSlots.find(v => v.id === bookSlots.slotId)?.from, "HH:mm").format("h:mm A")}</span>
                                    </div>
                                    <div>
                                        <p className="font-Poppins-Medium font-size-20px">
                                            Fee: AED {data?.fees}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 self-start mt-6'>
                                <PrimaryBtn onClick={moveToConfirm} type='button' ButtonText='Confirm booking' btnExtraStyle={{ borderRadius: '50rem', padding:7, background: "#00443F" }} />
                            </div>
                        </div>
                    </div>
                )
                    : step === 3 ? (
                        <div className='grid grid-cols-[7fr_3fr] gap-4'>
                            <div className="parent-box">
                                <h1 className="font-Poppins-Medium font-size-20px mb-6">Payment details</h1>
                                <Elements stripe={stripePromise}>
                                    <AppointmentPaymentForm data={bookSlots} allSlots={allSlots} />
                                </Elements>
                            </div>
                            <div className="parent-box h-fit">
                                <div className='flex items-start gap-3'>
                                    <img
                                        alt="profile image"
                                        className="rounded-full profile-image-find-docter-confirm-booking"
                                        src={`${process.env.REACT_APP_API_BASEURL}${data?.image}`}
                                        onError={(e) => {
                                            const target = e.target as HTMLImageElement; // Assert the type
                                            if (target) {
                                                target.src = defaultProfile;
                                            }
                                        }}
                                    />
                                    <div className='flex flex-col gap-3'>
                                        <div>
                                        <p className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize">
                                                {`${data?.firstName} ${data?.lastName}`}
                                            </p>
                                            <p className="font-size-13px font-Poppins-Regular theme-color-green flex items-center gap-1">
                                                {data?.hospitalName}
                                            </p>
                                        </div>
                                        <div className="rounded-full bg-[#F1F1F1] px-4 py-2 flex items-center gap-1">
                                            <span className='mr-2'>
                                                <img style={{width:'1vw',height:'1vw'}} src={timeIcon} alt="time icon" />
                                            </span>
                                            <span className='font-Poppins-Regular font-size-13px text-[#474747]'>{moment(bookSlots.appoinmentDate).format("MMM DD")}, {moment(allSlots.find(v => v.id === bookSlots.slotId)?.from, "HH:mm").format("h:mm A")}</span>
                                        </div>
                                        <div>
                                            <p className="font-Poppins-Medium font-size-20px">
                                                Fee: AED {data?.fees}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                        : null
            }
        </>
    );
};

export default BookDoctorAppointment;