/**
 * CommentInput Component
 * 
 * Description: This component allows users to input comments in a discussion. 
 * It features an emoji picker for adding emojis to comments and handles 
 * submission of comments, including showing loading indicators.
 * 
 * File Name: CommentInput.tsx
 * Date: 26-09-2024
 * MARKER: DOC_START
 */

import React, { useState, useRef, useEffect, FormEvent } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import defaultProfile from "src/assets/images/default_profile_image.png";
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { ICommentItem } from 'src/types/GlobalInterfaces';

const CommentInput: React.FC<{ discussionId: string, commentId?: string | null, onCommentSend: (obj: ICommentItem, commentId: string | null) => void }> = ({
    discussionId, commentId = null, onCommentSend }
) => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
    const emojiPickerRef = useRef<HTMLDivElement | null>(null);
    const toggleEmojiPicker = () => {
        setShowEmojiPicker((prev) => !prev);
    };
    const handleClickOutside = (event: MouseEvent) => {
        if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
            setShowEmojiPicker(false);
        }
    };

    // ******************* state & functions of add comment ******************* //
    const [caption, setCaption] = useState<string>('');
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const handleEmojiSelect = (emoji: { native: string }) => {
        setCaption((prev) => prev + emoji.native);
        setShowEmojiPicker(false);
    };

    // API_CALL: Add a new comment in discussion
    const handleAddComment = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; createComment: ICommentItem }>({
            method: "post",
            path: `/comment/add/${discussionId}`,
            params: { caption, ...(commentId ? { commentId } : {}) }
        });
        if (res) {
            const { _id, caption, createdAt, userId, commentId } = res.createComment;
            const obj: ICommentItem = {
                _id,
                coverPhoto: userDetails?.coverPhoto,
                firstName: userDetails?.firstName,
                lastName: userDetails?.lastName,
                caption,
                createdAt,
                commentId,
                reactionStatus: false,
                reactions: 0,
                reply: 0,
                role: userDetails?.userRole || "",
                userId,
            };
            onCommentSend(obj, commentId || null);
            setCaption("");
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };
    // ******************* state & functions of add comment ******************* //

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <form onSubmit={handleAddComment} className='comment-section'>
            <img
                className='comment-user-img'
                alt="User"
                src={`${process.env.REACT_APP_API_BASEURL}${userDetails?.coverPhoto}`}
                onError={(e) => {
                    const target = e.target as HTMLImageElement; // Assert the type
                    if (target) {
                        target.src = defaultProfile;
                    }
                }}
            />
            <div className='comment-section-inner'>
                <input
                    className='pl-2 pr-6 font-size-16px font-Poppins-Medium'
                    placeholder='Add a comment...'
                    type='text'
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                    required={true}
                />
                <FontAwesomeIcon
                    className='comment-section-inner-svg'
                    icon={faFaceSmile}
                    onClick={toggleEmojiPicker}
                    aria-label="Toggle emoji picker"
                />
                {showEmojiPicker && (
                    <div
                        ref={emojiPickerRef}
                        className='emoji-picker-container'
                        style={{ position: 'absolute', zIndex: 1, right: 0, top: '100%' }}
                    >
                        <Picker
                            previewPosition="none"
                            data={data}
                            onEmojiSelect={handleEmojiSelect}
                        />
                    </div>
                )}
            </div>
            <button
                type="submit"
                className='font-size-16px text-white comment-section-button font-Poppins-Medium'
            >
                {isLoader ?
                    <span className="animate-pulse">
                        <FontAwesomeIcon
                            className='text-white font-size-22px'
                            icon={faEllipsisH}
                        />
                    </span>
                    :
                    "Comment"
                }
            </button>
        </form>
    );
};

export default CommentInput;