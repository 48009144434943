/**
 * chatSlice
 * 
 * Description: Redux slice to manage chat-related state, including chat list, chat history, selected user, and loader state.
 * 
 * File Name: chatSlice.ts
 * Date: 23-09-2024
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChatItem, ILatestChat, ISelectedChatUser } from 'src/types/GlobalInterfaces';

// Define the application state interface
export interface AppState {
    latestChat: ILatestChat[];
    chatLoader: boolean;
    selectedUser: ISelectedChatUser | null;
    chatHistory: IChatItem[];
};

// Initial state of the chat slice
const initialState: AppState = {
    latestChat: [],
    chatLoader: false,
    selectedUser: null,
    chatHistory: [],
};

// Create the chat slice
export const chatSlice = createSlice({
    name: 'CHAT',
    initialState,
    reducers: {
        toogleChatLoader: (state, action: PayloadAction<boolean>) => {
            state.chatLoader = action.payload;
        },
        setSelectedChatUser: (state, action: PayloadAction<ISelectedChatUser>) => {
            state.selectedUser = action.payload;
        },
        resetSelectedChatUser: (state) => {
            state.selectedUser = null;
        },
        setChatList: (state, action: PayloadAction<ILatestChat[]>) => {
            state.latestChat = action.payload || [];
        }, // set complete list
        useStatusToogle: (state, action: PayloadAction<{ statusOnline: boolean; userId: string }>) => {
            const { statusOnline = false, userId = "" } = action.payload;
            const newArr = state.latestChat.map((v: ILatestChat) =>
                v?._id === userId
                    ? { ...v, statusOnline }
                    :
                    v
            );
            state.latestChat = newArr;
        }, // status toogle
        setChatHistory: (state, action: PayloadAction<IChatItem[]>) => {
            state.chatHistory = action.payload;
        },
        resetChatHistory: (state) => {
            state.chatHistory = [];
        },
        pushNewMessage: (state, action: PayloadAction<IChatItem>) => {
            const { senderId } = action.payload;
            if (senderId === state.selectedUser?._id || "") {
                state.chatHistory = [...state.chatHistory, action.payload];
            }
            const updatedLatestChat = state.latestChat.map((v: ILatestChat) =>
                v?._id === senderId ?
                    {
                        ...v,
                        lastMessageTime: action.payload.createdAt,
                        lastMessageType: action.payload.type,
                        lastMessage: action.payload.messageBody,
                        unRead: (senderId === state.selectedUser?._id) ? v.unRead : v.unRead + 1,
                    }
                    : v
            );
            const userIndex = updatedLatestChat.findIndex(v => v?._id === senderId);
            const [updatedUser] = updatedLatestChat.splice(userIndex, 1);
            state.latestChat = [updatedUser, ...updatedLatestChat];
        },
        sendNewMessage: (state, action: PayloadAction<{ data: IChatItem; receiverId: string }>) => {
            const { data, receiverId } = action.payload;
            state.chatHistory = [...state.chatHistory, data];
            const updatedLatestChat = state.latestChat.map((v: ILatestChat) =>
                v?._id === receiverId ?
                    {
                        ...v,
                        lastMessageTime: data.createdAt,
                        lastMessageType: data.type,
                        lastMessage: data.messageBody
                    }
                    : v
            );
            const userIndex = updatedLatestChat.findIndex(v => v?._id === receiverId);
            const [updatedUser] = updatedLatestChat.splice(userIndex, 1);
            state.latestChat = [updatedUser, ...updatedLatestChat];
        },
        resetIndividualCount: (state, action: PayloadAction<{ id: string }>) => {
            const { id } = action.payload;
            state.latestChat = state.latestChat.map((v: ILatestChat) =>
                v?._id === id ?
                    {
                        ...v,
                        unRead: 0
                    }
                    : v
            );
        }
    }
});

// Export action creators
export const { toogleChatLoader, setSelectedChatUser, resetSelectedChatUser, setChatList, useStatusToogle, setChatHistory, resetChatHistory, pushNewMessage, sendNewMessage, resetIndividualCount } = chatSlice.actions;

// Export the reducer to be used in the store
export default chatSlice.reducer;