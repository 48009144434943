/**
 * BreadCrumb Component
 * 
 * Description: This component renders a breadcrumb navigation with a current page title, previous page link, and an optional button for additional actions. It uses FontAwesome for icons and React Router for navigation.
 * 
 * File Name: BreadCrumb.jsx
 * Date: 23-07-2024
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackIcon from "../../assets/images/back-icon.png";

interface IBreadCrumbProps {
    current: string;
    previous: string;
    navigateUrl: string;
    btnText?: string;
    onPress?: () => void;
    btnShow?: boolean;
    isUpsert?: boolean;
};

const BreadCrumb: React.FC<IBreadCrumbProps> = ({
    current = "",
    previous = "",
    navigateUrl = "/",
    btnText = "",
    onPress = () => { },
    btnShow = false,
    isUpsert = false }) => {
    return (
        <div className="bread__crumb">
            <div className="flex items-end justify-between">
                <div className="left__bar">
                    <h1 className="font-size-36px font-Poppins-Medium capitalize">{current}</h1>
                    <span className="font-size-18px font-Poppins-Medium capitalize"><Link className='font-Poppins-Bold' to={navigateUrl}>{previous}</Link> / {current}</span>
                </div>
                {btnShow &&
                    <>
                        {
                            isUpsert
                                ?
                                <button className='right-bar-icon' onClick={onPress}>
                                    <img src={BackIcon} alt="back-icon" />
                                </button>
                                :
                                <button onClick={onPress} className="right__bar font-size-15px font-Poppins-Medium capitalize">
                                    <FontAwesomeIcon icon={faPlus} />
                                    {btnText}
                                </button>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default BreadCrumb;