/**
 * InputsModal Component
 *
 * A reusable modal component for displaying input fields and a submit button. It is used to collect input from the user and handle form submissions. The modal's visibility, close action, and submit action are controlled via props.
 *
 * File Name: InputsModal.tsx
 * Date: 31-07-2024
 */

import React from 'react';
import { Modal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import SimpleInput from '../SimpleInput';

interface IObj { value: string; setValue: (e: string) => void; title?: string, type: string, isRequired: boolean }

interface IProps {
    show?: boolean;
    onClose?: () => void;
    btnText: string;
    onSubmit: () => void;
    arr: IObj[]
}

const InputsModal: React.FC<IProps> = ({
    show = true,
    onClose = () => { },
    btnText = "",
    onSubmit = () => { },
    arr = []
}) => {
    // Style for the modal container
    const customeStyle: React.CSSProperties = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgb(255, 255, 255)",
        maxWidth: 400,
        width: "100%",
        padding: 12,
        borderRadius: 8
    };
    // Style for the confirm button
    const greenBtn: React.CSSProperties = {
        padding: "5px 32px",
        backgroundColor: "#00443F",
        color: "white",
        borderRadius: "1.5rem"
    };
    // Style for the close button text
    const backBtn: React.CSSProperties = {
        color: "white",
    };
    // Style for the close button container
    const backBtnMain: React.CSSProperties = {
        position: "absolute",
        top: -10,
        right: -10,
        backgroundColor: "red",
        borderRadius: 100,
        width: 20,
        height: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };
    return (
        <Modal
            open={show}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={customeStyle}>
                <button onClick={onClose} style={backBtnMain}>
                    <FontAwesomeIcon className='font-size-16px ' icon={faXmark} size="2x" style={backBtn} />
                </button>
                {/* Render input fields based on the array of input objects */}
                {arr.map((v: IObj, i: number) => (
                    <SimpleInput
                        key={i}
                        value={v?.value}
                        onChange={(val) => v?.setValue(val)}
                        isLabel={true}
                        labelText={v?.title}
                        isRequired={v?.isRequired}
                        type={v?.type}
                        rows={5}
                    />
                ))}
                <div className="flex justify-end items-center gap-3">
                    <button onClick={onSubmit} className='font-size-16px font-Poppins-Regular' style={greenBtn}>{btnText}</button>
                </div>
            </div>
        </Modal>
    );
};

export default InputsModal;