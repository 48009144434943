import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

interface ISearchFilterProps {
    onChangeText?: (value: string) => void;
    placeHolder?: string;
    debounceTime?: number;
    containerExtraStyle?: React.CSSProperties; // Correct type for CSS styles
    inputExtraStyle?: React.CSSProperties; // Correct type for CSS styles
};

const SearchFilterInput: React.FC<ISearchFilterProps> = ({
    onChangeText = () => { },
    placeHolder = "Search",
    debounceTime = 1000,
    containerExtraStyle = {},
    inputExtraStyle = {}
}) => {
    const [value, setValue] = useState<string>("");
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    // Debounced function
    const debounce = useCallback((func: (value: string) => void, delay: number) => {
        return (value: string) => {
            if (timer) clearTimeout(timer);
            const newTimer = setTimeout(() => func(value), delay);
            setTimer(newTimer);
        };
    }, [timer]);

    const handleChange = (val: string) => {
        setValue(val);
        debounce(onChangeText, debounceTime)(val); // Pass the updated value to debounce
    };

    return (
        <div style={{ ...containerExtraStyle }} className='flex justify-end'>
            <div style={{ ...inputExtraStyle }} className='admin-header-search admin-categories-search'>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                <input
                    placeholder={placeHolder}
                    className='font-size-15px font-Poppins-Medium'
                    type="search"
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                    style={{ backgroundColor: "transparent" }}
                />
            </div>
        </div>
    );
};

export default SearchFilterInput;