/**
 * AdminSidebar Component
 * 
 * Description: This component renders a sidebar for the admin panel. It includes navigation links to various sections of the admin interface, with corresponding icons and titles.
 * 
 * File Name: AdminSidebar.tsx
 * Date: 23-07-2024
 */

import React, { useEffect, useState } from 'react';
import dashboardIcon from '../../assets/images/dashbaord-icon.png';
import rolesIcon from '../../assets/images/team-roles-icon.png';
import operatorsIcon from '../../assets/images/operators-icon.png';
import customersIcon from '../../assets/images/customers-icon.png';
import doctorsIcon from '../../assets/images/doctors-icon.png';
import categoriesIcon from '../../assets/images/categories-icon.png';
import biomarkersIcon from '../../assets/images/biomarkers-icon.png';
import productsIcon from '../../assets/images/products-icon.png';
import ordersIcon from '../../assets/images/order-icons.png';
import specializaionIcon from "../../assets/images/patient.svg";
import diseasesIcon from "../../assets/images/virus.svg";
import analyticsIcon from "../../assets/images/analytisc-icon.png";
import revenueIcon from "../../assets/images/revenue-icon.png";
import discussionsIcon from '../../assets/images/discussions-icon.png';
import withdrawalIcon from '../../assets/images/withdrawal-icon.png';
import apointmentIcon from "src/assets/images/appointment.png";
import supportHubIcon from "src/assets/images/support-hub.png";
import settingIcon from "src/assets/images/settings-icon.png";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeToken } from '../../helpers/helperFunctions';
import { removeAuth } from '../../Store/reducer';
import { AppDispatch } from '@store/store';

interface IListItem {
  title: string;
  icons: string;
  url?: string;
  uniqueIdetifier?: string;
  child?: { title: string; url: string; }[];
}

const AdminSidebar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [dropDownId, setDropDownId] = useState<string>("");

  // List of navigation items with titles, icons, and URLs
  const listItems: IListItem[] = [
    { title: "Dashboard", icons: dashboardIcon, url: "/admin" },
    { title: "Roles", icons: rolesIcon, url: "/roles" },
    { title: "Operators", icons: operatorsIcon, url: "/operators" },
    { title: "Customers", icons: customersIcon, url: "/customers" },
    { title: "Doctors", icons: doctorsIcon, url: "/doctors" },
    { title: "Categories", icons: categoriesIcon, url: "/categories" },
    { title: "Biomarkers", icons: biomarkersIcon, url: "/biomarkers" },
    { title: "Products", icons: productsIcon, url: "/products" },
    { title: "Orders", icons: ordersIcon, url: "/all-orders" },
    { title: "Specializations", icons: specializaionIcon, url: "/all-specialization" },
    { title: "Diseases", icons: diseasesIcon, url: "/all-disease" },
    { title: "Revenue Management", icons: revenueIcon, url: "/revenue-management" },
    { title: "Analytics", icons: analyticsIcon, url: "/analytics" },
    { title: "Discussions", icons: discussionsIcon, url: "/discussions-list" },
    { title: "Withdrawal", icons: withdrawalIcon, url: "/withdrawal-requests" },
    // {
    //   title: "Pharma Categories", icons: withdrawalIcon, uniqueIdetifier: "pharma__category", child: [
    //     { title: "Main Categories", url: "/pharmacy/main-categories" },
    //     { title: "Sub Categories", url: "/pharmacy/sub-categories" },
    //     { title: "Child Categories", url: "/pharmacy/child-categories" },
    //   ]
    // },
    // { title: "Brands", icons: withdrawalIcon, url: "/pharmacy/brands" },
    { title: "Appointment", icons: apointmentIcon, url: "/all-appointments" },
    { title: "Support Hub", icons: supportHubIcon, url: "/support-list" },
    { title: "Platform Settings", icons: settingIcon, url: "/platform-settings" },
  ];

  const toogleDrawer = (key: string) => {
    setDropDownId(key === dropDownId ? "" : key);
  };

  const handleLogOut = () => {
    removeToken();
    dispatch(removeAuth());
    navigate('/admin/login');
  };

  useEffect(() => {
    const notClose = ["/pharmacy/main-categories", "/pharmacy/sub-categories", "/pharmacy/child-categories"];
    if (!notClose.includes(location.pathname)) {
      setDropDownId("");
    }
  }, [location.pathname]);

  return (
    <div className="sidebar fixed top-0">
      <Link
        className="sidebarlogo-parent flex items-center justify-center mx-auto"
        to=""
      >
      </Link>
      <ul className="sidebar-navigation-parent" style={{ cursor: "pointer" }}>
        {listItems?.map((item, index) => {
          return (
            <li className={`sidebar-navigation ${location.pathname === item.url ? 'active' : ''}`} key={index}>
              {item.child ?
                <>
                  <button
                    onClick={() => toogleDrawer(item?.uniqueIdetifier || "")}
                    className="sidebar-navigation-vendor flex items-center"
                  >
                    <img className="sidebar-icon" src={item?.icons} alt={item?.title || ""} />
                    <span className="text-white capitalize font-Poppins-Regular font-size-15px sidebar-nav-text">
                      {item?.title || ""}
                    </span>
                  </button>
                  {item.uniqueIdetifier === dropDownId &&
                    <div className="ps-5">
                      {item?.child?.map(v => (
                        <Link
                          to={v?.url || "/"}
                          className={`block child__item ps-2 ${location.pathname === v?.url ? "active__child" : ""}`}
                        >
                          <span className="text-white capitalize font-Poppins-Regular font-size-15px sidebar-nav-text">
                            {v?.title || ""}
                          </span>
                        </Link>
                      ))}
                    </div>
                  }
                </>
                :
                <Link
                  to={item?.url || "/"}
                  className={`sidebar-navigation-vendor flex items-center`}
                >
                  <img className={`sidebar-icon ${item?.title === 'Appointment' ? 'appointment-sidebar-icon' : ''} `} src={item?.icons} alt={item?.title || ""} />
                  <span className="text-white capitalize font-Poppins-Regular font-size-15px sidebar-nav-text">
                    {item?.title || ""}
                  </span>
                </Link>
              }
            </li>
          )
        })}
      </ul>
      <div className='logut-btn'>
        <button onClick={handleLogOut} className='text-white capitalize font-Poppins-Medium font-size-18px'>Logout</button>
      </div>
    </div>
  );
};

export default AdminSidebar;