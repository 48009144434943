/**
 * LinkTab Component
 *
 * Description: This component allows users to select multiple links associated with a product. 
 * It fetches available links from the API and displays them in a multi-select dropdown. 
 * It also includes a "Next" button to proceed to the next tab.
 *
 * Props:
 * - state (IProductItems): The current state containing product details and associated links.
 * - setState (function): Function to update the product state, including links associated with the product.
 * - tabChange (function): Callback function invoked to change the active tab after selecting links.
 *
 * File Name: LinkTab.tsx
 * Date: 31-07-2024
 * 
 * Marker: DOC_START
 */

import React, { useState, useEffect } from 'react';
import { IDropDownArray, IProductItems } from 'src/types/GlobalInterfaces';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';

const LinkTab: React.FC<{
    state: IProductItems; setState: React.Dispatch<React.SetStateAction<IProductItems>>; tabChange: () => void
}> = ({
    state,
    setState,
    tabChange,
}) => {

        const [isLoader, setIsLoader] = useState<boolean>(false); // State to handle loading status
        const [allLinks, setAllLinks] = useState<IDropDownArray[]>([]); // State to store the list of links for the dropdown

        //API_CALL: Function to fetch all links from the API
        const fetAllLinks = async () => {
            setIsLoader(true);
            const { res, err } = await httpRequest<{ linkName: string; _id: string, [key: string]: any }[]>({ path: `/category/link` });
            if (res) {
                // Map the response to dropdown options
                setAllLinks(res?.map(v => ({ label: v?.linkName, value: v?._id })));
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        };

        useEffect(() => {
            fetAllLinks();
        }, []); // Fetch links when the component mounts

        return (
            <>
                <div className='admin-create-role admin-categoreis-height'>
                    <SelectDropdown
                        loader={isLoader}
                        options={allLinks}
                        isMulti={true}
                        data={allLinks?.filter(v => state?.linksId?.includes(v.value))}
                        setData={(e) => setState(prev => ({ ...prev, linksId: e?.map(v => v?.value) }))}
                        checkboxShow={true}
                        isLabel={true}
                        labelText="Links"
                        isRequired={true}
                    />
                </div>
                <div className='flex justify-end my-6'>
                    <PrimaryBtn
                        ButtonText="Next"
                        btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        onPress={tabChange}
                    />
                </div>
            </>
        );
    };

export default LinkTab;