import { createSlice, PayloadAction, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import httpRequest from 'src/helpers/httpRequest';
import { IAvailability, IDoctorExperiences, IEducationsHistory } from 'src/types/GlobalInterfaces';

export const setDoctorState = createAsyncThunk(
    "SET/DOCTOR",
    async (_id: string, { rejectWithValue }) => {
        try {
            const { res, err } = await httpRequest({ path: `/provider/${_id}` });
            if (err) {
                return rejectWithValue(err);
            }
            return res;
        } catch (error) {
            // Safely handle the error object
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            return rejectWithValue('An unknown error occurred');
        }
    }
);

interface IDoctorItems {
    _id: string;
    aboutMe: string;
    experiences: IDoctorExperiences[];
    education: IEducationsHistory[];
    servicesDetails: { _id: string; name: string }[];
    diseasesDetails: { _id: string; name: string }[];
    specailizedDetails: { _id: string; name: string }[];
    availability: IAvailability;
    timings: IAvailability[];
    educatuonHistory: string[];
    workExperienceMonthly: number;
    reviewStar: number;
    [key: string]: any;
};

// Initial state of the doctor slice
const initialState: { profile: IDoctorItems | null; loader: boolean; error: string | SerializedError | null } = {
    loader: false,
    profile: null,
    error: null,
};

// Create the doctor slice
export const doctor = createSlice({
    name: 'DOCTOR_SLICE',
    initialState,
    reducers: {
        removeDoctorState: (state) => {
            state.profile = null;
        },
        updateDoctorField: (state, action: PayloadAction<Partial<IDoctorItems>>) => {
            if (state.profile)
                state.profile = { ...state.profile, ...action.payload }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setDoctorState.pending, (state) => {
                state.loader = true;
                state.error = null; // Reset error on new request
            })
            .addCase(setDoctorState.fulfilled, (state, action: PayloadAction<IDoctorItems>) => {
                state.loader = false;
                state.profile = action.payload; // Store the fetched doctor data
            })
            .addCase(setDoctorState.rejected, (state, action) => {
                state.loader = false;
                state.error = action.payload || action.error; // Store the error message or SerializedError
            });
    },
});

// Export action creators
export const { removeDoctorState, updateDoctorField } = doctor.actions;

// Export the reducer to be used in the store
export default doctor.reducer;