/**
 * CustomerLayout Component
 * 
 * Description: This component provides a layout for customer-related pages. It includes a header and a sidebar, with conditional rendering based on the current route. If the route is not included in the `exceptSidebar` array, both the header and sidebar are displayed; otherwise, only the content (`children`) is shown.
 * 
 * Props:
 * - children (ReactNode): The content to be rendered inside the layout. This typically includes the page-specific components or elements.
 * 
 * File Name: CustomerLayout.tsx
 * Date: 12-08-2024
 */

import React, { ReactNode } from 'react';
import CustomerHeader from 'src/Components/Customer/CustomerHeader';
import CustomerSidebar from 'src/Components/Customer/CustomerSidebar';
import { useLocation } from 'react-router-dom';

interface ICustomerLayoutProps {
    children: ReactNode;
};

const CustomerLayout: React.FC<ICustomerLayoutProps> = ({ children }) => {
    const exceptSidebar: string[] = ["/profile-view", "/checkout-payment", "/doctor-public-profile", "/scheduler", "/patient-public/"];
    const { pathname } = useLocation();
    // Function to check if current path matches any path in exceptSidebar
    const isExcludedPath = (path: string) => {
        return exceptSidebar.some(excludedPath => pathname.startsWith(excludedPath));
    };
    return (
        <div className="dashboards bg-[#F4F2EE]">
            <CustomerHeader />
            <>
                {
                    isExcludedPath(pathname)
                        ?
                        children
                        :
                        <div className="container-1480 h-3vw flex justify-between">
                            <CustomerSidebar />
                            <section className="admin-right-side">
                                {children}
                            </section>
                        </div>
                }
            </>
        </div>
    );
};

export default CustomerLayout;