/**
 * BiomarkerPieChart Component
 * 
 * Description: This component displays a pie chart representation of biomarker statistics. 
 * The chart uses data fetched from an API to visualize the count of various biomarkers.
 * 
 * File Name: BiomarkerPieChart.tsx
 * Date: 29-08-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { PieChart, Pie, Cell, Text, Tooltip, TooltipProps, ResponsiveContainer } from 'recharts';
import httpRequest from 'src/helpers/httpRequest';

const colors: string[] = [
    '#F6B667', '#80E7DA', '#FF8E84', '#FF4C61', '#FFA4C4',
    '#46C173', '#4E95D2', '#A66F37', '#A383FF', '#FFAA7D',
    '#6CC6A1', '#FFBD44', '#FF57BB', '#3DB9FF', '#FF7676',
    '#B877FF', '#FFD076', '#72EAD4', '#6B8EF1', '#FF98A1',
    '#FFA867', '#8CFF95', '#FFEB67', '#FF6E6E', '#B2FF6E',
    '#6EBFFF', '#FF94D6', '#FF8C4C', '#95FF8C', '#FF4C88'
];

interface IBioStats {
    name: string;
    biomarkerCount: number;
};

const BiomarkerPieChart: React.FC = () => {

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [data, setData] = useState<IBioStats[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);

    const fetchBiomarkersStats = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ totalBiomarkers: number; categoriesWithCounts: IBioStats[] }[]>({ path: "/biomarker/graph/statistics" });
        if (res) {
            const { totalBiomarkers, categoriesWithCounts } = res[0];
            setTotalCount(totalBiomarkers);
            setData(categoriesWithCounts);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchBiomarkersStats();
    }, []);

    // Custom label rendering function
    const renderCustomLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        value,
    }: {
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        value: number;
    }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <Text x={x} y={y} fill="white" verticalAnchor="middle" textAnchor='middle' dominantBaseline="center">
                {value}
            </Text>
        );
    };

    return (
        <div style={{ height: '100%' }}>
            <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">Biomarkers</h2>
            <div className='user-graph-inner h-[90%]'>
                {
                    isLoader
                    &&
                    <div className="text-center">
                        <CircularProgress size={20} style={{ color: "#004540" }} />
                    </div>
                }
                <div
                    className='Biomarkers-graph'
                >
                    <div className='bio-graph-bio-legend-main'>
                        {data.filter(v => v.biomarkerCount > 0).map((v: IBioStats, i: number) => (
                            <div
                                className='bio-graph-bio-legend'
                                key={`legend-${i}`}>
                                <div
                                    className='bio-graph-bio-color'
                                    style={{
                                        backgroundColor: colors[i],
                                        borderRadius: '50%',
                                    }}
                                ></div>
                                <span className='font-size-16px capitalize'>{v?.name || ""}</span>
                            </div>
                        ))}
                    </div>
                    <div className='relative h-[100%] w-[100%]'>
                        <div className='relative pie-chart-container w-[100%]'>
                            <ResponsiveContainer>
                                <PieChart >
                                    <Pie
                                        data={data.filter(v => v.biomarkerCount > 0)}
                                        dataKey="biomarkerCount"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={120}
                                        innerRadius={70}
                                        startAngle={200}
                                        endAngle={1000}
                                        paddingAngle={0.5}
                                        label={renderCustomLabel}
                                        labelLine={false}
                                    >
                                        {data.filter(v => v.biomarkerCount > 0).map((_: any, i: number) => (
                                            <Cell key={`cell-${i}`} fill={colors[i]} />
                                        ))}
                                    </Pie>

                                    <Tooltip position={{ x: -30, y: -10 }} />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>


                        <div
                            className='bio-graph-bio-count'
                        >
                            <span>{totalCount}</span>
                            <p>Biomarkers</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BiomarkerPieChart;