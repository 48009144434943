/**
 * ProgressIndicator Component
 * 
 * Description: A React component that displays either a circular or linear progress indicator.
 * 
 * File Name: ProgressIndicator.tsx
 * Date: 20-08-2024
 */

import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material';
import { useMediaQuery } from '@mui/material';

interface IProcessIndicatorProps {
    type: "circular" | "linear"; // Type of progress indicator to render
    value: number; // Progress value (0-100)
    size?: number; // Size of the circular progress indicator
    color?: string; // Color of the progress indicator
};

const ProgressIndicator: React.FC<IProcessIndicatorProps> = ({
    size = 150, // Default size for the circular progress indicator
    type = "circular", // Default type is circular
    color = "orange", // Default color
    value = 0 // Default progress value
}) => {

    // Media query to adjust the size of the progress bar on different screen sizes
    const is1440MQ = useMediaQuery('(max-width: 1440px)')

    // Adjust the size based on the media query result
    const updatedSize = is1440MQ ? size - 10 : size;

    return (
        <>
            {
                type === "circular" && (
                    <div className='circular-progress-bar'>
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'inline-flex',
                                backgroundColor: "#D4D4D4",
                                borderRadius: "100rem",
                                boxShadow: "5px 2px 5px rgba(0, 0, 0, 0.16)",
                                zIndex: "1"
                            }}
                        >
                            <CircularProgress
                                variant="determinate"
                                value={value}
                                size={updatedSize} // Adjust size as needed
                                thickness={3.2} // Adjust thickness as needed
                                // color="primary"
                                sx={{
                                    color: color,
                                }}
                            />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: "#ffffff",
                                    borderRadius: "100rem",
                                    zIndex: "2",
                                    scale: ".85",
                                    boxShadow: "0 0 2px rgba(0, 0, 0, .2)",
                                }}
                            >
                                <Typography variant="caption" component="div" color="textSecondary" sx={{
                                    fontSize: `${updatedSize / 3}px`,
                                    color: "#004540"
                                }}>
                                    {value}
                                </Typography>
                            </Box>
                        </Box>
                    </div>
                )
            }
            {
                type === "linear" && (
                    <div className='linear-progress-bar'>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress
                                variant="determinate"
                                value={value}
                                sx={{
                                    height: 5,
                                    borderRadius: 5,
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: color,
                                    },
                                    backgroundColor: '#d3d3d3',
                                }}
                            />
                        </Box>
                    </div>
                )
            }
        </>
    );
};

export default ProgressIndicator;