import React, { ReactNode } from 'react';
import { CircularProgress } from '@mui/material';
import PaginationComponet from '../GlobalComponents/PaginationComponet';

interface ICustomerTableWrapperProps {
    isLoading?: boolean;
    page?: number;
    total: number;
    thead: Array<string>;
    onChange?: (page: number) => void; // Define the type of the onChange function
    children?: ReactNode; // Use ReactNode for children prop
    columnWidth?: string; // Define the type for columnWidth
    tailwindClass?: string;
};

const CustomerTableWrapper: React.FC<ICustomerTableWrapperProps> = ({
    isLoading = false,
    page = 1,
    total = 0,
    thead = [],
    onChange = () => { },
    children,
    columnWidth = "auto", // Default to "auto" if not provided
    tailwindClass = '',
}) => {
    return (
        <>
            <ul className={`thead-light-table customer-table-wrapper table-customer-order-padding customer-table-wrapper-body-padding-non ${tailwindClass}`} style={{ gridTemplateColumns: columnWidth }}>

                {thead.map((v, i) => {
                    return (
                        <li className="font-size-16px font-Poppins-SemiBold" key={i}>
                            {v}
                        </li>
                    );
                })}
            </ul>
            {isLoading ?
                <ul className="font-size-16px tbody-light-table font-Poppins-Regular grid">
                    <li>
                        <CircularProgress style={{ width: 24, height: 24, color: "#00443f" }} />
                    </li>
                </ul>
                : <></>}
            {total ? children :
                <ul className="font-size-16px tbody-light-table font-Poppins-Regular grid">
                    <li>No Results</li>
                </ul>}
            {/* pagination */}
            {total > 1 ?
                <PaginationComponet
                    currentPage={page}
                    total={total}
                    setCurrentPage={onChange}
                />
                :
                null
            }
        </>
    );
};

export default CustomerTableWrapper;