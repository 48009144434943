/**
 * ChatLayouts Component
 * 
 * Description: Layout component for chat pages, displaying a left sidebar, right sidebar (conditionally), 
 * and content area based on the current URL path.
 * 
 * File Name: ChatLayouts.tsx
 * Date: 21-09-2024
 */

import React, { ReactNode } from 'react';
import ChatLeftBar from 'src/Components/ChatComponents/ChatLeftBar';
import CustomerHeader from 'src/Components/Customer/CustomerHeader';
import ChatRightBar from 'src/Components/ChatComponents/ChatRightBar';
import { useLocation } from 'react-router-dom';

interface IChatLayoutProps {
    children: ReactNode;
};

const ChatLayouts: React.FC<IChatLayoutProps> = ({ children }) => {
    const { pathname } = useLocation();
    // Check if the current path matches "/message/:id/view"
    const isMessageWithIdPath = /^\/message\/[a-zA-Z0-9]+\/view$/.test(pathname);

    return (
        <div className="h-screen flex flex-col">
            <CustomerHeader />
            <div className={`${isMessageWithIdPath ? "chat-layout-main" : "chat-layout-main-rightnone"}`}>
                <ChatLeftBar />
                {children}
                {/* Conditionally display the right sidebar if the path matches "/message/:id/view" */}
                {isMessageWithIdPath &&
                    <ChatRightBar />
                }
            </div>
        </div>
    );
};

export default ChatLayouts;