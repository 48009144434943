/**
 * MyOrders Component
 * 
 * Description: This component displays a list of orders with functionality for searching, filtering, and pagination. It interacts with an API to fetch orders based on query parameters and displays them in a table format. Each order item can be viewed in detail through a link.
 * 
 * File Name: MyOrders.tsx
 * Date: 15-08-24
 */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomerTableWrapper from 'src/Components/Customer/CustomerTableWrapper';
import PageHeader from 'src/Components/Customer/PageHeader';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import httpRequest from 'src/helpers/httpRequest';
import { IOrderItems, IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import { displayStatus, getLastSegment } from 'src/helpers/helperFunctions';
import moment from 'moment';
import eyeIcon from '../../assets/images/eye-icon-green.png';

interface iMyOrdersResponse {
    documents: IOrderItems[];
    paginated: IPaginated;
};

const MyOrders: React.FC = () => {
    const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state for API calls
    const [allOrders, setAllOrders] = useState<IOrderItems[]>([]); // List of orders
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination state
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        status: "",
        search: "",
    }); // Query parameters for API request

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Handles filter change for search input
    const handleFilterChange = (val: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            search: val,
        }));
    };

    // API_CALL: Fetches orders from the server based on query parameters
    const fetchMyOrders = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<iMyOrdersResponse>({ path: `/order/all/customer?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setAllOrders(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Fetch orders when queryParams changes
    useEffect(() => {
        fetchMyOrders();
    }, [queryParams]);

    return (
        <>
            <PageHeader
                title="Order List"
            />
            <div className="parent-box parent-box-height">
                <SearchFilterInput
                    containerExtraStyle={{ justifyContent: "flex-start" }}
                    onChangeText={handleFilterChange}
                />
                <CustomerTableWrapper
                    isLoading={isLoader}
                    thead={["Order ID", "Product Name", "Total Amount", "Date", "Status", "Actions"]}
                    page={paginated?.currentPage || 1}
                    total={paginated?.totalPages || 0}
                    columnWidth={"14% 25% 16% 17% 14% 14%"}
                    onChange={handlePageChange}
                >
                    {allOrders?.map((item: IOrderItems, index: number) => (
                        <ul key={index} className='font-size-16px tbody-light-table tbody-light-table-customer customer-table-wrapper-body-padding-non customer-table-wrapper-body font-Poppins-Regular grid' style={{ gridTemplateColumns: "14% 25% 16% 17% 14% 14%" }}>
                            <li className="action-last-colum-non">{getLastSegment(item?.serialOrderId)}</li>
                            <li > {/*notLastComa  ==> className*/}
                                <p className='truncate w-[80%] pr-3'>

                                {item?.orderDetails?.map((v: { productName: string;[key: string]: any }, index: number) => {
                                    return (
                                        <span key={v.productName}>
                                            {v.productName}{item?.orderDetails?.length > index + 1 ? ", " : ""}
                                        </span>
                                    )
                                })}
                                
                                </p>
                            </li>
                            <li>AED {item?.totalAmount || 0}</li>
                            <li>{moment(item?.createdAt).format("DD-MM-YYYY")}</li>
                            <li>{displayStatus(item?.status) || ""}</li>
                            <li className='action-last-colum'>
                                <Link to={`/order/${item?._id}/View`}>
                                    {/* <FontAwesomeIcon icon={faEye} /> */}
                                    <img src={eyeIcon} alt="" />
                                </Link>
                            </li>
                        </ul>
                    ))}
                </CustomerTableWrapper>
            </div>
        </>
    );
};

export default MyOrders;