import React from 'react';
import { ISubCategory } from 'src/types/GlobalInterfaces';

interface IProps {
    onSuccess: () => void;
    isEdit: ISubCategory | false;
};

const UpsertSubCategory: React.FC<IProps> = ({
    onSuccess = () => { },
    isEdit = false,
}) => {
    return (
        <div>UpsertSubCategory</div>
    );
};

export default UpsertSubCategory;