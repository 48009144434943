import React, { useState } from 'react';
import tickIcon from '../../assets/images/tick-icon-li.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { IBiomarkerItems, IFaqs, IProductItems } from 'src/types/GlobalInterfaces';

// Define the props interface
interface ProductDescriptionProps {
    item: IProductItems | null;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({ item = null }) => {
    const [activeQuestion, setActiveQuestion] = useState<number | null>(null); // State to manage the active FAQ question

    // Toggle the active question for FAQs
    const handleTabClick = (index: number) => {
        setActiveQuestion(index === activeQuestion ? null : index);
    };

    return (
        <>
            <div className='product-description'>
                <div className='w-full'>
                    <h2 className='theme-color-green font-size-35px font-Poppins-Medium'>Test Instructions</h2>
                    {item?.testInstuction?.map((v) => (
                        <div key={v?._id} className='test-instruction'>
                            <div className='test-intruction-data'>
                                <img src={tickIcon} alt="tick icon" className="mt-1" />
                                <span className='text-[#313131] font-size-18px font-font-Poppins-Regular'>{v?.instructionDetail || ""}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='w-full'>
                    <h2 className='theme-color-green font-size-35px font-Poppins-Medium'>Biomarker Included</h2>
                    <div className='biomarker-include-data'>
                        {item?.biomarkerData?.map((v: IBiomarkerItems, i: number) => (
                            <Tooltip
                                key={i}
                                placement="top-start"
                                className='font-size-18px font-Poppins-Regular'
                                title={v?.shortDescription || ""}
                                arrow={true}
                            >
                                <span className='text-white font-size-18px font-Poppins-Regular'>{v?.name || ""}</span>
                            </Tooltip>
                        ))}
                    </div>
                </div>
            </div>
            <div className='faqs'>
                <h2 className='text-center text-white uppercase font-Poppins-Bold font-size-40px'>FAQs</h2>
                <hr />
                {item?.qna?.map((v: IFaqs, i: number) => (
                    <div key={i} className='faqs-tabs-main'>
                        <div
                            className='faqs-tabs-head'
                        >
                            <p className='text-white font-Poppins-SemiBold font-size-18px'>{v?.question || ""}</p>
                            <FontAwesomeIcon
                                onClick={() => handleTabClick(i)}
                                className='text-white font-Poppins-SemiBold font-size-18px'
                                icon={activeQuestion === i ? faMinus : faPlus}
                            />
                        </div>
                        {activeQuestion === i && (
                            <div className='faqs-tabs-body'>
                                <p className='text-white font-Poppins-Regular font-size-16px'>{v?.answer || ""}</p>
                            </div>
                        )}
                        {(item?.qna?.length !== i + 1) &&
                            <hr />
                        }
                    </div>
                ))}
            </div>
        </>
    );
};

export default ProductDescription;
