/**
 * SingleBiomarkerResultHistory Component
 * 
 * Description: This component is responsible for displaying detailed information and historical data for a specific biomarker result. It fetches the biomarker result history based on the provided user ID (uId) and biomarker ID (bioId) and displays relevant data, including a description, FAQs, a line graph, and result history. The component also handles dynamic rendering of reference and optimal values based on age and gender.
 * 
 * File Name: SingleBiomarkerResultHistory.tsx
 * Date: 26-08-2024
 */

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import SimpleLineGraph from '../Graphs/SimpleLineGraph';
import { descriptiveResult, twoBarDNDAsc, twoBarDNDDesc, twoBarPNAsc, twoBarPNDesc } from 'src/lib/constans';
import { ageCategory, returnColor } from 'src/helpers/helperFunctions';
import HistoryGraph from '../Graphs/HistoryGraph';
import SplashScreen from 'src/Components/SplashScreen';

interface IBIO {
    biomarkerDetails: {
        shortDescription: string;
        name: string;
        barCategoryId: string;
        [key: string]: any;
    }
};

interface IResult {
    value: string;
    date: string;
    isShown: boolean;
    measurement: string;
    [key: string]: any;
};

interface ISingleHistory {
    biomarkerData: { biomarkerDetails: IBIO[]; resultHistory: IResult[] }[];
    customerData: {
        date: string;
        gender: string;
    };
};

const SingleBiomarkerResultHistory: React.FC<{ bioId: string; uId: string }> = ({ bioId, uId }) => {
    const faqs: { questions: string; answerKey: string }[] = [
        {
            questions: "Why do deviations occur and what does this mean?",
            answerKey: "derivationOccur",
        },
        {
            questions: "How to test 5 Bar marker?",
            answerKey: "testosterone",
        },
        {
            questions: "What specialist is required?",
            answerKey: "specialist",
        },
        {
            questions: "General recommendations",
            answerKey: "recomandation",
        },
        {
            questions: "Methods to increase",
            answerKey: "increase",
        },
        {
            questions: "Methods to increase",
            answerKey: "decrease",
        },
        {
            questions: "References",
            answerKey: "references",
        },
    ]; // FAQ section related to the biomarker results

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<number[]>([]);
    const [data, setData] = useState<ISingleHistory | null>(null);
    const [barCategoryId, setBarCategoryId] = useState<string | null>(null);
    const [latestResult, setLatestResult] = useState<number>(0);
    const [refValues, setRefValues] = useState<number[]>([0, 0]);
    const [opValues, setOpValues] = useState<number[]>([0, 0]);

    //API_CALL: Fetches the biomarker history data from the API
    const fetchSingleBioHistory = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ data: ISingleHistory; message: string }>({ path: `/healthprofile/biomarker/${uId}/${bioId}/details` });
        if (res) {
            setData(res?.data || null);
            setBarCategoryId(res?.data?.biomarkerData[0]?.biomarkerDetails[0]?.biomarkerDetails?.barCategoryId);
            setLatestResult(Number(res?.data.biomarkerData[0].resultHistory[0].value) || 0);

            const _gender = res?.data?.customerData?.gender;
            const _dob = res?.data?.customerData?.date;

            const age = moment().diff(moment(_dob), 'years');
            const ageGroup = ageCategory(age) || "";

            // Get the reference and optional values based on gender and age group
            const _ref = res?.data?.biomarkerData[0]?.biomarkerDetails[0]?.biomarkerDetails?.[`${_gender}Thresholds`]['ref'][ageGroup];
            const _op = res?.data?.biomarkerData[0]?.biomarkerDetails[0]?.biomarkerDetails?.[`${_gender}Thresholds`]['op'][ageGroup];
            setRefValues(_ref);
            setOpValues(_op);

        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    const checkBarCategory = (): string => {
        return data?.biomarkerData[0]?.biomarkerDetails[0]?.biomarkerDetails?.barCategoryId || ""
    };

    // Handles expanding or collapsing the FAQ sections
    const addOrRemoveOpenList = (key: number) => {
        if (isOpen.includes(key)) {
            setIsOpen(prev => prev.filter(v => v !== key));
        } else {
            setIsOpen(prev => [...prev, key])
        }
    };

    // Fetch data when bioId or uId changes
    useEffect(() => {
        if (bioId && uId) {
            fetchSingleBioHistory();
        }
    }, [bioId, uId]);

    return (
        <>
            {isLoader && <SplashScreen />}
            {
                data &&
                <div className="parent-box">
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                        <div>
                            <h2 className='font-Poppins-SemiBold text-[#4F6367] font-size-20px'>{data.biomarkerData[0].biomarkerDetails[0].biomarkerDetails.name}</h2>
                            <p className='font-Poppins-Regular text-[#797F82] font-size-16px mt-2'>{data.biomarkerData[0].biomarkerDetails[0].biomarkerDetails.shortDescription}</p>
                            {
                                faqs.map((v, i) => (
                                    <div key={i} className='biomarker-detail-accordion-main'>
                                        <div role='button' onClick={() => addOrRemoveOpenList(i)} className='biomarker-detail-accordion'>
                                            <span className='font-Poppins-Regular text-white font-size-16px'>{v.questions}</span>
                                            <FontAwesomeIcon
                                                style={{
                                                    transition: 'transform 0.3s ease, opacity 0.3s ease',
                                                    transform: isOpen.includes(i) ? 'rotate(180deg)' : 'rotate(0deg)',
                                                    opacity: isOpen.includes(i) ? 0.8 : 1,
                                                }}
                                                className='text-white font-size-16px' icon={isOpen.includes(i) ? faMinus : faPlus} />
                                        </div>
                                        <div
                                            className='biomarker-detail-accordion-content'
                                            style={{
                                                maxHeight: isOpen.includes(i) ? '500px' : '0',
                                                opacity: isOpen.includes(i) ? '1' : '0',
                                                transition: 'max-height 0.3s ease, opacity 0.3s ease',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            <p style={{ padding: isOpen.includes(i) ? '0.5rem' : '0' }} className='text-[#4F6367] font-Poppins-Regular font-size-16px'>{data.biomarkerData[0].biomarkerDetails[0].biomarkerDetails[v.answerKey] || "-"}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div>
                            {
                                (barCategoryId && barCategoryId !== descriptiveResult)
                                &&
                                <>
                                    <div className="parent-box">
                                        <div>
                                            <span style={{ color: returnColor(data?.biomarkerData[0]?.resultHistory[0]?.testData?.colour) }} className="flex justify-center font-Poppins-Medium font-size-20px">
                                                {
                                                    (checkBarCategory() === twoBarDNDAsc || checkBarCategory() === twoBarDNDDesc)
                                                        ?
                                                        Number(latestResult) === 1 ? "Detected" : "Not Detected"
                                                        : (checkBarCategory() === twoBarPNAsc || checkBarCategory() === twoBarPNDesc)
                                                            ?
                                                            Number(latestResult) === 1 ? "Positive" : "Negative"
                                                            :
                                                            latestResult
                                                }
                                                <span>{`${data?.biomarkerData[0]?.resultHistory[0]?.isShown ? data?.biomarkerData[0]?.resultHistory[0]?.measurement : ""}`}</span>
                                            </span>
                                        </div>
                                        <SimpleLineGraph
                                            result={latestResult}
                                            category={barCategoryId}
                                            size={"sm"}
                                            refMin={refValues[0]}
                                            refMax={refValues[1]}
                                            optMin={opValues[0]}
                                            optMax={opValues[1]}
                                        />
                                    </div>
                                    <div className="parent-box">
                                        <HistoryGraph
                                            reference={[Number(refValues[0]), Number(refValues[1])]}
                                            optimal={[Number(opValues[0]), Number(opValues[1])]}
                                            category={barCategoryId}
                                            data={
                                                data.biomarkerData[0].resultHistory.map(v => ({
                                                    date: moment(v.date).format("DD-MM-YYYY"),
                                                    value: Number(v.value)
                                                })).reverse()
                                            }
                                        />
                                    </div>
                                </>
                            }
                            <div className="parent-box">
                                <h4 className='text-[#004D48] text-center font-Poppins-Medium font-size-18px'>Result History</h4>
                                {
                                    data.biomarkerData[0].resultHistory.map((v, i) => (
                                        <div key={i} className='flex justify-between result-history-data'>
                                            <span className='font-Poppins-Medium text-[#444444] text-gray font-size-14px'>{moment(v.date).format("DD-MM-YYYY")}</span>
                                            <span className='font-Poppins-Medium text-[#444444] text-gray font-size-14px'>
                                                {
                                                    (checkBarCategory() === twoBarDNDAsc || checkBarCategory() === twoBarDNDDesc)
                                                        ?
                                                        Number(v?.value) === 1 ? "Detected" : "Not Detected"
                                                        : (checkBarCategory() === twoBarPNAsc || checkBarCategory() === twoBarPNDesc)
                                                            ?
                                                            Number(v?.value) === 1 ? "Positive" : "Negative"
                                                            :
                                                            v?.value
                                                }
                                                <span>{v.isShown ? v.measurement : ""}</span>
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default SingleBiomarkerResultHistory;