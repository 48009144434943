/**
 * File Name: Products.tsx
 * Purpose: This component manages the display, addition, editing, and deletion of products.
 * It includes functionalities for listing products with pagination, searching, and filtering.
 * Date: 31-07-24
 */

import React, { useEffect, useState } from 'react';
import editIcon from '../../assets/images/edit-icon.png';
import deleteIcon from '../../assets/images/delete-icon.png';
import BreadCrumb from '../../Components/GlobalComponents/BreadCrumb';
import TableWrapper from '../../Components/GlobalComponents/TableWrapper';
import emtyImg from '../../assets/images/emty-img.png';
import UpsertProduct from '../../Components/AdminComponents/UpsertProduct';
import { IPaginated } from 'src/types/paginated';
import { IProductItems, IqueryParams } from 'src/types/GlobalInterfaces';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import QueryString from "qs";
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import moment from 'moment';
import ConfirmationModal from 'src/Components/GlobalComponents/ConfirmationModal';

interface IProductResponse {
  documents: IProductItems[];
  paginated: IPaginated;
}

const Products: React.FC = () => {
  const [isUpsert, setIsUpsert] = useState<boolean>(false); // State to manage the upsert mode (create/edit product)
  const [isEdit, setIsEdit] = useState<string | false>(false); // State to determine if the component is in edit mode

  const [deleteLoader, setDeleteLoader] = useState<boolean>(false); // State to manage the loader during product deletion
  const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage the loader for fetching products
  const [allProducts, setallProducts] = useState<IProductItems[]>([]); // State to store the list of products
  const [paginated, setPaginated] = useState<IPaginated>({
    currentPage: 1,
    totalPages: 1,
  }); // state for handle pagination like a current page, page limit & total pages

  const [deleteModal, setDeleteModal] = useState<boolean>(false); // State to control the visibility of the delete confirmation modal
  const [deleteId, setDeleteId] = useState<string>("");  // State to store the ID of the product to be deleted

  const [queryParams, setQueryParams] = useState<IqueryParams>({
    page: 1,
    limit: 10,
    search: "",
    counter: 1
  });

  // Function change search text for api query params
  const handleChangeText = (txt: string) => {
    setQueryParams(prev => ({
      ...prev,
      page: 1,
      search: txt
    }));
  };

  // Function to update the list of categories after creating or editing a category
  const handleUpdateList = () => {
    setQueryParams(prev => ({
      ...prev,
      counter: (prev.counter ?? 0) + 1,
    }));
    setIsUpsert(false);
  };

  // Function to handle page change in pagination
  const handlePageChange = (e: number) => {
    setQueryParams(prev => ({
      ...prev,
      page: e
    }));
  };

  // Function to set the component to edit mode and populate it with product data
  const handleEdit = (id: string) => {
    setIsUpsert(true);
    setIsEdit(id);
  };

  // Function to show the delete confirmation modal and set the ID of the product to be deleted
  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setDeleteId(id);
  };

  // Function to close the delete confirmation modal
  const deleteModalClose = () => {
    setDeleteModal(false);
    setDeleteId("");
  };

  // API_CALL: Function to fetch all products based on query parameters
  const fetchAllProducts = async () => {
    setIsLoader(true);
    let { counter, ...remainingParams } = queryParams;
    const { res, err } = await httpRequest<IProductResponse>({ path: `/product/all?${QueryString.stringify(remainingParams)}` });
    if (res) {
      setallProducts(res?.documents);
      setPaginated(res?.paginated);
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };

  // API_CALL: Function to delete a product by its ID
  const deleteProducts = async () => {
    setDeleteLoader(true);
    const { res, err } = await httpRequest<any>({ method: "delete", path: `/product/delete/${deleteId}`, params: {} });
    if (res) {
      toast("Product deleted successfully.", { type: 'success' });
      setQueryParams(prev => ({
        ...prev,
        counter: (prev.counter ?? 0) + 1,
        page: allProducts?.length > 1 ? prev?.page : prev?.page > 1 ? prev?.page - 1 : prev?.page
      }));
      setDeleteModal(false);
      setDeleteId("");
    } else {
      toast(err, { type: 'error' });
    }
    setDeleteLoader(false);
  };

  useEffect(() => {
    fetchAllProducts();
  }, [queryParams]); // Re-fetch products when queryParams change

  return (
    <>
      <ConfirmationModal
        show={deleteModal}
        onClose={deleteModalClose}
        title="Are you sure you want to delete?"
        leftBtnText="No"
        rightBtnText="Yes"
        onConfirm={deleteProducts}
        loader={deleteLoader}
      />
      <BreadCrumb
        current="Products"
        previous="dashboard"
        navigateUrl="/admin"
        btnText={"Add Product"}
        btnShow={true}
        onPress={() => setIsUpsert(prev => !prev)}
        isUpsert={isUpsert}
      />
      {
        isUpsert
          ?
          <div className="table-container">
            <UpsertProduct
              onSuccess={handleUpdateList}
              isEdit={isEdit}
            />
          </div>
          :
          <div className="table-container">
            <SearchFilterInput
              onChangeText={handleChangeText}
            />
            <TableWrapper
              isLoading={isLoader}
              thead={["S.No", "Product Name", "Category", "SKU", "Regular Price", "Sale Price", "Date", "Action"]}
              page={paginated?.currentPage || 1}
              total={paginated?.totalPages || 0}
              columnWidth="6% 24% 20% 10% 10% 10% 10% 10%"
              onChange={handlePageChange}
            >
              <div className='tbody-content-height'>
              {allProducts.map((item, index: number) => (
                <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 24% 20% 10% 10% 10% 10% 10%" }}>
                  <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                  <li>{item?.productName || ""}</li>
                  <li className='coma-seprated-span'>
                    {item?.categoriesData?.map((v: any, i: number) => (
                      <span key={i}>{v?.name}</span>
                    ))}
                  </li>
                  <li>{item?.sku || ""}</li>
                  <li>{`AED ${item?.regularPrice || 0}`}</li>
                  <li>{`AED ${item?.salePrice || 0}`}</li>
                  <li>{moment(item?.date).format("DD-MM-YYYY")}</li>
                  <li className='action-last-colum'>
                    <img onClick={() => handleEdit(item?._id)} src={editIcon} alt="" />
                    <img onClick={() => handleDelete(item?._id)} src={deleteIcon} alt="" />
                  </li>
                </ul>
              ))}
              </div>
            </TableWrapper>
          </div>
      }
    </>
  );
};

export default Products;