/**
 * CommentSection
 * 
 * Description: A React component that displays a section for comments, allowing users to add, like, reply to, and delete comments.
 * It handles fetching comments, pagination, and updating the UI based on user interactions.
 * 
 * File Name: CommentSection.tsx
 * Date: 25-09-2024
 */

import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentInput from '../CommentInput'
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { IPaginated } from 'src/types/paginated';
import { ICommentItem, IqueryParams } from 'src/types/GlobalInterfaces';
import qs from "qs";
import defaultProfile from "src/assets/images/default_profile_image.png";
import { faThumbsUp, faEllipsis, faLink, faFlag, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@mui/material';
import { convertToHyperlinks, convertToTimeAgo } from 'src/helpers/helperFunctions';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';

interface ICommentResponse {
    documents: ICommentItem[];
    paginated: IPaginated;
};

const CommentSection: React.FC<{ id: string; updateCommentCount?: () => void }> = ({ id, updateCommentCount = () => { } }) => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const [likeAnimation, setLikeAnimation] = useState("");

    // API_CALL: Toggle reactions status (like or not)
    const handleToogleReactionStatus = async (type: "main" | "inner", commentId: string, prevStatus: boolean) => {
        setLikeAnimation(commentId);
        setTimeout(() => setLikeAnimation(""), 300);
        const prevArr = type === "main" ? allComments : innerComments;
        const updArr = prevArr.map(v => (
            v._id === commentId ?
                { ...v, reactionStatus: !prevStatus, reactions: prevStatus ? v.reactions - 1 : v.reactions + 1 }
                :
                v
        ));
        if (type === "main") {
            setAllComments(updArr);
        } else {
            setInnerComments(updArr);
        }; // Update local state to reflect the new reaction status.
        const { res, err } = await httpRequest<{ message: string; status: boolean }>({
            method: "post",
            path: `/comment/like`,
            params: { discussionId: id, commentId }
        });
        if (res) {
            // No action needed if the API call was successful.
        } else {
            toast(err, { type: 'error' }); // Show an error message.
            const oldArr = prevArr.map(v => (
                v._id === commentId ?
                    { ...v, reactionStatus: !prevStatus, reactions: prevStatus ? v.reactions - 1 : v.reactions + 1 }
                    :
                    v
            ));
            if (type === "main") {
                setAllComments(oldArr);
            } else {
                setInnerComments(oldArr);
            }; // Revert to previous state if API call fails.
        }
    };
    const addAComment = (type: "main" | "inner", obj: ICommentItem, commentId: string | null) => {
        updateCommentCount(); // for main count increament
        if (type === "main") {
            setAllComments(prev => [obj, ...prev]);
        } else {
            setInnerComments(prev => [...prev, obj]);
            setAllComments(prev => (
                prev.map(v =>
                    v._id === commentId ?
                        { ...v, reply: v.reply + 1 }
                        :
                        v
                )
            ));
        }
    };

    const [showReplies, setShowReplies] = useState<string | null>(null);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [innerLoader, setInnerLoader] = useState<boolean>(false);
    const [innerComments, setInnerComments] = useState<ICommentItem[]>([]);
    const [allComments, setAllComments] = useState<ICommentItem[]>([]);
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 5,
    });
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // ****************** COMMENT DELETE STATES & FUNCTIONS ****************** //
    const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
    // API_CALL: Delete a comment from the discussion thread
    const handleDeleteComment = async (type: "main" | "inner", id: string) => {
        setDeleteLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "delete",
            path: `/comment/${id}`,
            params: {}
        });
        if (res) {
            toast(res?.message || "Success", { type: 'success' }); // Show an success message.
            const prevArr = type === "main" ? allComments : innerComments;
            const updArr = prevArr.filter(v => v._id !== id);
            if (type === "main") {
                setAllComments(updArr);
            } else {
                setInnerComments(updArr);
            }
        } else {
            toast(err, { type: 'error' }); // Show an error message.
        }
        setDeleteLoader(false);
    };
    // ****************** COMMENT DELETE STATES & FUNCTIONS ****************** //

    // API_CALL: Fetch inner comments from a specific comment
    const fetchInnerComments = async (totleReply: number, commentId: string) => {
        setInnerComments([]); // initially empty if previoud inner comments exist
        setShowReplies(commentId);
        if (totleReply) {
            setInnerLoader(true);
            const { res, err } = await httpRequest<ICommentResponse>({
                path: `/comment/all/get/${id}?commentId=${commentId}&page=${1}&limit=${100}`,
            });
            if (res) {
                let { documents = [], paginated } = res;
                setInnerComments(documents);
            } else {
                toast(err, { type: 'error' });
            }
            setInnerLoader(false);
        }
    };

    // API_CALL: Fetch the main comment from the server
    const fetchAllComments = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ICommentResponse>({
            path: `/comment/all/get/${id}?${qs.stringify(queryParams)}`,
        });
        if (res) {
            let { documents = [], paginated } = res;
            setAllComments(prev => [...prev, ...documents]);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };
    useEffect(() => {
        fetchAllComments();
    }, [id, queryParams]);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<string | null>(null);
    const handleToggleDropdown = (id: string): void => {
        setIsOpen(prev => (prev === id ? null : id)); // Toggle dropdown
    };

    const handleClickOutside = (e: MouseEvent) => {
        const target = e.target as Element; // Assert the type of target

        if (isOpen && !target.closest('.dropdown-class')) {
            setIsOpen(null); // Close the dropdown
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <>
            <CommentInput
                discussionId={id}
                onCommentSend={(obj, commentId) => addAComment("main", obj, commentId)}
            />
            <p className='font-size-16px my-4 font-Poppins-SemiBold'>Replies</p>
            {allComments.length > 0
                ?
                allComments.map((item: ICommentItem, index: number) => (
                    <div key={index}>
                        <div className='comment-section-replies mb-0'>
                            <img
                                className='comment-user-img'
                                alt="user"
                                src={`${process.env.REACT_APP_API_BASEURL}${item?.coverPhoto}`}
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement; // Assert the type
                                    if (target) {
                                        target.src = defaultProfile;
                                    }
                                }}
                            />
                            <div>
                                <div className='mark-allen-comment'>
                                    <div className='flex justify-between items-center'><span className='font-size-13px text-[#464646] block font-Poppins-SemiBold'>{`${item?.firstName || ""} ${item?.lastName || ""}`}</span>
                                        <div className='flex gap-2 items-center line-height-issue-resolve'>
                                            <span className='font-size-13px text-[#464646] font-Poppins-Regular'>{convertToTimeAgo(item.createdAt)}</span>
                                            <div className='relative' ref={dropdownRef}>
                                                {item.userId === userDetails?._id &&
                                                    <FontAwesomeIcon
                                                        className='cursor-pointer font-size-16px'
                                                        icon={faEllipsis}
                                                        onClick={() => handleToggleDropdown(item._id)}
                                                    />
                                                }
                                                {isOpen === item._id && (
                                                    <div className='comment-drop-down-box'>
                                                        <button disabled={deleteLoader} onClick={() => handleDeleteComment("main", item._id)} className='flex gap-2 items-center comment-drop-down-box-inner w-full dropdown-class flex items-center gap-2'>
                                                            <FontAwesomeIcon className='font-size-16px text-[#464646]' icon={faTrash} />
                                                            <span className='font-size-13px text-[#464646] font-Poppins-SemiBold'>Delete comment</span>
                                                            <span>{deleteLoader && <CircularProgress size={12} style={{ color: "#004540" }} />}</span>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <span className='font-size-13px mb-1 text-[#707070] block font-Poppins-Regular leading-3 capitalize'>{item?.role || "-"}</span>
                                    <p className='font-size-13px text-[#464646] block font-Poppins-Regular'>
                                        <span
                                            dangerouslySetInnerHTML={{ __html: convertToHyperlinks(item?.caption || "") }}
                                        />
                                    </p>
                                </div>
                                <div className='flex gap-2 mb-4 items-center'>
                                    <button onClick={() => handleToogleReactionStatus("main", item._id, item.reactionStatus)} className="flex items-center gap-2">
                                        <span
                                            className={"font-size-13px text-[#464646] font-Poppins-SemiBold"}
                                        >
                                            Like
                                        </span>
                                        <span className="w-1 h-1 rounded-full bg-[#707070]"></span>
                                        <span className='flex gap-1 items-center'>
                                            <FontAwesomeIcon
                                                icon={faThumbsUp}
                                                color={item?.reactionStatus ? "dodgerblue" : "grey"}
                                                className={`text-[12px] ${likeAnimation === item._id ? 'like-animation' : ''}`}
                                            />
                                            <span className='font-size-13px text-[#464646] block font-Poppins-Medium'>{item?.reactions || 0}</span>
                                        </span>
                                    </button>
                                    |
                                    <button onClick={() => fetchInnerComments(item.reply, item._id)} className='font-size-13px text-[#464646] block font-Poppins-Medium'>{item?.reply || 0} {`Repl${item?.reply || 0 > 1 ? "ies" : "y"}`}</button>
                                </div>
                            </div>
                        </div>
                        {showReplies === item._id &&
                            <>
                                {innerLoader &&
                                    <div className="text-center">
                                        <CircularProgress size={20} style={{ color: "#004540" }} />
                                    </div>
                                }
                                <div className="mb-3 ml-12">
                                    {innerComments.map((v: ICommentItem, index: number) => (
                                        <div key={index} className='comment-section-replies'>
                                            <img
                                                className='comment-user-img'
                                                alt="user"
                                                src={`${process.env.REACT_APP_API_BASEURL}${v?.coverPhoto}`}
                                                onError={(e) => {
                                                    const target = e.target as HTMLImageElement; // Assert the type
                                                    if (target) {
                                                        target.src = defaultProfile;
                                                    }
                                                }}
                                            />
                                            <div>
                                                <div className='mark-allen-comment'>
                                                    <div className='flex justify-between'><span className='font-size-13px text-[#464646] block font-Poppins-SemiBold'>{`${v?.firstName || ""} ${v?.lastName || ""}`}</span>
                                                        <div className='flex gap-2 items-center line-height-issue-resolve'>
                                                            <span className='font-size-13px text-[#464646] font-Poppins-Regular'>{convertToTimeAgo(v?.createdAt)}</span>
                                                            <div className='relative' ref={dropdownRef}>
                                                                {v.userId === userDetails?._id &&
                                                                    <FontAwesomeIcon
                                                                        className='cursor-pointer font-size-16px'
                                                                        icon={faEllipsis}
                                                                        onClick={() => handleToggleDropdown(v._id)}
                                                                    />
                                                                }
                                                                {isOpen === v._id && (
                                                                    <div className='comment-drop-down-box'>
                                                                        {v.userId === userDetails?._id &&
                                                                            <button disabled={deleteLoader} onClick={() => handleDeleteComment("inner", v._id)} className='flex gap-2 items-center comment-drop-down-box-inner w-full dropdown-class flex items-center gap-2'>
                                                                                <FontAwesomeIcon className='font-size-16px text-[#464646]' icon={faTrash} />
                                                                                <span className='font-size-13px text-[#464646] font-Poppins-SemiBold'>Delete comment</span>
                                                                                <span>{deleteLoader && <CircularProgress size={12} style={{ color: "#004540" }} />}</span>
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className='font-size-13px mb-2 text-[#707070] block font-Poppins-Regular leading-3 capitalize'>{v?.role || "-"}</span>
                                                    <p className='font-size-13px text-[#464646] block font-Poppins-Regular'>
                                                        <span
                                                            dangerouslySetInnerHTML={{ __html: convertToHyperlinks(v?.caption || "") }}
                                                        />
                                                    </p>
                                                </div>
                                                <div className="mt-2">
                                                    <button onClick={() => handleToogleReactionStatus("inner", v._id, v.reactionStatus)} className="flex items-center gap-2">
                                                        <span
                                                            className={"font-size-13px text-[#464646] font-Poppins-SemiBold"}
                                                        >
                                                            Like
                                                        </span>
                                                        <span className="w-1 h-1 rounded-full bg-[#707070]"></span>
                                                        <span className='flex gap-1 items-center'>
                                                            <FontAwesomeIcon
                                                                icon={faThumbsUp}
                                                                color={v?.reactionStatus ? "dodgerblue" : "grey"}
                                                                className={`text-[12px] ${likeAnimation === v._id ? 'like-animation' : ''}`}
                                                            />
                                                            <span className='font-size-13px text-[#464646] block font-Poppins-Medium'>{v?.reactions || 0}</span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <CommentInput
                                        discussionId={id}
                                        commentId={item._id}
                                        onCommentSend={(obj, commentId) => addAComment("inner", obj, commentId)}
                                    />
                                </div>
                            </>
                        }
                    </div>
                ))
                :
                <div className="text-center">
                    <p>No Comments</p>
                </div>
            }
            {isLoader &&
                <div className="text-center">
                    <CircularProgress size={20} style={{ color: "#004540" }} />
                </div>
            }
            {(paginated.totalPages > paginated.currentPage) &&
                <button onClick={() => handlePageChange(paginated.currentPage + 1)} className='font-size-13px cursor-pointer text-[#464646] block font-Poppins-SemiBold'>Load more comments</button>
            }
        </>
    );
};

export default CommentSection;