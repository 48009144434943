/**
 * SupportList Component
 * 
 * Description: This component fetches and displays a list of support tickets,
 * allowing users to change the status of each ticket between "pending" and "resolved".
 * It features pagination and integrates with a backend API for fetching ticket data.
 * 
 * File Name: SupportList.tsx
 * Date: 26-09-2024
 */

import React, { useEffect, useState } from 'react';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import httpRequest from 'src/helpers/httpRequest';
import { IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import { toast } from 'react-toastify';
import moment from 'moment';
import pendingcircle from "src/assets/images/pending-circle.png";
import resolvedcircle from "src/assets/images/resolved-circle.png";
import { truncateString } from 'src/helpers/helperFunctions';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';

interface ITicketItems {
    _id: string;
    email: string;
    description: string;
    createdAt: string;
    status: "resolved" | "pending";
};

interface ITicketResponse {
    documents: ITicketItems[];
    paginated: IPaginated;
};

const SupportList: React.FC = () => {
    // States for loading status, pagination, and customer data
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [data, setData] = useState<ITicketItems[]>([]);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination

    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        search: ""
    });
    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            search: txt,
            page: 1
        }));
    };

    // API_CALL: Change the status of a support ticket to resolved
    const handleChangeStatus = async (id: string) => {
        const { res, err } = await httpRequest<ITicketItems>({
            method: "post",
            path: `/supportandsetting/update-status/${id}`,
            params: {}
        });
        if (res) {
            toast("The ticket has been resolved successfully.", { type: 'success' });
            setData(prev =>
                prev.map(v =>
                    v._id === id ?
                        { ...v, status: "resolved" }
                        :
                        v
                )
            );
        } else {
            toast(err, { type: 'error' });
        }
    };

    // API_CALL: Fetch all support tickets from the server
    const fetchAllTickets = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ITicketResponse>({
            path: `/supportandsetting/all?${qs.stringify(queryParams)}`
        });
        if (res) {
            let { documents = [], paginated } = res;
            setData(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchAllTickets();
    }, [queryParams]);

    return (
        <>
            <BreadCrumb
                current="Support Hub"
                previous="dashboard"
                navigateUrl="/admin"
            />
            <div className="table-container">
                <SearchFilterInput
                    onChangeText={handleChangeText}
                />
                <TableWrapper
                    isLoading={isLoader}
                    page={paginated?.currentPage || 1}
                    total={paginated?.totalPages || 0}
                    thead={["S.No", "Email", "Details", "Date", "Status", "Action"]}
                    onChange={handlePageChange}
                    columnWidth={"6% 20% 29% 15% 15% 15%"}
                >
                    <div className='tbody-content-height'>
                    {data?.map((item: ITicketItems, index: number) => (
                        <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 20% 29% 15% 15% 15%" }}>
                            <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                            <li>{item.email}</li>
                            <li>
                                <span className="cursor-context-menu" title={item.description}>{truncateString(item.description, 90)}</span>
                            </li>
                            <li>{moment(item.createdAt).format("DD-MM-YYYY")}</li>
                            <li style={{justifyContent:'center',paddingLeft:0}} className="capitalize">
                                <span className="flex gap-1 items-center">
                                    <img
                                        width={10}
                                        src={item.status === "pending" ? pendingcircle : resolvedcircle}
                                        alt='status'
                                    />
                                    {item.status}
                                </span>
                            </li>
                            <li>
                                <select disabled={item.status === "resolved"} onChange={() => handleChangeStatus(item._id)} value={item.status} className="font-Poppins-SemiBold user-graph-select theme-color-green font-size-14px">
                                    <option className="font-Poppins-Medium font-size-14px" value="pending">
                                        Pending
                                    </option>
                                    <option className="font-Poppins-Medium font-size-14px" value="resolved">
                                        Resolve
                                    </option>
                                </select>
                            </li>
                        </ul>
                    ))}
                    </div>
                </TableWrapper>
            </div>
        </>
    );
};

export default SupportList;