import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import homeIcon from "src/assets/images/home-icon.png";
import productsIcon from "src/assets/images/header-products-icon.png";
import discussionIcon from "src/assets/images/discuss-icon.png";
import schedulerIcon from "src/assets/images/shechduler-icon.png";
import messageIcon from "src/assets/images/message-icon.png";
import blogIcon from "src/assets/images/blog-icon.png";
import notificationIcon from "src/assets/images/notification-icon.png";
import cart from "src/assets/images/cart-white-icon.png";
import CartDrawer from '../AdminComponents/CartDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@store/store';
import { ICartItems } from 'src/types/GlobalInterfaces';
import { removeItemFromCart } from 'src/Store/Cart';
import { truncateString } from 'src/helpers/helperFunctions';
interface IHeaderList {
    title: string;
    icon: string;
    url: string;
    isDisplay: boolean;
};

const HeaderNav: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { pathname } = useLocation();
    const { totalItems, total, cartItems } = useSelector((store: RootState) => store.cart);
    const { messageUnreadCount, unreadNotificationCount } = useSelector((store: RootState) => store.appReducer);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleDeleteFromCart = (id: string) => {
        dispatch(removeItemFromCart(id))
    };

    const headerList: IHeaderList[] = [
        {
            title: "Home",
            icon: homeIcon,
            url: "/dashboard",
            isDisplay: true,
        },
        {
            title: "Products",
            icon: productsIcon,
            url: "/all-products",
            isDisplay: true,
        },
        {
            title: "Discussions",
            icon: discussionIcon,
            url: "/discussions",
            isDisplay: true,
        },
        {
            title: "Scheduler",
            icon: schedulerIcon,
            url: "/scheduler",
            isDisplay: true,
        },
        {
            title: "Messaging",
            icon: messageIcon,
            url: "/message",
            isDisplay: true,
        },
        // {
        //     title: "Blogs",
        //     icon: blogIcon,
        //     url: "",
        //     isDisplay: true,
        // },
        {
            title: "Notifications",
            icon: notificationIcon,
            url: "/notifications",
            isDisplay: true,
        },
    ];

    return (
        <>
            <ul className='header-nav'>
                {headerList.filter(v => v.isDisplay).map((item: IHeaderList, index: number) => (
                    <li key={index}>
                        <NavLink to={item.url} className={`header-nav-items font-size-15px font-Poppins-Regular ${pathname === item.url ? "header-nav-items-active" : ""}`} style={{ cursor: 'pointer' }}>
                            <div className='relative'>
                                <img src={item.icon} alt="" width={20} height={20} />
                                {(item.title === "Notifications" && unreadNotificationCount)
                                    ?
                                    <span className='cart-count-total text-white font-Poppins-Medium'>{unreadNotificationCount}</span>
                                    : (item.title === "Messaging" && messageUnreadCount)
                                        ?
                                        <span className='cart-count-total text-white font-Poppins-Medium'>{messageUnreadCount}</span>
                                        : ""
                                }
                            </div>
                            <p className='font-size-13px font-Poppins-Medium'>{item.title}</p>
                        </NavLink>
                    </li>
                ))}
                <li>
                    <NavLink to="" onClick={toggleDrawer} className="header-nav-items header-nav-items-bell-icon font-size-15px font-Poppins-Regular" style={{ cursor: 'pointer' }}>
                        <div className='relative'>
                            <img style={{ filter: "grayscale(100%) brightness(600%) contrast(100%) sepia(100%) hue-rotate(-90deg)" }} src={cart} alt="" width={20} height={20} />
                            {totalItems ?
                                <span className='cart-count-total text-white font-Poppins-Medium'>{totalItems}</span>
                                : ""
                            }
                        </div>
                        <p className='font-size-13px font-Poppins-Medium'>Cart</p>
                    </NavLink>
                </li>
            </ul>

            {/* Render the Drawer component */}
            <CartDrawer isOpen={isDrawerOpen} onClose={toggleDrawer}>
                <div>
                    <h2 className='theme-color-green my-3 mb-4 font-size-30px font-Poppins-SemiBold'>Your Cart ({totalItems})</h2>
                    {cartItems?.map((item: ICartItems, index: number) => (
                        <>
                            <div key={index}>
                                <div className='cart-item mb-3'>
                                    <span className='text-[#474747] font-size-20px font-Poppins-Medium'>{truncateString(item?.productName || "", 40)}</span>
                                    <button onClick={() => handleDeleteFromCart(item?._id)}><FontAwesomeIcon icon={faTrash} /></button>
                                </div>
                                <span className='text-[#474747] font-size-20px font-Poppins-SemiBold'>AED {item?.salePrice || 0}</span>
                            </div>
                            <hr className='my-4' />
                        </>
                    ))}
                </div>
                {
                    (cartItems.length > 0)
                    &&
                    <div className='drawer-order-summary'>
                        <h2 className='theme-color-green font-size-30px font-Poppins-SemiBold'>Your Cart ({totalItems})</h2>
                        <div className='border-solid flex justify-between border-b-[1px] border-[#D9D9D9] p-3'>
                            <span className='text-[#474747] font-size-20px font-Poppins-Regular'>Sub Total:</span>
                            <span className='text-[#474747] font-size-20px font-Poppins-SemiBold'>AED {total}</span>
                        </div>
                        <div className='flex justify-between p-3'>
                            <span className='text-[#474747] font-size-20px font-Poppins-Regular'>Total:</span>
                            <span className='text-[#474747] font-size-20px font-Poppins-SemiBold'>AED {total}</span>
                        </div>
                        <div className='flex justify-end'>
                            <Link onClick={toggleDrawer} to="/checkout-payment" className='proceed-to-checkout font-size-18px font-Poppins-SemiBold'>Proceed to Checkout</Link>
                        </div>
                    </div>
                }
            </CartDrawer>
        </>
    );
};

export default HeaderNav;