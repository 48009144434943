/**
 * DoctorDashboard
 * 
 * Description: This component displays the doctor's dashboard including total earnings, total customers, and today's online and physical appointments.
 * It fetches and displays statistics such as total earnings, customer count, and appointment details from the server.
 * 
 * File Name: DoctorDashboard.tsx
 * Date: 2024-09-11
 */

import React, { useEffect, useState } from 'react';
import revenueIcon from "../../assets/images/doctor-dashboard-revenue-icon.png";
import customerIcon from "../../assets/images/doctor-dashboard-customer-icon.png";
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import DoctorRevenue from 'src/Components/DoctorComponents/DoctorRevenue';
import { CircularProgress } from '@mui/material';
import moment from 'moment';

interface IOnlineAppointmentsState {
    patientName: string;
    startTime: string;
    appoinmentDate: string;
}

interface IPhysicalAppointmentsState {
    patientName: string;
    hospitalName: string;
    startTime: string;
    appoinmentDate: string;
};

interface IData {
    totalAmount: number;
    _id: { year: number; month: 8 };
};

interface IStatsResponse {
    totalCustomer: { value: number }[];
    totalEarning: { totalAmount: number }[];
    monthlyData: IData[];
    yearlyDataDoctor: IData[];
};

const DoctorDashboard: React.FC = () => {
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [apLoader, setApLoader] = useState<boolean>(false);
    const [totalCustomer, setTotalCustomer] = useState<number>(0);
    const [totalEarn, setTotalEarn] = useState<number>(0);
    const [monthlyData, setMonthlyData] = useState<IData[]>([]);
    const [yearlyData, setYearlyData] = useState<IData[]>([]);

    const [onlineAppointments, setOnlineAppointments] = useState<IOnlineAppointmentsState[]>([]);
    const [physicalAppointments, setPhysicalAppointments] = useState<IPhysicalAppointmentsState[]>([]);

    // API_CALL: Retrieves statistics for the doctor from the server
    const fetchStats = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IStatsResponse[]>({ path: "/accessdoctorside/statistic" });
        if (res) {
            const { totalCustomer, totalEarning, monthlyData, yearlyDataDoctor } = res[0];
            setTotalCustomer(totalCustomer[0]?.value || 0);
            setTotalEarn(totalEarning[0]?.totalAmount || 0);
            setMonthlyData(monthlyData);
            setYearlyData(yearlyDataDoctor);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setIsLoader(false);
    };

    // API_CALL: Fetches today's appointments for the doctor from the server
    const fetchAppointment = async () => {
        setApLoader(true);
        const { res, err } = await httpRequest<{ physicalAppoinment: IPhysicalAppointmentsState[]; onlineAppoinemnt: IOnlineAppointmentsState[] }[]>({ path: "/accessdoctorside/statistic/appoinment" });
        if (res) {
            setOnlineAppointments(res[0].onlineAppoinemnt || []);
            setPhysicalAppointments(res[0].physicalAppoinment || []);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setApLoader(false);
    };

    useEffect(() => {
        fetchStats();
        fetchAppointment();
    }, []);

    return (
        <>
            <div className="parent-box parent-box-docter-margin">
                <div className='grid grid-cols-10 gap-2'>
                    <div className="col-span-3">
                        <div className='total-earning gray-box mb-2'>
                            <div className=' flex xl:gap-4 gap-1 items-center '>
                                {/* <div className="green-box flex items-center justify-center min-h-[130px] min-w-[90px] xl:min-h-[150px] xl:min-w-[110px]"> */}
                                <div className="green-box flex items-center justify-center green-box-icon-width">
                                    <img src={revenueIcon} alt="revenue-icon" />
                                </div>
                                <div className='flex items-center justify-center flex-col gap-2 w-full'>
                                    <p className="font-Poppins-Medium font-size-20px text-center px-2">
                                        Total Earnings
                                    </p>
                                    <h1 className="font-Poppins-SemiBold font-size-26px theme-color-green text-center">
                                        {isLoader ?
                                            <CircularProgress size={20} style={{ color: "#004540" }} />
                                            :
                                            <>AED {totalEarn}</>
                                        }
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className='total-earning gray-box'>
                            <div className=' flex items-center '>
                                <div className="green-box flex items-center justify-center green-box-icon-width">
                                    <img src={customerIcon} alt="customer-icon" />
                                </div>
                                <div className='flex items-center justify-center flex-col 1 w-full'>
                                    <p className="font-Poppins-Medium font-size-20px text-center px-2">
                                        Total Customers
                                    </p>
                                    <h1 className="font-Poppins-SemiBold font-size-26px theme-color-green text-center">
                                        {isLoader ?
                                            <CircularProgress size={20} style={{ color: "#004540" }} />
                                            :
                                            totalCustomer
                                        }
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-7">
                        <DoctorRevenue
                            isLoader={isLoader}
                            monthlyData={monthlyData}
                            yearlyData={yearlyData}
                        />
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-[1.3fr_1.5fr] gap-2'>
                <div className="parent-box appointments-table appointments-table-online">
                    <div className='flex items-center justify-between mb-6'>
                        <p className="font-Poppins-Medium font-size-20px">Today's Online Appoinments</p>
                        <div className="tag tag-green">
                            <p className="font-Poppins-regular font-size-18px">
                                Online
                            </p>
                        </div>
                    </div>
                    <div className="flex scroll-content-doctor flex-col gap-2">
                        {apLoader && <div className="text-center"><CircularProgress size={20} style={{ color: "#004540" }} /></div>}
                        {onlineAppointments.length > 0
                            ?
                            onlineAppointments.map((item: IOnlineAppointmentsState, index: number) => (
                                <div key={index} className="gray-box">
                                    <div className="grid grid-cols-3 justify-items-center gap-0 xl:gap-1 px-0 xl:px-1 py-1">
                                        <div className='flex flex-col'>
                                            <p className="font-Poppins-Medium font-size-12px text-[#A1A1A1]">
                                                Name
                                            </p>
                                            <p className="font-Poppins-Medium font-size-15px">
                                                {item.patientName}
                                            </p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className="font-Poppins-Medium font-size-12px text-[#A1A1A1]">
                                                Time
                                            </p>
                                            <p className="font-Poppins-Medium font-size-15px">
                                                {moment(item.startTime, "HH:mm").format("h:mm A")}
                                            </p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className="font-Poppins-Medium font-size-12px text-[#A1A1A1]">
                                                Date
                                            </p>
                                            <p className="font-Poppins-Medium font-size-15px">
                                                {moment(item.appoinmentDate).format("DD-MM-YYYY")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            <p className="font-Poppins-Medium font-size-16px text-center">No online appointments scheduled for today.</p>
                        }
                    </div>
                </div>
                <div className="parent-box appointments-table appointments-table-physical">
                    <div className='flex items-center justify-between mb-6'>
                        <p className="font-Poppins-Medium font-size-20px">Today's Physical Appointments</p>
                        <div className="tag tag-blue">
                            <p className="font-Poppins-regular font-size-18px">
                                Physical
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col scroll-content-doctor gap-2">
                        {apLoader && <div className="text-center"><CircularProgress size={20} style={{ color: "#004540" }} /></div>}
                        {physicalAppointments.length > 0
                            ?
                            physicalAppointments.map((item: IPhysicalAppointmentsState, index: number) => (
                                <div key={index} className="gray-box">
                                    <div className="grid grid-cols-4 gap-0 xl:gap-1 justify-items-center px-0 xl:px-1 py-1">
                                        <div className='flex flex-col'>
                                            <p className="font-Poppins-Medium font-size-12px text-[#A1A1A1]">
                                                Name
                                            </p>
                                            <p className="font-Poppins-Medium font-size-15px">
                                                {item.patientName}
                                            </p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className="font-Poppins-Medium font-size-12px text-[#A1A1A1] truncate ...">
                                                Hospital Name
                                            </p>
                                            <p className="font-Poppins-Medium font-size-15px truncate ...">
                                                {item.hospitalName}
                                            </p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className="font-Poppins-Medium font-size-12px text-[#A1A1A1]">
                                                Time
                                            </p>
                                            <p className="font-Poppins-Medium font-size-15px">
                                                {moment(item.startTime, "HH:mm").format("h:mm A")}
                                            </p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className="font-Poppins-Medium font-size-12px text-[#A1A1A1]">
                                                Date
                                            </p>
                                            <p className="font-Poppins-Medium font-size-15px">
                                                {moment(item.appoinmentDate).format("DD-MM-YYYY")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            <p className="font-Poppins-Medium font-size-16px text-center">No physical appointments scheduled for today.</p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default DoctorDashboard;