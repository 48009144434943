/**
 * AllReviews Component
 * 
 * This component is responsible for displaying all reviews for a specific
 * doctor. It includes a page header with a title and a back button that
 * navigates the user to the previous page. The reviews are displayed
 * using the ReviewComponents component, which takes the doctor's ID as a prop.
 * Date: 01-10-2024
 */

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'src/Components/Customer/PageHeader';
import ReviewComponents from 'src/Components/ReviewComponents';
import BackIcon from "src/assets/images/back-icon.png";

const AllReviews: React.FC = () => {
    const navigate = useNavigate();
    const { id = "" } = useParams();

    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header
    return (
        <>
            <PageHeader
                title="Reviews"
                isButton={true}
                ButtonComponent={BackButton}
            />
            <div className="parent-box">
                <ReviewComponents
                    id={id}
                />
            </div>
        </>
    );
};

export default AllReviews;