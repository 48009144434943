/**
 * CustomerNotifications
 * 
 * Description: This component serves as a notifications page for customers, providing a header with 
 * a back button and rendering the NotificationsComponent to display notifications. 
 * It uses React Router's `useNavigate` for navigation to the dashboard.
 * 
 * File Name: CustomerNotifications.tsx
 * Date: 30-09-2024
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import BackIcon from "src/assets/images/back-icon.png";
import PageHeader from 'src/Components/Customer/PageHeader';
import NotificationsComponent from 'src/Components/NotificationsComponent';

const CustomerNotifications: React.FC = () => {
    const navigate = useNavigate();
    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate("/dashboard")}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header
    return (
        <>
            <PageHeader
                title="Notifications"
                isButton={true}
                ButtonComponent={BackButton}
            />
            <NotificationsComponent />
        </>
    );
};

export default CustomerNotifications;