/**
 * LatesTest Component
 * 
 * Description: A React component for displaying recent test information and biomarkers overview.
 * It fetches the latest test data based on a provided ID and displays test details and biomarkers 
 * with progress indicators. Users can view detailed results based on their role.
 * 
 * File Name: LatesTest.tsx
 * Date: 21-08-2024
 */

import React, { useEffect, useState } from 'react';
import ProgressIndicator from 'src/Pages/Customer/Progress/ProgressIndicator';
import PrimaryBtn from '../primaryBtn';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { customer, doctor, operator, superAdmin } from 'src/lib/constans';

interface ILatest {
    name: string;
    testDate: string;
    testDataCount: string;
    _id: string;
    red: string;
    green: string;
    orange: string;
    [key: string]: any;
};

const LatesTest: React.FC<{ id: string }> = ({ id }) => {
    // Retrieve user details from the Redux store
    const { userDetails } = useSelector((store: RootState) => store.appReducer);

    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [item, setItem] = useState<ILatest | null>(null);

    //API_CALL: Fetch the latest test data from the server
    const fetchLatestTest = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: ILatest | null }>({ path: `/test/customer/${id}` });
        if (res) {
            if (res?.data) {
                setItem({ ...res?.data, total: Number(res?.data?.red) + Number(res?.data?.green) + Number(res?.data?.orange) });
            } else {
                setItem(null);
            }
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Navigate to the appropriate result page based on user role
    const handleNavigate = () => {
        if (userDetails && item) {
            if (userDetails.userRole === customer || userDetails.userRole === doctor) {
                navigate(`/test-result/${item._id}/view`);
            } else if (userDetails.userRole === superAdmin || userDetails.userRole === operator) {
                navigate(`/result/${item._id}/view`);
            } else {
                console.warn("UnAuthoraized userRole not defined!");
                // Future role-based navigation can be handled here
            }
        }
    };

    // Fetch the latest test data when the component mounts or `id` changes
    useEffect(() => {
        if (id) {
            fetchLatestTest();
        }
    }, [id]);
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="parent-box">
                <div>
                    <p className="font-Poppins-SemiBold font-size-18px">Recent Test at a Glance</p>
                    {
                        isLoader
                            ?
                            <div className="text-center mt-3">
                                <CircularProgress style={{ width: 24, height: 24, color: "#00443f" }} />
                            </div>
                            :
                            item
                                ?
                                <>
                                    <div className='flex flex-col gap-2 my-4'>
                                        <div className="recent-test-details">
                                            <p className="font-Poppins-Regular font-size-15px">Test Name:</p>
                                            <p className="font-Poppins-Regular font-size-15px theme-color-green">{item.name}</p>
                                        </div>
                                        <div className="recent-test-details">
                                            <p className="font-Poppins-Regular font-size-15px">Test Date:</p>
                                            <p className="font-Poppins-Regular font-size-15px theme-color-green">{moment(item.testDate).format("DD-MM-YYYY")}</p>
                                        </div>
                                        <div className="recent-test-details">
                                            <p className="font-Poppins-Regular font-size-15px">Biomarkers:</p>
                                            <p className="font-Poppins-Regular font-size-15px theme-color-green">{item.testDataCount}</p>
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-center'>
                                        <PrimaryBtn
                                            onPress={handleNavigate}
                                            type="submit"
                                            ButtonText="View Result"
                                            btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem", padding: "5px 32px" }}
                                        />
                                    </div>
                                </>
                                :
                                <p className="font-Poppins-Regular font-size-15px text-center">No Test</p>
                    }
                </div>
            </div>
            <div className="parent-box">
                <div>
                    <p className="font-Poppins-SemiBold font-size-18px">Your Biomarkers at a Glance</p>
                    {
                        isLoader
                            ?
                            <div className="text-center mt-3">
                                <CircularProgress style={{ width: 24, height: 24, color: "#00443f" }} />
                            </div>
                            :
                            item
                                ?
                                <div className='flex flex-col gap-2 my-4'>
                                    <div className="recent-biomarkers-details">
                                        <div className='flex items-center justify-between mb-1'>
                                            <p className="font-Poppins-Regular font-size-15px">At Risk</p>
                                            <p className="font-Poppins-Regular font-size-15px theme-color-green">{item.red}</p>
                                        </div>
                                        <ProgressIndicator type="linear" value={(Number(item.red) / item.total) * 100} color='red' />
                                    </div>
                                    <div className="recent-biomarkers-details">
                                        <div className='flex items-center justify-between mb-1'>
                                            <p className="font-Poppins-Regular font-size-15px">Need improvement</p>
                                            <p className="font-Poppins-Regular font-size-15px theme-color-green">{item.orange}</p>
                                        </div>
                                        <ProgressIndicator type="linear" value={(Number(item.orange) / item.total) * 100} />
                                    </div>
                                    <div className="recent-biomarkers-details">
                                        <div className='flex items-center justify-between mb-1'>
                                            <p className="font-Poppins-Regular font-size-15px">Optimal</p>
                                            <p className="font-Poppins-Regular font-size-15px theme-color-green">{item.green}</p>
                                        </div>
                                        <ProgressIndicator type="linear" value={(Number(item.green) / item.total) * 100} color='green' />
                                    </div>
                                </div>
                                :
                                <p className="font-Poppins-Regular font-size-15px text-center">No Test</p>
                    }
                </div>
            </div>
        </div>
    );
};

export default LatesTest;