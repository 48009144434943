import React, { useEffect, useState } from 'react';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import UpsertPharmaBrand from 'src/Components/PharmacyComponents/UpsertPharmaBrand';
import httpRequest from 'src/helpers/httpRequest';
import { IPharmaBrand, IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import { toast } from 'react-toastify';
import ConfirmationModal from 'src/Components/GlobalComponents/ConfirmationModal';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import editIcon from 'src/assets/images/edit-icon.png';
import deleteIcon from 'src/assets/images/delete-icon.png';
import emtyImg from 'src/assets/images/emty-img.png';

interface IResponce {
    documents: IPharmaBrand[];
    paginated: IPaginated;
};

const PBrands: React.FC = () => {
    const [isUpsert, setIsUpsert] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<IPharmaBrand | false>(false);
    const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [displayData, setDisplayData] = useState<IPharmaBrand[]>([]);
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        search: "",
        bool: false,
    });
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    });

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string>("");

    // Function to close the delete confirmation modal
    const deleteModalClose = () => {
        setDeleteModal(false);
        setDeleteId("");
    };

    // Function to update the list of brands after creating or editing a brand
    const handleUpdateList = () => {
        setQueryParams(prev => ({
            ...prev,
            bool: !prev.bool,
        }));
        setIsUpsert(false);
    };

    // Function change search text for api query params
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            search: txt
        }));
    };

    // Function to handle page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Function to set the component to edit mode and populate it with brand data
    const handleEdit = (obj: IPharmaBrand) => {
        setIsUpsert(true);
        setIsEdit(obj);
    };

    // Function to show the delete confirmation modal and set the ID of the brand to be deleted
    const handleDelete = (id: string) => {
        setDeleteModal(true);
        setDeleteId(id);
    };

    // API_CALL: Function to delete a Brand by its ID
    const deleteRquest = async () => {
        setDeleteLoader(true);
        // const { res, err } = await httpRequest<any>({ method: "delete", path: `/brand/delete/${deleteId}`, params: {} });
        // if (res) {
        //     toast("Brand deleted successfully.", { type: 'success' });
        //     setQueryParams(prev => ({
        //         ...prev,
        //         counter: (prev.counter ?? 0) + 1,
        //         page: displayData?.length > 1 ? prev?.page : prev?.page > 1 ? prev?.page - 1 : prev?.page
        //     }));
        //     deleteModalClose();
        // } else {
        //     toast(err, { type: 'error' });
        // }
        setDeleteLoader(false);
    };

    // API_CALL: Function to fetch all brands of pharmacy based on query parameters
    const fetch = async () => {
        setIsLoader(true);
        const { bool, ...actualParams } = queryParams;
        setDisplayData([
            {
                _id: "6482e1b0a1b9c3d5f1a12345",
                brandName: "Bioderma",
                brandLogo: "https://www.google.com/imgres?q=bioderma%20logo&imgurl=https%3A%2F%2Flogos-world.net%2Fwp-content%2Fuploads%2F2020%2F11%2FBioderma-Emblem.png&imgrefurl=https%3A%2F%2Flogos-world.net%2Fbioderma-logo%2F&docid=wz78f9r3lBdwoM&tbnid=OTN_1pWlaD26fM&vet=12ahUKEwjjs92b79uJAxUvzAIHHWNhH5gQM3oECBkQAA..i&w=3840&h=2160&hcb=2&ved=2ahUKEwjjs92b79uJAxUvzAIHHWNhH5gQM3oECBkQAA"
            },
            {
                _id: "6482e1b0a1b9c3d5f1a12346",
                brandName: "Vichy",
                brandLogo: "https://www.google.com/imgres?q=bioderma%20logo&imgurl=https%3A%2F%2Flogos-world.net%2Fwp-content%2Fuploads%2F2020%2F11%2FBioderma-Emblem.png&imgrefurl=https%3A%2F%2Flogos-world.net%2Fbioderma-logo%2F&docid=wz78f9r3lBdwoM&tbnid=OTN_1pWlaD26fM&vet=12ahUKEwjjs92b79uJAxUvzAIHHWNhH5gQM3oECBkQAA..i&w=3840&h=2160&hcb=2&ved=2ahUKEwjjs92b79uJAxUvzAIHHWNhH5gQM3oECBkQAA"
            },
            {
                _id: "6482e1b0a1b9c3d5f1a12347",
                brandName: "La Roche-Posay",
                brandLogo: "https://www.google.com/imgres?q=bioderma%20logo&imgurl=https%3A%2F%2Flogos-world.net%2Fwp-content%2Fuploads%2F2020%2F11%2FBioderma-Emblem.png&imgrefurl=https%3A%2F%2Flogos-world.net%2Fbioderma-logo%2F&docid=wz78f9r3lBdwoM&tbnid=OTN_1pWlaD26fM&vet=12ahUKEwjjs92b79uJAxUvzAIHHWNhH5gQM3oECBkQAA..i&w=3840&h=2160&hcb=2&ved=2ahUKEwjjs92b79uJAxUvzAIHHWNhH5gQM3oECBkQAA"
            },
            {
                _id: "6482e1b0a1b9c3d5f1a12348",
                brandName: "Avene",
                brandLogo: "https://www.google.com/imgres?q=bioderma%20logo&imgurl=https%3A%2F%2Flogos-world.net%2Fwp-content%2Fuploads%2F2020%2F11%2FBioderma-Emblem.png&imgrefurl=https%3A%2F%2Flogos-world.net%2Fbioderma-logo%2F&docid=wz78f9r3lBdwoM&tbnid=OTN_1pWlaD26fM&vet=12ahUKEwjjs92b79uJAxUvzAIHHWNhH5gQM3oECBkQAA..i&w=3840&h=2160&hcb=2&ved=2ahUKEwjjs92b79uJAxUvzAIHHWNhH5gQM3oECBkQAA"
            },
            {
                _id: "6482e1b0a1b9c3d5f1a12349",
                brandName: "Neutrogena",
                brandLogo: "https://www.google.com/imgres?q=bioderma%20logo&imgurl=https%3A%2F%2Flogos-world.net%2Fwp-content%2Fuploads%2F2020%2F11%2FBioderma-Emblem.png&imgrefurl=https%3A%2F%2Flogos-world.net%2Fbioderma-logo%2F&docid=wz78f9r3lBdwoM&tbnid=OTN_1pWlaD26fM&vet=12ahUKEwjjs92b79uJAxUvzAIHHWNhH5gQM3oECBkQAA..i&w=3840&h=2160&hcb=2&ved=2ahUKEwjjs92b79uJAxUvzAIHHWNhH5gQM3oECBkQAA"
            }
        ]);
        // const { res, err } = await httpRequest<IResponce>({ path: `/brand/all?${qs.stringify(actualParams)}` });
        // if (res) {
        //     const { documents = [], paginated = { currentPage: 1, totalPages: 1 } } = res;
        //     setDisplayData(documents);
        //     setPaginated(paginated);
        // } else {
        //     toast(err, { type: 'error' });
        // }
        setIsLoader(false);
    };

    useEffect(() => {
        fetch();
    }, [queryParams]);

    return (
        <>
            <ConfirmationModal
                show={deleteModal}
                onClose={deleteModalClose}
                title="Are you sure you want to delete?"
                leftBtnText="No"
                rightBtnText="Yes"
                onConfirm={deleteRquest}
                loader={deleteLoader}
            />
            <BreadCrumb
                current="Brands"
                previous="dashboard"
                navigateUrl="/admin"
                btnText={"Add Brand"}
                btnShow={true}
                onPress={() => setIsUpsert(prev => !prev)}
                isUpsert={isUpsert}
            />
            <div className="table-container">
                {
                    isUpsert
                        ?
                        <UpsertPharmaBrand
                            onSuccess={handleUpdateList}
                            isEdit={isEdit}
                        />
                        :
                        <>
                            <SearchFilterInput
                                onChangeText={handleChangeText}
                            />
                            <TableWrapper
                                isLoading={isLoader}
                                thead={["S.No", "Brand Name", "Brand Logo", "Action"]}
                                page={paginated?.currentPage || 1}
                                total={paginated?.totalPages || 0}
                                columnWidth="10% 40% 40% 10%"
                                onChange={handlePageChange}
                            >
                                <div className='tbody-content-height'>
                                    {displayData.map((item, index: number) => (
                                        <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "10% 40% 40% 10%" }}>
                                            <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                            <li>{item?.brandName || ""}</li>
                                            <li>
                                                <img
                                                    className="max-h-[40px]"
                                                    src={`${process.env.REACT_APP_API_BASEURL}${item?.brandLogo}`}
                                                    alt={item?.brandName || ""}
                                                    onError={(e) => {
                                                        const target = e.target as HTMLImageElement; // Type assertion
                                                        target.src = emtyImg; // Handle error by setting default image
                                                    }}
                                                />
                                            </li>
                                            <li className='action-last-colum'>
                                                <img onClick={() => handleEdit(item)} src={editIcon} alt="" />
                                                <img onClick={() => handleDelete(item?._id)} src={deleteIcon} alt="" />
                                            </li>
                                        </ul>
                                    ))}
                                </div>
                            </TableWrapper>
                        </>
                }
            </div>
        </>
    );
};

export default PBrands;