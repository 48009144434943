import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { appRoutes } from "./routes";

// *********** SLICK SLIDER ***********  //
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// *********** REACT TOASTIFY ***********  //
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// *********** IMPORT FILES ***********  //
import ProtectedRoute from "../hoc/protectedRoute";
import FullScreenLoader from "../Components/fullScreenLoader.jsx";
import { RootState } from "@store/store";
import CallReceiveModal from "src/Components/Customer/CallReceiveModal";

function Router() {
    const { showLoader } = useSelector((state: RootState) => state.appReducer);
    return (
        <>
            <Routes>
                {appRoutes.map((item, index) => {
                    return (
                        <Route
                            key={index}
                            path={item.path}
                            element={<ProtectedRoute item={item} />}
                        />
                    );
                })}
            </Routes>
            <FullScreenLoader show={showLoader} />
            <ToastContainer />
            <CallReceiveModal />
        </>
    );
};

export default Router;