/**
 * DiseasesList Component
 * 
 * Description: This component handles the display, search, pagination, creation, and deletion of diseases.
 * It includes functionality to edit and delete existing diseases, and provides an interface to add new ones.
 * 
 * File Name: DiseasesList.tsx
 * Date: 05-09-2024
 */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import httpRequest from 'src/helpers/httpRequest';
import emtyImg from 'src/assets/images/emty-img.png';
import editIcon from 'src/assets/images/edit-icon.png';
import deleteIcon from 'src/assets/images/delete-icon.png';
import { IqueryParams, IDiseaseItem } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import ConfirmationModal from 'src/Components/GlobalComponents/ConfirmationModal';
import UpsertDisease from 'src/Components/AdminComponents/UpsertDisease';

interface IDiseaseResponse {
    documents: IDiseaseItem[];
    paginated: IPaginated;
}; // Interface for the API response related to diseases

const DiseasesList: React.FC = () => {
    const [isUpsert, setIsUpsert] = useState<boolean>(false); // State to manage the upsert mode (create/edit disease)
    const [isEdit, setIsEdit] = useState<IDiseaseItem | boolean>(false); // State to determine if the component is in edit mode

    // States for loading status, pagination, and customer data
    const [deleteLoader, setDeleteLoader] = useState<boolean>(false); // State to manage the loader during disease deletion
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [allDisease, setAllDisease] = useState<IDiseaseItem[]>([]);
    const [deleteModal, setDeleteModal] = useState<boolean>(false); // State to control the visibility of the delete confirmation modal
    const [deleteId, setDeleteId] = useState<string>("");  // State to store the ID of the disease to be deleted

    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        search: "",
        counter: 1
    });

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            search: txt,
            page: 1
        }));
    };

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Function to set the component to edit mode and populate it with disease data
    const handleEdit = (obj: IDiseaseItem) => {
        setIsUpsert(true);
        setIsEdit(obj);
    };

    // Function to show the delete confirmation modal and set the ID of the disease to be deleted
    const handleDelete = (id: string) => {
        setDeleteModal(true);
        setDeleteId(id);
    };

    // Function to close the delete confirmation modal
    const deleteModalClose = () => {
        setDeleteModal(false);
        setDeleteId("");
    };

    // Function to toggle between create and edit mode
    const handleClose = () => {
        setIsEdit(false);
        setIsUpsert(prev => !prev);
    };

    // Function to update the list of categories after creating or editing a category
    const handleUpdateList = () => {
        setQueryParams(prev => ({
            ...prev,
            counter: (prev.counter ?? 0) + 1,
        }));
        setIsUpsert(false);
    };

    // API_CALL: Function to delete a disease by its ID
    const deleteDisease = async () => {
        setDeleteLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({ method: "delete", path: `/diseases/${deleteId}`, params: {} });
        if (res) {
            toast(res.message, { type: 'success' });
            setQueryParams(prev => ({
                ...prev,
                counter: (prev.counter ?? 0) + 1,
                page: allDisease?.length > 1 ? prev?.page : prev?.page > 1 ? prev?.page - 1 : prev?.page
            }));
            setDeleteModal(false);
            setDeleteId("");
        } else {
            toast(err, { type: 'error' });
        }
        setDeleteLoader(false);
    };

    // API_CALL: Fetch the list of disease when the component mounts and change queryparams
    useEffect(() => {
        (async () => {
            setIsLoader(true);
            let { counter, ...remainingParams } = queryParams;
            const { res, err } = await httpRequest<IDiseaseResponse>({ path: `/diseases?${qs.stringify(remainingParams)}` });
            if (res) {
                let { documents = [], paginated } = res;
                setAllDisease(documents);
                setPaginated(paginated);
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        })();
    }, [queryParams]);

    return (
        <>
            <ConfirmationModal
                show={deleteModal}
                onClose={deleteModalClose}
                title="Are you sure you want to delete?"
                leftBtnText="No"
                rightBtnText="Yes"
                onConfirm={deleteDisease}
                loader={deleteLoader}
            />
            <BreadCrumb
                current="Disease"
                previous="dashboard"
                navigateUrl="/admin"
                btnText={"Add Disease"}
                onPress={handleClose}
                btnShow={true}
                isUpsert={isUpsert}
            />
            {isUpsert
                ?
                <UpsertDisease
                    onSuccess={handleUpdateList}
                    isEdit={isEdit}
                />
                :
                <div className="table-container">
                    <SearchFilterInput
                        onChangeText={handleChangeText}
                    />
                    <TableWrapper
                        isLoading={isLoader}
                        page={paginated?.currentPage || 1}
                        total={paginated?.totalPages || 0}
                        thead={["S.No", "Icon", "Disease", "Date", "Action"]}
                        onChange={handlePageChange}
                        columnWidth={"6% 15% 44% 20% 15%"}
                    >
                        <div className='tbody-content-height'>
                            {allDisease?.map((item: IDiseaseItem, index: number) => (
                                <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 15% 44% 20% 15%" }}>
                                    <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                    <li className='action-last-colum-img'>
                                        <img
                                            src={`${process.env.REACT_APP_API_BASEURL}${item.diseasesImage}`}
                                            alt="category"
                                            onError={(e) => {
                                                const target = e.target as HTMLImageElement; // Type assertion
                                                target.src = emtyImg; // Handle error by setting default image
                                            }}
                                        />
                                    </li>
                                    <li>{item.name}</li>
                                    <li>{moment(item.createdAt).format("DD-MM-YYYY")}</li>
                                    <li className='action-last-colum'>
                                        <img onClick={() => handleEdit(item)} src={editIcon} alt="" />
                                        <img onClick={() => handleDelete(item?._id)} src={deleteIcon} alt="" />
                                    </li>
                                </ul>
                            ))}
                        </div>
                    </TableWrapper>
                </div>
            }
        </>
    );
};

export default DiseasesList;