/**
 * MyResults Component
 * 
 * Description: This component fetches and displays a list of test results for the logged-in user. It includes pagination and search functionality to manage and view results efficiently.
 * 
 * File Name: MyResults.tsx
 * Date: 19-08-2024
 */

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from '@store/store';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomerTableWrapper from 'src/Components/Customer/CustomerTableWrapper';
import PageHeader from 'src/Components/Customer/PageHeader';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import httpRequest from 'src/helpers/httpRequest';
import { IqueryParams, IResultList } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import moment from 'moment';
import eyeIcon from '../../assets/images/eye-icon-green.png'
import { barColors } from 'src/lib/constans';

interface IMyResultsResponse {
    documents: IResultList[];
    paginated: IPaginated;
};

const MyResults: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const uId = userDetails?._id || ""; // user id

    const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state
    const [allResults, setAllResults] = useState<IResultList[]>([]); // List of results
    const [paginated, setPaginated] = useState<IPaginated>({ // Pagination state
        currentPage: 1,
        totalPages: 1,
    });
    const [queryParams, setQueryParams] = useState<IqueryParams>({ // Query parameters for API call
        page: 1,
        limit: 10,
        search: "",
    });

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Handles filter change for search functionality
    const handleFilterChange = (val: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            search: val,
        }));
    };

    // API_CALL: Fetches my results from the server
    const fetchMyResults = async (_id: string) => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IMyResultsResponse>({ path: `/test/${_id}?${qs.stringify(queryParams)}` });
        if (res) {
            const { documents = [], paginated } = res;
            setAllResults(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (uId) {
            fetchMyResults(uId); // Fetch results when user ID is available or queryParams change
        }
    }, [queryParams, uId]);

    return (
        <>
            <PageHeader
                title="All Results"
            />

            <div className="parent-box parent-box-height">
                <SearchFilterInput
                    containerExtraStyle={{ justifyContent: "flex-start" }}
                    onChangeText={handleFilterChange}
                />

                <CustomerTableWrapper
                    isLoading={isLoader}
                    thead={["S.No", "Test Name", "Date", "Results", "Actions"]}
                    page={paginated?.currentPage || 1}
                    total={paginated?.totalPages || 0}
                    columnWidth={"10% 36% 18% 22% 14%"}
                    onChange={handlePageChange}
                >
                    {allResults?.map((item: IResultList, index: number) => (
                        <ul key={index} className='font-size-16px tbody-light-table customer-table-wrapper-body-padding-non customer-table-wrapper-body tbody-light-table-customer font-Poppins-Regular grid' style={{ gridTemplateColumns: "10% 36% 18% 22% 14%" }}>
                            <li className="action-last-colum-non">{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                            <li>{item?.testName || ""}</li>
                            {/* <li>
                                <p className="truncate pr-3">
                                    {item?.docterName || ""}
                                </p>
                            </li> */}
                            <li>{moment(item?.date).format("DD-MM-YYYY")}</li>
                            <li className="result__count__circle-main">
                                <div className='pr-4'>
                                    <span className="result__count__circle" style={{ backgroundColor: barColors.green }}>{item.green}</span>
                                </div>
                                <div style={{ borderLeft: "1px solid #C7C7C7", borderRight: "1px solid #C7C7C7" }} className='px-4'>
                                    <span className="result__count__circle" style={{ backgroundColor: barColors.orange }}>{item.orange}</span>
                                </div>
                                <div className='pl-4'>
                                    <span className="result__count__circle" style={{ backgroundColor: barColors.red }}>{item.red}</span>
                                </div>
                            </li>
                            <li className='action-last-colum'>
                                <Link to={`/test-result/${item?._id}/view`}>
                                    {/* <FontAwesomeIcon icon={faEye} /> */}
                                    <img src={eyeIcon} alt="" />
                                </Link>
                            </li>
                        </ul>
                    ))}
                </CustomerTableWrapper>
            </div>
        </>
    );
};

export default MyResults;