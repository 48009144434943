/**
 * Reviews Component
 * 
 * This component fetches and displays the latest reviews for a specific doctor.
 * It shows a loading skeleton while the reviews are being fetched,
 * and uses a slider to navigate through the reviews. If there are multiple pages
 * of reviews, a link is provided to view all reviews.
 * 
 * Props:
 * - id: The ID of the doctor whose reviews are being fetched.
 * Data: 01-10-2024
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import defaultProfile from "src/assets/images/default_profile_image.png";
import { convertToTimeAgo } from 'src/helpers/helperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { IPaginated } from 'src/types/paginated';
import ReviewLoader from 'src/Components/ReviewLoader';

const settings = {
    dots: true,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    centerMode: false,
    centerPadding: "70px",
};

interface IReviewItem {
    _id: string;
    comment: string;
    star: number;
    firstName: string;
    lastName: string;
    coverPhoto: string;
    createdAt: string;
};

const Reviews: React.FC<{ id: string; isAdmin?: boolean }> = ({ id = "", isAdmin = false }) => {
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [data, setData] = useState<IReviewItem[]>([]);

    const handleNavigate = () => {
        if (isAdmin) {
            navigate(`/doctor/${id}/View?allreview=true`)
        } else {
            navigate(`/allreviews/${id}/view`);
        }
    };

    // API_CALL: Fetch latest 5 review from the serve
    const fetchLatestReview = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ documents: IReviewItem[]; paginated: IPaginated; }>({ path: `/review/get-profile/${id}?page=1&limit=5` });
        if (res) {
            let { documents = [], paginated } = res;
            setData(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchLatestReview();
    }, [id]);
    return (
        <div className="parent-box relative">
            <h2 className='font-size-20px font-Poppins-SemiBold theme-color-green mb-1'>Reviews</h2>
            <div className='pb-8'>
                {isLoader ?
                    <ReviewLoader />
                    :
                    <Slider {...settings}>
                        {data.map((item: IReviewItem, index: number) => (
                            <div key={index} className='px-4 py-4'>
                                <div className="reviews rounded-[15px] bg-[#FFFCF1] shadow-[3px_3px_10px_rgba(0,0,0,0.12)] w-full grid grid-cols-[1fr_4fr_1fr] gap-2 items-center mt-4 px-4 py-6">
                                    <div className='flex items-center justify-center flex-col gap-1 grow'>
                                        <img
                                            src={`${process.env.REACT_APP_API_BASEURL}${item?.coverPhoto}`}
                                            onError={(e) => {
                                                const target = e.target as HTMLImageElement; // Assert the type
                                                if (target) {
                                                    target.src = defaultProfile;
                                                }
                                            }}
                                            alt="profile-img"
                                            className='rounded-full w-[65px] h-[65px]'
                                        />
                                        <h2 className="font-size-16px font-Poppins-Medium theme-color-green capitalize">
                                            {`${item?.firstName || ""} ${item?.lastName || ""}`}
                                        </h2>
                                        <p className="font-size-13px font-Poppins-Regular leading-none">Patient</p>
                                    </div>
                                    <div>
                                        <div className="flex gap-2 items-center justify-start mb-3">
                                            {[1, 2, 3, 4, 5].map((v) => (
                                                <FontAwesomeIcon
                                                    className={item.star >= v ? "text-[#facc15]" : `text-[#b7b6b1]`}
                                                    key={v}
                                                    role="button"
                                                    fontSize="16px"
                                                    icon={faStar}
                                                />
                                            ))}
                                        </div>
                                        <p className="font-size-15px font-Poppins-Regular">{item?.comment || ""}</p>
                                    </div>
                                    <div className='font-size-13px font-Poppins-Regular text-[#636363] self-start ml-auto pr-2'>
                                        {convertToTimeAgo(item.createdAt)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                }
            </div>
            {paginated.totalPages > 1 &&
                <div className='pt-3 w-full my-4 border-t border-solid border-[#707070]'>
                    <button onClick={handleNavigate} className="flex items-center justify-center gap-4 mx-auto">
                        <p className='font-size-20px font-Poppins-Regular text-[#707070]'>Show all reviews</p>
                        <p className='font-size-20px '>&rarr;</p>
                    </button>
                </div>
            }
        </div>
    );
};

export default Reviews;