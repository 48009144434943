/**
 * FindDoctors Component
 * 
 * Description: Provides an interface to search and view doctors based on their specialization or disease. 
 * It includes modal functionality for detailed search, loading states, and display of services and diseases.
 * 
 * File Name: FindDoctors.tsx
 * Date: [Insert Date]
 */

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import emtyImg from 'src/assets/images/emty-img.png';
import PageHeader from 'src/Components/Customer/PageHeader';
import BackIcon from "src/assets/images/back-icon.png";
import httpRequest from 'src/helpers/httpRequest';
import { CircularProgress, Modal } from '@mui/material';
import { IDiseaseItem, ISpecializationItem } from 'src/types/GlobalInterfaces';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const FindDoctors: React.FC = () => {
    const navigate = useNavigate();
    const [modalFlag, setModalFlag] = useState<null | "service" | "disease">(null);
    const [serviceLoader, setServiceLoader] = useState<boolean>(false);
    const [diseaseLoader, setDiseaseLoader] = useState<boolean>(false);
    const [allServices, setAllServices] = useState<ISpecializationItem[]>([]);
    const [allDisease, setAllDisease] = useState<IDiseaseItem[]>([]);
    const [searchVal, setSearchVal] = useState<string>("");

    // API_CALL: Function to fetch all special/Specializations
    const fetchAllServices = async () => {
        setServiceLoader(true);
        const { res, err } = await httpRequest<ISpecializationItem[]>({ path: `/provider/special/get` });
        if (res) {
            setAllServices(res);
        } else {
            // Display error toast notification
            toast(err, { type: 'error' });
        }
        setServiceLoader(false);
    };

    // API_CALL: Function to fetch all disease/Specializations
    const fetchAllDisease = async () => {
        setDiseaseLoader(true);
        const { res, err } = await httpRequest<IDiseaseItem[]>({ path: `/provider/diseases/get` });
        if (res) {
            setAllDisease(res);
        } else {
            // Display error toast notification
            toast(err, { type: 'error' });
        }
        setDiseaseLoader(false);
    };

    const handleChangeSearch = (val: string) => {
        setSearchVal(val);
    };

    // Effect to fetch all services when the component mounts
    useEffect(() => {
        fetchAllServices();
        fetchAllDisease();
    }, []);

    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    return (
        <>
            {modalFlag
                &&
                <Modal
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div style={customeStyle} className='lg:min-w-[800px] md:min-w-[600px] min-w-[200px] w-[200px] px-10'>
                        <button style={backBtnMaain} onClick={() => { setSearchVal(""); setModalFlag(null); }}>
                            <FontAwesomeIcon className='font-size-16px' icon={faXmark} size="2x" style={backBtn} />
                        </button>
                        <div className="top_bar border-b border-[#707070]">
                            <p className='font-Poppins-SemiBold font-size-20px theme-color-green text-center py-4'>
                                Find a doctor by {modalFlag === "service" ? "specialization" : "Condition"}
                            </p>
                        </div>
                        <div className='py-6'>
                            <div className="doctors-list-search mt-2">
                                <div>
                                    <SearchFilterInput
                                        onChangeText={handleChangeSearch}
                                        placeHolder={modalFlag === "service" ? "Specializations" : "Disease"}
                                        containerExtraStyle={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        inputExtraStyle={{
                                            width: '100%',
                                            borderRadius: "50rem",
                                            padding: ".6rem 1rem",
                                            border: '1px solid #004540',
                                            margin: 0,
                                            boxShadow: '2px 2px 10px rgba(0, 0, 0, .16)',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 gap-2 px-4 max-h-[300px] overflow-y-scroll my-4'>
                            {modalFlag === "service" ? (
                                allServices.filter(v => v.name.includes(searchVal)).map((item: ISpecializationItem) => (
                                    <Link
                                        title={item.name}
                                        to={`/doctors-list?specialId=${item._id}/view`}
                                        key={item._id}
                                        className="modal-box-services flex items-center gap-2 pl-2 pr-4 py-2  rounded-[5px] border border-[#707070] relative overflow-hidden">
                                        <div className='size-[45px] flex items-center justify-center text-white theme-bg-yellow  rounded-full'>
                                            <img
                                                width={20}
                                                height={20}
                                                src={`${process.env.REACT_APP_API_BASEURL}${item.SpecailImage}`}
                                                onError={(e) => {
                                                    const target = e.target as HTMLImageElement; // Type assertion
                                                    target.src = emtyImg; // Handle error by setting default image
                                                }}
                                                alt={item.name}
                                            />
                                        </div>
                                        <span className='font-Poppins-Regular font-size-15px capitalize'>{item.name}</span>
                                    </Link>
                                ))
                            ) : (
                                allDisease.filter(v => v.name.includes(searchVal)).map((item: IDiseaseItem) => (
                                    <Link
                                        title={item.name}
                                        to={`/doctors-list?diseasesId=${item._id}/view`}
                                        key={item._id}
                                        className="modal-box-services flex items-center gap-2 pl-2 pr-4 py-2  rounded-[5px] border border-[#707070] relative overflow-hidden">
                                        <div className='size-[45px] flex items-center justify-center text-white theme-bg-yellow rounded-full'>
                                            <img
                                                width={20}
                                                height={20}
                                                src={`${process.env.REACT_APP_API_BASEURL}${item.diseasesImage}`}
                                                onError={(e) => {
                                                    const target = e.target as HTMLImageElement; // Type assertion
                                                    target.src = emtyImg; // Handle error by setting default image
                                                }}
                                                alt={item.name}
                                            />
                                        </div>
                                        <span className='font-Poppins-Regular font-size-15px capitalize'>{item.name}</span>
                                    </Link>
                                ))
                            )}
                        </div>
                    </div>
                </Modal>
            }
            <PageHeader
                title="Find Doctor"
                isButton={true}
                ButtonComponent={BackButton}
            />
            <div className="parent-box">
                <div className="mb-12">
                    <div className="flex items-center justify-between mb-3">
                        <h4 className='font-Poppins-Medium font-size-20px'>Consult best doctors online</h4>
                        <button onClick={() => setModalFlag("service")} className="underline font-Poppins-SemiBold font-size-16px text-[#00443F]">View All</button>
                    </div>
                    {serviceLoader &&
                        <div className="text-center mb-3">
                            <CircularProgress size={20} style={{ color: "#004540" }} />
                        </div>
                    }
                    <div className="grid grid-cols-2 grid-cols-7 gap-8 xl:gap-6">
                        {allServices.slice(0, 7).map(item => (
                            <Link
                                to={`/doctors-list?specialId=${item._id}/view`}
                                key={item._id}
                                className="child__boxes text-center flex justify-start items-center flex-col py-2 gap-2"
                                title={item.name}
                            >
                                <div className="rounded-full theme-bg-yellow size-[65px] flex items-center justify-center">
                                    <img
                                        src={`${process.env.REACT_APP_API_BASEURL}${item.SpecailImage}`}
                                        onError={(e) => {
                                            const target = e.target as HTMLImageElement; // Type assertion
                                            target.src = emtyImg; // Handle error by setting default image
                                        }}
                                        alt={item.name}
                                        className='xl:w-[30px] xl:h-[30px] w-[20px] h-[20px]'

                                    />
                                </div>
                                <p className="font-Poppins-Medium font-size-14px capitalize">{item.name}</p>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="mb-12">
                    <div className="flex items-center justify-between mb-3">
                        <h4 className='font-Poppins-Medium font-size-20px'>Consult best doctors online</h4>
                        <button onClick={() => setModalFlag("disease")} className="underline font-Poppins-SemiBold font-size-16px text-[#00443F]">View All</button>
                    </div>
                    {diseaseLoader &&
                        <div className="text-center mb-3">
                            <CircularProgress size={20} style={{ color: "#004540" }} />
                        </div>
                    }
                    <div className="grid grid-cols-2 grid-cols-7 gap-8 xl:gap-6">
                        {allDisease.slice(0, 7).map(item => (
                            <Link
                                to={`/doctors-list?diseasesId=${item._id}/view`}
                                key={item._id}
                                className="child__boxes text-center flex justify-start items-center flex-col py-2 gap-2"
                                title={item.name}
                            >
                                <div className="rounded-full theme-bg-yellow size-[65px] flex items-center justify-center">
                                    <img
                                        src={`${process.env.REACT_APP_API_BASEURL}${item.diseasesImage}`}
                                        onError={(e) => {
                                            const target = e.target as HTMLImageElement; // Type assertion
                                            target.src = emtyImg; // Handle error by setting default image
                                        }}
                                        alt={item.name}
                                        className='xl:w-[30px] xl:h-[30px] w-[20px] h-[20px]'

                                    />
                                </div>
                                <p className="font-Poppins-Medium font-size-14px capitalize">{item.name}</p>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

// Style object for the modal
const customeStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(255, 255, 255)",
    maxWidth: 600,
    width: "100%",
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: 8
};

// Style for the close button container
const backBtnMaain: React.CSSProperties = {
    position: "absolute",
    top: -10,
    right: -10,
    backgroundColor: "red",
    borderRadius: 100,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

// Style for the close button text
const backBtn: React.CSSProperties = {
    color: "white",
};

export default FindDoctors;