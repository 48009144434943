/**
 * Roles Component
 * 
 * Description: This component displays and manages roles within the application. It allows for creating, editing, and deleting roles.
 * It includes a table view of existing roles with permissions, and integrates a confirmation modal for deletions.
 * 
 * Date: 24-07-2024
 */

import React, { useEffect, useState } from 'react';
import TableWrapper from '../../Components/GlobalComponents/TableWrapper';
import BreadCrumb from '../../Components/GlobalComponents/BreadCrumb';
import UpsertRole from '../../Components/AdminComponents/UpsertRole'
import httpRequest from '../../helpers/httpRequest';
import { toast } from 'react-toastify';
import moment from 'moment';
import ConfirmationModal from '../../Components/GlobalComponents/ConfirmationModal';
import editIcon from '../../assets/images/edit-icon.png';
import deleteIcon from '../../assets/images/delete-icon.png';
import { IqueryParams, IRoleItems } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';

interface IRoleResponse {
  documents: IRoleItems[];
  paginated: IPaginated;
};

const Roles: React.FC = () => {
  const [isUpsert, setIsUpsert] = useState<boolean>(false); // State to manage the upsert mode (create/edit role)
  const [isEdit, setIsEdit] = useState<IRoleItems | boolean>(false); // State to determine if the component is in edit mode

  const [deleteLoader, setDeleteLoader] = useState<boolean>(false); // State to manage the loader during role deletion
  const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage the loader for fetching roles
  const [allRoles, setAllRoles] = useState<IRoleItems[]>([]); // State to store the list of roles
  const [paginated, setPaginated] = useState<IPaginated>({
    currentPage: 1,
    totalPages: 1,
  }); // Handles pagination
  const [queryParams, setQueryParams] = useState<IqueryParams>({
    page: 1,
    limit: 10,
    search: "",
    bool: false,
  });
  const [deleteModal, setDeleteModal] = useState<boolean>(false); // State to control the visibility of the delete confirmation modal
  const [deleteId, setDeleteId] = useState<string>("");  // State to store the ID of the role to be deleted

  // Function to format permission values for display
  const formatPermission = (permission: string) => {
    return permission === "null" ? "No Access" : permission;
  };

  // Function to close the delete confirmation modal
  const deleteModalClose = () => {
    setDeleteModal(false);
    setDeleteId("");
  };

  // Function to toggle between create and edit mode
  const handleClose = () => {
    setIsEdit(false);
    setIsUpsert(prev => !prev);
  };

  // Function to set the component to edit mode and populate it with role data
  const handleEdit = (obj: IRoleItems) => {
    setIsUpsert(true);
    setIsEdit(obj);
  };

  // Function to show the delete confirmation modal and set the ID of the role to be deleted
  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setDeleteId(id);
  };

  // Handles page change in pagination
  const handlePageChange = (e: number) => {
    setQueryParams(prev => ({
      ...prev,
      page: e
    }));
  };

  // Updates search text in query parameters
  const handleChangeText = (txt: string) => {
    setQueryParams(prev => ({
      ...prev,
      search: txt,
      page: 1
    }));
  };

  // Function to update the list of roles after creating or editing a role
  const handleUpdateList = () => {
    setQueryParams(prev => ({
      ...prev,
      bool: !prev.bool,
    }));
    setIsUpsert(false);
  };

  // API_CALL: Function to delete a role by its ID
  const delteRole = async () => {
    setDeleteLoader(true);
    const { res, err } = await httpRequest<any>({ method: "delete", path: `/roles/delete/${deleteId}`, params: {} });
    if (res) {
      toast("Role deleted successfully.", { type: 'success' });
      setQueryParams(prev => ({
        ...prev,
        bool: !prev.bool,
        page: allRoles?.length > 1 ? prev?.page : prev?.page > 1 ? prev?.page - 1 : prev?.page
      }));
      setDeleteModal(false);
      setDeleteId("");
    } else {
      toast(err, { type: 'error' });
    }
    setDeleteLoader(false);
  };

  // Fetch the list of roles when the component mounts
  useEffect(() => {
    (async () => {
      setIsLoader(true);
      const { bool, ...remainingParams } = queryParams;
      const { res, err } = await httpRequest<IRoleResponse>({ path: `/roles/all?${qs.stringify(remainingParams)}` });
      if (res) {
        let { documents = [], paginated } = res;
        setAllRoles(documents);
        setPaginated(paginated);
      } else {
        toast(err, { type: 'error' });
      }
      setIsLoader(false);
    })();
  }, [queryParams]);

  return (
    <>
      <ConfirmationModal
        show={deleteModal}
        onClose={deleteModalClose}
        title="Are you sure you want to delete?"
        leftBtnText="No"
        rightBtnText="Yes"
        onConfirm={delteRole}
        loader={deleteLoader}
      />

      <BreadCrumb
        current="roles"
        previous="dashboard"
        navigateUrl="/admin"
        btnText={"Create Role"}
        onPress={handleClose}
        btnShow={true}
        isUpsert={isUpsert}
      />
      {isUpsert
        ?
        <UpsertRole
          onSuccess={handleUpdateList}
          isEdit={isEdit}
        />
        :
        <div className="table-container">
          <SearchFilterInput
            onChangeText={handleChangeText}
          />
          <TableWrapper
            isLoading={isLoader}
            page={paginated?.currentPage || 1}
            total={paginated?.totalPages || 0}
            thead={["S.No", "Role", "Permission", "Created date", "Action"]}
            onChange={handlePageChange}
            columnWidth="6% 15% 54% 15% 10%"
          >
            <div className='tbody-content-height'>
              {allRoles?.map((item, index) => (
                <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 15% 54% 15% 10%" }}>
                  <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                  <li className="capitalize">{item?.roleName || ""}</li>
                  <li>{`Biomarker: ${formatPermission(item?.biomarker)}, Product: ${formatPermission(item?.product)}, Order: ${formatPermission(item?.order)}`}</li>
                  <li>{moment(item?.createdAt).format("DD-MM-YYYY")}</li>
                  <li className='action-last-colum'>
                    <img onClick={() => handleEdit(item)} src={editIcon} alt="" />
                    <img onClick={() => handleDelete(item?._id)} src={deleteIcon} alt="" />
                  </li>
                </ul>
              ))}
            </div>
          </TableWrapper>
        </div>
      }
    </>
  );
};

export default Roles;