/**
 * HistoryGraph
 * 
 * Description: A component that renders a history graph using D3.js. The graph
 * displays bars and lines based on the provided data, optimal and reference values,
 * and category. It also includes tooltips for data points and dynamic color bands
 * for different categories.
 * 
 * File Name: historyGraph.js
 * Date: 21-08-2024
 */

import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import './historyGraph.css';
import { barColors, barColorsLight, fiveBarMarker, fourBarMarkerAsc, fourBarMarkerDesc, threeBarMarkerAsc, threeBarMarkerCentralized, threeBarMarkerDesc, twoBarDNDAsc, twoBarDNDDesc, twoBarMarkerAsc, twoBarMarkerDesc, twoBarPNAsc, twoBarPNDesc } from 'src/lib/constans';

type DataPoint = {
    date: string;
    value: number;
};

type referenceAndOptimal = number[];

interface IGraphProps {
    reference: referenceAndOptimal; // Tuple with exactly 2 numbers
    optimal: referenceAndOptimal;   // Tuple with exactly 2 numbers
    category: string;
    data: DataPoint[];
    [key: string]: any;
};

const HistoryGraph: React.FC<IGraphProps> = ({
    data = [],
    optimal = [0, 0],
    reference = [0, 0],
    category = ""
}) => {

    const svgRef = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        // ******************** Select the SVG element and set margins ******************** //
        const svg = d3.select(svgRef.current);
        const margin = { top: 0, right: 0, bottom: 20, left: 30 };
        // const width = 450 - margin.left - margin.right;
        const svgElement = svgRef.current;
        const width = svgElement ? svgElement.clientWidth - margin.left - margin.right : 400 - margin.left - margin.right;


        const height = 250 - margin.top - margin.bottom;

        // ******************** Calculate dynamic y-axis domain based on data ******************** //
        const minResult = d3.min(data, (d: DataPoint) => d.value) || 0;
        const maxResult = d3.max(data, (d: DataPoint) => d.value) || 0;

        let minValue;
        let maxValue;

        // Determine the value range based on category
        if (category === twoBarDNDAsc || category === twoBarDNDDesc || category === twoBarPNAsc || category === twoBarPNDesc) {
            minValue = 0;
            maxValue = 100;
        } else {
            minValue = minResult < reference[0] ? 0 : reference[0] - 10;
            maxValue = maxResult > reference[1] ? maxResult : reference[1] + 10;
        };

        const yDomain = [minValue, maxValue];

        // Define scales for x and y axes
        const x = d3.scaleBand().domain(data.map(d => d.date)).range([0, width]).padding(0.1);
        const y = d3.scaleLinear().domain(yDomain).nice().range([height, 0]);

        svg.selectAll('*').remove(); // Clear the previous SVG content

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // ******************** Define color bands for different categories ******************** //
        const fiveBarBands = [
            { y1: minValue, y2: reference[0], color: barColorsLight.red },
            { y1: reference[0], y2: optimal[0], color: barColorsLight.orange },
            { y1: optimal[0], y2: optimal[1], color: barColorsLight.green },
            { y1: optimal[1], y2: reference[1], color: barColorsLight.orange },
            { y1: reference[1], y2: maxValue, color: barColorsLight.red },
        ];

        const fourBarAscBands = [
            { y1: minValue, y2: reference[0], color: barColorsLight.red },
            { y1: reference[0], y2: optimal[0], color: barColorsLight.orange },
            { y1: optimal[0], y2: optimal[1], color: barColorsLight.yellow },
            { y1: optimal[1], y2: maxValue, color: barColorsLight.green },
        ];

        const fourBarDescBands = [
            { y1: minValue, y2: optimal[0], color: barColorsLight.green },
            { y1: optimal[0], y2: optimal[1], color: barColorsLight.yellow },
            { y1: optimal[1], y2: reference[1], color: barColorsLight.orange },
            { y1: reference[1], y2: maxValue, color: barColorsLight.red },
        ];

        const threeBarCenter = [
            { y1: minValue, y2: optimal[0], color: barColorsLight.red },
            { y1: optimal[0], y2: optimal[1], color: barColorsLight.green },
            { y1: optimal[1], y2: maxValue, color: barColorsLight.red },
        ];

        const threeBarAsc = [
            { y1: minValue, y2: reference[0], color: barColorsLight.red },
            { y1: reference[0], y2: reference[1], color: barColorsLight.orange },
            { y1: reference[1], y2: maxValue, color: barColorsLight.green },
        ];

        const threeBarDesc = [
            { y1: minValue, y2: optimal[1], color: barColorsLight.green },
            { y1: optimal[1], y2: reference[1], color: barColorsLight.orange },
            { y1: reference[1], y2: maxValue, color: barColorsLight.red },
        ];

        const twoBarAsc = [
            { y1: minValue, y2: optimal[0], color: barColorsLight.red },
            { y1: optimal[0], y2: maxValue, color: barColorsLight.green },
        ];

        const twoBarDesc = [
            { y1: minValue, y2: optimal[1], color: barColorsLight.green },
            { y1: optimal[1], y2: maxValue, color: barColorsLight.red },
        ];

        const twoBarRedGreen = [
            { y1: 0, y2: 50, color: barColorsLight.red },
            { y1: 50, y2: 100, color: barColorsLight.green },
        ];

        const twoBarGreenRed = [
            { y1: 0, y2: 50, color: barColorsLight.green },
            { y1: 50, y2: 100, color: barColorsLight.red },
        ];

        // Choose the correct bands configuration based on the category
        const bands =
            category === fiveBarMarker ? fiveBarBands
                : category === fourBarMarkerAsc ? fourBarAscBands
                    : category === fourBarMarkerDesc ? fourBarDescBands
                        : category === threeBarMarkerCentralized ? threeBarCenter
                            : category === threeBarMarkerAsc ? threeBarAsc
                                : category === threeBarMarkerDesc ? threeBarDesc
                                    : category === twoBarMarkerAsc ? twoBarAsc
                                        : category === twoBarMarkerDesc ? twoBarDesc
                                            : category === twoBarDNDAsc ? twoBarRedGreen
                                                : category === twoBarDNDDesc ? twoBarGreenRed
                                                    : category === twoBarPNAsc ? twoBarRedGreen
                                                        : category === twoBarPNDesc ? twoBarGreenRed
                                                            : [];

        // Append color bands to the chart
        bands.forEach(band => {
            chart.append('rect')
                .attr('class', 'band')
                .attr('x', 0)
                .attr('y', y(band.y2))
                .attr('width', width)
                .attr('height', y(band.y1) - y(band.y2))
                .attr('fill', band.color)
                .lower(); // Ensure background bands are behind other elements
        });

        // Add borders around the bands
        bands.forEach(band => {
            chart.append('rect')
                .attr('class', 'border')
                .attr('x', -12) // Move the border to the left side of the chart
                .attr('y', y(band.y2))
                .attr('width', 12) // Width of the border
                .attr('height', y(band.y1) - y(band.y2))
                .attr('fill', getBorderColor(band.color));
        });

        // Adding axes
        const xAxis = chart.append('g')
            .attr('class', 'axis x')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(x));
        xAxis.selectAll('.domain').remove(); // Remove the line
        xAxis.selectAll('.tick line').remove(); // Remove tick lines

        const yAxis = chart.append('g')
            .attr('class', 'axis y')
            .call(d3.axisLeft(y)
                .tickValues(
                    (category === twoBarDNDAsc || category === twoBarDNDDesc || category === twoBarPNAsc || category === twoBarPNDesc)
                        ?
                        []
                        :
                        [...reference, ...optimal]
                )  // Set custom tick values
            );

        // Hide y-axis line
        yAxis.selectAll('.domain').remove(); // Remove the line
        yAxis.selectAll('.tick line').remove(); // Remove tick lines

        // Adjust y-axis tick labels
        yAxis.selectAll('.tick text')
            .attr('x', -15) // Move tick labels to the left
            .attr('dy', '.32em'); // Adjust vertical alignment


        // Adding line
        const line = d3.line<DataPoint>()
            .x((d: DataPoint) => {
                const xValue = x(d.date);
                return xValue !== undefined ? xValue + x.bandwidth() / 2 : 0; // Ensure a valid number is returned
            })
            .y((d: DataPoint) => y(
                (category === twoBarDNDAsc || category === twoBarPNAsc || category === twoBarDNDDesc || category === twoBarPNDesc)
                    ?
                    d.value === 1 ? 75 : 25
                    :
                    d.value
            ));

        data.forEach((_: any, i: number) => {
            if (i < data.length - 1) {
                const endColor = dotAndLineColor(data[i + 1]);

                chart.append('path')
                    .datum([data[i], data[i + 1]])
                    .attr('class', 'line')
                    .attr('d', line)
                    .attr('fill', 'none')
                    .attr('stroke', endColor)
                    .attr('stroke-width', 2)
                    .attr('stroke-linecap', 'round');
            }
        });


        // Adding data points with tooltip
        const tooltip = d3.select('#tooltip');

        // Adding data points
        chart.selectAll('.dot')
            .data(data)
            .enter().append('circle')
            .attr('class', 'dot') // Add unique class based on index
            .attr('cx', (d: DataPoint) => {
                const xValue = x(d.date);
                return xValue !== undefined ? xValue + x.bandwidth() / 2 : 0; // Handle undefined xValue
            })
            // .attr('cy', (d: DataPoint) => y(d.value))
            .attr('cy', (d: DataPoint) => y(
                (category === twoBarDNDAsc || category === twoBarPNAsc || category === twoBarDNDDesc || category === twoBarPNDesc)
                    ?
                    d.value === 1 ? 75 : 25
                    :
                    d.value
            ))
            .attr('r', 7)
            .attr('fill', (d: DataPoint) => dotAndLineColor(d))
            .on('mouseover', function (event: MouseEvent, d: DataPoint) {
                const fillColor = d3.select(this).style('fill');
                tooltip
                    .style('display', 'block')
                    .html(`Date: ${d.date}<br>Value: ${d.value}`)
                    .style('left', `${event.pageX + 5}px`)
                    .style('top', `${event.pageY - 28}px`)
                    .style("background-color", fillColor)
                    .style("color", "#fff");
            })
            .on('mousemove', (event: MouseEvent): void => {
                tooltip
                    .style('left', `${event.pageX + 5}px`)
                    .style('top', `${event.pageY - 28}px`);
            })
            .on('mouseout', (): void => {
                tooltip.style('display', 'none');
            });

    }, [data, optimal, reference]);

    const dotAndLineColor = (d: DataPoint) => {
        if (category === fiveBarMarker) {
            if (d.value > reference[1] || d.value < reference[0]) return barColors.red;
            else if (d.value >= optimal[0] && d.value <= optimal[1]) return barColors.green;
            else return barColors.orange;
        } else if (category === fourBarMarkerAsc) {
            if (d.value < reference[0]) return barColors.red;
            else if (d.value > reference[0] && d.value < optimal[0]) return barColors.orange;
            else if (d.value > optimal[0] && d.value < optimal[1]) return barColors.yellow;
            else return barColors.green;
        } else if (category === fourBarMarkerDesc) {
            if (d.value < optimal[0]) return barColors.green;
            else if (d.value >= optimal[0] && d.value <= optimal[1]) return barColors.yellow;
            else if (d.value > optimal[1] && d.value <= reference[1]) return barColors.orange;
            else return barColors.red;
        } else if (category === threeBarMarkerCentralized) {
            if (d.value > optimal[1] || d.value < optimal[0]) return barColors.red;
            else return barColors.green;
        } else if (category === threeBarMarkerAsc) {
            if (d.value < reference[0]) return barColors.red;
            else if (d.value >= reference[0] && d.value <= reference[1]) return barColors.orange;
            else return barColors.green;
        } else if (category === threeBarMarkerDesc) {
            if (d.value > reference[1]) return barColors.red;
            else if (d.value <= reference[1] && d.value >= reference[0]) return barColors.orange;
            else if (d.value < reference[0] && d.value >= optimal[0]) return barColors.green;
            else return "#000";
        } else if (category === twoBarMarkerAsc) {
            if (d.value >= reference[0] && d.value <= reference[1]) return barColors.green;
            else return barColors.red;
        } else if (category === twoBarMarkerDesc) {
            if (d.value > reference[1]) return barColors.red;
            else return barColors.green;
        } else if (category === twoBarDNDAsc || category === twoBarPNAsc) {
            return d.value === 1 ? barColors.green : barColors.red
        } else if (category === twoBarDNDDesc || category === twoBarPNDesc) {
            return d.value === 1 ? barColors.red : barColors.green
        }
        else { return "#000"; }
    };

    // Helper function to get the border color based on the background color
    const getBorderColor = (color: string) => {
        switch (color) {
            case barColorsLight.red: return barColors.red; // Dark red for light red band
            case barColorsLight.orange: return barColors.orange; // Dark orange for light orange band
            case barColorsLight.green: return barColors.green; // Dark green for light green band
            case barColorsLight.yellow: return barColors.yellow; // Dark yellow for light yellow band
            default: return 'black'; // Default color if not matched
        }
    };

    return (
        <>
            <svg ref={svgRef} width="100%" height="250"></svg>
            <div id="tooltip"></div>
        </>
    );
};

export default HistoryGraph;