/**
 * Doctors Component
 * 
 * Description: This component displays a list of doctors with functionalities to filter, approve, decline, and view details.
 * 
 * File Name: Doctors.tsx
 * Date: [Current Date]
 */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import ToggleRadioBtn from 'src/Components/ToggleRadioBtn';
import { IDoctorItems, IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import eyeIcon from "src/assets/images/view.png";
import viewTest from "src/assets/images/view-test.png";
import addTest from "src/assets/images/all-test.png";
import httpRequest from 'src/helpers/httpRequest';
import qs from "qs";
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import DeclineModalReason from 'src/Components/AdminComponents/DeclineModalReason';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';

interface IDoctorResponse {
    documents: IDoctorItems[];
    paginated: IPaginated;
};

const Doctors: React.FC = () => {
    // Style for the declined button
    const redBtn: React.CSSProperties = {
        padding: "4px 12px",
        border: "1px solid red",
        color: "red",
        borderRadius: 4
    };

    const greenBtn: React.CSSProperties = {
        padding: "4px 12px",
        border: "1px solid #00443f",
        color: "#00443f",
        borderRadius: 4,
        minWidth: 60
    };

    // List of tab names for filtering orders
    const tabList: string[] = ["approved", "pending"];

    // loader states
    const [approvedId, setApprovedId] = useState<string>("");
    const [statusId, setStatusId] = useState<string>("");
    const [isLoader, setIsLoader] = useState<boolean>(false);
    // loader states

    // decline with reason
    const [declineId, setDeclineId] = useState<string>("");
    const [declineLoader, setDeclineLoader] = useState<boolean>(Boolean);
    const [message, setMessage] = useState<string>("");
    // decline with reason

    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [allDoctors, setAllDoctors] = useState<IDoctorItems[]>([]); // Stores the list of doctors
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        status: "approved",
        search: "",
        count: false,
    }); // Query parameters for API requests

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            search: txt,
            page: 1
        }));
    };

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Handles tab click to filter doctors
    const handleTabClick = (tabName: string) => {
        setAllDoctors([]);
        setQueryParams(prev => ({
            ...prev,
            status: tabName,
            page: 1,
        }));
    };

    // Closes the decline modal
    const closeModal = () => {
        setDeclineId("");
        setMessage("");
    };

    /**
     * Handles the decline action with reason
     * 
     * API_CALL: POST /doctors/decline/${declineId}
     */
    const handleDecline = async () => {
        if (!message) {
            toast("Please enter reason!", { type: 'error' });
            return
        }
        setDeclineLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({ method: "post", path: `/doctors/decline/${declineId}`, params: { message } });
        if (res) {
            toast(res.message, { type: 'success' });
            setQueryParams(prev => ({ ...prev, count: !prev.count }));
            closeModal();
        } else {
            toast(err, { type: 'error' });
        }
        setDeclineLoader(false);
    };

    /**
    * Toggles the status of a doctor account
    * 
    * API_CALL: POST /doctors/account/status/${id}
    */
    const handleStatusToogle = async (id: string, status: boolean, message?: string) => {
        if (!status && !message) {
            toast("Please enter reason!", { type: 'error' });
            return
        }
        setStatusId(id);
        if (message) setDeclineLoader(true);
        // Build the params object conditionally
        const params: { status: boolean; message?: string } = { status };
        if (message) {
            params.message = message;
        }
        const { res, err } = await httpRequest<{ message: string; data: boolean }>({
            method: "post",
            path: `/doctors/account/status/${id}`,
            params,
        });
        if (res) {
            toast(res.message, { type: 'success' });
            setAllDoctors(prev => prev.map(v => v._id === id ? { ...v, accountActive: res.data } : v));
            closeModal();
        } else {
            toast(err, { type: 'error' });
        }
        setStatusId("");
        if (message) setDeclineLoader(false);
    };

    /**
    * Handles the approval of a doctor
    * 
    * API_CALL: POST /doctors/update-status/${id}
    */
    const handleApproved = async (id: string) => {
        setApprovedId(id);
        const { res, err } = await httpRequest<{ message: string }>({ method: "post", path: `/doctors/update-status/${id}`, params: {} });
        if (res) {
            toast(res.message, { type: 'success' });
            handleTabClick("approved");
        } else {
            toast(err, { type: 'error' });
        }
        setApprovedId("");
    };

    /**
     * Fetches the list of doctors
     * 
     * API_CALL: GET /doctors
     */
    const fetchDoctors = async () => {
        setIsLoader(true);
        const { count, ...apiParams } = queryParams;
        const { res, err } = await httpRequest<IDoctorResponse>({ path: `/doctors?${qs.stringify(apiParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setAllDoctors(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchDoctors();
    }, [queryParams]);

    const BackButton: React.FC = () => {
        return (
            <div className='mt-2 flex justify-center'>
                <PrimaryBtn
                    onPress={() => queryParams.status === "approved" ? handleStatusToogle(declineId, false, message) : handleDecline()}
                    type="button"
                    loader={declineLoader}
                    ButtonText='Send' btnExtraStyle={{
                        borderRadius: "100rem",
                        backgroundColor: "#004540"
                    }}
                />
            </div>
        );
    }; // display back icon in page header

    return (
        <>
            <BreadCrumb
                current="Doctors"
                previous="dashboard"
                navigateUrl="/admin"
                btnShow={false}
            />
            <div className="all-doctors">
                <DeclineModalReason
                    title={`Please specify the reason of Decline`}
                    value={message}
                    setValue={setMessage}
                    isOpen={declineId ? true : false}
                    onClose={closeModal}
                    ButtonComponent={BackButton}
                />
                <div className="table-container">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <div className='products-tabs-btn'>
                            {tabList.map((v: string) => (
                                <button
                                    key={v}
                                    className={`font-size-15px font-Poppins-Medium ${queryParams.status === v && 'active'}`}
                                    onClick={() => handleTabClick(v)}
                                >
                                    {
                                        v === "approved" ? `Approved Doctors` :
                                            v === "pending" ? `Pending Doctors` :
                                                ""
                                    }
                                </button>
                            ))}
                        </div>
                        <SearchFilterInput
                            onChangeText={handleChangeText}
                            inputExtraStyle={{ marginBottom: 0, width: 240 }}
                        />
                    </div>
                    <hr className='products-tabs-hr products-tabs-hr-allOrder' style={{ border: '1px solid #D4D4D4' }} />
                    <TableWrapper
                        isLoading={isLoader}
                        page={paginated?.currentPage || 1}
                        total={paginated?.totalPages || 0}
                        thead={["S.No", "Doctor Name", "Email", "License", queryParams.status === "approved" ? "Status" : "Date", queryParams.status === "approved" ? "Health Profile" : "Action"]}
                        onChange={handlePageChange}
                        columnWidth={"6% 18% 25% 18% 13% 20%"}
                    >
                        <div className='tbody-content-height'>
                            {allDoctors?.map((item: IDoctorItems, index: number) => (
                                <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 18% 25% 18% 13% 20%" }}>
                                    <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                    <li className="capitalize">
                                        <Link to={`/doctor/${item._id}/View`} className="underline">
                                            {`${item.firstName} ${item.lastName}`}
                                        </Link>
                                    </li>
                                    <li>{item.email}</li>
                                    <li>{item.license}</li>
                                    {
                                        queryParams.status === "approved"
                                            ?
                                            <li className='justify-center' style={{ justifyContent: 'center', paddingLeft: 0, }}>
                                                {
                                                    // statusId === item._id &&
                                                    //     // ?
                                                    //     // <CircularProgress size={16} style={{ color: "#00443f" }} />
                                                    //     // :
                                                    <ToggleRadioBtn
                                                        isToggled={item.accountActive}
                                                        handleToggle={
                                                            () => {
                                                                item.accountActive
                                                                    ?
                                                                    setDeclineId(item._id)
                                                                    :
                                                                    handleStatusToogle(item._id, true)
                                                            }
                                                        }
                                                        loader={declineLoader}
                                                    />
                                                }
                                            </li>
                                            :
                                            <li>
                                                {moment(item.createdAt).format("DD-MM-YYYY")}
                                            </li>
                                    }
                                    <li className="justify-center gap-2">
                                        {
                                            queryParams.status === "approved"
                                                ?
                                                <>
                                                    <Link to={`/health-profile/${item._id}/View?isdoctor=true&name=${`${item.firstName} ${item.lastName}`}`}>
                                                        <img src={eyeIcon} alt="eye" width="18" />
                                                    </Link>
                                                    <Link to={`/view/${item._id}/all-test?isdoctor=true`}>
                                                        <img src={viewTest} alt="view-test" width="18" />
                                                    </Link>
                                                    <Link to={`/add-result/${item._id}/View?isdoctor=true`}>
                                                        <img src={addTest} alt="add-test" width="18" />
                                                    </Link>
                                                </>
                                                :
                                                <>
                                                    <button
                                                        className="font-size-16px font-Poppins-SemiBold flex items-center gap-2 justify-center"
                                                        style={greenBtn}
                                                        onClick={() => handleApproved(item._id)}
                                                    >
                                                        {
                                                            approvedId === item._id ? <CircularProgress size={14} style={{ color: "#00443f" }} />
                                                                : "Approve"
                                                        }
                                                    </button>
                                                    <button
                                                        className="font-size-16px font-Poppins-SemiBold"
                                                        style={redBtn}
                                                        onClick={() => setDeclineId(item._id)}
                                                    >
                                                        Decline
                                                    </button>
                                                </>
                                        }
                                    </li>
                                </ul>
                            ))}
                        </div>
                    </TableWrapper>
                </div>
            </div>
        </>
    );
};

export default Doctors;