/**
 * CategoreisTab Component
 *
 * Description: This component represents the tab for selecting categories related to biomarkers for a product. 
 * It provides a dropdown to select multiple categories and fetches available categories from the API. 
 * The component also includes a loading state and a "Next" button to navigate to the next tab after selecting categories.
 *
 * Props:
 * - state (IProductItems): The current product state, which includes the list of selected category IDs.
 * - setState (function): Function to update the product state, particularly the biomarker category IDs.
 * - tabChange (function): Callback function invoked to change the active tab after completing the category selection.
 *
 * File Name: CategoreisTab.tsx
 * Date: 31-07-2024
 * 
 * Marker: DOC_START
 */

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import httpRequest from 'src/helpers/httpRequest';
import { IDropDownArray, IProductItems } from 'src/types/GlobalInterfaces';

const CategoreisTab: React.FC<{
    state: IProductItems; setState: React.Dispatch<React.SetStateAction<IProductItems>>; tabChange: () => void
}> = ({
    state,
    setState,
    tabChange
}) => {

        const [isLoader, setIsLoader] = useState<boolean>(false); // State to handle loading status
        const [allCategories, setAllCategories] = useState<IDropDownArray[]>([]); // State to store the list of categories for the dropdown

        //API_CALL: Function to fetch all categories from the API
        const fetAllCategories = async () => {
            setIsLoader(true);
            const { res, err } = await httpRequest<{ name: string; _id: string }[]>({ path: `/category` });
            if (res) {
                // Map the response to dropdown options
                setAllCategories(res?.map(v => ({ label: v?.name, value: v?._id })));
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        };

        // Fetch categories when the component mounts
        useEffect(() => {
            fetAllCategories();
        }, []);

        return (
            <>
                <div className='admin-create-role admin-categoreis-height'>
                    <SelectDropdown
                        loader={isLoader}
                        options={allCategories}
                        isMulti={true}
                        data={allCategories?.filter(v => state?.biomarkerCategoriesId?.includes(v.value))}
                        setData={(e) => setState(prev => ({ ...prev, biomarkerCategoriesId: e?.map(v => v?.value) }))}
                        checkboxShow={true}
                        isLabel={true}
                        labelText="Categoreis"
                        isRequired={true}
                    />
                </div>
                <div className='flex justify-end my-6'>
                    <PrimaryBtn
                        ButtonText="Next"
                        btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        onPress={tabChange}
                    />
                </div>
            </>
        );
    };

export default CategoreisTab;