/**
 * ResultInputs Component
 * 
 * Description: A form component to display result input fields based on provided thresholds and category.
 * It conditionally renders a line graph or a set of radio buttons based on the category type.
 * 
 * File Name: ResultInputs.tsx
 * Date: 20-08-2024
 */

import React from 'react';
import { IMaleFemaleThresholds } from 'src/types/GlobalInterfaces';
import { descriptiveResult, twoBarDNDAsc, twoBarDNDDesc, twoBarPNAsc, twoBarPNDesc } from "src/lib/constans";

import moment from 'moment';
import SimpleLineGraph from '../GlobalComponents/Graphs/SimpleLineGraph';
import { ageCategory } from 'src/helpers/helperFunctions';

interface IProps {
    dob: string;
    gender: string;
    data: {
        name: string;
        shortDescription: string;
        barCategoryId: string;
        _id: string;
        maleThresholds?: IMaleFemaleThresholds;
        femaleThresholds?: IMaleFemaleThresholds;
        isShown: boolean;
        [key: string]: any;
    };
    result: string;
    onChange: (id: string, value: string) => void;
    uniqueKey: number;
    [key: string]: any;
};

const ResultInputs: React.FC<IProps> = ({ data, dob = "", gender = "", result, onChange = () => { }, uniqueKey }) => {
    // Calculate the user's age based on date of birth
    const age = moment().diff(moment(dob), 'years');
    // Determine the age group for selecting reference and optional values
    const ageGroup = ageCategory(age);

    // Get the reference and optional values based on gender and age group
    const refValues = data[`${gender}Thresholds`]['ref'][ageGroup];
    const opValues = data[`${gender}Thresholds`]['op'][ageGroup];

    return (
        <>
            {/* Conditionally render the SimpleLineGraph if not a descriptive result */}
            {
                data.barCategoryId !== descriptiveResult
                &&
                <SimpleLineGraph
                    refMin={refValues[0]}
                    refMax={refValues[1]}
                    optMin={opValues[0]}
                    optMax={opValues[1]}
                    result={Number(result)}
                    category={data.barCategoryId}
                    size={"lg"}
                />
            }
            {/* Conditionally render the input fields if 'isShown' is true */}
            {
                data.isShown
                &&
                <div className='ref-min-main-wrapper'>
                    {/* ref min */}
                    <div className="ref-min-container">
                        <button type="button" className="ref-min-button font-size-14px font-Poppins-Regular">Ref.Min &lt;</button>
                        <input
                            disabled
                            type="text"
                            value={refValues[0] || ""}
                            className="ref-min-input font-size-14px"
                            placeholder="30" />
                    </div>
                    {/* ref max */}
                    <div className="ref-min-container">
                        <button type="button" className="ref-min-button font-size-14px font-Poppins-Regular">Ref.Max &lt;</button>
                        <input
                            disabled
                            value={refValues[1] || ""}
                            type="text"
                            className="ref-min-input font-size-14px "
                            placeholder="30" />
                    </div>
                    {/* op min */}
                    <div className="ref-min-container">
                        <button type="button" className="ref-min-button font-size-14px font-Poppins-Regular">Opt.Min &lt;</button>
                        <input
                            disabled
                            type="text"
                            value={opValues[0] || ""}
                            className="ref-min-input font-size-14px "
                            placeholder="30" />
                    </div>
                    {/* op max */}
                    <div className="ref-min-container">
                        <button type="button" className="ref-min-button font-size-14px font-Poppins-Regular">Opt.Max &lt;</button>
                        <input
                            disabled
                            value={opValues[1] || ""}
                            type="text"
                            className="ref-min-input font-size-14px "
                            placeholder="30" />
                    </div>
                </div>
            }
            {/* Conditionally render result input field based on barCategoryId */}
            <div className='flex justify-center'>
                <div className="ref-min-container">
                    <button type="button" className="ref-min-button font-size-14px font-Poppins-Regular">Result1111 &lt;</button>
                    {
                        (data.barCategoryId === twoBarDNDAsc || data.barCategoryId === twoBarDNDDesc)
                            ?
                            <>
                                <div className="flex items-center">
                                    <input
                                        id={`detected${uniqueKey}`}
                                        type="radio"
                                        name={`dndresult${uniqueKey}`}
                                        className="w-4 h-4"
                                        onChange={(e) => onChange(data?._id, e.target.checked ? String(1) : String(0))}
                                        required
                                    />
                                    <label htmlFor={`detected${uniqueKey}`} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Detected</label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        id={`not-detected${uniqueKey}`}
                                        type="radio"
                                        name={`dndresult${uniqueKey}`}
                                        className="w-4 h-4"
                                        onChange={(e) => onChange(data?._id, e.target.checked ? String(0) : String(1))}
                                        required
                                    />
                                    <label htmlFor={`not-detected${uniqueKey}`} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Not Detected</label>
                                </div>
                            </>
                            : (data.barCategoryId === twoBarPNAsc || data.barCategoryId === twoBarPNDesc)
                                ?
                                <>
                                    <div className="flex items-center">
                                        <input
                                            id={`positive${uniqueKey}`}
                                            type="radio"
                                            name={`pnnresult${uniqueKey}`}
                                            className="w-4 h-4"
                                            onChange={(e) => onChange(data?._id, e.target.checked ? String(1) : String(0))}
                                            required
                                        />
                                        <label htmlFor={`positive${uniqueKey}`} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Positive</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            id={`negative${uniqueKey}`}
                                            type="radio"
                                            name={`pnnresult${uniqueKey}`}
                                            className="w-4 h-4"
                                            onChange={(e) => onChange(data?._id, e.target.checked ? String(0) : String(1))}
                                            required
                                        />
                                        <label htmlFor={`negative${uniqueKey}`} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Negative</label>
                                    </div>
                                </>
                                :
                                <input
                                    type={data.barCategoryId === descriptiveResult ? "text" : "number"}
                                    inputMode={data.barCategoryId === descriptiveResult ? "text" : "numeric"}
                                    value={result}
                                    required
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (data.barCategoryId === descriptiveResult) {
                                            onChange(data?._id, value);
                                        } else {
                                            if (value === "" || /^\d+(\.\d{0,2})?$/.test(value)) {
                                                onChange(data?._id, value);
                                            }
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        // Prevent entering "e", "-", "+", or multiple "."
                                        if (["e", "E", "-", "+"].includes(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    className="ref-min-input font-size-14px"
                                />
                    }
                </div>
            </div>
        </>
    );
};

export default ResultInputs;