/**
 * DiseaseEdit Component
 * 
 * Description: This React component allows doctors to manage and update the diseases they handle. 
 * It provides a form where users can select multiple diseases from a dropdown, view their selected 
 * diseases as removable chips, and submit the changes to the server. The component fetches the 
 * available diseases from the server and initializes the selected diseases based on the user's profile.
 * 
 * File Name: DiseaseEdit.tsx
 * Date: 02-09-2024
 */

import React, { FormEvent, useEffect, useState } from 'react';
import { RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PageHeader from 'src/Components/Customer/PageHeader';
import PreviewProfileButton from 'src/Components/Customer/PreviewProfileButton';
import SelectionChip from 'src/Components/Customer/SelectionChip';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import httpRequest from 'src/helpers/httpRequest';
import { IDropDownArray } from 'src/types/GlobalInterfaces';
import { updateDoctorField } from '../../../Store/doctor';
import { setUniqueData } from "src/Store/reducer";

// Interface representing a disease item fetched from the server
interface IDiseasesItem {
    name: string;
    _id: string;
};

const DiseaseEdit: React.FC = () => {
    const { profile } = useSelector((store: RootState) => store.doctor); // Extract the user's profile from the Redux store
    const dispatch = useDispatch();

    const [isError, setisError] = useState<string>(""); // State to manage error messages related to form submission
    const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage the loading state during form submission
    const [fetchLoader, setFetchLoader] = useState<boolean>(false); // State to manage the loading state while fetching diseases from the server
    const [allDiseases, setAllDiseases] = useState<IDropDownArray[]>([]); // State to store all available diseases fetched from the server
    const [selectedDiseases, setSelectedDiseases] = useState<IDropDownArray[]>([]); // State to store the diseases selected by the user

    /**
   * handleSubmit
   * 
   * Description: Handles the form submission for updating the doctor's diseases. 
   * It validates the input, sends the selected diseases to the server, handles the response, 
   * and updates the Redux store accordingly.
   * 
   * @param {FormEvent} event - The form submission event.
   */
    // API_CALL: Updates the user's "diseasesDetails" section with new data
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        // Validate that at least one disease is selected
        if (!selectedDiseases.length) {
            setisError("Please select atleast one Disease.");
            setTimeout(() => {
                setisError("");
            }, 3000);
            return; // Exit the function early
        }
        setIsLoader(true); // Show the loader indicating submission is in progress
        const { res, err } = await httpRequest<{ message: string; data: { _id: string }[]; percent: number }>({
            method: "post", path: "/provider/update-diseases", params: {
                userDiseases: selectedDiseases.map(v => ({ _id: v.value }))
            }
        });
        if (res) {
            const { message, data, percent } = res;
            toast(message, { type: "success" }); // api success message
            const newArr = data.map(v => ({ _id: v._id, name: allDiseases.find(item => item.value === v._id)?.label || "" })); // Map the response data to the format expected by the Redux store
            dispatch(updateDoctorField({ diseasesDetails: newArr })); // Dispatch an action to update the diseasesDetails in the Redux store
            dispatch(setUniqueData({ key: 'profileCompletion', value: String(percent) }));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    /**
    * fetchAllDiseases
    * 
    * Description: Fetches all available diseases from the server and updates the `allDiseases` state 
    * with the fetched data. Displays a loading indicator while the data is being fetched and handles 
    * any errors that occur during the fetch.
    */
    const fetchAllDiseases = async () => {
        setFetchLoader(true);
        const { res, err } = await httpRequest<IDiseasesItem[]>({ path: "/provider/diseases/get" });
        if (res) {
            setAllDiseases(res.map(v => ({ label: v.name, value: v._id })));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setFetchLoader(false);
    };

    /**
    * useEffect (Initialize Selected Diseases)
    * 
    * Description: Initializes the `selectedDiseases` state based on the user's profile information. 
    * This effect runs whenever the `profile` state changes.
    */
    useEffect(() => {
        if (profile) setSelectedDiseases(
            profile.diseasesDetails
                ?
                profile.diseasesDetails.map(v => ({ value: v._id, label: v.name }))
                :
                []
        );
    }, [profile]);

    /**
   * useEffect (Fetch All Diseases on Mount)
   * 
   * Description: Fetches all available diseases from the server when the component is mounted.
   */
    useEffect(() => {
        fetchAllDiseases(); // Invoke the function to fetch diseases
    }, []);

    return (
        <>
            <PageHeader
                title="Diseases"
                isButton={true}
                ButtonComponent={PreviewProfileButton}
            />
            <div className="parent-box minheight-parent-box">
                <p className="font-Poppins-semiBold font-size-18px theme-color-green mb-2">Enter the diseases that you treat.</p>
                <form onSubmit={handleSubmit}>
                    <SelectDropdown
                        loader={fetchLoader}
                        options={allDiseases}
                        isMulti={true}
                        data={selectedDiseases}
                        setData={setSelectedDiseases}
                        checkboxShow={false}
                        isLabel={true}
                        labelText="Diseases Name"
                        isRequired={true}
                        extraClass={"border__dropdown__primary"}
                    />
                    {isError && <p className="font-size-14 text-[#ff0000]">{isError}</p>}
                    <div className="my-5 flex flex-wrap gap-3">
                        {selectedDiseases.map((v: IDropDownArray) => (
                            <SelectionChip
                                title={v.label}
                                onDelete={() => setSelectedDiseases(item => item.filter(disease => disease.value !== v.value))}
                            />
                        ))}
                    </div>
                    <div className="flex justify-end">
                        <PrimaryBtn
                            type={"submit"}
                            loader={isLoader}
                            ButtonText='Save'
                            btnExtraStyle={{
                                borderRadius: "100rem",
                                backgroundColor: "#004540"
                            }}
                        />
                    </div>
                </form>
            </div>
        </>
    )
};

export default DiseaseEdit;