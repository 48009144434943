/**
 * NotificationStatus Component
 * 
 * Description: This component handles the display and toggle functionality for user notification settings. 
 * It fetches notification preferences from the server, displays them based on user role, and allows the user 
 * to toggle each notification setting. The component uses React hooks for state management and makes HTTP 
 * requests to update the notification status.
 * 
 * Props: None
 * 
 * File Name: NotificationStatus.tsx
 * Date: 03-09-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import ToggleRadioBtn from '../ToggleRadioBtn';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { customer, doctor } from 'src/lib/constans';

interface INotification {
  appoinmentCancel: boolean;
  healthPrfoileUpdate: boolean;
  newAppointment: boolean;
  orderStatus: boolean;
  payDecline: boolean;
  payRecive: boolean;
  remindUpcomingAppoinment: boolean;
  resultUpdates: boolean;
  testUpload: boolean;
};

const NotificationStatus: React.FC = () => {
  const { userDetails } = useSelector((store: RootState) => store.appReducer);

  const dummyObject = {
    "appointments__notifications": {
      isDisplay: userDetails?.userRole === doctor,
      list: [
        { title: "New appointment", key: "newAppointment" },
        { title: "Reminder of upcoming appointment", key: "remindUpcomingAppoinment" },
        { title: "Appointment cancellation", key: "appoinmentCancel" },
      ]
    },
    "appointments": {
      isDisplay: userDetails?.userRole === customer,
      list: [
        { title: "Reminder of upcoming appointment", key: "remindUpcomingAppoinment" },
        { title: "Appointment cancellation", key: "appoinmentCancel" },
      ]
    },
    "payment__&__withdrawl": {
      isDisplay: userDetails?.userRole === doctor,
      list: [
        { title: "Payment receive", key: "payRecive" },
        { title: "Payment decline", key: "payDecline" },
      ]
    },
    "customers": {
      isDisplay: userDetails?.userRole === doctor,
      list: [
        { title: "Health profile updated", key: "healthPrfoileUpdate" },
      ]
    },
    "results": {
      isDisplay: true,
      list: [
        { title: "Results updates", key: "resultUpdates" },
      ]
    },
    "orders": {
      isDisplay: true,
      list: [
        { title: "Order status updates", key: "orderStatus" },
      ]
    },
  };

  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [notificationState, setNotificationState] = useState<INotification>({
    appoinmentCancel: false,
    healthPrfoileUpdate: false,
    newAppointment: false,
    orderStatus: false,
    payDecline: false,
    payRecive: false,
    remindUpcomingAppoinment: false,
    resultUpdates: false,
    testUpload: false,
  });

  const displayText = (txt: string): string => {
    if (txt.includes("__")) {
      txt = txt.replace(/__/g, ' '); // Replace double underscores with spaces
    }
    return txt;
  };

  // API_CALL: API call for notifcation status toggle
  const handleSubmit = async (key: keyof INotification) => {
    setIsLoader(true);
    const { res, err } = await httpRequest<{ message: string; data: INotification }>({
      method: "post",
      path: "/notification-setting/update",
      params: { [key]: !notificationState[key] }
    });
    if (res) {
      const { message, data } = res;
      toast(message, { type: 'success' });
      const { appoinmentCancel, healthPrfoileUpdate, newAppointment, orderStatus, payDecline, payRecive, remindUpcomingAppoinment, resultUpdates, testUpload } = data;
      setNotificationState({ appoinmentCancel, healthPrfoileUpdate, newAppointment, orderStatus, payDecline, payRecive, remindUpcomingAppoinment, resultUpdates, testUpload });
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };

  const fetchNotifications = async () => {
    setIsLoader(true);
    const { res, err } = await httpRequest<{ data: INotification | null }>({ path: "/notification-setting" });
    if (res) {
      if ('data' in res && res.data === null) {
        setNotificationState({
          appoinmentCancel: true,
          healthPrfoileUpdate: true,
          newAppointment: true,
          orderStatus: true,
          payDecline: true,
          payRecive: true,
          remindUpcomingAppoinment: true,
          resultUpdates: true,
          testUpload: true
        });
      } else {
        const notificationRes = res?.data as INotification;
        const { appoinmentCancel, healthPrfoileUpdate, newAppointment, orderStatus, payDecline, payRecive, remindUpcomingAppoinment, resultUpdates, testUpload } = notificationRes;
        setNotificationState({ appoinmentCancel, healthPrfoileUpdate, newAppointment, orderStatus, payDecline, payRecive, remindUpcomingAppoinment, resultUpdates, testUpload });
      }
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="parent-box parent-box-docter-margin">
      <h2 className='title-heading font-Poppins-SemiBold font-size-20px pb-5'>Notifications</h2>
      {Object.keys(dummyObject)
        .filter((key) => dummyObject[key as keyof typeof dummyObject].isDisplay)
        .map((key: string, index: number) => (
          <div key={index} className="mb-5">
            <h2 className='title-heading font-Poppins-SemiBold font-size-20px pb-5 capitalize'>{displayText(key)}</h2>
            {dummyObject[key as keyof typeof dummyObject].list.map((item, i) => (
              <div key={i} className="flex justify-between items-center mb-3">
                <p className="font-size-18px font-Poppins-Regular">{item.title}</p>
                <ToggleRadioBtn
                  isToggled={notificationState[item.key as keyof INotification]}
                  handleToggle={() => handleSubmit(item.key as keyof INotification)}
                />
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default NotificationStatus;