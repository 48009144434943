/**
 * WorkHistoryEdit Component
 * 
 * Description: This component allows users to view and edit their work history. It includes a form for entering job roles, hospital names, and start/end dates for each position. Users can add or remove work history entries, and submit updates to the server. The component retrieves initial data from the Redux store and updates the store upon successful submission.
 * 
 * File Name: WorkHistoryEdit.tsx
 * Date: 02-09-2024
 */

import React, { FormEvent, useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import PageHeader from 'src/Components/Customer/PageHeader';
import PreviewProfileButton from 'src/Components/Customer/PreviewProfileButton';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SimpleInput from 'src/Components/SimpleInput';
import httpRequest from 'src/helpers/httpRequest';
import { IDoctorExperiences } from 'src/types/GlobalInterfaces';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { updateDoctorField } from '../../../Store/doctor';
import { setUniqueData } from "src/Store/reducer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const emptyObject = {
    role: "",
    hospitalName: "",
    startDate: "",
    endDate: "",
};

const WorkHistoryEdit: React.FC = () => {
    const { profile } = useSelector((store: RootState) => store.doctor);
    const dispatch = useDispatch();

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [experiences, setExperiences] = useState<IDoctorExperiences[]>([emptyObject]);

    /**
     * Handles changes to individual fields in the work history form.
     * 
     * @param key - The key of the field being updated.
     * @param value - The new value for the field.
     * @param index - The index of the item being updated.
     */
    const handleChange = (key: keyof IDoctorExperiences, value: string, index: number): void => {
        setExperiences(
            prev =>
                prev.map((v, i) => i === index ? { ...v, [key]: value } : v)
        );
    };

    /**
     * Adds a new work history entry or removes an existing one.
     * 
     * @param index - The index of the entry to be removed, or undefined to add a new entry.
     */
    const handleAddOrRemove = (index?: number) => {
        if (index || index === 0) { // if index include the treat like a remove
            setExperiences(
                prev =>
                    prev.filter((_, i) => i !== index)
            );
        } else {
            setExperiences(prev => [...prev, emptyObject]);
        }
    };

    /**
   * Handles the form submission to update work history.
   * 
   * @param event - The form submission event.
   */
    // API_CALL: Updates the user's "experiences" section with new data
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: IDoctorExperiences[]; percent: number }>({
            method: "post",
            path: "/provider/experience",
            params: { experiences }
        });
        if (res) {
            const { message, data, percent } = res;
            toast(message, { type: "success" }); // api success message
            dispatch(updateDoctorField({ experiences: data }));
            dispatch(setUniqueData({ key: 'profileCompletion', value: String(percent) }));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    console.log("experiences", experiences);

    useEffect(() => {
        if (profile) setExperiences((profile.experiences && profile.experiences.length) ? profile.experiences : [emptyObject]);
    }, [profile]);

    return (
        <>
            <PageHeader
                title="Work History"
                isButton={true}
                ButtonComponent={PreviewProfileButton}
            />
            <div className="parent-box minheight-parent-box">
                <p className="font-Poppins-semiBold font-size-18px theme-color-green mb-2">Enter your work history and experience.</p>
                <form onSubmit={handleSubmit}>
                    {
                        experiences.map((item: IDoctorExperiences, index: number) => (
                            <div key={index}>
                                <SimpleInput
                                    value={item.role}
                                    onChange={(val) => handleChange("role", val, index)}
                                    isLabel={true}
                                    labelText={"Job title/role"}
                                    isRequired={true}
                                    extraStyle={{ borderColor: "#009d91" }}
                                />
                                <SimpleInput
                                    value={item.hospitalName}
                                    onChange={(val) => handleChange("hospitalName", val, index)}
                                    isLabel={true}
                                    labelText={"Hospital Name"}
                                    isRequired={true}
                                    extraStyle={{ borderColor: "#009d91" }}
                                />
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                    <div>
                                        <SimpleInput
                                            value={moment(item.startDate).format("YYYY-MM-DD")}
                                            onChange={(val) => handleChange("startDate", `${val}T00:00:00Z`, index)}
                                            isLabel={true}
                                            labelText={"Start Date"}
                                            isRequired={true}
                                            extraStyle={{ borderColor: "#009d91" }}
                                            type='date'
                                        />
                                    </div>
                                    <div>
                                        <SimpleInput
                                            value={moment(item.endDate).format("YYYY-MM-DD")}
                                            onChange={(val) => handleChange("endDate", `${val}T00:00:00Z`, index)}
                                            isLabel={true}
                                            labelText={"End Date"}
                                            isRequired={true}
                                            extraStyle={{ borderColor: "#009d91" }}
                                            type='date'
                                        />
                                    </div>
                                </div>

                                <>
                                    <div className="flex justify-end">
                                        <button
                                            className="flex gap-2 items-center"
                                            onClick={() => handleAddOrRemove(index)}
                                            type="button"
                                        >
                                            <FontAwesomeIcon size='sm' icon={faTrash} />
                                            Delete Work History
                                        </button>
                                    </div>
                                    <hr className='my-5' style={{ border: '1px solid #C8C8C8' }} />
                                </>
                            </div>
                        ))
                    }
                    <div className="flex justify-end mt-5">
                        <PrimaryBtn
                            loader={isLoader}
                            type="submit"
                            ButtonText="Save"
                            btnExtraStyle={{
                                borderRadius: "1.5rem",
                                backgroundColor: "#004540"
                            }}
                        />
                    </div>
                </form>
                <div className="my-5">
                    <button
                        onClick={() => handleAddOrRemove()}
                        type="button"
                        className="font-Poppins-semiBold font-size-18px theme-color-green">
                        + Add another work history
                    </button>
                </div>
            </div>
        </>
    );
};

export default WorkHistoryEdit;