import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IIncomingCall } from 'src/types/GlobalInterfaces';

// Define the application state interface
export interface AppState {
    inComingCall: IIncomingCall | null;
};

// Initial state of the video call slice
const initialState: AppState = {
    inComingCall: null
};

// Create the video call slice
export const videoCallSlice = createSlice({
    name: 'videoCall',
    initialState,
    reducers: {
        // Reducer to receive call notifications
        setCallNotifications: (state, action: PayloadAction<IIncomingCall>) => {
            state.inComingCall = action.payload;
        },

        declineCall: (state) => {
            state.inComingCall = null;
        },
    }
});

// Export action creators
export const { setCallNotifications, declineCall } = videoCallSlice.actions;

// Export the reducer to be used in the store
export default videoCallSlice.reducer;