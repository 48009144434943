/**
 * GeneralTab Component
 *
 * Description: This component represents the general tab in the product management interface. 
 * It allows users to input and manage basic product information such as product name, 
 * description, test type, and estimated results days. The component fetches test type options 
 * from the server and provides a user interface for selecting test types and entering other 
 * product details. It includes validation for required fields and a button to proceed to the next tab.
 *
 * Props:
 * - state (IProductsTabsProps): The current state containing product details.
 * - setState (function): Function to update the product state, including product name, description, test type, and estimated results days.
 * - tabChange (function): Callback function invoked to change the active tab after completing the input.
 *
 * File Name: GeneralTab.tsx
 * Date: 31-07-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import SimpleInput from '../../SimpleInput';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import { IDropDownArray, IProductsTabsProps } from 'src/types/GlobalInterfaces';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';

const GeneralTab: React.FC<IProductsTabsProps> = ({
    state,
    setState,
    tabChange
}) => {
    const [testTypeLoader, setTestTypeLoader] = useState<boolean>(false);
    const [allTypes, setAllType] = useState<IDropDownArray[]>([]);

    //API_CALL: Fetch all test types from the server
    const getAllTestType = async () => {
        setTestTypeLoader(true);
        const { res, err } = await httpRequest<any[]>({ path: "/testtype/all" });
        if (res) {
            setAllType(res.map(v => ({ label: v.testName, value: v._id })));
        } else {
            toast(err, { type: 'error' });
        }
        setTestTypeLoader(false);
    };

    useEffect(() => {
        getAllTestType();
    }, []);

    return (
        <>
            <div className='products-tabs-content'>
                <SimpleInput
                    value={state?.productName}
                    onChange={(val) => setState("productName", val)}
                    isLabel={true}
                    labelText="Product Name"
                    isRequired={true}
                />
                <SimpleInput
                    value={state?.description}
                    onChange={(val) => setState("description", val, 500)}
                    isLabel={true}
                    labelText="Product Description"
                    isRequired={true}
                    type="textArea"
                    rows={5}
                    isLimit={500}
                />
            </div>
            <div className='products-tabs-content-Test-Type'>
                <div className='products-tabs-content'>
                    <div className='products-tabs-content-estimated'>
                        <div className="test-type-general">
                            <SelectDropdown
                                loader={testTypeLoader}
                                options={allTypes}
                                isMulti={false}
                                data={allTypes.filter(v => v?.value === state?.testTypeId)}
                                setData={(e) => setState("testTypeId", e[0]?.value || "")}
                                checkboxShow={false}
                                isLabel={true}
                                labelText="Test Type"
                                isRequired={true}
                                extraClass="m-0"
                            />
                        </div>
                        <div className="estimated-results-general">
                            <SimpleInput
                                value={state?.resultDays}
                                onChange={(val) => setState("resultDays", val, 2)}
                                isLabel={true}
                                labelText="Estimated Results Days"
                                isRequired={true}
                                extraStyle={{ margin: 0 }}
                                type='number'
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* Button to proceed to the next tab */}
            <div className='flex justify-end my-6'>
                <PrimaryBtn
                    ButtonText="Next"
                    btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                    onPress={tabChange}
                />
            </div>
        </>
    );
};

export default GeneralTab;