/**
 * WithDrawalDetail
 * 
 * Description: This component displays the withdrawal details for a specific doctor, including 
 * doctor and account information, current requests, and allows for approval of withdrawal requests.
 * 
 * File Name: WithDrawalDetail.jsx
 * Date: 30-09-2024
 */

import { Skeleton } from '@mui/material';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SimpleInput from 'src/Components/SimpleInput';
import httpRequest from 'src/helpers/httpRequest';

interface IDoctorDetails {
    _id: string;
    accountHolderName: string;
    fullName: string;
    address: string;
    email: string;
    firstName: string;
    iban: string;
    lastName: string;
    mobile: string;
    swiftCode: string;
};

interface ICurrentRequest {
    _id: string;
    accountHolderName: string;
    accountNumber: string;
    amount: number;
    attachment: string;
    bankBranchCode: string;
    bankName: string;
    doctorId: string;
    iban: string;
    reason: string;
    status: "completed" | "pending" | "declined";
    swiftCode: string;
    transactionNumber: string;
};

interface IFinancialDetails {
    currentBalance: number;
    currentWithdraw: number;
    totalWithdrawAmount: number
    _id: string
};

interface IFetchDetails {
    currentRequest: ICurrentRequest[];
    doctorDetails: IDoctorDetails[];
    financialDetails: IFinancialDetails;
};

const WithDrawalDetail: React.FC = () => {
    const navigate = useNavigate();
    const { id = "" } = useParams();
    const doctorDetailsMap: { label: string; key: keyof IDoctorDetails }[] = [
        { label: "Doctor Name", key: "fullName" },
        { label: "Doctor Email", key: "email" },
        { label: "Phone Number", key: "mobile" },
        { label: "Address", key: "address" },
    ];
    const accountDetails: { label: string; key: keyof IFinancialDetails }[] = [
        { label: "Current Balance", key: "currentBalance" },
        { label: "Total Withdrawal", key: "totalWithdrawAmount" },
        { label: "Current Withdrawal Request", key: "currentWithdraw" },
    ];

    // ************* COMPLETED STATES & FUNCTIONS ************* //
    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [reason, setReason] = useState<string>("");
    const [transactionNumber, setTransactionNumber] = useState<string>("");
    const [attachment, setattachment] = useState<File | null | string>(null);
    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // setPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setattachment(file); // Update the file state
        }
    };
    // API_CALL: Approve withdrawal request
    const approveWithDrawal = async (event: FormEvent) => {
        event.preventDefault();
        setSubmitLoader(true);
        const formData = new FormData();
        formData.append("reason", reason);
        formData.append("transactionNumber", transactionNumber);
        formData.append("withdrawId", id);
        formData.append("attachment", attachment || "");

        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: "/paymentandwithdraw/completed",
            header: { "Content-Type": "multipart/form-data" },
            params: formData
        });
        if (res) {
            toast(res.message || "", { type: 'success' });
            navigate("/withdrawal-requests");
        } else {
            toast(err, { type: 'error' });
        }
        setSubmitLoader(false);
    };
    // ************* COMPLETED STATES & FUNCTIONS ************* //

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [doctorDetails, setdoctorDetails] = useState<IDoctorDetails | null>(null);
    const [financialDetails, setFinancialDetails] = useState<IFinancialDetails | null>(null);
    const [currentRequest, setCurrentRequest] = useState<ICurrentRequest | null>(null);

    // API_CALL: Fetch withdrawal details
    const fetch = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IFetchDetails>({ path: `/paymentandwithdraw/details/${id}` });
        if (res) {
            setdoctorDetails(res.doctorDetails[0]);
            setFinancialDetails(res.financialDetails);
            setCurrentRequest(res.currentRequest[0]);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetch();
    }, [id]);

    return (
        <>
            <BreadCrumb
                current="Withdrawal"
                previous="dashboard"
                navigateUrl="/admin"
                isUpsert={true}
                btnShow={true}
                onPress={() => navigate("/withdrawal-requests")}
            />
            <div className="withdrawal-requests">
                <div className="table-container">
                    <div className="grid grid-cols-1 gap-3 parent-box-scroll-bar-full">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div style={{ boxShadow: 'none' }} className="parent-box mb-0 rounded">
                                <h2 className='title-heading font-Poppins-SemiBold font-size-20px mb-3'>Doctor Details</h2>
                                {doctorDetailsMap.map(v => (
                                    <div key={v.key} className='flex justify-between border-t items-center py-3'>
                                        <span className='font-Poppins-Medium text-[#444444] font-size-18px w-[60%]'>{v.label}:</span>
                                        <span className='font-Poppins-Regular text-end text-[#444444] font-size-18px w-[40%] break-words'>
                                            {isLoader ? <Skeleton variant="text" className="font-size-18px" /> :
                                                doctorDetails ?
                                                    doctorDetails[v.key] || <span className='block text-center'>-</span>
                                                    : <span className='block text-center'>-</span>
                                            }
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div style={{ boxShadow: 'none' }} className="parent-box mb-0 rounded">
                                <h2 className='title-heading font-Poppins-SemiBold font-size-20px mb-3'>Account Details</h2>
                                {accountDetails.map(v => (
                                    <div key={v.key} className='flex justify-between border-t items-center py-3'>
                                        <span className='font-Poppins-Medium text-[#444444] font-size-18px w-[60%]'>{v.label}:</span>
                                        <span className='font-Poppins-Regular text-end text-[#444444] font-size-18px w-[40%] break-words'>
                                            {isLoader ? <Skeleton variant="text" className="font-size-18px" /> :
                                                financialDetails ?
                                                    `AED ${financialDetails[v.key]}` || <span className='block text-center'>-</span>
                                                    : <span className='block text-center'>-</span>
                                            }
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ boxShadow: 'none' }} className="parent-box mb-0 rounded">
                            <h2 className='title-heading font-Poppins-SemiBold font-size-20px mb-3'>Current Request</h2>
                            <span className='font-Poppins-Regular text-[#444444] block font-size-15px mb-2'>Requested Amount: <span className='font-Poppins-SemiBold'>AED {currentRequest?.amount || 0}</span></span>
                            <span className='font-Poppins-Regular text-[#444444] block font-size-15px mb-1'>Bank Account Number: {currentRequest?.accountNumber || "-"}</span>
                            <span className='font-Poppins-Regular text-[#444444] block font-size-15px mb-1'>Bank Branch Code: {currentRequest?.bankBranchCode || ""}</span>
                            <span className='font-Poppins-Regular text-[#444444] block font-size-15px mb-1'>BIC/SWIFT Code: {currentRequest?.swiftCode || ""}</span>
                            <span className='font-Poppins-Regular text-[#444444] block font-size-15px mb-1'>IBAN: {currentRequest?.iban || ""}</span>
                            {currentRequest?.status === "pending" ?
                                <div className="pending__status">
                                    <form onSubmit={approveWithDrawal}>
                                        <SimpleInput
                                            value={transactionNumber}
                                            onChange={(val) => setTransactionNumber(val)}
                                            isLabel={true}
                                            labelText="Transaction identification Number (TID)"
                                            isRequired={true}
                                        />
                                        <div>
                                            <label className="font-size-18px font-Poppins-Regular">
                                                <span className="text-[#FF0000]">* </span>Attach Receipt:</label>
                                            <input
                                                type="file"
                                                id="imageUpload"
                                                accept="image/*"
                                                className="create-role-input font-size-16px font-Poppins-Regular"
                                                onChange={handleImageUpload}
                                                required
                                            />
                                        </div>
                                        <SimpleInput
                                            rows={3}
                                            type='textArea'
                                            value={reason}
                                            onChange={(val) => setReason(val)}
                                            isLabel={true}
                                            labelText="Comment"
                                            isRequired={false}
                                        />
                                        <div className="flex justify-end">
                                            <PrimaryBtn
                                                loader={submitLoader}
                                                type="submit"
                                                ButtonText={"Complete"}
                                                btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                                            />
                                        </div>
                                    </form>
                                </div>
                                : currentRequest?.status === "completed" ?
                                    <div className="completed__status">
                                        <span className='font-Poppins-Regular text-[#444444] block font-size-15px mb-1'>Transaction Identification Number (TID):</span>
                                        <span className='font-Poppins-Regular text-[#444444] block font-size-15px mb-1'>{currentRequest?.transactionNumber || ""}</span>
                                        <span className='font-Poppins-Regular text-[#444444] block font-size-15px mb-1'>Attach Receipt:</span>
                                        <img className='img-recepit'
                                            src={`${process.env.REACT_APP_API_BASEURL}${currentRequest?.attachment}`}
                                            alt="attachement"
                                        />
                                        <span className='font-Poppins-Regular text-[#444444] block font-size-15px mb-1'>Comment:</span>
                                        <span className='font-Poppins-Regular text-[#444444] block font-size-15px mb-1'>{currentRequest?.reason || ""}</span>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithDrawalDetail;