import React, { ReactNode } from 'react';
import PaginationComponet from "./PaginationComponet";
import { CircularProgress } from '@mui/material';

interface ITableWrapperProps {
    isLoading?: boolean;
    page?: number;
    total: number;
    thead: Array<string>;
    onChange?: (page: number) => void; // Define the type of the onChange function
    children?: ReactNode; // Use ReactNode for children prop
    columnWidth?: string; // Define the type for columnWidth
};

const TableWrapper: React.FC<ITableWrapperProps> = ({
    isLoading = false,
    page = 1,
    total = 0,
    thead = [],
    onChange = () => { },
    children,
    columnWidth = "auto", // Default to "auto" if not provided
}) => {

    const centeredElements = ['Health Profile','Status', 'Image', 'Proof Document'];

    return (
        <>
            <ul className="thead-light-table" style={{ gridTemplateColumns: columnWidth }}>

                {thead.map((v, i) => {
                    return (
                        <li className="font-size-16px font-Poppins-SemiBold" style={centeredElements.includes(v) ? {justifyContent: 'center', paddingLeft: 0 } : {}} key={i}>
                            {v}
                        </li>
                    );
                })}
            </ul>
            {isLoading ?
                <ul className="font-size-16px tbody-light-table font-Poppins-Regular grid">
                    <li>
                        <CircularProgress style={{ width: 24, height: 24, color: "#00443f" }} />
                    </li>
                </ul>
                : <></>}
            {total ? children :
                <ul className="font-size-16px tbody-light-table font-Poppins-Regular grid">
                    <li>No Results</li>
                </ul>}
            {/* pagination */}
            <PaginationComponet
                currentPage={page}
                total={total}
                setCurrentPage={onChange}
            />
        </>
    );
};

export default TableWrapper;