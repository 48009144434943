import React from 'react';

interface IinputField {
  placeholder?: string;
  type?: string;
  value?: string;
  onChange?: (value: string) => void;
};

const InputFeild: React.FC<IinputField> = (
  {
    placeholder = "",
    type = "",
    value = "",
    onChange = () => { }
  }
) => {
  return (
    <input
      className="home-banner-input input-placeholder-green input-btns-height-55px w-full border-radius-12px theme-bg-cream-white outline-none font-Poppins-SemiBold font-size-18px theme-color-green"
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default InputFeild;