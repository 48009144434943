import { customer, doctor, operator, superAdmin } from "../lib/constans"; // costant
import { IRouteItem } from "src/types/routeTypes";
import Page404 from "../Pages/Page404"; // 404 page for all roles
import Login from '../Pages/Admin/Login'; // admin login page
import TwoFactor from "../Pages/Admin/TwoFactor"; // two factor page
import Dashboard from "../Pages/Admin/Dashboard"; // dashboard for admin
import Roles from "../Pages/Admin/Roles"; // roles for admin
import Operators from "../Pages/Admin/Operators"; // operators for admin
import Categories from "../Pages/Admin/Categories"; // categories for amdin
import Biomarkers from "../Pages/Admin/Biomarkers"; // biomarkers for admin
import Products from "../Pages/Admin/Products"; // products for amdin
import AllOrders from "../Pages/Admin/AllOrders"; // order for admin
import OrderDetails from "../Pages/Admin/OrderDetails"; // order detail for admin
import Doctors from "src/Pages/Admin/Doctors";
import DiscussionsList from "src/Pages/Admin/DiscussionsList";
import Customers from "src/Pages/Admin/Customers";
import ViewCustomerProfile from "../Pages/Admin/ViewCustomerProfile";
import ViewDoctorProfile from "../Pages/Admin/ViewDoctorProfile";
import ViewCustomerTest from "../Pages/Admin/ViewCustomerTest";
import ViewResult from "../Pages/Admin/ViewResult";
import CustomerHealthProfile from "../Pages/Admin/CustomerHealthProfile";
import AddResult from "../Pages/Admin/AddResult";
import CustomerBioMarkerDetails from "../Pages/Admin/CustomerBioMarkerDetails";
import WithDrawal from "../Pages/Admin/WithDrawal";
import WithDrawalDetail from "../Pages/Admin/WithDrawalDetail";
import SpecializationsList from "../Pages/Admin/SpecializationsList";
import DiseasesList from "../Pages/Admin/DiseasesList";
import AllApointments from "../Pages/Admin/AllApointments";
import SingleAppointment from "../Pages/Admin/SingleAppointment";
import SupportList from "../Pages/Admin/SupportList";
import PlatformSetting from "../Pages/Admin/PlatformSetting";
import RevenueManagement from "src/Pages/Admin/RevenueManagement";
import Analytics from "src/Pages/Admin/Analytics";
import PBrands from "src/Pages/Pharmacy/Admin/PBrands";
import PMainCategories from "src/Pages/Pharmacy/Admin/PMainCategories";
import PSubCategories from "src/Pages/Pharmacy/Admin/PSubCategories";
import PChildCategories from "src/Pages/Pharmacy/Admin/PChildCategories";
// ****************** CUSTOMER PAGES ****************** //
import AllProducts from "src/Pages/AllProducts";
import ProductDetail from "src/Pages/ProductDetail";
import CustomerDashboard from "src/Pages/Customer/CustomerDashboard";
import MyOrders from "src/Pages/Customer/MyOrders";
import OrderDetailsView from "src/Pages/Customer/OrderDetailsView";
import MyResults from "src/Pages/Customer/MyResults";
import AccountSettings from "src/Pages/Customer/AccountSettings";
import EditProfile from "src/Pages/Customer/EditProfile";
import PersonalDetails from "src/Pages/Customer/PersonalDetails";
import HealthVitals from "src/Pages/Customer/HealthVitals";
import HealthBiomarkers from "src/Pages/Customer/HealthBiomarkers";
import CheckOutAndPayment from "src/Pages/Customer/CheckOutAndPayment";
import ProfileView from "src/Pages/Customer/ProfileView";
import MyTestResult from "src/Pages/Customer/MyTestResult";
import BiomarkerDetails from "src/Pages/Customer/BiomarkerDetails";
import FindDoctors from "src/Pages/Customer/FindDoctors";
import DoctorsList from "src/Pages/Customer/DoctorsList";
import BookDoctorAppointment from "src/Pages/Customer/BookDoctorAppointment";
import Appointments from "src/Pages/Customer/Appointments";
import AppointmentDetails from "src/Pages/Customer/AppointmentDetails";
import MyDoctors from "src/Pages/Customer/MyDoctors";
import ManageDoctorAccess from "src/Pages/Customer/ManageDoctorAccess";
import DoctorPublicProfile from "src/Pages/Customer/DoctorPublicProfile";
import OnlineConsultations from "src/Pages/Customer/OnlineConsultations";
import Discussions from "src/Pages/Customer/Discussions";
import DiscussionDetail from "src/Pages/Customer/DiscussionDetail";
import Scheduler from "src/Pages/Customer/Scheduler";
import WelComeChat from "src/Pages/ChatApp/WelComeChat";
import SingleChat from "src/Pages/ChatApp/SingleChat";
import CustomerNotifications from "src/Pages/Customer/CustomerNotifications";
import SupportHubs from "src/Pages/Customer/SupportHubs";
import AllReviews from "src/Pages/Customer/AllReviews";
// ****************** CUSTOMER PAGES ****************** //

// ****************** DOCTOR PAGES ****************** //
import AboutMeEdit from "src/Pages/Doctor/EditProfiles/AboutMeEdit";
import WorkHistoryEdit from "src/Pages/Doctor/EditProfiles/WorkHistoryEdit";
import EducationEdit from "src/Pages/Doctor/EditProfiles/EducationEdit";
import ServicesEdit from "src/Pages/Doctor/EditProfiles/ServicesEdit";
import DiseaseEdit from "src/Pages/Doctor/EditProfiles/DiseaseEdit";
import SpecializationEdit from "src/Pages/Doctor/EditProfiles/SpecializationEdit";
import OnlineAvailabilityEdit from "src/Pages/Doctor/EditProfiles/OnlineAvailabilityEdit";
import HospitalAvailabilityEdit from "src/Pages/Doctor/EditProfiles/HospitalAvailabilityEdit";
import MyCustomer from "src/Pages/Doctor/MyCustomer";
import MyCustomerTestResults from "src/Pages/Doctor/MyCustomerTestResults";
import MyCustomerHealthProfile from "src/Pages/Doctor/MyCustomerHealthProfile";
import PayoutsAndWithDrawal from "src/Pages/Doctor/PayoutsAndWithDrawal";
// ****************** DOCTOR PAGES ****************** //

// ****************** DOCTOR AND CUSTOMER PAGES ****************** //
import Privacy from "src/Pages/DoctorsAndCustomers/Privacy";
import ActivityLog from "src/Pages/DoctorsAndCustomers/ActivityLog";
import PatientPublicProfile from "src/Pages/Doctor/PatientPublicProfile";
// ****************** DOCTOR AND CUSTOMER PAGES ****************** //

// Define the route configuration
export const appRoutes: IRouteItem[] = [
    {
        path: "/admin/login",
        ele: <Login />,
        isProtected: false,
        layout: "none",
        role: null,
        fallBack: "/admin",
    },
    {
        path: "/otp",
        ele: <TwoFactor />,
        isProtected: false,
        layout: "none",
        role: null,
        fallBack: "/admin",
    },
    {
        path: "/admin",
        ele: <Dashboard />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/roles",
        ele: <Roles />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/operators",
        ele: <Operators />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/customers",
        ele: <Customers />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/doctors",
        ele: <Doctors />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/discussions-list",
        ele: <DiscussionsList />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/categories",
        ele: <Categories />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/biomarkers",
        ele: <Biomarkers />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/products",
        ele: <Products />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/pharmacy/brands",
        ele: <PBrands />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/pharmacy/main-categories",
        ele: <PMainCategories />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/pharmacy/sub-categories",
        ele: <PSubCategories />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/pharmacy/child-categories",
        ele: <PChildCategories />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/all-orders",
        ele: <AllOrders />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/order-details/:orderId/View",
        ele: <OrderDetails />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/customer/:uid/View",
        ele: <ViewCustomerProfile />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/doctor/:uid/View",
        ele: <ViewDoctorProfile />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/health-profile/:uid/View",
        ele: <CustomerHealthProfile />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/add-result/:uid/view",
        ele: <AddResult />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/view/:uId/all-test",
        ele: <ViewCustomerTest />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/result/:uid/view",
        ele: <ViewResult />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/biomarker-result/:uid/:bioId/view",
        ele: <CustomerBioMarkerDetails />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/withdrawal-requests",
        ele: <WithDrawal />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/withdrawal/:id/view",
        ele: <WithDrawalDetail />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/all-specialization",
        ele: <SpecializationsList />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/all-disease",
        ele: <DiseasesList />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/all-appointments",
        ele: <AllApointments />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/single-appointment/:id/view",
        ele: <SingleAppointment />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/support-list",
        ele: <SupportList />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/platform-settings",
        ele: <PlatformSetting />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/revenue-management",
        ele: <RevenueManagement />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    {
        path: "/analytics",
        ele: <Analytics />,
        isProtected: true,
        layout: "admin",
        fallBack: "/admin/login",
        role: [superAdmin, operator],
    },
    // ******************* CUSTOMER ******************* //
    {
        path: "/all-products",
        ele: <AllProducts />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/product-detail/:id/view",
        ele: <ProductDetail />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/dashboard",
        ele: <CustomerDashboard />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/orders",
        ele: <MyOrders />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/results",
        ele: <MyResults />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/test-result/:id/view",
        ele: <MyTestResult />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/order/:id/view",
        ele: <OrderDetailsView />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/account-settings",
        ele: <AccountSettings />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/edit-profile",
        ele: <EditProfile />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/profile-view",
        ele: <ProfileView />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/personal-details",
        ele: <PersonalDetails />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/health-vitals",
        ele: <HealthVitals />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/health-biomarkers",
        ele: <HealthBiomarkers />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/checkout-payment",
        ele: <CheckOutAndPayment />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/bio-details/:uid/:bioId/view",
        ele: <BiomarkerDetails />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/find-doctors",
        ele: <FindDoctors />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/doctors-list",
        ele: <DoctorsList />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/book-appointment/:did/:hid/view",
        ele: <BookDoctorAppointment />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/appointments",
        ele: <Appointments />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/appointment/:id/view",
        ele: <AppointmentDetails />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/my-doctors",
        ele: <MyDoctors />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/doctor/:id/access",
        ele: <ManageDoctorAccess />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/doctor-public-profile/:id/view",
        ele: <DoctorPublicProfile />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/discussions",
        ele: <Discussions />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/discussion/:id/detail",
        ele: <DiscussionDetail />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/scheduler",
        ele: <Scheduler />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/message",
        ele: <WelComeChat />,
        isProtected: true,
        layout: "chat",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/message/:id/view",
        ele: <SingleChat />,
        isProtected: true,
        layout: "chat",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/notifications",
        ele: <CustomerNotifications />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/support-hub",
        ele: <SupportHubs />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/allreviews/:id/view",
        ele: <AllReviews />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    {
        path: "/online-consultation/:appoinmentId/:roomId/view",
        ele: <OnlineConsultations />,
        isProtected: true,
        layout: "none",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [customer, doctor],
    },
    // ******************* CUSTOMER ******************* //

    // ******************* DOCTOR AND CUSTOMER PAGES ******************* //
    {
        path: "/privacy",
        ele: <Privacy />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor, customer],
    },
    {
        path: "/activity",
        ele: <ActivityLog />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor, customer],
    },
    // ******************* DOCTOR AND CUSTOMER PAGES ******************* //

    // ******************* DOCTOR ******************* //
    {
        path: "/aboutme",
        ele: <AboutMeEdit />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/workhistory",
        ele: <WorkHistoryEdit />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/education",
        ele: <EducationEdit />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/my-services",
        ele: <ServicesEdit />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/my-diseases",
        ele: <DiseaseEdit />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/my-specialization",
        ele: <SpecializationEdit />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/online-availability",
        ele: <OnlineAvailabilityEdit />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/physical-availability",
        ele: <HospitalAvailabilityEdit />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/my-customers",
        ele: <MyCustomer />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/patient-public/:id/profile",
        ele: <PatientPublicProfile />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/customer-tests/:id/view",
        ele: <MyCustomerTestResults />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/customer-healthprofile/:id/view",
        ele: <MyCustomerHealthProfile />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    {
        path: "/payouts-withdrawal",
        ele: <PayoutsAndWithDrawal />,
        isProtected: true,
        layout: "customer",
        fallBack: "/login",
        extrnalFallBack: true,
        role: [doctor],
    },
    // ******************* DOCTOR ******************* //

    {
        path: "/*",
        ele: <Page404 />,
        isProtected: false,
        layout: "none",
        role: null
    },
];