import React from 'react'
import { Link } from "react-router-dom";
const Page404 = () => {
    return <>
        <div>
            <div className="notfound">
                <h1 className='font-Poppins-SemiBold font-size-60px'>404 Error: Page Not Found</h1>
                {/* <p className='font-size-18px font-Poppins-Medium'>Oops! It seems like you've stumbled upon a missing page. Don't worry, it happens to the best of us. </p> */}
                <div className="my-3"><Link className="font-Poppins-Medium font-size-20px" to="/">Go To Homepage</Link></div>
            </div>
        </div>

    </>
};
export default Page404;