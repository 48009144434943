import React, { useState, useEffect } from 'react';
import PageHeader from '../../Components/Customer/PageHeader';
import SimpleInput from '../../Components/SimpleInput';
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookSquare,
    faInstagram,
    faLinkedin,
    faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import { IDropDownArray } from 'src/types/GlobalInterfaces';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { setUserDetails } from 'src/Store/reducer';
import { AppDispatch, RootState } from '@store/store';
import { customer } from 'src/lib/constans';
import PreviewProfileButton from 'src/Components/Customer/PreviewProfileButton';
import { cityList } from 'src/Config/CityList';
interface FormValues {
    firstName: string;
    lastName: string;
    date: string;
    gender: string;
    mobile: string;
    address: string;
    city: string;
    postCode: string;
    headLine: string;
    facebookLink: string;
    linkedinLink: string;
    instagramLink: string;
    whatsappLink: string;

    [key: string]: any;
};

const EditProfile: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { userDetails } = useSelector((store: RootState) => store.appReducer);

    const genderList: IDropDownArray[] = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
    ];

    const [isLoader, setIsLoader] = useState<boolean>(false);

    const { control, handleSubmit, setValue, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            firstName: "",
            lastName: "",
            date: "",
            gender: "",
            mobile: "",
            address: "",
            city: "",
            postCode: "",
            headLine: "",
            facebookLink: "",
            linkedinLink: "",
            instagramLink: "",
            whatsappLink: "",
        }
    });

    // API_CALL: API call for customer edit profile
    const onSubmit = async (data: FormValues) => {
        setIsLoader(true);
        const { res, err } = await httpRequest<FormValues>({ method: "put", path: "/customer/profile-edit", params: data });
        if (res) {
            const { message = "", customerEdit } = res;
            toast(message, { type: 'success' });
            dispatch(setUserDetails(customerEdit)); // set data in redux store
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        setValue("firstName", userDetails?.firstName);
        setValue("lastName", userDetails?.lastName);
        setValue("date", moment(userDetails?.date).format("YYYY-MM-DD"));
        setValue("gender", userDetails?.gender);
        setValue("mobile", userDetails?.mobile);
        setValue("address", userDetails?.address);
        setValue("city", userDetails?.city);
        setValue("postCode", userDetails?.postCode);
        setValue("headLine", userDetails?.headLine);
        setValue("facebookLink", userDetails?.facebookLink);
        setValue("instagramLink", userDetails?.instagramLink);
        setValue("linkedinLink", userDetails?.linkedinLink);
        setValue("whatsappLink", userDetails?.whatsappLink);
    }, [userDetails]);

    return (
        <>
            {/* Page header for edit profile */}
            <PageHeader
                title="Edit Profile"
                isButton={true}
                ButtonComponent={PreviewProfileButton}
            />
            <div className='parent-box'>
                {/* <h2 className='title-heading font-Poppins-SemiBold font-size-20px'>Edit Bio</h2> */}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <Controller
                            name="firstName"
                            control={control}
                            rules={{ required: 'First Name is required' }}
                            render={({ field }) => (
                                <SimpleInput
                                    isLabel={true}
                                    labelText='First Name'
                                    type="text"
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                        {errors.firstName && <p className="font-size-14 text-[#ff0000]">{errors.firstName.message}</p>}
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="lastName"
                            control={control}
                            rules={{ required: 'Last Name is required' }}
                            render={({ field }) => (
                                <SimpleInput
                                    isLabel={true}
                                    labelText='Last Name'
                                    type="text"
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                        {errors.lastName && <p className="font-size-14 text-[#ff0000]">{errors.lastName.message}</p>}
                    </div>
                    <div className="mb-3">
                        <Controller
                            name="date"
                            control={control}
                            rules={{ required: 'Date of Birth is required' }}
                            render={({ field }) => (
                                <SimpleInput
                                    isLabel={true}
                                    labelText='Date of Birth'
                                    type="date"
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                        {errors.date && <p className="font-size-14 text-[#ff0000]">{errors.date.message}</p>}
                    </div>
                    <div className="mb-3 border_hide_and_primary_border">
                        <label className="poppins-regular font-size-16px text-[#252334]">Gender</label>
                        <Controller
                            name="gender"
                            control={control}
                            rules={{ required: 'Gender is required' }}
                            render={({ field }) => (
                                <SelectDropdown
                                    options={genderList}
                                    data={genderList.filter(v => v?.value === field.value)}
                                    setData={(e) => field.onChange(e[0]?.value || '')}
                                    isMulti={false}
                                />
                            )}
                        />
                        {errors.gender && <p className="font-size-14 text-[#ff0000]">{errors.gender.message}</p>}
                    </div>
                    {/* gender dropdown */}
                    <div className="mb-3">
                        <Controller
                            name="mobile"
                            control={control}
                            rules={{ required: 'Mobile is required' }}
                            render={({ field }) => (
                                <SimpleInput
                                    isLabel={true}
                                    labelText='Mobile'
                                    type="text"
                                    inputMode="numeric" // Suggests a numeric keyboard on mobile devices
                                    minLength={10}
                                    maxLength={15}
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                        {errors.mobile && <p className="font-size-14 text-[#ff0000]">{errors.mobile.message}</p>}
                    </div>
                    {/* <div className="mb-3">
                        <Controller
                            name="address"
                            control={control}
                            rules={{ required: 'Location is required' }}
                            render={({ field }) => (
                                <SimpleInput
                                    isLabel={true}
                                    labelText='Location'
                                    type="text"
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                        {errors.address && <p className="font-size-14 text-[#ff0000]">{errors.address.message}</p>}
                    </div> */}
                    <div className="border_hide_and_primary_border">
                        <label className="poppins-regular font-size-16px text-[#252334]">City</label>
                        <Controller
                            name="city"
                            control={control}
                            rules={{ required: 'City is required' }}
                            render={({ field }) => (
                                <SelectDropdown
                                    options={cityList}
                                    data={cityList.filter(v => v?.value === field.value)}
                                    setData={(e) => field.onChange(e[0]?.value || '')}
                                    isMulti={false}
                                />
                            )}
                        />
                        {errors.city && <p className="font-size-14 text-[#ff0000]">{errors.city.message}</p>}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="">
                            <Controller
                                name="address"
                                control={control}
                                rules={{ required: 'Location is required' }}
                                render={({ field }) => (
                                    <SimpleInput
                                        isLabel={true}
                                        labelText='Location'
                                        type="text"
                                        extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                        {...field}
                                    />
                                )}
                            />
                            {errors.address && <p className="font-size-14 text-[#ff0000]">{errors.address.message}</p>}
                        </div>
                        <div>
                            <Controller
                                name="postCode"
                                control={control}
                                rules={{
                                    required: 'Post Code is required',
                                    pattern: {
                                        value: /^[0-9]{4,7}$/, // Ensures the value is exactly 4 digits
                                        message: 'Post Code must be between 4 and 7 digits'
                                    }
                                }}
                                render={({ field }) => (
                                    <SimpleInput
                                        type="text"
                                        inputMode={"numeric"}
                                        minLength={4}
                                        maxLength={7}
                                        isLabel={true}
                                        labelText='Post Code'
                                        extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                        {...field}
                                    />
                                )}
                            />
                            {errors.postCode && <p className="font-size-14 text-[#ff0000]">{errors.postCode.message}</p>}
                        </div>
                    </div>
                    <hr className='my-4' />
                    {
                        userDetails?.userRole === customer &&
                        <>
                            {/* <h2 className='title-heading font-Poppins-SemiBold font-size-20px'>Headline</h2> */}
                            <div>
                                <Controller
                                    name="headLine"
                                    control={control}
                                    rules={{ required: 'Headline is required' }}
                                    render={({ field }) => (
                                        <SimpleInput
                                            type="textArea"
                                            isLabel={true}
                                            labelText="Headline"
                                            isLimit={500}
                                            maxLength={500}
                                            rows={3}
                                            extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.headLine && <p className="font-size-14 text-[#ff0000]">{errors.headLine.message}</p>}
                            </div>
                            <hr className='my-4' />
                        </>
                    }
                    <h2 className='title-heading font-Poppins-SemiBold font-size-20px mb-4'>Social Links</h2>
                    <div className='mb-3'>
                        <label className='font-size-18px font-Poppins-Regular flex items-center gap-2' htmlFor=""><FontAwesomeIcon className='text-[#00443F]' size="1x" icon={faFacebookSquare} />Facebook</label>
                        <Controller
                            name="facebookLink"
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <SimpleInput
                                    type="url"
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className='mb-3'>
                        <label className='font-size-18px font-Poppins-Regular flex items-center gap-2' htmlFor=""><FontAwesomeIcon className='text-[#00443F]' size="1x" icon={faInstagram} />Instagram</label>
                        <Controller
                            name="instagramLink"
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <SimpleInput
                                    type="url"
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className='mb-3'>
                        <label className='font-size-18px font-Poppins-Regular flex items-center gap-2' htmlFor=""><FontAwesomeIcon className='text-[#00443F]' size="1x" icon={faLinkedin} />LinkedIn</label>
                        <Controller
                            name="linkedinLink"
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <SimpleInput
                                    type="url"
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className='mb-3'>
                        <label className='font-size-18px font-Poppins-Regular flex items-center gap-2' htmlFor=""><FontAwesomeIcon className='text-[#00443F]' size="1x" icon={faWhatsapp} />WhatsApp</label>
                        <Controller
                            name="whatsappLink"
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <SimpleInput
                                    type="url"
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className="flex justify-end mt-5">
                        <PrimaryBtn
                            loader={isLoader}
                            type='submit'
                            ButtonText='Save'
                            btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        />
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditProfile;