/**
 * SimpleInput Component
 * 
 * Description: This component renders a styled input field or textarea. It provides customization options such as setting the input type, placeholder text, value, and change handler. It supports numeric and text inputs with validation for length and character type.
 * 
 * Props:
 * - value (string): The value of the input field. Defaults to an empty string.
 * - onChange (function): A callback function that is called when the value of the input field changes. Defaults to an empty function.
 * - isLabel (boolean): Determines whether a label should be displayed for the input field. Defaults to false.
 * - labelText (string): The text to be displayed in the label if `isLabel` is true. Defaults to an empty string.
 * - isRequired (boolean): Indicates if the input field is required. Adds a red asterisk to the label if true. Defaults to false.
 * - type (string): The type of the input field (e.g., "text", "password"). Defaults to "text".
 * - placeholder (string): The placeholder text displayed in the input field. Defaults to an empty string.
 * - isLimit (number | false): The maximum length of the input value. If set, displays a character count. Defaults to false.
 * - rows (number): The number of rows for textarea input. Defaults to 1.
 * - extraStyle (React.CSSProperties): Additional CSS styles to apply to the input field. Defaults to an empty object.
 * - inputMode ("none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search"): Defines the input mode for the input field. Defaults to "none".
 * - maxLength (number | undefined): The maximum number of characters allowed in the input field. Defaults to undefined.
 * - minLength (number | undefined): The minimum number of characters required in the input field. Defaults to undefined.
 * 
 * File Name: SimpleInput.tsx
 * Date: 24-07-2024
 */

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface ISimpletInputProps {
  value: string;
  onChange: (value: string) => void; // Corrected type
  isLabel?: boolean;
  labelText?: string;
  isRequired?: boolean
  type?: string;
  placeholder?: string;
  isLimit?: number | false;
  rows?: number
  extraStyle?: React.CSSProperties; // Correct type for CSS styles
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined;
  maxLength?: number | undefined;
  minLength?: number | undefined;
  showPasswordEye?: boolean;
  tooglePasswordVisibility?: () => void;
  [key: string]: any;
};

const SimpleInput: React.FC<ISimpletInputProps> = ({
  value = "",
  onChange = () => { },
  isLabel = false,
  labelText = "",
  isRequired = false,
  type = "text",
  placeholder = "",
  isLimit = false,
  rows = 1,
  extraStyle = {},
  inputMode = "none",
  maxLength = undefined,
  minLength = undefined,
  showPasswordEye = false,
  tooglePasswordVisibility = () => { },
  ...props
}) => {

  // Handle input change and validation
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = e.target.value;

    // Filter numeric values if inputMode is numeric
    if (inputMode === "numeric") {
      newValue = newValue.replace(/[^0-9]/g, '');
    }

    if (inputMode === "text") {
      newValue = newValue.replace(/[^A-Za-z]/g, '');
    }
    // Apply maxLength and minLength constraints
    if (maxLength && newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength);
    }
    if (minLength && newValue.length < minLength) {
      // Optionally handle cases where the length is less than minLength
    }

    // Update the value
    onChange(newValue);
  };
  return (
    <>
      {isLabel &&
        <label className='font-size-18px font-Poppins-Regular'>
          {isRequired &&
            <span className='text-[#FF0000]'>* </span>
          }
          {labelText}
        </label>
      }
      {
        type === "textArea"
          ?
          <div className="mb-2">
            <textarea
              className='create-role-input font-size-16px font-Poppins-Regular mb-0'
              value={value}
              onChange={handleChange}
              placeholder={placeholder}
              required={isRequired}
              rows={rows}
              style={extraStyle}
              inputMode={inputMode}
              maxLength={maxLength}
              minLength={minLength}
              {...props}
            >
            </textarea>
            {isLimit && <p className='text-end font-size-14px'>{`${value.length}/${isLimit}`}</p>}
          </div>
          :
          <div className='relative'>
            <input
              className='create-role-input font-size-16px font-Poppins-Regular'
              value={value}
              inputMode={inputMode}
              onChange={handleChange}
              placeholder={placeholder}
              type={type}
              required={isRequired}
              style={extraStyle}
              maxLength={maxLength}
              minLength={minLength}
              {...props}
            />
            {showPasswordEye && (
              <div>
                <FontAwesomeIcon
                  icon={type === "password" ? faEyeSlash : faEye}
                  className="input-field-eye absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => tooglePasswordVisibility()}
                />
              </div>
            )}
          </div>
      }
    </>
  );
};

export default SimpleInput;