/**
 * ProfileUserDetailDashboard
 * 
 * Description: This component displays the profile details for users, including doctors and customers.
 * It handles image uploads for profile and cover photos, image resizing, and user-specific data.
 * 
 * File Name: ProfileUserDetailDashboard.tsx
 * Date: 02-09-2024
 */


import React, { useState, useRef, ChangeEvent } from "react";
import { faCamera, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DefaultBackgroundImage from "../../assets/images/welcome-hudson.png";
import defaultProfile from "../../assets/images/default_profile_image.png";
import edit from "../../assets/images/awesome-edit.svg";
import CloseIcon from "../../assets/images/closeIcon.png";
import camraicon from "../../assets/images/camraicon.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AvatarEditor from "react-avatar-editor";
import { IDoctorProfile, IProfileData } from "src/types/GlobalInterfaces";
import httpRequest from "src/helpers/httpRequest";
import PrimaryBtn from "../GlobalComponents/primaryBtn";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@store/store";
import { setUniqueData } from "src/Store/reducer";
import { doctor } from "src/lib/constans";
import { convertMonthsToReadableFormat, getInitialsImage } from "src/helpers/helperFunctions";

// Define the props interface for the component
interface IProfileProps {
    data: IProfileData;
    setData?: React.Dispatch<React.SetStateAction<IProfileData>>;
    profile?: IDoctorProfile | null
};

// Define the component as a React Functional Component
const ProfileUserDetailDashboard: React.FC<IProfileProps> = ({
    data = {},
    setData = () => { },
    profile = null
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { userDetails } = useSelector((store: RootState) => store.appReducer);

    const editorRef = useRef<AvatarEditor>(null); // reference of avatar editor
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [isImageModal, setIsImageModal] = useState<null | string>(null); // upload image modal
    const [displayImage, setDisplayImage] = useState<string | ArrayBuffer | null>(null);

    // Function to handle cover image change
    const handleImageChange = (event: ChangeEvent<HTMLInputElement>, category: string) => {
        const file = event.target.files?.[0];
        const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
        if (file) {
            if (file.size > maxSize) {
                toast("File size exceeds the maximum allowed size (5 MB). Please choose a smaller file.", { type: "error" });
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setDisplayImage(reader.result);
                setIsImageModal(category);
            };
            reader.readAsDataURL(file);
            // Clear the selected file from the input element
            event.target.value = "";
        }
    };

    // Function to close the image modal
    const handleCloseImageModal = () => {
        setIsImageModal(null);
        setDisplayImage(null);
    };

    // Function to handle image resizing
    const handleImageResize = async () => {
        if (editorRef.current) {
            try {
                const canvas = editorRef.current.getImageScaledToCanvas?.();
                if (canvas instanceof HTMLCanvasElement) {
                    return new Promise<Blob>((resolve, reject) => {
                        canvas.toBlob((blob) => {
                            if (!blob) {
                                reject(new Error("Failed to convert canvas to blob"));
                                return;
                            }
                            resolve(blob);
                        }, "image/png");
                    })
                        .then((blob) => {
                            const file = new File([blob], "image.png", {
                                lastModified: new Date().getTime(),
                                type: "image/png",
                            });
                            handleImageUpload(file); // Ensure handleImageUpload is defined
                        });
                } else {
                    throw new Error("Failed to get a valid canvas from the editor.");
                }
            } catch (error: any) {
                toast(error?.message || "An error occurred while processing the image.", { type: 'error' });
            }
        }
    };

    // Dummy function for demonstration; replace with actual upload logic
    const handleImageUpload = async (file: File) => {
        setIsLoader(true);
        const imageType = isImageModal === "cover" ? "true" : "false"; // Convert boolean to string

        const formData = new FormData();
        formData.append("image", file);
        formData.append("isCoverPhoto", imageType);
        const { res, err } = await httpRequest<{ updatedImageUrl: string; message: string }>({ method: "put", path: `/customer/change-profile-photo`, params: formData, header: { 'Content-Type': 'multipart/form-data' } });
        if (res) {
            const { updatedImageUrl = "", message = "" } = res;
            if (isImageModal === "cover") {
                dispatch(setUniqueData({ key: 'coverPhoto', value: updatedImageUrl }));
                setData((prev: IProfileData) => ({
                    ...prev,
                    coverPhoto: updatedImageUrl
                }));
            } else {
                dispatch(setUniqueData({ key: 'backgroundPicture', value: updatedImageUrl }));
                setData((prev: IProfileData) => ({
                    ...prev,
                    backgroundPicture: updatedImageUrl
                }));
            };
            toast(message, { type: "success" }); // api success message
            setIsImageModal(null);
            setDisplayImage(null);
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    return (
        <>
            <div className="shadow-lg bg-white rounded-xl mb-2 pb-3 ">
                {/* background upload */}
                <div className="cover-img relative">
                    <img
                        src={`${process.env.REACT_APP_API_BASEURL}${data?.backgroundPicture}?t=${Date.now()}`}
                        onError={(e) => {
                            const target = e.target as HTMLImageElement; // Assert the type
                            if (target) {
                                target.src = DefaultBackgroundImage;
                            }
                        }}
                        alt="cover"
                        width={"100%"}
                    />
                    {(data?._id === userDetails?._id) &&
                        <>
                            <label
                                htmlFor="imageInput"
                                className="absolute icon-size right-5 top-5 z-10 bg-white rounded-full flex items-center justify-center w-8 h-8 "
                            >
                                <FontAwesomeIcon icon={faCamera} color="#21948C" />
                            </label>
                            <input
                                id="imageInput"
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleImageChange(e, "background")}
                                style={{ display: "none" }}
                            />
                        </>
                    }
                </div>
                <div className="px-4 flex justify-between">
                    <div className="profile-img-uploader relative">
                        <img
                            src={`${process.env.REACT_APP_API_BASEURL}${data?.coverPhoto}?t=${Date.now()}`}
                            onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.src = getInitialsImage(`${data?.firstName || ""} ${data?.lastName || ""}`, 112, 48, "#00443f");
                            }}
                            // onError={(e) => {
                            //     const target = e.target as HTMLImageElement; // Assert the type
                            //     if (target) {
                            //         target.src = defaultProfile;
                            //     }
                            // }}
                            alt="profile image"
                            className="rounded-full"
                        />
                        {(data?._id === userDetails?._id) &&
                            <>
                                <label
                                    htmlFor="imageInput2"
                                    className="absolute -right-2 bottom-5 rounded-full flex items-center justify-center w-8 h-8  z-0"
                                >
                                    <img src={camraicon} alt="" className="icon-size" />
                                </label>
                                <input
                                    id="imageInput2"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(e, "cover")}
                                    style={{ display: "none" }}
                                />
                            </>
                        }
                    </div>
                    {(data?._id === userDetails?._id) &&
                        <Link to="/edit-profile" className="mt-5 edit-pro-icon ">
                            <img src={edit} alt="" />
                        </Link>
                    }
                </div>
                <div className="user-name">
                    <h4 className="font-size-32px font-Poppins-SemiBold theme-color-green capitalize">
                        {`${data?.firstName || ""} ${data?.lastName || ""}`}
                    </h4>
                    {data?.userRole === doctor ?
                        <>
                            <div>
                                <p className="font-size-20px font-Poppins-Regular theme-color-green flex items-center gap-2">
                                    {profile?.specailizedDetails?.map((v, i) => (
                                        <>
                                            <span key={i}>{v.name}</span>
                                            {i < profile?.specailizedDetails?.length - 1 && <span>|</span>}
                                        </>
                                    ))}
                                </p> {/* specialization */}
                                <span className="font-size-18px font-Poppins-Regular text-[#393939]">
                                    {profile?.educatuonHistory?.map((v, i) => (
                                        <span key={i}>
                                            <span key={v}>{v}</span>
                                            {i < profile?.educatuonHistory?.length - 1 && <span>, </span>}
                                        </span>
                                    ))}
                                </span> {/* educations & degree */}
                                <div className="stars flex gap-1 items-center mt-2">
                                    {[1, 2, 3, 4, 5].map((v) => (
                                        <FontAwesomeIcon
                                            className={(profile?.reviewStar || 0) >= v ? "text-[#facc15]" : `text-[#b7b6b1]`}
                                            key={v}
                                            role="button"
                                            fontSize="16px"
                                            icon={faStar}
                                        />
                                    ))}
                                </div>
                            </div>

                            <div className="mt-6 flex gap-[20px] items-center">
                                <div className="doctor-experience">
                                    <p className="font-size-18px font-Poppins-Regular text-[#707070] mb-2">
                                        Experience
                                    </p>
                                    <h1 className="font-size-20px font-Poppins-Medium">
                                        {convertMonthsToReadableFormat(profile?.workExperienceMonthly || 0)}
                                    </h1>
                                </div>

                                <div className="doctor-experience">
                                    <p className="font-size-18px font-Poppins-Regular text-[#707070] mb-2">
                                        Reviews
                                    </p>
                                    <h1 className="font-size-20px font-Poppins-Medium">
                                        {profile?.reviews?.length || 0}
                                    </h1>
                                </div>
                            </div>
                        </>
                        :
                        <p className="font-size-16px font-Poppins-Regular">{data?.headLine || ""}</p> // if customer then only show headline
                    }
                </div>
            </div>

            {isImageModal && (
                <div className="ovrlayModal modal">
                    <div
                        className="createpost-modal absolute z-20"
                        style={isImageModal === "background" ? { width: "70vw" } : {}}
                    >
                        <button
                            className="absolute -top-5 font-size-25px"
                            style={{ right: "-10px" }}
                            disabled={isLoader}
                            onClick={handleCloseImageModal}
                        >
                            <img src={CloseIcon} alt="close" />
                        </button>
                        <div className="mb-3">
                            <h1 className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize">
                                {isImageModal} photo
                            </h1>
                            <hr />
                        </div>
                        {displayImage && typeof displayImage === "string" && (
                            <AvatarEditor
                                className="m-auto"
                                ref={editorRef}
                                image={displayImage}
                                width={isImageModal === "background" ? 700 : 220}
                                height={isImageModal === "background" ? 280 : 220}
                                border={20}
                                borderRadius={isImageModal === "background" ? 0 : 125}
                                scale={1}
                                rotate={0}
                                disableBoundaryChecks={false} // boolean value, not null
                                disableHiDPIScaling={true}    // boolean value, not null
                            />
                        )}
                        <div className="flex justify-end mt-3">
                            <PrimaryBtn
                                ButtonText="Update"
                                loader={isLoader}
                                type="button"
                                onPress={handleImageResize}
                                btnExtraStyle={{
                                    backgroundColor: "#005852",
                                    borderRadius: "1.5rem",
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ProfileUserDetailDashboard;