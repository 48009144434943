/**
 * ViewCustomerTest Component
 * 
 * Description: This component displays a list of test results for a specific customer. It includes functionality for search, pagination, and viewing individual test results. The data is fetched from an API based on the customer ID provided in the route parameters.
 * 
 * File Name: ViewCustomerTest.tsx
 * Date: 19-08-2024
 */

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import httpRequest from 'src/helpers/httpRequest';
import { IqueryParams, IResultList } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import { barColors } from 'src/lib/constans';

interface ICustomerResultsResponse {
    documents: IResultList[];
    paginated: IPaginated;
};

const ViewCustomerTest: React.FC = () => {
    const navigate = useNavigate();
    const { uId } = useParams(); // user id fetch from query
    const location = useLocation();
    const queryParamsUrl = new URLSearchParams(location.search);

    const isDoctor = queryParamsUrl.get('isdoctor') === 'true';
    const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state
    const [allResults, setAllResults] = useState<IResultList[]>([]); // List of results
    const [paginated, setPaginated] = useState<IPaginated>({ // Pagination state
        currentPage: 1,
        totalPages: 1,
    });
    const [queryParams, setQueryParams] = useState<IqueryParams>({ // Query parameters for API call
        page: 1,
        limit: 10,
        search: ""
    });

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            search: txt,
            page: 1
        }));
    };

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // API_CALL: Fetches customer results from the server
    const fetchCustomerResults = async (_id: string) => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ICustomerResultsResponse>({ path: `/test/${_id}?${qs.stringify(queryParams)}` });
        if (res) {
            const { documents = [], paginated } = res;
            setAllResults(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (uId) {
            fetchCustomerResults(uId); // Fetch results when user ID is available or queryParams change
        }
    }, [queryParams, uId]);

    return (
        <>
            <BreadCrumb
                current="Tests"
                previous="dashboard"
                navigateUrl="/admin"
                btnShow={true}
                isUpsert={true}
                onPress={() => navigate(isDoctor ? "/doctors" : "/customers")}
            />
            <div className="table-container">
                <SearchFilterInput
                    onChangeText={handleChangeText}
                />
                <TableWrapper
                    isLoading={isLoader}
                    page={paginated?.currentPage || 1}
                    total={paginated?.totalPages || 0}
                    thead={["S.No", "Test Name", "Date", "Results", "View"]}
                    onChange={handlePageChange}
                    columnWidth={"6% 45% 15% 20% 14%"}
                >
                    <div className="tbody-content-height">
                        {allResults?.map((item: IResultList, index: number) => (
                            <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 45% 15% 20% 14%" }}>
                                <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                <li>{item?.testName || ""}</li>
                                {/* <li>{item?.docterName || ""}</li> */}
                                <li>{moment(item?.date).format("DD-MM-YYYY")}</li>
                                <li className="result__count__circle-main">
                                    <div className='flex'>
                                        <div className='pr-4'>
                                            <span className="result__count__circle" style={{ backgroundColor: barColors.green }}>{item.green}</span>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            height: '90%',
                                            border: "1px solid rgb(199, 199, 199)",
                                        }}
                                    ></div>
                                    <div className='flex'>
                                        <div className='px-4'
                                        // style={{
                                        //     borderLeft: "1px solid rgb(199, 199, 199)",
                                        //     borderRight: "1px solid rgb(199, 199, 199)"
                                        // }}
                                        >
                                            <span className="result__count__circle" style={{ backgroundColor: barColors.orange }}>{item.orange}</span>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            height: '90%',
                                            border: "1px solid rgb(199, 199, 199)",
                                        }}
                                    ></div>
                                    <div className='flex'>
                                        <div className='px-4'>
                                            <span className="result__count__circle" style={{ backgroundColor: barColors.red }}>{item.red}</span>
                                        </div>
                                    </div>
                                </li>
                                <li className="action-last-colum">
                                    <Link to={`/result/${item?._id}/view`}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Link>
                                </li>
                            </ul>
                        ))}
                    </div>
                </TableWrapper>
            </div>
        </>
    );
};

export default ViewCustomerTest;