/**
 * Operators Component
 * 
 * Description: This component manages the operators section of the admin panel. It includes functionality for listing operators, creating or editing operators, and deleting operators. It features a paginated table display, a confirmation modal for deletions, and integrates with an API to fetch and manage operator data.
 * 
 * File Name: Operators.tsx
 * Date: 25-07-2024
 */

import React, { useEffect, useState } from 'react';
import TableWrapper from '../../Components/GlobalComponents/TableWrapper';
import BreadCrumb from '../../Components/GlobalComponents/BreadCrumb';
import UpsertOperator from '../../Components/AdminComponents/UpsertOperator';
import { toast } from 'react-toastify';
import httpRequest from '../../helpers/httpRequest';
import qs from 'qs';
import moment from 'moment';
import ConfirmationModal from '../../Components/GlobalComponents/ConfirmationModal';
import editIcon from '../../assets/images/edit-icon.png';
import deleteIcon from '../../assets/images/delete-icon.png';
import { IPaginated } from 'src/types/paginated';
import { IOperatorItems, IqueryParams } from 'src/types/GlobalInterfaces';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';

// Define the response type
interface IOperatorResponse {
  documents: IOperatorItems[];
  paginated: IPaginated;
}

const Operators: React.FC = () => {
  const [isUpsert, setIsUpsert] = useState<boolean>(false); // State to manage the upsert mode (create/edit operator)
  const [isEdit, setIsEdit] = useState<IOperatorItems | boolean>(false); // State to determine if the component is in edit mode

  const [deleteLoader, setDeleteLoader] = useState<boolean>(false); // State to manage the loader during operator deletion
  const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage the loader for fetching operators
  const [allOperators, setAllOperators] = useState<IOperatorItems[]>([]); // State to store the list of operators
  const [paginated, setPaginated] = useState<IPaginated>({
    currentPage: 1,
    totalPages: 1,
  }); // state for handle pagination like a current page, page limit & total pages

  const [deleteModal, setDeleteModal] = useState<boolean>(false); // State to control the visibility of the delete confirmation modal
  const [deleteId, setDeleteId] = useState<string>("");  // State to store the ID of the operator to be deleted

  const [queryParams, setQueryParams] = useState<IqueryParams>({
    page: 1,
    limit: 10,
    search: "",
    counter: 1
  });

  // Function to toggle between create and edit mode
  const handleClose = () => {
    setIsEdit(false);
    setIsUpsert(prev => !prev);
  };

  // Function to set the component to edit mode and populate it with operator data
  const handleEdit = (obj: IOperatorItems) => {
    setIsUpsert(true);
    setIsEdit(obj);
  };

  const handlePageChange = (e: number) => {
    setQueryParams(prev => ({
      ...prev,
      page: e
    }));
  };

  // Updates search text in query parameters
  const handleChangeText = (txt: string) => {
    setQueryParams(prev => ({
      ...prev,
      search: txt,
      page: 1
    }));
  };

  // Function to show the delete confirmation modal and set the ID of the operator to be deleted
  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setDeleteId(id);
  };

  // Function to close the delete confirmation modal
  const deleteModalClose = () => {
    setDeleteModal(false);
    setDeleteId("");
  };

  // Function to update the list of operators after creating or editing a role
  const handleUpdateList = () => {
    setQueryParams(prev => ({
      ...prev,
      counter: (prev.counter ?? 0) + 1,
    }));
    setIsUpsert(false);
  };

  // API_CALL: Function to delete a operator by its ID
  const deleteOperator = async () => {
    setDeleteLoader(true);
    const { res, err } = await httpRequest<any>({ method: "delete", path: `/operators/delete/${deleteId}`, params: {} });
    if (res) {
      toast("Operator deleted successfully.", { type: 'success' });
      setQueryParams(prev => ({
        ...prev,
        counter: (prev.counter ?? 0) + 1,
        page: allOperators?.length > 1 ? prev?.page : prev?.page > 1 ? prev?.page - 1 : prev?.page
      }));
      setDeleteModal(false);
      setDeleteId("");
    } else {
      toast(err, { type: 'error' });
    }
    setDeleteLoader(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoader(true);
      let { counter, ...remainingParams } = queryParams;
      const { res, err } = await httpRequest<IOperatorResponse>({ path: `/operators/all?${qs.stringify(remainingParams)}` });
      if (res) {
        setAllOperators(res?.documents || []);
        setPaginated(res?.paginated || {});
      } else {
        toast(err, { type: 'error' });
      }
      setIsLoader(false);
    })();
  }, [queryParams]);

  return (
    <>
      <BreadCrumb
        current="Operators"
        previous="dashboard"
        navigateUrl="/admin"
        btnText={"Add Operator"}
        btnShow={true}
        onPress={handleClose}
        isUpsert={isUpsert}
      />
      <div className="admin-operators">
        <ConfirmationModal
          show={deleteModal}
          onClose={deleteModalClose}
          title="Are you sure you want to delete?"
          leftBtnText="No"
          rightBtnText="Yes"
          onConfirm={deleteOperator}
          loader={deleteLoader}
        />
        {
          isUpsert
            ?
            <UpsertOperator
              onSuccess={handleUpdateList}
              isEdit={isEdit}
            />
            :
            <div className="table-container">
              <SearchFilterInput
                onChangeText={handleChangeText}
              />
              <TableWrapper
                isLoading={isLoader}
                page={paginated?.currentPage || 1}
                total={paginated?.totalPages || 0}
                thead={["S.No", "User Name", "Email", "Role", "Created date", "Last login", "Action"]}
                onChange={handlePageChange}
                columnWidth={"6% 18% 20% 14% 12% 20% 10%"}
              >
                <div className='tbody-content-height'>
                  {allOperators?.map((item: IOperatorItems, index: number) => (
                    <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 18% 20% 14% 12% 20% 10%" }}>
                      <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                      <li>{item?.userName || ""}</li>
                      <li className="break-all">{item?.email || ""}</li>
                      <li><span className='bg-[#00443f] font-size-12px font-Poppins-SemiBold text-[#fff] operators-btn capitalize'>{item?.roleName || ""}</span></li>
                      <li>{moment(item?.createdAt).format("DD-MM-YYYY") || ""}</li>
                      <li className={item?.lastLogin === null ? "" : ""}>{item?.lastLogin ? moment(item?.lastLogin).format("DD-MM-YYYY - hh:mm A") : "-"}</li>
                      <li className='action-last-colum'>
                        <img onClick={() => handleEdit(item)} src={editIcon} alt="" />
                        <img onClick={() => handleDelete(item?._id)} src={deleteIcon} alt="" />
                      </li>
                    </ul>
                  ))}
                </div>
              </TableWrapper>
            </div>
        }
      </div>
    </>
  );
};

export default Operators;