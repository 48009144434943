/**
 * OrderDetailsView Component
 * 
 * Description: Component to display the details view of an order including fetching order details, loading state, and page header
 * 
 * File Name: OrderDetailsView.tsx
 * Date: 15-08-24
 */

import React, { useEffect, useState } from 'react';
import PageHeader from 'src/Components/Customer/PageHeader';
import BackIcon from "src/assets/images/back-icon.png";

import { useNavigate, useParams } from 'react-router-dom';
import httpRequest from 'src/helpers/httpRequest';
import { IOrderDetails } from 'src/types/GlobalInterfaces';
import { toast } from 'react-toastify';
import SplashScreen from 'src/Components/SplashScreen';
import DetailView from 'src/Components/GlobalComponents/DetailView';

const OrderDetailsView: React.FC = () => {
    const { id = "" } = useParams(); // Extract order ID from URL parameters
    const navigate = useNavigate();

    // Component to display a back button in the page header
    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    // State to manage loading state and order details
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [item, setItem] = useState<IOrderDetails | null>(null);

    // API_CALL: Fetch order details by ID
    const fetchOrderById = async (_id: string) => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IOrderDetails>({ path: `/order/${_id}` });
        if (res) {
            setItem(res); // Update state with fetched order details
        } else {
            toast(err, { type: 'error' }); // Show error toast if the request fails
        }
        setIsLoader(false); // Hide loader once data is fetched
    };

    useEffect(() => {
        fetchOrderById(id);
    }, [id]); // Fetch order details when the component mounts or ID changes
    return (
        <>
            {
                isLoader
                &&
                <SplashScreen />
            }
            <PageHeader
                title="Order Invoice"
                isButton={true}
                ButtonComponent={BackButton}
            />
            {
                item
                &&
                <div className="order-detail-container">
                    <DetailView
                        item={item}
                        id={id}
                    />
                </div>
            }
        </>
    );
};

export default OrderDetailsView;