/**
 * Customers.tsx
 * 
 * Date: 08-08-24
 * 
 * Description: 
 * This component displays a list of customers with functionalities for searching, pagination, and viewing customer details.
 * It includes a breadcrumb for navigation, a search filter input for querying customers, and a table to list customer details.
 * The component fetches customer data from an API and updates the view based on user interactions.
 */

import React, { CSSProperties, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import httpRequest from 'src/helpers/httpRequest';
import { ICustomerItems, IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import ToggleRadioBtn from 'src/Components/ToggleRadioBtn';
import DeclineModalReason from 'src/Components/AdminComponents/DeclineModalReason';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';

interface ICustomerResponse {
    documents: ICustomerItems[];
    paginated: IPaginated;
};

const Customers: React.FC = () => {
    // Inline style for the buttons
    const btn__style: CSSProperties = {
        backgroundColor: "#00443f",
        color: "#fff",
        padding: "4px 15px",
        borderRadius: "1.5rem",
    };

    // decline with reason
    const [declineId, setDeclineId] = useState<string>("");
    const [declineLoader, setDeclineLoader] = useState<string | null>(null);
    const [message, setMessage] = useState<string>("");
    // Closes the decline modal
    const closeModal = () => {
        setDeclineId("");
        setMessage("");
    };

    const activeCall = async (id: string) => {
        setDeclineLoader(id);
        setAllCustomers(prev => prev.map(v => v._id === id ? { ...v, accountStatusByAdmin: true } : v));
        // Build the params object conditionally
        const { res, err } = await httpRequest<{ message: string }>({
            path: `/customer/account/status/${id}`,
        });
        if (res) {
            toast(res.message, { type: 'success' });
        } else {
            toast(err, { type: 'error' });
            setAllCustomers(prev => prev.map(v => v._id === id ? { ...v, accountStatusByAdmin: false } : v));
        }
        setDeclineLoader(null);
    };

    /**
* API_CALL: POST /customer/account/status/${id}
*/
    const handleStatusToogle = async (id: string) => {
        if (!message) {
            toast("Please enter reason!", { type: 'error' });
            return
        }
        setDeclineLoader(id);
        // Build the params object conditionally
        const { res, err } = await httpRequest<{ message: string; data: boolean }>({
            method: "post",
            path: "/customer/account/status",
            params: { customerId: id, message },
        });
        if (res) {
            toast(res.message, { type: 'success' });
            setAllCustomers(prev => prev.map(v => v._id === id ? { ...v, accountStatusByAdmin: res.data } : v));
            closeModal();
        } else {
            toast(err, { type: 'error' });
        }
        setDeclineLoader(null);
    };
    // decline with reason

    // States for loading status, pagination, and customer data
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [allCustomers, setAllCustomers] = useState<ICustomerItems[]>([]);

    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        search: ""
    });

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            search: txt,
            page: 1
        }));
    };

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // API_CALL: Fetches all customers from the server
    const fetchAllCustomers = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ICustomerResponse>({ path: `/customer?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setAllCustomers(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchAllCustomers();
    }, [queryParams]);

    const BackButton: React.FC = () => {
        return (
            <div className='mt-2 flex justify-center'>
                <PrimaryBtn
                    onPress={() => handleStatusToogle(declineId)}
                    type="button"
                    loader={declineLoader ? true : false}
                    ButtonText='Send' btnExtraStyle={{
                        borderRadius: "100rem",
                        backgroundColor: "#004540"
                    }}
                />
            </div>
        );
    }; // display back icon in page header

    return (
        <>
            <BreadCrumb
                current="Customers"
                previous="dashboard"
                navigateUrl="/admin"
                btnShow={false}
            />

            <div className="all-customers">
                <DeclineModalReason
                    title={`Please provide a reason for deactivation.`}
                    value={message}
                    setValue={setMessage}
                    isOpen={declineId ? true : false}
                    onClose={closeModal}
                    ButtonComponent={BackButton}
                />
                <div className="table-container">
                    <SearchFilterInput
                        onChangeText={handleChangeText}
                    />
                    <TableWrapper
                        isLoading={isLoader}
                        page={paginated?.currentPage || 1}
                        total={paginated?.totalPages || 0}
                        thead={["S.No", "Customer Name", "Email", "Phone Number", "Status", "Health Profile", "Action"]}
                        onChange={handlePageChange}
                        columnWidth={"6% 15% 20% 15% 12% 10% 22%"}
                    >
                        <div className='tbody-content-height'>
                            {allCustomers?.map((item: ICustomerItems, index: number) => (
                                <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 15% 20% 15% 12% 10% 22%" }}>
                                    <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                    <li className="capitalize">
                                        <Link className="underline" to={`/customer/${item?._id}/View`}>
                                            {`${item?.firstName || ""} ${item?.lastName || ""}`}
                                        </Link>
                                    </li>
                                    <li>{item?.email || ""}</li>
                                    <li>{item?.mobile || ""}</li>
                                    <li className='justify-center' style={{ justifyContent: 'center', paddingLeft: 0, }}>
                                        <ToggleRadioBtn
                                            isToggled={item.accountStatusByAdmin}
                                            handleToggle={
                                                () => {
                                                    item.accountStatusByAdmin
                                                        ?
                                                        setDeclineId(item._id)
                                                        :
                                                        activeCall(item._id)
                                                }
                                            }
                                            loader={item._id === declineLoader ? true : false}
                                        />
                                    </li>
                                    <li className="action-last-colum justify-center" style={{ justifyContent: 'center' }}>
                                        <Link className="" to={`/health-profile/${item?._id}/View`}>
                                            <FontAwesomeIcon icon={faEye} />
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="flex gap-2">
                                            <Link style={btn__style} to={`/view/${item?._id}/all-test`}>
                                                All Tests
                                            </Link>
                                            <Link style={btn__style} to={`/add-result/${item?._id}/View`}>
                                                Add Test
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            ))}
                        </div>
                    </TableWrapper>
                </div>
            </div>
        </>
    );
};

export default Customers;