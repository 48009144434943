import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/store';
import defaultProfile from "src/assets/images/default_profile_image.png";
import { declineCall } from 'src/Store/VideoCall';
import RingTone from "src/assets/audio/ringtone.mp3";
import { useNavigate } from 'react-router-dom';

const CallReceiveModal: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { inComingCall } = useSelector((state: RootState) => state.VideoCall);
    const audioRef = useRef<HTMLAudioElement>(null);

    // Handlers for button clicks
    const handleAccept = () => {
        navigate(`/online-consultation/${inComingCall?.appointmentId}/${inComingCall?.roomId}/view`);
        dispatch(declineCall());
    };

    const handleDecline = () => {
        dispatch(declineCall());
    };

    // Play audio when the modal is shown
    useEffect(() => {
        if (inComingCall && audioRef.current) {
            const playAudio = async () => {
                try { await audioRef.current?.play() }
                catch (error) { console.error("Audio playback failed:", error) }
            };
            playAudio();
        }
    }, [inComingCall]);

    return (
        <>
            {inComingCall &&
                <div style={{ zIndex: 9999 }} className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
                    < div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg" >
                        <div className="flex flex-col items-center gap-5">
                            <div className="w-20 h-20 bg-gray-200 rounded-full overflow-hidden">
                                <img
                                    alt="User"
                                    className="w-full h-full object-cover"
                                    src={`${process.env.REACT_APP_API_BASEURL}${inComingCall.image}`}
                                    onError={(e) => {
                                        const target = e.target as HTMLImageElement; // Assert the type
                                        if (target) {
                                            target.src = defaultProfile;
                                        }
                                    }}
                                />
                            </div>
                            <div className="text-center">
                                <h3 className="text-lg font-semibold capitalize">{inComingCall.doctorName}</h3>
                            </div>
                            <div className="flex justify-center gap-5">
                                <button
                                    onClick={handleAccept}
                                    className="bg-green-500 text-white p-2 rounded flex items-center"
                                >
                                    <FontAwesomeIcon shake={true} icon={faPhone} className="mr-2" />
                                    Accept
                                </button>
                                <button
                                    onClick={handleDecline}
                                    className="bg-red-500 text-white p-2 rounded flex items-center"
                                >
                                    <FontAwesomeIcon icon={faPhoneSlash} className="mr-2" />
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div>
                    <audio ref={audioRef} src={RingTone} loop />
                </div>
            }
        </>
    );
};

export default CallReceiveModal;