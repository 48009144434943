/**
 * Component Name: AllProducts
 * Description: This component fetches and displays a list of products with pagination, search/filter functionality, and options to add 
 * products to the cart. It also handles clipboard copying for product links and customer suggestions.
 * File Name: AllProducts.tsx
 * Date: 13-08-2024
 */

import React, { useEffect, useState, useRef } from 'react';
import { Skeleton, Modal } from '@mui/material';
import httpRequest from 'src/helpers/httpRequest';
import testInclude from 'src/assets/images/test-include.png';
import bloodSample from "src/assets/images/blood-sample.png";
import { IProductItems, IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import { toast } from 'react-toastify';
import { truncateString } from 'src/helpers/helperFunctions';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import PaginationComponet from 'src/Components/GlobalComponents/PaginationComponet';
import SplashScreen from 'src/Components/SplashScreen';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart } from 'src/Store/Cart';
import { Link } from 'react-router-dom';
import { AppDispatch, RootState } from '@store/store';
import AddToCartModal from 'src/Components/Customer/AddToCartModal';
import defaultProfile from "src/assets/images/default_profile_image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Cookies from 'js-cookie';
import { customer, doctor, doctorMode } from 'src/lib/constans';

interface IProductResponse {
    documents: IProductItems[];
    paginated: IPaginated;
};

interface ICustomerResponse {
    _id: string;
    firstName: string;
    lastName: string;
    photo: string;
};

const AllProducts: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const dispatch = useDispatch<AppDispatch>();
    const _add_to_cart = (item: IProductItems) => {
        const { productName, salePrice, regularPrice, _id } = item;
        const obj = { productName, salePrice: Number(salePrice), regularPrice: Number(regularPrice), _id };
        dispatch(addItemToCart(obj));
        setIsModal(true);
    }; // Function to handle adding a product to the cart

    const [isModal, setIsModal] = useState<boolean>(false);
    const [isLoader, setIsLoader] = useState<boolean>(true); // State to manage the loading spinner visibility
    const [allProducts, setAllProducts] = useState<IProductItems[]>([]); // State to hold all products and pagination data
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // state for handle pagination like a current page, page limit & total pages

    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 6,
        searchCategory: "",
        search: "",
    }); // State to manage query parameters for fetching products

    // Ref for the options menu
    const optionsRef = useRef<HTMLDivElement | null>(null);
    const [mode, setMode] = useState(Cookies.get(doctorMode) || customer);

    const [allCustomers, setAllCustomers] = useState<ICustomerResponse[]>([]); // state to manage all appointed customers of current logged in doctor...
    const [customerId, setCustomerId] = useState<null | string>(null); // state to manage selections of customers...
    const [productId, setProductId] = useState<null | string>(null);
    const [modalLoader, setModalLoader] = useState<boolean>(false); // state to mangae modal loader while fetching the data...
    const [sendLoader, setSendLoader] = useState<boolean>(false); // state to send loader while fetching the data...
    const [showOptionsId, setShowOptionsId] = useState<string>('');
    const [searchCustomer, setSearchCustomer] = useState<string>("");

    const handleChangeFilter = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            search: txt,
        }));
    }; // Function to handle search filter changes

    const handleChangePage = (_page: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: _page,
        }));
    }; // Function to handle page change for pagination

    const copyToClipBoard = (id: string) => {
        try {
            const textarea = document.createElement("textarea");
            textarea.value = `${process.env.REACT_APP_WEBSITE_URL}/product/${id}/view`;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);
            toast("Copied to clipboard.", { type: "success" });
        } catch (err) {
            toast("Something went wrong.", { type: "error" });
        }
        setShowOptionsId("");
    }; // Function to copy the product link to the clipboard

    const handleShowOptionsId = (index: number) => {
        if (String(index) === showOptionsId) {
            setShowOptionsId('');
            return
        };
        setShowOptionsId(String(index))
    }; // Function to handle the toggle of the options menu

    // API_CALL: Function to fetch products based on the current query parameters
    const fetchAllProducts = async () => {
        const { res, err } = await httpRequest<IProductResponse>({ path: `/product?${qs.stringify(queryParams)}` });
        if (res) {
            setAllProducts(res?.documents);
            setPaginated(res?.paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // API_CALL: Function to fetch customers with fixed appointments with the current doctor
    const fetchAllCustomers = async () => {
        setModalLoader(true);
        const { res, err } = await httpRequest<{ documents: ICustomerResponse[] }>({ path: "/appoiment/all/refer/customer" });
        if (res) {
            setAllCustomers(res.documents);
        } else {
            toast(err, { type: 'error' });
        }
        setModalLoader(false);
    };

    // API_CALL: Function to handle sending a product suggestion to a customer
    const handleSendSuggestion = async () => {
        setSendLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: "/appoiment/add/refer",
            params: {
                customerId,
                productId,
            }
        });
        if (res) {
            toast(res.message, { type: 'success' });
            setCustomerId(null);
            setProductId(null);
        } else {
            toast(err, { type: 'error' });
        }
        setSendLoader(false);
    };

    // Effect to fetch products when queryParams or componentLoad changes
    useEffect(() => {
        fetchAllProducts();
    }, [queryParams]);

    // Effect to fetch appointed customers when the component mounts
    useEffect(() => {
        fetchAllCustomers();
    }, []);

    // Effect to handle click outside of options menu
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
                setShowOptionsId('');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <AddToCartModal
                show={isModal}
                onClose={() => setIsModal(false)}
            />
            {/* Search filter input */}
            <div className='parent-box parent-box-bottom-margin search-filter-margin-non'>
                <h2 className='title-heading font-Poppins-SemiBold font-size-20px'>All Products</h2>
                <SearchFilterInput
                    onChangeText={handleChangeFilter}
                />
            </div>
            {/* Display products in a grid */}
            {isLoader ?
                <SplashScreen />
                :
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2'>
                    {allProducts.length > 0
                        ?
                        allProducts.map((item: IProductItems, index: number) => (
                            <div key={index} className='product-card'>
                                <div className='flex items-start justify-end mt-[-5px] mr-[-5px] relative'>
                                    {showOptionsId === String(index) &&
                                        <div ref={optionsRef} className="absolute top-5 right-0 flex flex-col gap-[10px] p-[8px] rounded-[3px] shadow-[0_2px_5px_rgba(0,0,0,0.4)] min-w-[70px] bg-[#ffffff] z-30">
                                            <span onClick={() => copyToClipBoard(item._id)} className="font-Poppins-Regular font-size-13px cursor-pointer">
                                                Copy Link
                                            </span>
                                            {(mode === doctor && userDetails?.userRole === doctor) &&
                                                <span className="font-Poppins-Regular font-size-13px cursor-pointer"
                                                    onClick={() => setProductId(item._id)}
                                                >Share</span>
                                            }
                                        </div>
                                    }

                                    <button onClick={() => handleShowOptionsId(index)}>
                                        <FontAwesomeIcon icon={faEllipsisVertical} className='text-[#707070] text-[15px] z-30' />
                                    </button>
                                </div>

                                <h2 className='text-[#4F6367] font-size-23px poppins-Medium capitalize'>{truncateString(item?.productName || "", 20)}</h2>
                                <p className='text[#333333] test-product-description font-size-14px poppins-regular'>{truncateString(item?.description || "", 100)}</p>
                                <div className='product-card-test-include'>
                                    <img src={testInclude} alt="" />
                                    <span className='text[#333333] font-size-14px poppins-regular'>{item?.biomarkerSize || 0} Biomarkers Included</span>
                                </div>
                                <div className='product-card-test-include'>
                                    <img src={bloodSample} alt="" />
                                    <span className='text[#333333] font-size-14px poppins-Medium'>{item?.testypeDetails?.testName || ""}</span>
                                </div>
                                <div className="flex items-center gap-1">
                                    <span className='text[#4F6367] product-card-price font-size-22px font-Poppins-SemiBold'>{`AED ${item?.salePrice || 0}`}</span>
                                    <span className='text[#4F6367] product-card-price text-[#4F6367] font-size-18px font-Poppins-SemiBold line-through'>{`AED ${item?.regularPrice || 0}`}</span>
                                </div>
                                <div className='product-card-button-sub'>
                                    <button onClick={() => _add_to_cart(item)} className='text-white font-size-13px font-Poppins-SemiBold __button'>Add to Cart</button>
                                    <Link to={`/product-detail/${item._id}/view`} className='color-light-green text-[#009e92] font-size-13px font-Poppins-SemiBold __button text-center'>Learn more</Link>
                                </div>
                            </div>
                        ))
                        :
                        <p>No Products</p>
                    }

                    {productId && (
                        <Modal
                            open={productId ? true : false}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div style={customeStyle} className='bg-[#ffffff] w-[500px]  rounded-[8px]'>
                                <button onClick={() => {
                                    setProductId(null)
                                    setCustomerId(null)
                                }}
                                    style={backBtnMaain}>
                                    <FontAwesomeIcon className='font-size-16px ' icon={faXmark} size="2x" style={backBtn} />
                                </button>

                                <div className="flex items-center justify-center border-b border-[#707070] w-full">
                                    <p className="font-font-Poppins-SemiBold font-size-20px text-[#004540] py-4">
                                        Share Test
                                    </p>
                                </div>

                                <div className="py-4 px-6">
                                    <div className="doctors-list-search ">
                                        <SearchFilterInput
                                            onChangeText={(val) => setSearchCustomer(val)}
                                            containerExtraStyle={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            inputExtraStyle={{
                                                width: '100%',
                                                borderRadius: "21px",
                                                padding: ".6rem 1rem",
                                                border: '1px solid #707070',
                                                margin: 0,
                                            }}
                                        />
                                    </div>

                                    <p className="font-font-Poppins-SemiBold font-size-20px my-4">
                                        Customer List
                                    </p>

                                    <div className='h-[220px] overflow-scroll'>
                                        {allCustomers.length > 0 ?
                                            (!modalLoader ?
                                                allCustomers.filter(v => v.firstName.toLowerCase().includes(searchCustomer.toLowerCase()) || v.lastName.toLowerCase().includes(searchCustomer.toLowerCase())).map(customer => (
                                                    <div className={`flex items-center gap-4  py-3 cursor-pointer border-b px-3 
                                                            ${customerId === customer?._id ? 'border-[#004540]' : ' border-[#DCDCDC]'}
                                                                `}
                                                        onClick={() => setCustomerId(customer?._id)}>
                                                        <img
                                                            className='rounded-full'
                                                            src={`${process.env.REACT_APP_API_BASEURL}${customer?.photo}`}
                                                            onError={(e) => {
                                                                const target = e.target as HTMLImageElement; // Assert the type
                                                                if (target) {
                                                                    target.src = defaultProfile;
                                                                }
                                                            }}
                                                            alt="profile image"
                                                            width={50}
                                                            height={50}
                                                        />
                                                        <div>
                                                            <h1 className="font-Poppins-Medium font-size-17px text-[#464646]">
                                                                {`${customer?.firstName} ${customer?.lastName}`}
                                                            </h1>
                                                            <p className="font-Poppins-Regular font-size-13px text-[#AFAFAF]">
                                                                Patient
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <div className='flex gap-4 items-center'>
                                                    <Skeleton variant="circular" width={50} height={50} />
                                                    <div style={{ minWidth: 350 }}>
                                                        <Skeleton variant="text" className='font-size-17px' />
                                                        <Skeleton variant="text"
                                                            className='font-size-13px'
                                                            sx={{ width: "50%" }} />
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <p className='text-center my-10 font-Poppins-Regular font-size-20px'>
                                                No Customers...
                                            </p>
                                        }
                                    </div>
                                    <div className='mt-8 mb-2 flex items-center justify-center'>
                                        <PrimaryBtn
                                            loader={sendLoader}
                                            ButtonText="Share"
                                            disabled={!customerId}
                                            onPress={handleSendSuggestion}
                                            type='button'
                                            btnExtraStyle={{
                                                borderRadius: "100rem",
                                                backgroundColor: "#004540"
                                            }} />
                                    </div>

                                </div>
                            </div>
                        </Modal>
                    )
                    }
                </div>
            }
            {/* Pagination component */}
            {paginated?.totalPages > 1 &&
                <PaginationComponet
                    currentPage={paginated?.currentPage}
                    total={paginated?.totalPages}
                    setCurrentPage={(_page) => handleChangePage(_page)}
                />
            }
        </>
    );
};

// Style for the modal container
const customeStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

// Style for the close button container
const backBtnMaain: React.CSSProperties = {
    position: "absolute",
    top: -10,
    right: -10,
    backgroundColor: "red",
    borderRadius: 100,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

// Style for the close button text
const backBtn: React.CSSProperties = {
    color: "white",
};

export default AllProducts;