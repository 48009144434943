/**
 * Education Component
 * 
 * Description: This component displays a doctor's educational history, including details of their academic background. 
 * It presents a list of educational experiences, each detailing the institution, course, and the start and end dates. 
 * Dates are formatted using the `moment` library for consistency. If the `isMyProfile` prop is true, an edit icon 
 * is shown that allows users to navigate to an edit page to update their education details.
 * 
 * Props:
 * - isMyProfile (boolean): Indicates whether the current profile is the user's own profile. If true, an edit icon is displayed.
 * - education (IEducationsHistory[]): An array of objects representing the doctor's education history. Each object includes:
 *   - institution (string): The name of the educational institution.
 *   - course (string): The name of the course or degree pursued.
 *   - startDate (string): The start date of the course.
 *   - endDate (string): The end date of the course.
 * 
 * Usage:
 * This component iterates over the `education` array to display each educational experience with the institution name, course,
 * and the duration formatted as "DD-MM-YYYY". If `isMyProfile` is true, an edit icon is rendered that links to the edit page 
 * for updating the education details.
 * 
 * File Name: Education.tsx
 * Date: 02-09-2024
 */

import React from 'react';
import { Link } from 'react-router-dom';
import edit from "../../../assets/images/awesome-edit.svg";
import moment from 'moment';
import { IEducationsHistory } from 'src/types/GlobalInterfaces';

const Education: React.FC<{ isMyProfile: boolean; education: IEducationsHistory[] }> = ({ isMyProfile = false, education = [] }) => {
    return (
        <div className="parent-box parent-box-margin-issue relative">
            <h2 className='font-size-20px font-Poppins-SemiBold theme-color-green mb-1'>Education</h2>
            <div className="education mt-4">
                {education?.map((item, index: number) => (
                    <div key={index} className="mt-2 mb-4">
                        <h3 className="font-size-20px font-Poppins-Regular theme-color-green">
                            {item.institution}
                        </h3>
                        <p className="font-Poppins-Regular font-size-18px">
                            {item.course}
                        </p>
                        <p className="font-Poppins-Regular font-size-18px flex items-center gap-2">
                            <span>{moment(item.startDate).format("DD-MM-YYYY")}</span>
                            <span>-</span>
                            <span>{moment(item.endDate).format("DD-MM-YYYY")}</span>
                        </p>
                    </div>
                ))}
            </div>
            {isMyProfile
                &&
                <Link to="/education" className="edit-pro-icon absolute top-2 right-4">
                    <img src={edit} alt="edit-icon" />
                </Link>
            }
        </div>
    );
};

export default Education;