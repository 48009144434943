/**
 * UpsertCategories Component
 * 
 * Description: This component enables users to either add a new category or edit an existing one. 
 * It features an image upload functionality, a text input for the category name, and a save button. 
 * On submission, the component sends a request to the server to add or update the category based on 
 * whether it is in edit mode. It includes form validation to ensure all required fields are filled 
 * and provides feedback to the user by displaying success or error messages based on the server's response.
 * 
 * Props:
 * - isEdit (ICategoryItems | boolean): Optional. If provided and not a boolean, it represents the category 
 *   to be edited. If false, the component operates in add mode.
 * - onSuccess (function): Callback function invoked upon successful form submission, allowing parent 
 *   components to refresh data or redirect.
 * 
 * File Name: UpsertCategories.tsx
 * Date: 27-07-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import ImageUpload from '../GlobalComponents/ImageUplaod'; // Corrected typo
import SimpleInput from '../SimpleInput';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { ICategoryItems } from 'src/types/GlobalInterfaces';

interface IUpsertCategoryProps {
    isEdit?: ICategoryItems | boolean;
    onSuccess: () => void;
};

const UpsertCategories: React.FC<IUpsertCategoryProps> = ({ isEdit = false, onSuccess = () => { } }) => {
    // img upload
    const [name, setName] = useState<string>("");
    const [categoryImage, setCategoryImage] = useState<File | null | string>(null);

    const [isLoader, setIsLoader] = useState<boolean>(false);

    const handleSubmit = async () => {
        if (!name) {
            toast("Category name is mandatory!", { type: 'error' });
        } else if (!categoryImage) {
            toast("Category image is mandatory!", { type: 'error' });
        } else {
            setIsLoader(true);
            const formData = new FormData();
            formData.append("name", name);
            formData.append("categoryImage", categoryImage);
            const path = isEdit && typeof isEdit !== 'boolean' ? `/category/edit/${isEdit._id}` : "/category/add";

            const { res, err } = await httpRequest<ICategoryItems>({ method: isEdit ? "put" : "post", path, params: formData, header: { "Content-Type": "multipart/form-data" } });
            if (res) {
                toast(`Category has been ${isEdit ? "updated" : "added"} successfully.`, { type: 'success' });
                onSuccess();
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        }
    };

    useEffect(() => {
        if (isEdit && typeof isEdit !== 'boolean') {
            let { name = "", categoryImage = "" } = isEdit;
            setName(name);
            setCategoryImage(categoryImage);
        }
    }, [isEdit]); // Execute this useEffect hook if in edit mode to initialize form with existing role data
    return (
        <div className="table-container">
            <div className='admin-create-role-main'>
                <div className='admin-create-role'>
                    <div className='create-operators-res '>
                        <h3 className='font-size-18px text-[#2F2F31] mb-1 font-Poppins-Regular'>Category Image</h3>
                        <ImageUpload
                            image={categoryImage}
                            onImageChange={setCategoryImage}
                        />
                        <SimpleInput
                            value={name}
                            onChange={setName}
                            isLabel={true}
                            labelText="Category Name"
                            isRequired={true}
                        />
                    </div>
                </div>
                <div className='flex justify-end my-6'>
                    <PrimaryBtn
                        loader={isLoader}
                        ButtonText="Save"
                        btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        onPress={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

export default UpsertCategories;