/**
 * UpsertProduct Component
 * 
 * Description: This component handles the creation and updating of product items,
 * featuring a tabbed interface to manage different sections of product information 
 * including general details, data specifics, biomarker details, categories, links, 
 * test instructions, FAQs, and product images. The component includes validation checks 
 * to ensure all required fields are filled out before submission.
 * 
 * Props:
 * - isEdit (string | false): Optional. If provided, it represents the product ID to be edited. 
 *   If false, the component operates in add mode.
 * - onSuccess (function): Callback function invoked upon successful form submission, allowing 
 *   parent components to refresh data or perform any necessary actions after a successful operation.
 * 
 * File Name: UpsertProduct.tsx
 * Date: 31-07-2024
 * 
 * Marker: DOC_START
 */

import React, { useState, useEffect } from 'react';
import GeneralTab from './productsTabs/GeneralTab';
import DataTab from './productsTabs/DataTab';
import AddBiomarkerTab from './productsTabs/AddBiomarkerTab';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { IProductItems } from 'src/types/GlobalInterfaces';
import CategoreisTab from './productsTabs/CategoreisTab';
import FaqsTab from './productsTabs/FaqsTab';
import LinkTab from './productsTabs/LinkTab';
import TestInstructionTab from './productsTabs/TestInstructionTab';
import ImageTab from './productsTabs/ImageTab';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

interface IUpsertProductProps {
  isEdit: string | false; // Product ID if in edit mode, otherwise false for add mode
  onSuccess: () => void; // Callback function to execute on successful operation
};

const UpsertProduct: React.FC<IUpsertProductProps> = ({
  isEdit = false,
  onSuccess = () => { }
}) => {
  // Callback function to execute on successful operation
  const tabsArray: string[] = ["General", "Data", "Add Biomarkers", "Categories", "Link", "Test Instructions", "FAQ's", "Image"];

  // State to track the currently active tab
  const [activeTab, setActiveTab] = useState<string>('General');

  // Function to handle tab clicks and set the active tab
  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state to indicate form submission status
  const [productState, setProductState] = useState<IProductItems>({
    productName: "",
    description: "",
    testTypeId: "",
    resultDays: "",
    sku: "",
    regularPrice: "",
    salePrice: "",
    biomarkerId: [],
    biomarkerCategoriesId: [],
    testInstuction: [],
    linksId: [],
    qna: [],
    productImage: ""
  });   // State to manage product details

  // Function to handle changes in form fields
  const handleChange = (key: string, val: string, isLimit: number | false = false) => {
    setProductState(
      prev => ({
        ...prev,
        [key]: (isLimit === false || val.length <= isLimit) ? val : prev[key],
      })
    );
  };

  // Function to append object data to FormData, handling files and nested objects
  const appendObjectToFormData = (formData: FormData, obj: IProductItems) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] instanceof File) {
        // Handle File instances directly
        formData.append(key, obj[key]);
      }
      // If the value is an array, handle it as a multi-value field
      else if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Handle nested objects (not arrays)
        formData.append(key, JSON.stringify(obj[key]));
      } else {
        formData.append(key, obj[key]);
      }
    });
    return formData;
  };

  //API_CALL: Function to handle form submission for edit & create product
  const handleSubmit = async () => {
    const { productName, description, testTypeId, resultDays, sku, regularPrice, salePrice, biomarkerId, biomarkerCategoriesId, testInstuction, linksId, qna, productImage } = productState;
    // Validation checks for required fields
    if (!productName || !description || !testTypeId || !resultDays) {
      toast("Please fill out all required fields in the General tab.", { type: 'error' });
      setActiveTab("General");
    } else if (!sku || !regularPrice || !salePrice) {
      toast("SKU, Regular Price, and Sale Price are required in the Data tab.", { type: 'error' });
      setActiveTab("Data");
    } else if (biomarkerId?.length < 1) {
      toast("Please select at least one biomarker.", { type: 'error' });
      setActiveTab("Add Biomarkers");
    } else if (biomarkerCategoriesId?.length < 1) {
      toast("Please select at least one category.", { type: 'error' });
      setActiveTab("Categories");
    } else if (linksId?.length < 1) {
      toast("Please select at least one link.", { type: 'error' });
      setActiveTab("Link");
    } else if (testInstuction?.length < 1) {
      toast("At least one test instruction is required.", { type: 'error' });
      setActiveTab("Test Instructions");
    } else if (qna?.length < 1) {
      toast("At least one FAQ is required.", { type: 'error' });
      setActiveTab("FAQ's");
    } else if (!productImage) {
      toast("Product image is required.", { type: 'error' });
      setActiveTab("Image");
    }
    else {
      setIsLoader(true);
      const formData = new FormData();
      appendObjectToFormData(formData, productState);
      // Determine the request path based on the mode (edit or add)
      const path = isEdit ? `/product/edit/${isEdit}` : "/product/add";
      // Send the HTTP request
      const { res, err } = await httpRequest<any>({ method: isEdit ? "put" : "post", path, params: formData, header: { "Content-Type": "multipart/form-data" } });
      if (res) {
        toast(`Product has been ${isEdit ? "updated" : "added"} successfully.`, { type: 'success' });
        onSuccess(); // Execute success callback
      } else {
        toast(err, { type: 'error' });
      }
      setIsLoader(false);
    }
  };

  //API_CALL: Function to fetch product data if editing
  const getProductById = async () => {
    const { res, err } = await httpRequest<IProductItems>({ path: `/product/single/${isEdit}` });
    if (res) {
      setProductState(res);
    } else {
      toast(err, { type: 'error' });
    }
  };

  // Fetch product data if in edit mode
  useEffect(() => {
    if (isEdit) {
      getProductById();
    }
  }, [isEdit]);

  return (
    <div className="relative">
      <button
        className="absolute right-[24px] top-[-3rem] border-[#333] border w-[25px] h-[25px] rounded"
        disabled={isLoader}
        onClick={handleSubmit}
      >
        <FontAwesomeIcon className="text-sm" icon={faFloppyDisk} />
      </button>
      <div className='products-tabs-btn'>
        {tabsArray.map((item: string) => (
          <button
            key={item}
            className={`font-size-15px font-Poppins-Medium ${activeTab === item ? 'active' : ''}`}
            onClick={() => handleTabClick(item)}
          >
            {item}
          </button>
        ))}
      </div>
      <hr className='products-tabs-hr' style={{ border: '1px solid #D4D4D4', }} />
      <div className='parent-box-scroll-bar-product'>
        {activeTab === 'General' &&
          <div className='products-tabs-content-main'>
            <GeneralTab
              state={productState}
              setState={handleChange}
              tabChange={() => handleTabClick('Data')}
            />
          </div>
        }
        {activeTab === 'Data' &&
          <div className='products-tabs-content-main'>
            <DataTab
              state={productState}
              setState={handleChange}
              tabChange={() => handleTabClick('Add Biomarkers')}
            />
          </div>
        }
        {activeTab === 'Add Biomarkers' &&
          <div className=''>
            <AddBiomarkerTab
              state={productState}
              setState={setProductState}
              tabChange={() => handleTabClick('Categories')}
            />
          </div>
        }
        {activeTab === 'Categories' &&
          <div className='products-tabs-content-main'>
            <CategoreisTab
              state={productState}
              setState={setProductState}
              tabChange={() => handleTabClick('Link')}
            />
          </div>
        }
        {activeTab === 'Link' &&
          <div className='products-tabs-content-main'>
            <LinkTab
              state={productState}
              setState={setProductState}
              tabChange={() => handleTabClick('Test Instructions')}
            />
          </div>
        }
        {activeTab === 'Test Instructions' &&
          <div className=''>
            <TestInstructionTab
              state={productState}
              setState={setProductState}
              tabChange={() => handleTabClick(`FAQ's`)}
            />
          </div>
        }
        {activeTab === `FAQ's` &&
          <div className=''>
            <FaqsTab
              state={productState}
              setState={setProductState}
              tabChange={() => handleTabClick("Image")}
            />
          </div>
        }
        {activeTab === 'Image' &&
          <div className='products-tabs-content-main'>
            <ImageTab
              state={productState}
              setState={setProductState}
            />
            <div className='flex justify-end my-6'>
              <PrimaryBtn
                loader={isLoader}
                ButtonText="Save"
                btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                onPress={handleSubmit}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default UpsertProduct;