/**
 * TotalSalesGraph Component
 * 
 * Description: This component displays a bar chart for total sales data, allowing users to view statistics 
 * on a monthly or yearly basis. It includes a loading spinner while data is being fetched and uses a 
 * `ComposedChart` for rendering the sales data. The component provides a dropdown for selecting the data 
 * category (monthly or yearly) and displays the total sales count prominently.
 * 
 * File Name: TotalSalesGraph.tsx
 * Date: 29-08-2024
 * 
 * Marker: DOC_START
 */

import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ComposedChart
} from 'recharts';
import httpRequest from 'src/helpers/httpRequest';
import { allMonths, allYears } from 'src/lib/constans';
import SelectDropdown from '../GlobalComponents/SelectDropdown';

interface IData {
    count: number;
    _id: { year: number; month: 8 };
};

interface ISalesStats {
    totalCount: { count: number }[];
    monthlyData: IData[];
    yearlyData: IData[];
};

const TotalSalesGraph: React.FC = () => {
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [data, setData] = useState<{ name: string; value: number }[]>([]);
    const [category, setCategory] = useState<string>("monthly");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [monthlyData, setMonthlyData] = useState<IData[]>([]);
    const [yearlyData, setYearlyData] = useState<IData[]>([]);

    const fetchSalesStats = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ISalesStats[]>({ path: "/order/graph/statistics/sales" });
        if (res) {
            const { totalCount, monthlyData, yearlyData } = res[0];
            setTotalCount(totalCount[0]?.count || 0);
            setMonthlyData(monthlyData);
            setYearlyData(yearlyData);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchSalesStats();
    }, []);

    useEffect(() => {
        if (category === 'monthly') {
            const formattedData = allMonths.map(({ month, name }) => {
                const _value = monthlyData.find(v => v._id.month === month);
                return {
                    name: name, // Month name
                    value: _value ? _value.count : 0, // Default to 0 if no data
                };
            });
            setData(formattedData);
        } else if (category === 'yearly') {
            const formattedData = allYears.map(year => {
                const _value = yearlyData.find(v => v._id.year === year);
                return {
                    name: String(year), // year name
                    value: _value ? _value.count : 0, // Default to 0 if no data
                };
            });
            setData(formattedData);
        } else { }
    }, [category, totalCount]);

    return (
        <div style={{ height: '100%' }}>
            <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">Sales</h2>
            <div className='user-graph-inner h-[90%]'>
                <div className='flex justify-between items-center'>
                    <div className='font-Poppins-Regular user-graph-header font-size-14px'>
                        Total sale: <span className='font-Poppins-SemiBold theme-color-green font-size-20px'>{totalCount}</span>
                    </div>
                    {/* <div>
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className='font-Poppins-SemiBold user-graph-select theme-color-green font-size-14px'
                        >
                            <option className='font-Poppins-Medium font-size-14px' value="monthly">Monthly</option>
                            <option className='font-Poppins-Medium font-size-14px' value="yearly">Yearly</option>
                        </select>
                    </div> */}
                    <div className='select-container-graph'>
                        <SelectDropdown
                            options={[{ label: "Monthly", value: "monthly" }, { label: "Yearly", value: "yearly" }]}
                            isMulti={false}
                            data={[{ label: category, value: category }]}
                            setData={(e) => e[0] ? setCategory(e[0].value) : ""}
                            checkboxShow={false}
                            isLabel={false}
                            isRequired={true}
                            clearIcon={false}
                        />
                    </div>
                </div>
                {
                    isLoader
                    &&
                    <div className="text-center">
                        <CircularProgress size={20} style={{ color: "#004540" }} />
                    </div>
                }
                <ResponsiveContainer className={'user-graph-container'}>
                    <ComposedChart
                        data={data}
                        margin={{ top: 20, right: 0, left: -35, bottom: 0 }}
                    >
                        <CartesianGrid />
                        <XAxis className='revenue-axis' dataKey="name" />
                        <YAxis className='revenue-axis' />
                        <Tooltip wrapperClassName='revenue-axis' />
                        <Bar dataKey={"value"} barSize={15} fill="#00443F" radius={[10, 10, 0, 0]} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default TotalSalesGraph;