/**
 * ViewDoctorProfile Component
 * 
* Description: ViewDoctorProfile component renders the complete profile view of a doctor.
 * It includes various sections such as profile details, health system, recent tests,
 * and more, fetched from an API based on the doctor's unique identifier (uid).
 * 
 * File Name: ViewDoctorProfile.tsx
 * Date: 02-09-2024
 */

import React, { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IAccountInfo, IAvailability, IDoctorProfile, IProfileData } from 'src/types/GlobalInterfaces';
import { toggleLoader } from '../../Store/reducer';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import ProfileUserDetailDashboard from 'src/Components/Customer/ProfileUserDetailDashboard';
import HealthSystem from 'src/Components/Customer/HealthSystem';
import RecentTest from 'src/Components/Customer/RecentTest';
import AddressSocialDetail from 'src/Components/AddressSocialDetail';
import AboutMe from 'src/Components/DoctorProfile/View/AboutMe';
import WorkHistory from 'src/Components/DoctorProfile/View/WorkHistory';
import Education from 'src/Components/DoctorProfile/View/Education';
import Services from 'src/Components/DoctorProfile/View/Services';
import Diseases from 'src/Components/DoctorProfile/View/Diseases';
import Specialization from 'src/Components/DoctorProfile/View/Specialization';
import OnlineConsultant from 'src/Components/DoctorProfile/View/OnlineConsultant';
import Hospitals from 'src/Components/DoctorProfile/View/Hospitals';
import Reviews from 'src/Components/DoctorProfile/View/Reviews';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import PaymentInfo from 'src/Components/DoctorComponents/PaymentInfo';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import EditPaymentInfo from 'src/Components/DoctorComponents/EditPaymentInfo';
import ChangeDoctorPassword from 'src/Components/AdminComponents/ChangeDoctorPassword';
import ChangeDoctorEmail from 'src/Components/AdminComponents/ChangeDoctorEmail';
import videoIcon from 'src/assets/images/doctor-profile/mydoctor-video-iconcamera.png';
import hospitalIcon from 'src/assets/images/doctor-profile/mydoctor-hospital-icon.png';
import { weekDays } from 'src/lib/constans';
import moment from 'moment';
import ReviewComponents from 'src/Components/ReviewComponents';

type tabKeys = "profile" | "bank_details" | "account_details";
const ViewDoctorProfile: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = location;

    // List of tab names for filtering orders
    const tabList: { label: string; key: tabKeys }[] = [
        { label: "Profile", key: "profile" },
        { label: "Bank Details", key: "bank_details" },
        { label: "Account Details", key: "account_details" },
    ];
    const [bankInfo, setBankInfo] = useState<IAccountInfo | null>(null);
    const [bankInfoEditMode, setBankInfoEditMode] = useState<boolean>(false);
    const [getLoader, setGetLoader] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<tabKeys>("profile");
    const { uid = "" } = useParams(); // Extracting user ID from URL parameters
    const dispatch = useDispatch<AppDispatch>(); // Dispatch function for Redux actions
    const [profileData, setProfileData] = useState<IProfileData>({}); // State to hold profile data
    const [doctorData, setDoctorData] = useState<IDoctorProfile | null>(null); // State to hold profile data

    const handleUpdateInfo = (obj: IAccountInfo) => {
        setBankInfoEditMode(false);
        setBankInfo(obj);
    };

    /**
    * Fetch profile data from the API.
    * 
    * @param uid - The user ID to fetch the profile data.
    */

    // API_CALL: Retrieve user data from the server using the specified user ID.
    const fetchProfileData = async (_id: string) => {
        dispatch(toggleLoader(true));
        const { res, err } = await httpRequest<IProfileData>({ path: `/customer/get/profile/${_id}` });
        if (res) {
            setProfileData(res);
        } else {
            toast(err, { type: 'error' });
        }
        dispatch(toggleLoader(false));
    };

    // API_CALL: Retrieve user data from the server using the specified user ID.
    const fetchDoctoProfile = async (_id: string) => {
        dispatch(toggleLoader(true));
        const { res, err } = await httpRequest({ path: `/provider/${_id}` })
        if (res) {
            console.log("Response", res);
            setDoctorData(res);
        } else {
            toast(err, { type: 'error' });
        }
        dispatch(toggleLoader(false));
    };

    // API_CALL: fetch account information from the server
    const fetchAccountInfo = async () => {
        setGetLoader(true);
        const { res, err } = await httpRequest<{ result: IAccountInfo }>({
            path: `/paymentandwithdraw?id=${uid}`
        });
        if (res) {
            setBankInfo(res.result);
        } else {
            toast(err, { type: 'error' });
        }
        setGetLoader(false);
    };

    useEffect(() => {
        if (activeTab === "profile") {
            fetchProfileData(uid); // Fetch profile data when component mounts
            fetchDoctoProfile(uid);
        } else if (activeTab === "bank_details") {
            fetchAccountInfo();
        } else {

        }
    }, [uid, activeTab]);
    return (
        <>
            <BreadCrumb
                current="Profile"
                previous="dashboard"
                navigateUrl="/admin"
                btnShow={false}
            />
            <div className='table-container'>
                <div className='products-tabs-btn'>
                    {tabList.map((v) => (
                        <button
                            key={v.key}
                            className={`font-size-15px font-Poppins-Medium ${activeTab === v.key && 'active'}`}
                            onClick={() => { navigate(`/doctor/${uid}/View`); setActiveTab(v.key); }}
                        >
                            {v.label}
                        </button>
                    ))}
                </div>
                <hr className='products-tabs-hr products-tabs-hr-allOrder' style={{ border: '1px solid #D4D4D4' }} />
                {search === "?allreview=true"
                    ?
                    <div className='parent-box-scroll-bar parent-box'>
                        <h2 className='mb-3 font-Poppins-SemiBold font-size-20px'>Reviews</h2>
                    <ReviewComponents
                        id={uid}
                    />
                    </div>
                    :
                    activeTab === "profile" ?
                        <div className="container-1480 parent-box-scroll-bar block justify-between md:flex lg:flex">
                            <div className="w-full md:w-3/4 lg:w-3/4">
                                <ProfileUserDetailDashboard
                                    data={profileData}
                                    setData={setProfileData}
                                    profile={doctorData}
                                />
                                {doctorData &&
                                    <>
                                        <AboutMe
                                            isMyProfile={false}
                                            aboutMe={doctorData.aboutMe}
                                        />
                                        <WorkHistory
                                            isMyProfile={false}
                                            experiences={doctorData.experiences}
                                        />
                                        <Education
                                            isMyProfile={false}
                                            education={doctorData.education}
                                        />
                                        <Services
                                            isMyProfile={false}
                                            servicesDetails={doctorData.servicesDetails}
                                        />
                                        <Diseases
                                            isMyProfile={false}
                                            diseasesDetails={doctorData.diseasesDetails}
                                        />
                                        <Specialization
                                            isMyProfile={false}
                                            specailizedDetails={doctorData.specailizedDetails}
                                        />
                                        <OnlineConsultant
                                            isMyProfile={false}
                                            availability={doctorData.availability}
                                        />
                                        <Hospitals
                                            isMyProfile={false}
                                            timings={doctorData.timings}
                                        />
                                    </>
                                }
                                <HealthSystem
                                    id={uid}
                                />
                                <RecentTest
                                    id={uid}
                                />
                                <Reviews
                                    id={uid}
                                    isAdmin={true}
                                />
                            </div>
                            <div className="w-1/4 pl-2 show-location-detail-mob">
                                <AddressSocialDetail
                                    data={profileData}
                                />
                                {doctorData?.availability &&
                                    <div className="parent-box mt-3">
                                        <div className="flex gap-3 items-center">
                                            <img src={videoIcon} alt="video-icon" width={18} height={18} />
                                            <h1 className="font-Poppins-SemiBold font-size-20px text-[#004540]">
                                                Online Consultation
                                            </h1>
                                        </div>
                                        <div className="my-4">
                                            <div className="flex py-3 px-1 items-center justify-between border-b border-[#D9D9D9]">
                                                <p className='font-Poppins-Regular font-size-16px text-[#646464]'>Fee:</p>
                                                <p className='font-Poppins-Medium font-size-16px text-[#202020]'>${doctorData.availability.fees}</p>
                                            </div>
                                            {weekDays.map((day) => { // Iterate over ordered days
                                                const slots = doctorData?.availability?.[day as keyof IAvailability];
                                                if (!slots) return null;
                                                return (
                                                    <div className="flex py-3 px-1 items-center justify-between border-b border-[#D9D9D9]">
                                                        <p className='font-Poppins-Regular font-size-16px text-[#646464] capitalize'>{day}</p>
                                                        <div className="text-end">
                                                            {Array.isArray(slots) && (
                                                                slots.map(([startTime, endTime], index) => (
                                                                    <p key={index} className='font-Poppins-Medium font-size-16px text-[#202020]'>
                                                                        <React.Fragment key={index}>
                                                                            <span>{moment(startTime, "HH:mm").format("h:mm A")}</span>
                                                                            <span className="mx-1">to</span>
                                                                            <span>{moment(endTime, "HH:mm").format("h:mm A")}</span>
                                                                            {/* {index < slots.length - 1 && <span>,</span>} */}
                                                                        </React.Fragment>
                                                                    </p>
                                                                ))
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                }
                                {(doctorData?.timings && doctorData.timings.length) ?
                                    doctorData.timings.map((item: IAvailability, index: number) => (
                                        <div key={index} className="parent-box">
                                            <div className="flex gap-3 items-center">
                                                <img src={hospitalIcon} alt="video-icon" width={18} height={18} />
                                                <h1 className="font-Poppins-SemiBold font-size-20px text-[#004540] capitalize">
                                                    {item.hospitalName}
                                                </h1>
                                            </div>
                                            <div className="my-4">
                                                <div className="flex py-3 px-1 items-center justify-between border-b border-[#D9D9D9]">
                                                    <p className='font-Poppins-Regular font-size-16px text-[#646464]'>Fee:</p>
                                                    <p className='font-Poppins-Medium font-size-16px text-[#202020]'>${item.fees}</p>
                                                </div>
                                                {weekDays.map((day) => { // Iterate over ordered days
                                                    const slots = doctorData.timings[index]?.[day as keyof IAvailability];
                                                    if (!slots) return null;
                                                    return (
                                                        <div className="flex py-3 px-1 items-center justify-between border-b border-[#D9D9D9]">
                                                            <p className='font-Poppins-Regular font-size-16px text-[#646464] capitalize'>{day}</p>
                                                            <div className="text-end">
                                                                {Array.isArray(slots) && (
                                                                    slots.map(([startTime, endTime], index) => (
                                                                        <p key={index} className='font-Poppins-Medium font-size-16px text-[#202020]'>
                                                                            <React.Fragment key={index}>
                                                                                <span>{moment(startTime, "HH:mm").format("h:mm A")}</span>
                                                                                <span className="mx-1">to</span>
                                                                                <span>{moment(endTime, "HH:mm").format("h:mm A")}</span>
                                                                                {/* {index < slots.length - 1 && <span>,</span>} */}
                                                                            </React.Fragment>
                                                                        </p>
                                                                    ))
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ))
                                    :
                                    null
                                }
                            </div>
                        </div>
                        : activeTab === "bank_details" ?
                            <>
                                {bankInfoEditMode ?
                                    <EditPaymentInfo
                                        accountInfo={bankInfo}
                                        isOperatorEdit={true}
                                        uid={uid}
                                        onSuccess={handleUpdateInfo}
                                        extraContainerClasses={"bg-[#f6fbff] p-5 pb-5 mb-5 rounded-md"}
                                    />
                                    :
                                    <div className="max-w-[50%]">
                                        <div className="flex justify-end mb-4">
                                            <PrimaryBtn
                                                onPress={() => setBankInfoEditMode(true)}
                                                ButtonText="Edit"
                                                btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem", padding: "0.5rem 2.5rem" }}
                                            />
                                        </div>
                                        <PaymentInfo
                                            accountInfo={bankInfo}
                                            loader={getLoader}
                                        />
                                    </div>
                                }
                            </>
                            :
                            <div className="flex flex-col gap-5 max-w-[50%]">
                                <ChangeDoctorPassword
                                    uid={uid}
                                />
                                <ChangeDoctorEmail
                                    uid={uid}
                                />
                            </div>

                }
            </div>
        </>
    );
};

export default ViewDoctorProfile;