/**
 * DoctorsList Component
 * 
 * Description: This component displays a list of doctors and filters based on availability, gender, and location.
 * It fetches the list of doctors from the API and provides options for filtering and pagination.
 * 
 * File Name: DoctorsList.tsx
 * Date: 05-09-2024
 */

import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import PageHeader from 'src/Components/Customer/PageHeader';
import BackIcon from "src/assets/images/back-icon.png";
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faAngleRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress, Modal } from '@mui/material';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import { IAvailability, IDropDownArray, IqueryParams } from 'src/types/GlobalInterfaces';
import httpRequest from 'src/helpers/httpRequest';
import qs from "qs";
import { toast } from 'react-toastify';
import { IPaginated } from 'src/types/paginated';
import defaultProfile from "src/assets/images/default_profile_image.png";
import SplashScreen from 'src/Components/SplashScreen';
import PaginationComponet from 'src/Components/GlobalComponents/PaginationComponet';
import { convertMonthsToReadableFormat } from 'src/helpers/helperFunctions';
import { weekDays } from 'src/lib/constans';
import { cityList } from 'src/Config/CityList';

interface IDoctorItem {
    _id: string;
    firstName: string;
    lastName: string;
    isCoverPhoto: "";
    speicalizationData: string[];
    education: { course: string }[];
    workExperience: string;
    averageStar: number[];
    allReviews: { reviews: [] }[];
    // in future add reviews keys
};

interface ISearchParams {
    days: string[];
    specialId: string;
    diseasesId: string;
    gender: string;
    name: string;
    city: string[];
};

interface IDoctorListResponse {
    documents: IDoctorItem[];
    paginated: IPaginated;
};

interface IDoctorAvailability {
    onlineAvalibility: IAvailability;
    timings: IAvailability[];
    _id: string;
}

const DoctorsList: React.FC = () => {
    const availabilityList: IDropDownArray[] = [
        { label: "Monday", value: "monday" },
        { label: "Tuesday", value: "tuesday" },
        { label: "Wednesday", value: "wednesday" },
        { label: "Thursday", value: "thursday" },
        { label: "Friday", value: "friday" },
        { label: "Saturday", value: "saturday" },
        { label: "Sunday", value: "sunday" },
    ];
    const genderList: IDropDownArray[] = [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" }
    ];

    const location = useLocation();
    // Create a URLSearchParams object from the query string
    const urlParams = new URLSearchParams(location.search);
    // Retrieve specific query parameters
    const specialIdParam = urlParams.get('specialId');
    const diseasesIdParam = urlParams.get('diseasesId');

    // Extract IDs from the parameters
    const specialId = specialIdParam ? specialIdParam.split('/')[0] : '';
    const diseasesId = diseasesIdParam ? diseasesIdParam.split('/')[0] : '';

    const navigate = useNavigate();
    const [fetchAvailLoader, setFetchAvailLoader] = useState<boolean>(false);
    const [doctorId, setDoctorId] = useState<null | string>(null);
    const [doctors, setDoctors] = useState<IDoctorItem[]>([]);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination

    const [filterPayload, setFilterPayload] = useState<ISearchParams>({
        specialId: specialId,
        diseasesId: diseasesId,
        name: "",
        days: [],
        gender: "",
        city: [],
    });

    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
    });

    const [availability, setAvailability] = useState<IDoctorAvailability | null>(null);

    const handleChangePage = (_page: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: _page,
        }));
    }; // Handler function to update the current page for pagination

    const handleChange = (key: keyof ISearchParams, arr: IDropDownArray[]) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1
        }));
        setFilterPayload(prev => ({
            ...prev,
            [key]:
                (key === "days" || key === "city") ? arr.map(v => v.value)
                    : arr[0] ? arr[0].value : ""
        }));
    };

    const fetchDoctors = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IDoctorListResponse>({
            method: "post",
            path: `/provider/all/doctor?${qs.stringify(queryParams)}`,
            params: filterPayload
        });
        if (res) {
            let { documents = [], paginated } = res;
            setDoctors(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    const fetchDoctorAvailability = async (_id: string) => {
        setFetchAvailLoader(true);
        const { res, err } = await httpRequest<IDoctorAvailability[]>({
            path: `/appoiment/doctor/${_id}`,
        });
        if (res) {
            setAvailability(res[0]);
        } else {
            toast(err, { type: 'error' });
        }
        setFetchAvailLoader(false);
    };

    useEffect(() => {
        fetchDoctors();
    }, [filterPayload, queryParams]);

    useEffect(() => {
        if (doctorId) {
            fetchDoctorAvailability(doctorId);
        }
    }, [doctorId]);

    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    return (
        <>
            {isLoader && <SplashScreen />}
            <PageHeader
                title="Find Doctor"
                isButton={true}
                ButtonComponent={BackButton}
            />

            <div className="parent-box parent-box-height">
                <div className="doctors-list-search grid lg:grid-cols-4 grid-cols-2 gap-3 mt-2">
                    <div className='col-span-2'>
                        <SearchFilterInput
                            onChangeText={(val) => handleChange("name", [{ value: val, label: "" }])}
                            placeHolder='Doctors, Specializations, Disease'
                            containerExtraStyle={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            inputExtraStyle={{
                                width: '100%',
                                borderRadius: "50rem",
                                padding: ".6rem 1rem",
                                border: '1px solid #004540',
                                margin: 0,
                                boxShadow: '2px 2px 10px rgba(0, 0, 0, .16)',
                            }}
                        />
                    </div>
                    {/* <PrimaryBtn
                        ButtonText='Search'
                        type='button'
                        className='bg-[#004540] text-[#ffffff] rounded-full py-2 px-8 inline-block justify-self-start'
                    /> */}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 py-6 mt-2">
                    <div>
                        <SelectDropdown
                            checkboxShow={true}
                            isMulti={true}
                            options={availabilityList}
                            data={availabilityList.filter(v => filterPayload.days.includes(v.value))}
                            setData={(arr) => handleChange("days", arr)}
                            extraClass='doctors-list-dropdown doctors-list-dropdown-availability'
                            placeholder={{ "selectSomeItems": "Availability" }}
                        />
                    </div>
                    <div>
                        <SelectDropdown
                            checkboxShow={true}
                            isMulti={false}
                            options={genderList}
                            data={genderList.filter(v => filterPayload.gender === v.value)}
                            setData={(arr) => handleChange("gender", arr)}
                            extraClass='doctors-list-dropdown doctors-list-dropdown-gender'
                            placeholder={{ "selectSomeItems": "Gender" }}
                        />
                    </div>
                    <div>
                        <SelectDropdown
                            checkboxShow={true}
                            isMulti={true}
                            options={cityList}
                            data={cityList.filter(v => filterPayload.city.includes(v.value))}
                            setData={(arr) => handleChange("city", arr)}
                            extraClass='doctors-list-dropdown doctors-list-dropdown-location'
                            placeholder={{ "selectSomeItems": "Location" }}
                        />
                    </div>
                </div>
                {doctors.map((item: IDoctorItem) => (
                    <div key={item._id} className="parent-box">
                        <div className="appointment-grid">
                            <div className='flex items-start gap-2'>
                                <img
                                    src={`${process.env.REACT_APP_API_BASEURL}${item.isCoverPhoto}`}
                                    onError={(e) => {
                                        const target = e.target as HTMLImageElement; // Assert the type
                                        if (target) {
                                            target.src = defaultProfile;
                                        }
                                    }}
                                    alt="profile image"
                                    className="rounded-full profile-image-find-docter"
                                />
                                <div className='flex flex-col gap-2'>
                                    <div>
                                        <Link
                                            to={`/doctor-public-profile/${item._id}/view`}
                                            className="font-size-28px font-Poppins-Medium theme-color-green capitalize">
                                            {`${item.firstName} ${item.lastName}`}
                                        </Link>
                                        <p className="font-size-20px flex-wrap font-Poppins-Medium text-[#202020] flex items-center gap-1 mb-1">
                                            {item?.speicalizationData?.map((v, i) => (
                                                <p key={i} className="flex items-center gap-1">
                                                    <p>{v}</p>
                                                    {i < item.speicalizationData.length - 1 && <p>|</p>}
                                                </p>
                                            ))}
                                        </p> {/* specialization */}
                                        <span className="font-size-18px font-Poppins-Regular text-[#393939] flex items-center gap-1">
                                            {item.education.map((v, i) => (
                                                <span key={i} className="flex items-center">
                                                    <span>{v.course}</span>
                                                    {i < item.education.length - 1 && <span>, </span>}
                                                </span>
                                            ))}
                                        </span> {/* educations & degree */}
                                        <div className="stars flex gap-1 items-center mt-2">
                                            {[1, 2, 3, 4, 5].map((v) => (
                                                <FontAwesomeIcon
                                                    className={(item.averageStar[0] || 0) >= v ? "text-[#facc15]" : `text-[#b7b6b1]`}
                                                    key={v}
                                                    fontSize="16px"
                                                    icon={faStar}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="mt-6 flex gap-[20px] items-center">
                                        <div className="doctor-experience">
                                            <p className="font-size-18px font-Poppins-Regular text-[#707070] mb-2">
                                                Experience
                                            </p>
                                            <h1 className="font-size-20px font-Poppins-Medium">
                                                {convertMonthsToReadableFormat(Number(item.workExperience) || 0)}
                                            </h1>
                                        </div>
                                        <div className="doctor-experience">
                                            <p className="font-size-18px font-Poppins-Regular text-[#707070] mb-2">
                                                Reviews
                                            </p>
                                            <h1 className="font-size-20px font-Poppins-Medium">
                                                {item?.allReviews[0]?.reviews?.length || 0}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 self-start mt-6'>
                                {/* <PrimaryBtn type='button' ButtonText='Video Consultation' btnExtraStyle={{ borderRadius: '50rem', background: "transparent", border: '1px solid #00443F', color: "#00443F" }} /> */}
                                <PrimaryBtn type='button' ButtonText='Book Appointment' onPress={() => setDoctorId(item._id)} btnExtraStyle={{ borderRadius: '50rem', background: "#00443F", paddingLeft: 10, paddingRight: 10 }} />
                            </div>
                        </div>
                    </div>
                ))}
                <PaginationComponet
                    currentPage={paginated?.currentPage}
                    total={paginated?.totalPages}
                    setCurrentPage={(_page) => handleChangePage(_page)}
                />
            </div>

            {/* MODAL.... */}
            {doctorId
                &&
                <Modal
                    open={doctorId ? true : false}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div style={customeStyle}>
                        <button style={backBtnMaain} onClick={() => { setAvailability(null); setDoctorId(null) }}>
                            <FontAwesomeIcon className='font-size-16px' icon={faXmark} size="2x" style={backBtn} />
                        </button>
                        <div className="top_bar border-b border-[#707070]">
                            <p className='font-Poppins-SemiBold font-size-20px theme-color-green text-center py-4'>
                                Appointment Details
                            </p>
                        </div>
                        {fetchAvailLoader &&
                            <div className="text-center my-3">
                                <CircularProgress size={20} style={{ color: "#004540" }} />
                            </div>
                        }
                        {(availability && availability.onlineAvalibility.fees) &&
                            <div className="appointment-details flex items-center justify-between px-6 py-6">
                                <div className='w-[85%]'>
                                    <h1 className="font-Poppins-SemiBold font-size-20px mb-1">
                                        Online Video Consultation
                                    </h1>
                                    <h2 className="font-Poppins-Regular font-size-16px mb-1">
                                        Online video call
                                    </h2>
                                    <p className="font-Poppins-Medium font-size-16px text-[#2A872E] flex-wrap flex items-center">
                                        {Object.keys(availability.onlineAvalibility)
                                            .filter(key => weekDays.includes(key))
                                            .map((v, i, arr) => (
                                                <span key={v}>
                                                    <span className="capitalize">{v}</span>
                                                    <span className='mx-1'>{i < arr.length - 1 && '|'}</span>
                                                </span>
                                            ))}
                                    </p>
                                </div>
                                <div className='flex flex-col w-[15%] justify-around self-start h-full' >
                                    <span className="font-Poppins-Medium text-end font-size-20px">
                                        AED {availability.onlineAvalibility.fees}
                                    </span>
                                    <Link
                                        to={`/book-appointment/${doctorId}/${availability.onlineAvalibility._id}/view`}
                                        className='self-end mt-6 size-7 rounded-full bg-[#E5E5F0] flex items-center justify-center'>
                                        <FontAwesomeIcon icon={faAngleRight} style={{ color: '#000046' }} />
                                    </Link>
                                </div>
                            </div>
                        }
                        {availability &&
                            availability.timings.map((item: IAvailability, index: number) => (
                                <div key={index} className="appointment-details flex items-center justify-between px-6 py-6">
                                     <div className='w-[85%]'>
                                        <h1 className="font-Poppins-SemiBold font-size-20px mb-1">
                                            {item.hospitalName}
                                        </h1>
                                        <h2 className="font-Poppins-Regular font-size-16px mb-1">
                                            In-person Consultation
                                        </h2>
                                        <p className="font-Poppins-Medium font-size-16px text-[#2A872E] flex-wrap flex items-center">
                                            {Object.keys(item)
                                                .filter(key => weekDays.includes(key))
                                                .map((v, i, arr) => (
                                                    <span key={v}>
                                                        <span className="capitalize">{v}</span>
                                                        <span className='mx-1'>{i < arr.length - 1 && '|'}</span>
                                                    </span>
                                                ))}
                                        </p>
                                    </div>
                                    <div className='flex w-[15%] flex-col justify-around self-start h-full' >
                                        <span className="font-Poppins-Medium text-end font-size-20px">
                                            AED {item.fees}
                                        </span>
                                        <Link
                                            to={`/book-appointment/${doctorId}/${item._id}/view`}
                                            className='self-end mt-6 size-7 rounded-full bg-[#E5E5F0] flex items-center justify-center'>
                                            <FontAwesomeIcon icon={faAngleRight} style={{ color: '#000046' }} />
                                        </Link>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Modal>
            }
            {/* MODAL--END.... */}

        </>
    );
};

// Style object for the modal
const customeStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(255, 255, 255)",
    maxWidth: 600,
    width: "100%",
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: 8
};

// Style for the close button container
const backBtnMaain: React.CSSProperties = {
    position: "absolute",
    top: -10,
    right: -10,
    backgroundColor: "red",
    borderRadius: 100,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

// Style for the close button text
const backBtn: React.CSSProperties = {
    color: "white",
};

export default DoctorsList;