/**
 * SideBarNav Component
 * 
 * Description: This component renders a sidebar navigation menu with a list of items.
 * Each item includes a title, an icon, and a URL to navigate to.
 * 
 * File Name: SideBarNav.tsx
 * Date: 12-08-2024
 */

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import myOrdersIcon from "src/assets/images/sidebar-icon-orders.png";
import myResultIcon from "src/assets/images/sidebar-icon-results.png";
import myHealthIcon from "src/assets/images/sidebar-icon-healthprofile.png";
import searchIcon from "src/assets/images/sidebar-icon-search.png";
import apointmentIcon from "src/assets/images/appointment.png";
import doctorIcon from "src/assets/images/medical-assistance.png";
import myCustomerIcon from "src/assets/images/my-customers.png";
import Cookies from 'js-cookie';
import { customer, doctor, doctorMode } from 'src/lib/constans';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';

interface IListItem {
    isDisplay: boolean;
    title: string;
    icons: string;
    url: string;
    activeUrl: string[];
}

const SideBarNav: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const [mode, setMode] = useState(Cookies.get(doctorMode) || customer);

    const { pathname } = useLocation();

    // List of navigation items with titles, icons, and URLs
    const listItems: IListItem[] = [
        { title: "My Orders", icons: myOrdersIcon, url: "/orders", isDisplay: mode === customer, activeUrl: ["/orders"] },
        { title: "My Results", icons: myResultIcon, url: "/results", isDisplay: mode === customer, activeUrl: ["/results"] },
        { title: "My Health Profile", icons: myHealthIcon, url: "/personal-details", isDisplay: mode === customer, activeUrl: ["/personal-details"] },
        { title: "Find Doctor", icons: searchIcon, url: "/find-doctors", isDisplay: mode === customer, activeUrl: ["/find-doctors"] },
        { title: "My Appointment", icons: apointmentIcon, url: "/appointments", isDisplay: mode === customer, activeUrl: ["/appointments"] },
        { title: "My Doctor", icons: doctorIcon, url: "/my-doctors", isDisplay: mode === customer, activeUrl: ["/my-doctors"] },

        { title: "My Appointment", icons: apointmentIcon, url: "/appointments", isDisplay: mode === doctor && userDetails?.userRole === doctor, activeUrl: ["/appointments"] },
        { title: "My Customer", icons: myCustomerIcon, url: "/my-customers", isDisplay: mode === doctor && userDetails?.userRole === doctor, activeUrl: ["/my-customers"] },
    ];

    useEffect(() => {
        // Function to check for cookie changes
        const checkCookieChange = () => {
            const currentMode = Cookies.get(doctorMode) || customer;
            if (currentMode !== mode) {
                setMode(currentMode);
            }
        };

        // Set an interval to check for changes every second (1000ms)
        const intervalId = setInterval(checkCookieChange, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [mode]);

    return (
        <div className='sidebar-nav'>
            <ul className='sidebar-nav-items py-2'>
                {listItems.filter(v => v.isDisplay).map((item: IListItem, index: number) => (
                    <li key={index}>
                        <Link to={item?.url} className={`sidebar-nav-item ${item.activeUrl.some(v => v === pathname) ? "bg-[#eeeeee]" : ""}`}>
                            <div className="sidebar-nav-icon">
                                <img src={item?.icons} alt="sidebar-myorders-icon" width={15} />
                            </div>
                            <p className='font-size-15px font-Poppins-Medium title-heading'>{item?.title || ""}</p>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SideBarNav;