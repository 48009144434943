/**
 * Appointments
 * 
 * Description: This component displays a list of appointments for the user, allowing for filtering by appointment type (online/physical), status, and date range. It supports pagination and fetches appointment data based on selected filters. The component uses a custom table wrapper to present the appointment details with a link to view more information. The view adapts depending on whether the appointments are online or physical.
 * 
 * File Name: Appointments.tsx
 * Date: 09-09-2024
 */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomerTableWrapper from 'src/Components/Customer/CustomerTableWrapper';
import PageHeader from 'src/Components/Customer/PageHeader';
import { IAppointmentItems, IDropDownArray, IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import eyeIcon from 'src/assets/images/eye-icon-green.png';
import { customer, doctorMode, statusColor } from 'src/lib/constans';
import StartAndEndDateFilter from 'src/Components/GlobalComponents/StartAndEndDateFilter';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import qs from "qs";
import moment from 'moment';
import Cookies from 'js-cookie';
import { truncateString } from "src/helpers/helperFunctions";

interface IItem {
    title: string;
    isOnline: boolean;
}; // Interface for dropdown items (status and appointment type)

interface IMyAppointmentResponse {
    documents: IAppointmentItems[];
    paginated: IPaginated;
}; // Interface for the API response containing appointments and pagination info

const Appointments: React.FC = () => {
    const statusList: IDropDownArray[] = [
        { label: "Pending", value: "pending" },
        { label: "Complete", value: "completed" },
        { label: "Cancelled", value: "cancelled" },
    ]; // Status filter options
    const tabList: IItem[] = [
        { title: "Online Appointment", isOnline: true },
        { title: "Physical Appointment", isOnline: false },
    ]; // Appointment type tabs (Online/Physical)

    const [mode] = useState(Cookies.get(doctorMode) || customer);

    // State hooks for loading status, appointment data, pagination, and query parameters
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [allAppointments, setAllAppointments] = useState<IAppointmentItems[]>([]); // List of orders
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Pagination state
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        role: mode, // Role for querying appointments
        isOnline: true, // Filter by appointment type (online or physical)
        startDate: "",
        endDate: "",
        status: "", // Filter by appointment status
    }); // Query parameters for API request

    // Function to handle changing the appointment type (online/physical)
    const handleChangeCategory = (isOnline: boolean) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            isOnline,
        }));
    };

    // Function to change the appointment status filter
    const changeStatus = (sel: IDropDownArray[]) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            status: sel[0] ? sel[0].value : "",
        }));
    };

    // Function to handle page changes in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Function to apply date range filters
    const applyFilter = (startDate: string, endDate: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            startDate,
            endDate,
        }));
    };

    // Function to reset date range filters
    const resetFilter = () => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            startDate: "",
            endDate: "",
        }));
    };

    // API_CALL: Function to fetch the list of appointments from the API
    const fetchAppointmentList = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IMyAppointmentResponse>({ path: `/appoiment/booking?${qs.stringify(queryParams)}` });
        if (res) {
            const { documents = [], paginated } = res;
            setAllAppointments(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchAppointmentList();
    }, [queryParams]); // Fetch appointments when queryParams change

    return (
        <>
            <PageHeader
                title="My Appointment"
            />
            <div className="parent-box">
                <div className="flex gap-4 items-center">
                    {
                        tabList.map((item: IItem, index: number) => (
                            <button
                                onClick={() => handleChangeCategory(item.isOnline)}
                                key={index}
                                className={`border font-size-16px font-Poppins-SemiBold health-tabs-main-btn rounded-full ${queryParams.isOnline === item.isOnline ? "text-[#fff] theme-bg-green" : "theme-color-green border-[#004540]"}`}>
                                {item.title}
                            </button>
                        ))
                    }
                </div>
                <hr className="my-4" />
                <StartAndEndDateFilter
                    reset={resetFilter}
                    apply={applyFilter}
                />
                <hr className="my-4" />
                <div className="mb-4 ms-auto" style={{ maxWidth: 240 }}>
                    <SelectDropdown
                        options={statusList}
                        setData={changeStatus}
                        data={statusList.filter(v => v.value === queryParams.status)}
                        extraClass={"border__dropdown__primary"}
                        placeholder={{ "selectSomeItems": "Filter by Status" }}
                    />
                </div>
                {queryParams.isOnline
                    ?
                    <CustomerTableWrapper
                        isLoading={isLoader}
                        thead={["S.No", "Name", "Fee", "Time ", "Status", "Date", "View Details"]}
                        page={paginated.currentPage || 1}
                        total={paginated.totalPages || 0}
                        // columnWidth={"10% 17% 12% 20% 12% 15% 14%"}
                        // columnWidth={"repeat(auto-fit, minmax(20px, 1fr)"}
                        columnWidth={"10% 20% 10% 20% 15% 11% 14%"}
                        onChange={handlePageChange}
                    // tailwindClass='gap-x-[6px]'

                    >
                        {allAppointments?.map((item: IAppointmentItems, index: number) => (
                            <ul key={index} className='font-size-16px tbody-light-table customer-table-wrapper-body-padding-non customer-table-wrapper-body tbody-light-table-customer font-Poppins-Regular grid' style={{ gridTemplateColumns: "10% 20% 10% 20% 15% 11% 14%" }}>
                                <li style={{ paddingLeft: '2rem' }}>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                <li className="capitalize">{`${item.firstName} ${item.lastName}`}</li>
                                <li>AED {item.fees}</li>
                                <li>{`${moment(item.startTime, "HH:mm").format("h:mm A")} to ${moment(item.endTime, "HH:mm").format("h:mm A")}`}</li>
                                <li>
                                    <span
                                        className='py-1 px-4 text-white capitalize rounded'
                                        style={{
                                            backgroundColor:
                                                item.status === "pending" ? statusColor.yellow
                                                    : item.status === "completed" ? statusColor.green
                                                        : item.status === "cancelled" ? "red"
                                                            : ""
                                        }}
                                    >{item.status}</span>
                                </li>
                                <li>{moment(item.appoinmentDate).format("DD-MM-YYYY")}</li>
                                <li className='action-last-colum'>
                                    <Link to={`/appointment/${item?._id}/view`}>
                                        <img src={eyeIcon} alt="" />
                                    </Link>
                                </li>
                            </ul>
                        ))}
                    </CustomerTableWrapper>
                    :
                    <CustomerTableWrapper
                        isLoading={isLoader}
                        thead={["S.No", "Name", "Hospital", "Fee", "Time ", "Status", "Date", "View Details"]}
                        page={paginated?.currentPage || 1}
                        total={paginated?.totalPages || 0}
                        columnWidth={"10% 16% 12% 9% 16% 14% 11% 12%"}
                        onChange={handlePageChange}
                    >
                        {allAppointments?.map((item: IAppointmentItems, index: number) => (
                            <ul key={index} className='font-size-16px tbody-light-table customer-table-wrapper-body-padding-non  tbody-light-table-customer customer-table-wrapper-body font-Poppins-Regular grid' style={{ gridTemplateColumns: "10% 16% 12% 9% 16% 14% 11% 12%" }}>
                                <li style={{ paddingLeft: '2rem' }}>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                <li>{`${item.firstName} ${item.lastName}`}</li>
                                <li>{truncateString(item?.hospitalName || "", 8) || ""}</li>
                                <li>AED {item.fees}</li>
                                <li>{`${moment(item.startTime, "HH:mm").format("h:mm A")} to ${moment(item.endTime, "HH:mm").format("h:mm A")}`}</li>
                                <li>
                                    <span
                                        className='py-1 px-4 text-white capitalize rounded'
                                        style={{
                                            backgroundColor:
                                                item.status === "pending" ? statusColor.yellow
                                                    : item.status === "completed" ? statusColor.green
                                                        : item.status === "cancelled" ? statusColor.red
                                                            : ""
                                        }}
                                    >{item.status}</span>
                                </li>
                                <li>{moment(item.appoinmentDate).format("DD-MM-YYYY")}</li>
                                <li className='action-last-colum'>
                                    <Link to={`/appointment/${item?._id}/view`}>
                                        <img src={eyeIcon} alt="" />
                                    </Link>
                                </li>
                            </ul>
                        ))}
                    </CustomerTableWrapper>
                }
            </div>
        </>
    );
};

export default Appointments;