/**
 * AccountSettings.tsx
 * 
 * Date: 09-08-2024
 * 
 * Description: 
 * This component renders the account settings page for users. It includes components for changing password, changing email,
 * updating notification settings, and deleting the account. The page header is also displayed with the title "Account Setting".
 */

import React from 'react';
import ChangeEmail from 'src/Components/Customer/ChangeEmail';
import ChangePassword from 'src/Components/Customer/ChangePassword';
import DeleteAccount from 'src/Components/Customer/DeleteAccount';
import NotificationStatus from 'src/Components/Customer/NotificationStatus';
import PageHeader from 'src/Components/Customer/PageHeader';

const AccountSettings: React.FC = () => {
    return (
        <>
            {/* Page header for account settings */}
            <PageHeader
                title="Account Settings"
            />
            {/* Component for changing the password */}
            <ChangePassword />
            {/* Component for changing the email address */}
            <ChangeEmail />
            {/* Component for managing notification status toogle */}
            <NotificationStatus />
            {/* Component for deleting the account */}
            <DeleteAccount />
        </>
    );
};

export default AccountSettings;