/**
 * EditPaymentInfo Component
 * 
 * Description: This component allows users to edit their payment information, including account holder name, bank details, and routing information.
 * It handles form submission, manages loading state, and uses a callback function to pass updated information upon successful submission.
 * 
 * File Name: EditPaymentInfo.tsx
 * Date: 27-09-2024
 */

import React, { FormEvent, useEffect, useState } from 'react';
import SimpleInput from '../SimpleInput';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { IAccountInfo } from 'src/types/GlobalInterfaces';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';

interface inputItems {
    label: string;
    required: boolean;
    type: "text" | "number";
    maxLength: number | undefined;
    key: keyof IAccountInfo;
};

const EditPaymentInfo: React.FC<{ accountInfo: IAccountInfo | null; onSuccess: (obj: IAccountInfo) => void; isOperatorEdit?: boolean; uid?: string; extraContainerClasses?: string; }> = ({ accountInfo = null, onSuccess = () => { }, isOperatorEdit = false, uid = "", extraContainerClasses = "" }) => {
    const allInputs: inputItems[] = [
        {
            label: "Account Holder Name",
            required: true,
            type: "text",
            maxLength: undefined,
            key: "accountHolderName"
        },
        {
            label: "Bank Account Number",
            required: true,
            type: "text",
            maxLength: undefined,
            key: "accountNumber"
        },
        {
            label: "Bank Name",
            required: true,
            type: "text",
            maxLength: undefined,
            key: "bankName"
        },
        {
            label: "Bank Branch Code",
            required: true,
            type: "text",
            maxLength: undefined,
            key: "bankBranchCode"
        },
        {
            label: "BIC / Swift Code",
            required: true,
            type: "text",
            maxLength: undefined,
            key: "swiftCode"
        },
        {
            label: "IBAN",
            required: true,
            type: "text",
            maxLength: undefined,
            key: "iban"
        },
        {
            label: "Routing Number",
            required: true,
            type: "text",
            maxLength: undefined,
            key: "routingNumber"
        },
    ];

    const handleChange = (key: keyof IAccountInfo, val: string) => {
        setEditState(prev => {
            // Create a new state object, ensuring we handle the case where prev might be null
            const prevState = prev || {} as IAccountInfo; // Fallback to an empty object if prev is null
            return {
                ...prevState,
                [key]: val
            };
        });
    };

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [editState, setEditState] = useState<IAccountInfo | null>(accountInfo); // State to manage edited values

    // API_CALL:to edit and create bank information
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; createBankDetailsDto: IAccountInfo }>({
            method: "put",
            path: isOperatorEdit ? `/paymentandwithdraw/update?id=${uid}` : "/paymentandwithdraw/update",
            params: editState
        });
        if (res) {
            const { message, createBankDetailsDto } = res;
            toast(message || "Success", { type: 'success' });
            onSuccess(createBankDetailsDto);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        setEditState(accountInfo);
    }, [accountInfo]);

    return (
        <form onSubmit={handleSubmit}>
            <div className={`grid grid-cols-1 md:grid-cols-2 gap-3 mb-3 ${extraContainerClasses}`}>
                {allInputs.map((item: inputItems) => (
                    <div key={item.key}>
                        <SimpleInput
                            value={editState ? editState[item.key] || "" : ""}
                            type={item.type}
                            isRequired={item.required}
                            isLabel={true}
                            labelText={item.label}
                            maxLength={item.maxLength}
                            onChange={(val) => handleChange(item.key, val)}
                            extraStyle={{ margin: 0, borderColor: "#009d91" }}
                        />
                    </div>
                ))}
            </div>
            <div className="flex justify-end">
                <PrimaryBtn
                    loader={isLoader}
                    type="submit"
                    ButtonText={"Update"}
                    btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                />
            </div>
        </form>
    );
};

export default EditPaymentInfo;