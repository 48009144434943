/**
 * RevenueGraph Component
 * 
 * Description: This component displays a line chart for revenue data, allowing users to view statistics 
 * on a monthly or yearly basis. It includes a loading spinner while data is being fetched and uses a 
 * `LineChart` for rendering the revenue data. The chart includes custom tooltips and legends for better 
 * visualization, and it provides a dropdown for selecting the data category (monthly or yearly).
 * 
 * File Name: RevenueGraph.tsx
 * Date: 29-08-2024
 * 
 * Marker: DOC_START
 */

import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import httpRequest from 'src/helpers/httpRequest';
import { allMonths, allYears } from 'src/lib/constans';
import SelectDropdown from '../GlobalComponents/SelectDropdown';
interface IData {
    totalAmount: number;
    _id: { year: number; month: 8 };
};

interface IRevenueStats {
    totalCount: { count: number }[];
    monthlyData: IData[];
    yearlyData: IData[];
};

const RevenueGraph: React.FC = () => {
    const currentYear = new Date().getFullYear(); // Get the current year

    const [isLoader, setIsLoader] = useState<boolean>(false);
    console.log("RevenueGraph", isLoader);
    const [data, setData] = useState<{ name: string; value: number }[]>([]);
    const [category, setCategory] = useState<string>("monthly");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [monthlyData, setMonthlyData] = useState<IData[]>([]);
    const [yearlyData, setYearlyData] = useState<IData[]>([]);

    const fetchRevenueStats = async () => {
        setIsLoader(true);
        try {
            const { res, err } = await httpRequest<IRevenueStats[]>({ path: "/order/graph/statistics/revenue" });
            if (res) {
                const { totalCount, monthlyData, yearlyData } = res[0];
                setTotalCount(totalCount[0]?.count || 0);
                setMonthlyData(monthlyData);
                setYearlyData(yearlyData);
            } else {
                toast(err, { type: 'error' });
            }
        } catch (error) {
            // Handle any unexpected errors here
            console.error("Error fetching revenue stats:", error);
            toast("Something went wrong!", { type: 'error' });
        } finally {
            setIsLoader(false); // Always set loader to false after the API call
        }
    };

    useEffect(() => {
        fetchRevenueStats();
    }, []);

    useEffect(() => {
        if (category === 'monthly') {
            const formattedData = allMonths.map(({ month, name }) => {
                const _value = monthlyData.find(v => v._id.month === month && v._id.year === currentYear);
                return {
                    name: name, // Month name
                    value: _value ? _value.totalAmount : 0, // Default to 0 if no data
                };
            });
            setData(formattedData);
        } else if (category === 'yearly') {
            const formattedData = allYears.map(year => {
                const _value = yearlyData.find(v => v._id.year === year);
                return {
                    name: String(year), // year name
                    value: _value ? _value.totalAmount : 0, // Default to 0 if no data
                };
            });
            setData(formattedData);
        } else { }
    }, [category, totalCount]);
    return (
        <div style={{ height: '100%' }}>
            <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">Revenue</h2>
            <div className='user-graph-inner'>
                <div className='flex justify-between items-center'>
                    <div className='font-Poppins-Regular user-graph-header-revenue user-graph-header font-size-14px'>
                        Total revenue: <span className='font-Poppins-SemiBold theme-color-green font-size-20px'>AED {totalCount}</span>
                    </div>
                    {/* <div>
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className='font-Poppins-SemiBold user-graph-select theme-color-green font-size-14px'
                        >
                            <option className='font-Poppins-Medium font-size-14px' value="monthly">Monthly</option>
                            <option className='font-Poppins-Medium font-size-14px' value="yearly">Yearly</option>
                        </select>
                    </div> */}
                    <div className='select-container-graph'>
                        <SelectDropdown
                            options={[{ label: "Monthly", value: "monthly" }, { label: "Yearly", value: "yearly" }]}
                            isMulti={false}
                            data={[{ label: category, value: category }]}
                            setData={(e) => e[0] ? setCategory(e[0].value) : ""}
                            checkboxShow={false}
                            isLabel={false}
                            isRequired={true}
                            clearIcon={false}
                        />
                    </div>
                </div>
                {isLoader
                    &&
                    <div className="text-center">
                        <CircularProgress size={20} style={{ color: "#004540" }} />
                    </div>
                }
                <ResponsiveContainer className={'user-graph-container'}>
                    <LineChart data={data} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
                        <CartesianGrid />
                        <XAxis className='revenue-axis' dataKey="name" />
                        <YAxis className='revenue-axis' tickFormatter={(value) => `${value}`} />
                        <Tooltip wrapperClassName='revenue-axis' />
                        {/* <Legend verticalAlign="top" height={36} iconType="circle" /> */}
                        <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#008A80"
                            dot={{ r: 5 }}
                            activeDot={{ r: 7 }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default RevenueGraph;