/**
 * AddBiomarkerTab Component
 *
 * Description: This component represents the tab for adding biomarkers to a product. It allows users to view, 
 * search, and select biomarkers from a list. The component includes functionality for pagination, adding/removing 
 * biomarkers, and displaying the selected biomarkers. It also manages state for loading indicators and API 
 * requests to fetch all available biomarkers.
 *
 * Props:
 * - state (IProductItems): The current product state, which includes the list of selected biomarker IDs.
 * - setState (function): Function to update the product state, particularly the biomarker IDs.
 * - tabChange (function): Callback function invoked to change the active tab after completing the addition of biomarkers.
 *
 * File Name: AddBiomarkerTab.tsx
 * Date: 31-07-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faMagnifyingGlass, faPlus, faSubtract } from "@fortawesome/free-solid-svg-icons";
import TableWrapper from "../../GlobalComponents/TableWrapper";
import { IBiomarkerItems, IProductItems, IProductsTabsProps, IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import httpRequest from 'src/helpers/httpRequest';
import QueryString from "qs";
import { toast } from 'react-toastify';
import { truncateString } from 'src/helpers/helperFunctions';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';

interface IBiomarkesResponse {
    documents: IBiomarkerItems[];
    paginated: IPaginated;
};

const AddBiomarkerTab: React.FC<{
    state: IProductItems; setState: React.Dispatch<React.SetStateAction<IProductItems>>; tabChange: () => void
}> = ({
    state,
    setState,
    tabChange
}) => {
        const [isLoader, setIsLoader] = useState<boolean>(false); // State for loading indicator
        const [queryParams, setQueryParams] = useState<IqueryParams>({
            page: 1,
            limit: 10,
            search: "",
            counter: 1
        });  // State for query parameters in API requests
        const [selectedBioMarkers, setSelectedBioMarkers] = useState<{ name: string; id: string; }[]>([]);
        const [allBiomarkers, setAllBiomarkers] = useState<IBiomarkerItems[]>([]); // State to store the list of biomarkers
        const [paginated, setPaginated] = useState<IPaginated>({
            currentPage: 1,
            totalPages: 1,
        }); // state for handle pagination like a current page, page limit & total pages

        // Function to handle adding or removing biomarkers from the state
        const handleAddOrRemove = (id: string, name: string = "") => {
            // Update selectedBioMarkers state
            setSelectedBioMarkers(prev => {
                // Check if the biomarker already exists in the state
                const exists = prev.some(marker => marker.id === id);
                if (exists) {
                    // Remove the biomarker if it exists
                    return prev.filter(marker => marker.id !== id);
                } else {
                    // Add the biomarker if it does not exist
                    return [...prev, { name, id }];
                }
            });
            setState(prev => {
                const isIdInArray = prev?.biomarkerId?.includes(id);
                if (isIdInArray) {
                    // add in array
                    return {
                        ...prev,
                        biomarkerId: prev?.biomarkerId?.filter(bi => bi !== id)
                    }
                } else {
                    // Otherwise, add the id to the array
                    return {
                        ...prev,
                        biomarkerId: [...prev?.biomarkerId, id]
                    };
                }
            });
        };

        // Function to handle page change in pagination
        const handlePageChange = (e: number) => {
            setQueryParams(prev => ({
                ...prev,
                page: e
            }));
        };

        // Function change searh query
        const changeQuery = (txt: string) => {
            setQueryParams(prev => ({
                ...prev,
                page: 1,
                search: txt
            }));
        };

        //API_CALL: Function to fetch all biomarkers from the API
        const fetAllBiomarkers = async () => {
            setIsLoader(true);
            let { counter, ...remainingParams } = queryParams;
            const { res, err } = await httpRequest<IBiomarkesResponse>({ path: `/biomarker/all?${QueryString.stringify(remainingParams)}` });
            if (res) {
                setAllBiomarkers(res?.documents);
                setPaginated(res?.paginated);
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        };

        useEffect(() => {
            fetAllBiomarkers();
        }, [queryParams]);

        return (
            <>
                <div className='product-tabs-add-biomarker'>
                    <h3 className='font-size-20px text-[#4F6367] font-Poppins-Medium'>Biomarkers</h3>
                    <div className='add-biomarker-box-main'>
                        {state?.biomarkerId?.length > 0
                            ?
                            selectedBioMarkers.map(item => (
                                <div key={item.id} className='add-biomarker-box relative'>
                                    <div onClick={() => handleAddOrRemove(item.id)} className='add-biomarker-box-svg'><FontAwesomeIcon icon={faXmark} /></div>
                                    <p className='font-size-16px font-Poppins-Regular'>{item.name}</p>
                                </div>
                            ))
                            :
                            <p>Please add biomarkers from the list below.</p>
                        }
                    </div>
                </div>
                <hr className='products-tabs-hr' style={{ border: '1px solid #D4D4D4' }} />
                <SearchFilterInput
                    onChangeText={changeQuery}
                />
                <TableWrapper
                    isLoading={isLoader}
                    thead={["S.No", "Biomarker", "Measurement Unit", "Description", "Action"]}
                    page={paginated?.currentPage || 1}
                    total={paginated?.totalPages || 0}
                    columnWidth="10% 15% 25% 40% 10%"
                    onChange={handlePageChange}
                >
                    {allBiomarkers.map((item: IBiomarkerItems, index: number) => (
                        <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "10% 15% 25% 40% 10%" }}>
                            <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                            <li>{item?.name || ""}</li>
                            <li>{item?.measurementUnit || ""}</li>
                            <li>{truncateString(item?.shortDescription, 80) || ""}</li>
                            <li className='action-last-colum'>
                                <FontAwesomeIcon icon={state?.biomarkerId?.includes(item?._id) ? faSubtract : faPlus} onClick={() => handleAddOrRemove(item?._id, item.name)} />
                            </li>
                        </ul>
                    ))}
                </TableWrapper>
                <div className='flex justify-end my-6'>
                    <PrimaryBtn
                        ButtonText="Next"
                        btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        onPress={tabChange}
                    />
                </div>
            </>
        );
    };

export default AddBiomarkerTab;