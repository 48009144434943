/**
 * Categories Component
 * 
 * Description: This component manages the categories section of the admin panel. It includes functionality for listing categories, creating or editing categories, and deleting categories. It features a paginated table display, a confirmation modal for deletions, and integrates with an API to fetch and manage Category data.
 * 
 * File Name: Categories.tsx
 * Date: 25-07-2024
 */

import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../Components/GlobalComponents/BreadCrumb';
import TableWrapper from '../../Components/GlobalComponents/TableWrapper';
import UpsertCategories from '../../Components/AdminComponents/UpsertCategories';
import { IPaginated } from 'src/types/paginated';
import { ICategoryItems, IqueryParams } from 'src/types/GlobalInterfaces';
import httpRequest from 'src/helpers/httpRequest';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import moment from 'moment';
import emtyImg from '../../assets/images/emty-img.png';
import editIcon from '../../assets/images/edit-icon.png';
import deleteIcon from '../../assets/images/delete-icon.png';
import ConfirmationModal from 'src/Components/GlobalComponents/ConfirmationModal';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';

// Define the response type
interface ICategoryResponse {
  documents: ICategoryItems[];
  paginated: IPaginated;
}

const Categories: React.FC = () => {

  const [isUpsert, setIsUpsert] = useState<boolean>(false); // State to manage the upsert mode (create/edit category)
  const [isEdit, setIsEdit] = useState<ICategoryItems | boolean>(false); // State to determine if the component is in edit mode

  const [deleteLoader, setDeleteLoader] = useState<boolean>(false); // State to manage the loader during category deletion
  const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage the loader for fetching categories
  const [allCategories, setAllCategories] = useState<ICategoryItems[]>([]); // State to store the list of categories
  const [paginated, setPaginated] = useState<IPaginated>({
    currentPage: 1,
    totalPages: 1,
  }); // state for handle pagination like a current page, page limit & total pages

  const [deleteModal, setDeleteModal] = useState<boolean>(false); // State to control the visibility of the delete confirmation modal
  const [deleteId, setDeleteId] = useState<string>("");  // State to store the ID of the category to be deleted

  const [queryParams, setQueryParams] = useState<IqueryParams>({
    page: 1,
    limit: 10,
    search: "",
    counter: 1
  });

  // Function to toggle between create and edit mode
  const handleClose = () => {
    setIsEdit(false);
    setIsUpsert(prev => !prev);
  };

  // Function to set the component to edit mode and populate it with category data
  const handleEdit = (obj: ICategoryItems) => {
    setIsUpsert(true);
    setIsEdit(obj);
  };

  const handlePageChange = (e: number) => {
    setQueryParams(prev => ({
      ...prev,
      page: e
    }));
  };

  // Function to show the delete confirmation modal and set the ID of the category to be deleted
  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setDeleteId(id);
  };

  // Function to close the delete confirmation modal
  const deleteModalClose = () => {
    setDeleteModal(false);
    setDeleteId("");
  };

  // Function change search text for api query params
  const handleChangeText = (txt: string) => {
    setQueryParams(prev => ({
      ...prev,
      page: 1,
      search: txt
    }));
  };

  // Function to update the list of categories after creating or editing a category
  const handleUpdateList = () => {
    setQueryParams(prev => ({
      ...prev,
      counter: (prev.counter ?? 0) + 1,
    }));
    setIsUpsert(false);
  };

  // API_CALL: Function to delete a category by its ID
  const deleteCategory = async () => {
    setDeleteLoader(true);
    const { res, err } = await httpRequest<any>({ method: "delete", path: `/category/delete/${deleteId}`, params: {} });
    if (res) {
      toast("Category deleted successfully.", { type: 'success' });
      setQueryParams(prev => ({
        ...prev,
        counter: (prev.counter ?? 0) + 1,
        page: allCategories?.length > 1 ? prev?.page : prev?.page > 1 ? prev?.page - 1 : prev?.page
      }));
      setDeleteModal(false);
      setDeleteId("");
    } else {
      toast(err, { type: 'error' });
    }
    setDeleteLoader(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoader(true);
      let { counter, ...remainingParams } = queryParams;
      const { res, err } = await httpRequest<ICategoryResponse>({ path: `/category/all?${QueryString.stringify(remainingParams)}` });
      if (res) {
        setAllCategories(res?.documents);
        setPaginated(res?.paginated);
      } else {
        toast(err, { type: 'error' });
      }
      setIsLoader(false);
    })();
  }, [queryParams]);

  return (
    <>
      <ConfirmationModal
        show={deleteModal}
        onClose={deleteModalClose}
        title="Are you sure you want to delete?"
        leftBtnText="No"
        rightBtnText="Yes"
        onConfirm={deleteCategory}
        loader={deleteLoader}
      />
      <BreadCrumb
        current="Test Categories"
        previous="dashboard"
        navigateUrl="/admin"
        btnText={"Add Category"}
        btnShow={true}
        onPress={handleClose}
        isUpsert={isUpsert}
      />
      {
        isUpsert
          ?
          <UpsertCategories
            onSuccess={handleUpdateList}
            isEdit={isEdit}
          />
          :
          <div className="table-container">
            <SearchFilterInput
              onChangeText={handleChangeText}
            />
            <TableWrapper
              isLoading={isLoader}
              thead={["S.No", "Image", "Category Name", "Date", "Action"]}
              page={paginated?.currentPage || 1}
              total={paginated?.totalPages || 0}
              columnWidth="6% 10% 54% 20% 10%"
              onChange={handlePageChange}
            >
              <div className='tbody-content-height'>
              {allCategories?.map((item: ICategoryItems, index: number) => (
                <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 10% 54% 20% 10%" }}>
                  <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                  <li className="action-last-colum-img" style={{justifyContent: 'center',paddingLeft:0,}}>
                    <img
                      src={`${process.env.REACT_APP_API_BASEURL}${item?.categoryImage}`}
                      alt="category"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement; // Type assertion
                        target.src = emtyImg; // Handle error by setting default image
                      }}
                    />
                  </li>
                  <li>{item?.name || ""}</li>
                  <li>{moment(item?.createdAt).format("DD-MM-YYYY")}</li>
                  <li className='action-last-colum'>
                    <img onClick={() => handleEdit(item)} src={editIcon} alt="" />
                    <img onClick={() => handleDelete(item?._id)} src={deleteIcon} alt="" />
                  </li>
                </ul>
              ))}
              </div>
            </TableWrapper>
          </div>
      }
    </>
  );
};

export default Categories;