/**
 * CustomerSidebar Component
 * 
 * Description: This component renders a sidebar for the customer panel. It includes navigation links to various sections of the customer interface, with corresponding icons and titles.
 * 
 * File Name: CustomerSidebar.tsx
 * Date: 06-08-2024
 */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SideBarNav from './SideBarNav';
import defaultProfile from "src/assets/images/default_profile_image.png";
import defaultBg from "src/assets/images/sidebar-bg.png";
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { customer, doctor, doctorMode } from 'src/lib/constans';
import Cookies from 'js-cookie';
import { truncateString } from 'src/helpers/helperFunctions';

const CustomerSidebar: React.FC = () => {
  const { userDetails } = useSelector((store: RootState) => store.appReducer);
  const { profile } = useSelector((store: RootState) => store.doctor);
  const [mode, setMode] = useState(Cookies.get(doctorMode) || customer);

  useEffect(() => {
    // Function to check for cookie changes
    const checkCookieChange = () => {
      const currentMode = Cookies.get(doctorMode) || customer;
      if (currentMode !== mode) {
        setMode(currentMode);
      }
    };

    // Set an interval to check for changes every second (1000ms)
    const intervalId = setInterval(checkCookieChange, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [mode]);

  return (
    <div className="customer-sidebar">
      <div className="parent-box customer-sidebar-first-box">
        <div className='cover-image-bg' style={{
          backgroundImage: `url(${userDetails?.backgroundPicture
            ?
            process.env.REACT_APP_API_BASEURL + userDetails?.backgroundPicture
            :
            defaultBg
            })`
        }}></div>
        <div className="profile-info">
          <img
            alt='profile-image'
            className='rounded-full'
            style={{ border: "2px solid #fff" }}
            src={`${process.env.REACT_APP_API_BASEURL}${userDetails?.coverPhoto}?t=${Date.now()}`}
            onError={(e) => {
              const target = e.target as HTMLImageElement; // Assert the type
              if (target) {
                target.src = defaultProfile;
              }
            }}
          />

          <div className="profile-des">
            <span className="title-heading font-Poppins-SemiBold font-size-20px capitalize">{`${userDetails?.firstName || ""} ${userDetails?.lastName || ""}`}</span>
            {userDetails?.userRole === doctor
              ?
              <div>
                <p className='font-size-15px font-Poppins-Regular flex items-center justify-center flex-wrap'>
                  {profile?.specailizedDetails?.map((v, i) => (
                    <span key={i}>
                      <span>{v.name}</span>
                      {i < profile?.specailizedDetails?.length - 1 && <span className="mx-1">|</span>}
                    </span>
                  ))}
                </p>
                <p className='font-size-15px font-Poppins-Regular flex items-center justify-center flex-wrap mt-2 theme-color-green'>
                  {profile?.educatuonHistory?.map((v, i) => (
                    <span key={i}>
                      <span>{v}</span>
                      {i < profile?.educatuonHistory?.length - 1 && <span>, </span>}
                    </span>
                  ))}
                </p>
              </div>
              :
              <span title={userDetails?.headLine || ""} className='font-Poppins-Regular text-[#707070] font-size-15px theme-color-green flex items-center justify-center'>{truncateString(userDetails?.headLine || "", 100)}</span>
            }
          </div>
        </div>

        <div className="profile-progress-box">
          <div className="progress-des">
            <p className='font-size-15px font-Poppins-Medium text-[#707070]'>Your {(mode === doctor && userDetails?.userRole === doctor) ? "doctor" : "health"} profile</p>
            <p className="font-size-15px font-Poppins-SemiBold title-heading" >
              {Number((mode === doctor && userDetails?.userRole === doctor) ? userDetails?.profileCompletion || 0 : userDetails?.profileCompletionPercentage || 0)?.toFixed(0)}%</p>
          </div>
          <div className="progress-bar">
            <LinearProgress
              variant='determinate'
              value={Math.floor(((mode === doctor && userDetails?.userRole === doctor) ? userDetails?.profileCompletion : userDetails?.profileCompletionPercentage) || 0)}
              sx={{
                height: 5, // Change height here
                borderRadius: 5,
                backgroundColor: '#e0e0e0', // Background color
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#00443F', // Foreground color
                  borderRadius: 5,
                },
              }}
            />
          </div>
          <Link to={(mode === doctor && userDetails?.userRole === doctor) ? "/profile-view" : "/personal-details"} className="font-size-15px font-Poppins-SemiBold title-heading mt-3">
            Complete your {(mode === doctor && userDetails?.userRole === doctor) ? "doctor" : "health"} profile
          </Link>
        </div>
      </div>
      <div className="parent-box customer-sidebar-second-box">
        <SideBarNav />
      </div>
    </div>
  );
};

export default CustomerSidebar;