import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomerTableWrapper from 'src/Components/Customer/CustomerTableWrapper';
import PageHeader from 'src/Components/Customer/PageHeader';
import BackIcon from "src/assets/images/back-icon.png";
import { IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import eyeIcon from 'src/assets/images/eye-icon-green.png';
import { barColors } from 'src/lib/constans';
import httpRequest from 'src/helpers/httpRequest';
import qs from "qs";
import { toast } from 'react-toastify';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';

interface ITests {
    _id: string;
    docterName: string;
    testName: string;
    date: string;
    green: string | number;
    orange: string | number;
    red: string | number;
};

interface ICustomerTestsResponse {
    documents: ITests[];
    paginated: IPaginated;
}; // Interface for the API response containing appointments and pagination info

const MyCustomerTestResults: React.FC = () => {
    const navigate = useNavigate();
    const { id = "" } = useParams();

    const [allTests, setAllTests] = useState<ITests[]>([]);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination state

    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        search: "",
    }); // Query parameters for API request

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Handles filter change for search functionality
    const handleFilterChange = (val: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            search: val,
        }));
    };

    // API_CALL: Function to fetch the list of my customers tests
    const fetch = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ICustomerTestsResponse>({
            path: `/accessdoctorside/for-test/${id}?${qs.stringify(queryParams)}`
        });
        if (res) {
            const { documents = [], paginated } = res;
            setAllTests(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetch();
    }, [queryParams, id]); // Fetch appointments when queryParams & customer id change & components mount

    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate("/my-customers")}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    return (
        <>
            <PageHeader
                title="Test Result"
                isButton={true}
                ButtonComponent={BackButton}
            />
            <div className="parent-box">
                <SearchFilterInput
                    containerExtraStyle={{ justifyContent: "flex-start" }}
                    onChangeText={handleFilterChange}
                />
                <CustomerTableWrapper
                    isLoading={isLoader}
                    thead={["S.No", "Test Name", "Doctor Name", "Date", "Results", "Actions"]}
                    page={paginated?.currentPage || 1}
                    total={paginated?.totalPages || 0}
                    columnWidth={"12% 15% 15% 15% 25% 13%"}
                    onChange={handlePageChange}
                >
                    {allTests?.map((item: ITests, index: number) => (
                        <ul key={index} className='font-size-16px tbody-light-table customer-table-wrapper-body-padding-non customer-table-wrapper-body tbody-light-table-customer font-Poppins-Regular grid' style={{ gridTemplateColumns: "12% 15% 15% 15% 25% 13%" }}>
                             <li className="action-last-colum-non">{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                            <li>{item.testName}</li>
                            <li>
                                <p className="truncate pr-3">
                                    {item?.docterName || "-"}
                                </p>
                            </li>
                            <li>{moment(item?.date).format("DD-MM-YYYY")}</li>
                            <li className="result__count__circle-main">
                            <div className='pr-4'>
                                    <span className="result__count__circle" style={{ backgroundColor: barColors.green }}>{item.green}</span>
                                </div>
                                <div style={{ borderLeft: "1px solid #C7C7C7", borderRight: "1px solid #C7C7C7" }} className='px-4'>
                                    <span className="result__count__circle" style={{ backgroundColor: barColors.orange }}>{item.orange}</span>
                                </div>
                                <div className='pl-4'>
                                    <span className="result__count__circle" style={{ backgroundColor: barColors.red }}>{item.red}</span>
                                </div>
                            </li>
                            <li className='action-last-colum justify-center'>
                                <Link to={`/test-result/${item._id}/view`}>
                                    <img src={eyeIcon} alt="" />
                                </Link>
                            </li>
                        </ul>
                    ))}
                </CustomerTableWrapper>
            </div>
        </>
    );
};

export default MyCustomerTestResults;