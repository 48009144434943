/**
 * CustomerHeader Component
 * 
 * Description: This component renders a header for the customer panel.
 * 
 * File Name: CustomerHeader.tsx
 * Date: 06-08-2024
 */

import React, { useState, useEffect } from 'react';
import HeaderNav from './HeaderNav';
import ProfilePopup from './ProfilePopup';
import defaultProfile from "src/assets/images/default_profile_image.png";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '@store/store';

const CustomerHeader: React.FC = () => {
    const navigate = useNavigate();
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
    const handleProfileClick = (): void => {
        setIsProfileModalOpen((prevState) => !prevState);
    };

    useEffect(() => {
        setIsProfileModalOpen(false);
    }, [navigate]);

    return (
        <>
            <header className='header sticky'>
                <div className='container-1480'>
                    <div className='customer-header-inner'>
                        <div className="header-left">
                            <div className="header-logo-container">
                                <Link to="/dashboard" className="header-logo font-Poppins-SemiBold font-size-25px font-Poppins-Regular">
                                    Healthcare
                                </Link>
                            </div>
                            {/* <div className="header-search-container">
                            <SearchFilterInput
                                containerExtraStyle={{ display: "block" }}
                                inputExtraStyle={{ width: "100%", marginBottom: 0 }}
                            />
                            <button className=' ask-ai-btn font-size-20px font-Poppins-Regular'>
                                <img src={magicWoundIcon} alt="magic-wound-icon" />
                                <p className='font-Poppins-SemiBold font-size-20px'>Ask AI</p>
                            </button>
                        </div> */}
                        </div>
                        <div className='header-right'>
                            <HeaderNav />

                            <div className='header-profile-container' onClick={handleProfileClick}>
                                <img
                                    alt='profile-image'
                                    width={30}
                                    height={30}
                                    className='rounded-full header-profile-container-avater'
                                    src={`${process.env.REACT_APP_API_BASEURL}${userDetails?.coverPhoto}?t=${Date.now()}`}
                                    onError={(e) => {
                                        const target = e.target as HTMLImageElement; // Assert the type
                                        if (target) {
                                            target.src = defaultProfile;
                                        }
                                    }}
                                />
                                <div className='avatar-label relative'>
                                    <p className='font-Poppins-Medium text-white font-size-13px capitalize'>{userDetails?.firstName || ""}</p><span className='text-white'>&#11206;</span>
                                    {isProfileModalOpen && <ProfilePopup />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default CustomerHeader;