import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import SimpleInput from 'src/Components/SimpleInput';
import httpRequest from 'src/helpers/httpRequest';
import { IDropDownArray, IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import moment from 'moment';
import { statusColor } from 'src/lib/constans';

interface IItem {
    title: string;
    isOnlineAppoinment: boolean;
}; // Interface for dropdown items (status and appointment type)

interface iFilterItems {
    status: string;
    search: string;
    startDate: string;
    endDate: string;
};

interface IAppointmentItems {
    _id: string;
    customerFirstName: string;
    customerLastName: string;
    doctorFirstName: string;
    doctorLastName: string;
    startTime: string;
    endTime: string;
    appoinmentDate: string;
    status: string;
    totalAmount: number;
};

interface IAppointmentResponse {
    documents: IAppointmentItems[];
    paginated: IPaginated;
}; // Interface for the API response containing appointments and pagination info

const AllApointments: React.FC = () => {
    const statusList: IDropDownArray[] = [
        { label: "Pending", value: "pending" },
        { label: "Complete", value: "completed" },
        { label: "Cancelled", value: "cancelled" },
    ]; // Status filter options

    const tabList: IItem[] = [
        { title: "Online Appointment", isOnlineAppoinment: true },
        { title: "Physical Appointment", isOnlineAppoinment: false },
    ]; // Appointment type tabs (Online/Physical)

    // States for loading status, pagination, and customer data
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination

    const [allAppointments, setAllAppointments] = useState<IAppointmentItems[]>([]);
    const [inputKey, setInputKey] = useState(0); // Key to force re-render

    const [filterItems, setFilterItems] = useState<iFilterItems>({
        status: "",
        search: "",
        startDate: "",
        endDate: "",
    });

    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        isOnlineAppoinment: true, // Filter by appointment type (online or physical)
        status: "",
        search: "",
        startDate: "",
        endDate: "",
    });

    const resetFilter = () => {
        setInputKey(prev => prev + 1);
        setFilterItems({
            status: "",
            search: "",
            startDate: "",
            endDate: "",
        });

        setQueryParams(prev => ({
            ...prev,
            page: 1,
            status: "",
            search: "",
            startDate: "",
            endDate: "",
        }));
    };

    const applyFilter = () => {
        const { status, startDate, endDate, search } = filterItems;
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            status,
            search,
            startDate,
            endDate,
        }));
    };

    // Handles tab click to filter orders
    const handleTabClick = (isOnlineAppoinment: boolean) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            isOnlineAppoinment,
        }));
    };

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // API_CALL: Function to fetch the list of all appointments from the API
    const fetchAllAppointments = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IAppointmentResponse>({
            method: "post",
            path: "/appoiment",
            params: queryParams
        });
        if (res) {
            const { documents = [], paginated } = res;
            setAllAppointments(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchAllAppointments();
    }, [queryParams]); // Fetch appointments when queryParams change

    return (
        <>
            <BreadCrumb
                current="Appointment"
                previous="dashboard"
                navigateUrl="/admin"
                btnShow={false}
            />
            <div className="table-container">
                <div className='products-tabs-btn md:col-span-2 col-span-1'>
                    {tabList.map((v: IItem, index: number) => (
                        <button
                            key={index}
                            className={`font-size-15px font-Poppins-Medium ${queryParams.isOnlineAppoinment === v.isOnlineAppoinment && 'active'}`}
                            onClick={() => handleTabClick(v.isOnlineAppoinment)}
                        >{v.title}</button>
                    ))}
                </div>
                <hr className='my-4' style={{ border: '1px solid #D4D4D4' }} />
                <div className="grid grid-cols-1 select-filter-height md:grid-cols-3 lg:grid-cols-5 gap-3 items-center">
                    <div>
                        <SelectDropdown
                            options={statusList}
                            setData={(arr) => setFilterItems(prev => ({ ...prev, status: arr[0] ? arr[0].value : "" }))}
                            data={statusList.filter(v => v.value === filterItems.status)}
                            extraClass={"border__dropdown__primary appointments-dropdown m-0 "}
                            placeholder={{ "selectSomeItems": "Filter by Status" }}
                        />
                    </div>
                    <div>
                        <SimpleInput
                            value={filterItems.startDate}
                            onChange={(val) => setFilterItems(prev => ({ ...prev, startDate: val }))}
                            type='date'
                            placeholder='Start Date'
                            extraStyle={{ borderColor: "#00a297", margin: 0,height:35 }}
                        />
                    </div>
                    <div>
                        <SimpleInput
                            value={filterItems.endDate}
                            onChange={(val) => setFilterItems(prev => ({ ...prev, endDate: val }))}
                            type='date'
                            placeholder='End Date'
                            extraStyle={{ borderColor: "#00a297", margin: 0,height:35 }}
                        />
                    </div>
                    <div>
                        <SearchFilterInput
                            key={inputKey} // Apply the key here
                            containerExtraStyle={{ display: "block" }}
                            inputExtraStyle={{ width: "100%", marginBottom: 0, borderColor: "#00a297", height:35 }}
                            onChangeText={(txt: string) => setFilterItems(prev => ({ ...prev, search: txt }))}
                        />
                    </div>
                    <div className="flex items-center gap-3 justify-end">
                        <PrimaryBtn
                            disabled={!filterItems.status && !filterItems.startDate && !filterItems.endDate && !filterItems.search}
                            ButtonText='Reset'
                            onPress={resetFilter}
                            btnExtraStyle={{
                                borderRadius: "1.5rem",
                                backgroundColor: "#004540",
                                padding: "6px 30px"
                            }}
                        />
                        <PrimaryBtn
                            ButtonText='Apply'
                            onPress={applyFilter}
                            btnExtraStyle={{
                                borderRadius: "1.5rem",
                                backgroundColor: "#004540",
                                padding: "6px 30px"
                            }}
                        />
                    </div>
                </div>
                <hr className='my-4' style={{ border: '1px solid #D4D4D4' }} />
                <TableWrapper
                    isLoading={isLoader}
                    page={paginated?.currentPage || 1}
                    total={paginated?.totalPages || 0}
                    thead={["S.No", "Doctor Name", "Customer Name", "Fee", "Time", "Status", "Date", "Action"]}
                    onChange={handlePageChange}
                    columnWidth={"6% 19% 15% 12% 14% 12% 12% 10%"}
                >
                    <div className='tbody-content-height-appointments-table'>
                        {allAppointments?.map((item: IAppointmentItems, index: number) => (
                            <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 19% 15% 12% 14% 12% 12% 10%" }}>
                                <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                <li>{`${item.doctorFirstName} ${item.doctorLastName}`}</li>
                                <li>{`${item.customerFirstName} ${item.customerLastName}`}</li>
                                <li>AED {item.totalAmount}</li>
                                <li>{`${moment(item.startTime, "HH:mm").format("h:mm A")} to ${moment(item.endTime, "HH:mm").format("h:mm A")}`}</li>
                                <li style={{paddingLeft:0,}} className='justify-center'>
                                    <span
                                        className='py-1 px-4 text-white capitalize rounded'
                                        style={{
                                            backgroundColor:
                                                item.status === "pending" ? statusColor.yellow
                                                    : item.status === "completed" ? statusColor.green
                                                        : item.status === "cancelled" ? statusColor.red
                                                            : ""
                                        }}
                                    >{item.status}</span>
                                </li>
                                <li>{moment(item.appoinmentDate).format("DD-MM-YYYY")}</li>
                                <li className="action-last-colum justify-center">
                                    <Link to={`/single-appointment/${item._id}/view`}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Link>
                                </li>
                            </ul>
                        ))}
                    </div>
                </TableWrapper>
            </div>
        </>
    );
};

export default AllApointments;