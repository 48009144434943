import React, { useState, useEffect, FormEvent } from 'react';
import PageHeader from 'src/Components/Customer/PageHeader';
import PreviewProfileButton from 'src/Components/Customer/PreviewProfileButton';
import SimpleInput from 'src/Components/SimpleInput';
import ToggleRadioBtn from 'src/Components/ToggleRadioBtn';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';

import {
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IAvailability } from 'src/types/GlobalInterfaces';
import { weekDays } from 'src/lib/constans';
import { capitalize } from '@mui/material';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { updateDoctorField } from '../../../Store/doctor';
import { setUniqueData } from "src/Store/reducer";

const OnlineAvailabilityEdit: React.FC = () => {
    const { profile } = useSelector((store: RootState) => store.doctor);
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [availability, setAvailability] = useState<IAvailability>({
        fees: "",
    });


    const handleToggle = (day: keyof IAvailability) => {
        const isExist = Object.keys(availability).includes(day);
        if (isExist) {
            setAvailability(prev => {
                const updatedAvailability = { ...prev } as Record<string, any>; // Assert type
                delete updatedAvailability[day];
                return updatedAvailability;
            }); // Remove the day from the availability object
        } else {
            setAvailability(prev => ({
                ...prev,
                [day]: [["", ""]],
            }));
        }
    };

    const handleChange = (day: keyof IAvailability, index: number, type: number, value: string) => {
        setAvailability((prev) => {
            // Ensure `prev[day]` is an array of tuples or default to an empty array
            // const currentSchedule: [string, string][] = Array.isArray(prev[day]) ? prev[day] : [];
            const currentSchedule = Array.isArray(prev[day])
                ? prev[day] as [string, string][]
                : [];

            // Clone the current schedule to avoid direct mutation
            const updatedSlots = currentSchedule.map((slot) => [...slot]);

            // Update the slot with the new value
            updatedSlots[index][type] = value;

            // Function to convert time string to number of minutes
            const timeToMinutes = (time: string) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };

            // Check if 'To' time is greater than 'From' time
            const fromTime = updatedSlots[index][0];
            const toTime = updatedSlots[index][1];

            if (fromTime && toTime && timeToMinutes(fromTime) >= timeToMinutes(toTime)) {
                toast(`${day}: End time must be greater than Start time.`, {
                    type: "error",
                });

                // Revert the change if validation fails
                const prevState = currentSchedule.map((slot) => [...slot]);
                prevState[index][type] = ""; // Clear the changed value
                return {
                    ...prev,
                    [day]: prevState,
                };
            }

            return {
                ...prev,
                [day]: updatedSlots,
            };
        });
    };

    const addRow = (day: keyof IAvailability) => {
        setAvailability(prev => {
            // Ensure `prev[day]` is an array or default to an empty array
            const currentSchedule = Array.isArray(prev[day])
                ? prev[day] as [string, string][]
                : [];
            return {
                ...prev,
                [day]: [...currentSchedule, ["", ""]],
            };
        });
    };

    const deleteRow = (day: keyof IAvailability, index: number) => {
        setAvailability(prev => {
            // Ensure `prev[day]` is an array or default to an empty array
            const currentSchedule = Array.isArray(prev[day])
                ? prev[day] as [string, string][]
                : [];

            // Filter out the item at the specified index
            const updatedSchedule = currentSchedule.filter((_, i) => i !== index);

            return {
                ...prev,
                [day]: updatedSchedule,
            };
        });
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; availability: IAvailability; percent: number }>({
            method: "post", path: "/provider/update-consultation", params: availability
        });
        if (res) {
            const { message, availability, percent } = res;
            toast(message, { type: "success" }); // api success message
            dispatch(updateDoctorField({ availability }));
            dispatch(setUniqueData({ key: 'profileCompletion', value: String(percent) }));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (profile) setAvailability(profile.availability || {});
    }, [profile]);

    return (
        <>
            <PageHeader
                title="Availability"
                isButton={true}
                ButtonComponent={PreviewProfileButton}
            />

            <div className="parent-box">
                <div className="border-b border-[#707070] pt-2 pb-4">
                    <p className="font-Poppins-SemiBold font-size-20px theme-color-green">Online availability days and times</p>
                    <p className="font-Poppins-Regular font-size-14px theme-color-gren">Keep this up to date so you get enquiries that suit you.</p>
                </div>
                <form onSubmit={handleSubmit} className="py-4">
                    <div>
                        <SimpleInput
                            type='text'
                            value={availability?.fees || ""}
                            onChange={(val) => {
                                if (/^\d+$/.test(val) || val === "") {  // Allow only positive whole numbers
                                    setAvailability(prev => ({ ...prev, fees: val }))
                                }
                            }}
                            isLabel={true}
                            labelText={"Charges"}
                            isRequired={true}
                            extraStyle={{ borderColor: "#009d91" }}
                        />
                    </div>
                    {weekDays.map(day => (
                        <div key={day} className="availability mt-4">
                            <div className='avail pb-4'>
                                <div className='flex gap-3'>
                                    <ToggleRadioBtn
                                        toggleText={capitalize(day)}
                                        isToggled={Object.keys(availability).includes(day)}
                                        handleToggle={() => handleToggle(day as keyof IAvailability)}
                                    />
                                </div>
                                {Object.keys(availability).includes(day) &&
                                    <>
                                        {Array.isArray(availability[day as keyof IAvailability])
                                            ? (availability[day as keyof IAvailability] as [string, string][]).map((item, index) => (
                                                <div className="flex w-full gap-4 mt-4 items-center" key={index}>
                                                    <div className='w-[30%]'>
                                                        <SimpleInput
                                                            value={item[0]}
                                                            onChange={(val) =>
                                                                handleChange(day as keyof IAvailability, index, 0, val)
                                                            }
                                                            isLabel={true}
                                                            labelText={"Start Time"}
                                                            extraStyle={{ borderColor: "#009d91" }}
                                                            type='time'
                                                            isRequired={true}
                                                        />
                                                    </div>

                                                    <div className='w-[30%]'>
                                                        <SimpleInput
                                                            value={item[1]}
                                                            onChange={(val) =>
                                                                handleChange(day as keyof IAvailability, index, 1, val)
                                                            }
                                                            isLabel={true}
                                                            labelText={"End Time"}
                                                            extraStyle={{ borderColor: "#009d91" }}
                                                            type='time'
                                                            isRequired={true}
                                                        />
                                                    </div>
                                                    {index !== 0 &&
                                                        <button
                                                            onClick={() => deleteRow(day as keyof IAvailability, index)}
                                                            type="button"
                                                            className="ml-4 mt-[35px]"
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    }
                                                </div>
                                            ))
                                            : null
                                        }
                                        <div className="mt-4">
                                            <button
                                                onClick={() => addRow(day as keyof IAvailability)}
                                                type="button"
                                                className="font-Poppins-semiBold font-size-18px theme-color-green">
                                                + Add another time
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    ))}
                    <div className='my-5'>
                        <PrimaryBtn
                            type='submit'
                            loader={isLoader}
                            ButtonText='Save'
                            btnExtraStyle={{
                                backgroundColor: "#004540",
                                borderRadius: "50rem",
                                marginLeft: "auto"
                            }}
                        />
                    </div>
                </form>
            </div>
        </>
    );
};

export default OnlineAvailabilityEdit;