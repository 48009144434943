/**
 * HealthScores Component
 * 
 * Description: A React component that displays health scores for various systems in a user’s health profile. 
 * The component fetches data based on a user ID and visualizes the results using circular progress indicators.
 * 
 * File Name: HealthScores.tsx
 * Date: 2024-08-26
 */

import React, { useEffect, useState } from 'react';
import ProgressIndicator from 'src/Pages/Customer/Progress/ProgressIndicator';
import iconMain from "src/assets/images/customer-dashboard/customer-dashboard-icon-main.png";
import iconImmunity from "src/assets/images/customer-dashboard/customer-dashboard-icon-immunity.png";
import iconBlood from "src/assets/images/customer-dashboard/customer-dashboard-icon-blood.png";
import iconCardiovascular from "src/assets/images/customer-dashboard/customer-dashboard-icon-cardiovascular.png";
import iconDigestive from "src/assets/images/customer-dashboard/customer-dashboard-icon-digestive.png";
import iconHormones from "src/assets/images/customer-dashboard/customer-dashboard-icon-hormones.png";
import iconLiver from "src/assets/images/customer-dashboard/customer-dashboard-icon-liver.png";
import iconNerves from "src/assets/images/customer-dashboard/customer-dashboard-icon-nerves.png";
import iconRespiratory from "src/assets/images/customer-dashboard/customer-dashboard-icon-respiratory.png";
import iconUrogenital from "src/assets/images/customer-dashboard/customer-dashboard-icon-urogenital.png";
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { barColors } from 'src/lib/constans';
import SplashScreen from 'src/Components/SplashScreen';
import { IHealthScores } from 'src/types/GlobalInterfaces';
import { useNavigate } from 'react-router-dom';
import HealthScoreIcon from "src/assets/images/healthscorenewicon.png";

const HealthScores: React.FC<{ id: string; isAdmin?: boolean; onPress?: () => void }> = ({ id, isAdmin = false, onPress = () => { } }) => {
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage loading status
    const [data, setData] = useState<IHealthScores | null>(null); // State to hold the fetched health scores data

    const checkClickOrNot = (key: keyof IHealthScores): boolean => {
        if (data && data[key]) {
            return false
        } else {
            return true
        }
    };

    const navigateToBioMarker = (): void => {
        if (isAdmin) {
            onPress();
        } else {
            navigate("/health-biomarkers");
        }
    };

    //API_CALL: Fetch health profile data when the component mounts
    useEffect(() => {
        if (id) {
            (async () => {
                setIsLoader(true);
                const { res, err } = await httpRequest<{ message: string; data: IHealthScores }>({ path: `/healthprofile/main/${id}` });
                if (res) {
                    setData(res?.data || {});
                } else {
                    toast(err, { type: 'error' });
                }
                setIsLoader(false);
            })();
        }
    }, [id]);
    return (
        <>
            {isLoader && <SplashScreen />}
            <div className="parent-box parent-box-bottom-margin">
                <div className="">
                    <div className="grid gap-2 md:grid-cols-5 grid-cols-2">
                        <div className="md:col-span-3 md:row-span-2 col-span-2">
                            <div className="dashboard-lg-box dashboard-lg-box-nnew flex h-full items-center justify-center">
                                <div className="dashboard-inner-box flex justify-between items-center gap-4">
                                    <div className="dashboard-inner-box-text">
                                        <img className='health-score-boxes-img-first' src={HealthScoreIcon} alt="main-icon" />
                                        <h2 className="font-Poppins-SemiBold text-white font-size-30px">Health score</h2>
                                        <p className="font-Poppins-Regular text-white font-size-16px">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                                    </div>
                                    <div className="dashboard-inner-box-progress">
                                        {
                                            data &&
                                            <ProgressIndicator
                                                size={170}
                                                type="circular"
                                                value={Math.floor(data["profileCompletionPercentage"] || 0)}
                                                color={
                                                    (data["profileCompletionPercentage"] || 0) > 80 ? barColors.green :
                                                        (data["profileCompletionPercentage"] || 0) >= 50 ? barColors.orange :
                                                            barColors.red
                                                }
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Small Boxes --> */}
                        <div className="col-span-1">
                            <div className="dashboard-sm-box">
                                <button onClick={navigateToBioMarker} disabled={checkClickOrNot("Urogenital System")} className={`dashboard-inner-box`}>
                                    {(data && !data["Urogenital System"]) && (
                                        <div className="black-overlay"></div>
                                    )}
                                    <img className='health-score-boxes-img' src={iconUrogenital} alt="immunity-icon" />
                                    <p className="font-Poppins-Medium font-size-18px">
                                        Urogenital
                                    </p>
                                    {
                                        data &&
                                        <ProgressIndicator
                                            size={75}
                                            type="circular"
                                            value={Math.floor(data["Urogenital System"] || 0)}
                                            color={
                                                (data["Urogenital System"] || 0) > 80 ? barColors.green :
                                                    (data["Urogenital System"] || 0) >= 50 ? barColors.orange :
                                                        barColors.red
                                            }
                                        />
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="dashboard-sm-box">
                                <button onClick={navigateToBioMarker} disabled={checkClickOrNot("Respiratory System")} className={`dashboard-inner-box`}>
                                    {(data && !data["Respiratory System"]) && (
                                        <div className="black-overlay"></div>
                                    )}
                                    <img className='health-score-boxes-img' src={iconRespiratory} alt="immunity-icon" />
                                    <p className="font-Poppins-Medium font-size-18px">
                                        Respiratory
                                    </p>
                                    {
                                        data &&
                                        <ProgressIndicator
                                            size={75}
                                            type="circular"
                                            value={Math.floor(data["Respiratory System"] || 0)}
                                            color={
                                                (data["Respiratory System"] || 0) > 80 ? barColors.green :
                                                    (data["Respiratory System"] || 0) >= 50 ? barColors.orange :
                                                        barColors.red
                                            }
                                        />
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="dashboard-sm-box">
                                <button onClick={navigateToBioMarker} disabled={checkClickOrNot("Nervous System")} className={`dashboard-inner-box`}>
                                    {(data && !data["Nervous System"]) && (
                                        <div className="black-overlay"></div>
                                    )}
                                    <img className='health-score-boxes-img' src={iconNerves} alt="immunity-icon" />
                                    <p className="font-Poppins-Medium font-size-18px">
                                        Nerves
                                    </p>
                                    {
                                        data &&
                                        <ProgressIndicator
                                            size={75}
                                            type="circular"
                                            value={Math.floor(data["Nervous System"] || 0)}
                                            color={
                                                (data["Nervous System"] || 0) > 80 ? barColors.green :
                                                    (data["Nervous System"] || 0) >= 50 ? barColors.orange :
                                                        barColors.red
                                            }
                                        />
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="dashboard-sm-box">
                                <button onClick={navigateToBioMarker} disabled={checkClickOrNot("Liver System")} className={`dashboard-inner-box`}>
                                    {(data && !data["Liver System"]) && (
                                        <div className="black-overlay"></div>
                                    )}
                                    <img className='health-score-boxes-img' src={iconLiver} alt="immunity-icon" />
                                    <p className="font-Poppins-Medium font-size-18px">
                                        Liver
                                    </p>
                                    {
                                        data &&
                                        <ProgressIndicator
                                            size={75}
                                            type="circular"
                                            value={Math.floor(data["Liver System"] || 0)}
                                            color={
                                                (data["Liver System"] || 0) > 80 ? barColors.green :
                                                    (data["Liver System"] || 0) >= 50 ? barColors.orange :
                                                        barColors.red
                                            }
                                        />
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="dashboard-sm-box">
                                <button onClick={navigateToBioMarker} disabled={checkClickOrNot("Immune System")} className={`dashboard-inner-box`}>
                                    {(data && !data["Immune System"]) && (
                                        <div className="black-overlay"></div>
                                    )}
                                    <img className='health-score-boxes-img' src={iconImmunity} alt="immunity-icon" />
                                    <p className="font-Poppins-Medium font-size-18px">
                                        Immunity
                                    </p>
                                    {
                                        data &&
                                        <ProgressIndicator
                                            size={75}
                                            type="circular"
                                            value={Math.floor(data["Immune System"] || 0)}
                                            color={
                                                (data["Immune System"] || 0) > 80 ? barColors.green :
                                                    (data["Immune System"] || 0) >= 50 ? barColors.orange :
                                                        barColors.red
                                            }
                                        />
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="dashboard-sm-box">
                                <button onClick={navigateToBioMarker} disabled={checkClickOrNot("Digestive System")} className={`dashboard-inner-box`}>
                                    {(data && !data["Digestive System"]) && (
                                        <div className="black-overlay"></div>
                                    )}
                                    <img className='health-score-boxes-img' src={iconDigestive} alt="immunity-icon" />
                                    <p className="font-Poppins-Medium font-size-18px">
                                        Digestive
                                    </p>
                                    {
                                        data &&
                                        <ProgressIndicator
                                            size={75}
                                            type="circular"
                                            value={Math.floor(data["Digestive System"] || 0)}
                                            color={
                                                (data["Digestive System"] || 0) > 80 ? barColors.green :
                                                    (data["Digestive System"] || 0) >= 50 ? barColors.orange :
                                                        barColors.red
                                            }
                                        />
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="dashboard-sm-box">
                                <button onClick={navigateToBioMarker} disabled={checkClickOrNot("Hormones System")} className={`dashboard-inner-box`}>
                                    {(data && !data["Hormones System"]) && (
                                        <div className="black-overlay"></div>
                                    )}
                                    <img className='health-score-boxes-img' src={iconHormones} alt="immunity-icon" />
                                    <p className="font-Poppins-Medium font-size-18px">
                                        Hormones
                                    </p>
                                    {
                                        data &&
                                        <ProgressIndicator
                                            size={75}
                                            type="circular"
                                            value={Math.floor(data["Hormones System"] || 0)}
                                            color={
                                                (data["Hormones System"] || 0) > 80 ? barColors.green :
                                                    (data["Hormones System"] || 0) >= 50 ? barColors.orange :
                                                        barColors.red
                                            }
                                        />
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="dashboard-sm-box">
                                <button onClick={navigateToBioMarker} disabled={checkClickOrNot("Cardiovascular System")} className={`dashboard-inner-box`}>
                                    {(data && !data["Cardiovascular System"]) && (
                                        <div className="black-overlay"></div>
                                    )}
                                    <img className='health-score-boxes-img' src={iconCardiovascular} alt="immunity-icon" />
                                    <p className="font-Poppins-Medium font-size-18px">
                                        Cardiovascular
                                    </p>
                                    {
                                        data &&
                                        <ProgressIndicator
                                            size={75}
                                            type="circular"
                                            value={Math.floor(data["Cardiovascular System"] || 0)}
                                            color={
                                                (data["Cardiovascular System"] || 0) > 80 ? barColors.green :
                                                    (data["Cardiovascular System"] || 0) >= 50 ? barColors.orange :
                                                        barColors.red
                                            }
                                        />
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="dashboard-sm-box">
                                <button onClick={navigateToBioMarker} disabled={checkClickOrNot("Blood System")} className={`dashboard-inner-box`}>
                                    {(data && !data["Blood System"]) && (
                                        <div className="black-overlay"></div>
                                    )}
                                    <img className='health-score-boxes-img blood-health-score-boxes-img' src={iconBlood} alt="immunity-icon" />
                                    <p className="font-Poppins-Medium font-size-18px">
                                        Blood
                                    </p>
                                    {
                                        data &&
                                        <ProgressIndicator
                                            size={75}
                                            type="circular"
                                            value={Math.floor(data["Blood System"] || 0)}
                                            color={
                                                (data["Blood System"] || 0) > 80 ? barColors.green :
                                                    (data["Blood System"] || 0) >= 50 ? barColors.orange :
                                                        barColors.red
                                            }
                                        />
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default HealthScores;