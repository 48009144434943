/**
 * BiomarkerDetails Component
 * 
 * Description: The main purpose of this component is to display the details of a specific biomarker result within a health profile. It utilizes routing parameters to fetch and display the biomarker result history. It also includes a back button to navigate to the previous page.
 * 
 * File Name: BiomarkerDetails.tsx
 * Date: 26-08-2024
 */

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'src/Components/Customer/PageHeader';
import SingleBiomarkerResultHistory from 'src/Components/GlobalComponents/HealthProfiles/SingleBiomarkerResultHistory';
import BackIcon from "src/assets/images/back-icon.png";

const BiomarkerDetails: React.FC = () => {
    const navigate = useNavigate();
    const { bioId = "", uid = "" } = useParams();

    // Button component to navigate back to the previous page
    const BackButton = () => (
        <button className='right-bar-icon' onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back-icon" />
        </button>
    );
    return (
        <>
            <PageHeader
                title='Health Profile'
                isButton={true}
                ButtonComponent={BackButton}
            />
            {
                (uid && bioId) &&
                <SingleBiomarkerResultHistory
                    bioId={bioId}
                    uId={uid}
                />
            }
        </>
    )
};

export default BiomarkerDetails;