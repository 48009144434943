export const ageGroup: { title: string; age: string }[] = [
    { title: "child", age: "0-18" },
    { title: "adult", age: "19-49" },
    { title: "elder", age: "50+" },
];

export const statusColor = {
    yellow: "#dcc44b",
    green: "#0a9830",
    red: "#f00000",
};

export const doctorMode = "doctorMode";
export const CURRENCY = "usd";
export const utcOffset = 4; // for utc

export const CART_COOKIE_KEY = 'cart_items';

export const twoFactorKey = "twoFactor";
export const customer = 'customer'
export const practitioner = 'practitioner'
export const superAdmin = 'superAdmin'
export const operator = "operator";
export const admin = 'admin'
export const editor = 'editor'
export const doctor = 'doctor'
export const active = 'active'
export const inactive = 'inactive'
export const pending = 'pending'
export const declined = 'declined'
export const currencySymbol = 'A$'
export const clientToken = "TOKEN"
export const adminToken = "ADMIN_TOKEN"
export const countryCode = "+61"
export const dateTime = 'YYYY-MM-DD hh:ss A'

// errors
export const unexpectedError = 'Unexpected server error.'
export const validationError = 'Form validation error.'

// validation messages
export const errMsg = {
    required: 'Value is required',
    onlyLetters: 'Value must contain only letters',
    noSpace: 'Value must contain only letters and no spacing.',
    notMatched: 'Value did not match.',
    invalidEmail: 'Must be a valid email address.',
    length3to16: 'Value must be between 3 and 16 characters',
    onlyNumbers: 'Value must contain only numbers',
    ph: 'Start with 61, include numbers, length 11.',
    invalidPassword: 'Password pattern: 1 lowercase, 1 uppercase, 1 digit, 1 special.'
};

export const findAge = (age: string): number => {
    return 1
};

export const barColors = {
    red: "red",
    orange: "#f6ac00",
    yellow: "#ffff17",
    green: "#4BB206"
};
export const barColorsLight = {
    red: "#fcd8d8",
    orange: "#fceac6",
    yellow: "#ffffd2",
    green: "#d1e5d0"
};

export const fiveBarMarker = 'fiveBarMarker'; // red, orange, green, orange, red, // opt min & opt max, will ly b/w ref min & ref max. any value should not be the same.
export const fourBarMarkerAsc = 'fourBarMarkerAsc'; // red, orange, yellow, green // opt max will be the same as ref max, opt min will ly b/w ref min & ref max
export const fourBarMarkerDesc = 'fourBarMarkerDesc'; //green, yellow, orange, red // opt min & ref min will be the same & opt max will ly b/w ref min & ref max
export const threeBarMarkerCentralized = 'threeBarMarkerCentralized'; // red, green, red // opt min & ref min will be the same & opt max & ref max will be the same
export const threeBarMarkerAsc = 'threeBarMarkerAsc'; // red, orange, green // opt min & ref max will be the same & ref min is less then ref max & opt max is greater than opt min
export const threeBarMarkerDesc = 'threeBarMarkerDesc'; // green, orange, red // opt max & ref min will be the same & opt min will be less then opt max & ref max will always be greater then ref min.
export const twoBarMarkerAsc = 'twoBarMarkerAsc'; // red, green //  opt min & ref min will be the same & opt max & ref max will be the same
export const twoBarMarkerDesc = 'twoBarMarkerDesc'; // green, red //  opt min & ref min will be the same & opt max & ref max will be the same
export const twoBarDNDAsc = 'twoBarDNDAsc'; // red, green // detected & not detected
export const twoBarDNDDesc = 'twoBarDNDDesc'; // green, red // detected & not detected
export const twoBarPNAsc = 'twoBarPNAsc';// red, green // positive & negative
export const twoBarPNDesc = 'twoBarPNDesc'; // green, red // positive & negative
export const descriptiveResult = 'descriptiveResult'; // only input field

export const positive = 'Positive';
export const negative = 'Negative';
export const detected = 'Detected';
export const notDetected = 'Not Detected';

export const allMonths = [
    { month: 1, name: 'Jan' },
    { month: 2, name: 'Feb' },
    { month: 3, name: 'Mar' },
    { month: 4, name: 'Apr' },
    { month: 5, name: 'May' },
    { month: 6, name: 'Jun' },
    { month: 7, name: 'Jul' },
    { month: 8, name: 'Aug' },
    { month: 9, name: 'Sep' },
    { month: 10, name: 'Oct' },
    { month: 11, name: 'Nov' },
    { month: 12, name: 'Dec' },
];

// Generate a list of years from startYear to endYear
const startYear = new Date().getFullYear() - 10; // Adjust as needed
const endYear = new Date().getFullYear();
export const allYears = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

export const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];