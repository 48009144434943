/**
 * ChangeDoctorPassword Component
 * 
 * Description: This component allows the user to change their password by 
 * validating the input and sending a request to reset the password for a 
 * specified user ID.
 * 
 * File Name: ChangeDoctorPassword.tsx
 * Date: 04-10-2024
 * 
 * Marker: DOC_START
 */

import React, { FormEvent, useState } from 'react';
import SimpleInput from '../SimpleInput';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { validatePassword } from 'src/helpers/helperFunctions';
import { toast } from 'react-toastify';
import { errMsg } from 'src/lib/constans';
import httpRequest from 'src/helpers/httpRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ChangeDoctorPassword: React.FC<{ uid: string }> = ({ uid }) => {
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    // API_CALL: Admin-side functionality to change the doctor's password
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (!validatePassword(password)) {
            toast(errMsg?.invalidPassword, { type: 'error' });
        } else if (password !== confirmPassword) {
            toast("Password & confirm password are not matched", { type: 'error' });
        } else {
            setIsLoader(true);
            const { res, err } = await httpRequest<{ message: string }>({
                method: "post",
                path: `/customer/reset-password-user`,
                params: { password, userId: uid }
            });
            if (res) {
                toast(res?.message || "Success", { type: 'success' });
                setPassword("");
                setConfirmPassword("");
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="parent-box mb-5">
                <h2 className="title-heading font-Poppins-SemiBold font-size-20px mb-3">Change Password</h2>
                <div className='relative'>
                    <SimpleInput
                        type={passwordVisible ? "text" : "password"}
                        value={password}
                        isRequired={true}
                        labelText="Password"
                        onChange={(val) => setPassword(val)}
                    />
                    <FontAwesomeIcon
                        icon={passwordVisible ? faEyeSlash : faEye}
                        className="input-field-eye-operator absolute right-4 top-[43%] transform -translate-y-1/2 cursor-pointer"
                        onClick={() => setPasswordVisible(prev => !prev)}
                    />
                </div>
                <div className='relative'>
                    <SimpleInput
                        type={confirmPasswordVisible ? "text" : "password"}
                        value={confirmPassword}
                        isRequired={true}
                        labelText="Confirm Password"
                        onChange={(val) => setConfirmPassword(val)}
                    />
                    <FontAwesomeIcon
                        icon={confirmPasswordVisible ? faEyeSlash : faEye}
                        className="input-field-eye-operator absolute right-4 top-[43%] transform -translate-y-1/2 cursor-pointer"
                        onClick={() => setConfirmPasswordVisible(prev => !prev)}
                    />
                </div>
            </div>
            <div className="flex justify-end mb-4">
                <PrimaryBtn
                    loader={isLoader}
                    type="submit"
                    ButtonText="Save"
                    btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem", padding: "0.5rem 2.5rem" }}
                />
            </div>
        </form>
    );
};

export default ChangeDoctorPassword;