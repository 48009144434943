/**
 * MyWithDrawalDetails
 * 
 * Description: This component displays the user's withdrawal details, including the current balance, total withdrawal amounts, 
 * and a list of withdrawal requests. It allows users to create new withdrawal requests and view their withdrawal history.
 * 
 * File Name: MyWithDrawalDetails.tsx
 * Date: 30-09-2024
 */

import React, { FormEvent, useEffect, useState } from 'react';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { IAccountInfo, IqueryParams } from 'src/types/GlobalInterfaces';
import CustomerTableWrapper from '../Customer/CustomerTableWrapper';
import { IPaginated } from 'src/types/paginated';
import { statusColor } from 'src/lib/constans';
import PaymentInfo from './PaymentInfo';
import SimpleInput from '../SimpleInput';
import folderArrow from "src/assets/images/folder-arrow.png";
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import qs from "qs";

interface IWallet {
    totalAmount: number;
    currentBalance: number;
    totalWithdrawAmount: number;
    pendingWithdraw: number;
};

interface IItem {
    _id: string;
    amount: number;
    status: "completed" | "pending" | "declined";
    attachment?: string;
    reason?: string;
};

interface IWithDrawalResponse {
    documents: IItem[];
    paginated: IPaginated;
};

const MyWithDrawalDetails: React.FC<{ accountInfo: IAccountInfo | null }> = ({ accountInfo = null }) => {
    const [isRequestMode, setIsRequestMode] = useState<boolean>(false);

    // ****************** create new request functions & state ****************** //
    const [createLoader, setCreateLoader] = useState<boolean>(false);
    const [amount, setAmount] = useState<string>("");
    // API_CALL: create new withdraw request
    const createNewRequest = async (event: FormEvent) => {
        event.preventDefault();
        setCreateLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: "/paymentandwithdraw/create",
            params: { amount }
        });
        if (res) {
            toast(res.message || "Success", { type: 'success' });
            setIsRequestMode(false);
            setAmount("");
        } else {
            toast(err, { type: 'error' });
        }
        setCreateLoader(false);
    };
    // ****************** create new request functions & state ****************** //

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [data, setData] = useState<IItem[]>([]);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
    });

    const checkBankInfoExist = (): boolean => {
        if (!accountInfo?.accountHolderName || !accountInfo?.accountNumber || !accountInfo?.bankName) {
            return false;
        } else {
            return true;
        }
    }

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // API_CALL: fetch request list
    const fetchWithDrawalList = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IWithDrawalResponse>({ path: `/paymentandwithdraw/all/for-doctors?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setData(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    const [walletData, setWalletData] = useState<IWallet | null>(null);
    // API_CALL: fetch wallet data
    const fetchWallet = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IWallet>({ path: `/paymentandwithdraw/wallet` });
        if (res) {
            setWalletData(res);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (!isRequestMode) {
            fetchWallet();
        }
    }, [isRequestMode]);

    useEffect(() => {
        if (!isRequestMode) {
            fetchWithDrawalList();
        }
    }, [queryParams, isRequestMode]);

    return (
        <>
            {isRequestMode ?
                <div className="max-w-[600px]">
                    <PaymentInfo
                        accountInfo={accountInfo}
                    />
                    <form onSubmit={createNewRequest}>
                        <SimpleInput
                            value={amount}
                            onChange={(val) => setAmount(val)}
                            isLabel={true}
                            labelText="Withdraw Amount"
                            inputMode='numeric'
                            extraStyle={{ borderColor: "#009d91" }}
                            isRequired={true}
                        />
                        <div className="flex gap-2 items-start mb-3">
                            <input type="checkbox" className="mt-1" required={true} />
                            <p className="text-sm">I confirm that I agree to these payment details, that they are accurate, and that funds can be transferred to this account as agreed.</p>
                        </div>
                        <div className="flex justify-end">
                            <PrimaryBtn
                                loader={createLoader}
                                type="submit"
                                ButtonText="Withdrawal Request"
                                btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "50px", padding: "0.5rem 2rem" }}
                            />
                        </div>
                    </form>
                </div>
                :
                <>
                    <div className='flex items-center justify-between my-4'>
                        <div className='Withdrawal-box-revenue'>
                            <div>
                                <p className='text-[#585858] font-size-16px font-Poppins-Regular'>Current Balance</p>
                                <strong className='text-[#373737] font-size-22px font-Poppins-SemiBold'>AED {walletData?.currentBalance || "-"}</strong>
                            </div>
                            <div>
                                <img src={folderArrow} alt="folder arrow" />
                            </div>
                        </div>
                        <div className='Withdrawal-box-revenue'>
                            <div>
                                <p className='text-[#585858] font-size-16px font-Poppins-Regular'>Total Withdrawal</p>
                                <strong className='text-[#373737] font-size-22px font-Poppins-SemiBold'>AED {walletData?.totalWithdrawAmount || "-"}</strong>
                            </div>
                            <div>
                                <img src={folderArrow} alt="folder arrow" />
                            </div>
                        </div>
                        <div className='Withdrawal-box-revenue'>
                            <div>
                                <p className='text-[#585858] font-size-16px font-Poppins-Regular'>Pending Withdrawal</p>
                                <strong className='text-[#373737] font-size-22px font-Poppins-SemiBold'>AED {walletData?.pendingWithdraw || "-"}</strong>
                            </div>
                            <div>
                                <img src={folderArrow} alt="folder arrow" />
                            </div>
                        </div>
                        <div className='Withdrawal-box-revenue'>
                            <div>
                                <p className='text-[#585858] font-size-16px font-Poppins-Regular'>Total Earnings</p>
                                <strong className='text-[#373737] font-size-22px font-Poppins-SemiBold'>AED {walletData?.totalAmount || "-"}</strong>
                            </div>
                            <div>
                                <img src={folderArrow} alt="folder arrow" />
                            </div>
                        </div>
                    </div>
                    <hr className='my-4' />
                    <div className="flex items-center justify-end mb-3">
                        <PrimaryBtn
                            title={checkBankInfoExist() ? "" : "Filling in your bank information is mandatory before submitting a withdrawal request."}
                            disabled={checkBankInfoExist() ? false : true}
                            btnExtralClasses={checkBankInfoExist() ? "" : "cursor-not-allowed"}
                            ButtonText="Withdrawal Request"
                            onPress={() => setIsRequestMode(true)}
                            btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem", padding: "7px 25px" }}
                        />
                    </div>
                    <CustomerTableWrapper
                        isLoading={isLoader}
                        thead={["S.No", "Requested Amount", "Status", "Verification Document", "Comment", ""]}
                        page={paginated.currentPage || 1}
                        total={paginated.totalPages || 0}
                        columnWidth={"8% 17% 17% 20% 38% 0%"}
                        onChange={handlePageChange}
                    >
                        {data.map((item: IItem, index) => (
                            <ul key={index} className='font-size-16px tbody-light-table customer-table-wrapper-body-padding-non customer-table-wrapper-body tbody-light-table-customer font-Poppins-Regular grid' style={{ gridTemplateColumns: "8% 17% 17% 20% 38% 0%" }}>
                                <li style={{ paddingLeft: '2rem' }}>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                <li>AED {item?.amount || ""}</li>
                                <li>
                                    <span
                                        className='py-1 px-4 text-white capitalize rounded'
                                        style={{
                                            backgroundColor:
                                                item.status === "pending" ? statusColor.yellow
                                                    : item.status === "completed" ? statusColor.green
                                                        : item.status === "declined" ? statusColor.red
                                                            : ""
                                        }}
                                    >{item.status}</span>
                                </li>
                                <li style={{ justifyContent: 'center' }}>
                                    {item.status === "completed"
                                        ?
                                        <img className='img-recepit'
                                            src={`${process.env.REACT_APP_API_BASEURL}${item?.attachment}`}
                                            alt="attachement"
                                        />
                                        :
                                        "-"
                                    }
                                </li>
                                <li>
                                    <span className="p-2">
                                        <p>{item?.reason || ""}</p>
                                    </span>
                                </li>
                                <li></li>
                            </ul>
                        ))}
                    </CustomerTableWrapper>
                </>
            }
        </>
    );
};

export default MyWithDrawalDetails;