/**
 * ToggleRadioBtn.tsx
 * 
 * Date: 09-08-2024
 * 
 * Description: 
 * This component represents a toggle button with a text label. It allows users to toggle between two states.
 * 
 * Props:
 * - toggleText (optional): The text to display next to the toggle button.
 * - isToggled (optional): A boolean indicating whether the toggle is in the "on" state.
 * - handleToggle (optional): A function to call when the toggle button is clicked.
 */

import React from 'react';

interface IToggleRadioBtnProps {
    toggleText?: string; // The text to display next to the toggle button
    isToggled?: boolean; // Indicates whether the toggle is in the "on" state
    handleToggle?: () => void; // Function to call when the toggle button is clicked

    loader?: boolean; // loader to disable the radio button while data is loading from the api
};

const ToggleRadioBtn: React.FC<IToggleRadioBtnProps> = ({
    toggleText = "",
    isToggled = false,
    handleToggle = () => { },

    loader = false,
}) => {
    return (
        <div className="toggle-button" onClick={handleToggle} style={loader ? {pointerEvents: "none"} : {}}>
            {/* Toggle knob indicating the state of the button */}
            <div className={`toggle-knob ${isToggled ? 'toggled' : ''}`} />
            {/* Display the text next to the toggle button */}
            <span className="font-size-18px font-Poppins-Regular">{toggleText}</span>
        </div>
    );
};

export default ToggleRadioBtn;