/**
 * SingleBioMarkerResult Component
 * 
 * Description: This component renders a form to capture and display single biomarker results. 
 * It includes fields for selecting dates, showing reference/optional values, and entering results.
 * The form is dynamically adjusted based on the biomarker type (e.g., DND, PN).
 * 
 * File Name: SingleBioMarkerResult.js
 * Date: 2024-08-24
 */

import React, { FormEvent, useEffect, useState } from 'react';
import { IAddSingleBio } from 'src/types/GlobalInterfaces';
import PrimaryBtn from '../primaryBtn';
import moment from 'moment';
import { ageCategory } from 'src/helpers/helperFunctions';
import { descriptiveResult, twoBarDNDAsc, twoBarDNDDesc, twoBarPNAsc, twoBarPNDesc } from 'src/lib/constans';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { setUniqueData } from "src/Store/reducer";
import { useDispatch } from 'react-redux';
import { AppDispatch } from "src/Store/store";

// SingleBioMarkerResult component definition
const SingleBioMarkerResult: React.FC<{ item: IAddSingleBio, uid: string; onSuccess: () => void, _gender: string; _date: string; }> = ({ item, uid, onSuccess, _date, _gender }) => {
    const dispatch = useDispatch<AppDispatch>();
    const uniqueKey = Math.random(); // Generate a unique key for each form instance

    const [isLoader, setIsLoader] = useState<boolean>(false); // State to handle form loading status

    const [data, setData] = useState<{ biomarkerId: string; value: string; date: string; isShown: boolean }>({
        biomarkerId: "",
        value: "",
        date: "",
        isShown: false
    }); // Initial state for capturing biomarker data

    // Calculate the user's age based on date of birth
    const age = moment().diff(moment(_date), 'years');
    // Determine the age group for selecting reference and optional values
    const ageGroup = ageCategory(age);

    // State to store reference and optional values based on gender and age group
    const [refValues, setRefValues] = useState<number[]>([]);
    const [opValues, setOpValues] = useState<number[]>([]);

    // Handle form input changes
    const handleChange = (key: string, value: string) => {
        setData(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    // API_CALL: Handle form submission for saving the biomarker result
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        // Make API request to save the biomarker result
        const { res, err } = await httpRequest<{ message: string; profileCompletionPercentage: number }>({ method: "post", path: `/healthprofile/create/${uid}`, params: data });
        if (res) {
            toast(res?.message, { type: 'success' });
            dispatch(setUniqueData({ key: 'profileCompletionPercentage', value: String(res?.profileCompletionPercentage) }));
            onSuccess();
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Effect to set data and load reference/optional values when item, date, or gender changes
    useEffect(() => {
        if (item && _date && _gender) {
            console.log("item", item)
            setData(prev => ({
                ...prev,
                biomarkerId: item?._id,
                isShown: item?.isShown,
            }));
            // Fetch reference and optional values based on the selected gender and age group
            setRefValues(item[`${_gender}Thresholds`]['ref'][ageGroup]);
            setOpValues(item[`${_gender}Thresholds`]['op'][ageGroup]);
        }
    }, [item, _date, _gender]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-center mb-3">
                <input
                    placeholder='Date'
                    value={moment(data.date).format("YYYY-MM-DD")}
                    onChange={(e) => handleChange("date", e.target.value + "T00:00:00Z")}
                    className='date-picker-health-vitals font-size-13px theme-color-green font-Poppins-Medium'
                    type="date"
                    required
                />
            </div>
            {
                item.isShown
                &&
                <div className='ref-min-main-wrapper-health-vitals mb-3'>
                    {/* ref min */}
                    <div className="ref-min-container-health-vitals">
                        <button type="button" className="ref-min-button-health-vitals font-size-13px font-Poppins-Regular">Ref.Min &lt;</button>
                        <input
                            disabled
                            value={refValues[0] || ""}
                            type="text"
                            className="ref-min-input-health-vitals font-size-13px"
                            placeholder="30"
                        />
                    </div>
                    {/* ref max */}
                    <div className="ref-min-container-health-vitals">
                        <button type="button" className="ref-min-button-health-vitals font-size-13px font-Poppins-Regular">Ref.Max &lt;</button>
                        <input
                            disabled
                            value={refValues[1] || ""}
                            type="text"
                            className="ref-min-input-health-vitals font-size-13px "
                            placeholder="30"
                        />
                    </div>
                    {/* opt min */}
                    <div className="ref-min-container-health-vitals">
                        <button type="button" className="ref-min-button-health-vitals font-size-13px font-Poppins-Regular">Opt.Min &lt;</button>
                        <input
                            disabled
                            value={opValues[0] || ""}
                            type="text"
                            className="ref-min-input-health-vitals font-size-13px "
                            placeholder="30"
                        />
                    </div>
                    {/* opt max */}
                    <div className="ref-min-container-health-vitals">
                        <button type="button" className="ref-min-button-health-vitals font-size-13px font-Poppins-Regular">Opt.Max &lt;</button>
                        <input
                            disabled
                            value={opValues[1] || ""}
                            type="text"
                            className="ref-min-input-health-vitals font-size-13px "
                            placeholder="30"
                        />
                    </div>
                </div>
            }
            <div className='flex justify-center'>
                <div className="ref-min-container-health-vitals">
                    <button type="button" className="ref-min-button-health-vitals font-size-13px font-Poppins-Regular">Result &lt;</button>
                    {
                        (item.barId === twoBarDNDAsc || item.barId === twoBarDNDDesc)
                            ?
                            <>
                                <div className="flex items-center">
                                    <input
                                        id={`detected${uniqueKey}`}
                                        type="radio"
                                        name={`dndresult${uniqueKey}`}
                                        className="w-4 h-4"
                                        onChange={(e) => handleChange("value", e.target.checked ? String(1) : String(0))}
                                        required
                                    />
                                    <label htmlFor={`detected${uniqueKey}`} className="ms-2 font-size-16px font-medium text-gray-900 dark:text-gray-300">Detected</label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        id={`not-detected${uniqueKey}`}
                                        type="radio"
                                        name={`dndresult${uniqueKey}`}
                                        className="w-4 h-4"
                                        onChange={(e) => handleChange("value", e.target.checked ? String(0) : String(1))}
                                        required
                                    />
                                    <label htmlFor={`not-detected${uniqueKey}`} className="ms-2 font-size-16px font-medium text-gray-900 dark:text-gray-300">Not Detected</label>
                                </div>
                            </>
                            : (item.barId === twoBarPNAsc || item.barId === twoBarPNDesc)
                                ?
                                <>
                                    <div className="flex items-center">
                                        <input
                                            id={`positive${uniqueKey}`}
                                            type="radio"
                                            name={`pnnresult${uniqueKey}`}
                                            className="w-4 h-4"
                                            onChange={(e) => handleChange("value", e.target.checked ? String(1) : String(0))}
                                            required
                                        />
                                        <label htmlFor={`positive${uniqueKey}`} className="ms-2 font-size-16px font-medium text-gray-900 dark:text-gray-300">Positive</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            id={`negative${uniqueKey}`}
                                            type="radio"
                                            name={`pnnresult${uniqueKey}`}
                                            className="w-4 h-4"
                                            onChange={(e) => handleChange("value", e.target.checked ? String(0) : String(1))}
                                            required
                                        />
                                        <label htmlFor={`negative${uniqueKey}`} className="ms-2 font-size-16px font-medium text-gray-900 dark:text-gray-300">Negative</label>
                                    </div>
                                </>
                                :
                                <input
                                    type={item.barId == descriptiveResult ? "text" : "number"}
                                    inputMode={item.barId == descriptiveResult ? "text" : "numeric"}
                                    className="ref-min-input-health-vitals font-size-13px "
                                    placeholder="30"
                                    value={data.value}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (item.barId === descriptiveResult) {
                                            handleChange("value", value);
                                        } else {
                                            if (value === "" || /^\d+(\.\d{0,2})?$/.test(value)) {
                                                handleChange("value", value);
                                            }
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        // Prevent entering "e", "-", "+", or multiple "."
                                        if (["e", "E", "-", "+"].includes(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    required
                                />
                    }
                </div>
            </div>
            <div className='flex justify-end my-3'>
                <PrimaryBtn
                    loader={isLoader}
                    type='submit'
                    ButtonText='Save'
                    btnExtraStyle={{ backgroundColor: "#004540", borderRadius: "1.5rem", paddingLeft: "2rem", paddingRight: "2rem" }}
                />
            </div>
        </form>
    );
};

export default SingleBioMarkerResult;