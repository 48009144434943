import React, { useState } from 'react';
import SimpleInput from '../SimpleInput';
import PrimaryBtn from './primaryBtn';

interface IDateFilterProps {
    apply?: (startDate: string, endDate: string) => void;
    reset?: () => void;
};

const StartAndEndDateFilter: React.FC<IDateFilterProps> = ({
    apply = () => { },
    reset = () => { },
}) => {
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
            <div>
                <SimpleInput
                    value={startDate}
                    onChange={(val) => setStartDate(val)}
                    type='date'
                    placeholder='Start Date'
                />
            </div>
            <div>
                <SimpleInput
                    value={endDate}
                    onChange={(val) => setEndDate(val)}
                    type='date'
                    placeholder='End Date'
                />
            </div>
            {/* Empty column for large screens */}
            <div className="hidden md:block"></div>
            <div className='flex items-center gap-3 justify-end'>
                <PrimaryBtn
                    disabled={!startDate && !endDate}
                    ButtonText='Reset'
                    onPress={() => { setStartDate(""); setEndDate(""); reset() }}
                    btnExtraStyle={{
                        borderRadius: "1.5rem",
                        backgroundColor: "#004540",
                        padding: "6px 30px"
                    }}
                />
                <PrimaryBtn
                    ButtonText='Apply'
                    onPress={() => apply(startDate, endDate)}
                    btnExtraStyle={{
                        borderRadius: "1.5rem",
                        backgroundColor: "#004540",
                        padding: "6px 30px"
                    }}
                />
            </div>
        </div>
    );
};

export default StartAndEndDateFilter;