import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../Store/store';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SimplePeer from 'simple-peer';
import { io } from 'socket.io-client';
import ConfirmationModal from 'src/Components/GlobalComponents/ConfirmationModal';
import { getToken } from 'src/helpers/helperFunctions';
import httpRequest from 'src/helpers/httpRequest';
import SplashScreen from 'src/Components/SplashScreen';
import defaultProfile from "src/assets/images/default_profile_image.png";

interface IMeetingDetails {
    userDetails: string;
    userImage: string;
    appointmentId: string;
    roomId: string;
};

const socket = io(`${process.env.REACT_APP_API_BASEURL}`, {
    auth: {
        token: getToken(),
    }
});

const OnlineConsultations: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);

    const navigate = useNavigate();
    const location = useLocation();
    const { roomId = "", appoinmentId = "" } = useParams();
    const [confirmModal, setConfirmModal] = useState(false);
    const [isCameraOn, setIsCameraOn] = useState(true);
    const [isPeerCameraOn, setIsPeerCameraOn] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const [currentUserStream, setCurrentUserStream] = useState<MediaStream | null>(null);
    const [peerStream, setPeerStream] = useState<MediaStream | null>(null);
    const peerRef = useRef<SimplePeer.Instance | null>(null); // Define peerRef here


    const copyToClipBoard = async () => {
        try {
            const fullUrl = `${window.location.origin}${location.pathname}`;
            await navigator.clipboard.writeText(fullUrl);
            toast("Copied to clipboard.", { type: "success" });
        } catch (err) {
            toast("Something went wrong.", { type: "error" });
        }
    };
    const handleMuteToggle = () => {
        if (currentUserStream) {
            const audioTracks = currentUserStream.getAudioTracks();
            if (audioTracks.length > 0) {
                // Toggle the audio track based on current mute state
                const newMuteState = !isMuted;
                audioTracks[0].enabled = !newMuteState;
                setIsMuted(newMuteState);

                // Emit the mute toggle state to the socket
                socket.emit('muteToggle', { roomId, isMuted: newMuteState });
            }
        }
    };

    // Ensure that the peer stream setup does not lead to duplicate 

    const handleCameraToggle = () => {
        if (currentUserStream) {
            const videoTracks = currentUserStream.getVideoTracks();
            if (videoTracks.length > 0) {
                videoTracks[0].enabled = !isCameraOn;
                setIsCameraOn(!isCameraOn);
                socket.emit('cameraToggle', { roomId, isCameraOn: !isCameraOn });
            }
        } else {
            console.warn("No video track available to toggle.");
        }
    };

    const confirmLeave = () => {
        if (currentUserStream) {
            currentUserStream.getTracks().forEach(track => track.stop());
            setCurrentUserStream(null);
        }
        navigate(-1);
    };

    const setupSocketListeners = (stream: MediaStream) => {
        socket.on('userJoined', (userId) => {
            console.log("User joined:", userId);
            if (!peerRef?.current) {
                createPeer(true, stream); // Create peer as initiator
            }
        });

        socket.on('signal', ({ signal, userId }) => {
            console.log("Received signal from:", userId);

            if (peerRef?.current) {
                try {
                    // Ignore signals if the peer connection is already in a stable state and the signal is an answer
                    const peerConnection = (peerRef.current as any)._pc; if (peerConnection && peerConnection.signalingState === 'stable' && signal.type === 'answer') { console.warn('Ignoring signal, as the peer is already in a stable state.'); return; }

                    peerRef.current.signal(signal);
                } catch (error) {
                    console.error('Error applying signal:', error);
                }
            } else {
                createPeer(false, stream); // Create peer as receiver if no peer exists
            }
        });

        socket.on('iceCandidate', ({ candidate }) => {
            if (peerRef?.current) {
                try {
                    peerRef.current.signal(candidate);
                } catch (error) {
                    console.error('Error processing ICE candidate:', error);
                }
            }
        });
    };

    const createPeer = (isInitiator: boolean, stream: MediaStream) => {
        console.log("Creating peer. Is initiator:", isInitiator);
        // Destroy any existing peer
        if (peerRef?.current) {
            peerRef?.current?.destroy();
            peerRef.current = null;
        }
        const peer = new SimplePeer({
            initiator: isInitiator,
            trickle: false,
            stream: stream,
        });
        peer?.on('signal', (signal) => {
            console.log("Peer signal:", signal);
            if (signal?.type === 'offer' || signal?.type === 'answer') {
                socket.emit('signal', { roomId, signalData: signal });
            }
        });
        peer?.on('stream', (incomingPeerStream) => {
            if (!incomingPeerStream?.getAudioTracks()[0].enabled) {
                setPeerStream(incomingPeerStream);
            }
        });
        peer?.on('stream', (peerStream) => {
            console.log("Peer stream received.");
            if (peerStream?.getVideoTracks()?.length > 0) {
                setPeerStream(peerStream);
            }

            // Check if the peer's video track is enabled or disabled
            const videoTrack = peerStream?.getVideoTracks()[0];
            setIsPeerCameraOn(videoTrack?.enabled);

            // Add a listener to monitor changes in the peer's camera state
            videoTrack.onmute = () => {
                setIsPeerCameraOn(false); // Peer turned off their camera
            };
            videoTrack.onunmute = () => {
                setIsPeerCameraOn(true); // Peer turned on their camera
            };
        });
        peer?.on('connect', () => {
            console.log('Peer connection established.');
        });
        peer?.on('error', (err) => {
            console.error('Peer connection error:', err);
        });
        peer?.on('close', () => {
            console.log('Peer connection closed.');
            peerRef.current = null; // Clear the peer reference
        });
        peerRef.current = peer;
    };


    const setupVideoCall = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevice = devices.some(device => device?.kind === 'videoinput');
            const audioDevice = devices.some(device => device?.kind === 'audioinput');

            const mediaConstraints: MediaStreamConstraints = {
                video: videoDevice,
                audio: audioDevice
            };

            const stream = await navigator?.mediaDevices?.getUserMedia(mediaConstraints);
            setCurrentUserStream(stream);

            socket.emit('joinRoom', roomId);
            setupSocketListeners(stream);
        } catch (error) {
            console.error('Error accessing media devices:', error);
            toast('Unable to access camera or microphone', { type: 'error' });
        }
    };

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [meetingDetails, setMeetingDetails] = useState<IMeetingDetails | null>(null);
    const fetchMeetingDetails = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IMeetingDetails[]>({ path: `/appoiment/meeting-details/${appoinmentId}?roomId=${roomId}` });
        if (res) {
            setMeetingDetails(res[0]);
            setupVideoCall();
        } else {
            toast(err, { type: 'error' });
            confirmLeave();
        }
        setIsLoader(false);
    };

    // useEffect(() => {
    //     fetchMeetingDetails();
    //     return () => {
    //         confirmLeave();
    //         if (peerRef.current) {
    //             peerRef.current.destroy();
    //             peerRef.current = null;
    //         }
    //         socket.off('userJoined');
    //         socket.off('signal');
    //         socket.off('iceCandidate');
    //     };
    // }, [roomId]);
    useEffect(() => {
        fetchMeetingDetails();

        return () => {
            confirmLeave();
            if (peerRef?.current) {
                peerRef?.current.destroy();
                peerRef.current = null;
            }
            socket?.off('userJoined');
            socket?.off('signal');
            socket?.off('iceCandidate');
            socket?.disconnect(); // Close the socket connection when the component unmounts
        };
    }, [roomId]);
    return (
        <>
            {isLoader && <SplashScreen />}
            <ConfirmationModal
                title='Are you sure you want to leave.'
                show={confirmModal}
                onClose={() => setConfirmModal(false)}
                leftBtnText='No'
                rightBtnText='Yes'
                loader={false}
                onConfirm={confirmLeave}
            />
            <div className='video-call-page'>
                <div className='video-call-page-header relative'>
                    <p className='font-size-22px text-white font-Poppins-SemiBold'>Online Consultation</p>
                    <FontAwesomeIcon onClick={copyToClipBoard} icon={faCopy} />
                </div>
                <div className='flex justify-evenly'>
                    <div className='video-call-page-video relative rounded-lg border-2 border-white w-[50%]'>
                        {isCameraOn && currentUserStream ? (
                            <video
                                ref={(videoElement) => {
                                    if (videoElement && currentUserStream) videoElement.srcObject = currentUserStream;
                                }}
                                className="w-full h-80 bg-black scale-x-[-1]"
                                autoPlay
                                muted
                            />
                        ) : (
                            <img
                                alt='profile-image'
                                className='max-h-[320px] mx-auto'
                                src={`${process.env.REACT_APP_API_BASEURL}${userDetails?.coverPhoto}?t=${Date.now()}`}
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    if (target) target.src = defaultProfile;
                                }}
                            />
                        )}
                        <span className='text-white font-size-16px font-Poppins-Medium capitalize'>{`${userDetails?.firstName || ""} ${userDetails?.lastName || ""}`}</span>
                    </div>
                    <div className='video-call-page-video relative rounded-lg border-2 border-white w-[50%]'>
                        {isPeerCameraOn && peerStream ? (
                            <video
                                ref={(videoElement) => {
                                    if (videoElement && peerStream) videoElement.srcObject = peerStream;
                                }}
                                className="w-full h-80 bg-black scale-x-[-1]"
                                autoPlay
                                playsInline
                            />
                        ) : (
                            <img
                                alt='profile-image'
                                className='max-h-[320px] mx-auto'
                                src={`${process.env.REACT_APP_API_BASEURL}${meetingDetails?.userImage}?t=${Date.now()}`}
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    if (target) target.src = defaultProfile;
                                }}
                            />
                        )}
                        <span className='text-white font-size-16px font-Poppins-Medium capitalize'>{meetingDetails?.userDetails || ""}</span>
                    </div>
                </div>
                <div className='video-call-page-footer relative flex justify-between'>
                    <span></span>
                    <div className='flex gap-4 items-center'>
                        <div onClick={handleMuteToggle} className='flex flex-col cursor-pointer w-[150px] items-center gap-2'>
                            <FontAwesomeIcon
                                icon={isMuted ? faMicrophoneSlash : faMicrophone}
                            />
                            <p className='font-size-14px text-white font-Poppins-Medium'>
                                {isMuted ? 'Unmute' : 'Mute'}
                            </p>
                        </div>
                        <div onClick={handleCameraToggle} className='flex flex-col cursor-pointer w-[150px] items-center gap-2'>
                            <FontAwesomeIcon
                                icon={isCameraOn ? faVideo : faVideoSlash}
                            />
                            <p className='font-size-14px text-white font-Poppins-Medium'>
                                {isCameraOn ? 'Turn Off Camera' : 'Turn On Camera'}
                            </p>
                        </div>
                    </div>
                    <button onClick={() => setConfirmModal(true)} className='text-white font-size-16px font-Poppins-Medium'>End</button>
                </div>
            </div>
        </>
    );
};

export default OnlineConsultations;
