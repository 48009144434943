import React, { FormEvent, useEffect, useState } from 'react';
import PageHeader from 'src/Components/Customer/PageHeader';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import SimpleInput from 'src/Components/SimpleInput';
import httpRequest from 'src/helpers/httpRequest';
import { IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import { toast } from 'react-toastify';
import CustomerTableWrapper from 'src/Components/Customer/CustomerTableWrapper';
import moment from 'moment';
import pendingcircle from "src/assets/images/pending-circle.png";
import resolvedcircle from "src/assets/images/resolved-circle.png";
import BackIcon from "src/assets/images/back-icon.png";

// Interface defining the structure of individual ticket items
interface ITicketItems {
    ticketNumber: string;
    email: string;
    createdAt: string;
    status: "resolved" | "pending";
};

// Interface defining the response structure for ticket fetching
interface ITicketResponse {
    documents: ITicketItems[];
    paginated: IPaginated;
};

// Main functional component for Support Hubs
const SupportHubs: React.FC = () => {
    const [isCreateMode, setIsCreateMode] = useState<boolean>(false); // State to toggle between create and view mode

    // *********************** for craete new support ticket ***********************
    const [createLoader, setCreateLoader] = useState<boolean>(false); // Loader for ticket creation
    const [email, setEmail] = useState<string>(""); // Email state for input
    const [description, setDescription] = useState<string>(""); // Description state for input

    //API_CALL: Function to handle creation of new support ticket
    const createNewTicket = async (event: FormEvent) => {
        event.preventDefault();
        setCreateLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: "/supportandsetting/create",
            params: { email, description }
        });
        if (res) {
            toast(res.message || "Success", { type: 'success' });
            setQueryParams(prev => ({
                ...prev,
                page: 1, // Reset to the first page after creation
                bool: !prev.bool, // Toggle to refresh the list
            }));
            setIsCreateMode(false); // Exit create mode
            setEmail(""); // Clear email input
            setDescription(""); // Clear description input
        } else {
            toast(err, { type: 'error' });
        }
        setCreateLoader(false);
    };
    // *********************** for craete new support ticket ***********************

    const [isLoader, setIsLoader] = useState<boolean>(false); // Loader for fetching tickets
    const [allTickets, setAllTickets] = useState<ITicketItems[]>([]); // State to store all tickets
    const [paginated, setPaginated] = useState<IPaginated>({ // State to handle pagination
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination

    const [queryParams, setQueryParams] = useState<IqueryParams>({ // Query parameters for API requests
        page: 1,
        limit: 10,
        search: "",
        bool: false,
    });

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            search: txt,
            page: 1, // Reset to the first page when searching
        }));
    };

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // API_CALL: Fetches all tickets from the server
    const fetchTickets = async () => {
        setIsLoader(true);
        const { bool, ...remaining } = queryParams;
        const { res, err } = await httpRequest<ITicketResponse>({
            path: `/supportandsetting?${qs.stringify(remaining)}`
        });
        if (res) {
            let { documents = [], paginated } = res;
            setAllTickets(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Fetch tickets whenever query parameters change
    useEffect(() => {
        fetchTickets();
    }, [queryParams]);

    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => setIsCreateMode(false)}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    return (
        <>
            <PageHeader
                title="Support & Feedback"
                isButton={isCreateMode}
                ButtonComponent={BackButton}
            />
            <div className="parent-box parent-box-height">
                {isCreateMode ?
                    <div className="create__support__hub">
                        <h2 className='title-heading font-Poppins-SemiBold font-size-20px mb-3'>Support & Feedback</h2>
                        <form onSubmit={createNewTicket}>
                            <SimpleInput
                                value={email}
                                type="email"
                                onChange={(val) => setEmail(val)}
                                isLabel={true}
                                labelText="Email"
                                isRequired={true}
                                extraStyle={{ borderColor: "#009d91" }}
                            />
                            <SimpleInput
                                value={description}
                                onChange={(val) => setDescription(val)}
                                isLabel={true}
                                labelText="Description"
                                isRequired={true}
                                type="textArea"
                                rows={5}
                                extraStyle={{ borderColor: "#009d91" }}
                            />
                            <div className="flex justify-end">
                                <PrimaryBtn
                                    loader={createLoader}
                                    type="submit"
                                    ButtonText="Submit"
                                    btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                                />
                            </div>
                        </form>
                    </div>
                    :
                    <>
                        <div className="flex items-center justify-between mb-3">
                            <SearchFilterInput
                                containerExtraStyle={{ display: "block" }}
                                inputExtraStyle={{ width: "100%", marginBottom: 0 }}
                                onChangeText={handleChangeText}
                                placeHolder={"Search"}
                            />
                            <PrimaryBtn
                                ButtonText="Generate Ticket"
                                onPress={() => setIsCreateMode(true)}
                                btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem", padding:"6px 30px" }}
                            />
                        </div>
                        <CustomerTableWrapper
                            isLoading={isLoader}
                            thead={["Ticket Number", "Email", "Date", "Status"]}
                            page={paginated.currentPage || 1}
                            total={paginated.totalPages || 0}
                            columnWidth={"20% 35% 23% 16%"}
                            onChange={handlePageChange}
                            tailwindClass='gap-x-[6px]'

                        >
                            {allTickets?.map((item: ITicketItems, index: number) => (
                                <ul key={index} className='table-customer-order-padding font-size-16px tbody-light-table customer-table-wrapper-body-padding-non customer-table-wrapper-body tbody-light-table-customer font-Poppins-Regular grid gap-x-[6px]' style={{ gridTemplateColumns: "20% 35% 23% 16%" }}>
                                    <li>{item.ticketNumber}</li>
                                    <li>{item.email}</li>
                                    <li>{moment(item.createdAt).format("DD-MM-YYYY")}</li>
                                    <li className="capitalize">
                                        <span className="flex gap-1 items-center">
                                            <img
                                                width={10}
                                                src={item.status === "pending" ? pendingcircle : resolvedcircle}
                                                alt='status'
                                            />
                                            {item.status}
                                        </span>
                                    </li>
                                </ul>
                            ))}
                        </CustomerTableWrapper>
                    </>
                }
            </div>
        </>
    );
};

export default SupportHubs;