/**
 * RevenuePercentages Component
 * 
 * Description: This component fetches and displays the revenue percentages for appointments and tests 
 * in a pie chart format. It also shows a loading indicator while fetching data from the API.
 * 
 * Props: None
 * 
 * File Name: RevenuePercentages.tsx
 * Date: 30-09-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import httpRequest from 'src/helpers/httpRequest';

/**
 * Interface for angles representing different revenue sources.
 */
interface IKeys {
    appointmentAngle: number;
    productAngle: number;
    pharmacyAngle: number;
};

/**
 * Interface for each data item displayed in the chart.
 */
interface IData {
    label: string;
    key: keyof IKeys;
    color: string;
    value: number;
};

const RevenuePercentages: React.FC = () => {
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [data, setData] = useState<IData[]>([
        { label: "Appointment", key: "appointmentAngle", color: "#8b8fff", value: 0 },
        { label: "Tests", key: "productAngle", color: "#4b95eb", value: 0 },
        // { label: "Pharmacy", key: "pharmacyAngle", color: "#2ab900", value: 0 },
    ]);

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
    }: {
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        percent: number;
    }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    //API_CALL: Fetch data from the API
    const fetch = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IKeys>({ path: "/appoiment/avarage/graph" });
        if (res) {
            // Update the data state based on the response
            setData(prev =>
                prev.map(v => ({
                    ...v,
                    value: v.key === "appointmentAngle" ? res.appointmentAngle :
                        v.key === "productAngle" ? res.productAngle : v.value
                }))
            );
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetch();
    }, []);
    return (
        <div>
            <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">Revenue Percentage</h2>
            <div className='user-graph-inner h-[90%]'>
                {isLoader &&
                    <div className="text-center">
                        <CircularProgress style={{ color: "#004540" }} size={20} />
                    </div>
                }
                <div className='Biomarkers-graph'>
                    <div className='bio-graph-bio-legend-main'>
                        {data.map((v: IData, i: number) => (
                            <div
                                className='bio-graph-bio-legend'
                                key={`legend-${i}`}>
                                <div
                                    className='bio-graph-bio-color'
                                    style={{
                                        backgroundColor: v.color,
                                        borderRadius: '50%',
                                    }}
                                ></div>
                                <span className='font-size-16px'>{v.label}</span>
                            </div>
                        ))}
                    </div>
                    <div className='relative h-[100%] w-[100%]'>
                        <div className='relative pie-chart-container w-[100%]'>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        // data={data}
                                        // cx="50%"
                                        // cy="50%"
                                        // labelLine={false}
                                        // label={renderCustomizedLabel}
                                        // outerRadius={80}
                                        // dataKey="value"
                                        data={data}
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        dataKey="value"
                                        label={renderCustomizedLabel}
                                        labelLine={false}
                                    >
                                        {data.map((entry: IData, index: number) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RevenuePercentages;