/**
 * UpsertDisease Component
 * 
 * Description: This component enables users to add a new disease or edit an existing one. 
 * It features an image upload functionality for the disease icon, a text input for the disease name, 
 * and a save button. Upon submission, the component sends a request to the server to either create 
 * a new disease or update an existing one, depending on whether it is in add or edit mode. 
 * It includes form validation for both the name and image fields, ensuring that the user provides 
 * all required information. Success or error messages are displayed based on the server's response, 
 * providing clear feedback to the user.
 * 
 * Props:
 * - isEdit (IDiseaseItem | boolean): Optional. If provided as an object, it represents the disease 
 *   to be edited. If `false`, the component operates in add mode.
 * - onSuccess (function): Callback function invoked upon successful form submission, allowing parent 
 *   components to refresh data or perform any necessary actions after a successful operation.
 * 
 * File Name: UpsertDisease.tsx
 * Date: 05-09-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import { IDiseaseItem } from 'src/types/GlobalInterfaces';
import ImageUpload from '../GlobalComponents/ImageUplaod';
import SimpleInput from '../SimpleInput';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';

interface IUpsertDiseaseProps {
    isEdit?: IDiseaseItem | boolean;
    onSuccess: () => void;
};

const UpsertDisease: React.FC<IUpsertDiseaseProps> = ({ isEdit = false, onSuccess = () => { } }) => {
    // img upload
    const [name, setName] = useState<string>("");
    const [diseasesImage, setDiseasesImage] = useState<File | null | string>(null);

    const [isLoader, setIsLoader] = useState<boolean>(false);

    const handleSubmit = async () => {
        if (!name) {
            toast("Disease name is mandatory!", { type: 'error' });
        } else if (!diseasesImage) {
            toast("Disease image is mandatory!", { type: 'error' });
        } else {
            setIsLoader(true);
            const formData = new FormData();
            formData.append("name", name);
            formData.append("diseasesImage", diseasesImage);
            const path = isEdit && typeof isEdit !== 'boolean' ? `/diseases/${isEdit._id}` : "/diseases";

            const { res, err } = await httpRequest<any>({ method: isEdit ? "put" : "post", path, params: formData, header: { "Content-Type": "multipart/form-data" } });
            if (res) {
                toast(`Disease has been ${isEdit ? "updated" : "added"} successfully.`, { type: 'success' });
                onSuccess();
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        }
    };

    useEffect(() => {
        if (isEdit && typeof isEdit !== 'boolean') {
            let { name = "", diseasesImage = "" } = isEdit;
            setName(name);
            setDiseasesImage(diseasesImage);
        }
    }, [isEdit]); // Execute this useEffect hook if in edit mode to initialize form with existing role data
    return (
        <div className="table-container">
            <div className='admin-create-role-main'>
                <div className='admin-create-role'>
                    <div className='create-operators-res '>
                        <h3 className='font-size-18px text-[#2F2F31] mb-1 font-Poppins-Regular'>Disease Icon</h3>
                        <ImageUpload
                            image={diseasesImage}
                            onImageChange={setDiseasesImage}
                        />
                        <SimpleInput
                            value={name}
                            onChange={setName}
                            isLabel={true}
                            labelText="Disease Name"
                            isRequired={true}
                        />
                    </div>
                </div>
                <div className='flex justify-end my-6'>
                    <PrimaryBtn
                        loader={isLoader}
                        ButtonText="Save"
                        btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        onPress={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

export default UpsertDisease;