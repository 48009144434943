import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import StoreProvider from './Store/StoreProvider';
import { BrowserRouter } from 'react-router-dom';
import "./index.css";
import process from 'process';

// Ensure that 'root' exists in the HTML file (usually public/index.html)
const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Root element not found');
}

window.process = process;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <StoreProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();