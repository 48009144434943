/**
 * ImageUpload Component
 * 
 * Description: This component allows users to upload an image, displays a preview of the uploaded image,
 * and provides an option to remove the uploaded image. It uses a file input to handle the image upload
 * and a button to remove the image.
 * 
 * Props:
 * - image (File | null): The currently uploaded image file.
 * - onImageChange (function): Function to call when the image is uploaded or removed. 
 * 
 * File Name: ImageUpload.tsx
 * Author: [Your Name]
 * Date: 27-07-2024
 */

import React, { ChangeEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";

// Define a type for the image state
type ImageState = string | ArrayBuffer | null;

interface IImgaeUploadProps {
    image?: File | null | string;
    onImageChange: (file: File | null) => void;
}

const ImageUpload: React.FC<IImgaeUploadProps> = ({ image, onImageChange }) => {
    const [preview, setPreview] = useState<ImageState>(null);

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
            onImageChange(file); // Update the file state
        }

        event.target.value = "";
    };

    const handleRemoveImage = () => {
        onImageChange(null); // Clear the image in the parent
        setPreview(null);
    };

    useEffect(() => {
        if (image) {
            if (typeof image === "string") {
                setPreview(`${process.env.REACT_APP_API_BASEURL}${image}`);
            } else if (image instanceof File) {
                // Handle File object
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreview(reader.result as string); // Ensure result is cast to string
                };
                reader.readAsDataURL(image); // Read the file as a data URL
            }
        }
    }, [image]);

    return (
        <div className="product-image-upload">
            <div className="upload-label relative">
                {preview && (
                    <button className="remove-image-button" onClick={handleRemoveImage}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                )}
                <label htmlFor="imageUpload">
                    {preview ? (
                        <>
                            <img
                                src={preview as string}
                                alt="Product" className="uploaded-image" />
                            <div className="image-options">
                            </div>
                        </>
                    ) : (
                        <div className="upload-placeholder">
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                    )}
                </label>
            </div>
            <input
                type="file"
                id="imageUpload"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default ImageUpload;