import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faBell } from "@fortawesome/free-solid-svg-icons";
import adminIcon from '../../assets/images/admin-panel-icon.png';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';

const AdminHeader: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);

    return (
            <header className='admin-header'>
                {/* <div className='admin-header-search'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                    <input className='font-size-15px font-Poppins-SemiBold' type="search" />
                </div> */}
                <div className='admin-header-right'>
                    {/* <div className='admin-header-notification'><FontAwesomeIcon icon={faBell} /><span className='font-size-15px font-Poppins-SemiBold'>Notifications</span></div> */}
                    <div className='admin-header-profile'><img src={adminIcon} alt="" /><span className='font-size-15px font-Poppins-SemiBold'>{userDetails?.userName || "Admin"}</span></div>
                </div>
            </header>
    );
};

export default AdminHeader;