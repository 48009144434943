/**
 * RecentTest Component
 * 
 * Description: The main purpose of this component is to display the latest 4 health test results for a user. It fetches the test results based on the provided user ID, handles loading states, and provides navigation links to view detailed results based on the user's role.
 * 
 * File Name: RecentTest.tsx
 * Date: 26-08-2024
 */

import { RootState } from '@store/store';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { customer, operator, superAdmin } from 'src/lib/constans';

interface ILatestFour { name: string; _id: string;[key: string]: any };

const RecentTest: React.FC<{ id: string }> = ({ id }) => {
  const { userDetails } = useSelector((store: RootState) => store.appReducer);

  const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage loading status
  const [data, setData] = useState<ILatestFour[]>([]); // State to hold the fetched health test results data

  // Navigate to the appropriate result page based on user role
  const handleNavigate = (url: string): string => {
    if (userDetails?.userRole === customer) {
      return `/test-result/${url}/view`;
    } else if (userDetails?.userRole === superAdmin || userDetails?.userRole === operator) {
      return `/result/${url}/view`;
    } else {
      return "";
      // Future role-based navigation can be handled here
    }
  };

  //API_CALL: Fetch health profile data when the component mounts or user id changes
  useEffect(() => {
    if (id) {
      (async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: ILatestFour[] }>({ path: `/test/recent/${id}` });
        if (res) {
          setData(res?.data || []);
        } else {
          toast(err, { type: 'error' });
        }
        setIsLoader(false);
      })();
    }
  }, [id]);
  return (
    <div className="parent-box">
      <div className="health-system-w">
        <h2 className="font-size-20px theme-color-green font-Poppins-SemiBold">Recent Test at a Glance</h2>
        <div className='sports-hormone'>
          {data && data.length ? data.map((item: ILatestFour, index: number) => {
            return (
              <div className='sports-hormone-profile-view' key={index}>
                <h2 className='font-size-18px text-[#232323] font-Poppins-Medium'>{item?.name || ""}</h2>
                <Link className='font-size-16px text-white font-Poppins-SemiBold' to={handleNavigate(item?._id || "")}>View Result</Link>
              </div>
            )
          }): null}

          {
            !data.length &&
            <p className="font-Poppins-Regular font-size-18px leading-normal pr-10">No Recent Test.</p>
          }
        </div>
      </div>
    </div>
  );
};

export default RecentTest;