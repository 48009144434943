/**
 * EducationEdit Component
 * 
 * Description: This component allows users to manage their education history. It provides a form for entering details about educational institutions, courses, start and end dates. Users can add or remove education entries and submit updates to the server. The component fetches initial data from the Redux store and updates it upon successful submission.
 * 
 * File Name: EducationEdit.tsx
 * Date: 02-09-2024
 */

import React, { FormEvent, useEffect, useState } from 'react';
import { RootState } from '@store/store';
import moment from 'moment';
import { updateDoctorField } from '../../../Store/doctor';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PageHeader from 'src/Components/Customer/PageHeader';
import PreviewProfileButton from 'src/Components/Customer/PreviewProfileButton';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SimpleInput from 'src/Components/SimpleInput';
import httpRequest from 'src/helpers/httpRequest';
import { setUniqueData } from "src/Store/reducer";
import { IEducationsHistory } from 'src/types/GlobalInterfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const emptyObject = {
    institution: "",
    course: "",
    startDate: "",
    endDate: "",
};

const EducationEdit: React.FC = () => {
    const { profile } = useSelector((store: RootState) => store.doctor);
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [education, setEducation] = useState<IEducationsHistory[]>([emptyObject]);

    /**
    * Handles changes to individual fields in the education form.
    * 
    * @param key - The key of the field being updated.
    * @param value - The new value for the field.
    * @param index - The index of the item being updated.
    */
    const handleChange = (key: keyof IEducationsHistory, value: string, index: number): void => {
        setEducation(
            prev =>
                prev.map((v, i) => i === index ? { ...v, [key]: value } : v)
        );
    };

    /**
    * Adds a new education entry or removes an existing one.
    * 
    * @param index - The index of the entry to be removed, or undefined to add a new entry.
    */
    const handleAddOrRemove = (index?: number) => {
        if (index) { // if index include the treat like a remove
            setEducation(
                prev =>
                    prev.filter((_, i) => i !== index)
            );
        } else {
            setEducation(prev => [...prev, emptyObject]);
        }
    };

    /**
     * Handles the form submission to update education history.
     * 
     * @param event - The form submission event.
     */
    // API_CALL: Updates the user's "education" section with new data
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: IEducationsHistory[]; percent: number }>({ method: "post", path: "/provider/education", params: { education } });
        if (res) {
            const { message, data, percent } = res;
            toast(message, { type: "success" }); // api success message
            dispatch(updateDoctorField({ education: data }));
            dispatch(setUniqueData({ key: 'profileCompletion', value: String(percent) }));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (profile) setEducation((profile.education && profile.education.length) ? profile.education : [emptyObject]);
    }, [profile]);
    return (
        <>
            <PageHeader
                title="Education"
                isButton={true}
                ButtonComponent={PreviewProfileButton}
            />
            <div className="parent-box minheight-parent-box">
                <p className="font-Poppins-semiBold font-size-18px theme-color-green mb-2">If you have undergone education and training including degrees, courses, diplomas and other qualifications enter them below.</p>
                <form onSubmit={handleSubmit}>
                    {
                        education.map((item: IEducationsHistory, index: number) => (
                            <div key={index}>
                                <SimpleInput
                                    value={item.institution}
                                    onChange={(val) => handleChange("institution", val, index)}
                                    isLabel={true}
                                    labelText={"Institution"}
                                    isRequired={true}
                                    extraStyle={{ borderColor: "#009d91" }}
                                />
                                <SimpleInput
                                    value={item.course}
                                    onChange={(val) => handleChange("course", val, index)}
                                    isLabel={true}
                                    labelText={"Degree/Course"}
                                    isRequired={true}
                                    extraStyle={{ borderColor: "#009d91" }}
                                />
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                    <div>
                                        <SimpleInput
                                            value={moment(item.startDate).format("YYYY-MM-DD")}
                                            onChange={(val) => handleChange("startDate", `${val}T00:00:00Z`, index)}
                                            isLabel={true}
                                            labelText={"Start Date"}
                                            isRequired={true}
                                            extraStyle={{ borderColor: "#009d91" }}
                                            type='date'
                                        />
                                    </div>
                                    <div>
                                        <SimpleInput
                                            value={moment(item.endDate).format("YYYY-MM-DD")}
                                            onChange={(val) => handleChange("endDate", `${val}T00:00:00Z`, index)}
                                            isLabel={true}
                                            labelText={"End Date"}
                                            isRequired={true}
                                            extraStyle={{ borderColor: "#009d91" }}
                                            type='date'
                                        />
                                    </div>
                                </div>
                                {/* remove line not include in first item */}
                                {
                                    index !== 0
                                    &&
                                    <div className="flex justify-end">
                                        <button
                                            className='flex gap-2 items-center'
                                            onClick={() => handleAddOrRemove(index)}
                                            type="button"
                                        >
                                            <FontAwesomeIcon size='sm' icon={faTrash} />
                                            Delete education
                                        </button>
                                    </div>
                                }
                                {education.length !== index + 1 ?
                                    <hr className='my-5' style={{ border: '1px solid #C8C8C8' }} />
                                    : null}
                            </div>
                        ))
                    }
                    <div className="flex justify-end mt-5">
                        <PrimaryBtn
                            loader={isLoader}
                            type="submit"
                            ButtonText="Save"
                            btnExtraStyle={{
                                borderRadius: "1.5rem",
                                backgroundColor: "#004540"
                            }}
                        />
                    </div>
                </form>
                <div className="my-5">
                    <button
                        onClick={() => handleAddOrRemove()}
                        type="button"
                        className="font-Poppins-semiBold font-size-18px theme-color-green">
                        + Add another course/degree
                    </button>
                </div>
            </div>
        </>
    );
};

export default EducationEdit;