/**
 * ChangeDoctorEmail Component
 * 
 * Description: This component allows the user to change their email by 
 * sending a request to update the email address for a specified user ID.
 * 
 * File Name: ChangeDoctorEmail.tsx
 * Date: 04-10-2024
 * 
 * Marker: DOC_START
 */

import React, { FormEvent, useState } from 'react';
import SimpleInput from '../SimpleInput';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';

const ChangeDoctorEmail: React.FC<{ uid: string }> = ({ uid }) => {
    const [email, setEmail] = useState<string>("");
    const [isLoader, setIsLoader] = useState<boolean>(false);

    // API_CALL: Admin-side functionality to change the doctor's email
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: `/customer/reset-email-user`,
            params: { email, userId: uid }
        });
        if (res) {
            toast(res?.message || "Success", { type: 'success' });
            setEmail("");
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="parent-box mb-5">
                <h2 className="title-heading font-Poppins-SemiBold font-size-20px mb-3">Change Email</h2>
                <SimpleInput
                    type="email"
                    value={email}
                    onChange={(val) => setEmail(val)}
                    isLabel={true}
                    labelText="New Email"
                    isRequired={true}
                />
            </div>
            <div className="flex justify-end mb-4">
                <PrimaryBtn
                    loader={isLoader}
                    type="submit"
                    ButtonText="Save"
                    btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem", padding: "0.5rem 2.5rem" }}
                />
            </div>
        </form>
    );
};

export default ChangeDoctorEmail;