/**
 * SingleChat Component
 * 
 * Description: This component manages a chat interface where users can send and receive messages, including text and file attachments, with real-time updates. It also handles user selection, chat history fetching, and file upload functionalities.
 * 
 * File Name: SingleChat.tsx
 * Date: 23-09-2024
 */

import React, { useState, useRef, useEffect, FormEvent, ChangeEvent } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faGear, faMicrophone, faPaperclip, faPaperPlane, faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { useNavigate, useParams } from 'react-router-dom';
import qs from "qs";
import { IChatItem, IqueryParams, ISelectedChatUser } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import moment from 'moment';
import defaultProfile from "src/assets/images/default_profile_image.png";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@store/store';
import { CircularProgress } from '@mui/material';
import { resetSelectedChatUser, setSelectedChatUser, toogleChatLoader, setChatHistory, resetChatHistory, sendNewMessage, setChatList, resetIndividualCount } from 'src/Store/chat';
import { convertToHyperlinks, downloadFile, truncateString } from 'src/helpers/helperFunctions';
import { setCounter } from 'src/Store/reducer';
import Audiocustom from 'src/Components/Customer/Audiocustom';
interface IChatHistoryResponse {
    documents: IChatItem[];
    paginated: IPaginated;
};

const SingleChat: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { userDetails, messageUnreadCount } = useSelector((store: RootState) => store.appReducer);
    const { selectedUser, chatHistory, latestChat } = useSelector((store: RootState) => store.chat);
    const { id = "" } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    /**
     * Toggles the dropdown menu visibility.
     */
    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
    };

    /**
     * Closes the dropdown menu.
     */
    const closeDropdown = () => {
        setIsOpen(false);
    };

    // ******************* SEND NEW MESSAGE ******************* //
    const [txtMsg, setTxtMsg] = useState<string>("");
    const [sendFiles, setSendFiles] = useState<File[]>([]);
    const [sendLoader, setSendLoader] = useState<boolean>(false);

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        let _filesList = e.target.files;
        setSendFiles(Array.from(_filesList || [])); // Handle case when _filesList is null
        e.target.value = "";
    };
    /**
     *API_CALL: Sends a text message to the selected user.
     */
    const sendTextMessage = async () => {
        const { res, err } = await httpRequest<{ message: string; document: IChatItem }>({
            method: "post",
            path: `/chat/message/send`,
            params: {
                receiverId: id,
                messageBody: txtMsg
            },
        });
        if (res) {
            dispatch(sendNewMessage({ data: res.document, receiverId: id }));
            setTxtMsg("");
        } else {
            toast(err, { type: 'error' });
        }
    };

    /**
    *API_CALL: Sends selected files as messages to the selected user.
    */
    const sendFilesMessage = async () => {
        const formData = new FormData();
        sendFiles.forEach(file => {
            formData.append('image', file);
        })
        formData.append('receiverId', id);
        const { res, err } = await httpRequest<{ message: string; document: IChatItem[] }>({
            method: "post",
            path: `/chat/send/file`,
            params: formData,
            header: { 'Content-Type': 'multipart/form-data' }
        });
        if (res) {
            for (let i = 0; i < res.document.length; i++) {
                dispatch(sendNewMessage({ data: res.document[i], receiverId: id }));
            }
            setSendFiles([]);
        } else {
            toast(err, { type: 'error' });
        }
    };

    /**
    * Handles message sending, whether it's a text message or file message.
    */
    const sendMessage = async (event: FormEvent) => {
        event.preventDefault();
        setSendLoader(true);
        if (sendFiles.length > 0) {
            await sendFilesMessage();
        } else {
            await sendTextMessage();
        }
        setSendLoader(false);
    };
    // ******************* SEND NEW MESSAGE ******************* //

    // ******************* CHAT HISTORY ******************* //
    const userChatRef = useRef<HTMLDivElement | null>(null);
    const [fetchLoader, setFetchLoader] = useState<boolean>(false);
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 500,
    });
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination

    /**
    * Scrolls to the bottom of the chat history.
    */
    const scrollBottom = () => {
        if (userChatRef.current) {
            userChatRef.current.scrollTop = userChatRef.current.scrollHeight;
        }
    };

    /**
     *API_CALL: Fetches the chat history for the selected user.
     */
    const fetchChatHistory = async () => {
        setFetchLoader(true);
        const { res, err } = await httpRequest<IChatHistoryResponse>({
            path: `/chat/all/messages/${id}?${qs.stringify(queryParams)}`,
        });
        if (res) {
            let { documents = [], paginated } = res;
            dispatch(setChatHistory(documents.reverse())); // in future add pagination and sort
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setFetchLoader(false);
    };

    useEffect(() => {
        scrollBottom();
    }, [chatHistory]);

    useEffect(() => {
        fetchChatHistory();

        return () => {
            dispatch(resetChatHistory());
        }
    }, [id, queryParams]);
    // ******************* CHAT HISTORY ******************* //

    // ******************* SET SELECTED CHAT USER ******************* //

    // ******************* DELETE CONVERSATION ******************* //
    const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
    /**
     *API_CALL: Deletes the specified conversation from the database.
     */
    const handleDeleteConversation = async () => {
        setDeleteLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "delete",
            path: `/chat/clear/${id}`,
            params: {}
        });
        if (res) {
            toast(res?.message || "Conversation deleted Successfully ", { type: 'success' });
            navigate("/message");
        } else {
            toast(err, { type: 'error' });
        }
        setDeleteLoader(false);
    };
    // ******************* DELETE CONVERSATION ******************* //

    // ******************* BLOCK STATES & FUNCTIONS ******************* //
    const [addBlockLoader, setAddBlockLoader] = useState<boolean>(false);
    /**
     *API_CALL: block the specified user from the conversation.
     */
    const blockUser = async () => {
        setAddBlockLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            path: `/block/person/${id}`,
        });
        if (res) {
            const newArr = latestChat.filter(v => v._id !== id);
            dispatch(setChatList(newArr));
            toast(res?.message || "User block Successfully ", { type: 'success' });
            navigate("/message");
        } else {
            toast(err, { type: 'error' });
        }
        setAddBlockLoader(false);
    };
    // ******************* BLOCK STATES & FUNCTIONS ******************* //

    /**
     *API_CALL: Fetches the selected user's information.
     */
    const fetchSelectedUser = async () => {
        dispatch(toogleChatLoader(true));
        const { res, err } = await httpRequest<{ message: string; documents: ISelectedChatUser[] }>({
            path: `/chat/profile/${id}`,
        });
        if (res) {
            dispatch(setSelectedChatUser(res.documents[0]));
        } else {
            toast(err, { type: 'error' });
        }
        dispatch(toogleChatLoader(false));
    };
    useEffect(() => {
        fetchSelectedUser();
        dispatch(resetIndividualCount({ id }));
        dispatch(setCounter({
            key: 'messageUnreadCount',
            val: messageUnreadCount ? messageUnreadCount - (latestChat.find(v => v._id === id)?.unRead || 0) : 0,
        }));

        return () => {
            dispatch(resetSelectedChatUser());
        }
    }, [id]);
    // ******************* SET SELECTED CHAT USER ******************* //

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                closeDropdown();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const removeFiles = (_name: string) => {
        const updatedFiles = sendFiles.filter(file => file.name !== _name);
        setSendFiles(updatedFiles);
    };
    const handleSubmit = (event: React.SyntheticEvent, file: File) => {
        console.log('Audio file submitted:', file);
        // You can also add any other dummy logic here
    };
    return (
        <section className="flex flex-col justify-between bg-[#EFEFEF]">
            <div className="chat-header">
                <div className='chat-header-inner-left'>
                    <img
                        alt="Profile"
                        className="rounded-full"
                        src={`${process.env.REACT_APP_API_BASEURL}${selectedUser?.photo}`}
                        onError={(e) => {
                            const target = e.target as HTMLImageElement; // Assert the type
                            if (target) {
                                target.src = defaultProfile;
                            }
                        }}
                    />
                    <div>
                        <div className='flex items-center gap-2'>
                            <h2 className="font-Poppins-SemiBold font-size-15px capitalize">{`${selectedUser?.firstName || ""} ${selectedUser?.lastName || ""}`}</h2>
                            {/* <span className='text-[#00443F] font-Poppins-Regular font-size-12px'>typing...</span> */}
                        </div>
                        <p className="font-Poppins-regular font-size-13px">{truncateString(selectedUser?.about || "-", 50)}</p>
                    </div>
                </div>

                <div className='chat-header-inner-right relative'>
                    {/* <div className='chat-header-search-bar'>
                        <input className='font-Poppins-Medium font-size-14px' type="search" placeholder="Search" />
                        <FontAwesomeIcon className='font-size-16px' icon={faMagnifyingGlass} />
                    </div> */}
                    <div ref={dropdownRef}>
                        <FontAwesomeIcon
                            onClick={toggleDropdown}
                            className='font-size-22px text-[#707070]'
                            icon={faGear}
                        />
                        {isOpen && (
                            <div className="dropdown-box-header-chat min-w-[180px]">
                                <button disabled={deleteLoader} onClick={handleDeleteConversation} className='font-Poppins-Medium font-size-14px flex items-center gap-2'>Delete Conversation {deleteLoader && <CircularProgress size={14} style={{ color: "#004540" }} />}</button>
                                <button disabled={addBlockLoader} onClick={blockUser} className="font-Poppins-Medium font-size-14px flex items-center gap-2">Block User {addBlockLoader && <CircularProgress size={14} style={{ color: "#004540" }} />}</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='chat-body' ref={userChatRef}>
                {fetchLoader &&
                    <div className="text-center">
                        <CircularProgress size={30} style={{ color: "#004540" }} />
                    </div>
                }
                {chatHistory.map((item: IChatItem, index: number) => (
                    <div key={index} className={`mb-4 ${item.senderId === id ? "chat-body-user-mess-left" : "chat-body-user-mess-right"}`}>
                        <img
                            alt="profile-picture"
                            src={`${item.senderId === userDetails?._id ?
                                process.env.REACT_APP_API_BASEURL + userDetails?.coverPhoto
                                : process.env.REACT_APP_API_BASEURL + (selectedUser?.photo || "")
                                }`}
                            onError={(e) => {
                                const target = e.target as HTMLImageElement; // Assert the type
                                if (target) {
                                    target.src = defaultProfile;
                                }
                            }}
                        />
                        <div className='chat-body-user-mess-inner'>
                            <span className={`font-Poppins-regular font-size-16px ${item?.senderId === userDetails?._id ? "text-black" : "text-black"}`} style={{ wordBreak: "break-word" }}>
                                {item.type === "text" ?
                                    <span
                                        dangerouslySetInnerHTML={{ __html: convertToHyperlinks(item?.messageBody) }}
                                    />
                                    : item.type?.startsWith('image/') ?
                                        <img
                                            src={process.env.REACT_APP_API_BASEURL + item.fileUrl}
                                            alt="file"
                                            className='chat-file-img'
                                        />
                                        : item.type?.startsWith('audio/') ?
                                            <audio
                                                controls
                                                src={process.env.REACT_APP_API_BASEURL + item.fileUrl}
                                                className='chat-file-audio'
                                            />
                                            : item.type?.startsWith('video/') ?
                                                <video
                                                    controls
                                                    src={process.env.REACT_APP_API_BASEURL + item.fileUrl}
                                                    className="chat-file-video"
                                                />
                                                :
                                                <button onClick={() => downloadFile(item.fileUrl)} className="flex gap-2 items-center">
                                                    <b>{item.messageBody}</b>
                                                    <svg
                                                        fill="none"
                                                        height={15}
                                                        width={15}
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        style={{ display: "inline-block", verticalAlign: "middle" }}
                                                    >
                                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                                        <polyline points="7 10 12 15 17 10" />
                                                        <line x1={12} y1={15} x2={12} y2={3} />
                                                    </svg>
                                                </button>
                                }
                            </span>
                            <span className='font-Poppins-regular date-msg '>{moment(item.createdAt).format("hh:mm a")}</span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="chat-footer">
                <label htmlFor="fileInput" className='flex justify-center items-center cursor-pointer'>
                    <FontAwesomeIcon className='font-size-26px text-[#707070]' icon={faPaperclip} />
                </label>
                <input type='file' id="fileInput" style={{ display: 'none' }} multiple onChange={onFileChange} />
                <form onSubmit={sendMessage} className='chat-footer-message-sent-input relative'>
                    {sendFiles.length ? (
                        <div className="selected-files-main">
                            {sendFiles.map((item: File, index: number) => (
                                <div key={index} className="selected-files-inner">
                                    <span className='font-Poppins-regular font-size-13px'>{truncateString(item.name || "", 20)}</span>
                                    <button type="button" onClick={() => removeFiles(item.name)} className="cancel-file-button">
                                        <FontAwesomeIcon icon={faXmark} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <input
                        className='font-Poppins-Medium font-size-16px'
                        type="text"
                        readOnly={sendFiles.length ? true : false}
                        value={sendFiles.length ? sendFiles.map(v => truncateString(v.name || "", 20)) : txtMsg}
                        onChange={(e) => setTxtMsg(e.target.value)}
                        placeholder='Type Message...'
                        required
                    />
                    <button disabled={sendLoader} type='submit' className='message-sent'>
                        <span className={sendLoader ? "animate-pulse" : ""}>
                            <FontAwesomeIcon
                                className='text-white font-size-22px'
                                icon={sendLoader ? faEllipsisH : faPaperPlane}
                            />
                        </span>
                    </button>
                </form>
                {/* <label htmlFor="microphone" className='flex justify-center items-center cursor-pointer'>
                    <FontAwesomeIcon className='font-size-26px text-[#707070]' icon={faMicrophone} />
                </label> */}
                <Audiocustom
                    receiverId={id}
                />
            </div>
        </section>
    );
};

export default SingleChat;