/**
 * SpecializationEdit
 * 
 * Description: A React component for managing and updating a doctor's specializations.
 *              It allows users to select specializations from a dropdown, displays 
 *              selected items as chips, and handles form submission to save changes.
 * 
 * File Name: SpecializationEdit.tsx
 * Date: 02-09-2024
 */

import React, { FormEvent, useEffect, useState } from 'react';
import { RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PageHeader from 'src/Components/Customer/PageHeader';
import PreviewProfileButton from 'src/Components/Customer/PreviewProfileButton';
import SelectionChip from 'src/Components/Customer/SelectionChip';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import httpRequest from 'src/helpers/httpRequest';
import { IDropDownArray } from 'src/types/GlobalInterfaces';
import { updateDoctorField } from '../../../Store/doctor';
import { setUniqueData } from "src/Store/reducer";

/**
 * ISpecialItem Interface
 * 
 * Description: Represents a specialization item fetched from the server.
 */
interface ISpecialItem {
    name: string;
    _id: string;
};

/**
 * SpecializationEdit Component
 * 
 * Description: This component renders the form to select and update the doctor's specializations.
 */
const SpecializationEdit: React.FC = () => {
    const { profile } = useSelector((store: RootState) => store.doctor); // Extract the user's profile from the Redux store
    const dispatch = useDispatch();

    // Local state management
    const [isError, setisError] = useState<string>("");
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [fetchLoader, setFetchLoader] = useState<boolean>(false);
    const [allSpecialization, setAllSpecialization] = useState<IDropDownArray[]>([]);
    const [selectedSpecialization, setSelectedSpecialization] = useState<IDropDownArray[]>([]);

    /**
   * handleSubmit
   * 
   * Description: Handles form submission. Validates the selected specializations and
   *              sends a POST request to update the specializations in the backend.
   * 
   * @param event - FormEvent to prevent the default form submission behavior.
   */
    // API_CALL: Updates the user's "specailizedDetails" section with new data
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (!selectedSpecialization.length) {
            setisError("Please select atleast one Specialization.");
            setTimeout(() => {
                setisError("");
            }, 3000);
            return
        }
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: { _id: string }[]; percent: number }>({
            method: "post", path: "/provider/update-specializations", params: {
                userSpeicalization: selectedSpecialization.map(v => ({ _id: v.value }))
            }
        });
        if (res) {
            const { message, data, percent } = res;
            toast(message, { type: "success" }); // api success message
            const newArr = data.map(v => ({ _id: v._id, name: allSpecialization.find(item => item.value === v._id)?.label || "" })); // Map the response data to the format expected by the Redux store
            dispatch(updateDoctorField({ specailizedDetails: newArr })); // Dispatch an action to update the specailizedDetails in the Redux store
            dispatch(setUniqueData({ key: 'profileCompletion', value: String(percent) }));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    /**
    * fetchAllSpecialization
    * 
    * Description: Fetches all available specializations from the server
    *              and sets the state to populate the dropdown menu.
    */
    const fetchAllSpecialization = async () => {
        setFetchLoader(true);
        const { res, err } = await httpRequest<ISpecialItem[]>({ path: "/provider/specialization/get" });
        if (res) {
            setAllSpecialization(res.map(v => ({ label: v.name, value: v._id })));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setFetchLoader(false);
    };

    /**
     * useEffect - Profile Update
     * 
     * Description: Populates the selected specializations when the profile data is available.
     */
    useEffect(() => {
        if (profile) setSelectedSpecialization(
            profile.specailizedDetails
                ?
                profile.specailizedDetails.map(v => ({ value: v._id, label: v.name }))
                :
                []
        );
    }, [profile]);

    /**
     * useEffect - Fetch Specializations
     * 
     * Description: Fetches all available specializations when the component is mounted.
     */
    useEffect(() => {
        fetchAllSpecialization(); // Invoke the function to fetch specialization
    }, []);

    return (
        <>
            <PageHeader
                title="Specialization"
                isButton={true}
                ButtonComponent={PreviewProfileButton}
            />
            <div className="parent-box minheight-parent-box">
                <p className="font-Poppins-semiBold font-size-18px theme-color-green mb-2">Enter the Specialization that you offer.</p>
                <form onSubmit={handleSubmit}>
                    <SelectDropdown
                        loader={fetchLoader}
                        options={allSpecialization}
                        isMulti={true}
                        data={selectedSpecialization}
                        setData={setSelectedSpecialization}
                        checkboxShow={false}
                        isLabel={true}
                        labelText="Name"
                        isRequired={true}
                        extraClass={"border__dropdown__primary"}
                    />

                    {isError && <p className="font-size-14 text-[#ff0000]">{isError}</p>}

                    <div className="my-5 flex flex-wrap gap-3">
                        {selectedSpecialization.map((v: IDropDownArray) => (
                            <SelectionChip
                                title={v.label}
                                onDelete={() => setSelectedSpecialization(item => item.filter(special => special.value !== v.value))}
                            />
                        ))}
                    </div>

                    <div className="flex justify-end">
                        <PrimaryBtn
                            type={"submit"}
                            loader={isLoader}
                            ButtonText='Save'
                            btnExtraStyle={{
                                borderRadius: "100rem",
                                backgroundColor: "#004540"
                            }}
                        />
                    </div>
                </form>
            </div>
        </>
    );
};

export default SpecializationEdit;