/**
 * PaginationComponet Component
 * 
 * Description: This component provides pagination functionality using Material-UI's Pagination and PaginationItem components. It allows navigating through multiple pages of content with a customizable theme.
 * 
 * File Name: PaginationComponet.tsx
 * Date: 23-07-2024
 */

import React from 'react';
import Pagination from "@mui/material/Pagination";
import PaginationItem from '@mui/material/PaginationItem';
import { createTheme, ThemeProvider } from "@mui/material/styles";

interface IPaginationProps {
    currentPage?: number;
    total?: number;
    setCurrentPage: (page: number) => void; // Type for setCurrentPage function
};

const PaginationComponet: React.FC<IPaginationProps> = ({
    currentPage = 1,
    total = 1,
    setCurrentPage = () => { }
}) => {
    // Create a custom theme for the pagination component
    const customTheme = createTheme({
        palette: {
            primary: {
                main: "#00443F",
            },
        },
    });

    // Handle page change event
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    return (
        <>
            <div className="mt-4 pb-2">
                {total > 1 ?
                    <ThemeProvider theme={customTheme}>
                        <Pagination
                            count={total}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            renderItem={(item) => {
                                const isPrevButton = item.type === 'previous';
                                const isNextButton = item.type === 'next';
                                const isDisabled = (isPrevButton && currentPage === 1) || (isNextButton && currentPage === total);
                                const isCurrentPage = item.page === currentPage;
                                return (
                                    <PaginationItem
                                        {...item}
                                        disabled={isDisabled || isCurrentPage} // Disable prev/next buttons and the current page button
                                        className={isCurrentPage ? "activeBtn" : ""}
                                    />
                                );
                            }}
                        />
                    </ThemeProvider>
                    :
                    null
                }
            </div>
        </>
    );
};

export default PaginationComponet;