/**
 * HealthVitals.tsx
 * 
 * 
 * Description: 
 * This component renders the health vitals section of a user’s health profile.
 * It includes a page header, health profile tabs, and User vitals information.
 * The component uses data from the Redux store to get the user's details.
 * 
 * Date: 22-08-2024
*/

import { RootState } from '@store/store';
import React from 'react';
import { useSelector } from 'react-redux';
import HealthProfileTabs from 'src/Components/Customer/HealthProfileTabs';
import PageHeader from 'src/Components/Customer/PageHeader';
import UserVitals from 'src/Components/GlobalComponents/HealthProfiles/UserVitals';

const HealthVitals: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer); // Get user details from the Redux store
    const uid = userDetails?._id || ""; // Extract user ID or set to an empty string if not available

    return (
        <>
            {/* Render the page header with the title "Health Profile" */}
            <PageHeader
                title="Health Profile"
            />
            <div className="parent-box parent-box-height">
                {/* Render tabs for navigating the health profile sections */}
                <HealthProfileTabs />
                {/* Render the user vitals information component, passing the user ID */}
                <UserVitals />
            </div>
        </>
    );
};

export default HealthVitals;