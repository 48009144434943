/**
 * WithDrawal Component
 * 
 * Description: This component handles the withdrawal requests of doctors, 
 * allowing the admin to view, approve, and decline withdrawal requests.
 * 
 * File Name: WithDrawal.tsx
 * Date: 30-09-2024
 */

import React, { useEffect, useState } from 'react';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import httpRequest from 'src/helpers/httpRequest';
import { IqueryParams } from 'src/types/GlobalInterfaces';
import qs from "qs";
import { IPaginated } from 'src/types/paginated';
import moment from 'moment';
import DeclineModalReason from 'src/Components/AdminComponents/DeclineModalReason';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';

interface IItem {
    _id: string;
    doctorId: string;
    firstName: string;
    lastName: string;
    accountHolderName: string;
    iban: string;
    swiftCode: string;
    amount: string;
    createdAt: string;
    transactionNumber: string;
    attachment?: string;
    reason?: string;
};

interface IWithDrawalResponse {
    documents: IItem[];
    paginated: IPaginated;
};

const WithDrawal: React.FC = () => {
    // List of tab names for filtering orders
    const tabList: string[] = ["completed", "pending", "declined"];
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [data, setData] = useState<IItem[]>([]);
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        status: "completed",
        search: "",
    });

    // Handles tab click to filter orders
    const handleTabClick = (tabName: string) => {
        setQueryParams(prev => ({
            ...prev,
            status: tabName,
            page: 1,
        }));
    };

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            search: txt,
            page: 1
        }));
    };

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    const [declinedModal, setDeclinedModal] = useState<boolean>(false);
    const [declineLoader, setDeclineLoader] = useState<boolean>(false);
    const [declinedId, setDeclinedId] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const openDeclinedModal = (id: string): void => {
        setDeclinedModal(true);
        setDeclinedId(id);
    };
    const closeDeclinedModal = (): void => {
        setDeclinedModal(false);
        setDeclinedId("");
        setReason("");
    };
    // API_CALL: Confirm declined withdrawal
    const confirmDeclined = async () => {
        if (!reason) {
            toast("Please provide a reason.", { type: 'error' });
            return;
        }
        setDeclineLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: "/paymentandwithdraw/declined",
            params: {
                withdrawId: declinedId,
                reason
            },
        });
        if (res) {
            toast(res.message || "Success", { type: 'success' });
            closeDeclinedModal();
            setQueryParams(prev => ({
                ...prev,
                page: 1,
                status: "declined"
            }));
        } else {
            toast(err, { type: 'error' });
        }
        setDeclineLoader(false);
    };
    // API_CALL: Fetch withdrawal data
    const fetchWithDrawal = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IWithDrawalResponse>({ path: `/paymentandwithdraw/all?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setData(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchWithDrawal();
    }, [queryParams]);

    const BackButton: React.FC = () => {
        return (
            <div className='mt-2 flex justify-center'>
                <PrimaryBtn
                    onPress={confirmDeclined}
                    type="button"
                    loader={declineLoader}
                    ButtonText='Send'
                    btnExtraStyle={{
                        borderRadius: "100rem",
                        backgroundColor: "#004540"
                    }}
                />
            </div>
        );
    }; // display back icon in page header

    return (
        <>
            <BreadCrumb
                current="Withdrawal"
                previous="dashboard"
                navigateUrl="/admin"
                btnShow={false}
            />
            <div className="withdrawal-requests">
                <DeclineModalReason
                    title="Please Specify the reason of Decline"
                    value={reason}
                    setValue={(val) => setReason(val)}
                    isOpen={declinedModal}
                    onClose={closeDeclinedModal}
                    ButtonComponent={BackButton}
                />
                <div className="table-container">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                        <div className='products-tabs-btn md:col-span-2 col-span-1'>
                            {tabList.map((v: string) => (
                                <button
                                    key={v}
                                    className={`font-size-15px font-Poppins-Medium ${queryParams.status === v && 'active'}`}
                                    onClick={() => handleTabClick(v)}
                                >
                                    {
                                        v === "completed" ? `Withdrawal List` :
                                            v === "pending" ? `Withdrawal Requests` :
                                                v === "declined" ? `Declined Requests` :
                                                    ""
                                    }
                                </button>
                            ))}
                        </div>
                        <div className='col-span-1'>
                            <SearchFilterInput
                                onChangeText={handleChangeText}
                                inputExtraStyle={{ marginBottom: 0, width: 240 }}
                            />
                        </div>
                    </div>
                    <hr className='my-4' style={{ border: '1px solid #D4D4D4' }} />
                    {queryParams.status === "completed"
                        ?
                        <TableWrapper
                            isLoading={isLoader}
                            page={paginated?.currentPage || 1}
                            total={paginated?.totalPages || 0}
                            thead={["S.No", "Doctor Name", "Account Details", "Amount", "TID Number", "Proof Document", "Action"]}
                            onChange={handlePageChange}
                            columnWidth={"6% 19% 29% 12% 12% 12% 10%"}
                        >
                            <div className='tbody-content-height-auto'>
                                {data?.map((item: IItem, index: number) => (
                                    <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 19% 29% 12% 12% 12% 10%" }}>
                                        <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                        <li className="capitalize">
                                            <Link to={`/doctor/${item?.doctorId}/View`} className="underline">
                                                {`${item?.firstName || ""} ${item?.lastName || ""}`}
                                            </Link>
                                        </li>
                                        <li>
                                            <p>Acount Title : {item?.accountHolderName || ""}</p>
                                            <p>IBAN Number : {item?.iban || ""}</p>
                                            <p>BIC (or Swift) : {item?.swiftCode || ""}</p>
                                        </li>
                                        <li>AED {item?.amount || ""}</li>
                                        <li>
                                            <span className="break-all">{item?.transactionNumber || ""}</span>
                                        </li>
                                        <li style={{ justifyContent: 'center',paddingLeft:0 }}>
                                            <img className='img-recepit'
                                                src={`${process.env.REACT_APP_API_BASEURL}${item?.attachment}`}
                                                alt="attachement"
                                            />
                                        </li>
                                        <li className="action-last-colum justify-center">
                                            <Link to={`/withdrawal/${item?._id}/view`}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </Link>
                                        </li>
                                    </ul>
                                ))}
                            </div>
                        </TableWrapper>
                        :
                        queryParams.status === "pending" ?
                            <TableWrapper
                                isLoading={isLoader}
                                page={paginated?.currentPage || 1}
                                total={paginated?.totalPages || 0}
                                thead={["S.No", "Doctor Name", "Account Details", "Amount", "Request Date", "Action"]}
                                onChange={handlePageChange}
                                columnWidth={"6% 19% 35% 12% 13% 15%"}
                            >
                                <div className='tbody-content-height-auto'>
                                    {data?.map((item: IItem, index: number) => (
                                        <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 19% 35% 12% 13% 15%" }}>
                                            <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                            <li className="capitalize">
                                                <Link to={`/doctor/${item?.doctorId}/View`} className="underline">
                                                    {`${item?.firstName || ""} ${item?.lastName || ""}`}
                                                </Link>
                                            </li>
                                            <li>
                                                <p>Acount Title : {item?.accountHolderName || ""}</p>
                                                <p>IBAN Number : {item?.iban || ""}</p>
                                                <p>BIC (or Swift) : {item?.swiftCode || ""}</p>
                                            </li>
                                            <li>AED {item?.amount || ""}</li>
                                            <li>{moment(item?.createdAt || "").format("DD-MM-YYYY")}</li>
                                            <li className="justify-center">
                                                <div className="flex gap-2">
                                                    <button onClick={() => openDeclinedModal(item._id)} className="font-size-13px leading-3 font-Poppins-Medium rounded py-1 px-2 border-[1px] bg-[#cc2929] text-[#cc2929] text-[#fff]">Decline</button>
                                                    <Link to={`/withdrawal/${item._id}/view`} className="font-size-13px leading-3 font-Poppins-Medium rounded py-1 px-2 border-[1px] border-[#00443f] bg-[#00443f] text-[#00443f] text-[#fff]">Approve</Link>
                                                </div>
                                            </li>
                                        </ul>
                                    ))}
                                </div>
                            </TableWrapper>
                            :
                            queryParams.status === "declined" ?
                                <TableWrapper
                                    isLoading={isLoader}
                                    page={paginated?.currentPage || 1}
                                    total={paginated?.totalPages || 0}
                                    thead={["S.No", "Doctor Name", "Account Details", "Decline Reason", "Amount", "Requested Date"]}
                                    onChange={handlePageChange}
                                    columnWidth={"6% 18% 25% 25% 12% 12%"}
                                >
                                    <div className='tbody-content-height'>
                                        {data?.map((item: IItem, index: number) => (
                                            <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 18% 25% 25% 12% 12%" }}>
                                                <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                                <li className="capitalize">
                                                    <Link to={`/doctor/${item?.doctorId}/View`} className="underline">
                                                        {`${item?.firstName || ""} ${item?.lastName || ""}`}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <p>Acount Title : {item?.accountHolderName || ""}</p>
                                                    <p>IBAN Number : {item?.iban || ""}</p>
                                                    <p>BIC (or Swift) : {item?.swiftCode || ""}</p>
                                                </li>
                                                <li>{item?.reason || ""}</li>
                                                <li>AED {item.amount}</li>
                                                <li>{moment(item.createdAt).format("DD-MM-YYYY")}</li>
                                            </ul>
                                        ))}
                                    </div>
                                </TableWrapper>
                                :
                                ""
                    }
                </div>
            </div>
        </>
    );
};

export default WithDrawal;