/**
 * EarningsGraph Component
 * 
 * Description: This component displays a bar chart that represents monthly earnings 
 * from appointments and tests for selected years. Data is fetched from an API based 
 * on the selected year and displayed using the Recharts library.
 * 
 * Props: None
 * 
 * File Name: EarningsGraph.tsx
 * Date: 01-10-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';

// Interface for the individual monthly appointment data
interface IMonthlyAppointment {
    _id: {
        year: number;
        month: number;
    };
    count: number;
    totalAmount: number;
}

// Main interface for the entire response
interface IResponseData {
    result1: {
        monthlyAppoinment: IMonthlyAppointment[];
    };
    testData: {
        monthlyTest: IMonthlyAppointment[];
    }[];
}

const EarningsGraph: React.FC = () => {
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [selectedYear, setSelectedYear] = useState<number>(2024);
    const [data, setData] = useState<{ name: string; appointment: number; test: number }[]>([]);

    //API_CALL: Fetch data from the API
    const fetch = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IResponseData>({ path: `/appoiment/earning?year=${selectedYear}` });
        if (res) {
            const { result1, testData } = res;
            // Initialize an array for all 12 months with default values
            const chartData = Array.from({ length: 12 }, (_, index) => {
                const month = index + 1; // Months are 1-indexed
                return {
                    name: `${month}-${selectedYear}`,
                    appointment: result1.monthlyAppoinment.find(v => v._id.month === month)?.totalAmount || 0,
                    test: testData.flatMap(test => test.monthlyTest).find(v => v._id.month === month)?.totalAmount || 0,
                };
            });
            setData(chartData);

        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetch();
    }, [selectedYear]);

    const renderLegend = (value: string, entry: any) => {
        const { color } = entry;
        return (
            <span className="custom-legend-item">
                <span className="color-box" style={{ backgroundColor: color }}></span>
                {value}
            </span>
        );
    };
    return (
        <div>
            <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">Earning</h2>
            <div className='user-graph-inner'>
                <div className='flex justify-between items-center'>
                    <div className='flex gap-2 mb-3'>
                        <span className="flex items-center gap-1">
                            <div className='bg-[#00a297] w-[8px] h-[8px] rounded-full'></div>
                            <span className="font-Poppins-Regular font-size-16px">Appointment</span>
                        </span>
                        <span className="flex items-center gap-1">
                            <div className='bg-[#00443f] w-[8px] h-[8px] rounded-full'></div>
                            <span className="font-Poppins-Regular font-size-16px">Test</span>
                        </span>
                    </div>
                    {/* <div>
                        <select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(Number(e.target.value))}
                            className='font-Poppins-SemiBold user-graph-select theme-color-green font-size-14px'
                        >
                            <option className='font-Poppins-Medium font-size-14px' value="2024">2024</option>
                            <option className='font-Poppins-Medium font-size-14px' value="2023">2023</option>
                        </select>
                    </div> */}
                    <div className='select-container-graph'>
                        <SelectDropdown
                            options={[{ label: "2024", value: "2024" }, { label: "2023", value: "2023" }]}
                            isMulti={false}
                            data={[{ label: String(selectedYear), value: selectedYear }]}
                            setData={(e) => e[0] ? setSelectedYear(Number(e[0]?.value)) : ""}
                            checkboxShow={false}
                            isLabel={false}
                            isRequired={true}
                            clearIcon={false}
                        />
                    </div>
                </div>
                {
                    isLoader
                    &&
                    <div className="text-center">
                        <CircularProgress size={20} style={{ color: "#004540" }} />
                    </div>
                }
                <ResponsiveContainer className={'user-graph-container'}>
                    <BarChart
                        data={data}
                        margin={{
                            top: 20, right: 0, left: 0, bottom: 0,
                        }}
                        barCategoryGap="15%"
                    >
                        <CartesianGrid />
                        <XAxis className='revenue-axis' dataKey="name" />
                        <YAxis className='revenue-axis' />
                        <Tooltip wrapperClassName='revenue-axis' />
                        <Legend
                            formatter={renderLegend}
                            verticalAlign="top"
                            align="center"
                        />
                        <Bar dataKey="appointment" fill="#00a297" radius={[10, 10, 0, 0]} />
                        <Bar dataKey="test" fill="#00443f" radius={[10, 10, 0, 0]} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default EarningsGraph;