/**
 * SingleAppointment
 * 
 * Description: This component is responsible for rendering the detailed view of a single appointment. It includes breadcrumb navigation and passes the appointment ID to the `AppointmentDetailComponent`.
 * 
 * File Name: SingleAppointment.tsx
 * Date: 16-09-2024
 */

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppointmentDetailComponent from 'src/Components/GlobalComponents/AppointmentDetailComponent';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';

const SingleAppointment: React.FC = () => {
    const { id = "" } = useParams();
    const navigate = useNavigate();

    return (
        <>
            {/* Breadcrumb component for navigation and returning to the previous page */}
            <BreadCrumb
                current="Appointment"
                previous="dashboard"
                navigateUrl="/admin"
                isUpsert={true}
                btnShow={true}
                onPress={() => navigate(-1)}
            />
            {/* Appointment detail view passing the appointment ID */}
            <AppointmentDetailComponent
                appoinmentId={id}
                isAdmin={true}
            />
        </>
    );
};

export default SingleAppointment;