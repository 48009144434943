/**
 * ViewResult Component
 * 
 * Description: This component is part of the admin site. It fetches and displays the test results for a customer based on the provided user ID (`uid`). The results include user data, product data, and biomarker data, which are shown using the DisplayResult component. The component also handles navigation and breadcrumbs specific to the admin dashboard.
 * 
 * File Name: ViewResult.tsx
 * Date: 2024-08-19
 */

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import DisplayResult from 'src/Components/GlobalComponents/DisplayResult';
import SplashScreen from 'src/Components/SplashScreen';
import httpRequest from 'src/helpers/httpRequest';
import { IResultBiomarker, IResultDetails, IResultProduct, IResultUser } from 'src/types/GlobalInterfaces';

const ViewResult: React.FC = () => {
    const { uid = "" } = useParams(); // Extract order ID from URL parameters
    const navigate = useNavigate();

    const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage loading status

    const [userData, setUserData] = useState<IResultUser | null>(null); // State to store user data
    const [productData, setProductData] = useState<IResultProduct | null>(null); // State to store product data
    const [biomarkerData, setBiomarkerData] = useState<IResultBiomarker[]>([]); // State to store biomarker data

    // API_CALL: Function to fetch test result details
    const fetchResult = async (_id: string) => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IResultDetails>({ path: `/test/single-details/${_id}` });
        if (res) {
            const { data } = res;
            // Update states with fetched data
            setUserData(data.userData[0]);
            setProductData(data.productData[0]);
            setBiomarkerData(data.biomarkerData);
        } else {
            // Display error toast notification
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Effect to fetch result when the component mounts or when the ID changes
    useEffect(() => {
        if (uid) {
            fetchResult(uid);
        }
    }, [uid]);

    return (
        <>
            {
                isLoader
                &&
                <SplashScreen />
            }
            <BreadCrumb
                current="Result"
                previous="customer"
                navigateUrl="/customers"
                btnShow={true}
                isUpsert={true}
                onPress={() => navigate(-1)}
            />
            <div className="table-container admin__site">
                {(userData && productData) &&
                    <DisplayResult
                        userData={userData}
                        productData={productData}
                        biomarkerData={biomarkerData}
                    />
                }
            </div>
        </>
    );
};

export default ViewResult;