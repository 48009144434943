
import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { ICartItems } from 'src/types/GlobalInterfaces';
import { truncateString } from 'src/helpers/helperFunctions';

// File Description:
// This file contains the `ConfrimOrder` component, which displays the order confirmation details.
// It includes:
// 1. Display of each item in the cart with its name and price.
// 2. Display of the subtotal and total amount for the order.


const ConfrimOrder: React.FC = () => {
    // Retrieve cart details from Redux store
    const { subTotal, total, cartItems } = useSelector((store: RootState) => store.cart);
    return (
        <div className='confrim-order'>
            <div className='confrim-order-inner'>
                <div className='confrim-order-inner-last'>
                    {/* Heading for confirmation section */}
                    <h2 className='text-[#7A9EA0] font-size-18pxfont-Poppins-Regular'>Confirm Order</h2>
                    {/* Map through cart items to display each item's name and sale price */}
                    {cartItems?.map((item: ICartItems, index: number) => (
                        <div key={index} className='sport-hormones-order'>
                            <div className='text-[#4F6367] font-size-18px font-Poppins-SemiBold'>{truncateString(item?.productName || "", 40)}</div>
                            <div className='text-[#4F6367] block font-size-18px font-Poppins-SemiBold'>{`AED ${item?.salePrice || 0}`}</div>
                        </div>
                    ))}
                    {/* Display subtotal */}
                    <div className="flex justify-between">
                        <span className='text-[#959595] block mb-2 font-size-18px font-Poppins-SemiBold'>Sub Total:</span>
                        <div>
                            <span className='text-[#474747] font-size-20px font-Poppins-SemiBold'>AED {total || 0}</span><span className='font-size-12px'></span>
                        </div>
                    </div>
                    {/* Display total amount */}
                    <div className="flex justify-between">
                        <span className='text-[#959595] block mb-4 font-size-18px font-Poppins-SemiBold'>Total:</span>
                        <div>
                            <span className='text-[#474747] font-size-20px font-Poppins-SemiBold'>AED {total || 0}</span><span className='font-size-12px'></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfrimOrder;