/**
 * AboutMeEdit Component
 * 
 * Description: This component allows users to edit their "About Me" section. It includes a form where users can write or update their description.
 * The component fetches the current "About Me" data from the profile and updates it via an API call upon form submission. A loader is displayed 
 * during the API request, and success or error messages are shown based on the result.
 * 
 * File Name: AboutMeEdit.tsx
 * Date: 02-09-2024
 */

import React, { FormEvent, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PageHeader from 'src/Components/Customer/PageHeader';
import PreviewProfileButton from 'src/Components/Customer/PreviewProfileButton';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SimpleInput from 'src/Components/SimpleInput';
import httpRequest from 'src/helpers/httpRequest';
import { updateDoctorField } from '../../../Store/doctor';
import { RootState } from '@store/store';
import { setUniqueData } from 'src/Store/reducer';

const AboutMeEdit: React.FC = () => {
    const { profile } = useSelector((store: RootState) => store.doctor);
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [aboutMe, setAboutMe] = useState<string>("");

    // API_CALL: Updates the user's "About Me" section with new data
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: string; percent: number }>({
            method: "post",
            path: "/provider/about",
            params: { aboutMe }
        });
        if (res) {
            const { message, data, percent } = res;
            toast(message, { type: "success" }); // api success message
            dispatch(updateDoctorField({ aboutMe: data }));
            dispatch(setUniqueData({ key: 'profileCompletion', value: String(percent) }));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (profile) setAboutMe(profile.aboutMe || "");
    }, [profile]);

    return (
        <>
            <PageHeader
                title="About Me"
                isButton={true}
                ButtonComponent={PreviewProfileButton}
            />

            <div className="parent-box minheight-parent-box">
                <p className="font-Poppins-semiBold font-size-18px theme-color-green mb-2">You can write about your years of experience, industry, or skills. People also talk about their achievements or previous job experiences.</p>
                <form onSubmit={handleSubmit}>
                    <SimpleInput
                        isRequired={true}
                        value={aboutMe}
                        onChange={(val) => setAboutMe(val)}
                        type="textArea"
                        rows={10}
                        isLimit={1200}
                        maxLength={1200}
                        extraStyle={{ borderColor: "#009d91" }}
                    />
                    <div className="flex justify-end mt-10">
                        <PrimaryBtn
                            loader={isLoader}
                            type="submit"
                            ButtonText="Save"
                            btnExtraStyle={{
                                borderRadius: "1.5rem",
                                backgroundColor: "#004540"
                            }}
                        />
                    </div>
                </form>
            </div>
        </>
    );
};

export default AboutMeEdit;