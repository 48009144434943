/**
 * AllOrders Page
 * 
 * Description: This page displays all orders in the admin dashboard. It includes a breadcrumb for navigation and an OrdersList component that renders a table with order details.
 * 
 * Components Used:
 * - BreadCrumb: Displays the current navigation path, allowing easy navigation back to the dashboard.
 * - OrdersList: Renders a list of orders in a table format.
 * 
 * File Name: AllOrders.tsx
 * Date: 15-08-2024
 */

import React from 'react';
import BreadCrumb from '../../Components/GlobalComponents/BreadCrumb';
import OrdersList from '../../Components/AdminComponents/OrdersList';


const AllOrders: React.FC = () => {
    return (
        <>
            <BreadCrumb
                current="Orders"
                previous="dashboard"
                navigateUrl="/admin"
            />
            <div className="table-container">
                <OrdersList />
            </div>
        </>
    );
};

export default AllOrders;