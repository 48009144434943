/**
 * DiscussionDetail Component
 * 
 * Description: This component displays the details of a specific discussion, including the title,
 * description, comment count, and the ability to edit the discussion. It fetches discussion data
 * based on the discussion ID from the URL and manages loading states and modal visibility.
 * 
 * File Name: DiscussionDetail.tsx
 * Date: 24-09-2024
 */

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'src/Components/Customer/PageHeader';
import CommentSection from 'src/Components/GlobalComponents/CommentSection';
import BackIcon from "src/assets/images/back-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { convertToHyperlinks, convertToTimeAgo } from 'src/helpers/helperFunctions';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import CreateDiscussionModal from 'src/Components/GlobalComponents/CreateDiscussionModal';
import FullScreenLoader from 'src/Components/fullScreenLoader';

// Interface for Discussion Item

interface IDiscussionItem {
    _id: string;
    title: string;
    description: string;
    photo: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    commentCount: number;
    userId: string;
};

const DiscussionDetail: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const navigate = useNavigate();
    const { id = "" } = useParams();

    // State to manage modal visibility
    const [modal, setModal] = useState<boolean>(false);
    // State to manage loading state
    const [isLoader, setIsLoader] = useState<boolean>(false);
    // State to store discussion data
    const [data, setData] = useState<null | IDiscussionItem>(null);

    // Function to increment the comment count
    const countIncreament = () => {
        setData(prev =>
            prev ? ({ ...prev, commentCount: prev.commentCount + 1 }) : null
        );
    };

    //API_CALL: Function to fetch discussion details from the API
    const fethcDiscussionDetail = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ doucnment: IDiscussionItem }>({
            path: `/disscussion/${id}`,
        });
        if (res) {
            setData(res.doucnment || null);
        } else {
            toast(err, { type: 'error' });
            navigate("/discussions")
        }
        setIsLoader(false);
    };

    // useEffect to fetch discussion details when the component mounts or the ID changes
    useEffect(() => {
        if (id) {
            fethcDiscussionDetail();
        }
    }, [id]);

    // Back button component to navigate back to discussions
    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate("/discussions")}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    };

    return (
        <>
            {isLoader && <FullScreenLoader />}
            <PageHeader
                title="Discussions"
                isButton={true}
                ButtonComponent={BackButton}
            />
            <CreateDiscussionModal
                isOpen={modal}
                onClose={() => setModal(false)}
                isEdit={{ title: data?.title || "", description: data?.description || "", _id: data?._id || "" }}
            />
            <div className="parent-box">
                <div>
                    <div className='pencil-icon-end-edit'>
                        <h5 className="theme-color-green font-size-30px font-Poppins-SemiBold">{data?.title || ""}</h5>
                        {(data?.userId === userDetails?._id) &&
                            <button onClick={() => setModal(true)} className='pencil-icon'>
                                <FontAwesomeIcon icon={faPencil} />
                            </button>
                        }
                    </div>
                    <p className="theme-color-green font-size-16px font-Poppins-Medium">Posted by: <span className="capitalize">{`${data?.firstName || ""} ${data?.lastName || ""}`}</span></p>
                    <div className="flex items-center mt-2 mb-4 gap-4">
                        <p className="text-[#6D6D6D] font-size-15px font-Poppins-Regular">{data?.commentCount || 0} {`Repl${data?.commentCount || 0 > 1 ? "ies" : "y"}`}</p>
                        <span className="w-1 h-1 rounded-full bg-[#707070]"></span>
                        <p className="text-[#6D6D6D] font-size-15px font-Poppins-Regular">{convertToTimeAgo(data?.createdAt || "")}</p>
                    </div>
                    <p className="text-[#6D6D6D] font-size-16px font-Poppins-Regular mb-4">
                        <span
                            dangerouslySetInnerHTML={{ __html: convertToHyperlinks(data?.description || "") }}
                        />
                    </p>
                </div>
                <CommentSection
                    id={id}
                    updateCommentCount={countIncreament}
                />
            </div>
        </>
    );
};

export default DiscussionDetail;