/**
 * DoctorSchedular Component
 * 
 * Description: This component displays the availability of a doctor and their appointments
 * for a selected hospital within a weekly calendar view. It allows navigation between
 * weeks and dynamically fetches the appointments based on the hospital and week selected.
 * 
 * Props: None
 * 
 * File Name: DoctorSchedular.tsx
 * Date: 04-10-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import SplashScreen from '../SplashScreen';
import { IAvailability } from 'src/types/GlobalInterfaces';
import httpRequest from 'src/helpers/httpRequest';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { toast } from 'react-toastify';
import qs from "qs";

type DayOfWeek = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

interface IDoctorAvailability {
    onlineAvalibility: IAvailability;
    timings: IAvailability[];
    _id: string;
};

interface IQParams {
    startDate: string;
    endDate: string;
    hospitalId: string;
};

interface IAppointmentItem {
    _id: string;
    doctorId: string;
    userId: string;
    hospitalId: string;
    patientName: string;
    phone: string;
    appoinmentDate: string; // Use Date if you want to store it as a Date object
    slotId: string;
    isOnlineAppointment: boolean;
    isBooked: boolean;
    isReschedule: boolean;
    totalAmount: number;
    startTime: string;
    endTime: string;
    status: "pending" | "completed" | "cancelled";
    createdAt: string; // Use Date if you want to store it as a Date object
    updatedAt: string; // Use Date if you want to store it as a Date object
    __v: number;
};

const DoctorSchedular: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const [totalSlots, setTotalSlots] = useState<{ startTime: string; endTime: string }[]>([]);
    const [allAppoiment, setAllAppoiment] = useState<IAppointmentItem[]>([]);
    const [weekDays, setWeekDays] = useState<moment.Moment[]>([]);
    const [hospitalLoader, setHospitalLoader] = useState<boolean>(false);
    const [appointmentLoader, setAppointmentLoader] = useState<boolean>(false);
    const [availability, setAvailability] = useState<IDoctorAvailability | null>(null);
    const [queryParams, setQueryParams] = useState<IQParams>({
        startDate: "",
        endDate: "",
        hospitalId: "",
    });

    // Update queryParams based on selected hospital
    const changeHospital = (key: keyof IQParams, val: string) => {
        setQueryParams(prev => ({
            ...prev,
            [key]: val
        }));
    };

    // Navigate to the next week
    const nextWeek = () => {
        const nextStartDate = moment(queryParams.startDate).add(1, 'week');
        setWeek(nextStartDate);
    };

    // Navigate to the previous week
    const prevWeek = () => {
        const prevStartDate = moment(queryParams.startDate).subtract(1, 'week');
        setWeek(prevStartDate);
    };

    // Set the week and generate dates for the week
    const setWeek = (date: moment.Moment) => {
        const start = moment(date).utc().startOf('day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const end = moment(start).add(6, 'days').utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // End date at midnight UTC after 6 days

        setQueryParams(prev => ({
            ...prev,
            startDate: start,
            endDate: end,
        }));

        // Create a moment object from the start date
        const START = moment(start).startOf('isoWeek'); // Week starts on Monday

        // Generate an array of dates for the entire week
        const arr = Array.from({ length: 7 }, (_, index) => {
            return START.clone().add(index, 'days'); // Add days to the start date
        });

        setWeekDays(arr);
    };

    //API_CALL: Fetch hospitals data from the API
    const fetchHospitals = async () => {
        setHospitalLoader(true);
        const { res, err } = await httpRequest<IDoctorAvailability[]>({ path: `/appoiment/doctor/${userDetails?._id}` });
        if (res) {
            const { onlineAvalibility, timings } = res[0];
            if (onlineAvalibility && onlineAvalibility?.fees) {
                changeHospital("hospitalId", onlineAvalibility?._id || "");
            } else {
                changeHospital("hospitalId", timings[0]?._id || "");
            }
            setAvailability(res[0]);
        } else {
            toast(err, { type: 'error' });
        }
        setHospitalLoader(false);
    };

    //API_CALL: Fetch appointments based on selected hospital and week
    const fetchAppointments = async () => {
        setAppointmentLoader(true);
        const { res, err } = await httpRequest<IAppointmentItem[]>({
            path: `/appoiment/doctor/slots/schedular?${qs.stringify(queryParams)}`
        });
        if (res) {
            setAllAppoiment(res || []);
            const newSlots = res.reduce((acc: { startTime: string; endTime: string }[], item: IAppointmentItem) => {
                // Check if the startTime is already in the accumulator
                if (!acc.some(v => v.startTime === item.startTime)) {
                    acc.push({ startTime: item.startTime, endTime: item.endTime });
                }
                return acc;
            }, []);
            setTotalSlots(newSlots);
        } else {
            toast(err, { type: 'error' });
        }
        setAppointmentLoader(false);
    };

    // Initialize week and fetch data when component mounts
    useEffect(() => {
        setWeek(moment());
    }, []);

    useEffect(() => {
        if (queryParams.hospitalId) {
            fetchAppointments();
        }
    }, [queryParams]);

    useEffect(() => {
        if (userDetails) {
            fetchHospitals();
        }
    }, [userDetails]);

    // Render appointment details
    const printAppointment = (day: moment.Moment, startTime: string) => {
        const item = allAppoiment.find(v => (
            v.appoinmentDate ?
                moment(v.appoinmentDate).format("DD") === moment(day).format("DD") && v.startTime === startTime
                :
                null
        ));
        if (item) {
            return (
                <div className={`event hovered-class ${item?.status === "completed" ? "Complete" : item?.status === "pending" ? "Pending" : "cancelled"}-Box min-w-[132px]`}>
                    <div className="Shedular-main">
                        <div className="UserName-calen">
                            <h6 className="capitalize font-Poppins-SemiBold font-size-10px">{item?.patientName || ""}</h6><span
                                className="job__status capitalize font-Poppins-Medium">{item?.status || ""}</span>
                        </div>
                        <p className="job__time__start__end font-Poppins-Regular">{`${moment(item.startTime, "HH:mm").format("hh:mm A")} - ${moment(item.endTime, "HH:mm").format("hh:mm A")}`}</p>
                        <div className="UserName-calen2">
                            <p className="font-Poppins-Regular">{item?.phone || ""}</p>
                            <p className="font-Poppins-Medium">AED {item?.totalAmount || ""}</p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <>-</>
        }
    };

    const isDataExist = (day: Moment, time: string): string => {
        console.log("AAAAAAAAAAAA", day, time)

        const _find = allAppoiment.find(v => moment(v.appoinmentDate).format("dd") === moment(day).format("dd") && v.startTime === time);

        if (_find) {
            return "z-[2] hovered-cell"
        }
        return "";
    };

    // Check if a day is available
    const checkAvailableOrNot = (day: DayOfWeek): string => {
        // Check if online availability exists for the selected hospital
        if (availability?.onlineAvalibility && availability.onlineAvalibility._id === queryParams.hospitalId) {
            // Check if the specific day exists in onlineAvalibility
            if (availability?.onlineAvalibility[day]) {
                return `bg-[#fff]`;
            } return "bg-[#eaf2f0]";
        } else {
            const obj = availability?.timings.find(v => v._id === queryParams.hospitalId);
            if (obj && obj[day]) {
                return `bg-[#fff]`;
            } return "bg-[#eaf2f0]";
        }
    };

    // Render hospital name
    const printHospitalName = (): string => {
        if (availability?.onlineAvalibility && availability.onlineAvalibility._id === queryParams.hospitalId) {
            return "Online Appointment";
        } else {
            return availability?.timings.find(v => v._id === queryParams.hospitalId)?.hospitalName || ""
        }
    };
    return (
        <>
            {appointmentLoader && <SplashScreen />}
            <div className='calendar-header flex flex-col relative'>
                <div className='clander-handler-head'>
                    <span className='font-Poppins-SemiBold font-size-20px theme-color-green'>
                        <span className="flex gap-2 items-center justify-center">
                            <span>
                                {moment(queryParams.startDate).format("DD-MM-YYYY")}
                            </span>
                            <span>
                                -
                            </span>
                            <span>
                                {moment(queryParams.endDate).format("DD-MM-YYYY")}
                            </span>
                        </span>
                    </span>
                </div>
                <div className="calender-header-grid">
                    <div className='calender-header-weeks'>
                        <button onClick={prevWeek} className='calender-header-week-arrow'>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <span className='text-[#FFFFFF] font-Poppins-Medium font-size-16px'>Week</span>
                        <button onClick={nextWeek} className='calender-header-week-arrow'>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                    <div className='hospital-name'><span className='font-Poppins-Medium text-white font-size-20px'>{printHospitalName()}</span></div>
                    <div className='flex hospital-select-option justify-end'>
                        <div className='relative'>
                            <span className='text-[#B5B5B5] font-Poppins-Medium font-size-12px'>Appointments</span>
                            <select value={queryParams.hospitalId || ""} onChange={(e) => changeHospital("hospitalId", e.target.value)} className="height-of-select-input-fields-of-calender font-Poppins-Medium font-size-15px">
                                {(availability && availability.onlineAvalibility.fees) &&
                                    <option value={availability.onlineAvalibility._id}>Online Appointment</option>

                                }
                                {availability &&
                                    availability.timings.map((item: IAvailability, index: number) => (
                                        <option key={index} value={item?._id}>{item?.hospitalName}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='calendar'>
                <table className='weekly-calender'>
                    <thead>
                        <tr>
                            <th className="px-2">
                                <span className='font-Poppins-SemiBold font-size-15px theme-color-green'>Timings</span>
                            </th>
                            {weekDays.map((day, index) => (
                                <th key={index} className={`px-2 font-Poppins-SemiBold font-size-15px text-[#4B4B4B] ${checkAvailableOrNot(day.format('dddd').toLocaleLowerCase() as DayOfWeek)}`}>
                                    <span className="flex flex-col">
                                        <span>{day.format('ddd')}</span>
                                        <span>{day.format('DD')}</span>
                                    </span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {totalSlots.map(v => (
                            <tr key={v.startTime}>
                                <td className="px-2">
                                    <span className="font-size-15px font-Poppins-SemiBold text-[#464646] capitalize text-left whitespace-nowrap">
                                        {`${moment(v.startTime, "HH:mm").format("hh:mm A")} to ${moment(v.endTime, "HH:mm").format("hh:mm A")}`}
                                    </span>
                                </td>
                                {weekDays.map((day, index) => (
                                    <td key={index} className={`${checkAvailableOrNot(day.format('dddd').toLocaleLowerCase() as DayOfWeek)} ${isDataExist(day, v.startTime)}`}>
                                        {printAppointment(day, v.startTime)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {Array.from({ length: Math.max(0, 10 - totalSlots.length) }).map(
                            (_, index) => (
                                <tr key={totalSlots.length + index}>
                                    <td></td>
                                    {weekDays.map((day, index) => (
                                        <td key={index} className={`${checkAvailableOrNot(day.format('dddd').toLocaleLowerCase() as DayOfWeek)}`}></td>
                                    ))}
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default DoctorSchedular;