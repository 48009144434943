/**
 * PageHeader.tsx
 * 
 * Date: 09-08-2024
 * 
 * Description: 
  * This component renders a page header with a title. It accepts a `title` prop to display the heading text.
 * It optionally displays a button if `isButton` is true.
 * Additional props can be passed via the `[key: string]: any` index signature.
 */

import React, { Fragment } from 'react';

interface IPageHeaderProps {
    title: string; // The title text to be displayed in the header
    isButton?: boolean;
    ButtonComponent?: React.ElementType; // Component to render as a button
    [key: string]: any; // Allows additional props to be passed
};

const PageHeader: React.FC<IPageHeaderProps> = ({
    title = "",
    isButton = false,
    ButtonComponent = Fragment
}) => {
    return (
        <div className='parent-box parent-box-bottom-margin'>
            {/* Display the page title with styling */}
            <div className="flex items-center justify-between">
                <h2 className='title-heading font-Poppins-SemiBold font-size-20px'>{title}</h2>
                {
                    isButton
                    &&
                    <ButtonComponent />
                }
            </div>
        </div>
    );
};

export default PageHeader;