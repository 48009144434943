/**
 * OnlineConsultant Component
 * 
 * Description: Displays a doctor's online consultation availability and fees. If `isMyProfile` 
 * is true, an edit icon is shown for modifying the details.
 * 
 * Props:
 * - isMyProfile (boolean): Shows edit icon if true.
 * - availability (IAvailability): Doctor's availability and consultation fees.
 * 
 * File Name: OnlineConsultant.tsx
 * Date: 02-09-2024
 */

import React from 'react';
import { Link } from 'react-router-dom';
import edit from "../../../assets/images/awesome-edit.svg";
import { weekDays } from 'src/lib/constans';
import moment from 'moment';
import { IAvailability } from 'src/types/GlobalInterfaces';

const OnlineConsultant: React.FC<{ isMyProfile: boolean; availability: IAvailability; }> = ({ isMyProfile = false, availability }) => {
    return (
        <div className="parent-box parent-box-margin-issue relative">
            <div className='w-[90%]'>
                <div className='flex items-center justify-between'>
                    <h2 className='font-size-20px font-Poppins-SemiBold theme-color-green mb-1'>Online Consultation</h2>
                    <p className="font-size-20px font-Poppins-SemiBold theme-color-green">AED {availability?.fees || 0}</p>
                </div>
                <div className="mt-6 grid grid-cols-3 xl:grid-cols-4 gap-y-8 gap-x-6">
                    {weekDays.map((day) => { // Iterate over ordered days
                        const slots = availability?.[day as keyof IAvailability];
                        if (!slots) return null;
                        return (
                            <div key={day} className="schedule">
                                <p className='font-Poppins-Regular font-size-20px capitalize'>{day}</p>
                                {Array.isArray(slots) && (
                                    slots.map(([startTime, endTime], index) => (
                                        <p key={index} className='font-Poppins-Regular font-size-16px flex items-center gap-2'>
                                            <React.Fragment key={index}>
                                                <span>{moment(startTime, "HH:mm").format("h:mm A")}</span>
                                                <span>to</span>
                                                <span>{moment(endTime, "HH:mm").format("h:mm A")}</span>
                                                {/* {index < slots.length - 1 && <span>,</span>} */}
                                            </React.Fragment>
                                        </p>
                                    ))
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            {isMyProfile
                &&
                <Link to="/online-availability" className="edit-pro-icon absolute top-2 right-4">
                    <img src={edit} alt="edit-icon" />
                </Link>
            }
        </div>
    );
};

export default OnlineConsultant;