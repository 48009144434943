/**
 * OrdersList Component
 * 
 * Description: This component displays a list of orders with tabs for filtering 
 * by status (All Orders, Pending, In Progress, Delivered). It includes functionalities 
 * for searching, pagination, and displaying order details. The component fetches 
 * order data from an API and updates the view based on user interactions.
 * 
 * File Name: OrdersList.tsx
 * Date: 15-08-24
 * 
 * Marker: DOC_START
 */

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import TableWrapper from '../GlobalComponents/TableWrapper';
import SearchFilterInput from '../GlobalComponents/SearchFilterInput';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { IOrderItems, IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import { Link } from 'react-router-dom';
import qs from "qs";
import { displayStatus, getLastSegment } from 'src/helpers/helperFunctions';
import moment from 'moment';

interface IOrderResponse {
    documents: IOrderItems[];
    paginated: IPaginated;
    pendingCount: number;
    deliveredCount: number;
    inProgressCount: number;
};

const OrdersList: React.FC = () => {
    // List of tab names for filtering orders
    const tabList: string[] = ["", "pending", "in_progress", "delivered"];
    const [activeTab, setActiveTab] = useState<string>("");

    // States for loading status, orders data, counts, and pagination
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [allOrders, setAllOrders] = useState<IOrderItems[]>([]); // Holds all orders data

    const [count, setCount] = useState<{ totalCount: number; pendingCount: number; inProgressCount: number; deliveredCount: number }>({
        totalCount: 0,
        pendingCount: 0,
        inProgressCount: 0,
        deliveredCount: 0,
    });
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        status: "",
        search: "",
    });

    // Handles tab click to filter orders
    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
        setQueryParams(prev => ({
            ...prev,
            status: tabName,
            page: 1,
        }));
    };

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            search: txt
        }));
    };

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // API_CALL: Fetches all orders from the server
    const fetchAllOrders = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IOrderResponse>({ path: `/order?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated, deliveredCount = 0, inProgressCount = 0, pendingCount = 0 } = res;
            setAllOrders(documents);
            setPaginated(paginated);
            setCount({
                totalCount: pendingCount + inProgressCount + deliveredCount,
                pendingCount: pendingCount,
                inProgressCount: inProgressCount,
                deliveredCount: deliveredCount,
            });
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchAllOrders();
    }, [queryParams]);

    return (
        <>
            <div className='flex justify-between items-center'>
                <div className='products-tabs-btn'>
                    {tabList.map((v: string) => (
                        <button
                            key={v}
                            className={`font-size-15px font-Poppins-Medium ${activeTab === v && 'active'}`}
                            onClick={() => handleTabClick(v)}
                        >
                            {
                                v === "" ? `All Orders (${count.totalCount})` :
                                    v === "pending" ? `Pending (${count.pendingCount})` :
                                        v === "in_progress" ? `In Progress (${count.inProgressCount})` :
                                            `Delivered (${count.deliveredCount})`
                            }
                        </button>
                    ))}
                </div>
                <SearchFilterInput
                    inputExtraStyle={{ marginBottom: 0, }}
                    onChangeText={handleChangeText}
                />
            </div>
            <hr className='products-tabs-hr products-tabs-hr-allOrder' style={{ border: '1px solid #D4D4D4', }} />
            {/* <SearchFilterInput
                onChangeText={handleChangeText}
            /> */}
            <TableWrapper
                isLoading={isLoader}
                thead={["Order ID", "Customer Name", "Total Amount", "Date", "Status", "Action"]}
                page={paginated?.currentPage || 1}
                total={paginated?.totalPages || 0}
                columnWidth={"10% 30% 15% 15% 20% 10%"}
                onChange={handlePageChange}
            >
                <div className='tbody-content-height tbody-content-height-allOrder'>
                    {allOrders?.map((item: IOrderItems, index: number) => (
                        <ul key={index} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "10% 30% 15% 15% 20% 10%" }}>
                            <li>{getLastSegment(item?.serialOrderId)}</li>
                            <li className="capitalize">{`${item?.firstName || ""} ${item?.lastName || ""}`}</li>
                            <li>{`AED ${item?.totalAmount || 0}`}</li>
                            <li>{moment(item?.createdAt).format("DD-MM-YYYY")}</li>
                            <li style={{ paddingLeft: 0 }} className='justify-center'>{displayStatus(item?.status) || ""}</li>
                            <li className='action-last-colum'>
                                <Link to={`/order-details/${item?._id}/View`}>
                                    <FontAwesomeIcon icon={faEye} />
                                </Link>
                            </li>
                        </ul>
                    ))}
                </div>
            </TableWrapper>
        </>
    );
};

export default OrdersList;