/**
 * AppointmentDetails
 * 
 * Description: This component displays detailed information about a specific appointment. It includes a page header with a back button to navigate to the previous page and renders the `AppointmentDetailComponent` using the appointment ID.
 * 
 * File Name: AppointmentDetails.tsx
 * Date: 16-09-2024
 */

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'src/Components/Customer/PageHeader';
import AppointmentDetailComponent from 'src/Components/GlobalComponents/AppointmentDetailComponent';
import BackIcon from "src/assets/images/back-icon.png";

const AppointmentDetails: React.FC = () => {
    const { id = "" } = useParams(); // Extracting appointment ID from URL parameters
    const navigate = useNavigate(); // Hook to handle navigation

    // BackButton component for rendering the back icon in the page header
    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header
    return (
        <>
            {/* Page header with a custom back button */}
            <PageHeader
                title="My Appointment"
                isButton={true}
                ButtonComponent={BackButton}
            />
            {/* Appointment detail view passing the appointment ID */}
            <AppointmentDetailComponent
                appoinmentId={id}
            />
        </>
    );
};

export default AppointmentDetails;