/**
 * DataTab Component
 *
 * This component represents the data tab in the product management interface. It allows users to input and manage product-specific data such as SKU, regular price, and sale price. The component includes input fields for these details and a button to proceed to the next tab.
 *
 * File Name: DataTab.tsx
 * Date: 31-07-24
 */

import React from 'react';
import SimpleInput from '../../SimpleInput';
import { IProductsTabsProps } from 'src/types/GlobalInterfaces';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';

const DataTab: React.FC<IProductsTabsProps> = ({
    state,
    setState,
    tabChange
}) => {
    return (
        <>
            <div className='products-tabs-content'>
                {/* Input for SKU */}
                <SimpleInput
                    value={state?.sku}
                    onChange={(val) => setState("sku", val)}
                    isLabel={true}
                    labelText="SKU:"
                    isRequired={true}
                />
                {/* Input for Regular Price */}
                <SimpleInput
                    value={state?.regularPrice}
                    onChange={(val) => setState("regularPrice", val)}
                    isLabel={true}
                    labelText="Regular Price:"
                    isRequired={true}
                    type='number'
                />
                {/* Input for Sale Price */}
                <SimpleInput
                    value={state?.salePrice}
                    onChange={(val) => setState("salePrice", val)}
                    isLabel={true}
                    labelText="Sale Price:"
                    isRequired={true}
                    type='number'
                />
            </div>
            {/* Button to proceed to the next tab */}
            <div className='flex justify-end my-6'>
                <PrimaryBtn
                    ButtonText="Next"
                    btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                    onPress={tabChange}
                />
            </div>
        </>
    );
};

export default DataTab;