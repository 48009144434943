/**
 * DisplayResult Component
 * 
 * Description: The DisplayResult component is responsible for rendering the results of a biomarker test, including user data, product data, and biomarker details. It displays a summary, result history, and allows for downloading reference documents.
 * 
 * File Name: DisplayResult.tsx
 * Date: 20-08-2024
 */

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import CrossImage from "src/assets/images/cross-image.png";
import { IResultBiomarker, IResultProduct, IResultUser } from 'src/types/GlobalInterfaces';
import moment from 'moment';
import PrimaryBtn from './primaryBtn';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import SimpleLineGraph from './Graphs/SimpleLineGraph';
import { ageCategory, returnColor } from 'src/helpers/helperFunctions';
import { descriptiveResult, twoBarDNDAsc, twoBarDNDDesc, twoBarPNAsc, twoBarPNDesc } from 'src/lib/constans';
import HistoryGraph from './Graphs/HistoryGraph';

export interface IDisplayResultProps {
    userData: IResultUser;
    productData: IResultProduct;
    biomarkerData: IResultBiomarker[];
};

export interface IHistory {
    collectionDate: string;
    testDataResult: string;
    _id: string;
};

// Component to display history of a single biomarker
const ResultHistory: React.FC<{ data: IResultBiomarker | any; dob: string; gender: string; id: string }> = ({ data = {}, dob = "", gender = "", id = "" }) => {
    const [dropDown, setDropDown] = useState<boolean>(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);

    const [history, setHistory] = useState<IHistory[]>([]);

    const age = moment().diff(moment(dob), 'years');
    const ageGroup = ageCategory(age) || "";

    // Get the reference and optional values based on gender and age group
    const refValues = data[`${gender}Thresholds`]['ref'][ageGroup];
    const opValues = data[`${gender}Thresholds`]['op'][ageGroup];

    // API_CALL: Fetch biomarker history data
    const fetchHistory = async (bioId: string) => {
        if (dropDown) {
            setDropDown(false);
            return;
        }
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: IHistory[] }>({
            method: "post",
            path: "/test/single-biomarker/all/details",
            params: {
                customerId: id,
                biomerkerId: bioId,
            }
        });
        if (res) {
            setHistory(res.data);
            setDropDown(true);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    return (
        <>
            <div className='Ferritin-box-btn-progress-bar'>
                <div>
                    <button onClick={() => fetchHistory(data?.biomarkerId)} className='Ferritin-box-content-btn font-Poppins-Regular font-size-15px'>Results history<FontAwesomeIcon icon={dropDown ? faChevronUp : faChevronDown} /></button>
                </div>
                {
                    data.barId !== descriptiveResult
                    &&
                    <SimpleLineGraph
                        refMin={refValues[0]}
                        refMax={refValues[1]}
                        optMin={opValues[0]}
                        optMax={opValues[1]}
                        result={Number(data.result)}
                        category={data.barId}
                        size={"md"}
                    />
                }
            </div>
            {
                isLoader
                &&
                <div className="text-center my-5">
                    <CircularProgress style={{ width: 24, height: 24, color: "#004540" }} />
                </div>
            }
            {dropDown && (
                <div className='result-dropdown-graph gap-4'>
                    {
                        data.barId !== descriptiveResult
                        &&
                        <div className='result-dropdown-graph-inner'>
                            <HistoryGraph
                                reference={[Number(refValues[0]), Number(refValues[1])]}
                                optimal={[Number(opValues[0]), Number(opValues[1])]}
                                category={data.barId}
                                data={
                                    history
                                        .map(v => ({
                                            date: moment(v.collectionDate).format("DD-MM-YYYY"),
                                            value: Number(v.testDataResult)
                                        }))
                                        .reverse() // Reverses the order of the array
                                }
                            />
                        </div>
                    }
                    <div className='result-dropdown-graph-detail'>
                        <h4 className='text-[#004D48] text-center font-Poppins-Medium font-size-18px'>Result History</h4>
                        <div className='result-dropdown-graph-detail-inner'>
                        {
                            history.length > 0
                                ?
                                history.map((v: IHistory, i: number) => (
                                    <div key={i} className='flex justify-between result-history-data'>
                                        <span className='font-Poppins-Medium text-[#444444] text-gray font-size-14px'>{moment(v.collectionDate).format("DD-MM-YYYY")}</span>
                                        <span className='font-Poppins-Medium text-[#444444] text-gray font-size-14px'>
                                            {
                                                (data.barId === twoBarDNDAsc || data.barId === twoBarDNDDesc)
                                                    ?
                                                    Number(v.testDataResult) === 1 ? "Detected" : "Not Detected"
                                                    : (data.barId === twoBarPNAsc || data.barId === twoBarPNDesc)
                                                        ?
                                                        Number(v.testDataResult) === 1 ? "Positive" : "Negative"
                                                        :
                                                        v.testDataResult
                                            }
                                        </span>
                                    </div>
                                ))
                                :
                                <p className="text-center">No Data...</p>
                        }
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

// Main component to display result with summary and download option
const DisplayResult: React.FC<IDisplayResultProps> = ({ userData, productData, biomarkerData }) => {
    const handleDownLoadFile = (file: string) => {
        try {
            if (!file || !file.includes('/')) {
                throw new Error('Invalid file path or file name missing.');
            }

            // Extract file name from URL
            const fileName = file.substring(file.lastIndexOf('/') + 1);

            if (!fileName) {
                throw new Error('File name could not be extracted from the URL.');
            }
            fetch(`${process.env.REACT_APP_API_BASEURL}${file}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch the file.');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName); // Use fileName parameter
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link); // Cleanup the link element
                    window.URL.revokeObjectURL(url); // Cleanup the object URL
                })
                .catch(error => console.error('Download failed:', error));
        } catch (err: any) {
            toast(err?.message || "ERROR", { type: 'error' });
        }
    };

    return (
        <div className="parent-box parent-box-scroll-bar-full">
            <div className="py-2 admin-resultdisplay px-4">
                <div className="customer-result-top flex flex-col justify-center px-[50px] rounded-xl theme-bg-green text-white">
                    <img src={CrossImage} className='absolute plus-icon-profile right-0' />
                    <h1 className='font-size-36px leading-10 font-Poppins-Medium capitalize'>{userData?.customerName || ""}</h1>
                    <h2 className='font-size-20px font-Poppins-Medium'>{productData?.productName || ""}</h2>
                    <p className='font-size-18px font-Poppins-Regular'>{productData?.biomarkerCount || 0} Biomarkers included</p>
                </div>
                <div className="customer-result-summary-box">
                    <div className="summary">
                        <h2 className="font-Poppins-SemiBold font-size-25px text-[#4F6367]">Summary</h2>
                        <p className="font-Poppins-Regular font-size-18px text-[#797F82]">{productData?.summary || ""}</p>
                    </div>
                    {/* <div className="doctor-detail">
                        <img src={docterImg} alt="doctor-image" className='rounded-full' />
                        <p className="font-Poppins-Medium font-size-20px text-[#4F6367]">
                            Dr. Alexa Smith
                        </p>
                    </div> */}
                </div>
                <div className="my-2">
                    <p className='font-Poppins-Regular text-[#4F6367] font-size-18px text-right pr-10'>
                        Collection Date: {moment(productData.collectionDate).format("DD-MM-YYYY")}
                    </p>
                </div>
                <div className='customer-result-results theme-bg-light-grey w-full'>
                    {biomarkerData?.map((item: IResultBiomarker, index: number) => (
                        <div key={index} className='Ferritin-box-main'>
                            <div className='Ferritin-box'>
                                <div className='Ferritin-box-inner'>
                                    <div className='Ferritin-box-content'>
                                        <h1 className='text-[#4F6367] font-Poppins-Medium font-size-20px'>{item?.name || ""}</h1>
                                        <p className='text-[#4F6367] font-Poppins-Medium font-size-18px'>{item?.shortDescription || ""}</p>
                                    </div>
                                    <div className='Ferritin-box-content-rpogress-bar'>
                                        {
                                            (item.barId === twoBarDNDAsc || item.barId === twoBarDNDDesc)
                                                ?
                                                <span style={{ color: returnColor(item.color) }} className="flex justify-end font-Poppins-Medium font-size-20px">{Number(item.result) === 1 ? "Detected" : "Not Detected"}</span>
                                                : (item.barId === twoBarPNAsc || item.barId === twoBarPNDesc)
                                                    ?
                                                    <span style={{ color: returnColor(item.color) }} className="flex justify-end font-Poppins-Medium font-size-20px">{Number(item.result) === 1 ? "Positive" : "Negative"}</span>
                                                    :
                                                    <span style={{ color: returnColor(item.color) }} className="flex justify-end font-Poppins-Medium font-size-20px">{`${item.result} ${item.isShown ? item.measuremtnDetails : ""}`}</span>
                                        }
                                    </div>
                                </div>
                                <ResultHistory
                                    data={item}
                                    dob={userData.age}
                                    gender={userData.gender}
                                    id={userData.customerId}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='customer-result-results theme-bg-light-grey w-full'>
                    <div className='practitioners-notes'>
                        <h2 className='text-[#363636] mb-3 text-center font-Poppins-SemiBold font-size-30px'>Practitioners notes</h2>
                        <div className='items-baseline'>
                            <p className='text-[#4F6367] font-Poppins-Regular font-size-18px'>{productData?.pracitionerNote || ""}</p>
                        </div>
                    </div>
                </div>
                <div className='customer-result-download'>
                    <span className='theme-color-green font-Poppins-Medium font-size-18px'>Reference document</span>
                    <PrimaryBtn
                        ButtonText="Download"
                        onPress={() => handleDownLoadFile(productData?.documentImage)}
                        btnExtraStyle={{ backgroundColor: "#004540", paddingLeft: "2rem", paddingRight: "2rem" }}
                    />
                </div>
            </div>
        </div>
    );
};

export default DisplayResult;