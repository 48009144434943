/**
 * AppointmentDetailComponent
 * 
 * Description: Displays detailed information about a specific appointment, allowing the user to cancel, reschedule, review, or complete the appointment. 
 * Additional functionality includes joining an online appointment and showing appointment history.
 * 
 * File Name: AppointmentDetailComponent.tsx
 * Date: 16-09-2024
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import AppointmentHistory from 'src/Components/GlobalComponents/AppointmentHistory';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { IAppointmentDetails } from 'src/types/GlobalInterfaces';
import moment, { Moment } from 'moment';
import defaultProfile from "src/assets/images/default_profile_image.png";
import SplashScreen from 'src/Components/SplashScreen';
import Cookies from 'js-cookie';
import { customer, doctor, doctorMode } from 'src/lib/constans';
import { Modal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faXmark } from '@fortawesome/free-solid-svg-icons';
import modalIcon from "src/assets/images/complete-modal-icon.png";
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import DeclineModalReason from 'src/Components/AdminComponents/DeclineModalReason';
import AddAReviewModal from '../Customer/AddAReviewModal';

// Style for the modal container
const customeStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(255, 255, 255)",
    maxWidth: 400,
    width: "100%",
    padding: 12,
    borderRadius: 8
};

// Style for the close button container
const backBtnMaain: React.CSSProperties = {
    position: "absolute",
    top: -10,
    right: -10,
    backgroundColor: "red",
    borderRadius: 100,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const AppointmentDetailComponent: React.FC<{ appoinmentId: string, isAdmin?: boolean }> = ({ appoinmentId, isAdmin = false }) => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const navigate = useNavigate();

    const [mode] = useState(Cookies.get(doctorMode) || "");
    const [comment, setComment] = useState<string>("");
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [cancelLoader, setCancelLoader] = useState<boolean>(false);
    const [cancelModal, setCancelModal] = useState<boolean>(false);
    const [completedLoader, setCompletedLoader] = useState<boolean>(false);
    const [completModal, setCompletModal] = useState<boolean>(false);
    const [data, setData] = useState<IAppointmentDetails | null>(null);
    const [reviewModal, setReviewModal] = useState<boolean>(false);
    const [roomId, setRoomId] = useState<null | string>(null);
    const [bool, setBool] = useState<boolean>(false);
    const [star, setStar] = useState<number>(1);
    const [reviewComment, setReviewComment] = useState<string>("");

    const handleNavigateRoom = () => {
        navigate(`/online-consultation/${appoinmentId}/${roomId}/view`);
    };

    const copyToClipBoard = async (): Promise<void> => {
        try {
            const textToCopy = `${process.env.REACT_APP_DOMAIN}/online-consultation/${appoinmentId}/${roomId}/view`;

            // Check if the Clipboard API is available
            if (navigator.clipboard && window.isSecureContext) {
                // Use the modern API
                await navigator.clipboard.writeText(textToCopy);
                toast("Copied to clipboard.", { type: "success" });
            } else {
                // Fallback for older browsers (less reliable)
                const textarea = document.createElement("textarea");
                textarea.value = textToCopy;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand("copy");
                document.body.removeChild(textarea);
                toast("Copied to clipboard.", { type: "success" });
            }
        } catch (err) {
            toast("Something went wrong.", { type: "error" });
        }
    }; // Function to copy the meet link to the clipboard

    const cancelAppointment = async () => {
        if (!comment) {
            toast("Kindly provide a reason for canceling the appointment.", { type: 'error' });
            return
        }
        setCancelLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: `/reshedule/cancelled/${data?._id}/${userDetails?._id}`,
            params: { comment }
        });
        if (res) {
            toast(res?.message || "Success", { type: 'success' });
            setComment("");
            setCancelModal(false);
            setBool(prev => !prev);
        } else {
            toast(err, { type: 'error' });
        }
        setCancelLoader(false);
    };

    const createCompletRequest = async () => {
        setCompletedLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({ path: `/reshedule/completed/${data?._id}/${data?.doctorId}` });
        if (res) {
            toast(res?.message || "Success", { type: 'success' });
            setCompletModal(false);
            setBool(prev => !prev);
        } else {
            toast(err, { type: 'error' });
        }
        setCompletedLoader(false);
    };

    const fetchInfo = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IAppointmentDetails[]>({ path: `/appoiment/doctor/details/${appoinmentId}` });
        if (res) {
            setData(res[0]);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    const handleEditReview = (obj: { star: number; comment: string }) => {
        const { star, comment } = obj;
        setReviewModal(true);
        setReviewComment(comment);
        setStar(star);
    };

    const [startCallLoader, setStartCallLoader] = useState<boolean>(false);
    const [isTimeValid, setIsTimeValid] = useState(false);

    // ************ start call ************ //
    const startCall = async () => {
        setStartCallLoader(true);
        const { res, err } = await httpRequest<{ roomId: string; message: string; }>({ path: `/appoiment/join/meeting/${appoinmentId}` });
        if (res) {
            const { message = "", roomId = "" } = res;
            if (roomId) {
                toast(message, { type: 'success' });
                setRoomId(roomId);
            } else {
                toast("Room id error!", { type: 'error' });
            }
        } else {
            toast(err, { type: 'error' });
        }
        setStartCallLoader(false);
    };

    useEffect(() => {
        fetchInfo();
    }, [appoinmentId]);

    useEffect(() => {
        // Function to check if the selected date is today or in the future
        const isValidDate = (selectedDate: Moment) => {
            const currentDate = moment().startOf('day');
            return moment(selectedDate).isSameOrAfter(currentDate);
        };

        // Function to check if the time is after the start time
        const isValidTime = (selectedDate: Moment, startTime: string) => {
            const [startHour, startMinute] = startTime.split(':').map(Number); // Extract hour and minute from startTime
            const startMoment = moment(selectedDate).set({ hour: startHour, minute: startMinute });
            const currentMoment = moment();

            return currentMoment.isAfter(startMoment); // Check if the current time is after the start time
        };
        // Interval to check date and time validity every minute
        const checkTimeValidity = () => {
            const appointmentDate = moment(data?.appoinmentDate); // Assuming the date is in a valid format
            const appointmentTime = data?.startTime || ""; // Fallback to "10:00" if startTime is not provided

            if (appointmentDate && isValidDate(appointmentDate) && isValidTime(appointmentDate, appointmentTime)) {
                setIsTimeValid(true);
                clearInterval(intervalId); // Stop interval when time becomes valid
            }
        };
        const intervalId = setInterval(() => {
            if (!isTimeValid) {
                checkTimeValidity();
            }
        }, 1000); // Check every second
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [data]);

    const ModalBtnComponent: React.FC = () => {
        return <div className="flex gap-5">
            <PrimaryBtn
                ButtonText='Go Back'
                onPress={() => setCancelModal(false)}
                btnExtraStyle={{
                    borderRadius: "100rem",
                    padding: '5px 30px',
                    border: "1px solid #004540",
                    backgroundColor: "#fffcf1",
                    color: "#004540",
                    fontSize: '14px'
                }}
            />
            <PrimaryBtn
                ButtonText='Submit'
                loader={cancelLoader}
                onPress={cancelAppointment}
                btnExtraStyle={{
                    borderRadius: "100rem",
                    padding: '5px 30px',
                    backgroundColor: "#df0000",
                    color: "#fff",
                    fontSize: '14px'
                }}
            />
        </div>
    };
    return (
        <>
            <Modal
                open={roomId ? true : false}
                onClose={() => setRoomId(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={customeStyle}>
                    <button onClick={() => setRoomId(null)} style={backBtnMaain}>
                        <FontAwesomeIcon className='font-size-16px text-white' icon={faXmark} size="2x" />
                    </button>

                    <div className="flex flex-col gap-3">
                        <h1 className='font-size-24px font-Poppins-Medium'>Your Meeting's ready</h1>
                        <p className="font-size-16px font-Poppins-Medium">Copy this link</p>
                        <div className="p-3 rounded-md flex items-center justify-between gap-3 bg-[#f0f0f0]">
                            <p className="font-size-16px font-Poppins-Medium">{`${process.env.REACT_APP_DOMAIN}/online-consultation/${appoinmentId}/${roomId}/view`}</p>
                            <FontAwesomeIcon role="button" onClick={() => copyToClipBoard()} icon={faCopy} />
                        </div>
                        <div className="flex justify-center">
                            <PrimaryBtn
                                type="button"
                                onPress={handleNavigateRoom}
                                ButtonText="Go to Room"
                                btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={completModal}
                onClose={() => setCompletModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='complete-confirm-modal'
            >
                <div className="modal-container">
                    <button onClick={() => setCompletModal(false)} className="close-btn">
                        <FontAwesomeIcon className='font-size-16px text-white' icon={faXmark} size="2x" />
                    </button>
                    <div className="text-center">
                        <img src={modalIcon} className='img-fluid mx-auto' width={60} title='icon' />
                        <h1 className='py-4 font-size-22px font-Poppins-Medium'>Are you sure you want to complete this job?</h1>
                    </div>
                    <div className="flex justify-center items-center gap-3">
                        <PrimaryBtn
                            ButtonText='No'
                            onPress={() => setCompletModal(false)}
                            btnExtraStyle={{
                                borderRadius: "100rem",
                                border: "1px solid #004540",
                                backgroundColor: "#fff",
                                color: "#004540"
                            }}
                        />
                        <PrimaryBtn
                            loader={completedLoader}
                            onPress={createCompletRequest}
                            ButtonText='Yes'
                            btnExtraStyle={{
                                borderRadius: "100rem",
                                backgroundColor: "#004540"
                            }}
                        />
                    </div>
                </div>
            </Modal>
            <AddAReviewModal
                isOpen={reviewModal}
                onSuccess={() => setBool(prev => !prev)}
                onClose={() => { setReviewModal(false); setReviewComment(""); setStar(1); }}
                data={{
                    fullName: `${data?.doctorfirstName || ""} ${data?.doctorlastName || ""}`,
                    profileImage: data?.coverPhoto || "",
                    specialization: data?.specialization || [],
                }}
                appoinmentId={appoinmentId}
                comment={reviewComment}
                setComment={setReviewComment}
                star={star}
                setStar={setStar}
            />
            <DeclineModalReason
                title="Please specify the reason of cancellation"
                value={comment}
                setValue={(val) => setComment(val)}
                isOpen={cancelModal}
                onClose={() => setCancelModal(false)}
                ButtonComponent={ModalBtnComponent}
            />
            {isLoader && <SplashScreen />}
            <div className="table-container parent-box-appointment">
                <div className="parent-box w-[60%] relative overflow-hidden rounded-[15px]">
                    <div className="absolute top-0 left-0 w-full h-[35px] bg-[#00443F]"></div>
                    <div className='grid grid-cols-2 z-[10] relative justify-items-center items-center'>
                        {data &&
                            <>
                                <div className='self-center flex flex-col justify-center items-center'>
                                    <img
                                        alt='profile-image'
                                        width={75}
                                        height={75}
                                        className='rounded-full  img-fluid-res'
                                        style={{ border: "2px solid #fff" }}
                                        src={`${process.env.REACT_APP_API_BASEURL}${data.coverPhoto}`}
                                        onError={(e) => {
                                            const target = e.target as HTMLImageElement; // Assert the type
                                            if (target) {
                                                target.src = defaultProfile;
                                            }
                                        }}
                                    />
                                    <div className='text-center mt-2 mb-4 '>
                                        <p className="font-Poppins-Medium font-size-20px capitalize">
                                            {`${data.doctorfirstName} ${data.doctorlastName}`}
                                        </p>
                                        <p className="font-Poppins-SemiBold font-size-14px flex flex-wrap justify-center">
                                            {data.specialization.map((v: string, i: number) => (
                                                <span key={i}>
                                                    <span>{v}</span>
                                                    {(i + 1 < data.specialization.length) &&
                                                        <span className="mx-1">|</span>
                                                    }
                                                </span>
                                            ))}
                                        </p>
                                        <p className="font-Poppins-Regular font-size-15px text-[#393939] mt-2 mb-1 flex flex-wrap justify-center">
                                            {data.education.map((v: string, i: number) => (
                                                <span key={i}>
                                                    <span>{v}</span>
                                                    {(i + 1 < data.education.length) &&
                                                        <span className="mr-1">,</span>
                                                    }
                                                </span>
                                            ))}
                                        </p>
                                        <p className="font-Poppins-Regular font-size-16px text-[#1C1C1C]">
                                            Fee: AED {data.totalAmount}
                                        </p>
                                    </div>
                                    {(data.isOnlineAppoinment && mode === doctor && data.status === "pending") &&
                                        <PrimaryBtn
                                            loader={startCallLoader}
                                            type="submit"
                                            onPress={startCall}
                                            ButtonText='Start Call'
                                            btnExtraStyle={{
                                                borderRadius: "100rem",
                                                padding: '8px 40px',
                                                backgroundColor: "#004540"
                                            }}
                                        />
                                    }
                                </div>
                                <div className='w-full relative'>
                                    <div className='flex flex-col gap-2 pl-8 py-4 mt-4 border-l border-[#CBCBCB]'>
                                        <p className="font-Poppins-Regular font-size-16px text-[#1C1C1C]">
                                            Patient Name: {data.patientName}
                                        </p>
                                        <p className="font-Poppins-Regular font-size-16px text-[#1C1C1C]">
                                            Phone Number: {data.phone}
                                        </p>
                                        <p className="font-Poppins-Regular font-size-16px text-[#1C1C1C]">
                                            Date: {moment(data.appoinmentDate).format("DD-MM-YYYY")}
                                        </p>
                                        <p className="font-Poppins-Regular font-size-16px text-[#1C1C1C]">
                                            Time: {moment(data.startTime, "HH:mm").format("h:mm A")} - {moment(data.endTime, "HH:mm").format("h:mm A")}
                                        </p>
                                        {data?.status === "cancelled" ?
                                            <div className='appointment-badge cancel-btn-appointment'>
                                                <p className="text-red font-size-14px font-Poppins-SemiBold">Cancelled</p>
                                            </div>
                                            : data?.status === "completed" ?
                                                <div className='appointment-badge completed-btn-appointment'>
                                                    <p className="text-red font-size-14px font-Poppins-SemiBold">Completed</p>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="flex w-[60%] items-center justify-center gap-4 mt-[20px] mb-[10px]">
                    {(data?.status === "pending" && !isAdmin) &&
                        <PrimaryBtn
                            type="button"
                            ButtonText='Reschedule'
                            btnExtraStyle={{
                                borderRadius: "100rem",
                                padding: '5px 30px',
                                backgroundColor: "#004540"
                            }}
                            onPress={() => navigate(`/book-appointment/${data?.doctorId}/${data?.hospitalId}/view`, {
                                state: { isEdit: data }
                            })}
                        />
                    }
                    {(data?.status === "pending" && (mode === customer || isAdmin)) &&
                        <>
                            {data?.status === "pending" &&
                                <PrimaryBtn
                                    type="button"
                                    ButtonText='Cancel'
                                    onPress={() => setCancelModal(true)}
                                    btnExtraStyle={{
                                        borderRadius: "100rem",
                                        padding: '5px 30px',
                                        backgroundColor: "#004540"
                                    }}
                                />
                            }
                        </>
                    }
                    {(mode === customer && (data?.status === "pending" || data?.status === "completed")) &&
                        <PrimaryBtn
                            onPress={() => setReviewModal(true)}
                            type="button"
                            ButtonText='Review'
                            btnExtraStyle={{
                                borderRadius: "100rem",
                                padding: '5px 30px',
                                backgroundColor: "#004540"
                            }}
                        />
                    }
                    {(data?.status === "pending" && (isAdmin || mode === doctor) && isTimeValid) &&
                        <PrimaryBtn
                            type="button"
                            ButtonText='Complete'
                            onPress={() => setCompletModal(true)}
                            btnExtraStyle={{
                                borderRadius: "100rem",
                                padding: '5px 30px',
                                backgroundColor: "#004540"
                            }}
                        />
                    }
                    {/* {data?.status === "cancelled" &&
                        <p className="text-[red]">Cancelled Appointment</p>
                    } */}
                    {(data?.status === "completed" && mode !== customer) &&
                        <p className="text-[#004540]">Completed Appointment</p>
                    }
                </div>
                <AppointmentHistory
                    id={appoinmentId}
                    reCall={bool}
                    isEditModal={handleEditReview}
                />
            </div>
        </>
    );
};

export default AppointmentDetailComponent;