import React from 'react';
import { IChildCategory } from 'src/types/GlobalInterfaces';

interface IProps {
    onSuccess: () => void;
    isEdit: IChildCategory | false;
};

const UpsertChildCategory: React.FC<IProps> = ({
    onSuccess = () => { },
    isEdit = false,
}) => {
    return (
        <div>UpsertChildCategory</div>
    );
};

export default UpsertChildCategory;