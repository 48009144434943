/**
 * Payment Component
 * 
 * Description: This component handles the payment process using Stripe's API. It validates card details, 
 * processes payments, makes necessary API calls to create an order, fetches a payment intent, and checks 
 * payment status. Upon successful payment, it shows a thank-you modal and navigates the user back to the dashboard.
 * 
 * File Name: Payment.tsx
 * Date: 05-08-2024
 * 
 * Marker: DOC_START
 */

import React, { useState } from 'react';
import visaCard from '../../assets/images/visa-card.png';
import { useNavigate } from 'react-router-dom';
import {
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { ICreateOrder } from 'src/types/GlobalInterfaces';
import { CURRENCY } from 'src/lib/constans';
import { cartEmpty } from 'src/Store/Cart';
import { useDispatch } from 'react-redux';

interface IProps {
    data: ICreateOrder; // Data for the order including customer and payment details
    setIsPayMod?: (value: boolean) => void; // Function to toggle payment mode
};

const Payment: React.FC<IProps> = ({
    data,
    setIsPayMod = () => { }
}) => {
    const navigate = useNavigate(); // Hook to programmatically navigate
    const dispatch = useDispatch(); // Hook to dispatch actions to the Redux store
    const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state for button
    const [modal, setModal] = useState<boolean>(false); // Modal state for showing thank you message

    const stripe = useStripe(); // Stripe instance
    const elements = useElements(); // Elements instance for Stripe components

    // Function to handle modal click, navigates back to dashboard
    const handleClickModal = () => {
        navigate("/dashboard"); // back to dashboard
        dispatch(cartEmpty());
    };

    // Function to handle form submission
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault(); // Prevent default form submission
        if (!stripe || !elements) {
            console.error("Stripe or Elements not loaded");
            return;
        }

        // Get card elements
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);
        if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
            console.error("Card Element(s) not found");
            return;
        }

        // Validate card details
        setIsLoader(true);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement, // Use cardNumberElement for full validation
        });

        if (error) {
            toast(error.message, { type: 'error' });
            console.log("Validation error:", error.message);
            setIsLoader(false);
            return;
        }

        try {
            // First API Call for create order & receive order id
            const orderResponse = await httpRequest<{ message: string; order: { _id: string;[key: string]: any } }>({
                method: "post",
                path: "/order/add",
                params: data
            });

            if (orderResponse.err) {
                toast(orderResponse.err, { type: 'error' });
                throw new Error(orderResponse.err);
            };

            const orderId: string = orderResponse.res?.order?._id || "";


            // Second API Call (depends on the result of the first call) & receive intent
            const paymentIntentResponse = await httpRequest<{ clientSecret: string; paymentIntentId: string }>({
                method: "post",
                path: "/stripe/create-payment-intent",
                params: { orderId, currency: CURRENCY, amount: data.totalAmount }
            });

            if (paymentIntentResponse.err) {
                toast(paymentIntentResponse.err, { type: 'error' });
                throw new Error(paymentIntentResponse.err);
            };

            const paymentIntentId: string = paymentIntentResponse?.res?.paymentIntentId || "";
            const clientSecret: string = paymentIntentResponse?.res?.clientSecret || "";

            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod.id,
            }); // Confirm the payment using clientSecret and card element

            if (confirmError) {
                toast(confirmError.message, { type: 'error' });
                setIsLoader(false);
                return;
            }

            if (paymentIntent?.status === "succeeded") {
                // third API call (depends in the result of the second call)

                const payStatus = await httpRequest<any>({
                    method: "post",
                    path: "/stripe/get-payment-status",
                    params: { paymentIntentId }
                });

                if (payStatus.err) {
                    toast(payStatus.err, { type: 'error' });
                    throw new Error(payStatus.err);
                } else {
                    setModal(true);
                }
            } else {
                toast("Payment failed. Please try again.", { type: 'error' });
            }
        } catch (err) {
            console.error("Error during API calls:", err);
            toast('An error occurred during processing. Please try again.', { type: 'error' });
        } finally {
            setIsLoader(false);
        }
    };

    return (
        <>
            <div>
                <h2 className='font-size-22px theme-color-green font-Poppins-Regular'>Payment Method</h2>
                <p className='font-size-16px text-[#3B3B3B] font-Poppins-Regular'>Please enter your details below to complete your purchase</p>
                <form onSubmit={handleSubmit}>
                    <div className="payement-method">
                        <div className="payement-method-inner mb-5">
                            <div className="grid grid-cols-1 gap-5">
                                <div>
                                    <label className='font-size-16px text-[#959595] font-Poppins-Regular flex items-center gap-2' htmlFor="">Card Number
                                        {/* <img src={questionIcon} alt="question icon" /> */}
                                    </label>
                                    <div className='visa-card-number relative'>
                                        <img src={visaCard} alt="visa card" />
                                        <CardNumberElement
                                            className="stripe-des font-size-16px font-Poppins-Regular paymnet-form-input"
                                            id="cardNumber"
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                    <div>
                                        <label className='font-size-16px text-[#959595] font-Poppins-Regular' htmlFor="">Expiry Date</label>
                                        <CardExpiryElement
                                            className="stripe-des font-size-16px font-Poppins-Regular paymnet-form-input"
                                            id="CardExpiryElement"
                                        />
                                    </div>
                                    <div>
                                        <label className='font-size-16px text-[#959595] font-Poppins-Regular' htmlFor="">CVV
                                            {/* <img src={questionIcon} alt="question icon" /> */}
                                        </label>
                                        <CardCvcElement
                                            className="stripe-des font-size-16px font-Poppins-Regular paymnet-form-input"
                                            id="CardCvcElement"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='text-[#4F6367] mb-2 font-size-14px font-Poppins-Regular'>by completing this order you agree to the Terms & Conditions</p>
                        {/* <span className='text-[#959595] block font-size-18px font-Poppins-Regular'>Payment Method</span> */}
                    </div>
                    <div className="flex justify-between mb-5">
                        <PrimaryBtn
                            ButtonText="Back"
                            type='button'
                            onPress={() => setIsPayMod(false)}
                            btnExtraStyle={{ borderRadius: "1.5rem" }}
                        />
                        <PrimaryBtn
                            loader={isLoader}
                            ButtonText="Payment"
                            type='submit'
                            btnExtraStyle={{ borderRadius: "1.5rem", backgroundColor: "#004540" }}
                        />
                    </div>
                </form>
            </div>
            {modal &&
                <div className='modal-stripe-overlay'>
                    <div className='modal-stripe'>
                        <h2 className='text-[#2F2F31] leading-tight font-size-40px font-Poppins-SemiBold'>Thank You For <br />
                            The Purchase</h2>
                        <button onClick={handleClickModal} className='back-to-home-btn text-white font-size-18px font-Poppins-SemiBold'>Back To Home</button>
                    </div>
                </div>
            }
        </>
    );
};

export default Payment;