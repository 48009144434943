import Cookies from "js-cookie";
import { jwtDecode, JwtPayload } from "jwt-decode";
import moment from "moment";
import { toast } from "react-toastify";
import { barColors, CART_COOKIE_KEY, doctorMode } from "src/lib/constans";
import { ICartItems } from "src/types/GlobalInterfaces";

// Define a custom interface for the decoded JWT payload
export interface ICustomJwtPayload extends JwtPayload {
    userRole?: string | undefined; // Adjust the type if necessary
    userName?: string | undefined;
    exp: number;
    [key: string]: any;
};

export const decodedToken = () => {
    const token = getToken();
    if (token) {
        return jwtDecode<ICustomJwtPayload>(token);
    } else {
        return undefined;
    }
}

// Set the token in cookies with expiration handling
export const setToken = (token: string): void => {
    const decodedToken = jwtDecode<ICustomJwtPayload>(token);
    // Get the expiration time from the token's 'exp' claim
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
    const cookieOptionsCross = {
        expires: new Date(expirationTime),
        path: "/",
        domain: process.env.REACT_APP_DOMAIN, // Add domain here
    };
    Cookies.set(process.env.REACT_APP_TOKEN as string, token, cookieOptionsCross);
};

// Get the token from cookies
export const getToken = (): string | undefined => {
    return Cookies.get(process.env.REACT_APP_TOKEN as string);
};

// Remove the token from cookies
export const removeToken = (): void => {
    Cookies.remove(process.env.REACT_APP_TOKEN as string);
    Cookies.remove(doctorMode);
};

// truncate string
export const truncateString = (str: string = "", limit: number = 10): string => {
    if ((str ? str.length : 0) > limit) {
        return `${str.slice(0, limit)}...`
    } else {
        return str;
    }
};

// Validate email using regex
export const validateEmail = (email: string) => { // email regex for validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

// Validate password using regex
export const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return passwordRegex.test(password);
};

export const generateRandomKey = (): string => {
    const getRandomHex = (length: number) => {
        let result = '';
        const characters = '0123456789abcdef';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    return getRandomHex(24); // MongoDB ObjectId is 24 hexadecimal characters
};

export const getLastSegment = (str: string): string => {
    if (!str) return '';
    const segments = str.split("-");
    return segments.length > 0 ? segments[segments.length - 1] : '';
};

// Get cart IDs from cookies
export const getCartItemsFromCookie = (): ICartItems[] => {
    const arr = Cookies.get(CART_COOKIE_KEY);
    return arr ? JSON.parse(arr) : [];
};

// Set cart IDs to cookies
export const setCartItemsToCookie = (items: ICartItems[]) => {
    const cookieOptionsCross = {
        expires: 7,
        path: "/",
        domain: process.env.REACT_APP_DOMAIN, // Add domain here
    };
    Cookies.set(CART_COOKIE_KEY as string, JSON.stringify(items), cookieOptionsCross); // Cookie expires in 7 days
};

// status display
export const displayStatus = (txt: string): string => {
    switch (txt) {
        case "pending": return "Pending";
        case "in_progress": return "In Progress";
        case "delivered": return "Delivered";
        default: return "";
    }
};

export const ageCategory = (age: number): string => {
    if (age < 18) {
        return 'child';
    } else if (age <= 50) {
        return 'adult';
    } else {
        return 'elder';
    }
};

export const returnColor = (color: string): string => {
    switch (color) {
        case "green":
            return barColors.green;
        case "yellow":
            return barColors.yellow;
        case "orange":
            return barColors.orange;
        case "red":
            return barColors.red;
        default:
            return "#000";
    };
};

/**
 * Converts a number of months into a human-readable format.
 *
 * @param {number} totalMonths - The total number of months to convert.
 * @returns {string} The formatted string representing the number of years and months.
 */
export const convertMonthsToReadableFormat = (totalMonths: number): string => {
    if (totalMonths < 0) {
        throw new Error("Total months cannot be negative.");
    }

    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    let result = '';
    if (years > 0) {
        result += `${years} year${years > 1 ? 's' : ''}`;
    }
    if (months > 0) {
        if (result) {
            result += ' ';
        }
        result += `${months} month${months > 1 ? 's' : ''}`;
    }

    return result || 'Less Than One Year';
};

export const capitalize = (str: string): string => {
    if (!str) return ''; // Handle empty strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}


export const downloadFile = (url: string) => {
    try {
        if (!url) {
            throw new Error('File name could not be extracted from the URL.');
        }
        // Extract the filename from the URL
        const fileName: string = url.split('/').pop() || ""; // Get the last part of the URL
        fetch(`${process.env.REACT_APP_API_BASEURL}${url}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch the file.');
                }
                return response.blob();
            })
            .then(blob => {
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', fileName); // Use extracted filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Cleanup the link element
                window.URL.revokeObjectURL(blobUrl); // Cleanup the object URL
            })
            .catch(error => console.error('Download failed:', error));
    } catch (err: any) {
        toast(err?.message || "ERROR", { type: 'error' });
    }
};

export const convertToTimeAgo = (dateString: string): string => {
    const now = moment(); // Current time
    const inputTime = dateString ? moment(dateString) : moment(); // Input date-time

    const duration = moment.duration(now.diff(inputTime)); // Difference between now and the input time

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (days > 0) {
        return `${days}d ago`;
    }
    if (hours > 0) {
        return `${hours}h ago`;
    }
    if (minutes > 0) {
        return `${minutes}min ago`;
    }
    // Handle cases where the difference is less than a minute
    if (seconds > 0 || duration.asSeconds() <= 0) {
        return `few seconds ago`;
    }
    return `few seconds ago`; // Fallback for any other edge cases
};

export const getInitialsImage = (name: string = "", size: number = 0, fontSize: number = 16, bgColor: string = "#006ADB") => {
    const nameParts = name.split(' ');
    const firstInitial = nameParts[0]?.charAt(0).toUpperCase();
    const lastInitial = nameParts[1]?.charAt(0).toUpperCase() || '';
    // Generate a fallback image with initials as a data URL
    const initials = `${firstInitial}${lastInitial}`;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = size;
    canvas.height = size;
    if (context) {
        // Draw circle background
        context.fillStyle = bgColor; // background color
        context.fillRect(0, 0, canvas.width, canvas.height);
        // Set text properties
        context.font = `${fontSize}px sans-serif`;
        context.fillStyle = '#FFFFFF'; // text color
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(initials, canvas.width / 2, canvas.height / 2);
    }
    return canvas.toDataURL(); // Convert canvas to image data URL
};

export const convertToHyperlinks = (inputText: string = ""): string => {
    // Regular expression to detect URLs
    const urlRegex = /(\b(https?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]))/gi;

    // Replace URLs in the text with an anchor tag that opens in a new tab
    const formattedText = inputText.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" class="underline text-[#0000EE]" rel="noopener noreferrer">${url}</a>`;
    });

    return formattedText;
};