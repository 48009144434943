/**
 * Login Component
 * 
 * Description: This component handles the admin login process. It includes form fields for username and password, and functionality to toggle password visibility. 
 * The form submits the login details and displays an error message if any fields are empty.
 * 
 * File Name: Login.tsx
 * Date: 2024-07-22
 */

import React, { useState, FormEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PrimaryBtn from '../../Components/GlobalComponents/primaryBtn';
import InputFeild from '../../Components/InputFeild';
import httpRequest from "../../helpers/httpRequest";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { twoFactorKey } from "../../lib/constans";
import moment from "moment";

const Login: React.FC = () => {
  const navigate = useNavigate();

  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // State for username, password, and loader
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [keepMeLogedIn, setKeepMeLogedIn] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);

  // Define the response type based on the expected structure
  interface ILoginResponse {
    otpExpire?: string;
    message?: string;
    [key: string]: any;  // Allow for any additional properties
  }

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  // API_CALL: API call for login admin
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!userName || !password) {
      toast("All fields are required!", { type: 'error' });
      return;
    }
    setIsLoader(true);
    const { res, err } = await httpRequest<ILoginResponse>({ method: "post", path: "/auth/login", params: { userName, password, keepMeLogedIn } });
    if (res) {
      let { message = "" } = res;
      toast(message || "Success!", { type: 'success' });

      // Store relevant information in sessionStorage
      let obj = { userName, keepMeLogedIn, otpExpire: moment().add(2, 'minutes').toISOString() };
      sessionStorage.setItem(twoFactorKey, JSON.stringify(obj));

      // Redirect to OTP page
      navigate("/otp");

      // Reset form fields
      setUserName("");
      setPassword("");
      setKeepMeLogedIn(false);
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };

  return (
    <div>
      <section className="homebanner relative h-screen theme-bg-cream-white">
        <img
          decoding="async"
          className="w-full h-full absolute top-0 left-0 z-10 opacity-20 bg-texture-banner"
          src={'https://ndisync.backslashwebs.com/bg-login-form-optima.png'}
          alt=""
        />
        <div className="container-1480 h-full items-center relative z-20 flex justify-center">
          <div className=" home-banner-main-content-parent-padding theme-bg-green w-[55%] h-fit lg-dashboard">
            <div className=" home-banner-main-content-inner-right flex flex-col login-recovery-main">
              <div className="lg-welcome">
                <h4 className="uppercase font-Poppins-SemiBold font-size-32px text-white leading-none text-center lg-main-head ">
                  <i>Welcome!</i>
                </h4>
                <h4 className=" font-Poppins-Regular font-size-26px text-white leading-none text-center sub-login-recovery-head">
                  Please enter your login details
                </h4>
              </div>
              <form className="wellcome-login-admin flex flex-col" onSubmit={handleSubmit}>
                <InputFeild type="text" placeholder={"Username"} value={userName} onChange={setUserName} />
                <div className="relative">
                  <InputFeild type={showPassword ? "text" : "password"} placeholder={"Password"} value={password} onChange={setPassword} />
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    className="input-field-eye absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                </div>

                <div className="flex items-center gap-1">
                  <input checked={keepMeLogedIn} onChange={() => setKeepMeLogedIn(prev => !prev)} id="keep-me-logged-in" className="input-checkbox" type="checkbox" />
                  <label htmlFor="keep-me-logged-in" className="font-size-16px font-Poppins-SemiBold text-white inline-block cursor-pointer">
                    Keep me logged in
                  </label>
                </div>
                <div className="login-btn-home-banner-parent flex items-center justify-center">
                  <PrimaryBtn
                    ButtonText={'Login'}
                    loader={isLoader}
                    type={"submit"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div >
  );
};

export default Login;