/**
 * PaymentInfo
 * 
 * Description: Displays payment information such as account holder name, bank account number, 
 * and other relevant banking details. Shows loading skeletons while data is being fetched.
 * 
 * File Name: PaymentInfo.tsx
 * Date: 27-09-2024
 */

import { Skeleton } from '@mui/material';
import React from 'react';
import { IAccountInfo } from 'src/types/GlobalInterfaces';

const PaymentInfo: React.FC<{ loader?: boolean; accountInfo: IAccountInfo | null }> = ({ loader = false, accountInfo = null }) => {
    const listOfKeys: { label: string; key: keyof IAccountInfo }[] = [
        { label: "Account Holder Name", key: "accountHolderName" },
        { label: "Bank Account Number", key: "accountNumber" },
        { label: "Bank Name", key: "bankName" },
        { label: "Bank Branch Code", key: "bankBranchCode" },
        { label: "BIC / SWIFT Code", key: "swiftCode" },
        { label: "IBAN", key: "iban" },
        { label: "Routing Number", key: "routingNumber" },
    ];
    return (
        <div className='parent-box'>
            <h2 className='title-heading font-Poppins-SemiBold font-size-20px mb-3'>Payment Information</h2>
            {listOfKeys.map(v => (
                <div key={v.key} className='flex justify-between border-t items-center py-3'>
                    <span className='font-Poppins-Medium text-[#444444] font-size-18px w-[70%]'>{v.label}:</span>
                    <span className='font-Poppins-Regular text-[#444444] font-size-18px w-[30%]'>
                        {loader ? <Skeleton variant="text" className="font-size-18px" /> :
                            accountInfo ?
                                accountInfo[v.key] || <span className='block text-center'>-</span>
                                : <span className='block text-center'>-</span>
                        }
                    </span>
                </div>
            ))}
        </div>
    );
};

export default PaymentInfo;