/**
 * PayoutsAndWithDrawal Component
 * 
 * Description: This component manages the display and editing of payment and withdrawal information for users. 
 * It allows users to switch between payment details and withdrawal details tabs, fetch account information, 
 * and edit their payment information.
 * 
 * File Name: PayoutsAndWithDrawal.tsx
 * Date: 27-09-2024
 */

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageHeader from 'src/Components/Customer/PageHeader';
import EditPaymentInfo from 'src/Components/DoctorComponents/EditPaymentInfo';
import MyWithDrawalDetails from 'src/Components/DoctorComponents/MyWithDrawalDetails';
import PaymentInfo from 'src/Components/DoctorComponents/PaymentInfo';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import BackIcon from "src/assets/images/back-icon.png";
import httpRequest from 'src/helpers/httpRequest';
import { IAccountInfo } from 'src/types/GlobalInterfaces';

type tabType = "payment_details" | "withdrawal_details"; // Define tab types for toggling between tabs

const PayoutsAndWithDrawal: React.FC = () => {
    const tabList: { title: string; key: tabType }[] = [
        { title: "Payment details", key: "payment_details" },
        { title: "Withdrawal details", key: "withdrawal_details" },
    ];
    const [activeTab, setActiveTab] = useState<tabType>("payment_details");
    const [isInfoEdit, setIsInfoEdit] = useState<boolean>(false);

    const [getLoader, setGetLoader] = useState<boolean>(false);
    const [accountInfo, setAccountInfo] = useState<IAccountInfo | null>(null);

    const handleUpdateInfo = (obj: IAccountInfo) => {
        setIsInfoEdit(false);
        setAccountInfo(obj);
    };

    // API_CALL: fetch account information from the server
    const fetchAccountInfo = async () => {
        setGetLoader(true);
        const { res, err } = await httpRequest<{ result: IAccountInfo }>({
            path: "/paymentandwithdraw"
        });
        if (res) {
            setAccountInfo(res.result);
        } else {
            toast(err, { type: 'error' });
        }
        setGetLoader(false);
    };

    useEffect(() => {
        fetchAccountInfo();
    }, []);

    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => { setIsInfoEdit(false); setActiveTab("payment_details"); }}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    return (
        <>
            <PageHeader
                title="Payouts & Withdrawal"
                isButton={isInfoEdit || activeTab === "withdrawal_details"}
                ButtonComponent={BackButton}
            />
            <div className="parent-box parent-box-height">
                <div className="flex gap-4 items-center">
                    {
                        tabList.map((item, index: number) => (
                            <button
                                onClick={() => setActiveTab(item.key)}
                                key={index}
                                className={`border font-size-16px font-Poppins-SemiBold health-tabs-main-btn rounded-full ${activeTab === item.key ? "text-[#fff] theme-bg-green" : "theme-color-green border-[#004540]"}`}>
                                {item.title}
                            </button>
                        ))
                    }
                </div>
                <hr className='my-4' />
                {activeTab === "payment_details"
                    ?
                    isInfoEdit ?
                        <EditPaymentInfo
                            accountInfo={accountInfo}
                            onSuccess={handleUpdateInfo}
                        />
                        :
                        <div className="max-w-[600px]">
                            <div className="flex justify-end mb-4">
                                <PrimaryBtn
                                    onPress={() => setIsInfoEdit(true)}
                                    ButtonText="Edit"
                                    btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem",padding: "0.5rem 2.5rem" }}
                                />
                            </div>
                            <PaymentInfo
                                loader={getLoader}
                                accountInfo={accountInfo}
                            />
                        </div>
                    :
                    <MyWithDrawalDetails
                        accountInfo={accountInfo}
                    />
                }
            </div>
        </>
    );
};

export default PayoutsAndWithDrawal;