import React, { useEffect, useState } from 'react';
import { RootState } from '@store/store';
import { useSelector } from 'react-redux';

import LatesTest from 'src/Components/GlobalComponents/HealthProfiles/LatesTest';
import HealthScores from 'src/Components/GlobalComponents/HealthProfiles/HealthScores';
import Cookies from 'js-cookie';
import { customer, doctor, doctorMode } from 'src/lib/constans';
import DoctorDashboard from '../Doctor/DoctorDashboard';

const CustomerDashboard: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const uid = userDetails?._id || "";

    const [mode, setMode] = useState(Cookies.get(doctorMode) || customer);


    useEffect(() => {
        // Function to check for cookie changes
        const checkCookieChange = () => {
            const currentMode = Cookies.get(doctorMode) || customer;
            if (currentMode !== mode) {
                setMode(currentMode);
            }
        };

        // Set an interval to check for changes every second (1000ms)
        const intervalId = setInterval(checkCookieChange, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [mode]);

    return (
        <>
            {(userDetails?.userRole === doctor && mode === doctor)
                ?
                <DoctorDashboard />
                :
                <>
                    <HealthScores
                        id={uid}
                        isAdmin={false}
                    />
                    <LatesTest
                        id={uid}
                    />
                </>
            }
        </>
    );
};

export default CustomerDashboard;