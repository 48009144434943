import React from 'react';

interface DrawerProps {
    onClose: () => void;
    children: React.ReactNode;
    isOpen: boolean;
}

const CartDrawer: React.FC<DrawerProps> = ({ onClose, children, isOpen }) => {
    return (
        <>
            {/* Overlay */}
            {isOpen && (
                <div
                    onClick={onClose}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 999,
                    }}
                />
            )}

            {/* Drawer */}
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    width: '30%',
                    height: '100%',
                    backgroundColor: '#fff',
                    boxShadow: '-2px 0px 5px rgba(0,0,0,0.3)',
                    zIndex: 1000,
                    overflowY: 'auto',
                    transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
                    transition: 'transform 0.3s ease-in-out',
                }}
            >
                <div style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                }}>{children}</div>
            </div>
        </>
    );
};

export default CartDrawer;