/**
 * UpsertOperator Component
 * 
 * Description: This component facilitates the creation and updating of operator records, providing form fields 
 * for operator details such as name, username, email, role, and password. It manages state for form inputs, 
 * password visibility, and form submission. The component fetches available roles from the server, validates 
 * the input data, and performs API calls to create or update operators. It provides visual feedback 
 * through loaders and success/error messages using toast notifications.
 * 
 * Props:
 * - onSuccess (function): Callback function invoked upon successful form submission.
 * - isEdit (IOperatorItems | boolean): Optional. If provided as an object, it represents the operator 
 *   to be edited. If `false`, the component operates in add mode.
 * 
 * File Name: UpsertOperator.tsx
 * Date: 25-07-2024
 * 
 * Marker: DOC_START
 */

import React, { FormEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import SimpleInput from '../SimpleInput';
import httpRequest from '../../helpers/httpRequest';
import { toast } from 'react-toastify';
import SelectDropdown from '../GlobalComponents/SelectDropdown';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { validatePassword } from '../../helpers/helperFunctions';
import { errMsg } from '../../lib/constans';
import { IDropDownArray, IOperatorItems, IRoleItems } from 'src/types/GlobalInterfaces';

interface IUpsertOperatorProps {
    onSuccess: () => void; // Adjust the type as needed
    isEdit?: IOperatorItems | boolean;
};

const UpsertOperator: React.FC<IUpsertOperatorProps> = ({ onSuccess = () => { }, isEdit = false }) => {
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);

    const [getLoader, setGetLoader] = useState<boolean>(false);
    const [allRoles, setAllRoles] = useState<IDropDownArray[]>([]);

    const [role, setRole] = useState<IDropDownArray[]>([]);
    const [userName, setUserName] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [userRoleId, setUserRoleId] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [cnfrmPasword, setCnfrmPasword] = useState<string>("");

    const [isLoader, setIsLoader] = useState<boolean>(false);
    // API_CALL: API call for creating or editing a operator
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        // Validation checks
        if (!userName || !name || !email || !userRoleId || !password || !cnfrmPasword) {
            toast("All fields are required.", { type: 'error' });
        } else if (!validatePassword(password)) {
            toast(errMsg?.invalidPassword, { type: 'error' });
        } else if (password !== cnfrmPasword) {
            toast("Password & confirm password are not matched.", { type: 'error' });
        } else {
            setIsLoader(true);
            const path = isEdit && typeof isEdit !== 'boolean' ? `/operators/edit/${isEdit._id}` : "/operators/add";
            const { res, err } = await httpRequest<any>({ method: isEdit ? "put" : "post", path, params: { userName, name, email, userRoleId, password } });
            if (res) {
                toast(`Operator has been ${isEdit ? "updated" : "added"} successfully.`, { type: 'success' });
                onSuccess();
            } else {
                toast(err, { type: 'error' });
            }
            setIsLoader(false);
        }
    };

    const fetchAllRoles = async () => {
        setGetLoader(true);
        const { res, err } = await httpRequest<{ documents: IRoleItems[] }>({ path: "/roles/all" });
        if (res) {
            const { documents } = res;
            setAllRoles(documents.map(v => ({ label: v.roleName, value: v._id })));
        } else {
            toast(err, { type: 'error' });
        }
        setGetLoader(false);
    };

    useEffect(() => {
        fetchAllRoles();
    }, []);

    useEffect(() => {
        if (role.length > 0) {
            setUserRoleId(role[0]?.value || "");
        }
    }, [role]);

    useEffect(() => {
        if (isEdit && typeof isEdit !== 'boolean') {
            let { name = "", userName = "", email = "", roleName = "" } = isEdit;
            setName(name);
            setUserName(userName);
            setEmail(email);
            let _obj = allRoles?.find(v => v?.label === roleName);
            setRole([{ label: _obj?.label || "", value: _obj?.value || "" }]);
        }
    }, [isEdit, allRoles]); // Execute this useEffect hook if in edit mode to initialize form with existing operator data

    return (
        <div className="table-container">
            <div className='admin-create-role-main parent-box-scroll-bar-full'>
                <form onSubmit={handleSubmit}>
                    <div className='admin-create-role'>
                        <div className='create-operators-res  create-operators-res'>
                            {/* name */}
                            <SimpleInput
                                value={name}
                                onChange={setName}
                                isLabel={true}
                                labelText="Name"
                                isRequired={true}
                            />
                            {/* user name */}
                            <SimpleInput
                                value={userName}
                                onChange={setUserName}
                                isLabel={true}
                                labelText="User Name"
                                isRequired={true}
                            />
                            {/* email */}
                            <SimpleInput
                                type="email"
                                value={email}
                                onChange={setEmail}
                                isLabel={true}
                                labelText="Email"
                                isRequired={true}
                            />
                            {/* role */}
                            <SelectDropdown
                                loader={getLoader}
                                options={allRoles}
                                isMulti={false}
                                data={role}
                                setData={setRole}
                                checkboxShow={false}
                                isLabel={true}
                                labelText="Role"
                                isRequired={true}
                            />
                            <div className='flex gap-6'>
                                {/* password */}
                                <div className='w-[50%]'>
                                    <h3 className='font-size-18px font-Poppins-Regular'><span className='text-[#FF0000]'>* </span>Password </h3>
                                    <div className='relative'>
                                        <SimpleInput
                                            type={passwordVisible ? "text" : "password"}
                                            value={password}
                                            onChange={setPassword}
                                        />
                                        <FontAwesomeIcon
                                            icon={passwordVisible ? faEyeSlash : faEye}
                                            className="input-field-eye-operator absolute right-4 top-[43%] transform -translate-y-1/2 cursor-pointer"
                                            onClick={() => setPasswordVisible(prev => !prev)}
                                        />
                                    </div>
                                </div>
                                {/* confirm password */}
                                <div className='w-[50%]'>
                                    <h3 className='font-size-18px font-Poppins-Regular'><span className='text-[#FF0000]'>* </span> Confirm Password </h3>
                                    <div className='relative'>
                                        <SimpleInput
                                            type={confirmPasswordVisible ? "text" : "password"}
                                            value={cnfrmPasword}
                                            onChange={setCnfrmPasword}
                                        />
                                        <FontAwesomeIcon
                                            icon={confirmPasswordVisible ? faEyeSlash : faEye}
                                            className="input-field-eye-operator absolute right-4 top-[43%] transform -translate-y-1/2 cursor-pointer"
                                            onClick={() => setConfirmPasswordVisible(prev => !prev)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end my-6'>
                        <PrimaryBtn
                            ButtonText="Save"
                            loader={isLoader}
                            type="submit"
                            btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpsertOperator;