/**
 * SelectDropdown.tsx
 * 
 * This component provides a customizable multi-select dropdown using the `react-multi-select-component` library.
 * It supports both single-select and multi-select options, and includes optional features like labels, loaders, and custom styling.
 * 
 * Key Features:
 * - Supports multi-select and single-select modes.
 * - Allows passing a custom set of options and selected data.
 * - Optional features include labels, required fields, and dynamic classNames.
 * 
 * File Name: SelectDropdown.tsx
 * Date: 25-07-24
 */

import React from 'react';
import { MultiSelect, Option } from "react-multi-select-component";

// Define the prop types for SelectDropdown
interface ISelectDropdownProps {
    loader?: boolean;
    options: Option[];
    isMulti?: boolean;
    data: Option[];
    setData: (data: Option[]) => void;
    checkboxShow?: boolean;
    isLabel?: boolean;
    labelText?: string;
    isRequired?: boolean;
    extraClass?: string; // Correct type for CSS styles
    placeholder?: {[key: string]: string};
    clearIcon?: boolean;
    
}

const SelectDropdown: React.FC<ISelectDropdownProps> = ({
    loader = false,
    options = [],
    isMulti = false,
    data = [],
    setData = () => { },
    checkboxShow = false,
    isLabel = false,
    labelText = "",
    isRequired = false,
    extraClass = "",
    placeholder = undefined,
    clearIcon = true
}) => {

    const handleChange = (e: Option[]) => {
        if (isMulti) {
            setData(e);
        } else {
            if (e.length > 1) {
                setData([e[e.length - 1]]);
            } else {
                setData(e);
            }
        }
    };

    return (
        <>
            {isLabel &&
                <label className='font-size-18px font-Poppins-Regular'>
                    {isRequired &&
                        <span className='text-[#FF0000]'>* </span>
                    }
                    {labelText}
                </label>
            }
            <MultiSelect
                isLoading={loader}
                options={options}
                value={data}
                onChange={handleChange}
                labelledBy="Re Select"
                hasSelectAll={false}
                closeOnChangedValue={true}
                disableSearch={true} // Optional: Disable search if not needed
                className={`${extraClass} ${checkboxShow ? "" : "checkboxHide"}`}
                overrideStrings={placeholder}
                {...(!clearIcon && { clearIcon: null, ClearSelectedIcon: null })} // Conditionally add properties if clearIcon is false

                // customPlaceholder = {placeholder}
            />
        </>
    );
};

export default SelectDropdown;