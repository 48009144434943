import { AppDispatch } from '@store/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddressSocialDetail from 'src/Components/AddressSocialDetail';
import HealthSystem from 'src/Components/Customer/HealthSystem';
import ProfileUserDetailDashboard from 'src/Components/Customer/ProfileUserDetailDashboard';
import RecentTest from 'src/Components/Customer/RecentTest';
import httpRequest from 'src/helpers/httpRequest';
import { toggleLoader } from 'src/Store/reducer';
import { IProfileData } from 'src/types/GlobalInterfaces';

const ViewCustomerProfile: React.FC = () => {
    const { uid = "" } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const [profileData, setProfileData] = useState<IProfileData>({});

    /**
    * Fetch profile data from the API.
    * 
    * @param uid - The user ID to fetch the profile data.
    */
    const fetchProfileData = async (_id: string) => {
        dispatch(toggleLoader(true));
        const { res, err } = await httpRequest<IProfileData>({ path: `/customer/get/profile/${_id}` });
        if (res) {
            setProfileData(res);
        } else {
            toast(err, { type: 'error' });
        }
        dispatch(toggleLoader(false));
    };

    useEffect(() => {
        fetchProfileData(uid); // Fetch profile data when component mounts
    }, [uid]);
    return (
        <div style={{ minHeight: "800px" }} className='mt-5'>
            <div className="container-1480 h-3vw block justify-between h-3vw md:flex lg:flex">
                <div className="w-full md:w-3/4 lg:w-3/4">
                    <ProfileUserDetailDashboard
                        data={profileData}
                        setData={setProfileData}
                    />
                    <HealthSystem
                        id={uid}
                    />
                    <RecentTest
                        id={uid}
                    />
                </div>
                <div className="w-1/4 pl-2 show-location-detail-mob">
                    <AddressSocialDetail
                        data={profileData}
                    />
                </div>
            </div>
        </div>
    );
};

export default ViewCustomerProfile;