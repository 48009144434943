/**
 * DoctorRevenue Component
 * 
 * Description: Displays a bar chart showing the revenue data for doctors, either on a monthly or yearly basis. 
 * It allows users to toggle between monthly and yearly views and dynamically updates the chart based on the selected category.
 * A loading indicator is displayed while data is being fetched.
 * 
 * File Name: DoctorRevenue.tsx
 * Date: 12-09-2024
 */

import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ComposedChart,
    ResponsiveContainer,
} from 'recharts';
import { allMonths, allYears } from 'src/lib/constans';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';

interface IData {
    totalAmount: number;
    _id: { year: number; month: 8 };
};

interface IProps {
    isLoader: boolean;
    monthlyData: IData[];
    yearlyData: IData[]
}

const DoctorRevenue: React.FC<IProps> = ({
    isLoader = false,
    monthlyData = [],
    yearlyData = [],
}) => {
    const currentYear = new Date().getFullYear(); // Get the current year

    const [category, setCategory] = useState<string>("monthly");
    const [data, setData] = useState<{ name: string; value: number }[]>([]);

    useEffect(() => {
        if (category === 'monthly') {
            const formattedData = allMonths.map(({ month, name }) => {
                const _value = monthlyData.find(v => v._id.month === month && v._id.year === currentYear);
                return {
                    name: name, // Month name
                    value: _value ? _value.totalAmount : 0, // Default to 0 if no data
                };
            });
            setData(formattedData);
        } else if (category === 'yearly') {
            const formattedData = allYears.map(year => {
                const _value = yearlyData.find(v => v._id.year === year);
                return {
                    name: String(year), // year name
                    value: _value ? _value.totalAmount : 0, // Default to 0 if no data
                };
            });
            setData(formattedData);
        } else { }
    }, [category, monthlyData]);

    return (
        <div className='user-graph-inner h-full'>
            <div className='flex justify-between items-center'>
                <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px">Earnings</h2>
                {/* <div>
                    <select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className='font-Poppins-SemiBold user-graph-select theme-color-green font-size-14px'
                    >
                        <option className='font-Poppins-Medium font-size-14px' value="monthly">Monthly</option>
                        <option className='font-Poppins-Medium font-size-14px' value="yearly">Yearly</option>
                    </select>
                </div> */}
                <div className='select-container-graph'>
                    <SelectDropdown
                        options={[{ label: "Monthly", value: "monthly" }, { label: "Yearly", value: "yearly" }]}
                        isMulti={false}
                        data={[{ label: category, value: category }]}
                        setData={(e) => e[0] ? setCategory(e[0].value) : ""}
                        checkboxShow={false}
                        isLabel={false}
                        isRequired={true}
                        clearIcon={false}
                    />
                </div>
            </div>
            {
                isLoader
                &&
                <div className="text-center">
                    <CircularProgress size={20} style={{ color: "#004540" }} />
                </div>
            }
            <ResponsiveContainer className={'user-graph-container'}>
                <ComposedChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid />
                    <XAxis className='revenue-axis' dataKey="name" />
                    <YAxis className='revenue-axis' />
                    <Tooltip wrapperClassName='revenue-axis' />
                    <Bar dataKey={"value"} barSize={15} fill="#00a297" radius={[10, 10, 0, 0]} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DoctorRevenue;