/**
 * Component Name: ProductDetail
 * Description: This component fetches and displays detailed information about a single product, including its name, price, and other relevant details. It also handles adding the product to the cart and displaying a modal when the item is added.
 * File Name: ProductDetail.tsx
 * Date: 13-08-2024
 */

import { useEffect, useState } from 'react';
import bloodSample from '../assets/images/blood-sample-ye.png';
import biomarker from '../assets/images/biomarker-ye.png';
import result from '../assets/images/result-es-ye.png';
import metroMart from '../assets/images/metro-cart.png';
import ProductDescription from '../Components/AdminComponents/ProductDescription';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeader from 'src/Components/Customer/PageHeader';
import SplashScreen from 'src/Components/SplashScreen';
import httpRequest from 'src/helpers/httpRequest';
import { IProductItems } from 'src/types/GlobalInterfaces';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { addItemToCart } from 'src/Store/Cart';
import AddToCartModal from 'src/Components/Customer/AddToCartModal';
import BackIcon from "src/assets/images/back-icon.png";
import { truncateString } from 'src/helpers/helperFunctions';

const ProductDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams(); // Extract product ID from URL parameters
    const [isModal, setIsModal] = useState<boolean>(false); // State to control the visibility of the add-to-cart modal
    const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage the loading spinner visibility
    const [product, setProduct] = useState<IProductItems | null>(null); // State to store product details

    const _add_to_cart = (item: IProductItems) => {
        const { productName, salePrice, regularPrice, _id } = item;
        const obj = { productName, salePrice: Number(salePrice), regularPrice: Number(regularPrice), _id };
        dispatch(addItemToCart(obj)); // Dispatch action to add item to cart
        setIsModal(true); // Show modal after adding item to cart
    };

    // API_CALL: Function to fetch single product data
    const fetchSingleProduct = async (_id: string) => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IProductItems>({ path: `/product/single/byId/${_id}` });
        if (res) {
            setProduct(res || {}); // Update state with fetched product data
        } else {
            toast(err, { type: 'error' }); // Show error message if the request fails
        }
        setIsLoader(false); // Hide loading spinner
    };

    // Simulate fetching product data
    useEffect(() => {
        if (id) {
            fetchSingleProduct(id); // Fetch product data when component mounts or ID changes
        }
    }, [id]);

    // Display a splash screen while data is loading
    if (isLoader) return <SplashScreen />;

    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    return (
        <>
            <PageHeader
                title='Product'
                isButton={true}
                ButtonComponent={BackButton}
            />
            <AddToCartModal
                show={isModal}
                onClose={() => setIsModal(false)}
            />
            <div className='product-detail'>
                <div className='hormones-main'>
                    <div className='hormones-main-fir'>
                        <div>
                            <h2 className='font-size-35px text-white font-Poppins-Medium'>{product?.productName || ""}</h2>
                            {/* <strong className='font-size-30px text-white font-Poppins-SemiBold'>${product?.salePrice || 0}<span className='font-size-18px'>/Per test</span></strong> */}
                            <div className="flex items-center gap-1">
                                <strong className='font-size-30px text-white font-Poppins-SemiBold'>AED {product?.salePrice || 0}</strong>
                                <strong className='font-size-18px text-white font-Poppins-SemiBold line-through'>AED {product?.regularPrice || 0}</strong>
                            </div>
                        </div>
                        <div>
                            <div className='hormones-grid'>
                                <div className='hormones-grid-inner'>
                                    <img src={bloodSample} alt="" />
                                    <span className='font-size-18px text-white font-Poppins-Regular'>{product?.testypeDetails?.testName || ""}</span>
                                </div>
                                <div className='hormones-grid-inner'>
                                    <img src={biomarker} alt="" />
                                    <span className='font-size-18px text-white font-Poppins-Regular'>{product?.biomarkerData?.length || 0} Biomarkers</span>
                                </div>
                                <div className='hormones-grid-inner'>
                                    <img src={result} alt="" />
                                    <span className='font-size-18px text-white font-Poppins-Regular'>Results estimated in {product?.resultDays || 0} working days</span>
                                </div>
                            </div>
                            <p title={product?.description} className='font-size-18px text-white font-Poppins-Regular'>{truncateString(product?.description, 180)}</p>
                        </div>
                        <button onClick={() => { if (product) _add_to_cart(product) }} className='font-size-18px add-to-card-btn theme-color-green font-Poppins-SemiBold'>
                            <img src={metroMart} alt="cart" />
                            Add to cart
                        </button>
                    </div>
                    <div className='hormones-main-sec'>
                        <img
                            src={`${process.env.REACT_APP_API_BASEURL}${product?.productImage}`}
                            alt="product-image"
                        />
                    </div>
                </div>
            </div>
            <ProductDescription item={product} />
        </>
    );
};

export default ProductDetail;