/**
 * ChatLeftBar Component
 * 
 * Description: This component displays a sidebar for the chat section, showing the user's inbox and chat list with search functionality.
 * It fetches the list of recent chats and displays them with real-time search filtering.
 * 
 * Props: None
 * 
 * File Name: ChatLeftBar.tsx
 * Date: 23-09-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import SearchFilterInput from '../GlobalComponents/SearchFilterInput';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { CircularProgress, Skeleton } from '@mui/material';
import { ILatestChat } from 'src/types/GlobalInterfaces';
import defaultProfile from "src/assets/images/default_profile_image.png";
import moment from 'moment';
import inboxicon from "src/assets/images/inbox-icon.png";
import blockIcon from "src/assets/images/block-icon.png";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/Store/store';
import { setChatList } from 'src/Store/chat';
import { truncateString } from 'src/helpers/helperFunctions';
import PrimaryBtn from '../GlobalComponents/primaryBtn';

interface IBlockItem {
    _id: string;
    firstName: string;
    lastName: string;
};

const ChatLeftBar: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { latestChat, selectedUser } = useSelector((store: RootState) => store.chat);
    const [searchVal, setSearchVal] = useState<string>("");
    const [showBlockList, setShowBlockList] = useState<boolean>(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);

    // ************************ BLOCK STATES & FUNCTIONS ************************ //
    const [blockList, setBlockList] = useState<IBlockItem[]>([]);
    const [unBlockLoader, setUnBlockLoader] = useState<string | null>(null);
    /**
     *API_CALL: unblock the specified user from the conversation.
     */
    const handleUnBlockUser = async (id: string) => {
        setUnBlockLoader(id);
        const { res, err } = await httpRequest<{ message: string }>({
            path: `/block/person/${id}`,
        });
        if (res) {
            toast(res?.message || "User block Successfully ", { type: 'success' });
            setShowBlockList(false);
        } else {
            toast(err, { type: 'error' }); // Display error message if fetching fails
        }
        setUnBlockLoader(null);
    };
    // ************************ BLOCK STATES & FUNCTIONS ************************ //

    //API_CALL: Function to fetch the list of chats from the server
    const fetchChatList = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ documents: ILatestChat[] }>({ path: "/chat/list-all/users" });
        if (res) {
            dispatch(setChatList(res.documents)); // Store fetched chat list in Redux state
        } else {
            toast(err, { type: 'error' }); // Display error message if fetching fails
        }
        setIsLoader(false);
    };

    //API_CALL: Function to fetch the block list of chats from the server
    const fetchBlockList = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ docunment: IBlockItem[] }>({ path: "/block/all" });
        if (res) {
            setBlockList(res?.docunment || []);
        } else {
            toast(err, { type: 'error' }); // Display error message if fetching fails
        }
        setIsLoader(false);
    };

    // Fetch chat list when the component mounts
    useEffect(() => {
        if (showBlockList) {
            fetchBlockList();
        } else {
            fetchChatList();
        }
    }, [showBlockList]);

    return (
        <div className="mess-sidebar">
            <div className='user-mess-chat-header-left'>
                <div className="inbox-list-icon">
                    <img className='inbox-icon' src={inboxicon} />
                    <h2 className="font-Poppins-Regular font-size-16px">Inbox</h2>
                </div>
                <SearchFilterInput
                    containerExtraStyle={{ display: "block" }}
                    inputExtraStyle={{ width: "100%", borderRadius: 0, borderLeft: 0, borderRight: 0 }}
                    onChangeText={(val) => setSearchVal(val)}
                    placeHolder={"Search for people"}
                />
            </div>
            <div className="user-mess-chat-list-main">
                {isLoader ?
                    Array.from({ length: 5 }).map((_, index) => (
                        <div key={index} className="mt-4">
                            <div className="mb-2 flex justify-between gap-3 p-4 hover:bg-[#f1f1f1]">
                                <div className="flex items-center gap-3">
                                    <Skeleton variant="circular" width={40} height={40} />
                                    <div>
                                        <Skeleton width={100} variant="text" className="text-sm" />
                                        <Skeleton width={80} variant="text" className="text-xs" />
                                    </div>
                                </div>
                                <span>
                                    <Skeleton width={70} variant="text" className="text-sm" />
                                </span>
                            </div>
                        </div>
                    ))
                    :
                    showBlockList ?
                        <div className="user-mess-chat-main">
                            {blockList.length > 0
                                ?
                                blockList.filter(v => v?.firstName?.toLowerCase()?.includes(searchVal.toLowerCase()) || v?.lastName?.toLowerCase()?.includes(searchVal.toLowerCase())).map((item: IBlockItem) => (
                                    <div key={item?._id} className="user-mess-chat">
                                        <div className='flex justify-between items-center w-full'>
                                            <div className="flex items-center gap-3">
                                                <div className='user-img-online min-w-[36px]'>
                                                    <img
                                                        alt="User"
                                                        className="user-img-mes"
                                                        src={defaultProfile}
                                                    />
                                                </div>
                                                <div className="">
                                                    <p className="font-Poppins-Regular font-size-16px capitalize">{`${item?.firstName || ""} ${item?.lastName || ""}`}</p>
                                                </div>
                                            </div>
                                            <button onClick={() => handleUnBlockUser(item._id)} className='Unblock-button'>{item._id === unBlockLoader ? <CircularProgress style={{ color: "white" }} size={12} /> : "Unblock"}</button>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className="user-mess-chat my-4 flex justify-center">
                                    <p className='font-Poppins-Medium font-size-20px'>No Block user</p>
                                </div>
                            }
                        </div>
                        :
                        <div className="user-mess-chat-main">
                            {latestChat.length > 0
                                ?
                                latestChat.filter(v => v?.firstName?.toLowerCase()?.includes(searchVal.toLowerCase()) || v?.lastName?.toLowerCase()?.includes(searchVal.toLowerCase())).map((item: ILatestChat) => (
                                    <Link to={`/message/${item?._id}/view`} key={item?._id} className={`user-mess-chat ${item._id === selectedUser?._id ? "bg-[#f1f1f1]" : ""}`}>
                                        <div className="grid grid-chate-align items-center gap-3">
                                            <div className='user-img-online w-full'>
                                                {item?.unRead
                                                    ?
                                                    <span className="bg-red-500 w-[14px] h-[14px] rounded-full text-white flex justify-center items-center absolute right-0 text-[10px]">{item?.unRead || 0}</span>
                                                    :
                                                    null
                                                }
                                                <img
                                                    alt="User"
                                                    className="user-img-mes"
                                                    src={`${process.env.REACT_APP_API_BASEURL}${item?.image}`}
                                                    onError={(e) => {
                                                        const target = e.target as HTMLImageElement; // Assert the type
                                                        if (target) {
                                                            target.src = defaultProfile;
                                                        }
                                                    }}
                                                />
                                                {item.statusOnline &&
                                                    <div className='user-status' />
                                                }
                                            </div>
                                            <div className="w-full">
                                                <p className="font-Poppins-Regular font-size-16px capitalize">{`${item?.firstName || ""} ${item?.lastName || ""}`}</p>
                                                <p className="font-Poppins-Regular text-[#7A7A7A] font-size-13px" style={{ wordBreak: "break-word" }}>
                                                    {truncateString(item.lastMessage || "", 25)}
                                                </p>
                                            </div>
                                        </div>
                                        <span className="min-w-[60px] text-end">
                                            <p className="font-Poppins-Regular font-size-13px">{moment(item?.lastMessageTime || item?.createdAt).format("hh:mm A")}</p>
                                        </span>
                                    </Link>
                                ))
                                :
                                <div className="user-mess-chat my-4 flex justify-center">
                                    <p className='font-Poppins-Medium font-size-20px'>No Chat</p>
                                </div>
                            }
                        </div>
                }
            </div>
            <button onClick={() => setShowBlockList(prev => !prev)} className="block-list-icon">
                <img src={blockIcon} alt="" />
                <span className='font-Poppins-Regular font-size-14px'>{showBlockList ? "Hide" : "See"} Block List</span>
            </button>
        </div>
    );
};

export default ChatLeftBar;