/**
 * ReviewComponents Component
 * 
 * This component fetches and displays a list of reviews for a specific profile,
 * along with a pagination component for navigating through multiple pages of reviews.
 * It shows a loading state while fetching data and handles the display of default
 * profile images if the user's cover photo is unavailable.
 * 
 * File Name: ReviewComponents.tsx
 * Date: 01-10-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import defaultProfile from "src/assets/images/default_profile_image.png";
import ReviewLoader from './ReviewLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { convertToTimeAgo } from 'src/helpers/helperFunctions';
import PaginationComponet from './GlobalComponents/PaginationComponet';

interface IReviewItem {
    _id: string;
    comment: string;
    star: number;
    firstName: string;
    lastName: string;
    coverPhoto: string;
    createdAt: string;
};

const ReviewComponents: React.FC<{ id: string }> = ({ id = "" }) => {
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
    });
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [data, setData] = useState<IReviewItem[]>([]);
    // API_CALL: Fetch review from the serve
    const fetch = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ documents: IReviewItem[]; paginated: IPaginated; }>({ path: `/review/get-profile/${id}?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setData(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetch();
    }, [id, queryParams]);
    return (
        <>
            {isLoader ?
                <ReviewLoader length={5} />
                :
                data.map((item: IReviewItem, index: number) => (
                    <div key={index} className="reviews rounded-[15px] bg-[#FFFCF1] shadow-[3px_3px_10px_rgba(0,0,0,0.12)] w-full grid grid-cols-[1fr_4fr_1fr] gap-2 items-center mb-4 px-4 py-6">
                        <div className='flex items-center justify-center flex-col gap-1 grow'>
                            <img
                                src={`${process.env.REACT_APP_API_BASEURL}${item?.coverPhoto}`}
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement; // Assert the type
                                    if (target) {
                                        target.src = defaultProfile;
                                    }
                                }}
                                alt="profile-img"
                                className='rounded-full w-[65px] h-[65px]'
                            />
                            <h2 className="font-size-16px font-Poppins-Medium theme-color-green capitalize">
                                {`${item?.firstName || ""} ${item?.lastName || ""}`}
                            </h2>
                            <p className="font-size-13px font-Poppins-Regular leading-none">Patient</p>
                        </div>
                        <div>
                            <div className="flex gap-2 items-center justify-start mb-3">
                                {[1, 2, 3, 4, 5].map((v) => (
                                    <FontAwesomeIcon
                                        className={item.star >= v ? "text-[#facc15]" : `text-[#b7b6b1]`}
                                        key={v}
                                        role="button"
                                        fontSize="16px"
                                        icon={faStar}
                                    />
                                ))}
                            </div>
                            <p className="font-size-15px font-Poppins-Regular">{item?.comment || ""}</p>
                        </div>
                        <div className='font-size-13px font-Poppins-Regular text-[#636363] self-start ml-auto pr-2'>
                            {convertToTimeAgo(item.createdAt)}
                        </div>
                    </div>
                ))
            }

            <PaginationComponet
                currentPage={paginated.currentPage || 1}
                total={paginated.totalPages || 1}
                setCurrentPage={(_page) => setQueryParams(prev => ({ ...prev, page: _page }))}
            />
        </>
    );
};

export default ReviewComponents;