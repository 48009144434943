/**
 * TwoFactor Component
 * 
 * Description: This component handles the Two-Factor Authentication (2FA) process. It includes a form for entering a 4-digit OTP sent to the user's registered email, and a countdown timer for resending the code. It also manages the loading state during the verification process.
 * 
 * File Name: TwoFactor.tsx
 * Date: 2024-07-22
 */

import React, { useEffect, useState, FormEvent } from "react";
import VerificationInput from "react-verification-input";
import PrimaryBtn from '../../Components/GlobalComponents/primaryBtn';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import httpRequest from "../../helpers/httpRequest";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setAuth } from "../../Store/reducer";
import { twoFactorKey } from "../../lib/constans";
import { AppDispatch } from "@store/store";

interface ItwoFactor {
  keepMeLogedIn: boolean;
  otpExpire: string;
  userName: string;
  [key: string]: any;  // Allow for any additional properties
};

// Define the response type based on the expected structure
interface IOTPResponse {
  otpExpire?: string;
  message?: string;
  [key: string]: any;  // Allow for any additional properties
};

interface ItokenReceived {
  token: string
};

const TwoFactor: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string>(""); // State to store the OTP entered by the user
  const [isLoader, setIsLoader] = useState<boolean>(false); // State to handle loading indicator

  const [timeLeft, setTimeLeft] = useState<string>("00:00"); // Time left in seconds
  const [userName, setUserName] = useState<string>(""); // State to store the username
  const [keepMeLogedIn, setKeepMeLogedIn] = useState<boolean>(false); // State to store "Keep me logged in" checkbox status

  const [bool, setBool] = useState<boolean>(false); // State to trigger timer updates


  // API_CALL: API call for resent OTP
  const resendOtp = async () => {
    const { res, err } = await httpRequest<IOTPResponse>({ method: "post", path: "/auth/recentOtp", params: { userName } });
    if (res) {
      let { message = "" } = res;
      toast(message, { type: 'success' });

      // Store relevant information in sessionStorage
      let obj = { userName, keepMeLogedIn, otpExpire: moment().add(2, 'minutes').toISOString() };
      sessionStorage.setItem(twoFactorKey, JSON.stringify(obj));

      setBool(prev => !prev); // Trigger timer update
    } else {
      toast(err, { type: 'error' });
    }
  };

  // API_CALL: API call for verifying the OTP
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!otp || otp.length < 4) {
      toast("OTP is required!", { type: 'error' });
      return;
    }
    setIsLoader(true);
    const { res, err } = await httpRequest<ItokenReceived>({ method: "post", path: "/auth/verfifcation", params: { otp, userName, keepMeLogedIn } })
    if (res) {
      dispatch(setAuth(res?.token)); // Set authentication token in the state
      sessionStorage.removeItem(twoFactorKey); // Clear session storage
      navigate("/admin"); // Navigate to admin page
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };

  // Function to format time in MM:SS format
  const formatTime = (seconds: string) => {
    // Convert seconds to a number and handle invalid cases
    const totalSeconds = Number(seconds);
    if (isNaN(totalSeconds) || totalSeconds < 0) {
      return "00:00"; // Return default time format if conversion fails
    }
    const minutes = Math.floor(totalSeconds / 60);
    const secs = Math.floor(totalSeconds % 60);
    return `${minutes.toString().padStart(2, '0') || "00"}:${secs.toString().padStart(2, '0') || "00"}`;
  };

  // Effect to handle OTP expiration timer
  useEffect(() => {
    const storedData = sessionStorage.getItem(twoFactorKey);
    let info: ItwoFactor | null = null;

    if (storedData) {
      try {
        info = JSON.parse(storedData) as ItwoFactor;
      } catch (error) {
        console.error("Failed to parse session storage data:", error);
      }
    }
    if (!info) {
      navigate(-1); // Navigate back if no session info
    } else {
      setUserName(info?.userName);
      setKeepMeLogedIn(info?.keepMeLogedIn);
      const otpExpire = moment(info.otpExpire); // Assuming otpExpire is stored as a timestamp
      const interval = setInterval(() => {
        const now = moment();
        const diff = otpExpire.diff(now);
        if (diff <= 0) {
          setTimeLeft("00:00"); // Time has expired
          clearInterval(interval);
        } else {
          setTimeLeft(String(moment.duration(diff).asSeconds())); // Update time left
        }
      }, 1000);

      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, [bool]);

  return (
    <div>
      <section className="homebanner relative h-screen theme-bg-cream-white">
        <img
          decoding="async"
          className="w-full h-full absolute top-0 left-0 z-10 opacity-30 bg-texture-banner"
          src={'https://ndisync.backslashwebs.com/bg-login-form-optima.png'}
          alt=""
        />
        <div className="container-1480 h-3vw h-full relative z-20 flex items-center justify-center">
          <div className=" home-banner-main-content-parent-padding theme-bg-green w-1/2 h-fit lg-dashboard">
            <div className=" home-banner-main-content-inner-right flex flex-col login-recovery-main">
              <div className="flex flex-col gap-3">
                <div className="">
                  <h4 className="uppercase font-Poppins-SemiBold font-size-32px text-white leading-none text-center lg-main-head ">
                    <i> Two Factor Authentication
                    </i>
                  </h4>
                  <h4 className=" font-Poppins-Regular font-size-26px text-white leading-none text-center sub-login-recovery-head">
                    A 4 digit code sent to your registered email.
                  </h4>
                </div>
                <form onSubmit={handleSubmit} className="verification-code">
                  <VerificationInput
                    autoFocus={true}
                    length={4}
                    validChars="0-9"
                    placeholder=""
                    value={otp}
                    onChange={(e) => setOtp(e)}
                  />
                  <div className="flex items-center gap-1">
                    <span className="font-size-16px font-Poppins-Regular text-white mt-2 inline-block">
                      {formatTime(timeLeft)}
                    </span>
                    <button type="button" disabled={timeLeft !== "00:00"} onClick={resendOtp} className="font-size-16px font-Poppins-SemiBold text-white mt-2 inline-block">
                      Resend Code
                    </button>
                  </div>
                  <div className="login-btn-home-banner-parent-padding flex items-center justify-center">
                    <PrimaryBtn
                      ButtonText={'Verify'}
                      loader={isLoader}
                      type={"submit"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TwoFactor;