// File Description:
// This file contains the `CheckOutAndPayment` component, which manages the checkout and payment process for the application.
// It includes:
// 1. Handling of user details and cart items.
// 2. Form management using `react-hook-form` for order creation.
// 3. Conditional rendering of checkout and payment components based on the payment mode state.
// 4. Integration with Stripe for payment processing.
// 5. Navigation to a different page if no items are in the cart.

import { RootState } from '@store/store';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ConfrimOrder from 'src/Components/AdminComponents/ConfrimOrder';
import CheckOut from 'src/Components/Customer/CheckOut';
import Payment from 'src/Components/Customer/Payment';
import { ICreateOrder } from 'src/types/GlobalInterfaces';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';

// Initialize Stripe with the public key
const stripePromise = loadStripe(process.env.REACT_APP_VITE_STRIPE_PK as string);

const CheckOutAndPayment: React.FC = () => {
    const navigate = useNavigate();
    // Extract user details and cart items from Redux store
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const { cartItems, total } = useSelector((store: RootState) => store.cart);

    // Local state to toggle between checkout and payment components
    const [isPayMod, setIsPayMod] = useState<boolean>(false);

    // Initialize form with default values
    const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm<ICreateOrder>({
        defaultValues: {
            firstName: "",
            lastName: "",
            postCode: "",
            email: "",
            date: "",
            gender: "",
            city: "",
            address: "",
            mobile: "",
            totalAmount: total * 100,
            items: [],
        }
    });

    // Handle form submission
    // Set the payment mode to true to switch to the payment component
    const onSubmit = async (data: ICreateOrder) => {
        setIsPayMod(true);
    };

    useEffect(() => {
        // Redirect to dashboard if cart is empty
        if (cartItems.length < 1) {
            navigate("/dashboard");
            return
        }
        // Populate form with user details and cart items
        if (userDetails) {
            setValue("firstName", userDetails?.firstName);
            setValue("lastName", userDetails?.lastName);
            setValue("email", userDetails?.email);
            setValue("date", moment(userDetails?.date).format("YYYY-MM-DD"));
            setValue("gender", userDetails?.gender);
            setValue("mobile", userDetails?.mobile);
            setValue("address", userDetails?.address);
            setValue("city", userDetails?.city);
            setValue("postCode", userDetails?.postCode);
            setValue("items", cartItems.map(v => ({ productId: v?._id, quantity: 1 })));
            setValue("totalAmount", total * 100);
        }
    }, [userDetails, cartItems]);

    return (
        <div className='checkout-main h-screen flex items-center'>
            {/* <div className='checkout-main-inner'> */}
            <div className='container-1480 h-3vw'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-10'>
                    {
                        !isPayMod
                            ?
                            <CheckOut
                                control={control}
                                setValue={setValue}
                                errors={errors}
                                submit={handleSubmit(onSubmit)}
                            />
                            :
                            <Elements stripe={stripePromise}>
                                <Payment
                                    data={getValues()}
                                    setIsPayMod={setIsPayMod}
                                />
                            </Elements>
                    }
                    <ConfrimOrder />
                </div>
            </div>
        </div>
    );
};

export default CheckOutAndPayment;