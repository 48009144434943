/**
 * AboutMe Component
 * 
 * Description: This component displays the "About Me" section of a doctor's profile. 
 * It shows the profile's "aboutMe" information and includes an edit icon if the profile belongs to the currently logged-in user.
 * 
 * Props:
 * - isMyProfile (boolean): Indicates if the current profile is the user's own profile. If true, an edit icon is shown.
 * - aboutMe (string): The "About Me" text of the profile, which is fetched from the Redux store.
 * 
 * Usage:
 * This component fetches the profile's "aboutMe" text and displays it. If the text exceeds 500 characters, it provides a button to toggle between showing a truncated version and the full text.
 * If `isMyProfile` is true, an edit icon is displayed that links to the edit page.
 * 
 * File Name: AboutMe.tsx
 * Date: 02-09-2024
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import edit from "../../../assets/images/awesome-edit.svg";
import { truncateString } from 'src/helpers/helperFunctions';

const AboutMe: React.FC<{ isMyProfile: boolean; aboutMe: string }> = ({ isMyProfile = false, aboutMe = "" }) => {
    const [fullText, setFullText] = useState<boolean>(false);

    return (
        <div className="parent-box parent-box-margin-issue relative">
            <h2 className='font-size-20px font-Poppins-SemiBold theme-color-green mb-1'>About Me</h2>
            <p className='font-Poppins-Regular font-size-18px leading-normal pr-10'>
                {fullText
                    ?
                    aboutMe
                    :
                    truncateString(aboutMe, 500)
                }

                {(aboutMe?.length || 0) > 500 &&
                    <button onClick={() => setFullText(prev => !prev)} className="underline ms-2">
                        {fullText ? "See less" : "See more"}
                    </button>
                }
            </p>
            {isMyProfile
                &&
                <Link to="/aboutme" className="edit-pro-icon absolute top-2 right-4">
                    <img src={edit} alt="edit-icon" />
                </Link>
            }
        </div>
    );
};

export default AboutMe;