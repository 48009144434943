/**
 * DeleteAccount Component
 * 
 * Description:
 * This component provides a modal for users to confirm the deletion of their account. It includes a form where users must enter their
 * password to proceed with the account deletion. The modal displays a confirmation message and two buttons: one to submit the request
 * and one to cancel. The component also includes a button to trigger the modal's visibility.
 * 
 * Props: None
 * 
 * File Name: DeleteAccount.tsx
 * Date: 10-08-2024
 * 
 * Marker: DOC_START
 */

import React, { FormEvent, useState } from 'react';
import { Modal } from '@mui/material';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import SimpleInput from '../SimpleInput';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { removeToken } from 'src/helpers/helperFunctions';
import { removeAuth } from 'src/Store/reducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface iAPIResponse {
  message: string;
  [key: string]: any
}; // Define the shape of the API response

const DeleteAccount: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>(""); // State for storing the password input
  const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state to show loading indicator
  const [isModalShow, setIsModalShow] = useState<boolean>(false); // State for showing and hiding the modal

  // Style object for the modal
  const customeStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(255, 255, 255)",
    maxWidth: 600,
    width: "100%",
    padding: 30,
    borderRadius: 8
  };

  // API_CALL: Handle API call for account deletion
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoader(true);
    const { res, err } = await httpRequest<iAPIResponse>({ method: "delete", path: "/customer", params: { password } });
    if (res) {
      toast(res?.message || "Success!", { type: 'success' });
      dispatch(removeAuth()); // logout
      removeToken();
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };

  return (
    <>
      {/* Modal for account deletion confirmation */}
      {
        isModalShow
        &&
        <Modal
          open={isModalShow}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={customeStyle}>
            <h1 className='title-heading font-size-36px font-Poppins-Regular text-center mb-12'>Healthcare</h1>
            <div className='mb-6'>
              <h2 className='font-size-40px mb-4'>Confirm account deletion</h2>
              <p className="font-poppins-Regular">
              You are about to submit a request for us to permanently close your account and delete your data. Once your account has been closed, all of the products and services accessed through your account will no longer be available to you.
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className='w-[60%]'>
                <SimpleInput
                  isLabel={true}
                  labelText='Password:'
                  type={showPassword ? "text" : "password"}
                  showPasswordEye={true}
                  tooglePasswordVisibility={() => setShowPassword(prev => !prev)}
                  isRequired={true}
                  extraStyle={{ borderColor: "#4F6367", marginBottom: 0, boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.16)" }}
                  onChange={(val) => setPassword(val)}
                  value={password}
                />
              </div>
              <div className="flex gap-8 mt-8">
                {/* form submit button */}
                <PrimaryBtn
                  loader={isLoader}
                  type="submit"
                  ButtonText="Submit"
                  btnExtraStyle={{ backgroundColor: "#00443f", padding: "8px 32px", fontFamily: "poppins-regular", boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.16)" }}
                />
                {/* modal hide button */}
                <PrimaryBtn
                  type="submit"
                  ButtonText="Cancel"
                  btnExtraStyle={{ backgroundColor: "transparent", border: "1px solid #4F6367", color: '#4F6367', padding: "8px 32px", fontFamily: "poppins-regular", boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.16)" }}
                  onPress={() => setIsModalShow(false)}
                />
              </div>
            </form>
          </div>
        </Modal>
      }
      <div className='parent-box'>
        <h2 className='title-heading font-Poppins-SemiBold font-size-20px pb-5'>Delete Account</h2>
        <p className='font-Poppins-Regular'>
          Before you delete your account, we would want you to know that this action will delete your data across entire platform. If that's what you want, please
          proceed with entering your password to confirm that it's you.
        </p>
        <div className='mt-[25px]'>
          <PrimaryBtn
            ButtonText="Delete My Account"
            btnExtraStyle={{ backgroundColor: "#E35252", borderRadius: "1.5rem", padding: "8px 32px", fontFamily: "poppins-regular" }}
            onPress={() => setIsModalShow(prev => !prev)}
          />
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;