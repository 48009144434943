/**
 * ChatRightBar Component
 * 
 * Description: This component displays the details of the selected user 
 * along with files shared in the chat. It allows users to toggle between 
 * viewing all files and their own files. It also includes loading states 
 * and user information.
 * 
 * Props: None
 * 
 * File Name: ChatRightBar.tsx
 * Date: 26-09-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faFile, faEnvelope, faLocationDot, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import defaultProfile from "src/assets/images/default_profile_image.png";
import { downloadFile, truncateString } from 'src/helpers/helperFunctions';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import qs from "qs";
import { printCity } from 'src/Config/CityList';

interface IFile {
    _id: string;
    fileUrl: string;
    messageBody: string;
    createdAt: string;
};

const ChatRightBar: React.FC = () => {
    const { selectedUser, chatLoader } = useSelector((store: RootState) => store.chat);
    const { id = "" } = useParams();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [allFiles, setAllFiles] = useState<IFile[]>([]);

    const [queryParams, setQueryParams] = useState<{ method: "all" | "my" }>({
        method: "all"
    });

    //API_CALL: Fetch files shared in the chat
    const fetchFiles = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ documents: IFile[] }>({
            path: `/chat/files/get/${id}?${qs.stringify(queryParams)}`,
        });
        if (res) {
            setAllFiles(res.documents || []);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (id) {
            fetchFiles();
        }
    }, [id, queryParams]);

    return (
        <div className="chat-rightbar">
            {chatLoader ?
                <div className="flex flex-col gap-1 items-center mb-4">
                    <Skeleton variant="circular" width={80} height={80} />
                    <Skeleton variant="text" width={150} className="font-size-14px" />
                    <Skeleton variant="text" width={120} className="font-size-14px" />
                    <div className="flex flex-col items-center">
                        <Skeleton variant="text" width={250} className="font-size-14px" />
                        <Skeleton variant="text" width={200} className="font-size-14px" />
                        <Skeleton variant="text" width={150} className="font-size-14px" />
                    </div>
                </div>
                :
                <>
                    <div className='chat-rightbar-detail-inner-main'>
                        <div className="text-center">
                            <img
                                alt="Profile"
                                className="rounded-full user-img-chat-right"
                                src={`${process.env.REACT_APP_API_BASEURL}${selectedUser?.photo}`}
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement; // Assert the type
                                    if (target) {
                                        target.src = defaultProfile;
                                    }
                                }}
                            />
                            <h2 className="font-Poppins-Medium font-size-14px capitalize">{`${selectedUser?.firstName || ""} ${selectedUser?.lastName || ""}`}</h2>
                            <p className="font-Poppins-Regular border-b border-b-[#CBCBCB] pb-2 font-size-14px w-[80%] mx-auto text-[#646464]">{truncateString(selectedUser?.about || "-", 80)}</p>
                        </div>
                        <div className="chat-user-detail">
                            {selectedUser?.mobile &&
                                <div className="chat-user-detail-inner">
                                    <FontAwesomeIcon className="text-[#202020]" icon={faPhone} />
                                    <p className="font-Poppins-Regular text-[#202020] font-size-14px">{selectedUser.mobile}</p>
                                </div>
                            }
                            {selectedUser?.email &&
                                <div className="chat-user-detail-inner">
                                    <FontAwesomeIcon className="text-[#202020]" icon={faEnvelope} />
                                    <p className="font-Poppins-Regular text-[#202020] font-size-14px">{selectedUser.email}</p>
                                </div>
                            }
                            {selectedUser?.addressSection &&
                                <div className="chat-user-detail-inner">
                                    <FontAwesomeIcon className="text-[#202020]" icon={faLocationDot} />
                                    <p className="font-Poppins-Regular text-[#202020] font-size-14px">{truncateString(`${printCity(selectedUser.addressSection?.city) || ""} ${selectedUser.addressSection?.state || ""} ${selectedUser.addressSection?.address || ""}`, 20)}</p>
                                </div>
                            }
                            <div className="chat-user-detail-inner">
                                <FontAwesomeIcon className="text-[#202020]" icon={faClockRotateLeft} />
                                <p className="font-Poppins-Regular text-[#202020] font-size-14px">{`User Since ${moment(selectedUser?.createdAt).format('DD MMM, YYYY')}`}</p>
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="all-files-my-files-main">
                <div className='all-files-my-files'>
                    <button onClick={() => setQueryParams({ method: "all" })} className={`font-Poppins-Regular font-size-14px text-[#202020] ${queryParams.method === "all" ? "active-button text-white" : ""}`}>All Files</button>
                    <button onClick={() => setQueryParams({ method: "my" })} className={`font-Poppins-Regular font-size-14px text-[#202020] ${queryParams.method === "my" ? "active-button text-white" : ""}`}>My Files</button>
                </div>
                <div className='my-files-data-main'>
                    {isLoader &&
                        <div className="text-center">
                            <CircularProgress size={20} style={{ color: "#004540" }} />
                        </div>
                    }
                    {allFiles.map((item) => (
                        <div key={item._id} className='my-files-data'>
                            <div className='flex gap-2'>
                                <FontAwesomeIcon className='font-size-14px' icon={faFile} />
                                <p className='font-Poppins-Medium text-[#00A297] filname-andword-limit font-size-11px' style={{ wordBreak: "break-word" }}>{item.messageBody}</p></div>
                            <div className='flex flex-col gap-1 items-center'>
                                <svg
                                    className='cursor-pointer'
                                    onClick={() => downloadFile(item.fileUrl)}
                                    fill="none"
                                    height={15}
                                    width={15}
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{ display: "inline-block", verticalAlign: "middle" }}
                                >
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                    <polyline points="7 10 12 15 17 10" />
                                    <line x1={12} y1={15} x2={12} y2={3} />
                                </svg>
                                <p className='font-Poppins-Regular font-size-10px'>{moment(item.createdAt).format('MMMM Do, h:mm A')}</p></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ChatRightBar