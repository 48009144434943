/**
 * ActivityLog Component
 * 
 * Description: This component displays activity logs for the user. It includes functionality for filtering logs based on different categories, infinite scrolling for loading more logs, and handles loading and error states. It fetches activity logs from an API and displays them with relevant details.
 * 
 * File Name: ActivityLog.tsx
 * Date: 03-09-2024
 */

import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import SplashScreen from 'src/Components/SplashScreen';
import moment from 'moment';
import defaultProfile from "src/assets/images/default_profile_image.png";
import qs from "qs";
import { IPaginated } from 'src/types/paginated';
import InfiniteScroll from "react-infinite-scroll-component";
import { doctor } from 'src/lib/constans';

// Interface for activity log item
interface IActivityLogs {
    log: string,
    createdAt: string,
};

const ActivityLog: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const activityAllOptions: { label: string; key: string; display: boolean }[] = [
        { label: "Profile", key: "profile", display: true },
        { label: "Tests", key: "tests", display: true },
        { label: "Health Profile", key: "health__profile", display: true },
        { label: "Appointment", key: "appoinment", display: true },
        { label: "Access", key: "access", display: true },
        { label: "Discussion", key: "discussion", display: true },
        { label: "Chat", key: "chat", display: true },
        { label: "Withdraw", key: "withdraw", display: userDetails?.userRole === doctor },
    ]; // Options for activity filters

    // State for activity logs, query parameters, and pagination
    const [activityLogs, setActivityLogs] = useState<IActivityLogs[]>([]);
    const [queryParams, setQueryParams] = useState({
        page: 1,
        pageSize: 10,
        search: "profile",
    }); // query params for APIs
    const [isLoader, setIsLoader] = useState(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination

    // Change tab and reset activity logs based on selected filter
    const changeTab = (key: string) => {
        setActivityLogs([]);
        setQueryParams(prev => (
            { ...prev, page: 1, search: key }
        ));
    };

    //API_CALL: Fetch activity logs from API
    const fetchActivityLogs = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ paginated: IPaginated; documents: IActivityLogs[] }>({ path: `/activitylog?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setActivityLogs(prev => [...prev, ...documents]);
            setPaginated(paginated);
        }
        else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Initial fetch of activity logs when component mounts or queryParams change
    useEffect(() => {
        fetchActivityLogs();
    }, [queryParams])

    return (
        <>
            <div className="parent-box">
                <h2 className='font-size-20px font-Poppins-SemiBold theme-color-green mb-1'>Activity Log</h2>
            </div>

            <div className="parent-box min-h-[75vh]">
                {/* filters section */}
                <div className="filters flex flex-wrap items-center gap-4">
                    {activityAllOptions.filter(v => v.display).map(item => (
                        <button
                            disabled={queryParams.search === item.key}
                            key={item.key}
                            onClick={() => changeTab(item.key)}
                            className={`font-Poppins-Medium font-size-18px capitalize filter ${queryParams.search === item.key && "active"}`}>{item.label}</button>
                    ))}
                </div>
                <hr className='mt-4' />
                {/* logs section */}
                <div>
                    {isLoader && <SplashScreen />}
                    <InfiniteScroll
                        dataLength={activityLogs.length}
                        next={() =>
                            setQueryParams((prev) => ({ ...prev, page: prev.page + 1 }))
                        }
                        hasMore={queryParams.page < paginated.totalPages}
                        loader={isLoader && <SplashScreen />}
                        endMessage={
                            // <div className="text-center my-12">
                            //     <p className="font-Poppins-Regular font-size-20px">...</p>
                            // </div>
                            <></>
                        }
                    >
                        {activityLogs.map((item, index) => (
                            <div key={index} className='my-2 py-2 rounded-lg'>
                                <div className='flex items-center justify-between'>
                                    <div className='flex gap-4 items-center justify-start w-[80%]'>
                                        <img
                                            src={`${process.env.REACT_APP_API_BASEURL}${userDetails?.coverPhoto}`}
                                            onError={(e) => {
                                                const target = e.target as HTMLImageElement; // Assert the type
                                                if (target) {
                                                    target.src = defaultProfile;
                                                }
                                            }}
                                            alt="profile image"
                                            className="rounded-full"
                                            width={60}
                                            height={60}
                                        />
                                        <h1 className="font-size-20px font-Poppins-SemiBold theme-color-green flex-shrink-0">
                                            {`${userDetails?.firstName} ${userDetails?.lastName}`}
                                        </h1>
                                        <p className="font-size-20px font-Poppins-Medium truncate ...">
                                            {item.log}
                                        </p>
                                    </div>
                                    <div className="date">
                                        <p className="font-Poppins-Medium font-size-18px text-[#707070]">{moment(item.createdAt).format("DD-MM-YYYY")}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </>
    );
};

export default ActivityLog;