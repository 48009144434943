/**
 * UpsertRole Component
 * 
 * Description: This component allows administrators to create or edit a role by specifying a role name and assigning permissions to various modules.
 * It includes input fields for role name and checkboxes for setting permissions. 
 * On form submission, the role is either added or updated via an API call and the result is handled with toast notifications.
 * 
 * Props:
 * - onSuccess: Callback function to execute upon successful role creation or update.
 * - isEdit: If true, the component is in edit mode and will populate the form with existing role data.
 * 
 * File Name: UpsertRole.tsx
 * Date: [Insert date here]
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import SimpleInput from '../SimpleInput';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { toast } from 'react-toastify';
import httpRequest from '../../helpers/httpRequest';
import { PermissionType } from 'src/types/permissions';
import { IRoleItems } from 'src/types/GlobalInterfaces';

interface IUpserRoleProps {
  onSuccess: () => void; // Adjust the type as needed
  isEdit?: IRoleItems | boolean;
};

interface IPermission {
  biomarker: PermissionType;
  product: PermissionType;
  order: PermissionType;
};

const UpsertRole: React.FC<IUpserRoleProps> = ({ onSuccess = () => { }, isEdit = false }) => {
  // Function to map permission keys to their corresponding labels
  const getLabel = (key: string) => {
    switch (key) {
      case "biomarker":
        return "Biomarker";
      case "product":
        return "Product";
      case "order":
        return "Order";
      default:
        return "";
    }
  };

  const [roleName, setRoleName] = useState<string>(""); // State to manage the role name input
  const [permissions, setPermissions] = useState<IPermission>({
    biomarker: "null",
    product: "null",
    order: "null",
  }); // State to manage permissions for each module

  const [isLoader, setIsLoader] = useState<boolean>(false);  // State to manage the loading state of the submit button

  // Handler for changing checkbox values for permissions
  const handleCheckboxChange = (key: keyof IPermission, value: PermissionType, checked: boolean) => {
    setPermissions(prev => ({
      ...prev,
      [key]: checked ? value : (permissions[key] === "read/write" && value === "read/write") ? "read" : "null"
    }));
  };

  // API_CALL: API call for creating or editing a role
  const handleSubmit = async () => {
    // Validation checks
    if (!roleName) {
      toast("Provide the role name!", { type: 'error' });
    } else if (Object.values(permissions).every(value => value == "null")) {
      toast("Please select any permissions!", { type: 'error' });
    } else {
      setIsLoader(true);
      const path = isEdit && typeof isEdit !== 'boolean' ? `/roles/edit/${isEdit._id}` : "/roles/add";

      const { res, err } = await httpRequest<IRoleItems>({ method: isEdit ? "put" : "post", path, params: { roleName, ...permissions } });
      if (res) {
        toast(`Role has been ${isEdit ? "updated" : "added"} successfully.`, { type: 'success' });
        onSuccess();
      } else {
        toast(err, { type: 'error' });
      }
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (isEdit && typeof isEdit !== 'boolean') {
      let { roleName = "", biomarker = "null", product = "null", order = "null" } = isEdit;
      setRoleName(roleName);
      setPermissions({
        biomarker: biomarker,
        product: product,
        order: order,
      });
    }
  }, [isEdit]); // Execute this useEffect hook if in edit mode to initialize form with existing role data

  return (
    <div className="table-container">
      <div className='admin-create-role-main'>
        <div className='admin-create-role'>
          <div className='create-operators-res '>
            <SimpleInput
              value={roleName}
              onChange={setRoleName}
              isLabel={true}
              labelText="Role Name"
              isRequired={true}
            />
          </div>
          <hr />
          <div className='create-operators-res '>
            <ul className='admin-create-role-ul'>
              <li className='font-size-18px font-Poppins-SemiBold'>Module Permission</li>
              <li className='font-size-18px font-Poppins-SemiBold'>View</li>
              <li className='font-size-18px font-Poppins-SemiBold'>Edit</li>
            </ul>
            <hr />
            {Object.keys(permissions).map((key) => (
              <React.Fragment key={key}>
                <ul className='admin-create-role-ul-second'>
                  <li className='font-size-18px font-Poppins-Regular'>{getLabel(key)}</li>
                  <li className='font-size-18px font-Poppins-Regular'>
                    <input
                      type="checkbox"
                      checked={permissions[key as keyof IPermission] === "read" || permissions[key as keyof IPermission] === "read/write"}
                      onChange={(e) => handleCheckboxChange(key as keyof IPermission, "read", e.target.checked)}
                    />
                  </li>
                  <li className='font-size-18px font-Poppins-Regular'>
                    <input
                      type="checkbox"
                      checked={permissions[key as keyof IPermission] === "read/write"}
                      onChange={(e) => handleCheckboxChange(key as keyof IPermission, "read/write", e.target.checked)}
                    />
                  </li>
                </ul>
                <hr />
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className='flex justify-end my-6'>
          <PrimaryBtn
            ButtonText="Save"
            loader={isLoader}
            onPress={handleSubmit}
            type="button"
            btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
          />
        </div>
      </div>
    </div>
  );
};

export default UpsertRole;