/**
 * AddAReviewModal Component
 * 
 * Description: A modal component for adding a review for a specific appointment. 
 * It includes functionality to rate a service and leave a comment, 
 * and utilizes an API to submit the review.
 * 
 * Props: 
 * - isOpen (boolean): Whether the modal is open or not.
 * - onClose (function): Function to close the modal.
 * - onSuccess (function): Function to be called upon successful submission.
 * - data (object): Data of the doctor for whom the review is being written, including name, profile image, and specialization.
 * - appoinmentId (string): The ID of the appointment being reviewed.
 * - comment (string): The current comment entered by the user.
 * - star (number): The current star rating entered by the user.
 * - setComment (function): Function to update the comment value.
 * - setStar (function): Function to update the star rating.
 * 
 * File Name: AddAReviewModal.tsx
 * Date: 01-10-2024
 * 
 * Marker: DOC_START
 */

import React, { FormEvent, useState } from 'react';
import { faStar, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@mui/material';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import defaultProfile from "src/assets/images/default_profile_image.png";
import SimpleInput from '../SimpleInput';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';

// Style for the modal container
const customeStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
};

// Style for the close button container
const backBtnMaain: React.CSSProperties = {
    position: "absolute",
    top: -10,
    right: -10,
    backgroundColor: "red",
    borderRadius: 100,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

interface IReviewModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    data: { fullName: string; profileImage: string; specialization: string[] };
    appoinmentId: string;
    comment: string;
    star: number;
    setComment: (value: string) => void;
    setStar: (value: number) => void;
};

const AddAReviewModal: React.FC<IReviewModalProps> = ({
    isOpen = false,
    onClose = () => { },
    onSuccess = () => { },
    data = { fullName: "", profileImage: "", specialization: [] },
    appoinmentId = "",
    comment = "",
    star = 0,
    setComment = () => { },
    setStar = () => { }
}) => {
    const [isLoader, setIsLoader] = useState<boolean>(false);

    // API_CALL: Submit a review for the selected doctor
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: `/review/person`,
            params: { star, appoinmentId, comment }
        });
        if (res) {
            toast(res?.message || "Success", { type: 'success' });
            onSuccess();
            onClose();
            setComment("");
            setStar(1);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };
    return (
        <Modal
            open={isOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={customeStyle} className='flex items-center flex-col gap-2 bg-[#FFFCF1] w-[600px] py-[20px] px-[40px] rounded-[8px]'>
                <button onClick={onClose} style={backBtnMaain}>
                    <FontAwesomeIcon className='font-size-16px text-white' icon={faXmark} size="2x" />
                </button>
                <h2 className="font-Poppins-SemiBold font-size-40px text-[#464646]">
                    Write a review
                </h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className="flex gap-4 my-4 items-center">
                            <div className="w-full md:w-3/12">
                                <img
                                    alt='profile-image'
                                    className='rounded-full min-w-[100px] min-h-[100px]'
                                    src={`${process.env.REACT_APP_API_BASEURL}${data.profileImage}`}
                                    onError={(e) => {
                                        const target = e.target as HTMLImageElement; // Assert the type
                                        if (target) {
                                            target.src = defaultProfile;
                                        }
                                    }}
                                />
                            </div>
                            <div className="w-full md:w-9/12">
                                <h2 className="font-Poppins-SemiBold font-size-25px text-[#00443F] capitalize">{data.fullName}</h2>
                                <h3 className="font-Poppins-SemiBold capitalize-first-letter font-size-16px text-[#202020] w-fit flex flex-wrap">
                                    {data.specialization.map((v: string, i: number) => (
                                        <span key={i}>
                                            <span>{v}</span>
                                            {(i + 1 < data.specialization.length) &&
                                                <span className="mx-1">|</span>
                                            }
                                        </span>
                                    ))}
                                </h3>
                                <div className="text-yellow-400 flex flex-row mt-2 reviewstars">
                                    {[1, 2, 3, 4, 5].map((item) => (
                                        <FontAwesomeIcon
                                            className={star >= item ? "text-[#facc15]" : `text-[#b7b6b1]`}
                                            key={item}
                                            role="button"
                                            onClick={() => setStar(item)}
                                            fontSize="20px"
                                            icon={faStar}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div>
                            <SimpleInput
                                isRequired={true}
                                placeholder="Share your experience with this doctor..."
                                value={comment}
                                onChange={(val) => setComment(val)}
                                type="textArea"
                                rows={5}
                            />
                        </div>
                    </div>
                    <div className="flex cancel-job justify-center gap-2 mt-4">
                        <PrimaryBtn
                            type='submit'
                            loader={isLoader}
                            ButtonText="Post"
                            btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default AddAReviewModal;