/**
 * UpsertPlatformSetting Component
 * 
 * Description: This component handles the creation and editing of platform settings,
 * allowing users to select a category and input a commission percentage. It 
 * fetches available specializations from an API and manages the form submission 
 * with validation.
 * 
 * Props:
 * - isEdit (ICommisionItems | boolean): Optional. If provided and not a boolean, it represents 
 *   the platform setting to be edited. If false, the component operates in add mode.
 * - onSuccess (function): Callback function invoked upon successful form submission, allowing 
 *   parent components to refresh data or perform any necessary actions after a successful operation.
 * 
 * File Name: UpsertPlatformSetting.tsx
 * Date: 27-09-2024
 * 
 * Marker: DOC_START
 */

import React, { FormEvent, useEffect, useState } from 'react';
import { ICommisionItems, IDropDownArray } from 'src/types/GlobalInterfaces';
import SimpleInput from '../SimpleInput';
import SelectDropdown from '../GlobalComponents/SelectDropdown';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import PrimaryBtn from '../GlobalComponents/primaryBtn';

interface IUpsertPlatformSettingProps {
    isEdit?: ICommisionItems | boolean;
    onSuccess: (data: ICommisionItems) => void;
};

const UpsertPlatformSetting: React.FC<IUpsertPlatformSettingProps> = ({ onSuccess = () => { }, isEdit = false }) => {
    // ******************** ALL SPECIALIZATION STATES ******************** //
    const options: IDropDownArray[] = [
        { label: "Online", value: "online" },
        { label: "Physical", value: "physical" },

    ];

    // ******************** ALL SPECIALIZATION STATES ******************** //

    // ******************** CREATE & EDIT ******************** //
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [type, setType] = useState<"online" | "Physical" | "">("");
    const [commission, setCommission] = useState<string>("");

    // API_CALL: Handle Form Submission for craete & edit commissions
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (!type) {
            toast("Please select type.", { type: 'error' });
            return;
        }
        setIsLoader(true);
        const { res, err } = await httpRequest<{ createCommission: ICommisionItems; messsage: string }>({
            method: isEdit ? "put" : "post",
            path: `/commission${typeof isEdit !== "boolean" ? `/edit/${isEdit._id}` : "/add"}`,
            params: { type, commission: Number(commission) },
        });
        if (res) {
            const { messsage = "", createCommission } = res;
            toast(messsage || "Success", { type: 'success' });
            onSuccess(createCommission);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (isEdit && typeof isEdit !== 'boolean') {
            let { type, commission = 0 } = isEdit;
            setType(type);
            setCommission(String(commission));
        }
    }, [isEdit]); // Execute this useEffect hook if in edit mode to initialize form with existing role data

    return (
        <div className="table-container">
            <div className='admin-create-role-main'>
                <form onSubmit={handleSubmit}>
                    <div className='admin-create-role'>
                        <div className='create-operators-res'>
                            <h3 className='font-size-18px text-[#2F2F31] mb-1 font-Poppins-Regular'>Category</h3>
                            <SelectDropdown
                                options={options}
                                setData={(arr) => setType(arr[0]?.value || "")}
                                data={options.filter(v => v.value === type)}
                                isRequired={true}
                            />
                            <h3 className='font-size-18px text-[#2F2F31] mb-1 font-Poppins-Regular'>Commission</h3>
                            <SimpleInput
                                value={commission}
                                onChange={(val) => setCommission(val)}
                                placeholder="%"
                                inputMode='numeric'
                                maxLength={2}
                                isRequired={true}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-5">
                        <PrimaryBtn
                            loader={isLoader}
                            ButtonText="Save"
                            type="submit"
                            btnExtraStyle={{
                                borderRadius: "1.5rem",
                                backgroundColor: "#004540"
                            }}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpsertPlatformSetting;