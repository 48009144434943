/**
 * CustomerHealthProfile Component
 * 
 * Description: This component displays the customer's health profile. It includes navigation breadcrumbs, tabs for switching between profile overview and health profile, and renders components for health scores, latest tests, and user biomarkers based on the selected tab.
 * 
 * File Name: CustomerHealthProfile.tsx
 * Date: 24-08-2024
 */

/**
 * CustomerHealthProfile Component
 * 
 * Description: This component displays the customer's health profile. It includes navigation breadcrumbs, tabs for switching between profile overview and health profile, and renders components for health scores, latest tests, and user biomarkers based on the selected tab.
 * 
 * File Name: CustomerHealthProfile.tsx
 * Date: 24-08-2024
 */

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import HealthScores from 'src/Components/GlobalComponents/HealthProfiles/HealthScores';
import LatesTest from 'src/Components/GlobalComponents/HealthProfiles/LatesTest';
import UserBiomarkers from 'src/Components/GlobalComponents/HealthProfiles/UserBiomarkers';

const CustomerHealthProfile: React.FC = () => {
    const tabList: string[] = ["profile_overview", "health_profile"];
    const navigate = useNavigate();
    const { uid = "" } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isDoctor = queryParams.get('isdoctor') === 'true';
    const doctorName = queryParams.get('name');

    const [activeTab, setActiveTab] = useState<string>("profile_overview");

    return (
        <>
            <BreadCrumb
                current={isDoctor ? `${doctorName} health profile` : "Customers"}
                previous="dashboard"
                navigateUrl="/admin"
                btnShow={true}
                isUpsert={true}
                onPress={() => navigate(isDoctor ? "/doctors" : "/customers")}
            />

            <div className="table-container">
                <div className='products-tabs-btn'>
                    {tabList.map((v: string) => (
                        <button
                            key={v}
                            className={`font-size-15px font-Poppins-Medium ${activeTab === v && 'active'}`}
                            onClick={() => setActiveTab(v)}
                        >
                            {
                                v === "profile_overview" ? `Profile Overview` :
                                    v === "health_profile" ? `Health Profile` :
                                        ""
                            }
                        </button>
                    ))}
                </div>
                <hr className='my-3' style={{ border: '1px solid #D4D4D4' }} />
                <div className='parent-box-scroll-bar'>
                    {
                        activeTab === "profile_overview"
                            ?
                            <>
                                <HealthScores
                                    id={uid}
                                    isAdmin={true}
                                    onPress={() => setActiveTab("health_profile")}
                                />
                                <LatesTest
                                    id={uid}
                                />
                            </>
                            : activeTab === "health_profile" ?
                                <>
                                    {/* Render the user's Biomarkers information component and pass the user ID */}
                                    <UserBiomarkers
                                        id={uid}
                                        isMyProfile={false}
                                        role={"superAdmin"}
                                    />
                                </>
                                : null
                    }
                </div>
            </div>
        </>
    );
};

export default CustomerHealthProfile;