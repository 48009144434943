/**
 * Dashboard Component
 * 
 * This component fetches and displays various statistics and charts on the admin dashboard.
 * It includes metrics such as total biomarkers, tests, customers, doctors, orders, and revenue.
 * The data is fetched from an API endpoint and displayed in a grid format with corresponding icons.
 * Additionally, the component displays graphs for users, total sales, revenue, and biomarkers.
 * Date: 29-08-2024
 */

import React from 'react';
import UserGraph from '../../Components/AdminComponents/UserGraph';
import RevenueGraph from '../../Components/AdminComponents/RevenueGraph';
import TotalSalesGraph from '../../Components/AdminComponents/TotalSalesGraph';
import BiomarkerPieChart from '../../Components/AdminComponents/BiomarkerPieChart';
import PlatformStatistics from 'src/Components/AdminComponents/PlatformStatistics';

const Dashboard: React.FC = () => {
    return (
        <div className="gap-marging-admin container-bg-change">
            <PlatformStatistics />
            {/* GRAPHS AND CHARTS */}
            <div className="parent-box parent-box-graph-height">
                <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                    <UserGraph />
                    <RevenueGraph />
                    <TotalSalesGraph />
                    <BiomarkerPieChart />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;