/**
 * CreateDiscussionModal
 * 
 * Description: A modal component for creating or editing discussions. 
 * It includes input fields for the discussion title and description, 
 * handles API requests for creating/editing discussions, and displays 
 * a success or error message based on the response.
 * 
 * File Name: CreateDiscussionModal.tsx
 * Date: 24-09-2024
 */

import React, { FormEvent, useEffect, useState } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PrimaryBtn from './primaryBtn';
import SimpleInput from '../SimpleInput';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { useNavigate } from 'react-router-dom';

const backBtnMaain: React.CSSProperties = {
    position: "absolute",
    top: -10,
    right: -10,
    backgroundColor: "#F53546",
    borderRadius: 100,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #FFFFFF"
};

// Props interface for CreateDiscussionModal
interface ICreateDiscusProps {
    isOpen?: boolean;
    onClose?: () => void,
    isEdit?: false | { title: string; description: string; _id: string };
}

const CreateDiscussionModal: React.FC<ICreateDiscusProps> = ({
    isOpen = false,
    onClose = () => { },
    isEdit = false,
}) => {
    const navigate = useNavigate();

    // State for discussion title and description
    const [title, setTitle] = useState<string>(""); // for discussion title
    const [description, setDescription] = useState<string>(""); // for discussion discription
    const [isLoader, setIsLoader] = useState<boolean>(false); // for API loader

    //API_CALL: Handles form submission for creating/editing discussions
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: isEdit ? "put" : "post",
            path: `/disscussion${isEdit ? `/edit/${isEdit._id}` : "/create"}`,
            params: { title, description },
        });
        if (res) {
            toast(res.message || "Success!", { type: 'success' });
            onClose();
            setTitle("");
            setDescription("");
            if (isEdit) {
                navigate("/discussions");
            }
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Effect to prefill form fields when editing an existing discussion
    useEffect(() => {
        if (isEdit) {
            setTitle(isEdit?.title);
            setDescription(isEdit?.description);
        }
    }, [isEdit]);

    return (
        <div
            className="ovrlayModal modal"
            style={{ display: isOpen ? "block" : "none" }}
        >
            <div className="createpost-modal absolute z-20">
                <button onClick={onClose} style={backBtnMaain}>
                    <FontAwesomeIcon className='font-size-16px' style={{ color: '#FFFFFF' }} icon={faXmark} size="2x" />
                </button>
                {/* close discussion modal */}
                <div>
                    <h4 className="font-size-20px font-Poppins-SemiBold text-center gap-2 capitalize" style={{ color: '#0b4d48' }}>
                        {isEdit ? "Edit" : "New"} Discussion
                    </h4>
                    <hr color="#707070" className="mt-3" />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="create-post-text-area " style={{ display: 'flex', flexDirection: 'column' }}>
                        <SimpleInput
                            isRequired={true}
                            placeholder="Title"
                            value={title}
                            onChange={(val) => setTitle(val)}
                            extraStyle={{color: "#343434", fontWeight: 600,marginTop:'1rem', }}
                        />
                        <SimpleInput
                            isRequired={true}
                            type="textArea"
                            placeholder="Description"
                            value={description}
                            onChange={(val) => setDescription(val)}
                            rows={7}
                            // extraStyle={{ border: "none", color: "#707070", margin: 0 }}
                        />
                    </div>
                    <hr color="#707070" className="mt-3" />
                    <div className="relative">
                        <div className="flex justify-end  mt-3 gap-2">
                            <PrimaryBtn
                                type="submit"
                                loader={isLoader}
                                ButtonText="Post"
                                btnExtraStyle={{ backgroundColor: "#0b4d48", borderRadius: "1.5rem" }}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateDiscussionModal;