/**
 * OrderDetails.tsx
 * 
 * This component displays the details of an order, allowing the user to view the order status and history,
 * and add comments or update the status. It includes the ability to fetch order details by ID, handle 
 * status changes, and submit history updates.
 * 
 * Key Features:
 * - Fetches and displays order details based on the provided order ID.
 * - Allows users to update the order status and add history comments.
 * - Includes loading indicators for both data fetching and history submission.
 * 
 * File Name: OrderDetails.tsx
 * Date: 15-08-24
 */

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import BreadCrumb from "../../Components/GlobalComponents/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import httpRequest from "src/helpers/httpRequest";
import { toast } from "react-toastify";
import SplashScreen from "src/Components/SplashScreen";
import { IOrderDetails } from "src/types/GlobalInterfaces";
import DetailView from "src/Components/GlobalComponents/DetailView";
import SimpleInput from "src/Components/SimpleInput";
import { CircularProgress } from "@mui/material";

const OrderDetails: React.FC = () => {
  const navigate = useNavigate();
  const { orderId = "" } = useParams();

  const [isLoader, setIsLoader] = useState<boolean>(false); // Loader for fetching order details
  const [postLoader, setPostIsLoader] = useState<boolean>(false); // Loader for submitting history update
  const [item, setItem] = useState<IOrderDetails | null>(null); // State to store order details
  const [changeHistory, setChangeHistory] = useState<boolean>(false); // State to trigger history refresh
  const [status, setStatus] = useState<string>(""); // State for selected status
  const [comment, setComment] = useState<string>(""); // State for comment input

  // Handles status change in the dropdown
  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value);
  };

  // API_CALL: Submits the updated history (status and comment) for the order
  const historySubmit = async () => {
    setPostIsLoader(true);
    const { res, err } = await httpRequest<{ message: string }>({
      method: "post",
      path: `/order/history/status/${orderId}`,
      params: {
        status: status,
        comment: comment,
      }
    });
    if (res) {
      toast(res?.message || "Success", { type: 'success' });
      setComment(""); // Clear comment input after successful submission
      setChangeHistory(prev => !prev); // Toggle changeHistory to refresh order history
    } else {
      toast(err, { type: 'error' });
    }
    setPostIsLoader(false);
  };

  // API_CALL: Fetches order details based on the order ID
  const fetchOrderById = async (_id: string) => {
    setIsLoader(true);
    const { res, err } = await httpRequest<IOrderDetails>({ path: `/order/${_id}` });
    if (res) {
      setItem(res);
      setStatus(res.status || "");
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };

  // Fetch order details when the component mounts or when orderId changes
  useEffect(() => {
    fetchOrderById(orderId);
  }, [orderId]);

  return (
    <>
      {
        isLoader
        &&
        <SplashScreen />
      }
      <BreadCrumb
        current="Orders"
        previous="dashboard"
        navigateUrl="/admin"
        btnShow={true}
        isUpsert={true}
        onPress={() => navigate(-1)}
      />
      {
        item
        &&
        <div className="order-detail-container">
          <DetailView
            item={item}
            id={orderId}
            changeHistory={changeHistory}
          />
          <div>
            <div className="order-history">
              <h2 className="font-size-20px text-[#303030] mt-4 font-Poppins-Medium">
                Add History
              </h2>
            </div>
            <div className="order-status-outer">
              <span className="font-size-15px text-[#303030] font-Poppins-SemiBold">
                Order Status
              </span>
              <div>
                <select onChange={handleStatusChange} value={status} className="create-role-input font-size-16px font-Poppins-Regular">
                  <option disabled value="pending">Pending</option>
                  <option value="in_progress">In Progress</option>
                  <option value="delivered">Delivered</option>
                </select>
              </div>
            </div>
            <div className="order-comments">
              <span className="font-size-15px text-[#303030] font-Poppins-SemiBold">
                Comment
              </span>
              <div className="order-invoice-select">
                <SimpleInput
                  value={comment}
                  type="textArea"
                  rows={7}
                  placeholder="Comment"
                  onChange={(val) => setComment(val)}
                />
              </div>
            </div>
            <div className="order-add-history flex justify-end">
              <button
                disabled={postLoader}
                onClick={historySubmit}
                className="bg-[#00443F] flex items-center justify-center confirm-btn"
                style={{ minWidth: 100 }}
              >
                {
                  postLoader
                    ?
                    <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />
                    :
                    <>
                      <div className="plus-icon-order">
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                      <span className="font-size-12px font-Poppins-SemiBold ">
                        Add History
                      </span>
                    </>
                }
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default OrderDetails;