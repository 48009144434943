/**
 * UpsertBiomarker Component
 * 
 * Description: This component provides functionality to create or update a biomarker, allowing for enhanced user interaction 
 * and data management. It includes form fields for various biomarker properties such as name, measurement, bar category, 
 * biomarker category, short and long descriptions, and threshold values for different age groups and genders. The component 
 * also handles data fetching and submission to the backend, ensuring seamless integration with the API for both creation and 
 * updates of biomarkers.
 * 
 * File Name: UpsertBiomarker.tsx
 * Date: 30-07-2024
 * 
 * Marker: DOC_START
 */

import React, { FormEvent, useState, useEffect } from 'react';
import SimpleInput from '../SimpleInput';
import ToggleRadioBtn from '../ToggleRadioBtn';
import { ICreateBiomarker, IBiomarkerMeasurement, IBiomarkerCategory, IBarCategory, IDropDownArray, IThresholds } from 'src/types/GlobalInterfaces';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import SelectDropdown from '../GlobalComponents/SelectDropdown';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { ageGroup, fiveBarMarker, fourBarMarkerAsc, fourBarMarkerDesc, threeBarMarkerAsc, threeBarMarkerCentralized, threeBarMarkerDesc, twoBarMarkerAsc, twoBarMarkerDesc } from 'src/lib/constans';

interface IUpsertBiomarkerProps {
    onSuccess: () => void;  // Callback function to be called on success
    isEdit?: string | boolean; // Optional parameter for editing mode
};

// Functional component for creating or editing biomarker
const UpsertBiomarker: React.FC<IUpsertBiomarkerProps> = ({ onSuccess = () => { }, isEdit = false }) => {
    type ageCategory = "child" | "adult" | "elder"; // Type for age categories

    // Array of questions used for biomarker descriptions
    const questionsArray: { question: string; key: string }[] = [
        {
            question: "Why do deviations occur and what does this mean?",
            key: "derivationOccur",
        },
        {
            question: "How to test {{{BIOMARKER_NAME}}}?",
            key: "testosterone",
        },
        {
            question: "What specialist is required?",
            key: "specialist",
        },
        {
            question: "General recommendations",
            key: "recomandation",
        },
        {
            question: "Methods to increase",
            key: "increase",
        },
        {
            question: "Methods to decrease",
            key: "decrease",
        },
        {
            question: "References",
            key: "references",
        },
    ];
    // ************* State variables for dropdown options and loaders ************* //
    const [allMeasurements, setAllMeasurements] = useState<IDropDownArray[]>([]);
    const [allBarCategory, setAllBarCategory] = useState<IDropDownArray[]>([]);
    const [allBiomarkerCategory, setAllBiomarkerCategory] = useState<IDropDownArray[]>([]);

    const [unitLoader, setUnitLoader] = useState<boolean>(false);
    const [barCatLoader, setBarCatLoader] = useState<boolean>(false);
    const [biomarkerCatLoader, setBiomarkerCatLoader] = useState<boolean>(false);
    // ************* State variables for dropdown options and loaders ************* //

    // ************* CREATE & UPDATE ************* //
    const [isLoader, setIsLoader] = useState<boolean>(false);
    // ************* CREATE & UPDATE ************* //

    // Initial state for biomarker data
    const [biomarkerState, setBiomarkerState] = useState<ICreateBiomarker>({
        name: "",
        measurementUnit: "",
        measurementId: "",
        barCategoryId: "",
        biomarkerCategoryId: [],
        shortDescription: "",
        longDescription: "",
        derivationOccur: "",
        testosterone: "",
        specialist: "",
        recomandation: "",
        increase: "",
        decrease: "",
        references: "",
        maleThresholds: {
            ref: {
                child: [],
                adult: [],
                elder: []
            },
            op: {
                child: [],
                adult: [],
                elder: []
            }
        },
        femaleThresholds: {
            ref: {
                child: [],
                adult: [],
                elder: []
            },
            op: {
                child: [],
                adult: [],
                elder: []
            }
        }
    });

    // Replace placeholder with actual biomarker name in question
    const printQuestion = (txt: string) => {
        return txt.replaceAll("{{{BIOMARKER_NAME}}}", biomarkerState?.name) || "-";
    };

    // Handle changes in form fields
    const handleChange = (key: string, val: string | string[], isLimit: number | false = false) => {
        setBiomarkerState(
            prev => ({
                ...prev,
                [key]: (isLimit === false || val.length <= isLimit) ? val : prev[key],
            })
        );
    };

    // Get value for a specific threshold based on gender and age category
    const setValueOf = (
        ref: "ref" | "op",  // "ref" or "op"
        index: 0 | 1,  // Index in the array
        gender: "maleThresholds" | "femaleThresholds",  // Gender type
        ageCategory: ageCategory // Age category
    ): number | string => {
        // Return undefined or a default value if the key does not exist
        return String(biomarkerState[gender]?.[ref]?.[ageCategory][index]);
    };

    // Update threshold value in the state
    const changeThresholdValue = (
        ref: "ref" | "op",  // "ref" or "op"
        index: 0 | 1,  // Index in the array
        gender: "maleThresholds" | "femaleThresholds",  // Gender type
        ageCategory: ageCategory, // Age category
        value: string
    ) => {
        // Validate the numeric value
        setBiomarkerState(prev => ({
            ...prev,
            [gender]: {
                ...prev[gender],
                [ref]: {
                    ...prev[gender]?.[ref],
                    [ageCategory]: {
                        ...prev[gender]?.[ref][ageCategory],
                        [index]: Number(value)
                    }
                }
            }
        }));
    };

    // Validate thresholds based on bar category
    const validateBarCategory = (barCategoryId: string) => {
        let errorMessage: string | null = null;

        if (barCategoryId === fiveBarMarker) {
            const validateOptimalRanges = (thresholds: IThresholds, refThresholds: IThresholds) => {
                if (!thresholds || typeof thresholds !== 'object' || !refThresholds || typeof refThresholds !== 'object') return false;

                return Object.entries(thresholds).every(([key, value]) => {
                    const optMin = value[0];
                    const optMax = value[1];
                    const refMin = refThresholds[key as keyof IThresholds][0];
                    const refMax = refThresholds[key as keyof IThresholds][1];

                    // Check if optMin and optMax are within refMin and refMax
                    if (optMin < refMin || optMax > refMax) {
                        return false;
                    }

                    // Check for duplicate values among opt and ref
                    const allValues = [optMin, optMax, refMin, refMax];
                    const uniqueValues = new Set(allValues);

                    if (uniqueValues.size !== allValues.length) {
                        return false;
                    }

                    return true;
                });
            };

            const isValidMale = validateOptimalRanges(biomarkerState.maleThresholds.op, biomarkerState.maleThresholds.ref);
            const isValidFemale = validateOptimalRanges(biomarkerState.femaleThresholds.op, biomarkerState.femaleThresholds.ref);

            if (!isValidMale || !isValidFemale) {
                return "For 5-bar category, Optimal thresholds must be within Reference thresholds, no values should be the same, and min should be less than max.";
            }
        } else if (barCategoryId === fourBarMarkerAsc) {
            const validateFourBarRangesAsc = (thresholds: IThresholds, refThresholds: IThresholds) => {
                if (!thresholds || typeof thresholds !== 'object' || !refThresholds || typeof refThresholds !== 'object') return false;

                return Object.entries(thresholds).every(([key, value]) => {
                    const optMin = value[0];
                    const optMax = value[1];
                    const refMin = refThresholds[key as keyof IThresholds][0];
                    const refMax = refThresholds[key as keyof IThresholds][1];

                    // Check if optMax is equal to refMax
                    if (optMax !== refMax) {
                        return false;
                    }

                    // Check if optMin lies strictly between refMin and refMax
                    if (optMin <= refMin || optMin >= refMax) {
                        return false;
                    }

                    return true;
                });
            };

            const isValidMale = validateFourBarRangesAsc(biomarkerState.maleThresholds.op, biomarkerState.maleThresholds.ref);
            const isValidFemale = validateFourBarRangesAsc(biomarkerState.femaleThresholds.op, biomarkerState.femaleThresholds.ref);

            if (!isValidMale || !isValidFemale) {
                return "For 4-bar category (fourBarMarkerAsc), optMax must equal refMax, and optMin must be strictly between refMin and refMax.";
            }
        } else if (barCategoryId === fourBarMarkerDesc) {
            const validateFourBarRangesDesc = (thresholds: IThresholds, refThresholds: IThresholds) => {
                if (!thresholds || typeof thresholds !== 'object' || !refThresholds || typeof refThresholds !== 'object') return false;

                return Object.entries(thresholds).every(([key, value]) => {
                    const optMin = value[0];
                    const optMax = value[1];
                    const refMin = refThresholds[key as keyof IThresholds][0];
                    const refMax = refThresholds[key as keyof IThresholds][1];

                    // Check if optMax is equal to refMax
                    if (optMin !== refMin) {
                        return false;
                    }

                    // Check if optMin lies strictly between refMin and refMax
                    if (optMax <= refMin || optMax >= refMax) {
                        return false;
                    }

                    return true;
                });
            };

            const isValidMale = validateFourBarRangesDesc(biomarkerState.maleThresholds.op, biomarkerState.maleThresholds.ref);
            const isValidFemale = validateFourBarRangesDesc(biomarkerState.femaleThresholds.op, biomarkerState.femaleThresholds.ref);

            if (!isValidMale || !isValidFemale) {
                return "For 4-bar category (fourBarMarkerDesc), optMin must equal refMin, and optMax must be strictly between refMax and refMin.";
            }
        } else if (barCategoryId === threeBarMarkerCentralized) {
            const validateThreeBarCentralizedRanges = (thresholds: IThresholds, refThresholds: IThresholds) => {
                if (!thresholds || typeof thresholds !== 'object' || !refThresholds || typeof refThresholds !== 'object') return false;

                return Object.entries(thresholds).every(([key, value]) => {
                    const optMin = value[0];
                    const optMax = value[1];
                    const refMin = refThresholds[key as keyof IThresholds][0];
                    const refMax = refThresholds[key as keyof IThresholds][1];

                    // Check if optMin equals refMin and optMax equals refMax
                    if (optMin !== refMin || optMax !== refMax) {
                        return false;
                    }

                    return true;
                });
            };

            const isValidMale = validateThreeBarCentralizedRanges(biomarkerState.maleThresholds.op, biomarkerState.maleThresholds.ref);
            const isValidFemale = validateThreeBarCentralizedRanges(biomarkerState.femaleThresholds.op, biomarkerState.femaleThresholds.ref);

            if (!isValidMale || !isValidFemale) {
                return "For 3-bar category (threeBarMarkerCentralized), optMin must equal refMin, and optMax must equal refMax.";
            }
        } else if (barCategoryId === threeBarMarkerAsc) {
            const validateThreeBarAsc = (thresholds: IThresholds, refThresholds: IThresholds) => {
                if (!thresholds || typeof thresholds !== 'object' || !refThresholds || typeof refThresholds !== 'object') return false;

                return Object.entries(thresholds).every(([key, value]) => {
                    const optMin = value[0];
                    const optMax = value[1];
                    const refMin = refThresholds[key as keyof IThresholds][0];
                    const refMax = refThresholds[key as keyof IThresholds][1];

                    // Check if optMin equals refMax
                    if (optMin !== refMax) {
                        return false;
                    }

                    // Check if refMin is less than refMax
                    if (refMin >= refMax) {
                        return false;
                    }

                    // Check if optMax is greater than optMin
                    if (optMax <= optMin) {
                        return false;
                    }

                    return true;
                });
            };

            const isValidMale = validateThreeBarAsc(biomarkerState.maleThresholds.op, biomarkerState.maleThresholds.ref);
            const isValidFemale = validateThreeBarAsc(biomarkerState.femaleThresholds.op, biomarkerState.femaleThresholds.ref);

            if (!isValidMale || !isValidFemale) {
                return "For 3-bar category (threeBarMarkerAsc), optMin must equal refMax, refMin must be less than refMax, and optMax must be greater than optMin.";
            }
        } else if (barCategoryId === threeBarMarkerDesc) {
            const validateThreeBarDesc = (thresholds: IThresholds, refThresholds: IThresholds) => {
                if (!thresholds || typeof thresholds !== 'object' || !refThresholds || typeof refThresholds !== 'object') return false;

                return Object.entries(thresholds).every(([key, value]) => {
                    const optMin = value[0];
                    const optMax = value[1];
                    const refMin = refThresholds[key as keyof IThresholds][0];
                    const refMax = refThresholds[key as keyof IThresholds][1];

                    // Check if optMin equals refMax
                    if (optMax !== refMin) {
                        return false;
                    }

                    // Check if optMin is less than optMax
                    if (optMin >= optMax) {
                        return false;
                    }

                    // Check if refMax is greater than refMin
                    if (refMax <= refMin) {
                        return false;
                    }

                    return true;
                });
            };

            const isValidMale = validateThreeBarDesc(biomarkerState.maleThresholds.op, biomarkerState.maleThresholds.ref);
            const isValidFemale = validateThreeBarDesc(biomarkerState.femaleThresholds.op, biomarkerState.femaleThresholds.ref);

            if (!isValidMale || !isValidFemale) {
                return "For 3-bar category (threeBarMarkerDesc), optMax must equal refMin, optMin must be less than optMax, and refMax must be greater than refMin.";
            }
        } else if ((barCategoryId === twoBarMarkerAsc) || (barCategoryId === twoBarMarkerDesc)) {
            const validateThreeBarDesc = (thresholds: IThresholds, refThresholds: IThresholds) => {
                if (!thresholds || typeof thresholds !== 'object' || !refThresholds || typeof refThresholds !== 'object') return false;

                return Object.entries(thresholds).every(([key, value]) => {
                    const optMin = value[0];
                    const optMax = value[1];
                    const refMin = refThresholds[key as keyof IThresholds][0];
                    const refMax = refThresholds[key as keyof IThresholds][1];

                    // Check if optMin equals refMax
                    if ((optMin !== refMin) || (optMax !== refMax)) {
                        return false;
                    }

                    return true;
                });
            };

            const isValidMale = validateThreeBarDesc(biomarkerState.maleThresholds.op, biomarkerState.maleThresholds.ref);
            const isValidFemale = validateThreeBarDesc(biomarkerState.femaleThresholds.op, biomarkerState.femaleThresholds.ref);

            if (!isValidMale || !isValidFemale) {
                return "For 2-bar category (twoBarMarkerAsc) & (twoBarMarkerAsc), optMin must equal refMin, and optMax must equal refMax.";
            }
        } else {
            return false;
        }

        return errorMessage;
    };

    // Handle form submission
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (!biomarkerState?.measurementId) {
            toast("Measurement is required!", { type: 'error' });
        } else if (!biomarkerState?.barCategoryId) {
            toast("Bar category is required!", { type: 'error' });
        } else if (biomarkerState?.biomarkerCategoryId.length < 1) {
            toast("Biomarker category is required!", { type: 'error' });
        }
        else {
            const validationError = validateBarCategory(biomarkerState.barCategoryId);
            if (validationError) {
                toast(validationError, { type: 'error' });
            } else {
                setIsLoader(true);
                const path = isEdit && typeof isEdit !== 'boolean' ? `/biomarker/edit/${isEdit}` : "/biomarker/add";

                const { res, err } = await httpRequest<any>({ method: isEdit ? "put" : "post", path, params: biomarkerState });
                if (res) {
                    toast(`Biomarker has been ${isEdit ? "updated" : "added"} successfully.`, { type: 'success' });
                    onSuccess();
                } else {
                    toast(err, { type: 'error' });
                }
                setIsLoader(false);
            }
        }
    };

    // Fetch biomarker details by ID for editing
    const getBiomarkerById = async () => {
        const { res, err } = await httpRequest<ICreateBiomarker>({ path: `/biomarker/single/${isEdit}` });
        if (res) {
            setBiomarkerState(res)
        } else {
            toast(err, { type: 'error' });
        }
    };

    // Fetch all measurement units
    const getAllUnits = async () => {
        setUnitLoader(true);
        const { res, err } = await httpRequest<IBiomarkerMeasurement[]>({ path: "/biomarker/getallunit" });
        if (res) {
            setAllMeasurements(res.map(v => ({ label: v.name, value: v._id })));
        } else {
            toast(err, { type: 'error' });
        }
        setUnitLoader(false);
    };

    // Fetch all bar categories
    const getAllBarCategories = async () => {
        setBarCatLoader(true);
        const { res, err } = await httpRequest<IBarCategory[]>({ path: "/biomarker/getallbarCategory" });
        if (res) {
            setAllBarCategory(res.map(v => ({ label: v.name, value: v._id, isShown: v.isShown })));
        } else {
            toast(err, { type: 'error' });
        }
        setBarCatLoader(false);
    };

    // Fetch all biomarker categories
    const getAllBiomarkerCategories = async () => {
        setBiomarkerCatLoader(true);
        const { res, err } = await httpRequest<IBiomarkerCategory[]>({ path: "/biomarker/getallbiomarkerCategory" });
        if (res) {
            setAllBiomarkerCategory(res.map(v => ({ label: v.name, value: v._id })));
        } else {
            toast(err, { type: 'error' });
        }
        setBiomarkerCatLoader(false);
    };

    useEffect(() => {
        getAllUnits();
        getAllBarCategories();
        getAllBiomarkerCategories();

        if (isEdit) {
            getBiomarkerById();
        }
    }, []); // Added isEdit to dependency array to refetch on edit mode change

    return (
        <div className="table-container">
            <div className="admin-create-role-main parent-box-scroll-bar-full">
                <form onSubmit={handleSubmit}>
                    <div className="admin-create-role">
                        {/* name */}
                        <SimpleInput
                            value={biomarkerState.name}
                            onChange={(val) => handleChange("name", val)}
                            isLabel={true}
                            labelText="Name"
                            isRequired={true}
                        />
                        {/* measurement */}
                        <SelectDropdown
                            loader={unitLoader}
                            options={allMeasurements}
                            isMulti={false}
                            data={allMeasurements.filter(v => v?.value === biomarkerState?.measurementId)}
                            setData={(e) => handleChange("measurementId", e[0]?.value || "")}
                            checkboxShow={false}
                            isLabel={true}
                            labelText="Measurement"
                            isRequired={true}
                        />
                        {/* bar category */}
                        <SelectDropdown
                            loader={barCatLoader}
                            options={allBarCategory}
                            isMulti={false}
                            data={allBarCategory.filter(v => v?.value === biomarkerState?.barCategoryId)}
                            setData={(e) => handleChange("barCategoryId", e[0]?.value || "")}
                            checkboxShow={false}
                            isLabel={true}
                            labelText="Bar Category"
                            isRequired={true}
                        />
                        {/* biomarker category */}
                        <SelectDropdown
                            loader={biomarkerCatLoader}
                            options={allBiomarkerCategory}
                            isMulti={true}
                            data={allBiomarkerCategory.filter(v => biomarkerState?.biomarkerCategoryId?.includes(v?.value))}
                            setData={(e) => handleChange("biomarkerCategoryId", e.map(v => v.value))}
                            checkboxShow={false}
                            isLabel={true}
                            labelText="Biomarker Category"
                            isRequired={true}
                        />
                        {/* short description */}
                        <SimpleInput
                            value={biomarkerState.shortDescription}
                            onChange={(val) => handleChange("shortDescription", val, 200)}
                            type="textArea"
                            isLabel={true}
                            labelText="Short Description"
                            isRequired={true}
                            isLimit={200}
                            rows={3}
                        />
                        {/* <ToggleRadioBtn toggleText="Gender specific Description " /> */}
                        <h3 className="font-size-20px my-4 font-Poppins-Medium">Descriptions:</h3>
                        {/* long description */}
                        <SimpleInput
                            value={biomarkerState.longDescription}
                            onChange={(val) => handleChange("longDescription", val, 500)}
                            type="textArea"
                            isLabel={true}
                            labelText="Long Description"
                            isRequired={true}
                            isLimit={500}
                            rows={6}
                        />
                        {questionsArray.map(item => (
                            <SimpleInput
                                key={item?.key}
                                value={biomarkerState[item?.key] || ""}
                                onChange={(val) => handleChange(item?.key, val, 200)}
                                type="textArea"
                                isLabel={true}
                                labelText={printQuestion(item?.question)}
                                isLimit={200}
                                rows={3}
                            />
                        ))}
                        {allBarCategory.find(v => v.value === biomarkerState.barCategoryId)?.isShown &&
                            <div>
                                <ul className='admin-add-biomarker-ul'>
                                    <li className='font-size-16px font-Poppins-Medium'>Group</li>
                                    <li className='font-size-16px font-Poppins-Medium'>Age</li>
                                    <li className='font-size-16px font-Poppins-Medium'>Reference</li>
                                    <li className='font-size-16px font-Poppins-Medium'>Optimal</li>
                                </ul>
                                {ageGroup.map((item, index) => (
                                    <ul key={index} className='admin-add-biomarker-ul-inner'>
                                        <li className='font-size-16px font-Poppins-Medium'>Male</li>
                                        <li className='font-size-16px font-Poppins-Medium'>{item?.age || ""}</li>
                                        <li className='font-size-16px font-Poppins-Medium'>
                                            <div className='add-biomarker-input-grid'>
                                                <input
                                                    value={setValueOf("ref", 0, "maleThresholds", item?.title as ageCategory)}
                                                    onChange={(e) => changeThresholdValue("ref", 0, "maleThresholds", item?.title as ageCategory, e.target.value)}
                                                    type="number" placeholder="MIN" required />
                                                <input
                                                    value={setValueOf("ref", 1, "maleThresholds", item?.title as ageCategory)}
                                                    onChange={(e) => changeThresholdValue("ref", 1, "maleThresholds", item?.title as ageCategory, e.target.value)}
                                                    type="number" placeholder="MAX" required />
                                            </div>
                                        </li>
                                        <li className='font-size-16px font-Poppins-Medium'>
                                            <div className='add-biomarker-input-grid'>
                                                <input
                                                    value={setValueOf("op", 0, "maleThresholds", item?.title as ageCategory)}
                                                    onChange={(e) => changeThresholdValue("op", 0, "maleThresholds", item?.title as ageCategory, e.target.value)}
                                                    type="number" placeholder="MIN" required />
                                                <input
                                                    value={setValueOf("op", 1, "maleThresholds", item?.title as ageCategory)}
                                                    onChange={(e) => changeThresholdValue("op", 1, "maleThresholds", item?.title as ageCategory, e.target.value)}
                                                    type="number" placeholder="MAX" required />
                                            </div>
                                        </li>
                                    </ul>
                                ))}
                                {ageGroup.map((item, index) => (
                                    <ul key={index} className='admin-add-biomarker-ul-inner'>
                                        <li className='font-size-16px font-Poppins-Medium'>Female</li>
                                        <li className='font-size-16px font-Poppins-Medium'>{item?.age || ""}</li>
                                        <li className='font-size-16px font-Poppins-Medium'>
                                            <div className='add-biomarker-input-grid'>
                                                <input
                                                    value={setValueOf("ref", 0, "femaleThresholds", item?.title as ageCategory)}
                                                    onChange={(e) => changeThresholdValue("ref", 0, "femaleThresholds", item?.title as ageCategory, e.target.value)}
                                                    type="number" placeholder="MIN" required />
                                                <input
                                                    value={setValueOf("ref", 1, "femaleThresholds", item?.title as ageCategory)}
                                                    onChange={(e) => changeThresholdValue("ref", 1, "femaleThresholds", item?.title as ageCategory, e.target.value)}
                                                    type="number" placeholder="MAX" required />
                                            </div>
                                        </li>
                                        <li className='font-size-16px font-Poppins-Medium'>
                                            <div className='add-biomarker-input-grid'>
                                                <input
                                                    value={setValueOf("op", 0, "femaleThresholds", item?.title as ageCategory)}
                                                    onChange={(e) => changeThresholdValue("op", 0, "femaleThresholds", item?.title as ageCategory, e.target.value)}
                                                    type="number" placeholder="MIN" required />
                                                <input
                                                    value={setValueOf("op", 1, "femaleThresholds", item?.title as ageCategory)}
                                                    onChange={(e) => changeThresholdValue("op", 1, "femaleThresholds", item?.title as ageCategory, e.target.value)}
                                                    type="number" placeholder="MAX" required />
                                            </div>
                                        </li>
                                    </ul>
                                ))}
                            </div>
                        }
                    </div>
                    <div className='flex justify-end my-6'>
                        <PrimaryBtn
                            loader={isLoader}
                            ButtonText="Save"
                            type="submit"
                            btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpsertBiomarker;