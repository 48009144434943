/**
 * ImageTab Component
 *
 * A component for managing product images. It provides an interface for uploading and displaying a product image.
 *
 * File Name: ImageTab.tsx
 * Date: 31-07-24
 */

import React from 'react';
import ImageUpload from '../../GlobalComponents/ImageUplaod';
import { IProductItems } from 'src/types/GlobalInterfaces';

const ImageTab: React.FC<{
    state: IProductItems;
    setState: React.Dispatch<React.SetStateAction<IProductItems>>;
}> = ({
    state,
    setState,
}) => {
        return (
            <>
                <div className='products-tabs-content products-tabs-content-image-tab'>
                    <div className='flex gap-10 items-center'>
                        <div className='flex flex-col gap-2'>
                            <label className='font-size-18px font-Poppins-Regular' htmlFor="">Product Image</label>
                            <ImageUpload
                                image={state?.productImage} // Current product image
                                onImageChange={(e) => setState(prev => ({ ...prev, productImage: e }))}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

export default ImageTab;