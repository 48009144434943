/**
 * MyDoctors Component
 * 
 * Description: Displays a list of doctors with pagination, filtering options for appointment date range, 
 * and handles API calls to fetch data. It allows users to manage appointments with doctors.
 * 
 * File Name: MyDoctors.tsx
 * Date: 09-09-2024
 */

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CustomerTableWrapper from 'src/Components/Customer/CustomerTableWrapper';
import PageHeader from 'src/Components/Customer/PageHeader';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import StartAndEndDateFilter from 'src/Components/GlobalComponents/StartAndEndDateFilter';
import httpRequest from 'src/helpers/httpRequest';
import { IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

interface IDoctorList {
    _id: string;
    firstName: string;
    lastName: string;
    isOnlineAppoinment: boolean;
    hospitalName: null | string;
    appoinmentDate: string;
};

interface IMyDoctorResponse {
    documents: IDoctorList[];
    paginated: IPaginated;
}; // Interface for the API response containing appointments and pagination info

const MyDoctors: React.FC = () => {
    const navigate = useNavigate();
    const [myDoctors, setMyDoctors] = useState<IDoctorList[]>([]);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination state
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        startDate: "",
        endDate: "",
    }); // Query parameters for API request

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    const applyFilter = (startDate: string, endDate: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            startDate,
            endDate,
        }));
    };

    const resetFilter = () => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            startDate: "",
            endDate: "",
        }));
    };

    const sendToAccess = (id: string) => {
        navigate(`/doctor/${id}/access`);
    };

    // API_CALL: Function to fetch the list of my doctors
    const fetchMyDoctorList = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IMyDoctorResponse>({ path: `/appoiment/all/doctor?${qs.stringify(queryParams)}` });
        if (res) {
            const { documents = [], paginated } = res;
            setMyDoctors(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchMyDoctorList();
    }, [queryParams]); // Fetch appointments when queryParams change

    return (
        <>
            <PageHeader
                title="My Doctor"
            />
            <div className="parent-box">
                <StartAndEndDateFilter
                    reset={resetFilter}
                    apply={applyFilter}
                />
                <hr className="mb-4" />
                <CustomerTableWrapper
                    isLoading={isLoader}
                    thead={["S.No", "Doctor Name", "Appointment Type", "Hospital Name ", "Date", "Access"]}
                    page={paginated?.currentPage || 1}
                    total={paginated?.totalPages || 0}
                    columnWidth={"10% 20% 20% 22% 14% 14%"}
                    onChange={handlePageChange}
                >
                    {myDoctors?.map((item: IDoctorList, index: number) => (
                        <ul key={index} className='font-size-16px tbody-light-table customer-table-wrapper-body-padding-non customer-table-wrapper-body tbody-light-table-customer font-Poppins-Regular grid' style={{ gridTemplateColumns: "10% 20% 20% 22% 14% 14%" }}>
                            <li className="action-last-colum-non">{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                            <li>
                                <Link className="underline" to={`/doctor-public-profile/${item._id}/view`}>{`${item.firstName} ${item.lastName}`}</Link>
                            </li>
                            <li>{item.isOnlineAppoinment ? "Online" : "Physical"}</li>
                            <li>{item.hospitalName || "-"}</li>
                            <li>{moment(item.appoinmentDate).format("DD-MM-YYYY")}</li>
                            <li className='action-last-colum'>
                                <PrimaryBtn
                                    ButtonText='Manage'
                                    onPress={() => sendToAccess(item._id)}
                                    btnExtraStyle={{
                                        borderRadius: "1.5rem",
                                        backgroundColor: "#004540",
                                        padding: "6px 20px",
                                        fontSize: 12,
                                    }}
                                />
                            </li>
                        </ul>
                    ))}
                </CustomerTableWrapper>
            </div>
        </>
    );
};

export default MyDoctors;