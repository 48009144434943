/**
 * DeclineModalReason Component
 * 
 * Description: A modal component used for displaying a decline reason. It includes
 * a title, an input for the decline reason, and a button to submit the reason. The
 * modal also includes a close button and styles for positioning and appearance.
 * 
 * File Name: DeclineModalReason.tsx
 * Date: 28-08-2024
 */

import React, { Dispatch, Fragment, SetStateAction } from 'react';
import Modal from '@mui/material/Modal';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import SimpleInput from '../SimpleInput';
import modalIcon from "src/assets/images/reason-modal-icon.png"

// Style for the modal container
const customeStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

// Style for the close button container
const backBtnMaain: React.CSSProperties = {
  position: "absolute",
  top: -10,
  right: -10,
  backgroundColor: "red",
  borderRadius: 100,
  width: 20,
  height: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

// Style for the close button text
const backBtn: React.CSSProperties = {
  color: "white",
};

interface IDeclineProps {
  title: string; // The title displayed in the modal
  value: string; // The current value of the input field
  setValue: Dispatch<SetStateAction<string>>; // Function to update the value state
  ButtonComponent?: React.ElementType; // Component to render as a button
  isOpen: boolean; // Boolean to control the visibility of the modal
  onClose: () => void; // Function to be called when the modal is closed
};

const DeclineModalReason: React.FC<IDeclineProps> = ({
  title,
  value,
  setValue,
  ButtonComponent = Fragment,
  isOpen = false,
  onClose
}) => {
  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={customeStyle} className='flex items-center flex-col gap-2 bg-[#FFFCF1] w-[600px] py-[20px] px-[40px] rounded-[8px]'>
          <button onClick={onClose} style={backBtnMaain}>
            <FontAwesomeIcon className='font-size-16px ' icon={faXmark} size="2x" style={backBtn} />
          </button>
          <img src={modalIcon} alt="icon" width={60} />
          <h1 className="font-size-28px font-Poppins-Refular text-center w-[400px] leading-1">
            {title}
          </h1>
          <div className="w-full">
            <SimpleInput
              value={value}
              onChange={(val) => setValue(val)}
              type='textArea'
              rows={7}
              extraStyle={{ borderColor: "#009d91" }}
              isRequired={true}
            />
          </div>
          <ButtonComponent />
        </div>
      </Modal>
    </div>
  );
};

export default DeclineModalReason;