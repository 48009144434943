/**
 * Analytics Component
 * 
 * Description: Fetches and displays analytics data for top-selling and top-booked test categories using the PopularTestsAndSpecializations component.
 * 
 * File Name: Analytics.tsx
 * Date: 30-09-2024
 */

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PlatformStatistics from 'src/Components/AdminComponents/PlatformStatistics';
import PopularTestsAndSpecializations from 'src/Components/AdminComponents/PopularTestsAndSpecializations';
import httpRequest from 'src/helpers/httpRequest';
import { IAnalytics } from 'src/types/GlobalInterfaces';

interface ISelling {
    _id: string;
    count: number;
    biomnarkerCategories: string;
};
interface IBooked {
    count: number;
    specializationName: string;
    _id: string;
};

const Analytics: React.FC = () => {
    const [sellingLoader, setSellingLoader] = useState<boolean>(false);
    const [totalCategories, setTotalCategories] = useState<number>(0);
    const [sellingData, setSellingData] = useState<IAnalytics[]>([]);
    const [bookedLoader, setBookedLoader] = useState<boolean>(false);
    const [totalSpecialization, setTotalSpecialization] = useState<number>(0);
    const [bookedData, setBookedData] = useState<IAnalytics[]>([]);

    const fetchTopSelling = async () => {
        setSellingLoader(true);
        const { res, err } = await httpRequest<{ result: ISelling[]; totalCategories: { count: number } }>({ path: "/appoiment/top/selling" });
        if (res) {
            const data = res?.result?.map(v => ({ name: v.biomnarkerCategories, count: v.count, _id: v._id })) || [];
            setSellingData(data);
            setTotalCategories(res?.totalCategories?.count || 0)
        } else {
            toast(err, { type: 'error' });
        }
        setSellingLoader(false);
    };

    const fetchTopBooked = async () => {
        setBookedLoader(true);
        const { res, err } = await httpRequest<{ result: IBooked[]; totalSpecailization: { count: number } }>({ path: "/order/test/graph" });
        if (res) {
            const data = res?.result?.map(v => ({ name: v.specializationName, count: v.count, _id: v._id })) || [];
            setBookedData(data);
            setTotalSpecialization(res?.totalSpecailization?.count || 0);
        } else {
            toast(err, { type: 'error' });
        }
        setBookedLoader(false);
    };

    useEffect(() => {
        fetchTopSelling();
        fetchTopBooked();
    }, []);

    return (
        <div className="gap-marging-admin">
            <PlatformStatistics />
            <div className="parent-box">
                <div className="flex flex-col gap-3">
                    <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">Top Selling Test Categories</h2>
                            <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">Selling Test categories</h2>
                        </div>
                        <PopularTestsAndSpecializations
                            allCount={totalCategories}
                            loader={sellingLoader}
                            graphTitle="Categories"
                            data={sellingData}
                        />
                    </div>
                    <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">Top Booked Specialization</h2>
                            <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">Booked Specialization</h2>
                        </div>
                        <PopularTestsAndSpecializations
                            allCount={totalSpecialization}
                            loader={bookedLoader}
                            graphTitle="Specialization"
                            data={bookedData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Analytics;