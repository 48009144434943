/**
 * SelectionChip Component
 * 
 * Description: A chip component that displays a title and includes a delete button. 
 * It allows customization of the background color and handles delete actions through a callback.
 * 
 * File Name: SelectionChip.tsx
 * Date: [current date]
 */

import React from 'react';
import {
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Interface for the props of the SelectionChip component
interface ISelectionChipProps {
    title: string; // Title to display inside the chip
    onDelete: () => void; // Callback function for delete action
    bgColor?: string; // Optional background color for the chip
};

// Functional component for the selection chip
const SelectionChip: React.FC<ISelectionChipProps> = ({ title, onDelete, bgColor = "#d4d4d4" }) => {
    return (
        <span className='flex items-center'>
            <div className={`chip flex items-center gap-5 py-2 px-4 rounded-full `} style={{ backgroundColor: bgColor }}>
                <p className="font-Poppins-Regular font-size-20px text-[#464646]">
                    {title}
                </p>
                <button type="button" className='close-chip-btn flex items-center justify-center' onClick={onDelete}>
                    <FontAwesomeIcon className='text-[14px]' icon={faXmark} />
                </button>
            </div>
        </span>
    );
};

export default SelectionChip;