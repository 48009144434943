/**
 * ConfirmationModal Component
 * 
 * Description: This component displays a modal with a confirmation message. It includes options for a primary action (e.g., delete) and a secondary action (e.g., cancel).
 * The modal can show a loading spinner while performing an action.
 * 
 * Date: 24-07-2024
 */

import { CircularProgress, Modal } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

// Define the props type for the ConfirmationModal component
interface IConfirmationModalProps {
    show: boolean; // Boolean to control the visibility of the modal
    onClose: () => void; // Function to handle closing the modal
    title: string; // Title text displayed in the modal
    leftBtnText: string; // Text for the left button (e.g., "Cancel")
    rightBtnText: string; // Text for the right button (e.g., "Confirm")
    onConfirm: () => void; // Function to handle the confirm action
    loader: boolean; // Boolean to control the display of the loading spinner
    status?: "approve" | null;
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
    show = false, // Boolean to control the visibility of the modal
    onClose = () => { }, // Function to handle closing the modal
    title = "", // Title text displayed in the modal
    leftBtnText = "", // Text for the left button (e.g., "Cancel")
    rightBtnText = "", // Text for the right button (e.g., "Confirm")
    onConfirm = () => { }, // Function to handle the confirm action
    loader = false, // Boolean to control the display of the loading spinner
    status = null,
}) => {
    // Style for the modal container
    const customeStyle: React.CSSProperties = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgb(255, 255, 255)",
        maxWidth: 400,
        width: "100%",
        padding: 12,
        borderRadius: 8
    };
    // Style for the confirm button
    const greenBtn: React.CSSProperties = {
        padding: "7px 40px",
        backgroundColor: "#00443F",
        color: "white",
        borderRadius: 5
    };
    // Style for the cancel button
    const redBtn: React.CSSProperties = {
        padding: "7px 40px",
        backgroundColor: "red",
        color: "white",
        borderRadius: 5
    };
    // Style for the close button text
    const backBtn: React.CSSProperties = {
        color: "white",
    };
    // Style for the close button container
    const backBtnMaain: React.CSSProperties = {
        position: "absolute",
        top: -10,
        right: -10,
        backgroundColor: "red",
        borderRadius: 100,
        width: 20,
        height: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };
    // Style for the inline loading spinner
    const inLineLoader: React.CSSProperties = {
        width: 14,
        height: 14,
        color: "#fff"
    };
    return (
        <Modal
            open={show}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={customeStyle}>
                <button onClick={onClose} style={backBtnMaain}>
                    <FontAwesomeIcon className='font-size-16px ' icon={faXmark} size="2x" style={backBtn} />
                </button>
                <h1 className='text-center py-6 font-size-18px font-Poppins-Medium'>{title}</h1>

                <div className="flex justify-center items-center gap-3">
                    <button
                        onClick={onClose}
                        className='font-size-16px font-Poppins-Regular'
                        style={status === "approve" ? redBtn : greenBtn}>
                        {leftBtnText}
                    </button>
                    <button
                        disabled={loader}
                        onClick={onConfirm}
                        className='font-size-16px font-Poppins-Regular flex items-center gap-2'
                        style={status === "approve" ? greenBtn : redBtn}>
                        {rightBtnText} {loader && <CircularProgress style={inLineLoader} />}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;