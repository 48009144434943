/**
 * ChangePassword Component
 * 
 * Description:
 * This component provides a form for users to change their password. It includes fields for the current password, new password,
 * and confirm password. It performs validation to ensure that the new and confirm passwords match. On submission, it makes an
 * API call to update the password and displays appropriate success or error messages based on the response.
 * 
 * Props: None
 * 
 * File Name: ChangePassword.tsx
 * Date: 10-08-2024
 * 
 * Marker: DOC_START
 */

import React, { useState } from 'react';
import SimpleInput from '../SimpleInput';
import PrimaryBtn from '../GlobalComponents/primaryBtn';
import { useForm, Controller } from "react-hook-form";
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { removeToken } from 'src/helpers/helperFunctions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/Store/store';
import { removeAuth } from 'src/Store/reducer';

interface FormValues {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}; // Define the shape of the form values

interface iAPIResponse {
    message: string;
    [key: string]: any
}; // Define the shape of the API response

const ChangePassword: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [isLoader, setIsLoader] = useState<boolean>(false); // loader state
    const [passwordVisibility, setPasswordVisibility] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    // State for password, new password & confirm password
    const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        }
    }); // State for handling form input and validation

    // Custom validation function to ensure new and confirm passwords match
    const validatePasswordMatch = (value: string) => {
        const { newPassword } = getValues();
        return value === newPassword || 'Passwords do not match';
    };

    // API_CALL: API call for customer change password
    const onSubmit = async (data: FormValues) => {
        setIsLoader(true);
        const { confirmPassword, ...payload } = data;
        const { res, err } = await httpRequest<iAPIResponse>({ method: "put", path: "/customer/change-password", params: payload });
        if (res) {
            toast(res?.message || "Success!", { type: 'success' });
            setValue("currentPassword", "");
            setValue("newPassword", "");
            setValue("confirmPassword", "");
            const baseUrl = process.env.REACT_APP_WEBSITE_URL;
            const targetUrl = `${baseUrl}/login`;
            window.location.href = targetUrl;
            dispatch(removeAuth()); // logout
            removeToken();
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    return (
        <div className="parent-box parent-box-docter-margin">
            <h2 className="title-heading font-Poppins-SemiBold font-size-20px pb-5">Change Password</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="wellcome-login-admin flex flex-col">
                <div>
                    <div className="mb-2">
                        <Controller
                            name="currentPassword"
                            control={control}
                            rules={{ required: 'Password is required' }}
                            render={({ field }) => (
                                <SimpleInput
                                    isLabel={true}
                                    labelText='Current Password'
                                    showPasswordEye={true}
                                    type={passwordVisibility["currentPassword"] ? "text" : "password"}
                                    tooglePasswordVisibility={() => setPasswordVisibility(prev => ({ ...prev, currentPassword: !prev.currentPassword }))}
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                        {errors.currentPassword && <p className="font-size-14 text-[#ff0000]">{errors.currentPassword.message}</p>}
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="newPassword"
                            control={control}
                            rules={{ required: 'New Passowrd is required' }}
                            render={({ field }) => (
                                <SimpleInput
                                    isLabel={true}
                                    labelText='New Password'
                                    showPasswordEye={true}
                                    type={passwordVisibility["newPassword"] ? "text" : "password"}
                                    tooglePasswordVisibility={() => setPasswordVisibility(prev => ({ ...prev, newPassword: !prev.newPassword }))}
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                        {errors.newPassword && <p className="font-size-14 text-[#ff0000]">{errors.newPassword.message}</p>}
                    </div>
                    <div className="mb-2">
                        <Controller
                            name="confirmPassword"
                            control={control}
                            rules={{
                                required: 'Confirm Passowrd is required',
                                validate: validatePasswordMatch
                            }}
                            render={({ field }) => (
                                <SimpleInput
                                    isLabel={true}
                                    labelText='Confirm Password'
                                    showPasswordEye={true}
                                    type={passwordVisibility["confirmPassword"] ? "text" : "password"}
                                    tooglePasswordVisibility={() => setPasswordVisibility(prev => ({ ...prev, confirmPassword: !prev.confirmPassword }))}
                                    extraStyle={{ borderColor: "#00A297", marginBottom: 0 }}
                                    {...field}
                                />
                            )}
                        />
                        {errors.confirmPassword && <p className="font-size-14 text-[#ff0000]">{errors.confirmPassword.message}</p>}
                    </div>
                </div>
                <div>
                    <PrimaryBtn
                        loader={isLoader}
                        type="submit"
                        ButtonText="Update"
                        btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem", padding: "8px 32px" }}
                    />
                </div>
            </form>
        </div>
    );
};

export default ChangePassword;