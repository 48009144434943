import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCartItemsFromCookie, setCartItemsToCookie } from 'src/helpers/helperFunctions';
import { ICartItems } from 'src/types/GlobalInterfaces';

// Define the application state interface
export interface AppState {
    totalItems: number;
    cartItems: ICartItems[];
    subTotal: number; // for regular price
    total: number; // for sale price
};

const arr = getCartItemsFromCookie();

// Initial state of the cart slice
const initialState: AppState = {
    totalItems: arr.length || 0,
    cartItems: arr,
    subTotal: arr.reduce((total, item) => total + (item.regularPrice || 0), 0),
    total: arr.reduce((total, item) => total + (item.salePrice || item.regularPrice || 0), 0),
};

// Create the cart slice
export const Cart = createSlice({
    name: 'CART_SLICE',
    initialState,
    reducers: {
        // Reducer to add an item to the cart
        addItemToCart: (state, action: PayloadAction<ICartItems>) => {
            const newItem = action.payload;

            // Check if the item is already in the cart (both in state and cookie)
            const isExist = state.cartItems.some(item => item._id === newItem._id);

            if (!isExist) {
                // Add the new item to the cart items state
                const newCartItems = [...state.cartItems, newItem];
                state.totalItems = newCartItems?.length || 0;
                state.cartItems = newCartItems;

                // Update the subtotal and total
                state.subTotal = newCartItems.reduce((total, item) => total + (item.regularPrice || 0), 0);
                state.total = newCartItems.reduce((total, item) => total + (item.salePrice || item.regularPrice || 0), 0);

                // Update the cart items in the cookie
                setCartItemsToCookie(newCartItems);
            }
        },
        // Reducer to remove an item from the cart
        // Reducer to remove an item from the cart
        removeItemFromCart: (state, action: PayloadAction<string>) => {
            // Filter out the item to be removed
            const newCartItems = state.cartItems.filter(item => item._id !== action.payload);
            state.totalItems = newCartItems.length || 0;
            state.cartItems = newCartItems;

            // Update the subtotal and total
            state.subTotal = newCartItems.reduce((total, item) => total + (item.regularPrice || 0), 0);
            state.total = newCartItems.reduce((total, item) => total + (item.salePrice || item.regularPrice || 0), 0);

            // Update the cart items in the cookie
            setCartItemsToCookie(newCartItems);
        },
        cartEmpty: (state) => {
            state.totalItems = 0;
            state.cartItems = [];
            state.subTotal = 0;
            state.total = 0;
            setCartItemsToCookie([]); // cart empty
        },
        // Other reducers
    }
});

// Export action creators
export const { addItemToCart, removeItemFromCart, cartEmpty } = Cart.actions;

// Export the reducer to be used in the store
export default Cart.reducer;