/**
 * UserBiomarkers Component
 * 
 * Description: A React component to display a user's biomarker data, categorized into favorite, completed, and pending biomarkers. 
 * Includes UI for filtering by categories and toggling visibility for details.
 * 
 * File Name: UserBiomarkers.tsx
 * Date: 24-August-2024
 */
/**
 * UserBiomarkers Component
 * 
 * Description: A React component to display a user's biomarker data, categorized into favorite, completed, and pending biomarkers. 
 * Includes UI for filtering by categories and toggling visibility for details.
 * 
 * File Name: UserBiomarkers.tsx
 * Date: 24-August-2024
 */
import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPencil, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import httpRequest from 'src/helpers/httpRequest';
import { IAddSingleBio } from 'src/types/GlobalInterfaces';
import SingleBioMarkerResult from './SingleBioMarkerResult';
import moment from 'moment';
import SingleBioMarkerHistory from './SingleBioMarkerHistory';
import { returnColor } from 'src/helpers/helperFunctions';
import { Link } from 'react-router-dom';
import { customer, superAdmin } from 'src/lib/constans';

interface IBioCategory {
    name: string;
    _id: string;
    [key: string]: any;
};
interface ICustomer {
    date: string;
    gender: string
};
export interface ICompletedBios {
    date: string;
    measurement: boolean;
    name: string;
    value: string;
    _id: string;
    isShown: boolean;
    isFavourite: boolean;
    biomarkerColour: string;
    barcategory: string;
    [key: string]: any;
};

const UserBiomarkers: React.FC<{ id: string; isMyProfile: boolean; role: string }> = ({ id, isMyProfile, role }) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>('bottom');

    // *********************** CUSTOMER DATA *********************** //
    const [gender, setGender] = useState<string>("");
    const [dob, setDob] = useState<string>("");
    // *********************** CUSTOMER DATA *********************** //

    // *********************** CATEGORY STATES HOLD *********************** //
    const [categoryLoader, setCategoryLoader] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [allCategory, setAllCategory] = useState<IBioCategory[]>([]);
    const changeCategories = (txt: string) => {
        setSelectedCategory(txt);
        setFavouriteDropDown("");
        setPendingDropDown("");
        setCompletedDropDown("");
    };
    // *********************** CATEGORY STATES HOLD *********************** //

    // *********************** FAVORITE BIOMARKERS STATES HOLD *********************** //
    const [favouriteLoader, setFavouriteLoader] = useState<boolean>(false);
    const [favouriteList, setFavouriteList] = useState<ICompletedBios[]>([]);
    const [favouriteDropDown, setFavouriteDropDown] = useState<string>("");
    const toogleFavourite = (id: string) => {
        setFavouriteDropDown(prev => prev === id ? "" : id);
    };
    // *********************** FAVORITE BIOMARKERS STATES HOLD *********************** //

    // *********************** COMPLETED BIOMARKERS STATES HOLD *********************** //
    const [completedLoader, setCompletedLoader] = useState<boolean>(false);
    const [completedList, setCompletedList] = useState<ICompletedBios[]>([]);
    const [completedDropDown, setCompletedDropDown] = useState<string>("");
    const toogleCompleted = (id: string) => {
        setCompletedDropDown(prev => prev === id ? "" : id);
    };
    const updateBiosHistory = (categ: string) => {
        setFavouriteDropDown("");
        setPendingDropDown("");
        setCompletedDropDown("");
        if (categ === "favorite") {
            fetchFavoriteBios();
        } else {
            fetchCompletedBios();
        }
    };
    // *********************** COMPLETED BIOMARKERS STATES HOLD *********************** //

    // *********************** PENDING BIOMARKERS STATES HOLD *********************** //
    const [pendingLoader, setPendingLoader] = useState<boolean>(false);
    const [pendingList, setPendingList] = useState<IAddSingleBio[]>([]);
    const [pendingDropDown, setPendingDropDown] = useState<string>("");
    const tooglePending = (id: string) => {
        setPendingDropDown(prev => prev === id ? "" : id);
    };

    /**
    * Handles moving a vitals item from the "to be completed" list to the "completed" list.
    * Updates the state and triggers a refetch of completed vitals.
    */
    const addToCompleted = () => {
        setPendingList(prev => prev?.filter(v => v?._id !== pendingDropDown)); //remove from toBeCompletedList;
        setPendingDropDown(""); // hide dropdown
        fetchCompletedBios(); // completed List api again cal;
    };
    // *********************** PENDING BIOMARKERS STATES HOLD *********************** //

    //API_CALL: to fecth all biomarkers categories
    const fetchBioCategory = async () => {
        setCategoryLoader(true);
        const { res, err } = await httpRequest<IBioCategory[]>({ path: "/biomarker/getallbiomarkerCategory" });
        if (res) {
            setAllCategory(res);
        } else {
            toast(err, { type: 'error' });
        }
        setCategoryLoader(false);
    };

    //API_CALL: to fetch favorite biomarkers based on user ID
    const fetchFavoriteBios = async () => {
        setFavouriteLoader(true);
        const { res, err } = await httpRequest<ICompletedBios[]>({ path: `/healthprofile/biomarkers/favorites` });
        if (res) {
            setFavouriteList(res || []);
        } else {
            toast(err, { type: 'error' });
        }
        setFavouriteLoader(false);
    };

    //API_CALL: to fetch completed biomarkers based on user ID
    const fetchCompletedBios = async () => {
        setCompletedLoader(true);
        const { res, err } = await httpRequest<ICompletedBios[]>({ path: `/healthprofile/biomarker/details/${id}/completed?categoryId=${selectedCategory}` });
        if (res) {
            setCompletedList(res || []);
        } else {
            toast(err, { type: 'error' });
        }
        setCompletedLoader(false);
    };

    //API_CALL: to fetch pending biomarkers based on user ID
    const fetchPendingBios = async () => {
        setPendingLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: { biomarkerData: IAddSingleBio[]; customerData: ICustomer }; }>({ path: `/healthprofile/biomarker/details/${id}?categoryId=${selectedCategory}` });
        if (res) {
            setPendingList(res?.data?.biomarkerData || []);
            setGender(res?.data?.customerData?.gender || "");
            setDob(res?.data?.customerData?.date || "");
        } else {
            toast(err, { type: 'error' });
        }
        setPendingLoader(false);
    };

    //API_CALL: to add or remove a biomarker from the favorite list
    const addAndRemoveFavourite = async (id: string) => {
        const { res, err } = await httpRequest<{ message: string; }>({ method: "put", path: `/healthprofile/biomarker/${id}`, params: {} });
        if (res) {
            toast(res.message, { type: 'success' });
            setCompletedList(prev => prev.map(v => v?._id === id ? { ...v, isFavourite: !v?.isFavourite } : v));
            const itemToToggle = completedList.find(v => v?._id === id);
            if (itemToToggle) {
                const isExist = favouriteList.some(v => v._id === id);
                if (isExist) {
                    setFavouriteList(prev => prev.filter(v => v?._id !== id));
                } else {
                    setFavouriteList(prev => [...prev, { ...itemToToggle, isFavourite: true }]);
                }
            };
        } else {
            toast(err, { type: 'error' });
        }
    };

    const navigateToDetail = (bioId: string): string => {
        if (role === customer) {
            return `/bio-details/${id}/${bioId}/view`;
        } else if (role === superAdmin) {
            return `/biomarker-result/${id}/${bioId}/view`;
        } else {
            return "";
            // Future role-based navigation can be handled here
        }
    };

    // Fetch biomarker categories and favorites when the component mounts
    useEffect(() => {
        fetchBioCategory();
        if (isMyProfile) {
            fetchFavoriteBios();
        }
    }, []);

    // Fetch pending and completed biomarkers when the component mounts or `id` changes
    useEffect(() => {
        if (id) {
            fetchPendingBios();
            fetchCompletedBios();
        }
    }, [id, selectedCategory]);

    useEffect(() => {
        const checkSpace = () => {
            if (dropdownRef.current) {
                const dropdownRect = dropdownRef.current.getBoundingClientRect();
                const spaceBelow = window.innerHeight - dropdownRect.bottom;

                // If there's less space below than above, set dropdown position to top
                if (spaceBelow < dropdownRect.height) {
                    setDropdownPosition('top');
                } else {
                    setDropdownPosition('bottom');
                }
            }
        };

        // Add event listener for resizing the window
        window.addEventListener('resize', checkSpace);

        // Check space on mount
        checkSpace();

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('resize', checkSpace);
        };
    }, [completedDropDown, pendingDropDown, favouriteDropDown]);

    return (
        <>
            {/* buttons list */}
            <div className='health-bimarkers-tabs-btn-main'>
                <button
                    className={`font-size-14px font-Poppins-Medium theme-color-green health-bimarkers-tabs-btn rounded-full ${selectedCategory === "" && "active-tab"}`}
                    onClick={() => changeCategories("")}
                >Overall</button>
                {
                    categoryLoader
                        ?
                        <>
                            <CircularProgress size={14} className='loader__extra__class ms-5' />
                        </>
                        :
                        allCategory.map((v: IBioCategory) => (
                            <button
                                key={v._id}
                                className={`font-size-14px font-Poppins-Medium theme-color-green health-bimarkers-tabs-btn rounded-full ${selectedCategory === v._id && "active-tab"}`}
                                onClick={() => changeCategories(v._id)}
                            >{v.name}</button>
                        ))
                }
            </div>
            {/* button list */}
            <hr className="my-4" />
            {/* favourties list */}
            {isMyProfile && selectedCategory === ""
                &&
                <>
                    <div className='health-biomarkers-tab-1'>
                        <div className='health-vitals-content'>
                            <h2 className='font-Poppins-Regular text-[#313131] font-size-18px'><span className='theme-color-green font-Poppins-SemiBold'>Favorite Biomarkers</span> (Select up to 5 favorite biomarkers.)</h2>
                            {
                                favouriteLoader
                                &&
                                <div className="text-center mt-3">
                                    <CircularProgress size={24} style={{ color: "#00443f" }} />
                                </div>
                            }
                            <div className='health-vitals-row-main'>
                                {favouriteList.length > 0
                                    ?
                                    favouriteList.map((v: ICompletedBios, i: number) => (
                                        <div key={i} className='health-vitals-row-grid relative'>
                                            <Link to={navigateToDetail(v?._id)}
                                                style={{ borderLeftColor: returnColor(v?.biomarkerColour) }}
                                                className='font-size-16px health-vitals-border-left text-white font-Poppins-Regular hover:underline'
                                            >
                                                {v?.name}
                                            </Link>
                                            <span className='font-size-16px flex justify-center text-white font-Poppins-Regular'>
                                                {((v?.barcategory === "twoBarDNDAsc" || v?.barcategory === "twoBarDNDDesc") && v.value === "1") ?
                                                    "Detected"
                                                    : ((v?.barcategory === "twoBarDNDAsc" || v?.barcategory === "twoBarDNDDesc") && v.value === "0") ?
                                                        "Not Detected"
                                                        : ((v?.barcategory === "twoBarPNAsc" || v?.barcategory === "twoBarPNDesc") && v.value === "1") ?
                                                            "Positive"
                                                            : ((v?.barcategory === "twoBarPNAsc" || v?.barcategory === "twoBarPNDesc") && v.value === "0") ?
                                                                "Negative"
                                                                :
                                                                v?.value
                                                }
                                                {v?.isShown ? v?.measurement : ""}
                                            </span>
                                            <span className='font-size-16px flex justify-center text-white font-Poppins-Regular'>
                                                {moment(v?.date).format("DD-MM-YYYY")}
                                            </span>
                                            <span className='flex justify-center '>
                                                <FontAwesomeIcon onClick={() => toogleFavourite(v?._id)} icon={faPencil} />
                                            </span>
                                            <div style={{ backgroundColor: returnColor(v?.biomarkerColour) }} className='health-vitals-row-circle-dot'></div>
                                            <FontAwesomeIcon onClick={() => addAndRemoveFavourite(v?._id)} className='health-vitals-star cursor-pointer' icon={v?.isFavourite ? faStar : faStarRegular} />
                                            {favouriteDropDown === v?._id && (
                                                <div className={`health-vitals-dropdown ${dropdownPosition === "top" ? "bottom-[105%] top-auto" : "top-[105%]"}`} ref={dropdownRef}>
                                                    <SingleBioMarkerHistory
                                                        uid={id}
                                                        _date={dob}
                                                        _gender={gender}
                                                        bioId={favouriteDropDown}
                                                        onSuccess={() => updateBiosHistory("favorite")}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))
                                    :
                                    <p className="font-Poppins-Regular font-size-15px text-center">No Items</p>
                                }
                            </div>
                        </div>
                    </div>
                    <hr className='my-4' />
                </>
            }
            {/* completed list or tested biomarkers */}
            <div className='health-biomarkers-tab-1'>
                <div className='health-vitals-content'>
                    <h2 className='font-Poppins-Regular text-[#313131] font-size-18px'><span className='theme-color-green font-Poppins-SemiBold'>Tested Biomarkers</span> ({completedList.length || 0} biomarker out of {completedList.length + pendingList.length} has been tested)</h2>
                    {
                        completedLoader
                        &&
                        <div className="text-center mt-3">
                            <CircularProgress size={24} style={{ color: "#00443f" }} />
                        </div>
                    }
                    <div className='health-vitals-row-main'>
                        {completedList.length > 0
                            ?
                            completedList.map((v: ICompletedBios, i: number) => (
                                <div key={i} className='health-vitals-row-grid relative'>
                                    <Link to={navigateToDetail(v?._id)}
                                        style={{ borderLeftColor: returnColor(v?.biomarkerColour) }}
                                        className='font-size-16px health-vitals-border-left text-white font-Poppins-Regular hover:underline'
                                    >
                                        {v?.name}
                                    </Link>
                                    <span className='font-size-16px flex justify-center text-white font-Poppins-Regular'>
                                        {((v.barcategories === "twoBarDNDAsc" || v.barcategories === "twoBarDNDDesc") && v.value === "1") ?
                                            "Detected"
                                            : ((v.barcategories === "twoBarDNDAsc" || v.barcategories === "twoBarDNDDesc") && v.value === "0") ?
                                                "Not Detected"
                                                : ((v.barcategories === "twoBarPNAsc" || v.barcategories === "twoBarPNDesc") && v.value === "1") ?
                                                    "Positive"
                                                    : ((v.barcategories === "twoBarPNAsc" || v.barcategories === "twoBarPNDesc") && v.value === "0") ?
                                                        "Negative"
                                                        :
                                                        v?.value
                                        }
                                        {v?.isShown ? v?.measurement : ""}
                                    </span>
                                    <span className='font-size-16px flex justify-center text-white font-Poppins-Regular'>
                                        {moment(v?.date).format("DD-MM-YYYY")}
                                    </span>
                                    <span className='flex justify-center '>
                                        <FontAwesomeIcon onClick={() => toogleCompleted(v?._id)} icon={faPencil} />
                                    </span>
                                    <div style={{ backgroundColor: returnColor(v?.biomarkerColour) }} className='health-vitals-row-circle-dot'></div>
                                    {
                                        isMyProfile
                                        &&
                                        <FontAwesomeIcon onClick={() => addAndRemoveFavourite(v?._id)} className='health-vitals-star cursor-pointer' icon={v?.isFavourite ? faStar : faStarRegular} />
                                    }
                                    {completedDropDown === v?._id && (
                                        <div className={`health-vitals-dropdown ${dropdownPosition === "top" ? "bottom-[105%] top-auto" : "top-[105%]"}`} ref={dropdownRef}>
                                            <SingleBioMarkerHistory
                                                uid={id}
                                                _date={dob}
                                                _gender={gender}
                                                bioId={completedDropDown}
                                                onSuccess={() => updateBiosHistory("competed")}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))
                            :
                            <p className="font-Poppins-Regular font-size-15px text-center">No Items</p>
                        }
                    </div>
                </div>
            </div>
            <hr className='my-4' />
            {/* to be completed or pending */}
            <div className='health-biomarkers-tab-1'>
                <div className='health-vitals-content'>
                    <h2 className='font-Poppins-Regular text-[#313131] font-size-18px'><span className='theme-color-green font-Poppins-SemiBold'>To be Completed</span></h2>
                    {
                        pendingLoader
                        &&
                        <div className="text-center mt-3">
                            <CircularProgress size={24} style={{ color: "#00443f" }} />
                        </div>
                    }
                    <div className='health-vitals-row-main'>
                        {pendingList.length > 0
                            ?
                            pendingList.map((v, index) => (
                                <div key={index} className='health-vitals-row-grid-comp relative'>
                                    <span className='font-size-16px text-white font-Poppins-Regular'>{v?.name}</span>
                                    <span className='font-size-16px flex justify-center  text-white font-Poppins-Regular'>{v?.isShown ? v?.measurement : ""}</span>
                                    <span className='flex justify-end'><FontAwesomeIcon onClick={() => tooglePending(v?._id)} icon={pendingDropDown === v._id ? faChevronUp : faChevronDown} /></span>
                                    {pendingDropDown === v._id && (
                                        <div className={`health-vitals-dropdown ${dropdownPosition === "top" ? "bottom-[105%] top-auto" : "top-[105%]"}`} ref={dropdownRef}>
                                            <SingleBioMarkerResult
                                                item={v}
                                                onSuccess={addToCompleted}
                                                _date={dob}
                                                _gender={gender}
                                                uid={id}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))
                            :
                            <p className="font-Poppins-Regular font-size-15px text-center">No Items</p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserBiomarkers;