/**
 * ServicesEdit
 * 
 * Description: A React component that allows doctors to select and update the services they offer. 
 * The component fetches available services from the server, displays them in a dropdown, and lets 
 * the user select multiple services to save. The selected services are displayed as chips and can 
 * be removed if needed. The component handles form submission and validation.
 * 
 * File Name: ServicesEdit.tsx
 * Date: 02-09-2024
 */

import React, { FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageHeader from 'src/Components/Customer/PageHeader';
import PreviewProfileButton from 'src/Components/Customer/PreviewProfileButton';
import SelectionChip from 'src/Components/Customer/SelectionChip';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SelectDropdown from 'src/Components/GlobalComponents/SelectDropdown';
import httpRequest from 'src/helpers/httpRequest';
import { IDropDownArray } from 'src/types/GlobalInterfaces';
import { updateDoctorField } from '../../../Store/doctor';
import { setUniqueData } from "src/Store/reducer";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/store';

interface IServicesItem {
    name: string;
    _id: string;
};

const ServicesEdit: React.FC = () => {
    const { profile } = useSelector((store: RootState) => store.doctor);
    const dispatch = useDispatch();
    const [isError, setisError] = useState<string>("");
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [fetchLoader, setFetchLoader] = useState<boolean>(false);
    const [allServices, setAllServices] = useState<IDropDownArray[]>([]);
    const [selectedServices, setSelectedServices] = useState<IDropDownArray[]>([]);

    /**
   * handleSubmit
   * 
   * Description: Handles the form submission for updating the doctor's services. 
   * It checks if at least one service is selected, makes an API request to update the services, 
   * and dispatches the updated services to the Redux store.
   * 
   * @param {FormEvent} event - The form submission event.
   */
    // API_CALL: Updates the user's "servicesDetails" section with new data
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (!selectedServices.length) {
            setisError("Please select atleast one Service.");
            setTimeout(() => {
                setisError("");
            }, 3000);
            return
        }
        setIsLoader(true);
        const { res, err } = await httpRequest<{ message: string; data: { _id: string }[]; percent: number }>({
            method: "post", path: "/provider/update-services", params: {
                userServices: selectedServices.map(v => ({ _id: v.value }))
            }
        });
        if (res) {
            const { message, data, percent } = res;
            toast(message, { type: "success" }); // api success message
            const newArr = data.map(v => ({ _id: v._id, name: allServices.find(item => item.value === v._id)?.label || "" }));
            dispatch(updateDoctorField({ servicesDetails: newArr }));
            dispatch(setUniqueData({ key: 'profileCompletion', value: String(percent) }));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    /**
   * fetchAllServices
   * 
   * Description: Fetches all available services from the server and updates the `allServices` state 
   * with the fetched data. Displays a loading indicator while the data is being fetched.
   */
    const fetchAllServices = async () => {
        setFetchLoader(true);
        const { res, err } = await httpRequest<IServicesItem[]>({ path: "/provider/service/get" });
        if (res) {
            setAllServices(res.map(v => ({ label: v.name, value: v._id })));
        } else {
            toast(err, { type: "error" }); // api error
        }
        setFetchLoader(false);
    };

    /**
    * useEffect (profile)
    * 
    * Description: This useEffect hook sets the `selectedServices` state based on the doctor's 
    * profile information. It runs whenever the `profile` state changes.
    */
    useEffect(() => {
        if (profile) setSelectedServices(
            profile.servicesDetails
                ?
                profile.servicesDetails.map(v => ({ value: v._id, label: v.name }))
                :
                []
        );
    }, [profile]);

    /**
   * useEffect (fetchAllServices)
   * 
   * Description: This useEffect hook triggers the `fetchAllServices` function to fetch the list 
   * of services when the component is mounted.
   */
    useEffect(() => {
        fetchAllServices();
    }, []);

    return (
        <>
            <PageHeader
                title="Services"
                isButton={true}
                ButtonComponent={PreviewProfileButton}
            />
            <div className="parent-box minheight-parent-box">
                <p className="font-Poppins-semiBold font-size-18px theme-color-green mb-2">Enter the services that you offer.</p>
                <form onSubmit={handleSubmit}>
                    <SelectDropdown
                        loader={fetchLoader}
                        options={allServices}
                        isMulti={true}
                        data={selectedServices}
                        setData={setSelectedServices}
                        checkboxShow={false}
                        isLabel={true}
                        labelText="Services Name"
                        isRequired={true}
                        extraClass={"border__dropdown__primary"}
                    />

                    {isError && <p className="font-size-14 text-[#ff0000]">{isError}</p>}

                    <div className="my-5 flex flex-wrap gap-3">
                        {selectedServices.map((v: IDropDownArray) => (
                            <SelectionChip
                                title={v.label}
                                onDelete={() => setSelectedServices(item => item.filter(service => service.value !== v.value))}
                            />
                        ))}
                    </div>

                    <div className="flex justify-end">
                        <PrimaryBtn
                            type={"submit"}
                            loader={isLoader}
                            ButtonText='Save'
                            btnExtraStyle={{
                                borderRadius: "100rem",
                                backgroundColor: "#004540"
                            }}
                        />
                    </div>
                </form>
            </div>
        </>
    );
};

export default ServicesEdit;