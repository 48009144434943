/**
 * PrimaryBtn Component
 * 
 * Description: This component renders a customizable button with an optional loading spinner. 
 * It supports dynamic button text, a loading state, click event handling, and additional custom styles.
 * 
 * Props:
 * - ButtonText (string): Text to display on the button (default: "").
 * - loader (boolean): Whether to show a loading spinner (default: false).
 * - onPress (function): Function to call when the button is clicked (default: empty function).
 * - type (string): The type of the button, e.g., "button", "submit" (default: "").
 * - btnExtraStyle (object): Additional CSS styles to apply to the button (default: empty object).
 * - extraProps (object): Additional props to be applied to the button element (default: empty object).
 * 
 * File Name: PrimaryBtn.jsx
 * Date: 23-07-2024
 */


import React from 'react';
import { CircularProgress } from '@mui/material';

interface IPrimaryBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ButtonText: string;
  loader?: boolean;
  onPress?: () => void;
  type?: "button" | "submit" | "reset"; // Type adjusted to possible button types
  btnExtraStyle?: React.CSSProperties; // Correct type for CSS styles;
  btnExtralClasses?: string,
};

const PrimaryBtn: React.FC<IPrimaryBtnProps> = ({
  ButtonText = "",
  loader = false,
  onPress = () => { },
  type = "button",
  btnExtraStyle = {},
  btnExtralClasses = "",
  ...extraProps // Spread any additional props
}) => {
  // Style for the loading spinner
  const loaderStyle = {
    width: 14,
    height: 14,
    color: "#fff"
  };
  return (
    <button
      className={`bg-[#009E92] text-[#FFFFFF] border-radius-6px font-size-16px font-Poppins-SemiBold flex items-center justify-center gap-2 primary-btn-admin ${btnExtralClasses}`}
      disabled={loader}
      onClick={onPress}
      type={type}
      style={{ ...btnExtraStyle }}
      {...extraProps} // Apply additional props
    >
      {ButtonText}

      {loader && <CircularProgress style={loaderStyle} />}
    </button>
  );
};

export default PrimaryBtn;