/**
 * DiscussionsList Component
 * 
 * Description: Displays a list of discussions with functionalities to view details, change status, and filter discussions.
 * 
 * File Name: DiscussionsList.jsx
 * Date: 24-09-2024
 */

import React, { useEffect, useState } from 'react';
import { faEye, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@mui/material';
import qs from "qs";
import { toast } from 'react-toastify';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import ConfirmationModal from 'src/Components/GlobalComponents/ConfirmationModal';
import SearchFilterInput from 'src/Components/GlobalComponents/SearchFilterInput';
import TableWrapper from 'src/Components/GlobalComponents/TableWrapper';
import ToggleRadioBtn from 'src/Components/ToggleRadioBtn';
import { IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import httpRequest from 'src/helpers/httpRequest';
import { convertToHyperlinks, truncateString } from 'src/helpers/helperFunctions';
import moment from 'moment';

// Style for the modal container
const customeStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(255, 255, 255)",
    maxWidth: 600,
    width: "100%",
    borderRadius: 8
};
// Style for the close button container
const backBtnMaain: React.CSSProperties = {
    position: "absolute",
    top: -10,
    right: -10,
    backgroundColor: "red",
    borderRadius: 100,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

interface IDiscusItem {
    title: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    description: string;
    status: "approved" | "pending" | "declined";
    _id: string;
    discussionStatus?: boolean;
};

interface IDiscussionResponse {
    documents: IDiscusItem[];
    paginated: IPaginated;
};

const DiscussionsList: React.FC = () => {
    // List of tab names for filtering orders
    const tabList: string[] = ["approved", "pending", "declined"];

    // ******************** DETAIL MODAL ******************** //
    const [detailModal, setDetailModal] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<{ title: string; description: string } | null>(null);
    // Opens the detail modal with the selected discussion's title and description
    const detailModalOpen = (title: string, description: string) => {
        setDetailModal(true);
        setDetailData({ title, description });
    };
    // Closes the detail modal
    const detailModalClose = () => {
        setDetailModal(false);
        setDetailData(null);
    };
    // ******************** DETAIL MODAL ******************** //

    // States for loading status, pagination, and discussions list data
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination
    const [allDiscussions, setAllDiscussions] = useState<IDiscusItem[]>([]);
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        status: "approved", // bydefault set approved
        search: "",
    });

    // Updates search text in query parameters
    const handleChangeText = (txt: string) => {
        setQueryParams(prev => ({
            ...prev,
            search: txt,
            page: 1
        }));
    };

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Handles tab click to filter orders
    const handleTabClick = (tabName: string) => {
        setQueryParams(prev => ({
            ...prev,
            status: tabName,
            page: 1,
        }));
    };

    // *********************** status changed *********************** //
    const [statusFlag, setStatusFlag] = useState<false | "approved" | "declined">(false);
    const [statusLoader, setStatusLoader] = useState<boolean>(false);
    const [discussionId, setDiscussionId] = useState<string>("");
    // Sets the new status for a discussion
    const handleChangeStatus = (newStatus: "approved" | "declined", id: string) => {
        setDiscussionId(id);
        setStatusFlag(newStatus);
    };

    // Closes the status change modal
    const handleCloseModal = () => {
        setStatusFlag(false);
        setDiscussionId("");
    };

    // API_CALL: API call to change the status of a discussion
    const satusChangeApiCall = async () => {
        setStatusLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: `/disscussion/${statusFlag}/${discussionId}`,
            params: {}
        });
        if (res) {
            toast(res?.message || "Sucess", { type: 'success' });
            setQueryParams(prev => ({
                ...prev,
                page: 1,
                status: statusFlag
            }));
            handleCloseModal();
        } else {
            toast(err, { type: 'error' });
        }
        setStatusLoader(false);
    };
    // *********************** status changed *********************** //

    //  for status active & inactive
    const [switchLoader, setSwitchLoader] = useState<boolean>(false);
    //API_CALL: API call to toggle the discussion status
    const toogleDiscussionStatus = async (id: string) => {
        setSwitchLoader(true);
        const { res, err } = await httpRequest<{ message: string }>({
            method: "post",
            path: `/disscussion/update-status/${id}`,
            params: {}
        });
        if (res) {
            toast(res?.message || "Sucess", { type: 'success' });
            setAllDiscussions(prev => (
                prev.map(v =>
                    v._id === id ?
                        { ...v, discussionStatus: !v.discussionStatus }
                        :
                        v
                )
            ));
        } else {
            toast(err, { type: 'error' });
        }
        setSwitchLoader(false);
    };

    //API_CALL: API call to fetch all discussions
    const fetchDiscussions = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IDiscussionResponse>({ path: `/disscussion/all/get?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setAllDiscussions(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchDiscussions();
    }, [queryParams]);

    return (
        <>
            <BreadCrumb
                current="Discussions"
                previous="dashboard"
                navigateUrl="/admin"
                btnShow={false}
            />
            <div className="all-discussions">
                <Modal
                    open={detailModal}
                    onClose={detailModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div style={customeStyle}>
                        <button onClick={detailModalClose} style={backBtnMaain}>
                            <FontAwesomeIcon className='font-size-16px text-[#fff]' icon={faXmark} size="2x" />
                        </button>
                        <div className="p-3 modalHeader">
                            <h1 className='text-center font-size-20px font-Poppins-SemiBold text-[#00443f]'>Discussion</h1>
                        </div>
                        <hr className='my-0' style={{ border: '1px solid #D4D4D4' }} />
                        <div className="p-5 modalBody">
                            <h1 className='font-size-18px font-Poppins-SemiBold text-[#000]'>{detailData?.title || ""}</h1>
                            <p className="font-size-14px text-[#797F82] my-2 font-Poppins-Regular">
                                <span
                                    dangerouslySetInnerHTML={{ __html: convertToHyperlinks(detailData?.description || "") }}
                                />
                            </p>
                        </div>
                    </div>
                </Modal>
                <ConfirmationModal
                    show={statusFlag ? true : false}
                    onClose={handleCloseModal}
                    onConfirm={satusChangeApiCall}
                    title={`Are you sure you want to ${statusFlag === "approved" ? "approve" : statusFlag === "declined" ? "decline" : ""} ?`}
                    leftBtnText={"No"}
                    rightBtnText={"Yes"}
                    loader={statusLoader}
                    status={statusFlag === "approved" ? "approve" : null}
                />
                <div className="table-container">
                    <div className='flex items-center justify-between'>
                        <div className="products-tabs-btn">
                            {tabList.map((v: string) => (
                                <button
                                    key={v}
                                    className={`font-size-15px font-Poppins-Medium ${queryParams.status === v && 'active'}`}
                                    onClick={() => handleTabClick(v)}
                                >
                                    {
                                        v === "approved" ? `Approved Discussions` :
                                            v === "pending" ? `Pending Discussions` :
                                                v === "declined" ? `Declined Discussions` :
                                                    ""
                                    }
                                </button>
                            ))}
                        </div>
                        <SearchFilterInput
                            // containerExtraStyle={{ display: "block", width: "220px" }}
                            inputExtraStyle={{ marginBottom: 0 }}
                            onChangeText={handleChangeText}
                        />
                    </div>
                    <hr className='my-4' style={{ border: '1px solid #D4D4D4' }} />
                    {queryParams.status === "declined"
                        ?
                        <>
                            <TableWrapper
                                isLoading={isLoader}
                                page={paginated?.currentPage || 1}
                                total={paginated?.totalPages || 0}
                                thead={["S.No", "Title", "Posted by", "Created date", "Actions"]}
                                onChange={handlePageChange}
                                columnWidth={"6% 39% 20% 20% 15%"}
                            >
                                <div className='tbody-content-height-auto'>
                                    {allDiscussions?.map((item: IDiscusItem, index: number) => (
                                        <ul className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 39% 20% 20% 15%" }}>
                                            <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                            <li className="capitalize">{truncateString(item?.title || "", 30)}</li>
                                            <li>{`${item?.firstName || ""} ${item?.lastName || ""}`}</li>
                                            <li>{moment(item.createdAt).format("DD-MM-YYYY")}</li>
                                            <li className="action-last-colum justify-center">
                                                <button
                                                    onClick={() => detailModalOpen(item.title, item.description)}>
                                                    <FontAwesomeIcon icon={faEye} />
                                                </button>
                                            </li>
                                        </ul>
                                    ))}
                                </div>
                            </TableWrapper>
                        </>
                        :
                        <TableWrapper
                            isLoading={isLoader}
                            page={paginated?.currentPage || 1}
                            total={paginated?.totalPages || 0}
                            thead={["S.No", "Title", "Posted by", "Created date", "Status", "Actions"]}
                            onChange={handlePageChange}
                            columnWidth={"6% 29% 20% 20% 15% 10%"}
                        >
                            <div className='tbody-content-height-auto'>
                                {allDiscussions?.map((item: IDiscusItem, index: number) => (
                                    <ul className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "6% 29% 20% 20% 15% 10%" }}>
                                        <li>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                        <li className="capitalize">{truncateString(item?.title || "", 30)}</li>
                                        <li>{`${item?.firstName || ""} ${item?.lastName || ""}`}</li>
                                        <li>{moment(item.createdAt).format("DD-MM-YYYY")}</li>
                                        <li className='justify-center' style={{ justifyContent: 'center', paddingLeft: 0, }}>
                                            {item.status === "approved"
                                                ?
                                                <ToggleRadioBtn
                                                    loader={switchLoader}
                                                    isToggled={item.discussionStatus ? true : false}
                                                    handleToggle={() => toogleDiscussionStatus(item._id)}
                                                />
                                                :
                                                <div className="flex gap-2">
                                                    <button onClick={() => handleChangeStatus("declined", item._id)} className="font-size-13px leading-3 font-Poppins-Medium rounded py-1 px-2 border-[1px] border-[#cc2929] hover:bg-[#cc2929] text-[#cc2929] hover:text-[#fff]">Decline</button>
                                                    <button onClick={() => handleChangeStatus("approved", item._id)} className="font-size-13px leading-3 font-Poppins-Medium rounded py-1 px-2 border-[1px] border-[#00443f] hover:bg-[#00443f] text-[#00443f] hover:text-[#fff]">Approve</button>
                                                </div>
                                            }
                                        </li>
                                        <li className="action-last-colum justify-center">
                                            <button
                                                onClick={() => detailModalOpen(item.title, item.description)}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </button>
                                        </li>
                                    </ul>
                                ))}
                            </div>
                        </TableWrapper>
                    }
                </div>
            </div>
        </>
    );
};

export default DiscussionsList;