/**
 * PersonalDetails Component
 * 
 * Description: A React component for displaying and editing user personal details. 
 * It allows users to view their profile information or enter edit mode to update their details.
 * 
 * File Name: PersonalDetails.tsx
 * Date: 21-08-2024
 */

import { setUserDetails } from 'src/Store/reducer';
import { AppDispatch, RootState } from '@store/store';
import moment from 'moment';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import HealthProfileTabs from 'src/Components/Customer/HealthProfileTabs';
import PageHeader from 'src/Components/Customer/PageHeader';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SimpleInput from 'src/Components/SimpleInput';
import httpRequest from 'src/helpers/httpRequest';

const PersonalDetails: React.FC = () => {
    // Retrieve user details from the Redux store
    const { userDetails } = useSelector((store: RootState) => store.appReducer);
    const dispatch = useDispatch<AppDispatch>();

    // State to manage form input values
    const [data, setData] = useState<{ firstName: string; lastName: string; email: string; mobile: string; date: string; gender: string; }>({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        date: "",
        gender: "",
    });

    // State to manage edit mode and loader visibility
    const [isEditMode, setIsEditMode] = useState(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);

    // Handle changes in form input fields
    const handleChange = (key: string, value: string) => {
        setData(prev => ({
            ...prev,
            [key]: value
        }));
    };

    // API_CALL: API call for Handle form submission to update user profile
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoader(true);
        const { res, err } = await httpRequest<any>({ method: "put", path: "/customer/profile-edit", params: data });
        if (res) {
            console.log("RESPONSE", res);
            const { message = "", customerEdit } = res;
            toast(message, { type: 'success' });
            dispatch(setUserDetails(customerEdit)); // set data in redux store
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Initialize form data with user details from Redux store on component mount or userDetails change
    useEffect(() => {
        if (userDetails) {
            handleChange("firstName", userDetails.firstName);
            handleChange("lastName", userDetails.lastName);
            handleChange("email", userDetails.email);
            handleChange("mobile", userDetails.mobile);
            handleChange("date", userDetails.date);
            handleChange("gender", userDetails.gender);
        }
    }, [userDetails]);

    return (
        <>
            <PageHeader
                title="Health Profile"
            />

            <div className="parent-box parent-box-height">
                <HealthProfileTabs />
                {
                    isEditMode
                        ?
                        <div className='w-[95%]'>
                            <form onSubmit={handleSubmit}>
                                <div className='persnal-details-edit-box'>
                                    <div>
                                        <label className='text-[#2F2F31] font-size-14px font-Poppins-Medium'>First Name</label>
                                        <SimpleInput
                                            value={data?.firstName}
                                            inputMode='text'
                                            isRequired
                                            onChange={(val) => handleChange('firstName', val)}
                                            style={{marginBottom:"0.5rem"}}
                                        />
                                    </div>
                                    <div>
                                        <label className='text-[#2F2F31] font-size-14px font-Poppins-Medium'>Last Name</label>
                                        <SimpleInput
                                            value={data?.lastName}
                                            inputMode='text'
                                            isRequired
                                            onChange={(val) => handleChange('lastName', val)}
                                            style={{marginBottom:"0.5rem"}}
                                        />
                                    </div>
                                </div>
                                <div className='persnal-details-edit-box'>
                                    <div>
                                        <label className='text-[#2F2F31] font-size-14px font-Poppins-Medium'>Email</label>
                                        <SimpleInput
                                            value={data?.email}
                                            isRequired
                                            onChange={(val) => handleChange('email', val)}
                                            style={{marginBottom:"0.5rem"}}
                                        />
                                    </div>
                                    <div>
                                        <label className='text-[#2F2F31] font-size-14px font-Poppins-Medium'>Mobile</label>
                                        <SimpleInput
                                            value={data?.mobile}
                                            inputMode='numeric'
                                            isRequired
                                            onChange={(val) => handleChange('mobile', val)}
                                            style={{marginBottom:"0.5rem"}}
                                        />
                                    </div>
                                </div>
                                <div className='persnal-details-edit-box'>
                                    <div>
                                        <label className='text-[#2F2F31] font-size-14px font-Poppins-Medium'>Date of birth</label>
                                        <SimpleInput
                                            type='date'
                                            value={moment(data?.date).format("YYYY-MM-DD")}
                                            isRequired
                                            onChange={(val) => handleChange('date', val + "T00:00:00Z")}
                                            style={{marginBottom:"0.5rem"}}
                                        />
                                    </div>
                                    <div>
                                        <label className='text-[#2F2F31] font-size-14px font-Poppins-Medium'>Gender</label>
                                        <div className='persnal-details-edit-box-gender'>
                                            <button
                                                type='button'
                                                className={`font-size-14px theme-color-green font-Poppins-Medium ${data.gender === 'male' ? 'selected' : ''}`}
                                                onClick={() => handleChange('gender', "male")}
                                            >
                                                Male
                                            </button>
                                            <button
                                                type='button'
                                                className={`font-size-14px theme-color-green font-Poppins-Medium ${data.gender === 'female' ? 'selected' : ''}`}
                                                onClick={() => handleChange('gender', "female")}
                                            >
                                                Female
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-end mt-5'>
                                    <PrimaryBtn
                                        type='submit'
                                        loader={isLoader}
                                        ButtonText='Save'
                                        btnExtraStyle={{ borderRadius: "1.5rem", backgroundColor: "#004540" }}
                                    />
                                </div>
                            </form>
                        </div>
                        :
                        <div className='persnal-details-vitals'>
                            <div className='flex justify-end'>
                                <button onClick={() => setIsEditMode(true)} className='text-white persnal-detail-edit-btn font-size-16px font-Poppins-Medium'>Edit</button>
                            </div>
                            <div className='user-persnal-detail'>
                                <div className='flex justify-between'>
                                    <span className='font-Poppins-Medium text-[#444444] font-size-18px'>Name:</span>
                                    <span className='font-Poppins-Regular text-[#444444] font-size-18px capitalize'>{`${userDetails?.firstName} ${userDetails?.lastName}`}</span>
                                </div>
                                <div className='flex justify-between'>
                                    <span className='font-Poppins-Medium text-[#444444] font-size-18px'>Email:</span>
                                    <span className='font-Poppins-Regular text-[#444444] font-size-18px'>{userDetails?.email}</span>
                                </div>
                                <div className='flex justify-between'>
                                    <span className='font-Poppins-Medium text-[#444444] font-size-18px'>Mobile:</span>
                                    <span className='font-Poppins-Regular text-[#444444] font-size-18px'>{userDetails?.mobile}</span>
                                </div>
                                <div className='flex justify-between'>
                                    <span className='font-Poppins-Medium text-[#444444] font-size-18px'>Date of birth:</span>
                                    <span className='font-Poppins-Regular text-[#444444] font-size-18px'>{moment(userDetails?.date).format("DD-MM-YYYY")}</span>
                                </div>
                                <div className='flex justify-between'>
                                    <span className='font-Poppins-Medium text-[#444444] font-size-18px'>Gender:</span>
                                    <span className='font-Poppins-Regular text-[#444444] font-size-18px capitalize'>{userDetails?.gender}</span>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </>
    );
};

export default PersonalDetails;