import React, { ReactNode } from 'react';
import AdminSidebar from '../Components/AdminComponents/AdminSidebar';
import AdminHeader from '../Components/AdminComponents/AdminHeader';
import { useLocation } from 'react-router-dom';

// Define props type
interface IAdminLayoutProps {
    children: ReactNode;
}

const AdminLayout: React.FC<IAdminLayoutProps> = ({ children }) => {
    const {pathname} = useLocation();

    const arr:string [] = ["/admin", "/revenue-management","/analytics"];
    return (
        // <div className={`dashboards ${arr.includes(pathname) && "bg-[#F4F2EE]"}`}>
        <div className={`${arr.includes(pathname) && "bg-[#F4F2EE] h-full"}`}>
        <div className='dashboards'>
            <div className="">
                <AdminSidebar />
                <section className="admin-right-side-sup">
                    <AdminHeader />
                    {children}
                </section>
            </div>
        </div>
        </div>
    );
};

export default AdminLayout;