/**
 * Privacy Component
 * 
 * Description: This component allows users to view and update their privacy settings.
 * It fetches the current privacy settings from the server and displays them in a list.
 * Users can toggle between different privacy levels for various categories.
 * 
 * File Name: Privacy.tsx
 * Date: 03-09-2024
 */

import React, { useEffect, useState } from 'react';
import BackIcon from "../../assets/images/back-icon.png";
import rightArrowIcon from "../../assets/images/right-arrow-icon.png";
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { doctor } from 'src/lib/constans';
import { Skeleton } from '@mui/material';
import PageHeader from 'src/Components/Customer/PageHeader';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';

interface IDummyArr {
    key: string;
    title: string;
};

interface IAllPrivacyOptions {
    about: 0 | 1 | 2;
    workHistory: 0 | 1 | 2;
    education: 0 | 1 | 2;
    review: 0 | 1 | 2;
    location: 0 | 1 | 2;
    contact: 0 | 1 | 2;
    email: 0 | 1 | 2;
    socialLinks: 0 | 1 | 2;
};

const Privacy: React.FC = () => {
    const { userDetails } = useSelector((store: RootState) => store.appReducer);

    // Dummy array to map privacy items to display
    const dummyArr: IDummyArr[] = [
        { key: "about", title: "About me" },
        { key: "workHistory", title: "Work History" },
        { key: "education", title: "Education & Training" },
        { key: "review", title: "Reviews" },
        { key: "location", title: "Location" },
        { key: "contact", title: "Contact no." },
        { key: "email", title: "Email" },
        { key: "socialLinks", title: "Social links" },
    ];

    const privOptions: { title: string; key: 0 | 1 | 2 }[] = [
        { title: "Every One", key: 2 },
        { title: "My Connections", key: 1 },
        { title: "Nobody", key: 0 }
    ];

    const [isLoader, setIsLoader] = useState<boolean>(false); // Loading state for API requests
    const [showToggle, setShowToogle] = useState<null | keyof IAllPrivacyOptions>(null); // Toggle state for privacy options
    const [allPrivacy, setAllPrivacy] = useState<IAllPrivacyOptions>({
        about: 0,
        workHistory: 0,
        education: 0,
        review: 0,
        location: 0,
        contact: 0,
        email: 0,
        socialLinks: 0,
    });

    // Component to display back icon in page header
    const BackButton: React.FC = () => {
        return <button onClick={() => setShowToogle(null)} className='right-bar-icon '>
            <img src={BackIcon} alt="back-icon" />
        </button>
    }; // display back icon in page header

    // Functionality: API_CALL: for updating privacy settings
    const handleChange = async (key: keyof IAllPrivacyOptions, value: number) => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IAllPrivacyOptions>({
            method: "post",
            path: `/privacy-setting/update`,
            params: { [key]: value },
        });
        if (res) {
            const { about = 0, workHistory = 0, education = 0, review = 0, location = 0, contact = 0, email = 0, socialLinks = 0 } = res;
            setAllPrivacy({ about, workHistory, education, review, location, contact, email, socialLinks });
            setShowToogle(null);
            toast("Privacy updated!", { type: "success" });
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    // Functionality: API_CALL: to fetch user privacy settings
    const fetchUserPrivacy = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IAllPrivacyOptions>({ path: "/privacy-setting" });
        if (res) {
            const { about = 0, workHistory = 0, education = 0, review = 0, location = 0, contact = 0, email = 0, socialLinks = 0 } = res;
            setAllPrivacy({ about, workHistory, education, review, location, contact, email, socialLinks });
        } else {
            toast(err, { type: "error" }); // api error
        }
        setIsLoader(false);
    };

    // Fetch privacy settings on component mount
    useEffect(() => {
        fetchUserPrivacy();
    }, []);

    return (
        <>
            <PageHeader
                title="Privacy"
                isButton={showToggle ? true : false}
                ButtonComponent={BackButton}
            />
            {!showToggle &&
                <>
                    {userDetails?.userRole === doctor &&
                        <div className="parent-box">
                            {dummyArr.slice(0, 4).map(item => (
                                <div key={item.key} className="privacy-item flex items-center justify-between">
                                    <p className="font-size-18px font-Poppins-Medium">
                                        {item.title}
                                    </p>
                                    {isLoader ? (
                                        <Skeleton variant="rectangular" width={120} height={20} />
                                    ) : (
                                        <button
                                            onClick={() => setShowToogle(item.key as keyof IAllPrivacyOptions)}
                                            className="flex items-center justify-center gap-4 mr-4 cursor-pointer"
                                        >
                                            <span className="font-size-18px font-Poppins-Medium">
                                                {allPrivacy[item.key as keyof IAllPrivacyOptions] == 0
                                                    ? "Nobody"
                                                    : allPrivacy[item.key as keyof IAllPrivacyOptions] == 1
                                                        ? "My Connections"
                                                        : allPrivacy[item.key as keyof IAllPrivacyOptions] == 2
                                                            ? "Everyone"
                                                            : ""}
                                            </span>
                                            <span className='flex items-center justify-center'>
                                                <img src={rightArrowIcon} alt="right-arrow-icon" className='w-[8px]' />
                                            </span>
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>
                    }

                    <div className="parent-box">
                        {dummyArr.slice(4, 8).map(item => (
                            <div key={item.key} className="privacy-item flex items-center justify-between">
                                <p className="font-size-18px font-Poppins-Medium">
                                    {item.title}
                                </p>
                                {isLoader ? (
                                    <Skeleton variant="rectangular" width={120} height={20} />
                                ) : (
                                    <button
                                        onClick={() => setShowToogle(item.key as keyof IAllPrivacyOptions)}
                                        className="flex items-center justify-center gap-4 mr-4 cursor-pointer"
                                    >
                                        <span className="font-size-18px font-Poppins-Medium">
                                            {allPrivacy[item.key as keyof IAllPrivacyOptions] == 0
                                                ? "Nobody"
                                                : allPrivacy[item.key as keyof IAllPrivacyOptions] == 1
                                                    ? "My Connections"
                                                    : allPrivacy[item.key as keyof IAllPrivacyOptions] == 2
                                                        ? "Everyone"
                                                        : ""}
                                        </span>
                                        <span className='flex items-center justify-center'>
                                            <img src={rightArrowIcon} alt="right-arrow-icon" className='w-[8px]' />
                                        </span>
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            }

            {showToggle &&
                <div className="parent-box">
                    {privOptions.map(item => (
                        <div className="privacy-option flex items-center justif-left gap-4">
                            {isLoader ? (
                                <Skeleton
                                    variant="rectangular"
                                    className="mb-3"
                                    width={120}
                                    height={20}
                                />
                            ) : (
                                <>
                                    <input
                                        type="radio"
                                        id={`p-option-${item.key}`}
                                        name='privacy-option'
                                        checked={allPrivacy[showToggle] == item.key}
                                        onChange={() => handleChange(showToggle, item.key)}
                                    />
                                    <label htmlFor={`p-option-${item.key}`} className='ms-2 font-size-18px font-Poppins-Medium'>
                                        {item.title}
                                    </label>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            }
        </>
    );
};

export default Privacy;