/**
 * PlatformStatistics Component
 * 
 * Description: This component fetches and displays various statistics for the 
 * platform, including total biomarkers, tests, customers, doctors, orders, 
 * and revenue. It presents the data visually with icons and shows a loading 
 * spinner while fetching the statistics from the server.
 * 
 * File Name: PlatformStatistics.tsx
 * Date: 15-08-24
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from 'react';
import iconRevenue from "../../assets/images/admin-dashboard/revenue-icon.png";
import iconBiomarker from "../../assets/images/admin-dashboard/biomarker-icon.png";
import iconTest from "../../assets/images/admin-dashboard/test-icon.png";
import iconCustomer from "../../assets/images/admin-dashboard/customer-icon.png";
import iconDoctor from "../../assets/images/admin-dashboard/doctor-icon.png";
import iconOrder from "../../assets/images/admin-dashboard/purchase-order.png";
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

interface IItems {
    id: string;
    icon: string;
    title: string;
    value: number | null;
};

interface IResponseCount {
    totalBiomarkerCount: number;
    totalTest: number;
    totalCustomerCount: number;
    totalDoctor: number;
    totalOrders: number;
    totalRevenue: number;
};

const PlatformStatistics: React.FC = () => {
    const [data, setData] = useState<IItems[]>([
        {
            id: "totalBiomarkerCount",
            icon: iconBiomarker,
            title: "Total Biomarkers",
            value: null,
        },
        {
            id: "totalTest",
            icon: iconTest,
            title: "Total Tests",
            value: null,
        },
        {
            id: "totalCustomerCount",
            icon: iconCustomer,
            title: "Total Customers",
            value: null,
        },
        {
            id: "totalDoctor",
            icon: iconDoctor,
            title: "Total Doctors",
            value: null,
        },
        {
            id: "totalOrders",
            icon: iconOrder,
            title: "Total Orders",
            value: null,
        },
        {
            id: "totalRevenue",
            icon: iconRevenue,
            title: "Total Revenue",
            value: null,
        }
    ]);

    const [isLoader, setIsLoader] = useState<boolean>(false);

    const fetchDashboardStats = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ data: IResponseCount; message: string; }>({ path: "/test/dashoard/data" });
        if (res) {
            Object.entries(res?.data).map(([key, value]) => {
                setData(prev => prev.map(v => v.id === key ? { ...v, value } : v));
            });
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchDashboardStats();
    }, []);
    return (
        <div className="parent-box parent-box-dashboard">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-3">
                {
                    data.map((item, index) => (
                        <div key={index} className="dashboard-sm-box">
                            <div className="dashboard-inner-box w-[80%] text-center">
                                <img className='admin-dashboard-img' src={item.icon} alt="biomarker-icon" />
                                <p className="font-Poppins-Medium whitespace-nowrap font-size-20px">
                                    {item.title}
                                </p>
                                {
                                    isLoader
                                        ?
                                        <CircularProgress size={20} className="theme-color-green" />
                                        :
                                        <p className="font-Poppins-SemiBold font-size-26px theme-color-green">
                                            {`${item.id === "totalRevenue" ? "AED " : ""}${item.value || "-"}`}
                                        </p>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default PlatformStatistics;