import React, { Fragment, useEffect, useState } from 'react';

import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import AdminLayout from '../layouts/AdminLayout';
import CustomerLayout from '../layouts/CustomerLayout';

import { ICustomJwtPayload, getToken, removeToken } from '../helpers/helperFunctions';

import { removeAuth, setAuth, setUserDetails } from '../Store/reducer';
import SplashScreen from '../Components/SplashScreen';
import { jwtDecode } from 'jwt-decode';
import { IRouteItem } from 'src/types/routeTypes';
import httpRequest from 'src/helpers/httpRequest';
import { toast } from 'react-toastify';
import { AppDispatch, RootState } from '@store/store';
import { doctor } from 'src/lib/constans';
import { setDoctorState } from '../Store/doctor';
import ChatLayouts from 'src/layouts/ChatLayouts';

// Define props for ProtectedRoute component
interface IProtectedRouteProps {
    item: IRouteItem;
}

const layouts: Record<string, React.FC<any> | typeof Fragment> = {
    admin: AdminLayout,
    customer: CustomerLayout,
    chat: ChatLayouts,
    none: Fragment,
};

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ item }) => {
    const { layout, role, ele, isProtected, fallBack, extrnalFallBack = false } = item;
    const [isLoading, setIsLoading] = useState<boolean | undefined>(isProtected);
    const [unauthorize, setUnauthorize] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const { isLogged } = useSelector((state: RootState) => state.appReducer);
    const navigate = useNavigate();
    const LayoutComponent = layouts[layout];

    const token = getToken();

    const navigateToFallBack = () => {
        if (extrnalFallBack) {
            window.location.href = `${process.env.REACT_APP_WEBSITE_URL}${fallBack}`; // navigate to customer login
        } else {
            navigate(fallBack || "/");
        }
    };

    useEffect(() => {
        if (isLogged && token) {
            const decodedToken = jwtDecode<ICustomJwtPayload>(token);

            const userRole = decodedToken?.userRole || '';
            if (!(role || []).includes(userRole as string)) {
                setTimeout(() => {
                    setUnauthorize(true);
                }, 0);
            } else {
                setUnauthorize(false);
            }
            setIsLoading(false);
        }
    }, [navigate, isLogged, token]);

    useEffect(() => {
        if (!isLogged && !isLoading && isProtected) {
            navigateToFallBack();
        }
    }, [isLogged, isLoading, isProtected, navigate, fallBack]);

    useEffect(() => {
        async function init() {
            if (isLoading) {
                if (token) {
                    const { res, err } = await httpRequest<any>({ path: "/customer/profile" });
                    if (res) {
                        if (res?.userRole === doctor) {
                            dispatch(setDoctorState(res?._id));
                        }
                        dispatch(setAuth(token));
                        dispatch(setUserDetails(res));
                    } else {
                        toast(err, { type: 'error' });
                        dispatch(removeAuth()); // logout
                        removeToken();
                        navigateToFallBack();
                    }
                }
                else {
                    navigateToFallBack();
                }
                setIsLoading(false);
            }
        }
        init();
    }, [isProtected, token]);
    if (unauthorize) {
        return <Unauthorize />
    }
    if (isLoading) {
        return <SplashScreen />;
    };
    return <LayoutComponent>{ele}</LayoutComponent>
};

export default ProtectedRoute;

const Unauthorize: React.FC = () => {
    return (
        <div className='bg-secondary bg-opacity-25 d-flex align-items-center justify-content-center w-100' style={{ height: '100vh' }}>
            <p className='text-secondary mb-0'>
                You are not authorize to access this route. Go to <Link to="/" className='text_slate text-decoration-underline'>Home</Link>
            </p>
        </div>
    );
};