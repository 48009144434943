import { IDropDownArray } from "src/types/GlobalInterfaces";

export type cityName = "abu_dhabi" | "dubai" | "sharjah" | "ajman" | "ras_al_khaimah" | "fujairah" | "umm_al_quwain" | "al_ain" | "dibba" | "khor_fakkan";

const obj: Record<cityName, string> = {
    abu_dhabi: "Abu Dhabi",
    dubai: "Dubai",
    sharjah: "Sharjah",
    ajman: "Ajman",
    ras_al_khaimah: "Ras Al Khaimah",
    fujairah: "Fujairah",
    umm_al_quwain: "Umm Al Quwain",
    al_ain: "Al Ain",
    dibba: "Dibba",
    khor_fakkan: "Khor Fakkan",
};

export const cityList: IDropDownArray[] = [
    { label: "Abu Dhabi", value: "abu_dhabi" },
    { label: "Dubai", value: "dubai" },
    { label: "Sharjah", value: "sharjah" },
    { label: "Ajman", value: "ajman" },
    { label: "Ras Al Khaimah", value: "ras_al_khaimah" },
    { label: "Fujairah", value: "fujairah" },
    { label: "Umm Al Quwain", value: "umm_al_quwain" },
    { label: "Al Ain", value: "al_ain" },
    { label: "Dibba", value: "dibba" },
    { label: "Khor Fakkan", value: "khor_fakkan" }
];

export const printCity = (city: cityName): string => {
    return obj[city] || ""
};