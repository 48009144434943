/**
 * PreviewProfileButton Component
 * 
 * Description: A button component that provides a link to the user's profile view page. 
 * It is styled with a border and padding to give an outlined button appearance.
 * 
 * File Name: PreviewProfileButton.tsx
 * Date: 30-08-2024
 */

import React from 'react';
import { Link } from 'react-router-dom';

// Define the inline styles for the button
const outLineButton: React.CSSProperties = {
    border: "1px solid #004540", // Border color
    borderRadius: "1.5rem", // Rounded corners
    padding: "0.4rem 2rem", // Padding inside the button
};

// Functional component for the preview profile button
const PreviewProfileButton: React.FC = () => {
    return (
        <Link style={outLineButton} className="text-[#004540] primary-btn-admin font-size-16px font-Poppins-SemiBold" to="/profile-view">Preview Profile</Link>
    );
};

export default PreviewProfileButton;