/**
 * HealthProfileTabs Component
 * 
 * Description: This component renders a set of navigation tabs for the health profile section. Each tab is a link that navigates to different parts of the health profile. The currently active tab is highlighted.
 * 
 * File Name: HealthProfileTabs.tsx
 * Date: 12-08-2024
 */

import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface IItem {
    title: string;
    url: string;
};

const HealthProfileTabs: React.FC = () => {
    const { pathname } = useLocation();

    const tabList: IItem[] = [
        { title: "Personal Details", url: "/personal-details" },
        { title: "Vitals", url: "/health-vitals" },
        { title: "Biomarkers", url: "/health-biomarkers" },
    ];
    return (
        <>
            <div className="flex gap-4 items-center">
                {
                    tabList.map((item: IItem, index: number) => (
                        <Link key={index} to={item?.url} className={`border font-size-16px font-Poppins-SemiBold health-tabs-main-btn rounded-full ${pathname === item?.url ? "text-[#fff] theme-bg-green" : "theme-color-green border-[#004540]"}`}>{item?.title || ""}</Link>
                    ))
                }
            </div>
            <hr className="my-4" />
        </>
    );
};

export default HealthProfileTabs;