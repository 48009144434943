/**
 * ManageDoctorAccess
 *
 * Description: This component is used to manage a doctor's access to different sections such as tests, health profiles, and suggested tests.
 * It also allows toggling main access and handling various actions related to a doctor's permissions.
 *
 * File Name: ManageDoctorAccess.tsx
 * Date: 12-09-2024
 */

import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomerTableWrapper from 'src/Components/Customer/CustomerTableWrapper';
import PageHeader from 'src/Components/Customer/PageHeader';
import ToggleRadioBtn from 'src/Components/ToggleRadioBtn';
import BackIcon from "src/assets/images/back-icon.png";
import httpRequest from 'src/helpers/httpRequest';
import { IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import eyeIcon from "src/assets/images/eye-icon-green.png";
import cartIcon from "src/assets/images/cart-icon-green.png";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { addItemToCart } from 'src/Store/Cart';
import AddToCartModal from 'src/Components/Customer/AddToCartModal';

type TabKey = "all_tests" | "health_profile" | "suggested_tests";

interface IData {
    firstName: string;
    lastName: string;
    _id: string;
    speicalizationData: string[];
    education: { course: string }[];
    status: boolean;
};

interface IItem {
    title: string;
    key: TabKey;
};

interface ITest {
    _id: string;
    collectionDate: string;
    testName: string;
    testId: string;
    status: boolean;
};
interface IHealth {
    _id: string;
    biomarkerCategoryId: string;
    date: string;
    biomarkerName: string;
    status: boolean;
};
interface ISuggested {
    _id: string;
    createdAt: string;
    name: string;
    productId: string;
    regularPrice: number;
    salePrice: number;
};

const ManageDoctorAccess: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { id = "" } = useParams();

    // Tabs to switch between different views
    const tabList: IItem[] = [
        { title: "All Tests", key: "all_tests" },
        { title: "Health Profile", key: "health_profile" },
        { title: "Suggested Tests", key: "suggested_tests" },
    ];

    const [isModal, setIsModal] = useState<boolean>(false); // State for controlling modal visibility
    const [activeTab, setActiveTab] = useState<TabKey>("all_tests"); // State for tracking the active tab

    // Loader states for different API calls
    const [infoLoader, setInfoLoader] = useState<boolean>(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);

    // Doctor information and data for different tabs
    const [data, setData] = useState<IData | null>(null);
    const [allTests, setAllTests] = useState<ITest[]>([]);
    const [healthProfiles, setHealthProfiles] = useState<IHealth[]>([]);
    const [suggestedTest, setSuggestedTest] = useState<ISuggested[]>([]);

    // Pagination state for API calls
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    });

    // Handles tab change and page reset
    const handleTabChange = (key: TabKey) => {
        setActiveTab(key);
        handlePageChange(1);
    };

    // Query parameters for API calls
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
    });

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    // Adds item to cart and shows modal
    const _add_to_cart = (item: ISuggested) => {
        const { name, salePrice, regularPrice, productId } = item;
        const obj = { productName: name, salePrice: Number(salePrice), regularPrice: Number(regularPrice), _id: productId };
        dispatch(addItemToCart(obj)); // Dispatch action to add item to cart
        setIsModal(true); // Show modal after adding item to cart
    };

    // *************************** Toogle Main Access ***************************
    const [toogleLoader, setToogleLoader] = useState<boolean>(false);
    // API_CALL: Toggles main access for the doctor
    const handleToogleMainAccess = async () => {
        setToogleLoader(true);
        const { res, err } = await httpRequest<{ message: string; status: boolean }>({
            method: "post",
            path: "/manageacess/for-doctors",
            params: {
                doctorId: id,
                status: !data?.status,
            },
        });
        if (res) {
            const { message = "", status = false } = res;
            toast(message || "Success", { type: "success" });
            setData(prev => {
                if (prev) {
                    return { ...prev, status };
                }
                return prev; // Or handle this case as needed
            });
            setActiveTab("all_tests")
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setToogleLoader(false);
    };
    // *************************** Toogle Main Access ***************************

    // API_CALL: Fetches doctor information
    const fetchDoctorInfo = async () => {
        setInfoLoader(true);
        const { res, err } = await httpRequest<IData>({ path: `/manageacess/doctor/${id}` });
        if (res) {
            setData(res);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setInfoLoader(false);
    };

    // API_CALL: Toggles test access
    const testAccessToogle = async (testId: string, newStatus: boolean) => {
        const { res, err } = await httpRequest<{ message: string; status: boolean }>({
            method: "post",
            path: "/manageacess/addtest/to-doctors",
            params: {
                doctorId: id,
                status: newStatus,
                testId,
            },
        });
        if (res) {
            const { message = "", status = false } = res;
            setAllTests(prev => prev.map(v => v._id === testId ? { ...v, status: status } : v));
            toast(message || "Success", { type: "success" });
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
    };

    // API_CALL: Toggles health biomarker access
    const bioAccessToogle = async (biomarkerCategoryId: string, newStatus: boolean) => {
        const { res, err } = await httpRequest<{ message: string; status: boolean }>({
            method: "post",
            path: "/manageacess/addhealth/to-doctors",
            params: {
                doctorId: id,
                status: newStatus,
                biomarkerCategoryId,
            },
        });
        if (res) {
            const { message = "", status = false } = res;
            setHealthProfiles(prev => prev.map(v => v.biomarkerCategoryId === biomarkerCategoryId ? { ...v, status: status } : v));
            toast(message || "Success", { type: "success" });
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
    };

    // API_CALL: Fetches all tests data
    const fetchAllTests = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ documents: ITest[]; paginated: IPaginated }>({ path: `/manageacess/for-alltest/${id}?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setAllTests(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setIsLoader(false);
    };

    // API_CALL: Fetches health profiles data
    const fetchHealthBios = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ documents: IHealth[]; paginated: IPaginated }>({
            path:
                `/manageacess/for-healthprofile/${id}?${qs.stringify(queryParams)}`
        });
        if (res) {
            let { documents = [], paginated } = res;
            setHealthProfiles(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setIsLoader(false);
    };

    // API_CALL: Fetches suggested tests data
    const fetchSuggestedTests = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<{ documents: ISuggested[]; paginated: IPaginated }>({ path: `/appoiment/all/refer/${id}?${qs.stringify(queryParams)}` });
        if (res) {
            let { documents = [], paginated } = res;
            setSuggestedTest(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setIsLoader(false);
    };

    // Effect for fetching doctor info when component mounts or id changes
    useEffect(() => {
        fetchDoctorInfo();
    }, [id]);

    // Effect for fetching data based on the active tab and queryParams
    useEffect(() => {
        if (data?.status) {
            if (activeTab === "all_tests") {
                fetchAllTests();
            } else if (activeTab === "health_profile") {
                fetchHealthBios();
            } else if (activeTab === "suggested_tests") {
                fetchSuggestedTests();
            } else { }
        }
    }, [data, activeTab, queryParams]);

    const BackButton: React.FC = () => {
        return <button className='right-bar-icon' onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back-icon" />
        </button>;
    }; // display back icon in page header

    return (
        <>
            <PageHeader
                title="My Doctor"
                isButton={true}
                ButtonComponent={BackButton}
            />
            <AddToCartModal
                show={isModal}
                onClose={() => setIsModal(false)}
            />
            <div className="parent-box">
                {infoLoader
                    ?
                    <div className="flex items-end justify-between">
                        <div>
                            <Skeleton width={200} variant="text" className='font-size-25px' />
                            <Skeleton width={180} variant="text" className='font-size-20px' />
                            <Skeleton width={150} variant="text" className='font-size-18px' />
                        </div>
                        <div>
                            <Skeleton width={200} height={36} variant="rounded" />
                        </div>
                    </div>
                    :
                    <div className="flex items-end justify-between">
                        <div>
                            <h1 className="font-Poppins-SemiBold font-size-28px theme-color-green capitalize">
                                {`${data?.firstName || ""} ${data?.lastName || ""}`}
                            </h1>
                            <p className='font-Poppins-Medium font-size-20px text-[#202020] flex items-center'>
                                {data?.speicalizationData?.length ? (
                                    data.speicalizationData.map((v, i) => (
                                        <span key={i}>
                                            <span>{v}</span>
                                            {(i + 1) < data.speicalizationData.length &&
                                                <span className="mx-1">|</span>
                                            }
                                        </span>
                                    ))
                                ) : (
                                    <span>...</span>
                                )}
                            </p>
                            <p className='font-Poppins-Regular font-size-18px text-[#393939] flex items-center'>
                                {data?.education?.length ? (
                                    data.education.map((v, i) => (
                                        <span key={i}>
                                            <span>{v.course || "N/A"}</span>
                                            {(i + 1) < data.education.length &&
                                                <span className="mr-1">,</span>
                                            }
                                        </span>
                                    ))
                                ) : (
                                    <span>...</span>
                                )}
                            </p>
                        </div>
                        <div>
                            <div className="flex items-center justify-between border border-slate-400 p-2 gap-x-20 rounded ">
                                <p className='font-Poppins-Regular font-size-15px text-[#404040]'>Access</p>
                                <ToggleRadioBtn
                                    isToggled={data?.status}
                                    handleToggle={handleToogleMainAccess}
                                    loader={toogleLoader}
                                />
                            </div>
                        </div>
                    </div>
                }
                <hr className='my-4' style={{ border: '1px solid #D4D4D4' }} />
                {data?.status ?
                    <>
                        <div className="flex gap-4 items-center">
                            {
                                tabList.map((item: IItem, index: number) => (
                                    <button
                                        onClick={() => handleTabChange(item.key)}
                                        key={index}
                                        className={`border font-size-16px font-Poppins-SemiBold health-tabs-main-btn rounded-full ${activeTab === item.key ? "text-[#fff] theme-bg-green" : "theme-color-green border-[#004540]"}`}>
                                        {item.title}
                                    </button>
                                ))
                            }
                        </div>
                        <hr className='my-4' style={{ border: '1px solid #D4D4D4' }} />
                        {activeTab === "all_tests"
                            ?
                            <CustomerTableWrapper
                                isLoading={isLoader}
                                thead={["S.No", "Test Name", "Date", "Access"]}
                                page={paginated?.currentPage || 1}
                                total={paginated?.totalPages || 0}
                                columnWidth={"1fr 2fr 2fr 2fr .5fr"}
                                onChange={handlePageChange}
                            >
                                {allTests.map((item: ITest, index: number) => (
                                    <ul key={index} className='font-size-16px tbody-light-table customer-table-wrapper-body-padding-non customer-table-wrapper-body tbody-light-table-customer font-Poppins-Regular grid' style={{ gridTemplateColumns: "1fr 2fr 2fr 2fr .5fr" }}>
                                        <li className="action-last-colum-non">{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                        <li>{item.testName}</li>
                                        <li>{moment(item.collectionDate).format("DD-MM-YYYY")}</li>
                                        <li className='action-last-colum'>
                                            <input type="checkbox"
                                                key={index}
                                                checked={item?.status || false}
                                                onChange={(e) => testAccessToogle(item._id, e.target.checked)}
                                            />
                                        </li>
                                    </ul>
                                ))}
                            </CustomerTableWrapper>
                            : activeTab === "health_profile"
                                ?
                                <CustomerTableWrapper
                                    isLoading={isLoader}
                                    thead={["S.No", "Health System", "Date", "Access"]}
                                    page={paginated?.currentPage || 1}
                                    total={paginated?.totalPages || 0}
                                    columnWidth={"1fr 2fr 2fr 2fr .5fr"}
                                    onChange={handlePageChange}
                                >
                                    {healthProfiles.map((item: IHealth, index: number) => (
                                        <ul key={index} className='font-size-16px tbody-light-table customer-table-wrapper-body-padding-non customer-table-wrapper-body tbody-light-table-customer font-Poppins-Regular grid' style={{ gridTemplateColumns: "1fr 2fr 2fr 2fr .5fr" }}>
                                            <li className="action-last-colum-non">{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                            <li>{item.biomarkerName}</li>
                                            <li>{moment(item.date).format("DD-MM-YYYY")}</li>
                                            <li className='action-last-colum'>
                                                <input type="checkbox"
                                                    key={index}
                                                    checked={item?.status || false}
                                                    onChange={(e) => bioAccessToogle(item.biomarkerCategoryId, e.target.checked)}
                                                />
                                            </li>
                                        </ul>
                                    ))}
                                </CustomerTableWrapper>
                                :
                                <CustomerTableWrapper
                                    isLoading={isLoader}
                                    thead={["S.No", "Test Name", "Suggested Date", "Action"]}
                                    page={paginated?.currentPage || 1}
                                    total={paginated?.totalPages || 0}
                                    columnWidth={"1fr 2fr 2fr 2fr .5fr"}
                                    onChange={handlePageChange}
                                >
                                    {suggestedTest.map((item: ISuggested, index: number) => (
                                        <ul key={index} className='font-size-16px tbody-light-table customer-table-wrapper-body-padding-non customer-table-wrapper-body tbody-light-table-customer font-Poppins-Regular grid' style={{ gridTemplateColumns: "1fr 2fr 2fr 2fr .5fr" }}>
                                           <li className="action-last-colum-non">{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                                            <li>{item.name}</li>
                                            <li>{moment(item.createdAt).format("DD-MM-YYYY")}</li>
                                            <li className='action-last-colum'>
                                                <button onClick={() => _add_to_cart(item)}>
                                                    <img src={cartIcon} alt="cart" />
                                                </button>
                                                <Link to={`/product-detail/${item.productId}/view`}>
                                                    <img src={eyeIcon} alt="eye" />
                                                </Link>
                                            </li>
                                        </ul>
                                    ))}
                                </CustomerTableWrapper>
                        }
                    </>
                    :
                    <p className="font-Poppins-Medium font-size-20px text-[#202020] text-center">No Access.</p>
                }
            </div>
        </>
    );
};

export default ManageDoctorAccess;