/**
 * Specialization Component
 * 
 * Description: Displays the doctor's specializations. If `isMyProfile` is true, an edit icon 
 * is shown to modify the list of specializations.
 * 
 * Props:
 * - isMyProfile (boolean): Displays edit icon if true.
 * - specailizedDetails ({ _id: string; name: string }[]): List of specializations.
 * 
 * File Name: Specialization.tsx
 * Date: 02-09-2024
 */

import React from 'react';
import { Link } from 'react-router-dom';
import edit from "../../../assets/images/awesome-edit.svg";

const Specialization: React.FC<{ isMyProfile: boolean; specailizedDetails: { _id: string; name: string }[] }> = ({ isMyProfile = false, specailizedDetails = [] }) => {
    return (
        <div className="parent-box parent-box-margin-issue relative">
            <h2 className='font-size-20px font-Poppins-SemiBold theme-color-green mb-1'>Specialization</h2>
            <div className="services mt-6 flex items-center gap-4 flex-wrap">
                {specailizedDetails?.map(item => (
                    <div key={item._id} className='py-2 px-6 rounded-md border border-solid border-[#707070] bg-[#F3F3F3]'>
                        <p className="font-Poppins-Regular font-size-18px">{item.name}</p>
                    </div>
                ))}
            </div>
            {isMyProfile
                &&
                <Link to="/my-specialization" className="edit-pro-icon absolute top-2 right-4">
                    <img src={edit} alt="edit-icon" />
                </Link>
            }
        </div>
    );
};

export default Specialization;