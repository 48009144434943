/**
 * TestInstructionTab Component
 *
 * This component allows users to manage test instructions for a product. Users can add, edit, and delete instructions. It includes a form for adding new instructions, a table for displaying existing instructions, and modals for editing and confirming deletions.
 *
 * File Name: TestInstructionTab.tsx
 * Date: 31-07-24
 */

import React, { FormEvent, useState } from 'react';
import editIcon from '../../../assets/images/edit-icon.png';
import deleteIcon from '../../../assets/images/delete-icon.png';
import TableWrapper from '../../GlobalComponents/TableWrapper';
import { IProductItems } from 'src/types/GlobalInterfaces';
import PrimaryBtn from 'src/Components/GlobalComponents/primaryBtn';
import SimpleInput from 'src/Components/SimpleInput';
import { generateRandomKey } from 'src/helpers/helperFunctions';
import ConfirmationModal from 'src/Components/GlobalComponents/ConfirmationModal';
import InputsModal from 'src/Components/GlobalComponents/InputsModal';

interface IInstObj { instructionDetail: string; _id: string }

const TestInstructionTab: React.FC<{
    state: IProductItems;
    setState: React.Dispatch<React.SetStateAction<IProductItems>>;
    tabChange: () => void;
}> = ({
    state,
    setState,
    tabChange,
}) => {

        const [inst, setInst] = useState<string>(""); // State to handle the new instruction input value
        const [instEdit, setInstEdit] = useState<boolean>(false); // State to control the visibility of the edit modal
        const [editInst, setEditInst] = useState<string>(""); // State to hold the instruction being edited
        const [isDelete, setIsDelete] = useState<boolean>(false); // State to control the visibility of the delete confirmation modal
        const [instId, setInstId] = useState<string>(""); // State to hold the ID of the instruction being deleted or edited

        // Handle form submission to add a new instruction
        const handleSubmit = (event: FormEvent) => {
            event.preventDefault();
            let obj: IInstObj = {
                instructionDetail: inst,
                _id: generateRandomKey()
            };
            // Update the state with the new instruction
            setState(prev => ({
                ...prev,
                testInstuction: [...prev.testInstuction, obj]
            }));

            setInst("");
        };

        // Set states for editing an instruction
        const handleEdit = (obj: IInstObj): void => {
            setInstEdit(true);
            setInstId(obj?._id);
            setEditInst(obj?.instructionDetail);
        };

        // Set states for deleting an instruction
        const handleDelete = (id: string): void => {
            setIsDelete(true);
            setInstId(id);
        };

        // Close modals and reset relevant states
        const modalClose = (): void => {
            setInstEdit(false);
            setIsDelete(false);
            setInstId("");
        };

        // Delete an instruction from the state
        const deleteInst = (): void => {
            setState(prev => ({
                ...prev,
                testInstuction: prev?.testInstuction?.filter(v => v?._id !== instId),
            }));
            modalClose();
        };

        // Update an instruction in the state
        const updateInst = () => {
            let Arr = state?.testInstuction?.map(v => {
                if (v?._id === instId) {
                    return {
                        ...v,
                        instructionDetail: editInst
                    };
                }
                return v;
            });
            setState(prev => ({
                ...prev,
                testInstuction: Arr,
            }));
            modalClose();
        };

        return (
            <>
                {/* Modal for editing an instruction */}
                <InputsModal
                    btnText='Update'
                    show={instEdit}
                    onClose={modalClose}
                    onSubmit={updateInst}
                    arr={[{ value: editInst, setValue: (val) => setEditInst(val), title: "Instruction", type: "textArea", isRequired: true }]}
                />
                {/* Modal for confirming deletion */}
                <ConfirmationModal
                    show={isDelete}
                    onClose={modalClose}
                    title="Are you sure you want to delete?"
                    leftBtnText="No"
                    rightBtnText="Yes"
                    onConfirm={deleteInst}
                    loader={false}
                />
                {/* Table displaying test instructions */}
                <TableWrapper
                    thead={["S.No", "Instruction", "Action",]}
                    total={state?.testInstuction?.length < 1 ? 0 : 1}
                    columnWidth={"10% 80% 10%"}
                >
                    {state?.testInstuction?.map((v: IInstObj, i: number) => (
                        <ul key={i} className='font-size-16px tbody-light-table font-Poppins-Regular grid' style={{ gridTemplateColumns: "10% 80% 10%" }}>
                            <li>{i + 1}</li>
                            <li className="break-all">{v?.instructionDetail || ""}</li>
                            <li className='action-last-colum'>
                                <img onClick={() => handleEdit(v)} src={editIcon} alt="" />
                                <img onClick={() => handleDelete(v?._id)} src={deleteIcon} alt="" />
                            </li>
                        </ul>
                    ))}
                </TableWrapper>
                {/* Form for adding new test instructions */}
                <div className='products-tabs-content-main'>
                    <form onSubmit={handleSubmit}>
                        <div className='products-tabs-content'>
                            <SimpleInput
                                value={inst}
                                onChange={(val) => setInst(val)}
                                isLabel={true}
                                labelText={"Test Instruction"}
                                isRequired={true}
                                type={"textArea"}
                                rows={5}
                            />
                        </div>
                        <div className='flex justify-between my-6'>
                            <PrimaryBtn
                                ButtonText="Add New Test Instruction"
                                btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                                type='submit'
                            />
                            <PrimaryBtn
                                ButtonText="Next"
                                btnExtraStyle={{ backgroundColor: "#00443f", borderRadius: "1.5rem" }}
                                onPress={tabChange}
                                type='button'
                            />
                        </div>
                    </form>
                </div>
            </>
        );
    };

export default TestInstructionTab;