/**
 * CustomerBioMarkerDetails Component
 * 
 * Description: The main purpose of this component is to display detailed information about a specific biomarker result for a customer. It uses routing parameters to fetch and display the biomarker result history. Additionally, it includes a breadcrumb navigation component to help users navigate back to the previous page.
 * 
 * File Name: CustomerBioMarkerDetails.tsx
 * Date: 26-08-2024
 */

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from 'src/Components/GlobalComponents/BreadCrumb';
import SingleBiomarkerResultHistory from 'src/Components/GlobalComponents/HealthProfiles/SingleBiomarkerResultHistory';

const CustomerBioMarkerDetails: React.FC = () => {
    const navigate = useNavigate();
    const { bioId = "", uid = "" } = useParams();

    return (
        <>
            <BreadCrumb
                current="Customers"
                previous="dashboard"
                navigateUrl="/admin"
                btnShow={true}
                isUpsert={true}
                onPress={() => navigate(-1)}
            />
            {
                (uid && bioId) &&
                <SingleBiomarkerResultHistory
                    bioId={bioId}
                    uId={uid}
                />
            }
        </>
    );
};

export default CustomerBioMarkerDetails;