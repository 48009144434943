/**
 * HealthSystem Component
 * 
 * Description: This component fetches and displays health scores for various body systems based on the provided user ID. It handles loading states and displays the scores in a grid format with associated images.
 * 
 * Props: 
 * - id (string): The user ID used to fetch the health scores.
 * 
 * File Name: HealthSystem.tsx
 * Date: 26-08-2024
 * 
 * Marker: DOC_START
 */

import React, { useEffect, useState } from "react";
import healthSystem1 from "../../assets/images/health-socre.png";
import healthSystem3 from "../../assets/images/cardiovascular.png";
import healthSystem4 from "../../assets/images/urogenital.png";
import healthSystem5 from "../../assets/images/Respiratory.png";
import healthSystem6 from "../../assets/images/nerves.png";
import healthSystem7 from "../../assets/images/liver.png";
import healthSystem8 from "../../assets/images/digestive.png";
import healthSystem9 from "../../assets/images/hormones-profile.png";
import healthSystem10 from "../../assets/images/immunity.png";
import healthSystem11 from "../../assets/images/blood.png";
import { IHealthScores } from "src/types/GlobalInterfaces";
import httpRequest from "src/helpers/httpRequest";
import { toast } from "react-toastify";

const HealthSystem: React.FC<{ id: string }> = ({ id }) => {
    const [isLoader, setIsLoader] = useState<boolean>(false); // State to manage loading status
    const [data, setData] = useState<IHealthScores | null>(null); // State to hold the fetched health scores data

    //API_CALL: Fetch health profile data when the component mounts or user id change
    useEffect(() => {
        if (id) {
            (async () => {
                setIsLoader(true);
                const { res, err } = await httpRequest<{ message: string; data: IHealthScores }>({ path: `/healthprofile/main/${id}` });
                if (res) {
                    setData(res?.data || {});
                } else {
                    toast(err, { type: 'error' });
                }
                setIsLoader(false);
            })();
        }
    }, [id]);
    return (
        <>
            <div className="parent-box parent-box-margin-issue">
                <div className="health-system-w">
                    <h2 className="font-size-20px theme-color-green font-Poppins-SemiBold">Health System</h2>
                    <div className="health-system-box-grid">
                        <div className="health-system-box">
                            <span className="health-system-box-img"><img src={healthSystem1} alt="" /></span>
                            <span className="font-size-18px font-Poppins-Medium">Health score</span>
                            <strong className="font-size-25px theme-color-green font-Poppins-SemiBold">
                                {
                                    data
                                        ?
                                        Math.floor(data["profileCompletionPercentage"] || 0)
                                        :
                                        "-"
                                }
                            </strong>
                        </div>
                        <div className="health-system-box">
                            <span className="health-system-box-img"><img src={healthSystem3} alt="" /></span>
                            <span className="font-size-18px font-Poppins-Medium">Cardiovascular</span>
                            <strong className="font-size-25px theme-color-green font-Poppins-SemiBold">
                                {
                                    data
                                        ?
                                        Math.floor(data["Cardiovascular System"] || 0)
                                        :
                                        "-"
                                }
                            </strong>
                        </div>
                        <div className="health-system-box">
                            <span className="health-system-box-img"><img src={healthSystem4} alt="" /></span>
                            <span className="font-size-18px font-Poppins-Medium">Urogenital</span>
                            <strong className="font-size-25px theme-color-green font-Poppins-SemiBold">
                                {
                                    data
                                        ?
                                        Math.floor(data["Urogenital System"] || 0)
                                        :
                                        "-"
                                }
                            </strong>
                        </div>

                        <div className="health-system-box">
                            <span className="health-system-box-img"><img src={healthSystem5} alt="" /></span>
                            <span className="font-size-18px font-Poppins-Medium">Respiratory</span>
                            <strong className="font-size-25px theme-color-green font-Poppins-SemiBold">
                                {
                                    data
                                        ?
                                        Math.floor(data["Respiratory System"] || 0)
                                        :
                                        "-"
                                }
                            </strong>
                        </div>
                        <div className="health-system-box">
                            <span className="health-system-box-img"><img src={healthSystem6} alt="" /></span>
                            <span className="font-size-18px font-Poppins-Medium">Nerves</span>
                            <strong className="font-size-25px theme-color-green font-Poppins-SemiBold">
                                {
                                    data
                                        ?
                                        Math.floor(data["Nervous System"] || 0)
                                        :
                                        "-"
                                }
                            </strong>
                        </div>
                        <div className="health-system-box">
                            <span className="health-system-box-img"><img src={healthSystem7} alt="" /></span>
                            <span className="font-size-18px font-Poppins-Medium">Liver</span>
                            <strong className="font-size-25px theme-color-green font-Poppins-SemiBold">
                                {
                                    data
                                        ?
                                        Math.floor(data["Liver System"] || 0)
                                        :
                                        "-"
                                }
                            </strong>
                        </div>

                        <div className="health-system-box">
                            <span className="health-system-box-img"><img src={healthSystem8} alt="" /></span>
                            <span className="font-size-18px font-Poppins-Medium">Digestive</span>
                            <strong className="font-size-25px theme-color-green font-Poppins-SemiBold">
                                {
                                    data
                                        ?
                                        Math.floor(data["Digestive System"] || 0)
                                        :
                                        "-"
                                }
                            </strong>
                        </div>
                        <div className="health-system-box">
                            <span className="health-system-box-img"><img src={healthSystem9} alt="" /></span>
                            <span className="font-size-18px font-Poppins-Medium">Hormones</span>
                            <strong className="font-size-25px theme-color-green font-Poppins-SemiBold">
                                {
                                    data
                                        ?
                                        Math.floor(data["Hormones System"] || 0)
                                        :
                                        "-"
                                }
                            </strong>
                        </div>
                        <div className="health-system-box">
                            <span className="health-system-box-img"><img src={healthSystem10} alt="" /></span>
                            <span className="font-size-18px font-Poppins-Medium">Immunity</span>
                            <strong className="font-size-25px theme-color-green font-Poppins-SemiBold">
                                {
                                    data
                                        ?
                                        Math.floor(data["Immune System"] || 0)
                                        :
                                        "-"
                                }
                            </strong>
                        </div>
                        <div className="health-system-box">
                            <span className="health-system-box-img"><img src={healthSystem11} alt="" /></span>
                            <span className="font-size-18px font-Poppins-Medium">Blood</span>
                            <strong className="font-size-25px theme-color-green font-Poppins-SemiBold">
                                {
                                    data
                                        ?
                                        Math.floor(data["Blood System"] || 0)
                                        :
                                        "-"
                                }
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HealthSystem;