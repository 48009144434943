/**
 * WorkHistory Component
 * 
 * Description: This component displays a doctor's work history, including their job roles, associated hospitals, 
 * and the duration of their employment. Dates are formatted using the `moment` library for consistency. 
 * If `isMyProfile` is true, an edit icon is shown that links to the edit page for updating work history details.
 * 
 * Props:
 * - isMyProfile (boolean): Indicates if the current profile is the user's own profile. If true, an edit icon is shown.
 * - experiences (IDoctorExperiences[]): An array of objects representing the doctor's work experiences. Each object includes:
 *   - role (string): The job role held by the doctor.
 *   - hospitalName (string): The name of the hospital where the doctor worked.
 *   - startDate (string): The start date of the employment period.
 *   - endDate (string): The end date of the employment period.
 * 
 * Usage:
 * This component iterates over the `experiences` array to display each work experience with the role, hospital name, 
 * and the employment period. The dates are formatted as "DD-MM-YYYY". If `isMyProfile` is true, an edit icon is displayed 
 * that links to the edit page for updating work history.
 * 
 * File Name: WorkHistory.tsx
 * Date: 02-09-2024
 */

import React from 'react';
import { Link } from 'react-router-dom';
import edit from "../../../assets/images/awesome-edit.svg";
import moment from 'moment';
import { IDoctorExperiences } from 'src/types/GlobalInterfaces';

const WorkHistory: React.FC<{ isMyProfile: boolean; experiences: IDoctorExperiences[] }> = ({ isMyProfile = false, experiences = [] }) => {
    return (
        <div className="parent-box parent-box-margin-issue relative">
            <h2 className='font-size-20px font-Poppins-SemiBold theme-color-green mb-1'>Work History</h2>
            <div className="work-history mt-4">
                {experiences?.map((item, index: number) => (
                    <div key={index} className="mt-2 mb-4">
                        <h3 className="font-size-20px font-Poppins-Regular theme-color-green">
                            {item.role}
                        </h3>
                        <p className="font-Poppins-Regular font-size-18px">
                            {item.hospitalName}
                        </p>
                        <p className="font-Poppins-Regular font-size-18px flex items-center gap-2">
                            <span>{moment(item.startDate).format("DD-MM-YYYY")}</span>
                            <span>-</span>
                            <span>{moment(item.endDate).format("DD-MM-YYYY")}</span>
                        </p>
                    </div>
                ))
                }
            </div>
            {isMyProfile
                &&
                <Link to="/workhistory" className="edit-pro-icon absolute top-2 right-4">
                    <img src={edit} alt="edit-icon" />
                </Link>
            }
        </div>
    );
};

export default WorkHistory;