/**
 * MyCustomer Component
 * 
 * Description: Displays a list of customers with filtering options for appointment date range, pagination, and access to view 
 * individual customer details like appointment information, test results, and health profiles.
 * Add a modal to notify if access is blocked from a patient, 09-10-2024.
 * 
 * File Name: MyCustomer.tsx
 * Date: 09-09-2024
 */

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CustomerTableWrapper from 'src/Components/Customer/CustomerTableWrapper';
import PageHeader from 'src/Components/Customer/PageHeader';
import StartAndEndDateFilter from 'src/Components/GlobalComponents/StartAndEndDateFilter';
import httpRequest from 'src/helpers/httpRequest';
import { IqueryParams } from 'src/types/GlobalInterfaces';
import { IPaginated } from 'src/types/paginated';
import qs from "qs";
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import eyeIcon from 'src/assets/images/eye-icon-green.png';
import { Modal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import blockIconModal from "src/assets/images/block-icon-modal.png";

interface ICustomerList {
    _id: string;
    firstName: string;
    lastName: string;
    isOnlineAppoinment: boolean;
    hospitalName: null | string;
    appoinmentDate: string;
    access: boolean;
};

interface IMyCustomerResponse {
    documents: ICustomerList[];
    paginated: IPaginated;
}; // Interface for the API response containing appointments and pagination info

// Style for the modal container
const customeStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    backgroundColor: "#fffcf1"
};

// Style for the close button container
const backBtnMaain: React.CSSProperties = {
    position: "absolute",
    top: -10,
    right: -10,
    backgroundColor: "red",
    borderRadius: 100,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const MyCustomer: React.FC = () => {
    const navigate = useNavigate();
    const [myCustomers, setMyCustomers] = useState<ICustomerList[]>([]);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [paginated, setPaginated] = useState<IPaginated>({
        currentPage: 1,
        totalPages: 1,
    }); // Handles pagination state
    const [queryParams, setQueryParams] = useState<IqueryParams>({
        page: 1,
        limit: 10,
        startDate: "",
        endDate: "",
    }); // Query parameters for API request

    // Handles page change in pagination
    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    const applyFilter = (startDate: string, endDate: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            startDate,
            endDate,
        }));
    };

    const resetFilter = () => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            startDate: "",
            endDate: "",
        }));
    };

    const [isModalShow, setIsModalShow] = useState<false | "health" | "test">(false);

    const handleView = (access: boolean, category: "health" | "test", id: string) => {
        if (access && category === "test") {
            navigate(`/customer-tests/${id}/view`);
        } else if (access && category === "health") {
            navigate(`/customer-healthprofile/${id}/view`);
        } else if (!access) {
            setIsModalShow(category);
        }
    };

    // API_CALL: Function to fetch the list of my customers
    const fetchMyCustomersList = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<IMyCustomerResponse>({ path: `/appoiment/all/customer?${qs.stringify(queryParams)}` });
        if (res) {
            const { documents = [], paginated } = res;
            setMyCustomers(documents);
            setPaginated(paginated);
        } else {
            toast(err, { type: "error" }); // Show error toast if API call fails
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchMyCustomersList();
    }, [queryParams]); // Fetch appointments when queryParams change

    return (
        <>
            <PageHeader
                title="My Customer"
            />
            <Modal
                open={isModalShow ? true : false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={customeStyle} className='flex items-center flex-col gap-2 bg-[#FFFCF1] w-[600px] py-[20px] px-[40px] rounded-[8px]'>
                    <button onClick={() => setIsModalShow(false)} style={backBtnMaain}>
                        <FontAwesomeIcon className='font-size-16px text-white' icon={faXmark} size="2x" />
                    </button>
                    <div className="">
                        <img src={blockIconModal} alt="block" className="mx-auto max-w-[60px]" />
                        <p className="font-Poppins-Medium text-center mt-5">{`You do not have permission to view this patient's ${isModalShow === "health" ? "health profile" : "test results"}. The patient has disabled access to their ${isModalShow} information.`}</p>
                    </div>
                </div>
            </Modal>
            <div className="parent-box test-result-cloum-center">
                <StartAndEndDateFilter
                    reset={resetFilter}
                    apply={applyFilter}
                />
                <hr className="my-4" />
                <CustomerTableWrapper
                    isLoading={isLoader}
                    thead={["S.No", "Customer Name", "Appointment Type", "Date", "Test Result", "Health Profile"]}
                    page={paginated?.currentPage || 1}
                    total={paginated?.totalPages || 0}
                    columnWidth={"10% 25% 25% 16% 12% 12%"}
                    onChange={handlePageChange}
                >
                    {myCustomers?.map((item: ICustomerList, index: number) => (
                        <ul key={index} className='font-size-16px tbody-light-table customer-table-wrapper-body-padding-non customer-table-wrapper-body tbody-light-table-customer font-Poppins-Regular grid' style={{ gridTemplateColumns: "10% 25% 25% 16% 12% 12%" }}>
                            <li style={{ paddingLeft: '2rem' }}>{(paginated?.currentPage - 1) * queryParams.limit + (index + 1)}</li>
                            <li>
                                <Link to={`/patient-public/${item?._id || ""}/profile`} className="underline capitalize">{`${item.firstName} ${item.lastName}`}</Link>
                            </li>
                            <li>{item.isOnlineAppoinment ? "Online" : "Physical"}</li>
                            <li>{moment(item.appoinmentDate).format("DD-MM-YYYY")}</li>
                            <li className='justify-center'>
                                <button
                                    onClick={() => handleView(item.access, "test", item._id)}
                                >
                                    <img src={eyeIcon} alt="" />
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleView(item.access, "health", item._id)}
                                >
                                    <img src={eyeIcon} alt="" />
                                </button>
                            </li>
                        </ul>
                    ))}
                </CustomerTableWrapper>
            </div >
        </>
    );
};

export default MyCustomer;