/**
 * UserGraph Component
 * 
 * Description: This component displays a bar chart representing user statistics, including customer and doctor data. 
 * It allows toggling between monthly and yearly views and shows a loading spinner while fetching data.
 * 
 * Props:
 * - isLoader (boolean): Indicates whether the data is being loaded.
 * - data (IDataItem[]): Array of data items containing customer and doctor counts.
 * - category (string): The current view category ('monthly' or 'yearly').
 * - totalCount (number): The total count of users.
 * - monthlyDataCustomer (IData[]): Monthly customer data for the bar chart.
 * - yearlyDataCustomer (IData[]): Yearly customer data for the bar chart.
 * - monthlyDataDoctor (IData[]): Monthly doctor data for the bar chart.
 * - yearlyDataDoctor (IData[]): Yearly doctor data for the bar chart.
 * 
 * File Name: UserGraph.tsx
 * Date: 29-08-2024
 * 
 * Marker: DOC_START
 */

import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import httpRequest from 'src/helpers/httpRequest';
import { allMonths, allYears } from 'src/lib/constans';
import SelectDropdown from '../GlobalComponents/SelectDropdown';

interface IDataItem {
    name: string;
    customers: number;
    doctors: number;
}

interface IData {
    count: number;
    _id: { year: number; month: 8 };
};

interface ICustomerStats {
    totalCount: number;
    monthlyDataCustomer: IData[];
    monthlyDataDoctor: IData[];
    yearlyDataCustomer: IData[];
    yearlyDataDoctor: IData[];
};

const UserGraph: React.FC = () => {
    const [isLoader, setIsLoader] = useState<boolean>(false);

    const [data, setData] = useState<IDataItem[]>([]);
    const [category, setCategory] = useState<string>("monthly");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [monthlyDataCustomer, setMonthlyDataCustomer] = useState<IData[]>([]);
    const [yearlyDataCustomer, setYearlyDataCustomer] = useState<IData[]>([]);
    const [monthlyDataDoctor, setMonthlyDataDoctor] = useState<IData[]>([]);
    const [yearlyDataDoctor, setYearlyDataDoctor] = useState<IData[]>([]);

    const fetchUserStats = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ICustomerStats[]>({ path: "/customer/statistics" });
        if (res) {
            const { totalCount, monthlyDataCustomer, monthlyDataDoctor, yearlyDataCustomer, yearlyDataDoctor } = res[0];
            setMonthlyDataCustomer(monthlyDataCustomer);
            setYearlyDataCustomer(yearlyDataCustomer);
            setMonthlyDataDoctor(monthlyDataDoctor);
            setYearlyDataDoctor(yearlyDataDoctor);
            setTotalCount(totalCount);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchUserStats();
    }, []);

    useEffect(() => {
        if (category === 'monthly') {
            const formattedData = allMonths.map(({ month, name }) => {
                const customerData = monthlyDataCustomer.find(v => v._id.month === month);
                const doctorData = monthlyDataDoctor.find(v => v._id.month === month);
                return {
                    name: name, // Month name
                    customers: customerData ? customerData.count : 0, // Default to 0 if no data
                    doctors: doctorData ? doctorData.count : 0, // Default to 0 if no data
                };
            });
            setData(formattedData);
        } else if (category === 'yearly') {
            const formattedData = allYears.map(year => {
                const customerData = yearlyDataCustomer.find(v => v._id.year === year);
                const doctorData = yearlyDataDoctor.find(v => v._id.year === year);
                return {
                    name: String(year), // year name
                    customers: customerData ? customerData.count : 0, // Default to 0 if no data
                    doctors: doctorData ? doctorData.count : 0, // Default to 0 if no data
                };
            });
            setData(formattedData);
        } else { }
    }, [category, totalCount]);

    const renderLegend = (value: string, entry: any) => {
        const { color } = entry;
        return (
            <span className="custom-legend-item">
                <span className="color-box" style={{ backgroundColor: color }}></span>
                {value}
            </span>
        );
    };
    return (
        <div style={{ height: '100%' }}>
            <h2 className="font-Poppins-SemiBold text-[#232323] font-size-20px mb-2">Users</h2>
            <div className='user-graph-inner'>
                <div className='flex justify-between items-center'>
                    <div className='font-Poppins-Regular user-graph-header font-size-14px'>
                        Total users: <span className='font-Poppins-SemiBold theme-color-green font-size-20px'>{totalCount}</span>
                    </div>
                    {/* <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className='font-Poppins-SemiBold user-graph-select theme-color-green font-size-14px'
                        >
                            <option className='font-Poppins-Medium font-size-14px' value="monthly">Monthly</option>
                            <option className='font-Poppins-Medium font-size-14px' value="yearly">Yearly</option>
                        </select> */}
                    <div className='flex gap-2'>
                        <span className="flex items-center gap-1">
                            <div className='bg-[#00a297] w-[8px] h-[8px] rounded-full'></div>
                            <span className="font-Poppins-Regular font-size-16px">Customers</span>
                        </span>
                        <span className="flex items-center gap-1">
                            <div className='bg-[#00443f] w-[8px] h-[8px] rounded-full'></div>
                            <span className="font-Poppins-Regular font-size-16px">Doctors</span>
                        </span>
                    </div>
                    <div className='select-container-graph'>
                        <SelectDropdown
                            options={[{ label: "Monthly", value: "monthly" }, { label: "Yearly", value: "yearly" }]}
                            isMulti={false}
                            data={[{ label: category, value: category }]}
                            setData={(e) => e[0] ? setCategory(e[0].value) : ""}
                            checkboxShow={false}
                            isLabel={false}
                            isRequired={true}
                            clearIcon={false}
                        />
                    </div>
                </div>
                {
                    isLoader
                    &&
                    <div className="text-center">
                        <CircularProgress size={20} style={{ color: "#004540" }} />
                    </div>
                }
                <ResponsiveContainer className={'user-graph-container'}>
                    <BarChart
                        data={data}
                        margin={{ top: 20, right: 0, left: -35, bottom: 0 }}
                        barCategoryGap="15%"
                    >
                        <CartesianGrid />
                        <XAxis className='revenue-axis' dataKey="name" />
                        <YAxis className='revenue-axis' />
                        <Tooltip wrapperClassName='revenue-axis' />
                        {/* <Legend
                            formatter={renderLegend}
                            verticalAlign="top"
                            align="center"
                        // className='customer-docter'
                        /> */}
                        <Bar dataKey="customers" fill="#00A297" radius={[10, 10, 0, 0]} />
                        <Bar dataKey="doctors" fill="#00443F" radius={[10, 10, 0, 0]} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default UserGraph;